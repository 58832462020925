import { Icon } from "@virtuslab/tetrisly-icons";
import React from "react";

const StepButtons = ({
  primaryText,
  secondaryText,
  primaryClick,
  secondaryClick,
}) => {
  return (
    <div className="flex flex-col justify-center align-items-center gap-4 px-4">
      <button
        className="py-2 font-semibold bg-secondarygreen border-2 rounded-md shadow-sm border-primarygreen w-full md:w-1/2 flex align-items-center justify-center gap-2 mt-2"
        onClick={primaryClick}
      >
        <span className="text-white">{primaryText}</span>{" "}
        <Icon name="20-chevron-right" color="#fff" />
      </button>
      <button
        className="py-2 font-semibold bg-white border-2 rounded-md shadow-sm border-primarylightgrey w-full md:w-1/2"
        onClick={secondaryClick}
      >
        <span className="text-black">{secondaryText}</span>
      </button>
    </div>
  );
};

export default StepButtons;