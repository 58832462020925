import * as React from "react";

const NotChart = (props) => (
  <svg
    width={props?.width || '24'}
    height={props?.height || '24'}
    viewBox="0 0 24 24"
    fill={props?.fill || '#000'}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M4 9h4v11H4zm12 4h4v7h-4zm-6-9h4v16h-4z" fill="currentColor" />
    <path
      d="m4 4 17 17"
      stroke={props?.fill || '#000'}
      strokeWidth="2"
    />
  </svg>
);

export default NotChart;
