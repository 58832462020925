import { apiSlice } from "../../../app/api/apiSlice";

export const communityConstraintApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCommunityConstraint: builder.mutation({
      query: (credentials) => ({
        url: `/community/constraint/${credentials.community_id}`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updateCommunityConstraint: builder.mutation({
      query: (credentials) => ({
        url: `/community/constraint/${credentials.id}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    deleteCommunityConstraint: builder.mutation({
      query: (credentials) => ({
        url: `/community/constraint/${credentials.id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreateCommunityConstraintMutation,
  useUpdateCommunityConstraintMutation,
  useDeleteCommunityConstraintMutation,
} = communityConstraintApiSlice;
