import React, { useRef, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckboxSelector from "../../../Schedules/components/CheckboxSelector";
import SearchIcon from "@mui/icons-material/Search";
import { Icons } from "../../../../assets";
//Graph
import ReactEcharts from "echarts-for-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as echarts from "echarts";
import { Tooltip } from "@mui/material";

function RevenueReportTable({
  revenueReports,
  setRevenueReports,
  providers,
  filteredPv,
  setFilteredPv,
  selectedProviders,
  handleProviderFilter,
  patients,
  selectedPatientsName,
  handlePatientNameFilter,
  speciality,
  selectedSpeciality,
  handleSpecialityFilter,
  filteredSpe,
  setFilteredSpe,
  isSelecetdValue,
  filteredPt,
  setFilteredPt,
  visitType,
  selectedVisitType,
  handleVisitTypeFilter,
  isSelectAllProviders,
  setIsSelectAllProviders,
  isSelectAllSpe,
  setIsSelectAllSpe,
  isSelectAllPatients,
  setIsSelectAllPatients,
  isSelectAllVisitType,
  setIsSelectAllVisitType,
  status,
  selectedStatus,
  handleStatusFilter,
  setIsSelectAllStatus,
  isSelectAllStatus
}) {
  const dropdownRef = useRef(null);
  const dropdownRefSpe = useRef(null);
  const dropdownRefPatient = useRef(null);
  const dropdownRefVisit = useRef(null); const dropdownStatusRef = React.useRef(null);

  const [providerListOpen, setProviderListOpen] = useState(false);
  const [patientNameListOpen, setPatientNameListOpen] = React.useState(false);
  const [visitTypeListOpen, setVisitTypeListOpen] = React.useState(false);
  const [speListOpen, setSpeListOpen] = useState(false);

  const [isApptDtAse, setIsAppDtAse] = React.useState(true);
  const [isRevAse, setIsRevAse] = React.useState(true);

  const [providerNameSearch, setProviderNameSearch] = useState("");
  const [speNameSearch, setSpeNameSearch] = useState("");
  const [patientNameSearch, setPatientNameSearch] = React.useState("");
  const [statusListOpen, setStatusListOpen] = React.useState(false);

  //graph

  const handleProviderListOpen = () => {
    setProviderListOpen(!providerListOpen);
    setPatientNameListOpen(false);
    setSpeListOpen(false);
    setVisitTypeListOpen(false);
    if (providerListOpen) {
      setProviderNameSearch("");
      setFilteredPv(providers);
    }
    setSpeNameSearch("");
    setFilteredSpe(speciality);
    setPatientNameSearch("");
    setFilteredPt(patients);
    setStatusListOpen(false);
  };

  const handleStatusListOpen = () => {
    setStatusListOpen(!statusListOpen);
    setVisitTypeListOpen(false);
    setProviderListOpen(false);
    setPatientNameListOpen(false);
    setSpeListOpen(false);
    setPatientNameSearch("");
    setFilteredPt(patients);
    setProviderNameSearch("");
    setFilteredPv(providers);
    setSpeNameSearch("");
    setFilteredSpe(speciality);
  };
  const handlePatientListOpen = () => {
    setPatientNameListOpen(!patientNameListOpen);
    setProviderListOpen(false);
    setSpeListOpen(false);
    setVisitTypeListOpen(false);
    setProviderNameSearch("");
    setFilteredPv(providers);
    setSpeNameSearch("");
    setFilteredSpe(speciality);
    if (patientNameListOpen) {
      setPatientNameSearch("");
      setFilteredPt(patients);
    }
    setStatusListOpen(false);
  };
  const handleSpeListOpen = () => {
    setSpeListOpen(!speListOpen);
    setProviderListOpen(false);
    setPatientNameListOpen(false);
    setVisitTypeListOpen(false);
    setProviderNameSearch("");
    setFilteredPv(providers);
    if (speListOpen) {
      setSpeNameSearch("");
      setFilteredSpe(speciality);
    }
    setPatientNameSearch("");
    setFilteredPt(patients);
    setStatusListOpen(false);
  };
  const handleVisitTypeListOpen = () => {
    setVisitTypeListOpen(!visitTypeListOpen);
    setProviderListOpen(false);
    setPatientNameListOpen(false);
    setSpeListOpen(false);
    setPatientNameSearch("");
    setFilteredPt(patients);
    setProviderNameSearch("");
    setFilteredPv(providers);
    setSpeNameSearch("");
    setFilteredSpe(speciality);
    setStatusListOpen(false);
  };

  const handleSelectAllProviders = () => {
    handleProviderFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllProviders,
    });
    setIsSelectAllProviders(!isSelectAllProviders);
  };
  const handleSelectAllSpe = () => {
    handleSpecialityFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllSpe,
    });
    setIsSelectAllSpe(!isSelectAllSpe);
  };
  const handleSelectAllPatients = () => {
    handlePatientNameFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllPatients,
    });
    setIsSelectAllPatients(!isSelectAllPatients);
  };
  const handleSelectAllVisitType = () => {
    handleVisitTypeFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllVisitType,
    });
    setIsSelectAllVisitType(!isSelectAllVisitType);
  };
  const handleSelectAllStatus = () => {
    handleStatusFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllStatus,
    });
    setIsSelectAllStatus(!isSelectAllStatus)
  };
  const TABLE_HEADERS = [
    "Appt Date",
    "Provider Name",
    "Speciality",
    "Patient Name",
    "Visit Type",
    "Expected Revenue",
    "Status"
  ];
  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };

  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(",")
        : type === "date"
          ? moment(value).format("MM/DD/YYYY")
          : value;
  };
  const handleClickOutside = (event) => {
    if (
      dropdownRefSpe.current &&
      !dropdownRefSpe.current.contains(event.target)
    ) {
      setSpeListOpen(false);
      setFilteredSpe(speciality);
      setSpeNameSearch("");
    }
    if (
      dropdownRefPatient.current &&
      !dropdownRefPatient.current.contains(event.target)
    ) {
      setPatientNameListOpen(false);
      setPatientNameSearch("");
      setFilteredPt(patients);
    }
    if (
      dropdownRefVisit.current &&
      !dropdownRefVisit.current.contains(event.target)
    ) {
      setVisitTypeListOpen(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setProviderListOpen(false);
      setProviderNameSearch("");
      setFilteredPv(providers);
    }
    if (dropdownStatusRef.current && !dropdownStatusRef.current.contains(event.target)) {
      setStatusListOpen(false);
    }
  };
  React.useEffect(() => {
    if (
      speListOpen ||
      providerListOpen ||
      patientNameListOpen ||
      visitTypeListOpen ||
      statusListOpen
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [speListOpen, patientNameListOpen, providerListOpen, visitTypeListOpen, statusListOpen]);

  // React.useEffect(() => {
  //   if (!isSelecetdValue) {
  //     setProviderListOpen(false);
  //     setSpeListOpen(false);
  //     setProviderListOpen(false);
  //     setVisitTypeListOpen(false);
  //   }
  // }, [isSelecetdValue]);

  React.useEffect(() => {
    setIsAppDtAse(true);
    setIsRevAse(true);
    selectedProviders.length === providers.length
      ? setIsSelectAllProviders(true)
      : setIsSelectAllProviders(false);
    selectedSpeciality.length === speciality.length
      ? setIsSelectAllSpe(true)
      : setIsSelectAllSpe(false);
    selectedPatientsName.length === patients.length
      ? setIsSelectAllPatients(true)
      : setIsSelectAllPatients(false);
    selectedVisitType.length === visitType.length
      ? setIsSelectAllVisitType(true)
      : setIsSelectAllVisitType(false);
    selectedStatus.length === status.length ?
      setIsSelectAllStatus(true)
      : setIsSelectAllStatus(false);
  }, [
    selectedPatientsName,
    selectedProviders,
    selectedSpeciality,
    selectedVisitType,
    selectedStatus
  ]);
  return (
    <>
      <div className="h-full overflow-auto mt-2 w-full">
        <table
          className="w-full"
          style={{ whiteSpace: "nowrap" }}
          id="revenueReport"
        >
          <thead>
            <tr className="bg-primarylightgrey">
              {TABLE_HEADERS.map((header, index) => {
                if (header === "Provider Name") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (selectedProviders.length > 0 || providerListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRef}
                    >
                      <div className="flex flex-row  items-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button
                            className="pr-2"
                            onClick={handleProviderListOpen}
                          >
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>{" "}
                        {providerListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                            <div className="d-flex align-center sticky top-0 bg-white">
                              <input
                                type="search"
                                value={providerNameSearch}
                                onChange={(e) => {
                                  setProviderNameSearch(e.target.value);
                                  setFilteredPv(
                                    providers.filter((provider) => {
                                      return provider.name
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase());
                                    })
                                  );
                                }}
                                className="search-input"
                                autoFocus
                                placeholder="Search"
                              />
                              {providerNameSearch === "" ? (
                                <SearchIcon className="search-icon-input" />
                              ) : (
                                ""
                              )}
                            </div>
                            {filteredPv?.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllProviders ||
                                        selectedProviders.length ===
                                        providers.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllProviders();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {filteredPv.map((provider, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      providers.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={provider.name}
                                      isChecked={
                                        selectedProviders.includes(provider.id)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleProviderFilter(provider.id, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllProviders,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Providers Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Speciality") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (selectedSpeciality.length > 0 || speListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRefSpe}
                    >
                      <div className="flex flex-row justify-center items-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button className="pr-2" onClick={handleSpeListOpen}>
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>{" "}
                        {speListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16">
                            <div className="d-flex align-center sticky top-0 bg-white">
                              <input
                                type="search"
                                value={speNameSearch}
                                onChange={(e) => {
                                  setSpeNameSearch(e.target.value);
                                  setFilteredSpe(
                                    speciality.filter((spe) => {
                                      return spe
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase());
                                    })
                                  );
                                }}
                                className="search-input"
                                autoFocus
                                placeholder="Search"
                              />
                              {speNameSearch === "" ? (
                                <SearchIcon className="search-icon-input" />
                              ) : (
                                ""
                              )}
                            </div>
                            {filteredSpe.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllSpe ||
                                        selectedSpeciality.length ===
                                        speciality.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllSpe();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {filteredSpe.map((spe, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      speciality.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={spe}
                                      isChecked={
                                        selectedSpeciality?.includes(spe)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleSpecialityFilter(spe, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllSpe,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}{" "}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Speciality Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Patient Name") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (selectedPatientsName.length > 0 || patientNameListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRefPatient}
                    >
                      <div className="flex flex-row items-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button
                            className="pr-2"
                            onClick={handlePatientListOpen}
                          >
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>{" "}
                        {patientNameListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                            <div className="d-flex align-center sticky top-0 bg-white">
                              <input
                                type="search"
                                value={patientNameSearch}
                                onChange={(e) => {
                                  setPatientNameSearch(e.target.value);
                                  setFilteredPt(
                                    patients.filter((patient) => {
                                      return patient.name
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase());
                                    })
                                  );
                                }}
                                className="search-input"
                                autoFocus
                                placeholder="Search"
                              />
                              {patientNameSearch === "" ? (
                                <SearchIcon className="search-icon-input" />
                              ) : (
                                ""
                              )}
                            </div>
                            {filteredPt.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllPatients ||
                                        selectedPatientsName.length ===
                                        patients.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllPatients();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {filteredPt.map((patient, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      patients.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={patient.name}
                                      isChecked={
                                        selectedPatientsName.includes(
                                          patient.id
                                        )
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handlePatientNameFilter(patient.id, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllPatients,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}{" "}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Patients Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Visit Type") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (selectedVisitType.length > 0 || visitTypeListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRefVisit}
                    >
                      <div className="flex flex-row items-center justify-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button
                            className="pr-2"
                            onClick={handleVisitTypeListOpen}
                          >
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>
                        {visitTypeListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 ">
                            {visitType.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllVisitType ||
                                        selectedVisitType.length ===
                                        visitType.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllVisitType();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {visitType.map((type, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      visitType.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={type.name}
                                      isChecked={
                                        selectedVisitType.includes(type.id)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleVisitTypeFilter(type.id, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllVisitType,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Visit Types Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Appt Date") {
                  return (
                    <th
                      key={index}
                      className="px-2 py-3 justify-center text-base min-w-40 d-flex"
                    >
                      <div className="d-flex flex-row items-center justify-center relative">
                        <div className="d-flex align-items-center w-full justify-center">
                          <p className="m-0">{header}</p>
                          {isApptDtAse ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDropUpIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setIsAppDtAse(false);
                                  const result = [...revenueReports].sort(
                                    (a, b) =>
                                      new Date(a.visit_date) -
                                      new Date(b.visit_date)
                                  );
                                  setRevenueReports(result);
                                }}
                                value="asc"
                                className="arrow-up"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sort Ascending">
                              <ArrowDropDownIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                }}
                                value="des"
                                className="arrow-down"
                                onClick={() => {
                                  setIsAppDtAse(true);
                                  const result = [...revenueReports].sort(
                                    (a, b) =>
                                      new Date(b.visit_date) -
                                      new Date(a.visit_date)
                                  );
                                  setRevenueReports(result);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                } else if (header === "Status") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (statusListOpen || selectedStatus.length > 0
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownStatusRef}
                    >
                      <div className="flex flex-row items-center justify-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button
                            className="pr-2"
                            onClick={handleStatusListOpen}
                          >
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>{" "}
                        {statusListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                            {status.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllStatus ||
                                        selectedStatus.length ===
                                        status.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllStatus();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {status.map((st, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      st.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={st?.name}
                                      isChecked={
                                        selectedStatus.includes(st.id)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleStatusFilter(st.id, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllStatus,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Status Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Expected Revenue") {
                  return (
                    <th
                      key={index}
                      className="px-2 py-3 text-base justify-center min-w-40 d-flex"
                    >
                      <div className="d-flex flex-row items-center relative">
                        <div className="d-flex align-items-center w-full justify-center">
                          <p className="m-0">{header}</p>
                          {isRevAse ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDropUpIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setIsRevAse(false);
                                  const result = [...revenueReports].sort(
                                    (a, b) =>
                                      new Date(a.rate) - new Date(b.rate)
                                  );
                                  setRevenueReports(result);
                                }}
                                value="asc"
                                className="arrow-up"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sort Ascending">
                              <ArrowDropDownIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                }}
                                value="des"
                                className="arrow-down"
                                onClick={() => {
                                  setIsRevAse(true);
                                  const result = [...revenueReports].sort(
                                    (a, b) =>
                                      new Date(b.rate) - new Date(a.rate)
                                  );
                                  setRevenueReports(result);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className="px-2 py-3 text-base">
                      <div className="flex flex-row items-center">
                        <p className="m-0">{header}</p>
                      </div>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          {revenueReports.length > 0 ? (
            <tbody>
              {revenueReports.map((revenue, index) => {
                const isEven = (index + 1) % 2 === 0;
                return (
                  <tr
                    className={`text-base border-bottom text-left text-gray-900 border-bottom ${isEven ? "bg-primarylightgrey" : ""
                      }`}
                  >
                    <td className="px-2 py-3">
                      {parseNull(revenue.visit_date, "NA", "date")}
                    </td>
                    <td className="px-2 py-3  reportnamecolumn">
                      {parseNull(revenue.provider_name)}
                    </td>
                    <td className="px-2 py-3">
                      {parseNull(revenue.provider_type)}
                    </td>
                    <td className="px-2 py-3 reportnamecolumn">
                      {parseNull(revenue.patient_name)}
                    </td>
                    <td className="px-2 py-3">
                      {parseNull(revenue.visit_type)}
                    </td>
                    <td className="px-2 py-3">
                      {revenue.rate && "$"}
                      {parseNull(revenue.rate?.toLocaleString("en-US"))}
                    </td>
                    <td className="px-2 py-3">{parseNull(revenue.status === 'SCHEDULED' ? 'Pending' : revenue.status.charAt(0).toUpperCase() + revenue.status.slice(1).toLowerCase())}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <div
                className="d-flex align-Items-center justify-content-center absolute w-full my-5 py-3"
                style={{ zIndex: 0 }}
              >
                <p className="text-xl font-bold text-primarygrayborder">
                  {" "}
                  No Records Found
                </p>
              </div>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}

export default RevenueReportTable;
