import React from "react";
import classNames from "classnames";
import { DeleteOutline, PeopleSharp } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import ReusableModal from "../../components/Modals/ReusableModal";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { useGetAllUserMutation } from "../../features/user/userApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import Search from "../../components/Search/SearchNew";
import {
  useAddUserToGroupMutation,
  useGetAllGroupUsersMutation,
  useGetGroupByIdMutation,
  useRemoveUserFromGroupMutation,
} from "../../features/group/groupApiSlice";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
// import PatientsTable from "./components/PatientsTable";

const GroupInfo = () => {
  const [reports, setReports] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [groupUsers, setGroupUsers] = React.useState([]);
  const [group, setGroup] = React.useState(null);

  const [userId, setUserId] = React.useState(null);

  const [addUserOpen, setAddUserOpen] = React.useState(false);

  const [searchFilter, setSearchFilter] = React.useState("");

  const [tab, setTab] = React.useState("users");

  const handleTabChange = (newTab) => {
    if (newTab !== tab) {
      setTab(newTab);
    }
  };

  const { id } = useParams();

  const navigate = useNavigate();

  const [getAllUser] = useGetAllUserMutation();
  const [getAllGroupUsers] = useGetAllGroupUsersMutation();
  const [createUser] = useAddUserToGroupMutation();
  const [removeUser] = useRemoveUserFromGroupMutation();
  const [getGroupById] = useGetGroupByIdMutation();

  const user = useSelector(selectUser);

  const saveUser = async () => {
    const { data } = await createUser({
      groupId: id,
      organisation_id: user.organisation.id,
      id: userId,
    }).unwrap();

    const userList = [data, ...groupUsers];
    setGroupUsers(userList);
  };

  const removeGroupUser = async (userGroupId) => {
    await removeUser({
      userGroupId,
    }).unwrap();

    const userList = groupUsers.filter(
      (userMember) => userMember.userGroupId !== userGroupId
    );
    setGroupUsers(userList);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const fetchGroupUsers = async () => {
    try {
      const { data } = await getAllGroupUsers({ groupId: id }).unwrap();

      setGroupUsers(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await getAllUser().unwrap();

      setUsers(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const handleAdd = async () => {
    await saveUser();
    setAddUserOpen(false);
    clearInput();
  };

  const fetchGroups = async () => {
    try {
      const { data } = await getGroupById({ groupId: id }).unwrap();

      setGroup(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchGroups();
      fetchGroupUsers();
      fetchUsers();
    }
  }, [id]);

  const groupUsersList = groupUsers?.filter((u) => user.id !== u.id);

  const Avatar = ({
    name,
    image,
    size = "w-10 h-10",
    textSize = "text-base",
    fontWeight = "font-normal",
  }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className={classNames(
                "object-cover w-10 h-10 rounded-full",
                size
              )}
            />
          </div>
        ) : (
          <p
            className={classNames(
              "flex items-center justify-center m-0 text-white rounded-full bg-primaryblue",
              size,
              textSize,
              fontWeight
            )}
          >
            {name
              .split(" ")
              .map((n) => n[0])
              .slice(0, 2)
              .join("")
              .toUpperCase()}
          </p>
        )}
      </>
    );
  };

  const clearInput = () => {
    setUserId(null);
  };

  const tabName = {
    reports: "Reports",
    users: "Users",
  };

  const tabCount = {
    reports: groupUsersList.length,
    users: groupUsersList.length,
  };

  return (
    <div className="flex flex-row items-start">
      <div className="w-3/12 py-4 pl-16 pr-8">
        <div className="w-full overflow-x-auto overflow-y-auto h-[calc(100vh-128px)] bg-white rounded-lg shadow border-1 border-primarygrayborder">
          <button className="p-3 text-primaryblue" onClick={handleBack}>
            Back
          </button>
          <div className="flex flex-col items-center justify-end gap-2 p-4 border-b border-b-primarygrayborder">
            <div className="relative">
              <Avatar
                name={group?.name || ""}
                image={null}
                size="w-20 h-20"
                textSize="text-3xl"
                fontWeight="font-semibold"
              />
              <div className="absolute right-0 w-4 h-4 rounded-full bottom-14 bg-primarygreen" />
            </div>
            <p className="m-0 text-lg font-bold">{group?.name}</p>
          </div>
          <div className="flex flex-col h-auto gap-4 p-4">
            <button
              onClick={() => handleTabChange("users")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "users" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "users" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "users" && "text-primaryblue"
                )}
              >
                Users
              </p>
            </button>
            <button
              onClick={() => handleTabChange("reports")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "reports" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "reports" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "reports" && "text-primaryblue"
                )}
              >
                Reports
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start w-9/12 py-4">
        <div className="flex flex-row items-center justify-between w-full pl-8 pr-16">
          <div className="flex flex-row items-center w-full gap-12">
            <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
              {tabName[tab]}{" "}
              <span className="ml-2 text-base font-bold text-primaryblue">
                {tabCount[tab]}
              </span>
            </h1>
            <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
              <Search value={searchFilter} setValue={setSearchFilter} />
            </div>
          </div>
          {tab === "users" && (
            <button
              onClick={() => {
                setAddUserOpen(true);
              }}
              className="px-4 py-2 shadow-sm rounded-md border-[2px] border-primarygrayborder whitespace-nowrap text-primaryblue"
            >
              Add
            </button>
          )}
        </div>
        <div className="w-full pl-8 pr-16">
          <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow max-h-[calc(100vh-200px)]">
            {
              <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
                <thead>
                  <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                    <th className="px-4 py-3">Name</th>
                    <th className="px-4 py-3">Email</th>
                    <th className="px-4 py-3">Phone</th>
                    <th className="px-4 py-3">Role</th>
                    <th className="px-4 py-3">Status</th>
                    <th className="px-4 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {groupUsersList
                    .filter((u) =>
                      `${u.firstname || ""} ${u.lastname || ""}`
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    )
                    .map((user, index) => (
                      <tr
                        className={classNames(
                          "text-left text-gray-900 h-24 whitespace-nowrap",
                          index !== user.length - 1 ? "border-b" : "border-none"
                        )}
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">
                          <button className="flex flex-row items-center gap-4">
                            <Avatar name={user.firstname} image={null} />
                            <p className="m-0 font-semibold underline">
                              {`${user.firstname || ""} ${user.lastname || ""}`}
                            </p>
                          </button>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {user.email}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {user.phone}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {user.type === "2" ? "Admin" : "User"}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <div
                            className={classNames(
                              user.status.toUpperCase() === "ACTIVE"
                                ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                                : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                              "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                            )}
                          >
                            <div
                              className={classNames(
                                "w-2 h-2 rounded-full",
                                user.status.toUpperCase() === "ACTIVE"
                                  ? "bg-primarygreen"
                                  : "bg-primarytextgrey"
                              )}
                            />
                            {user.status}
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <button
                            onClick={() => removeGroupUser(user.userGroupId)}
                            className="flex items-center justify-center w-8 h-8 rounded-md aspect-square bg-primarylightgrey"
                          >
                            <DeleteOutline
                              style={{
                                fontSize: "20px",
                                color: "#EB5757",
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
      <ReusableModal
        open={addUserOpen}
        title={`Add User`}
        onClose={() => {
          setAddUserOpen(false);
          clearInput();
        }}
      >
        <div className="flex flex-col items-center min-w-[480px]">
          <div className="flex flex-col gap-4 p-6 w-full">
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="user" className="m-0 text-base font-semibold">
                Users
              </label>
              <select
                id="user"
                name="user"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              >
                <option value={0}>Select user</option>
                {users
                  ?.filter((u) => !groupUsers.find((g) => g.user_id === u.id))
                  ?.map((user) => {
                    return (
                      <option value={user.id}>{`${user.firstname || ""} ${
                        user.lastname || ""
                      }`}</option>
                    );
                  })}
              </select>
            </div>
            <button
              disabled={!userId}
              className="w-full disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
              onClick={handleAdd}
            >
              Add
            </button>
          </div>
        </div>
      </ReusableModal>
    </div>
  );
};

export default GroupInfo;
