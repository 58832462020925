import * as React from "react";
const TagSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M1 3.856A2.856 2.856 0 0 1 3.856 1H9.3c.757 0 1.483.3 2.019.836l6.844 6.844a2.856 2.856 0 0 1 0 4.039l-5.445 5.445a2.856 2.856 0 0 1-4.039 0L1.836 11.32A2.856 2.856 0 0 1 1 9.3V3.857ZM3.856 2.5c-.749 0-1.356.607-1.356 1.356V9.3c0 .36.143.704.397.958l6.844 6.844c.53.53 1.388.53 1.917 0l5.445-5.445c.53-.53.53-1.388 0-1.917l-6.844-6.844a1.356 1.356 0 0 0-.958-.397H3.856ZM6.5 6a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-2 .5a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TagSvg;
