import React, { useState } from "react";
import ConversationScreen from "../../screens/Chat/ConversationScreen";
import { Dialog } from "@mui/material";

const ChatModal = ({
  open: isOpen,
  handleClose,
  chat,
  onSendMessage,
  socket,
}) => {
  const [open, setOpen] = useState(isOpen);

  const onClose = () => {
    setOpen(true);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      fullWidth
      aria-labelledby="chat reply modal"
      aria-describedby="chat reply modal"
    >
      <ConversationScreen
        socket={socket}
        onClose={onClose}
        chat={chat}
        onSendMessage={onSendMessage}
      />
    </Dialog>
  );
};

export default ChatModal;
