import { apiSlice } from "../../app/api/apiSlice";
import { SB_URL } from "../../constants/api-constants";

export const reportApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // ... SKORBOARD
        uploadExcelFile: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}data-management/create-table`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        getAllTableSkorBoard: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}data-management/get-user-tables`,
                method: "GET",
            }),
        }),
        getTableData: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}data-management/get-table-data`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        getReportTableData: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/get-table-data`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        filterTableDataSb: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}data-management/get-filtered-table-data`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        updateTableByName: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}data-management/update-table`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        createComputedColumn: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}data-management/generate-calculated-column`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        validateData: builder.mutation({
            query: (credentials) =>
            //     {
            //     url: `${SB_URL}data-management/validate-table-data-new`,
            //     method: "POST",
            //     body: { ...credentials },
            // }
            {
                const formData = new FormData();

                // Append file
                if (credentials.file) {
                    formData.append("file", credentials.file);
                }

                // Append other fields
                Object.keys(credentials).forEach((key) => {
                    if (key !== "file") {
                        formData.append(key, credentials[key]);
                    }
                });

                console.log(formData, ">>JJJ")
                return {
                    url: `${SB_URL}data-management/validate-table-data-new`,
                    method: "POST",
                    body: formData,
                    headers: {
                        // DO NOT manually set `Content-Type`, the browser will handle it
                    },
                };
            },
        }),
        appendData: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}data-management/append-table-data`,
                method: "POST",
                body: { ...credentials },
            }),
        }),

        //  Superset .....
        connectDataBase: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}superset/add-connection`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        removeConnection: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}superset/remove-connection`,
                method: "POST",
                body: { ...credentials },
            }),
        }),

        getAllDatabase: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}superset/fetch-connections`,
                method: "GET",
            }),
        }),

        fetchSchema: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}superset/fetch-schema?database_id=${credentials?.database_id}`,
                method: "GET",
            }),
        }),
        fetchTables: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}superset/fetch-tables?database_id=${credentials?.database_id}&schema_name=${credentials?.schema_name}`,
                method: "GET",
            }),
        }),

        fetchTableMetadata: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}superset/fetch-table-metadata?database_id=${credentials?.database_id}&schema_name=${credentials?.schema_name}&table_name=${credentials?.table_name}&db_type=${credentials?.db_type}`,
                method: "GET",
            }),
        }),

        getTableDataUsingQuery: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}superset/fetch-queried-data`,
                method: "POST",
                body: { ...credentials },
            }),
        }),

        addDatatset: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}superset/add-dataset`,
                method: "POST",
                body: { ...credentials },
            }),
        }),


        // Report Designer ....

        getAllUserReportsSb: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/get-user-reports`,
                method: "GET",
            }),
        }),
        getReportByIdSb: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/get-report/${credentials?.id}`,
                body: { ...credentials },
                method: "POST",
            }),
        }),
        creteUserReportSb: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/create-report`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        updateUserReportSb: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/update-report`,
                method: "POST",
                body: { ...credentials },
            }),
        }),
        calculateExpression: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/calculate-expression`,
                method: "POST",
                body: { ...credentials }
            })
        }),
        getLocations: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/get-locations`,
                method: "GET",
            })
        }),
        getFilteredTables: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/get-filtered-tables/${credentials?.id}`,
                method: "POST",
                body: { ...credentials }
            })
        }),
        getListViewData: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/get-list-view-data`,
                method: "POST",
                body: { ...credentials }
            })
        }),
        getDistValues: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}report/get-table-distinct-value`,
                method: "POST",
                body: { ...credentials }
            })
        }),
        createTable: builder.mutation({
            query: (credentials) => {
                const formData = new FormData();

                // Append file
                if (credentials.file) {
                    formData.append("file", credentials.file);
                }

                // Append other fields
                Object.keys(credentials).forEach((key) => {
                    if (key !== "file") {
                        formData.append(key, credentials[key]);
                    }
                });

                return {
                    url: `${SB_URL}data-management/create-table-new`,
                    method: "POST",
                    body: formData,
                    headers: {
                        // DO NOT manually set `Content-Type`, the browser will handle it
                    },
                };
            },
        }),
        getChartData: builder.mutation({
            query: (credentials) => ({
                url: `${SB_URL}/dashboard/get-chart-data`,
                method: "POST",
                body: { ...credentials }
            })
        })
    }),
});

export const {
    useUploadExcelFileMutation,
    useGetAllTableSkorBoardMutation,
    useGetTableDataMutation,
    useFilterTableDataSbMutation,
    useUpdateTableByNameMutation,
    useCreateComputedColumnMutation,
    useValidateDataMutation,
    useAppendDataMutation,
    useCreteUserReportSbMutation,
    useGetAllUserReportsSbMutation,
    useUpdateUserReportSbMutation,
    useGetReportByIdSbMutation,
    useCalculateExpressionMutation,
    useGetLocationsMutation,
    useGetFilteredTablesMutation,
    useGetReportTableDataMutation,
    useGetListViewDataMutation,
    useGetDistValuesMutation,
    useCreateTableMutation,
    useGetChartDataMutation,
    useConnectDataBaseMutation,
    useRemoveConnectionMutation,
    useGetAllDatabaseMutation,
    useFetchSchemaMutation,
    useFetchTablesMutation,
    useFetchTableMetadataMutation,
    useGetTableDataUsingQueryMutation,
    useAddDatatsetMutation
} = reportApiSlice;
