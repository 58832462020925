import { apiSlice } from "../../../app/api/apiSlice";

export const patientConstraintApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPatientConstraint: builder.mutation({
      query: (credentials) => ({
        url: `/patient/constraint/${credentials.patient_id}`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updatePatientConstraint: builder.mutation({
      query: (credentials) => ({
        url: `/patient/constraint/${credentials.id}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    deletePatientConstraint: builder.mutation({
      query: (credentials) => ({
        url: `/patient/constraint/${credentials.id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useCreatePatientConstraintMutation,
  useUpdatePatientConstraintMutation,
  useDeletePatientConstraintMutation,
} = patientConstraintApiSlice;
