import React, { useState } from "react";
import Logo from "../../../assets/images/CareSMS_Logo.png";
import SupportModal from "./SupportModal";

const Header = ({ setOpaque }) => {
  const [openSupport, setOpenSupport] = useState(false);
  const onSupport = () => {
    // setOpenSupport(true);
    // setOpaque && setOpaque(true);
    window.open("https://www.caresms.io/support", "_blank");
  };
  const onCloseSupport = () => {
    setOpenSupport(false);
    setOpaque && setOpaque(false);
  };
  return (
    <div className="flex justify-between mx-4 my-2 flex-row">
      <div>
        <img src={Logo} alt="logo" className="-ml-2 logo" />
      </div>
      <div className="self-end">
        <button
          className="py-2 font-semibold bg-white border-2 rounded-md shadow-sm border-black w-40 md:w-60"
          onClick={onSupport}
        >
          <span className="text-primarytextgrey">Request Support</span>
        </button>
      </div>
      {openSupport && <SupportModal onCloseSupport={onCloseSupport} />}
    </div>
  );
};

export default Header;
