import React, { useCallback, useState } from "react";
import ReactSimpleImageViewer from "react-simple-image-viewer";
import { avatarName } from "../../../utilities/resuseFunctions";
import LazyLoad from "react-lazy-load";
import { Avatar } from "@mui/material";
import {
  useUpdateUserMutation,
  useUploadProfilePicMutation,
} from "../../../features/user/userApiSlice";
import { useLSStore } from "../../../context/ls-store";
import { selectUser, updateUser } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";

function Profile() {
  const user = useSelector(selectUser);
  const [uploadProfilePic, { isLoading: uploadLoading }] =
    useUploadProfilePicMutation();
  const [updateUserApi, { isLoading: updateLoading }] = useUpdateUserMutation();
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const isLoading = uploadLoading || updateLoading;

  const setAlert = useLSStore((state) => state.setAlert);

  const dispatch = useDispatch();

  const openImageViewer = useCallback((index) => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };
  const onAddMedia = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg, .jpeg, .png";
    input.onchange = (_) => {
      let files = Array.from(input.files);
      if (files.length > 0) {
        const file = files[0];

        if (file.size > 2 * 1024 * 1024) {
          setAlert({
            message: "File size exceeds 2MB. Please select a smaller file.",
            severity: "error",
          });
          return;
        }
        const formData = new FormData();
        formData.append("file", file);
        uploadProfilePic(formData).then((res) => {
          const payload = {
            id: user.id,
            img: res?.data?.data?.url,
          };
          updateUserApi(payload);
          dispatch(
            updateUser({
              user: { ...user, img: res?.data?.data?.url },
            })
          );
        });
      }
    };
    input.click();
  };
  return (
    <div className="d-flex align-items-center">
      {isLoading && <Loading loading={isLoading} />}
      <LazyLoad>
        <Avatar
          className="my-3 acc-avatar"
          alt={avatarName(user.name)}
          src={user?.img}
          onClick={openImageViewer}
        >
          {avatarName(user.name)}
        </Avatar>
      </LazyLoad>
      {isViewerOpen && (
        <ReactSimpleImageViewer
          src={[user?.img]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
      <span
        className="mx-3 acc-change-profile text-primaryblue"
        role="button"
        onClick={onAddMedia}
      >
        Change profile picture
      </span>
    </div>
  );
}

export default Profile;
