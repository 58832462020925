import moment from "moment";

export const appointments = [
  {
    id: 1,
    name: "Alice Smith",
    community: "Tempe Community Home",
    phone: "(408) 432-7800",
    completed: true,
    mrn: "XXXXXXXXX",
    dob: "3/1/1972",
    gender: "M",
    age: 58,
    lastVisit: "January 12, 2023",
    appointmentDate: moment().format("MMMM DD, YYYY"),
    address: "1000 S Indiana Ave, Chicago, IL",
    distance: "0.2",
    appointmentType: "NEW PATIENT 45",
    appointmentTime: "10: 00 AM",
    region: "East Valley",
    pointOfContact: "Sage Nurse 1",
    coord: {
      latitude: 33.42491041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.02812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: 2,
    name: "Carolyn Johnson",
    community: "Phoenix Community Home",
    phone: "(408) 432-7800",
    completed: false,
    mrn: "XXXXXXXXX",
    dob: "3/1/1972",
    gender: "M",
    age: 58,
    lastVisit: "January 12, 2023",
    appointmentDate: moment().format("MMMM DD, YYYY"),
    address: "201 W Roosevelt Rd, Chicago, IL",
    distance: "1.5",
    appointmentType: "NEW PATIENT 45",
    appointmentTime: "12: 00 AM",
    urgent: true,
    region: "East Valley",
    pointOfContact: "Sage Nurse 25",
    coord: {
      latitude: 33.43491041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.04512240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: 3,
    name: "Samantha Jones",
    community: "Private Residence",
    phone: "(408) 432-7800",
    completed: false,
    mrn: "XXXXXXXXX",
    dob: "3/1/1972",
    gender: "F",
    age: 58,
    lastVisit: "January 12, 2023",
    appointmentDate: moment().format("MMMM DD, YYYY"),
    address: "201 W Roosevelt Rd, Chicago, IL",
    distance: "1.5",
    appointmentType: "NEW PATIENT 45",
    appointmentTime: "12: 00 AM",
    region: "East Valley",
    pointOfContact: "Maggie Smith",
    coord: {
      latitude: 33.44491041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.05812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: 4,
    name: "John Doe",
    community: "Private Residence",
    phone: "(408) 432-7800",
    completed: false,
    mrn: "XXXXXXXXX",
    dob: "3/1/1972",
    gender: "M",
    age: 58,
    lastVisit: "January 12, 2023",
    appointmentDate: moment().format("MMMM DD, YYYY"),
    address: "201 W Roosevelt Rd, Chicago, IL",
    distance: "1.5",
    appointmentType: "NEW PATIENT 45",
    appointmentTime: "12: 00 AM",
    region: "East Valley",
    pointOfContact: "Sage Nurse 71",
    coord: {
      latitude: 33.44591041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.04512240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: 5,
    name: "Alice Smith",
    community: "Tempe Community Home",
    phone: "(408) 432-7800",
    completed: false,
    mrn: "XXXXXXXXX",
    dob: "3/1/1972",
    gender: "M",
    age: 58,
    lastVisit: "January 12, 2023",
    appointmentDate: moment().format("MMMM DD, YYYY"),
    address: "1000 S Indiana Ave, Chicago, IL",
    distance: "0.2",
    appointmentType: "NEW PATIENT 45",
    appointmentTime: "10: 00 AM",
    region: "West Valley",
    pointOfContact: "Paul Jones",
    coord: {
      latitude: 40.69191041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.00812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: 6,
    name: "Carolyn Johnson",
    community: "Phoenix Community Home",
    phone: "(408) 432-7800",
    completed: false,
    mrn: "XXXXXXXXX",
    dob: "3/1/1972",
    gender: "M",
    age: 58,
    lastVisit: "January 12, 2023",
    appointmentDate: moment().format("MMMM DD, YYYY"),
    address: "201 W Roosevelt Rd, Chicago, IL",
    distance: "1.5",
    appointmentType: "NEW PATIENT 45",
    appointmentTime: "12: 00 AM",
    urgent: true,
    region: "West Valley",
    pointOfContact: "Sage Nurse 101",
    coord: {
      latitude: 40.69591041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.01512240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: 7,
    name: "Samantha Jones",
    community: "Private Residence",
    phone: "(408) 432-7800",
    completed: false,
    mrn: "XXXXXXXXX",
    dob: "3/1/1972",
    gender: "F",
    age: 58,
    lastVisit: "January 12, 2023",
    appointmentDate: moment().format("MMMM DD, YYYY"),
    address: "201 W Roosevelt Rd, Chicago, IL",
    distance: "1.5",
    appointmentType: "NEW PATIENT 45",
    appointmentTime: "12: 00 AM",
    region: "Central Valley",
    pointOfContact: "Sage Nurse 101",
    coord: {
      latitude: 37.83291041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -97.38512240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
];
export const providers = [
  {
    // id: "1023775061",
    id: "1700292877",
    firstName: "Jaeha",
    lastName: "Song",
    type: "Podiatry",
    // profileUrl:
    //   "https://images.unsplash.com/photo-1665080954352-5a12ef53017a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    phone: "(801) 915-6855",
    email: "song.dpm@gmail.com",
    windShieldTime: "27 minutes",
    distanceTraveled: "10.0 miles",
    visits: "11",
    completedVisits: "2",
    region: "East Valley",
    dob: "July 7, 1980",
    address: "6633 E Morningside Dr, Phoenix, AZ, 85054-6733",
    coord: {
      latitude: 33.44491041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.02812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: "1043699622",
    firstName: "Barbara",
    lastName: "Williams",
    type: "Family NP",
    // profileUrl:
    //   "https://images.unsplash.com/photo-1622253692010-333f2da6031d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80",
    phone: "602-697-1147",
    email: "barbara@medicalhomecall.com",
    windShieldTime: "27 minutes",
    distanceTraveled: "10.0 miles",
    visits: "11",
    completedVisits: "2",
    region: "East Valley",
    dob: "July 7, 1980",
    address: "20895 N 88th Ln, Peoria, AZ 85382-6430",
    coord: {
      latitude: 33.45491041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.04812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: "1396369401",
    firstName: "Emily",
    lastName: "Spano",
    type: "Cardiology",
    // profileUrl:
    //   "https://images.unsplash.com/photo-1605214178469-178b98bb29fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=436&q=80",
    phone: "623-229-9857",
    email: "emily@medicalhomecall.com",
    windShieldTime: "27 minutes",
    distanceTraveled: "10.0 miles",
    visits: "11",
    completedVisits: "2",
    region: "East Valley",
    dob: "July 7, 1980",
    address: "2318 E Mallard Ct, Gilbert, AZ 85234-3812",
    coord: {
      latitude: 33.43491041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.03812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: "1386227536",
    firstName: "Lauren",
    lastName: "Schriber",
    type: "Physician Assistant",
    // profileUrl:
    //   "https://images.unsplash.com/photo-1665080954352-5a12ef53017a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    phone: "504-710-8392",
    email: "lschriber93@gmail.com",
    windShieldTime: "27 minutes",
    distanceTraveled: "10.0 miles",
    visits: "11",
    completedVisits: "2",
    region: "West Valley",
    dob: "July 7, 1980",
    address: "4509 Lake Borgne Ave, Metairie, AZ 70006",
    coord: {
      latitude: 40.69491041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.02812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: "1700292878",
    firstName: "Jaeha",
    lastName: "Song",
    type: "Podiatry",
    // profileUrl:
    //   "https://plus.unsplash.com/premium_photo-1661281308844-58c3a8d51447?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    phone: "801-915-6855",
    email: "song.dpm@gmail.com",
    windShieldTime: "27 minutes",
    distanceTraveled: "10.0 miles",
    visits: "11",
    completedVisits: "2",
    region: "West Valley",
    dob: "July 7, 1980",
    address: "6633 E Morningside Dr, Phoenix, AZ 85054-6733",
    coord: {
      latitude: 40.68491041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -112.01812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    id: "1891279907",
    firstName: "Denise",
    lastName: "Gomez",
    type: "Nurse Practitioner",
    // profileUrl:
    //   "https://images.unsplash.com/photo-1639154968821-6dbc3efb8d23?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    phone: "347-325-2472",
    email: "Denise@medicalhomecare.com",
    windShieldTime: "27 minutes",
    distanceTraveled: "10.0 miles",
    visits: "11",
    completedVisits: "2",
    region: "Central Valley",
    dob: "July 7, 1980",
    address: "13851 W Blossom Way, Litchfield Park, AZ 85340-4262",
    coord: {
      latitude: 37.832391041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -97.372012240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
];

export const markers = [
  {
    title: "title 1",
    description: "description 1",
    coord: {
      latitude: 37.76991041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -122.42812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    title: "title 2",
    description: "description 2",
    coord: {
      latitude: 37.753670432765746,
      latitudeDelta: 0.015000707274857916,
      longitude: -122.43004554882644,
      longitudeDelta: 0.018980316817760468,
    },
  },
  {
    title: "title 3",
    description: "description 3",
    coord: {
      latitude: 37.723563381307296,
      latitudeDelta: 0.0150007290022387,
      longitude: -122.42143766954541,
      longitudeDelta: 0.01898031681778889,
    },
  },
];
export const visits = [
  {
    title: "title 1",
    description: "description 1",
    coord: {
      latitude: 37.78991041689911,
      latitudeDelta: 0.014999440703050482,
      longitude: -122.42812240496278,
      longitudeDelta: 0.01898031681777468,
    },
  },
  {
    title: "title 2",
    description: "description 2",
    coord: {
      latitude: 37.743670432765746,
      latitudeDelta: 0.015000707274857916,
      longitude: -122.43004554882644,
      longitudeDelta: 0.018980316817760468,
    },
  },
  {
    title: "title 3",
    description: "description 3",
    coord: {
      latitude: 37.713563381307296,
      latitudeDelta: 0.0150007290022387,
      longitude: -122.42143766954541,
      longitudeDelta: 0.01898031681778889,
    },
  },
];

export const eligibleVisits = [
  {
    AppointmentID: null,
    Distance: 2.584,
    DistanceFromHome: null,
    Facility: "Private Home",
    ID: 237,
    LastVisitDate: "2023-04-20T00:00:00.000Z",
    PatientAddress: "1167 E Benrich Dr Gilbert AZ",
    PatientID: "50747",
    PatientLatitude: 33.295424,
    PatientLongitude: -111.76496,
    PatientName: "Gary Malaela",
    ProviderName: "ASHLEY MUENZ",
    ProviderSpecialty: "Family Medicine",
    Rotation: 14,
    SequenceNo: 1,
    Slack: -34,
    TargetVisitDate: "2023-05-04T00:00:00.000Z",
    VisitDate: "2023-06-07T00:00:00.000Z",
    VisitType: "Primary Follow up",
    isCanceled: false,
    isCompleted: false,
    isConfirmed: false,
  },
  // {
  //   id: 8,
  //   name: "Oprah Jeffrey",
  //   community: "Sage Community Home",
  //   distance: "0.0",
  //   from: "4",
  //   on: "2/28/23",
  //   phone: "(408) 432-7800",
  //   completed: false,
  //   mrn: "XXXXXXXXX",
  //   dob: "3/1/1972",
  //   gender: "F",
  //   address: "201 W Roosevelt Rd, Chicago, IL",
  //   appointmentType: "NEW PATIENT 45",
  //   appointmentTime: "12: 00 AM",
  //   // urgent: true,
  //   region: "West Valley",
  //   coord: {
  //     latitude: 40.69491041689911,
  //     latitudeDelta: 0.014999440703050482,
  //     longitude: -112.01412240496278,
  //     longitudeDelta: 0.01898031681777468,
  //   },
  // },
  // {
  //   id: 9,
  //   name: "Tony Soprano",
  //   community: "Private Residence",
  //   distance: "1.0",
  //   from: "6",
  //   on: "2/28/23",
  //   phone: "(408) 432-7800",
  //   completed: false,
  //   mrn: "XXXXXXXXX",
  //   dob: "3/1/1972",
  //   gender: "F",
  //   address: "201 W Roosevelt Rd, Chicago, IL",
  //   appointmentType: "NEW PATIENT 45",
  //   appointmentTime: "12: 00 AM",
  //   // urgent: true,
  //   region: "West Valley",
  //   coord: {
  //     latitude: 40.69391041689911,
  //     latitudeDelta: 0.014999440703050482,
  //     longitude: -112.01212240496278,
  //     longitudeDelta: 0.01898031681777468,
  //   },
  // },
  // {
  //   id: 10,
  //   name: "Tim Yang",
  //   community: "Sun City Community Home",
  //   distance: "0.7",
  //   from: "8",
  //   on: "2/28/23",
  //   phone: "(408) 432-7800",
  //   completed: false,
  //   mrn: "XXXXXXXXX",
  //   dob: "3/1/1972",
  //   gender: "F",
  //   address: "201 W Roosevelt Rd, Chicago, IL",
  //   appointmentType: "NEW PATIENT 45",
  //   appointmentTime: "12: 00 AM",
  //   // urgent: true,
  //   region: "Central Valley",
  //   coord: {
  //     latitude: 37.83691041689911,
  //     latitudeDelta: 0.014999440703050482,
  //     longitude: -97.38412240496278,
  //     longitudeDelta: 0.01898031681777468,
  //   },
  // },
];

export const regions = [
  {
    id: 1,
    name: "East Valley",
    dataSource: "Athena EHR",
    coords: {
      latitude: 33.4484,
      longitude: -112.074,
      latitudeDelta: 0.075,
      longitudeDelta: 0.0721,
    },
  },
  {
    id: 2,
    name: "West Valley",
    dataSource: "Athena EHR",
    coords: {
      latitude: 40.6916,
      longitude: -112.0011,
      latitudeDelta: 0.075,
      longitudeDelta: 0.0721,
    },
  },
  {
    id: 3,
    name: "Central Valley",
    dataSource: "Athena EHR",
    coords: {
      latitude: 37.8323,
      longitude: -97.372,
      latitudeDelta: 0.075,
      longitudeDelta: 0.0721,
    },
  },
];

export const chats = [
  {
    id: 1,
    profileUrl:
      "https://images.unsplash.com/photo-1665080954352-5a12ef53017a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    name: "Oprah Winfrey Chat",
    time: "1:10 PM",
    mentions: ["Dr.Robert Smith", "Sun City Nurse"],
    message:
      "@Dr.Robert Smith @Sun City Nurse please let us know if a refill is required",
    replies: 98,
    isLastMessaged: true,
    isSent: false,
    isGroup: true,
    members: ["Sun City Nurse", "Dr. Robert Smith", "You"],
    // tagName: ["Oprah Winfrey"],
    mrn: "XXXXXXXXX",
    age: "58",
    gender: "F",
    messages: [
      {
        id: 1,
        name: "Dr.Robert Smith",
        message: "I’ll be at Sun City Community in 30 minutes.",
        time: "12:30 PM",
        isMe: false,
      },
      {
        id: 2,
        name: "Sun City Nurse",
        message: "Great, she’s ready for the appointment",
        time: "12:37 PM",
        isMe: false,
      },
      {
        id: 3,
        // name: 'Dr.Robert Smith',
        mentions: ["Dr. Robert Smith", "Sun City Nurse"],
        message:
          "@Dr.Robert_Smith @Sun_City_Nurse please let us know if a refill is required",
        time: "1:10 PM",
        isMe: true,
      },
    ],
  },
  {
    id: 2,
    profileUrl:
      "https://images.unsplash.com/photo-1527613426441-4da17471b66d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80",
    name: "Sage Nurse 9",
    time: "12:10 PM",
    mentions: ["sage"],
    tags: ["Tony Soprano"],
    hashTags: ["refill"],
    message: "@sage <Tony_Soprano> #refill lisinopril",
    replies: 1,
    isLastMessaged: false,
    isSent: true,
    isGroup: false,
    messages: [
      {
        id: 1,
        name: "Sage Nurse 9",
        mentions: ["sage"],
        tags: ["Tony Soprano"],
        hashTags: ["refill"],
        message: "@sage <Tony_Soprano> #refill lisinopril",
        time: "12:30 PM",
        isMe: false,
      },
    ],
  },
  {
    id: 3,
    profileUrl:
      "https://images.unsplash.com/photo-1559839734-2b71ea197ec2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    name: "Sage Nurse 3",
    time: "11:55 AM",
    mentions: ["sage"],
    tags: ["Anne West"],
    hashTags: ["refill"],
    message: "@sage <Anne_West> #refill levothyroxine",
    replies: 2,
    isLastMessaged: false,
    isSent: true,
    isGroup: false,
    messages: [
      {
        id: 1,
        name: "Sage Nurse 3",
        mentions: ["sage"],
        tags: ["Anne West"],
        hashTags: ["refill"],
        message: "@sage <Anne_West> #refill levothyroxine",
        time: "1:10 PM",
        isMe: false,
      },
    ],
  },
  {
    id: 4,
    profileUrl:
      "https://images.unsplash.com/photo-1559839734-2b71ea197ec2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    name: "Sage Nurse 23",
    time: "11:15 AM",
    mentions: ["sage"],
    tags: ["Tim Allen"],
    hashTags: ["urgentvisit", "fall"],
    message: "@sage <Tim Allen> needs an #urgentvisit due to a #fall",
    replies: 2,
    isLastMessaged: false,
    isSent: false,
    isGroup: false,
    messages: [
      {
        id: 1,
        name: "Sage Nurse 23",
        mentions: ["sage"],
        tags: ["Tim Allen"],
        hashTags: ["urgentvisit", "fall"],
        message: "@sage <Tim Allen> needs an #urgentvisit due to a #fall",
        time: "11:15 AM",
        isMe: false,
      },
    ],
  },
];

export const timeframes = [
  {
    id: 1,
    label: "Within 24 hours",
  },
  {
    id: 2,
    label: "Within 48 hours",
  },
];

export const communities = [
  {
    id: 1,
    name: "Arizona Senior Home",
    zone: "East Valley",
    address: "111 Sun City Road, Phoenix, AZ 40808",
    phone: "(408) 312 2300",
    email: "admin@arizonahome.com",
    patients: [],
    staff: [],
  },
  {
    id: 2,
    name: "Arizona Senior Home",
    zone: "East Valley",
    address: "111 Sun City Road, Phoenix, AZ 40808",
    phone: "(408) 312 2300",
    email: "admin@arizonahome.com",
    patients: [],
    staff: [],
  },
  {
    id: 3,
    name: "Arizona Senior Home",
    zone: "East Valley",
    address: "111 Sun City Road, Phoenix, AZ 40808",
    phone: "(408) 312 2300",
    email: "admin@arizonahome.com",
    patients: [],
    staff: [],
  },
  {
    id: 4,
    name: "Arizona Senior Home",
    zone: "East Valley",
    address: "111 Sun City Road, Phoenix, AZ 40808",
    phone: "(408) 312 2300",
    email: "admin@arizonahome.com",
    patients: [],
    staff: [],
  },
  {
    id: 5,
    name: "Arizona Senior Home",
    zone: "East Valley",
    address: "111 Sun City Road, Phoenix, AZ 40808",
    phone: "(408) 312 2300",
    email: "admin@arizonahome.com",
    patients: [],
    staff: [],
  },
  {
    id: 6,
    name: "Arizona Senior Home",
    zone: "East Valley",
    address: "111 Sun City Road, Phoenix, AZ 40808",
    phone: "(408) 312 2300",
    email: "admin@arizonahome.com",
    patients: [],
    staff: [],
  },
  {
    id: 7,
    name: "Arizona Senior Home",
    zone: "East Valley",
    address: "111 Sun City Road, Phoenix, AZ 40808",
    phone: "(408) 312 2300",
    email: "admin@arizonahome.com",
    patients: [],
    staff: [],
  },
];

export const communityHeader = [
  {
    label: "NAME",
    dataKey: "name",
    // width: 200,
    dataKeyPriority: "nameSort",
    isSort: true,
  },
  {
    label: "ZONE",
    dataKey: "zone",
    // width: 200,
    isSort: true,
  },
  {
    label: "ADDRESS",
    dataKey: "address",
    // width: 200,
    isSort: true,
  },
  {
    label: "CONTACT",
    dataKey: "phone",
    // width: 200,
    isSort: true,
  },
  {
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
];

export const patientHeader = [
  {
    id: 1,
    label: "NAME",
    dataKey: "name",
    dataKeyPriority: "nameSort",
    // width: 200,
    isSort: true,
  },
  {
    id: 2,
    label: "ZONE",
    dataKey: "region",
    // width: 200,
    isSort: true,
  },
  {
    id: 3,
    label: "LOCATION",
    dataKey: "community",
    // width: 200,
    isSort: true,
  },
  {
    id: 4,
    label: "LAST VISIT DATE",
    dataKey: "lastVisitDate",
    // width: 200,
    isSort: true,
  },
  {
    id: 5,
    label: "LAST VISIT TYPE",
    dataKey: "lastVisitType",
    // width: 200,
    isSort: true,
  },
  // {
  //   id: 6,
  //   label: "EDIT",
  //   dataKey: "edit",
  //   width: 100,
  // },
];

export const providerHeader = [
  {
    id: 1,
    label: "NAME",
    dataKey: "name",
    dataKeyPriority: "nameSort",
    // width: 200,
    isSort: true,
  },
  {
    id: 2,
    label: "TYPE",
    dataKey: "type",
    // width: 200,
    isSort: true,
  },
  {
    id: 3,
    label: "ZONE",
    dataKey: "region",
    // width: 200,
    isSort: true,
  },
  {
    id: 4,
    label: "CONTACT",
    dataKey: "phone",
    // width: 200,
    isSort: true,
  },
  {
    id: 5,
    label: "EMAIL",
    dataKey: "email",
    // width: 250,
    isSort: true,
  },
  {
    id: 6,
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
  {
    id: 7,
    label: "DELETE",
    dataKey: "delete",
    width: 100,
  },
];

export const regionsHeader = [
  {
    id: 1,
    label: "NAME",
    dataKey: "name",
    dataKeyPriority: "nameSort",
    // width: 200,
    isSort: true,
  },
  // {
  //   id: 2,
  //   label: "Zip Codes",
  //   dataKey: "ZoneZipCodes",
  //   // width: 200,
  // },
  // {
  //   id: 2,
  //   label: "LATITUDE",
  //   dataKey: "latitude",
  //   // width: 200,
  //   isSort: true,
  // },
  // {
  //   id: 3,
  //   label: "LONGITUDE",
  //   dataKey: "longitude",
  //   // width: 200,
  //   isSort: true,
  // },
  {
    id: 4,
    label: "DATA SOURCE",
    dataKey: "dataSource",
    // width: 200,
    isSort: true,
  },
  {
    id: 5,
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
  {
    id: 6,
    label: "DELETE",
    dataKey: "delete",
    width: 100,
  },
];

export const tagHeader = [
  {
    id: 1,
    label: "# TAG",
    dataKey: "tag",
    // width: 300,
    dataKeyPriority: "tagSort",
    isSort: true,
  },
  {
    id: 2,
    label: "DESCRIPTION",
    dataKey: "description",
    // width: 300,
    isSort: true,
  },
  {
    id: 3,
    label: "CREATED AT",
    dataKey: "createdAt",
    // width: 100,
    isSort: true,
  },
  {
    id: 4,
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
  {
    id: 5,
    label: "DELETE",
    dataKey: "delete",
    width: 100,
  },
];

export const tagsData = [
  {
    id: 1,
    tag: "#refill",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    createdAt: "3/1/23",
  },
  {
    id: 2,
    tag: "#fall",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    createdAt: "2/14/23",
  },
  {
    id: 3,
    tag: "#urgent",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    createdAt: "2/14/23",
  },
  {
    id: 4,
    tag: "#priorityvisit",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    createdAt: "2/14/23",
  },
];

export const staffHeader = [
  {
    id: 1,
    label: "NAME",
    dataKey: "name",
    dataKeyPriority: "nameSort",
    // width: 200,
    isSort: true,
  },
  {
    id: 4,
    label: "ROLE",
    dataKey: "role",
    // width: 200,
  },
  {
    id: 2,
    label: "PHONE",
    dataKey: "phone",
    // width: 200,
    isSort: true,
  },
  {
    id: 3,
    label: "EMAIL",
    dataKey: "email",
    // width: 100,
    isSort: true,
  },
  {
    id: 4,
    label: "INVITE",
    dataKey: "invite",
    width: 100,
  },
  {
    id: 5,
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
  {
    id: 6,
    label: "DELETE",
    dataKey: "delete",
    width: 100,
  },
];

export const staffData = [
  {
    id: 1,
    name: "Sage Nurse 1",
    phone: "(408) 200-4800",
    email: "nurse1@medicalhome.com",
    password: "12345678",
  },
  {
    id: 2,
    name: "Sage Nurse 2",
    phone: "(408) 200-4800",
    email: "nurse1@medicalhome.com",
    password: "12345678",
  },
  {
    id: 3,
    name: "Sage Nurse 3",
    phone: "(408) 200-4800",
    email: "nurse1@medicalhome.com",
    password: "12345678",
  },
  {
    id: 4,
    name: "Sage Nurse 4",
    phone: "(408) 200-4800",
    email: "nurse1@medicalhome.com",
    password: "12345678",
  },
];

export const usersHeader = [
  {
    id: 1,
    label: "NAME",
    dataKey: "name",
    // width: 200,
    isSort: true,
    dataKeyPriority: "nameSort",
  },
  {
    id: 2,
    label: "POSITION",
    dataKey: "position",
    // width: 200,
    isSort: true,
  },
  {
    id: 3,
    label: "CONTACT",
    dataKey: "phone",
    // width: 200,
    isSort: true,
  },
  {
    id: 4,
    label: "EMAIL",
    dataKey: "email",
    // width: 200,
    isSort: true,
  },
  {
    id: 5,
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
  {
    id: 6,
    label: "DELETE",
    dataKey: "delete",
    width: 100,
  },
];

export const usersData = [
  {
    id: 1,
    name: "Valerie Kenworthy",
    firstName: "Valerie",
    lastName: "Kenworthy",
    type: "Admin",
    phone: "(408) 200-4800",
    email: "val@medicalhomecare.com",
    postToAthena: "yes",
  },
  {
    id: 2,
    name: "Jane Doe",
    firstName: "Jane",
    lastName: "Doe",
    type: "Scheduler",
    phone: "(408) 200-4800",
    email: "janedoe@medicalhome.com",
    postToAthena: "no",
  },
  {
    id: 3,
    name: "Jim Smith",
    firstName: "Jim",
    lastName: "Smith",
    type: "Scheduler",
    phone: "(408) 200-4800",
    email: "jim@medicalhome.com",
    postToAthena: "no",
  },
  {
    id: 4,
    name: "Robert Smith",
    firstName: "Robert",
    lastName: "Smith",
    type: "Scheduler",
    phone: "(408) 200-4800",
    email: "robert@medicalhome.com",
    postToAthena: "no",
  },
];

export const providersCategory = [
  { name: "All Providers", value: "" },
  { name: "Family Medicine", value: "Family Medicine" },
  { name: "Cardiologists", value: "Cardiology" },
  { name: "Podiatrists", value: "Podiatry" },
  { name: "Psychiatrists", value: "Psychology" },
];

export const schedulePolicyHeader = [
  {
    id: 1,
    label: "Provider",
    dataKey: "provider",
    // width: 200,
    isSort: true,
  },
  {
    id: 2,
    label: "Provider Type",
    dataKey: "providerType",
    // width: 200,
    isSort: true,
  },
  {
    id: 3,
    label: "Monthly Visits",
    dataKey: "visits",
    // width: 200,
    isSort: true,
  },
  {
    id: 4,
    label: "Working Days",
    dataKey: "workingDays",
    // width: 200,
    isSort: true,
  },
  {
    id: 5,
    label: "PTO",
    dataKey: "pto",
    // width: 200,
    isSort: true,
  },
  {
    id: 6,
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
  {
    id: 7,
    label: "DELETE",
    dataKey: "delete",
    width: 100,
  },
];

export const schedulePolicyData = [
  {
    id: 1,
    provider: "Karen Sheridan",
    providerType: "Family NP",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 2,
    provider: "Barbara Williams",
    providerType: "Family NP",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 3,
    provider: "Kim Brands",
    providerType: "Family NP",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 4,
    provider: "Hortense Noble",
    providerType: "Nurse Practitioner",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 5,
    provider: "Valerie Scott",
    providerType: "Family NP",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 6,
    provider: "Ashley Muenz",
    providerType: "Family NP",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 7,
    provider: "Martha La Marre",
    providerType: "Psychiatry PA",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 8,
    provider: "Scott Butler",
    providerType: "Nurse Practitioner",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 9,
    provider: "Lauren Schriber",
    providerType: "Physician Assistant",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 10,
    provider: "Emily Spano",
    providerType: "Cardiology",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 11,
    provider: "Kathleen Lupone",
    providerType: "Family NP",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 12,
    provider: "Jordi Livi",
    providerType: "Family MD",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 13,
    provider: "Ashley Archer",
    providerType: "Family NP",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 14,
    provider: "Wilhelmina Sagoe-Annor",
    providerType: "Cardiology",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
  {
    id: 15,
    provider: "Jaeha Song",
    providerType: "Podiatry",
    visits: 200,
    workingDays: ["Monday - Friday"],
    pto: ["Thu Apr 20 2023"],
  },
];

export const scheduleRulesHeader = [
  {
    id: 1,
    label: "Provider",
    dataKey: "provider",
    dataKeyPriority: "providerSort",
    // width: 200,
    isSort: true,
  },
  {
    id: 2,
    label: "Location Type",
    dataKey: "locationType",
    // width: 200,
    isSort: true,
  },
  {
    id: 3,
    label: "Location Name",
    dataKey: "locationName",
    // width: 200,
    isSort: true,
  },
  {
    id: 4,
    label: "Day of the Week",
    dataKey: "dayOfWeek",
    // width: 200,
    isSort: true,
    custom_sort: "weekday",
  },
  {
    id: 9,
    label: "Max Visits",
    dataKey: "maxVisits",
    // width: 200,
    isSort: true,
  },
  {
    id: 5,
    label: "Additional Rules",
    dataKey: "additionalRules",
    // width: 200,
    isSort: true,
  },
  {
    id: 6,
    label: "Date Range",
    dataKey: "dateRange",
    // width: 200,
    isSort: true,
  },
  {
    id: 7,
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
  {
    id: 8,
    label: "DELETE",
    dataKey: "delete",
    width: 100,
  },
];

export const pointofContactHeader = [
  {
    id: 1,
    label: "NAME",
    dataKey: "name",
    // width: 200,
  },
  {
    id: 2,
    label: "CELL NUMBER",
    dataKey: "contactCellNumber",
    // width: 200,
  },
  {
    id: 3,
    label: "HOME NUMBER",
    dataKey: "contactHomeNumber",
    // width: 200,
  },
  {
    id: 4,
    label: "WORK NUMBER",
    dataKey: "contactWorkNumber",
    // width: 200,
  },
  {
    id: 5,
    label: "EMAIL",
    dataKey: "contactEmail",
    // width: 200,
  },
  {
    id: 6,
    label: "CATEGORY",
    dataKey: "contactCategory",
    // width: 200,
  },
  {
    id: 7,
    label: "Type",
    dataKey: "contactType",
    // width: 200,
  },
  {
    id: 8,
    label: "EDIT",
    dataKey: "edit",
    width: 100,
  },
];

export const visitstHeader = [
  {
    id: 6,
    label: "",
    dataKey: "check",
    // width: 200,
  },
  {
    id: 1,
    label: "NAME",
    dataKey: "name",
    // width: 200,
  },
  {
    id: 2,
    label: "Mobile Number",
    dataKey: "phone",
    // width: 200,
  },
  {
    id: 3,
    label: "Community",
    dataKey: "community",
    // width: 200,
  },
  {
    id: 4,
    label: "Distance",
    dataKey: "distance",
    // width: 200,
  },
  // {
  //   id: 5,
  //   label: "Scheduled",
  //   dataKey: "scheduled",
  //   // width: 200,
  // },
];

export const latlong = [
  {
    latitude: 33.44491041689911,
    latitudeDelta: 0.014999440703050482,
    longitude: -112.02812240496278,
    longitudeDelta: 0.01898031681777468,
  },
  {
    latitude: 33.45491041689911,
    latitudeDelta: 0.014999440703050482,
    longitude: -112.04812240496278,
    longitudeDelta: 0.01898031681777468,
  },
  {
    latitude: 33.43491041689911,
    latitudeDelta: 0.014999440703050482,
    longitude: -112.03812240496278,
    longitudeDelta: 0.01898031681777468,
  },
  {
    latitude: 40.69491041689911,
    latitudeDelta: 0.014999440703050482,
    longitude: -112.02812240496278,
    longitudeDelta: 0.01898031681777468,
  },
  {
    latitude: 40.68491041689911,
    latitudeDelta: 0.014999440703050482,
    longitude: -112.01812240496278,
    longitudeDelta: 0.01898031681777468,
  },
  {
    latitude: 37.832391041689911,
    latitudeDelta: 0.014999440703050482,
    longitude: -97.372012240496278,
    longitudeDelta: 0.01898031681777468,
  },
];
