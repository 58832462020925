import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";

const AddDocumentModal = ({ open: isOpen, handleClose, handleSubmit }) => {
  const [open, setOpen] = useState(isOpen);
  const [edit, setEdit] = useState({});

  const onChangeHandler = (val, name) => {
    setEdit({
      ...edit,
      [name]: val,
    });
  };

  const onClose = () => {
    setOpen(true);
    handleClose();
    setEdit({});
  };

  const onSubmit = () => {
    handleSubmit(edit);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid>
        <Grid
          className="d-flex justify-content-between py-1 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <Typography className="region-modal-title">
            {"Add Document Modal"}
          </Typography>
          <Tooltip title="Close">
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid className="px-2">
          {/* <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Subject</Typography>
            <Form.Control
              placeholder="Subject"
              className="inputField px-2 border-1 w-100"
              value={edit?.subject}
              onChange={(val) => onChangeHandler(val.target.value, "subject")}
            />
          </Grid> */}
          <Grid className="m-2">
            <Typography className="fieldName px-1 py-2">
              Document Subclass
            </Typography>

            <Grid className="inputField px-3">
              <Form.Select
                aria-label="Default select example"
                value={edit?.documentsubclass}
                onChange={(val) =>
                  onChangeHandler(val.target.value, "documentsubclass")
                }
              >
                <option>Select Document Subclass</option>
                <option value="CLINICALDOCUMENT">Clinical Document</option>
                <option value="ADMISSIONDISCHARGE">Admission Discharge</option>
                <option value="CONSULTNOTE">Consult Note</option>
                <option value="MENTALHEALTH">Mental Health</option>
                <option value="OPERATIVENOTE">Operative Note</option>
                <option value="URGENTCARE">Urgent Care</option>
              </Form.Select>
            </Grid>
          </Grid>
          <Grid className=" m-2">
            <Typography className="fieldName px-1 py-2">
              Internal Note
            </Typography>
            <Grid className="d-flex px-3">
              <Form.Control
                placeholder="Internal Note"
                className="inputField px-2 border-1 w-100"
                value={edit?.internalnote}
                onChange={(val) =>
                  onChangeHandler(val.target.value, "internalnote")
                }
              />
            </Grid>
          </Grid>
          <Grid className="d-flex justify-content-end mb-2">
            <CustomButton className="button mx-4 p-1 px-4" onClick={onSubmit}>
              <Typography>Next</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AddDocumentModal;
