import React from "react";

const BoldText = ({ text, color }) => {
  const t = text?.split("(");
  const [t1, t2] = t;
  return (
    <span
      className="font-14"
      style={{ fontWeight: 600, color: color || "black" }}
    >
      &nbsp;{t1}
      {t2 && <span style={{ fontSize: 12, paddingLeft: 5 }}>({t2}</span>}
      &nbsp;
    </span>
  );
};

const BoldString = ({ text, indexGroups, textColor }) => {
  let renderedText = [];
  let renderedText1 = [];
  const textL = text.length;

  for (let i = 0; i < indexGroups.length; i++) {
    const { startIndex, endIndex } = indexGroups[i];
    const prefix = text.slice(0, startIndex - (textL - text.length));
    const boldText = text.slice(
      startIndex - (textL - text.length),
      endIndex + 1 - (textL - text.length)
    );
    renderedText.push(
      <span className="font-14" key={i} style={{ color: textColor || "#000" }}>
        {prefix?.split(" ")?.map((pre, idx) => {
          if (!pre?.includes("#")) return pre + " ";
          return <BoldText key={idx} text={pre} color={textColor || "#000"} />;
        })}
      </span>
    );

    renderedText.push(
      <BoldText
        key={`bold-${i}`}
        text={boldText}
        color={textColor || indexGroups[i].color}
      />
    );
    renderedText1.push(boldText);
    text = text.slice(endIndex + 1 - (textL - text.length));
  }

  if (text.length > 0) {
    renderedText.push(
      <span
        className="font-14"
        key={indexGroups.length}
        style={{ color: textColor || "#000" }}
      >
        {/* {text} */}
        {text?.split(" ")?.map((pre, idx) => {
          if (!pre?.includes("#")) return pre + " ";
          return <BoldText key={idx} text={pre} color={textColor || "#000"} />;
        })}
      </span>
    );
    renderedText1.push(text);
  }
  return (
    <span style={{ wordWrap: "break-word", paddingRight: 15 }}>
      {renderedText}
    </span>
  );
};

export default BoldString;
