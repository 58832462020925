import React from "react";
import RadioIcon from "../../assets/images/RadioIcon.png";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import { avatarName } from "../../utilities/resuseFunctions";
import "./ContactCard.css";
import RadioInactive from "../../assets/svgs/RadioInactive";

const ContactCard = ({
  label,
  subLabel,
  subLabel2,
  profile,
  icon,
  isSelected,
  isSelectable,
  onSelect = () => {},
}) => {
  return (
    <Button
      className="d-flex align-items-center px-2 w-100 text-capitalize justify-content-start"
      onClick={onSelect}
    >
      {isSelectable &&
        (isSelected ? (
          <Grid className="mx-2">
            <img
              src={RadioIcon}
              alt="radio"
              style={{ width: 18, height: 18 }}
            />
          </Grid>
        ) : (
          <Grid className="mx-2">
            <RadioInactive color="#ccc" />
          </Grid>
        ))}
      <Avatar className="my-2 mx-2" src={profile}>
        {label && avatarName(label)}
      </Avatar>
      <Grid className="w-100 d-grid justify-content-start ">
        <Typography
          style={{ textAlign: "left" }}
          className="text-black text-left contact-title"
        >
          {label}
        </Typography>
        {subLabel && (
          <Typography className="text-start" style={{ color: "#000000B2" }}>
            {subLabel}
          </Typography>
        )}
        {subLabel2 && (
          <Typography className="text-start" style={{ color: "#000000B2" }}>
            {subLabel2}
          </Typography>
        )}
      </Grid>
    </Button>
  );
};

export default ContactCard;
