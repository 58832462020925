import React, { useEffect } from 'react'

function Nav({
    isTableTool,
    setActiveNavItem,
    activeNavItem,
    setSelectedColumn,
    selectedColumn,
    isReportBuilder,
    superset,
    isDashboardBuilder
}) {
    const navItems = ["File", !isDashboardBuilder && "Home", !isReportBuilder && !isDashboardBuilder && "Database Connections", isTableTool && "Table Tools", selectedColumn && "Column Tools",]
    const handleItemClick = (item) => {
        if (!isReportBuilder) {
            superset?.setIsDatasetView(false);
            if (item === 'Database Connections') {
                superset?.setIsDatabaseView(true);
            } else {
                superset?.setIsDatabaseView(false);
            }
        }
        setActiveNavItem(item);

    };

    useEffect(() => {
        if (selectedColumn) {
            setActiveNavItem("Column Tools"); // Activate "Column Tools" if a column is selected
        } else if (isTableTool) {
            setActiveNavItem("Table Tools"); // Activate "Table Tools" if table tools are enabled
        } else {
            isReportBuilder || isDashboardBuilder ? setActiveNavItem("File") : setActiveNavItem("Home") // Default to "Home"
        }
    }, [isTableTool, selectedColumn]);
    // useEffect(() => {
    //     if (isTableTool) {
    //         setActiveNavItem("Table Tools")
    //     }
    // }, [isTableTool]);
    return (
        <div className='w-full bg-[#ECF4FA] pb-[6px] pl-2 space-x-5 flex items-start pt-1'>
            {navItems?.map((item, index) => (
                <span
                    key={index}
                    onClick={() => handleItemClick(item)}
                    className={`text-xs font-semibold cursor-pointer ${activeNavItem === item ? 'border-b-2 border-[#075985] text-[#075985]' : ''
                        }`}
                >
                    {item}
                </span>
            ))}
        </div>
    )
}

export default Nav;
