import { update } from "react-spring";
import { apiSlice } from "../../app/api/apiSlice";

export const zoneApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createZone: builder.mutation({
      query: (credentials) => ({
        url: `/zone/`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updateZone: builder.mutation({
      query: ({ id, ...credentials }) => ({
        url: `/zone/${id}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    fetchZones: builder.mutation({
      query: (credentials) => ({
        url: `/zone/`,
        method: "GET",
      }),
    }),
    fetchZoneProviders: builder.mutation({
      query: ({ id }) => ({
        url: `/zone/${id}/providers`,
        method: "GET",
      }),
    }),
    fetchZonePatients: builder.mutation({
      query: ({ id }) => ({
        url: `/zone/${id}/patients`,
        method: "GET",
      }),
    }),
    fetchZoneCommunities: builder.mutation({
      query: ({ id }) => ({
        url: `/zone/${id}/communities`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateZoneMutation,
  useUpdateZoneMutation,
  useFetchZonesMutation,
  useFetchZoneProvidersMutation,
  useFetchZonePatientsMutation,
  useFetchZoneCommunitiesMutation,
} = zoneApiSlice;
