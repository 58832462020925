import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { mediaOptions } from "../../data/listOptions";
import { useLSStore } from "../../context/ls-store";

const AddMedia = ({
  isFullScreen,
  setFile,
  setDocumentOpen,
  setFileType,
  isEditOption,
  isGroup,
  handleSubmit,
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const setAlert = useLSStore((state) => state.setAlert);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onAddMedia = ({ accepts }) => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = accepts;
    input.onchange = (_) => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);
      if (files.length > 0) {
        const file = files[0];
        if (file.size > 5 * 1024 * 1024) {
          setAlert({
            message: "File size should not exceed 5MB",
            severity: "error",
          });
          return;
        }
        // console.log(files);
        setFile(files[0]);
        if (isGroup) {
          setDocumentOpen(true);
        } else {
          handleSubmit();
        }
      }
    };
    input.click();
  };
  return (
    <div>
      <Tooltip title="Add Attachment">
        <IconButton className="ml-2" onClick={handleClick}>
          <AddOutlinedIcon
            style={{
              color: isFullScreen ? "#000" : "#fff",
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        className="w-100"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {mediaOptions
          ?.filter((x) =>
            x.id === 1 || x.id === 3
              ? true
              : isEditOption === true
              ? false
              : true
          )
          ?.map((item, i) => {
            const Icon = item.icon;
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  if (item?.id === 1) {
                    onAddMedia({ accepts: ".jpg, .png, .jpeg" });
                    setFileType("image");
                  }
                  if (item?.id === 2) {
                    onAddMedia({ accepts: ".pdf, .doc, .docx, .xlsx, .csv" });
                    setFileType("doc");
                  }
                  handleClose();
                }}
                style={{ color: item.color }}
              >
                <Icon className="mx-2" />{" "}
                <span className="mx-2">{item.name}</span>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default AddMedia;
