import React, { useState } from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { useSupportRequestMutation } from "../../../features/user/userApiSlice";
import ReusableModal from "../../../components/Modals/ReusableModal";

const SupportModal = ({ onCloseSupport }) => {
  const user = useSelector(selectUser);
  const [supportRequest] = useSupportRequestMutation();

  const [phone, setPhone] = useState("");
  const [supportReason, setSupportReason] = useState("");

  const onSubmit = async () => {
    try {
      await supportRequest({
        name: user.details.name,
        organisation: user.organisation.name,
        email: user.email,
        phone,
        reason: supportReason === "" ? "No reason provided" : supportReason,
      });
      alert(
        "Welcome to CareSMS! Our team will reach out to you within 30 minutes to help you finish your account setup."
      );
    } catch (error) {
      console.log("err", error);
    } finally {
      onCloseSupport();
    }
  };

  return (
    <ReusableModal
      open
      title={"Request Support with Account Setup"}
      onClose={onCloseSupport}
    >
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col gap-4 py-4">
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-1 px-4">
            <label className="text-sm font-semibold">
              Email
              <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              value={user.email}
              disabled
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-1 px-4">
            <label className="text-sm font-semibold">
              Organization Name
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={user.organisation.name}
              disabled
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex flex-row gap-4 px-4">
          <div className="flex flex-row w-1/2">
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <label className="text-sm font-semibold">
                Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={user.details.name}
                disabled
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          <div className="flex flex-row w-1/2">
            <div className="flex flex-col items-start justify-start w-full gap-1">
              <label className="text-sm font-semibold">Phone</label>
              <input
                type="tel"
                className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                placeholder="(123) 456-7890"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-4 p-4">
            <label className="text-sm font-semibold">
              Additional Information
            </label>
            <textarea
              className="block w-full p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              value={supportReason}
              rows={5}
              onChange={(e) => setSupportReason(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col items-start justify-between gap-2 p-4">
        <div className="flex flex-row items-center justify-end w-full gap-4">
          <button
            className="w-full px-4 py-2 font-bold text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
            onClick={onSubmit}
          >
            Contact Support
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default SupportModal;
