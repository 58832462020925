import React from "react";
import ReportHeader from "../reportHeader";
import dayjs from "dayjs";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import OverduePatientsReportTable from "./components/OverduePatientsReportTable";
import OverduePatientsReportFilter from "./components/OverduePatientsReportFilter";
import { useGetOverduePatientReportMutation } from "../../../features/report/reportApiSlice";

function index() {
    const today = dayjs();

    const [dateRange, setDateRange] = React.useState([
        today.startOf("month"),
        today,
    ]);
    const [dateRangeValue, setDateRangeValue] = React.useState("THIS_MONTH");

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("Something went wrong");
    const [isTableView, setIsTableView] = React.useState(true);
    const [overdueReports, setOverdueReports] = React.useState([]);
    const [totals, setTotals] = React.useState([
        { name: "Total Records Found", value: 0 },
        { name: "Total Overdue Patients", value: "$0" },
    ]);
    const [patients, setPatients] = React.useState([]);
    const [locations, setLocation] = React.useState([]);
    const [filteredPt, setFilteredPt] = React.useState([]);
    const [filteredLt, setFilteredLt] = React.useState([]);
    const [patientReports, setPatientReports] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    const [stateNames, setStateNames] = React.useState([]);
    const [clear, setClear] = React.useState(true);
    const [selectedPatientsName, setSelectedPatientsName] = React.useState([]);
    const [selectedLocation, setSelectedLocation] = React.useState([]);
    const [providers, setProviders] = React.useState([]);

    const [selectedCity, setSelectedCity] = React.useState([]);
    const [selectedStateName, setSelectedStateName] = React.useState([]);
    const [isClearCalled, setIsClearCalled] = React.useState(false);
    const [isSelectAllPatients, setIsSelectAllPatients] = React.useState(true);
    const [isSelectAllLocations, setIsSelectAllLocations] = React.useState(true);
    const [isSelectAllCity, setIsSelectAllCity] = React.useState(true);
    const [isSelectAllState, setIsSelectAllState] = React.useState(true);
    const [isSelectedValue, setIsSelectedValue] = React.useState(false);
    const [visitType, setVisitType] = React.useState([]);
    const [selectedVisitType, setSelecetdVisitType] = React.useState([]);
    const [isSelectAllVisitType, setIsSelectAllVisitType] = React.useState(true);
    const [selectedProviders, setSelectedProviders] = React.useState([]);
     const [isSelectAllProviders, setIsSelectAllProviders] = React.useState(true);
     const [filteredPv, setFilteredPv] = React.useState([]);

    const DAY_MAP = {
        MONDAY: "M",
        TUESDAY: "T",
        WEDNESDAY: "W",
        THURSDAY: "Th",
        FRIDAY: "F",
        SATURDAY: "Sa",
        SUNDAY: "Su",
    };

    const [getOverduePatientReport] = useGetOverduePatientReportMutation();
    const getOverduePatientsReports = async (filters) => {
        console.log(filters);
        try {
            const from_date = filters.dateRange
                ? dayjs(filters.dateRange[0]).isValid()
                    ? dayjs(filters.dateRange[0])?.format("DD-MM-YYYY")
                    : ""
                : "";
            const to_date = filters.dateRange
                ? dayjs(filters.dateRange[1]).isValid()
                    ? dayjs(filters.dateRange[1])?.format("DD-MM-YYYY")
                    : ""
                : "";
            const overdueReport = await getOverduePatientReport({
                date_range: filters.dateRangeValue
                    ? filters.dateRangeValue
                    : dateRangeValue,
                    from_date: dateRangeValue ? "" : from_date,
        to_date: dateRangeValue ? "" : to_date,
        patient_id: filters.setHeaders ? "" : selectedPatientsName,
        city: filters.setHeaders ? "" : selectedCity,
        location: filters.setHeaders ? "" : selectedLocation,
        visit_type: filters.setHeaders ? "" : selectedVisitType,
        provider_id: filters.setHeaders ? "" : selectedProviders,
            }).unwrap();

            setOverdueReports(
                [...overdueReport.data].sort(
                    (a, b) => b.overdue_days - a.overdue_days
                )
            );
            setTotals([
                {
                    name: "Total Records Found",
                    value: overdueReport?.data?.length,
                },
            ]);
              if (filters.setHeaders === true) {
                const uniquePatients = overdueReport.data.reduce(
                  (acc, item) => {
                    const existingPatient = acc.find(
                      (patient) => patient.id === item.patient_id
                    );
                    if (!existingPatient) {
                      acc.push({ name: item.patient_name, id: item.patient_id });
                    }
                    return acc;
                  },
                  []
                );
                setPatients(uniquePatients);
                setFilteredPt(uniquePatients);
                const uniqueProviders =overdueReport?.data?.reduce(
                  (acc, item) => {
                    const existingProvider = acc.find(
                      (provider) => provider.id === item.provider_id
                    );
                    if (!existingProvider) {
                      acc.push({ name: item.provider_name, id: item.provider_id });
                    }
                    return acc;
                  },
                  []
                );
                setProviders(uniqueProviders);
                setFilteredPv(uniqueProviders);
            const uniqueLocation =overdueReport?.data?.reduce(
                (acc, item) => {
                  const existingLocation = acc.find(
                    (data) => data.name === item.location
                  );
                  if (!existingLocation) {
                    acc.push({ name: item.location, id: item.location });
                  }
                  return acc;
                },
                [{ name: "PRIVATE_HOME", id: "PRIVATE_HOME" }]
              );
              setLocation(uniqueLocation);
              setFilteredLt(uniqueLocation);

                const uniqueVisitT = overdueReport.data.reduce(
                  (acc, item) => {
                    const existingItem = acc.find((v) => v.id === item.visit_type_id);
                    if (!existingItem) {
                      acc.push({ name: item.visit_type, id: item.visit_type_id });
                    }
                    return acc;
                  },
                  []
                );
                setVisitType(uniqueVisitT);
                const spe = overdueReport.data.map(
                  (item) => item.provider_type
                );
                // setSpeciality([...new Set(spe)]);
                // setFilteredSpe([...new Set(spe)]);
                // setSelectedProviders(uniqueProviders.map(i => i.id));
                // setSelectedSpeciality([...new Set(spe)]);
                setSelectedPatientsName(uniquePatients.map(i => i.id));
                setSelecetdVisitType(uniqueVisitT.map(i => i.id));
                setSelectedLocation(uniqueLocation.map(i => i.id))
                const cities = overdueReport?.data?.map((item) => item.city);
                setCities([...new Set(cities)]);
                setSelectedCity([...new Set(cities)]);
                setSelectedProviders(uniqueProviders.map(i => i.id));
                setIsSelectAllProviders(true)
              }
        } catch (err) {
            setOpen(true);
            setMessage(err.response?.data?.message);
            
        }
    }
    React.useEffect(() => {

        getOverduePatientsReports({ dateRange: dateRange });

    },[])
    
      const handlePatientNameFilter = (patient, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedPatientsName(patients.map(i => i.id))
            } else {
                setSelectedPatientsName([])
            }
        } else {
            if (selectedPatientsName.includes(patient)) {
                setSelectedPatientsName(
                    selectedPatientsName.filter((filter) => filter !== patient)
                );
            } else {
                setSelectedPatientsName([...selectedPatientsName, patient]);
            }
        }
    };
    const handleLocationFilter = (location, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedLocation(locations.map(i => i.id))
            } else {
                setSelectedLocation([])
            }
        } else {
            if (selectedLocation.includes(location)) {
                setSelectedLocation(
                    selectedLocation.filter((filter) => filter !== location)
                );
            } else {
                setSelectedLocation([...selectedLocation, location]);
            }
        }
    };
    const handleCityNameFilter = (city, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedCity(cities)
            } else {
                setSelectedCity([])
            }
        } else {
            if (selectedCity.includes(city)) {
                setSelectedCity(selectedCity.filter((filter) => filter !== city));
            } else {
                setSelectedCity([...selectedCity, city]);
            }
        }
    };
    const handleStateNameFilter = (state, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
            if (obj.isSelectAll) {
                setSelectedStateName(stateNames)
            } else {
                setSelectedStateName([])
            }
        } else {
            if (selectedStateName.includes(state)) {
                setSelectedStateName(
                    selectedStateName.filter((filter) => filter !== state)
                );
            } else {
                setSelectedStateName([...selectedStateName, state]);
            }
        }
    };
    const handleVisitTypeFilter = (type, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
          if (obj.isSelectAll) {
            setSelecetdVisitType(visitType.map(i => i.id))
          } else {
            setSelecetdVisitType([])
          }
        } else {
          if (selectedVisitType.includes(type)) {
            setSelecetdVisitType(
              selectedVisitType.filter((filter) => filter !== type)
            );
          } else {
            setSelecetdVisitType([...selectedVisitType, type]);
          }
        }
      };
      const handleProviderFilter = (provider, obj) => {
        setClear(false);
        if (obj.selectAll === true) {
          if (obj.isSelectAll) {
            setSelectedProviders(providers.map(i => i.id))
          } else {
            setSelectedProviders([])
          }
        } else {
          if (selectedProviders.includes(provider)) {
            setSelectedProviders(
              selectedProviders.filter((filter) => filter !== provider)
            );
          } else {
            setSelectedProviders([...selectedProviders, provider]);
          }
        }
      };
    const handleClear = () => {
        setSelectedPatientsName([]);
        setSelectedCity([]);
        setSelectedStateName([]);
        // setDateRange([today.startOf("month"), today]);
        setDateRange([null, null]);
        setDateRangeValue("");
        setCities([]);
        setPatients([]);
        setFilteredPt([]);
        setStateNames([]);
        setSelectedLocation([]);
        setFilteredLt([]);
        setIsClearCalled(true);
        //getPatientReports({ setHeaders: true, dateRangeValue: "" });
        setOverdueReports([])
        setClear(true)
        setIsTableView(true)
        setVisitType([]);
        setSelecetdVisitType([]);
        setProviders([]);
        setFilteredPv([]);
        setSelectedProviders([]);
        // setIsPivot(false)
    };
    const exportPDF = () => {
        if (overdueReports?.length > 0) {
            const doc = new jsPDF();
            doc.text("Overdue Patients Report", 20, 10);
            doc.autoTable({
                styles: { halign: "center" },
                headStyles: { fillColor: [245, 247, 249] },
                html: "#overdueReport",
                theme: "plain",
                didParseCell: function (data) {
                    if (data.row.index === 0 || data.row.index % 2 === 0) {
                        data.cell.styles.fillColor = [255, 255, 255]; // White color for even rows
                    } else {
                        data.cell.styles.fillColor = [245, 247, 249]; // Light blue color for odd rows
                    }
                },
            });
            doc.setFont("helvetica", "normal");
            doc.setFontSize(13);
            doc.setTextColor(0, 0, 0);
            // doc.text(
            //     `Total Records Found: ${totals[0]?.value}\nTotal Overdue: ${totals[1].value}`,
            //     15,
            //     doc.lastAutoTable.finalY + 10
            // );
            doc.save("overdue-report.pdf");
        } else {
            alert("No Records Found");
        }
    };
    const exportCSV = () => {
        if (overdueReports?.length > 0) {
          let csvContent =
            "Patient_Name,Provider_Name,Days_Overdue,Next_Schedule _visit_Date,Visit_Type,Patient_Location,Patient_Address\r\n";
          overdueReports.forEach((overdue) => {
            const row = [
              parseNull(overdue.patient_name, "NA", "date"),
              parseNull(overdue.provider_name),
              parseNull(overdue.overdue_days),
              parseNull(overdue.next_scheduled_visit_date),
              parseNull(overdue.visit_type),
              parseNull(overdue.location),
              parseNull(overdue.street),
            ];
            csvContent += row.join(",") + "\r\n";
          });
    
          // Add extra data at the bottom of the CSV
        //   csvContent += `\r\nTotal_Records_Found: ${totals[0].value}`;
        //   csvContent += `\r\nTotal_Revenue: ${totals[1].value}`;
          const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "revenue-report.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } else {
          alert("No Records Found");
        }
      };
    const parseNull = (value, replacement = "NA", type = "string") => {
        return value === null || (Array.isArray(value) && value.length === 0)
          ? replacement
          : Array.isArray(value)
            ? value.map((day) => DAY_MAP[day]).join(", ")
            : type === "date"
              ? moment(value).format("DD/MM/YYYY")
              : value;
    };
    const handleClose = () => {
        setOpen(false);
    };
    React.useEffect(() => {
        if (!clear) {
            getOverduePatientsReports({ dateRange: dateRange });
        }
        if (
          selectedPatientsName?.length > 0 ||
          selectedCity?.length > 0 ||
          selectedStateName?.length > 0 ||
          selectedVisitType?.length > 0  ||
          selectedProviders?.length > 0
        ) {
          setIsSelectedValue(true);
        } else {
          setIsSelectedValue(false);
        }
      }, [
        selectedPatientsName,
        selectedCity,
        selectedStateName,
        selectedVisitType,
        selectedLocation,
        selectedProviders,
      ]);
      React.useEffect(() => {
        getOverduePatientsReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
        if (
          selectedPatientsName?.length > 0 ||
          selectedCity?.length > 0 ||
          selectedStateName?.length > 0 ||
          selectedVisitType?.length > 0 ||
          selectedProviders?.length > 0
        
        ) {
          setIsSelectedValue(true);
        } else {
          setIsSelectedValue(false);
        }
      }, []);
    return (
        <div className="relative flex flex-col w-full overflow-auto no-scrollbar standard-reports" style={{ height: "89vh" }}>
            <Snackbar
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                message={message ? message : "Something went wrong"}
                severity="error"
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message ? message : "Something went wrong"}
                </Alert>
            </Snackbar>
            <ReportHeader
                title="Overdue Patients Report"
                exportCSV={exportCSV}
             exportPDF={exportPDF}
                totals={totals}
                total={overdueReports.length}
            />
            <div
                className="p-2 d-flex flex-column overflow-auto align-items-center"
                style={{ height: "77vh" }}
            >
                <OverduePatientsReportFilter
                    getOverduePatientsReports={getOverduePatientsReports}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    dateRangeValue={dateRangeValue}
                    setDateRangeValue={setDateRangeValue}
                     handleClear={handleClear}
                    setClear={setClear}
                    isSelecetdValue={isSelectedValue}
                    isTableView={isTableView}
                    setIsTableView={setIsTableView}
                />
                <OverduePatientsReportTable
                    overdueReports={overdueReports}
                    patients={patients}
                    location={locations}
                    cities={cities}
                    stateNames={stateNames}
                    patientReports={patientReports}
                    handlePatientNameFilter={handlePatientNameFilter}
                    handleLocationFilter={handleLocationFilter}
                    handleCityNameFilter={handleCityNameFilter}
                    handleStateNameFilter={handleStateNameFilter}
                    selectedPatientsName={selectedPatientsName}
                    selectedCity={selectedCity}
                    selectedStateName={selectedStateName}
                    isSelectedValue={isSelectedValue}
                    setPatientReports={setPatientReports}
                    filteredPt={filteredPt}
                    setFilteredPt={setFilteredPt}
                    selectedLocation={selectedLocation}
                    filteredLt={filteredLt}
                    setFilteredLt={setFilteredLt}
                    isClearCalled={isClearCalled}
                    isSelectAllPatients={isSelectAllPatients}
                    setIsSelectAllPatients={setIsSelectAllPatients}
                    isSelectAllLocations={isSelectAllLocations}
                    setIsSelectAllLocations={setIsSelectAllLocations}
                    isSelectAllCity={isSelectAllCity}
                    setIsSelectAllCity={setIsSelectAllCity}
                    isSelectAllState={isSelectAllState}
                    setIsSelectAllState={setIsSelectAllState}
                    visitType={visitType}
                    handleVisitTypeFilter={handleVisitTypeFilter}
                    isSelectAllVisitType={isSelectAllVisitType}
                    setIsSelectAllVisitType={setIsSelectAllVisitType}
                    selectedVisitType={selectedVisitType}
                    setOverdueReports={setOverdueReports}
                    filteredPv={filteredPv}
          setFilteredPv={setFilteredPv}
          handleProviderFilter={handleProviderFilter}
          providers={providers}
          isSelectAllProviders={isSelectAllProviders}
          setIsSelectAllProviders={setIsSelectAllProviders}
          selectedProviders={selectedProviders}
                />
            </div>

        </div>
    )
}
export default index;