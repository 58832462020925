import { apiSlice } from "../../app/api/apiSlice";

export const locationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createLocation: builder.mutation({
      query: (credentials) => ({
        url: "/location",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    editLocation: builder.mutation({
      query: ({ locationId, ...credentials }) => ({
        url: `/location/${locationId}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    getAllLocations: builder.mutation({
      query: () => ({
        url: `/location`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useCreateLocationMutation,
  useEditLocationMutation,
  useGetAllLocationsMutation,
} = locationApiSlice;
