import { DndProvider, DragPreviewImage, useDrag, useDrop } from "react-dnd";
import React, { useCallback, useState } from "react";

import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReusableModal from "../../../../../components/Modals/ReusableModal";
import ReusableReportModal from "../ReusableReportModal";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Search_Icon } from "../../../../../assets/images";
import StraightIcon from "@mui/icons-material/Straight";
import moment from "moment";
import { Tooltip } from "@mui/material";

const ItemType = {
  ROW: "row",
  COLUMN: "column",
};

const DragHandle = ({
  index,
  moveItem,
  type,
  children,
  column,
  properties,
}) => {
  const [{ isDragging }, ref] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop({
    accept: type,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <>
      <span
        ref={(node) => ref(drop(node))}
        style={{
          cursor: "grab",
          marginRight: "5px",
          display: "flex",
          justifyContent: "space-between",
          fontFamily: `${
            properties?.columnProperties?.find((item) => item.name === column)
              ?.fontFamily
              ? properties?.columnProperties?.find(
                  (item) => item.name === column
                )?.fontFamily
              : properties?.fontFamily
          }`,
        }}
      >
        {children}
      </span>
    </>
  );
};
const EditableCell = ({
  value,
  rowIndex,
  column,
  onCellChange,
  properties,
  onCellClick,
  setActiveRowIndex,
  currentColumns,
  isTableView,
  isChartView,
}) => {
  // const [isEditing, setIsEditing] = useState(false);
  const [cellValue, setCellValue] = useState(value);
  const [cellWidth, setCellWidth] = useState("auto");
  const [cellHeight, setCellHeight] = useState("auto");
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = React.useRef(null);

  const handleDoubleClick = () => {
    // const tdWidth = inputRef?.current.offsetWidth;
    // const tdHeight = inputRef?.current.offsetHeight;
    // setCellWidth((tdWidth - 10) + 'px');
    // setCellHeight((tdHeight - 10) + 'px');
    setIsEditing(true);
    setActiveRowIndex(rowIndex);
    setCellValue(value);
    const tdElement = document.getElementById(`cell-${rowIndex}-${column}`);
    if (tdElement) {
      tdElement.style.borderBottom = "none";
      tdElement.style.borderRight = "none";
      tdElement.style.border = "2px solid #247dda";
    }
    const currentColIndex = currentColumns.findIndex((item) => item === column);
    const previousElement =
      currentColIndex >= 0 && currentColIndex > 0
        ? currentColumns[currentColIndex - 1]
        : null;
    if (previousElement) {
      const leftNeighborId = `cell-${rowIndex}-${previousElement}`;
      const leftNeighbor = document.getElementById(leftNeighborId);
      const topNeighbor = document.getElementById(
        `cell-${rowIndex - 1}-${column}`
      );

      if (leftNeighbor) {
        leftNeighbor.style.borderRight = "none";
      }
      if (topNeighbor) {
        if (column === 0) {
          topNeighbor.style.borderBottom = "none";
        } else {
          topNeighbor.style.borderBottom = "none";
        }
      }
    } else {
      const topNeighbor = document.getElementById(
        `cell-${rowIndex - 1}-${column}`
      );
      if (topNeighbor) {
        topNeighbor.style.borderBottom = "none";
      }
    }
  };
  const handleChange = (e) => {
    setCellValue(e.target.value);
  };
  const handleClick = () => {
    onCellClick(rowIndex);
  };
  const handleBlur = () => {
    setIsEditing(false);
    onCellChange(rowIndex, column, cellValue);
    const tdElement = document.getElementById(`cell-${rowIndex}-${column}`);
    if (tdElement) {
      tdElement.style.borderBottom = `${properties.borderWidth}px ${properties.borderType} ${properties.borderColor}`;
      tdElement.style.borderRight = `${properties.borderWidth}px ${properties.borderType} ${properties.borderColor}`;
      tdElement.style.borderTop = "none";
      tdElement.style.borderLeft = "none";
    }
    if (rowIndex > 0) {
      const topNeighbor = document.getElementById(
        `cell-${rowIndex - 1}-${column}`
      );
      if (topNeighbor) {
        topNeighbor.style.borderBottom = `${properties.borderWidth}px ${properties.borderType} ${properties.borderColor}`;
      }
    }
    const currentColIndex = currentColumns.findIndex((item) => item === column);
    const previousElement =
      currentColIndex > 0 ? currentColumns[currentColIndex - 1] : null;
    if (previousElement) {
      const leftNeighborId = `cell-${rowIndex}-${previousElement}`;
      const leftNeighbor = document.getElementById(leftNeighborId);
      if (leftNeighbor) {
        leftNeighbor.style.borderRight = `${properties.borderWidth}px ${properties.borderType} ${properties.borderColor}`;
      }
    }
  };
  React.useEffect(() => {
    if (inputRef.current) {
      const tdWidth = inputRef.current.offsetWidth;
      const tdHeight = inputRef.current.offsetHeight;

      setCellWidth(tdWidth + "px");
      setCellHeight(tdHeight + "px");
    }
  }, [isChartView, isTableView]);
  return (
    <div
      ref={inputRef}
      onDoubleClick={handleDoubleClick}
      onClick={handleClick}
      style={{
        height: "100%",
        fontFamily: `${
          properties?.columnProperties?.find((item) => item.name === column)
            ?.fontFamily
            ? properties?.columnProperties?.find((item) => item.name === column)
                ?.fontFamily
            : properties?.fontFamily
        }`,
      }}
    >
      {isEditing ? (
        <input
          value={cellValue}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          style={{
            width: cellWidth,
            //height: cellHeight,
            border: "none",
            fontSize: "inherit",
            outline: "unset",
            // maxWidth: '0px'
            color: "black",
          }}
          className="border-0"
        />
      ) : (
        value
      )}
    </div>
  );
};

const TableComponent = ({
  component,
  columns,
  data,
  id,
  properties,
  stopTableDrag,
  onPropertyChange,
  setSelectedComponent,
  searchQuery,
  showAlert,
  setIsSavedClicked,
  setIsCreateNewTemplate,
  setIsSaveAsClicked,
  selectedTemplate,
  isChartView,
  isTableView,
}) => {
  const [currentColumns, setCurrentColumns] = useState(columns);
  const [currentData, setCurrentData] = useState(data);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [filteredData, setFilteredData] = useState(data);
  const [search, setSearch] = useState("");
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const tableRef = React.useRef(null);
  const [isdeleteRowOpen, setIsdeleteRowOpen] = React.useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = React.useState(null);

  const moveRow = useCallback(
    (fromIndex, toIndex) => {
      const updatedData = [...currentData];
      const [movedRow] = updatedData.splice(fromIndex, 1);
      updatedData.splice(toIndex, 0, movedRow);
      setCurrentData(updatedData);
    },
    [currentData]
  );

  const moveColumn = useCallback(
    (fromIndex, toIndex) => {
      const updatedColumns = [...currentColumns];
      const [movedColumn] = updatedColumns.splice(fromIndex, 1);
      updatedColumns.splice(toIndex, 0, movedColumn);

      const updatedData = currentData.map((row) => {
        const newRow = { ...row };
        for (let i = 0; i < updatedColumns.length; i++) {
          const originalColumnIndex = currentColumns?.indexOf(
            updatedColumns[i]
          );
          newRow[updatedColumns[i]] = row[currentColumns[originalColumnIndex]];
        }
        return newRow;
      });
      setCurrentColumns(updatedColumns);
      setCurrentData(updatedData);
      const updatedProperties = {
        ...properties,
        columns: updatedColumns,
        data: updatedData,
      };
      onPropertyChange(updatedProperties);

      setSelectedComponent((prev) => ({
        ...prev,
        properties: updatedProperties,
      }));
    },
    [
      currentColumns,
      currentData,
      properties,
      onPropertyChange,
      setSelectedComponent,
    ]
  );

  const validateCellType = (value, expectedType) => {
    if (expectedType === "VARCHAR" && typeof value !== "string") {
      return `You cannot enter "${value}" because it expects a String (Text) value.`;
    }
    if (expectedType === "INTEGER" && !Number.isInteger(Number(value))) {
      return `You cannot enter "${value}" because it expects an Integer (Number) value.`;
    }
    if (expectedType === "FLOAT" && isNaN(value)) {
      return `You cannot enter "${value}" because it expects a Float (Number with decimal) value.`;
    }
    // if (expectedType === 'DATE' && isNaN(Date.parse(value))) {
    //     return `You cannot enter "${value}" because it expects a Date (DATE) value.`;
    // }
    if (expectedType === "DATE") {
      const formats = [
        "DD-MM-YYYY",
        "MM/DD/YYYY",
        "YYYY-MM-DD",
        "MM-DD-YYYY",
        "YYYY/MM/DD",
        "DD/MM/YYYY",
      ];
      if (!moment(value, formats, true).isValid()) {
        return `You cannot enter "${value}"  because it expects a valid date value.`;
      }
    }
    if (
      expectedType === "BOOLEAN" &&
      ![
        "1",
        "0",
        "true",
        "false",
        "yes",
        "no",
        "YES",
        "NO",
        true,
        false,
      ].includes(value)
    ) {
      return `You cannot enter "${value}" because it expects a boolean (1,0,true,false,yes,no) value.`;
    }
    return null;
  };

  const handleCellChange = async (rowIndex, column, newValue) => {
    const columnTypes = component?.properties?.columnProperties?.reduce(
      (acc, item) => {
        acc[item.name] = item?.type;
        return acc;
      },
      {}
    );

    const expectedType = columnTypes[column];
    const errorMessage = await validateCellType(newValue, expectedType);
    if (errorMessage) {
      showAlert(errorMessage, "error");
      return;
    } else {
      const updatedData = currentData?.map((row, idx) =>
        idx === rowIndex ? { ...row, [column]: newValue } : { ...row }
      );
      setCurrentData(updatedData);
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev?.properties,
          data: updatedData,
        },
      }));
      onPropertyChange({ data: updatedData });
    }
  };
  const handleHeaderClick = (index) => {
    setSelectedColumnIndex(index);
  };

  const handleDeleteRow = async (rowIndex) => {
    const updatedData = await currentData?.filter(
      (_, index) => index !== rowIndex
    );
    await setCurrentData(updatedData);
    await setSelectedComponent((prev) => {
      return {
        ...prev,
        properties: {
          ...prev?.properties,
          data: updatedData,
        },
      };
    });
    await onPropertyChange({ data: updatedData });
    setDeleteRowIndex(null);
  };
  const handleSortClick = (columnIndex) => {
    const column = currentColumns[columnIndex];
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: column, direction });
    setSelectedColumnIndex(columnIndex);

    const sortedData = [...currentData].sort((a, b) => {
      const aValue = isNaN(a[column]) ? a[column] : Number(a[column]);
      const bValue = isNaN(b[column]) ? b[column] : Number(b[column]);

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setCurrentData(sortedData);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    const filtered = currentData.filter((row) =>
      currentColumns.some((column) =>
        row[column]
          ?.toString()
          ?.toLowerCase()
          ?.includes(e?.target?.value?.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handleClickOutside = (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      setActiveRowIndex(null);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    setCurrentColumns(columns);
    setCurrentData(data);
  }, [columns, data]);

  const handleCellClick = (rowIndex) => {
    setActiveRowIndex(rowIndex);
  };
  const hexToRgba = (hex, opacity) => {
    let r = 0,
      g = 0,
      b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  };

  const borderColorWithOpacity = hexToRgba(properties?.borderColor, 0.6);
  const rgbaToRgb = (rgba) => {
    const match = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*\d*\.\d+\)$/);
    if (match) {
      return `rgb(${match[1]}, ${match[2]}, ${match[3]})`;
    }
    return rgba; // Return the original value if it doesn't match the expected format
  };
  return (
    <>
      {isdeleteRowOpen && (
        <ReusableReportModal
          open
          onClose={() => {
            setIsdeleteRowOpen(!isdeleteRowOpen);
          }}
          title={"Delete Row ?"}
        >
          <div className="mx-2 my-2 d-flex flex-col">
            <div className="text-[15px] mt-1">{`Are you sure you want to delete this row ?`}</div>
            <div className="self-end mt-2">
              <button
                onClick={() => {
                  //handleUpdateTamplate({ isUpdateData: false });
                  handleDeleteRow(deleteRowIndex);
                  setIsdeleteRowOpen(false);
                }}
                className="px-4 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setIsdeleteRowOpen(false);
                }}
                className="px-4 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
              >
                No
              </button>
            </div>
          </div>
        </ReusableReportModal>
      )}
      <DndProvider backend={HTML5Backend}>
        <div
          className="d-flex flex-col"
          style={{ height: "100%", width: "100%" }}
        >
          {selectedTemplate !== null && (
            <div
              className="d-flex justify-center align-center sticky top-0 bg-white my-1 rounded"
              style={{ width: "100%" }}
            >
              <div className="relative w-1/4 flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip">
                <span className="pl-2">
                  <Tooltip title="Search">
                    <img
                      src={Search_Icon}
                      alt="search"
                      className="w-4 h-4 cursor-pointer  opacity-80"
                    />
                  </Tooltip>
                </span>
                <input
                  type="text"
                  placeholder="Search..."
                  className="w-full h-full px-1 py-1 text-sm outline-none"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
              <span className="position-absolute d-flex gap-1 align-center mt-1 left-0">
                <Tooltip title="Save">
                  <button
                    className="report-builder-nav-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsSavedClicked(true);
                    }}
                  >
                    <SaveOutlinedIcon />
                  </button>
                </Tooltip>
                <Tooltip title="Save As">
                  <button
                    className="report-builder-nav-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCreateNewTemplate(true);
                      setIsSaveAsClicked(true);
                    }}
                  >
                    <SaveAsOutlinedIcon />
                  </button>
                </Tooltip>
              </span>
            </div>
          )}
          {selectedTemplate !== null && (
            <>
              <div>
                <div
                  className={`w-full md:w-[calc(100%-3px)]`}
                  colSpan={currentColumns.length + 1}
                  style={{
                    // textAlign: 'center',
                    whiteSpace: "nowrap",
                    // width: "calc(100% - 17px)",
                    // borderBottom: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                    // borderTop: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                    // borderRight: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                    // padding: "3px 3px",
                    border: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                    color: `${properties?.textColor}`,
                    backgroundColor: `${rgbaToRgb(
                      properties?.backgroundColor
                    )}`,
                    textAlign: `${properties?.align} `,
                    fontSize: `${Number(properties?.fontSize) + 2 + "px"}`,
                    fontWeight: `${properties?.fontWeight} `,
                    paddingLeft: `${properties.paddingLeft + "px"}`,
                    paddingRight: `${properties.paddingRight + "px"}`,
                    paddingTop: `${properties.paddingTop + "px"}`,
                    paddingBottom: `${properties.paddingBottom + "px"}`,
                  }}
                >
                  <div className="d-flex text-sm justify-between items-center">
                    <div
                      className="px-3 text-left capitalize"
                      style={{
                        fontFamily: `${properties?.fontFamily}`,
                        fontWeight: "bold",
                      }}
                    >
                      Name: {properties?.name}
                    </div>
                    <div className="px-4 text-right capitalize fw-bold">
                      Total Rows: {properties?.data?.length}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  colSpan={currentColumns.length + 1}
                  className={`w-full md:w-[calc(100%-3px)]`}
                  style={{
                    // textAlign: 'center',
                    whiteSpace: "nowrap",
                    // width: "calc(100% - 17px)",
                    // borderRight: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,

                    borderLeft: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                    borderRight: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,

                    padding: "3px 3px",
                    color: `${properties?.textColor}`,
                    backgroundColor: `${rgbaToRgb(
                      properties?.backgroundColor
                    )}`,
                    textAlign: `${properties?.align} `,
                    fontSize: `${properties?.fontSize + "px"}`,
                    fontWeight: `${properties?.fontWeight} `,
                    paddingLeft: `${properties.paddingLeft + "px"}`,
                    paddingRight: `${properties.paddingRight + "px"}`,
                    paddingTop: `${properties.paddingTop + "px"}`,
                    paddingBottom: `${properties.paddingBottom + "px"}`,
                  }}
                >
                  <div
                    style={{
                      maxWidth: "95%",
                      whiteSpace: "pre-wrap",
                      fontFamily: `${properties?.fontFamily}`,
                    }}
                    className="text-left px-3 fw-bold"
                  >
                    Description:&nbsp;
                    <span
                      className=" font-normal"
                      style={{ fontFamily: `${properties?.fontFamily}` }}
                    >
                      {properties?.description}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* {selectedTemplate == null && (<div className="d-flex justify-end fw-bold pe-3 pb-2">Total Rows : {properties?.data?.length}</div>)} */}
          {selectedTemplate == null && (
            <span
              className={`d-flex  text-sm fw-bold pe-3 pb-2 w-full md:w-[calc(100%-3px)]`}
              style={{
                borderLeft: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                borderRight: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                borderTop: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                backgroundColor: `${properties.backgroundColor}`,
                color: `${properties.textColor}`,
                padding: "8px",
                // width: "calc(100% - 17px)",
                justifyContent: "space-between",
              }}
            >
              <span>{properties?.name}</span>
              <span> Total Rows: {properties?.data?.length}</span>
            </span>
          )}
          <div
            style={{ width: "100%", overflow: "auto" }}
            className={`custom-scrollbar ${
              search && filteredData?.length <= 0 ? "no-scrollbar " : ""
            }  `}
            id={id}
            onMouseMove={() => stopTableDrag()}
          >
            <table
              id={`element-${id}`}
              style={{
                borderLeft: `${properties.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                height: "100%",
                width: "100%",
                backgroundColor: `${properties.backgroundColor}`,
                color: `${properties.textColor}`,
                borderCollapse: "separate",
                borderSpacing: "0",
              }}
              className="scroll"
            >
              <thead
                style={{
                  top: "0px",
                }}
              >
                <tr>
                  {currentColumns?.map((col, index) => (
                    <th
                      onClick={() => handleHeaderClick(index)}
                      key={index}
                      id={`header-${index}`}
                      style={{
                        // textAlign: 'center',
                        whiteSpace: "nowrap",
                        borderBottom: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                        borderTop: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                        borderRight: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                        padding: "3px 3px",
                        color: `${
                          properties?.columnProperties?.find(
                            (item) => item.name === col
                          )?.textColor
                            ? properties.columnProperties?.find(
                                (item) => item.name === col
                              )?.textColor
                            : properties?.textColor
                        }`,
                        backgroundColor: rgbaToRgb(
                          `${
                            properties?.columnProperties?.find(
                              (item) => item.name === col
                            )?.backgroundColor
                              ? properties?.columnProperties?.find(
                                  (item) => item.name === col
                                )?.backgroundColor
                              : properties?.backgroundColor
                          }`
                        ),
                        textAlign: `${
                          properties?.columnProperties?.find(
                            (item) => item.name === col
                          )?.align
                            ? properties.columnProperties?.find(
                                (item) => item.name === col
                              )?.align
                            : properties?.align
                        } `,
                        fontSize: `${
                          properties?.columnProperties?.find(
                            (item) => item.name === col
                          )?.fontSize
                            ? properties.columnProperties?.find(
                                (item) => item.name === col
                              )?.fontSize + "px"
                            : properties?.fontSize + "px"
                        }`,
                        fontWeight: `${
                          properties?.columnProperties?.find(
                            (item) => item.name === col
                          )?.fontWeight
                            ? properties.columnProperties?.find(
                                (item) => item.name === col
                              )?.fontWeight
                            : properties?.fontWeight
                        } `,
                        paddingLeft: ` ${properties.paddingLeft + "pt"}`,
                        paddingRight: `${properties.paddingRight + "pt"}`,
                        paddingTop: ` ${properties.paddingTop + "pt"}`,
                        paddingBottom: `${properties.paddingBottom + "pt"}`,
                      }}
                    >
                      <DragHandle
                        index={index}
                        moveItem={moveColumn}
                        type={ItemType.COLUMN}
                        column={col}
                        properties={properties}
                      >
                        {/* &#x2630; */}
                        <DragIndicatorIcon />
                        {/* <EditableHeaderCell
                                                value={col}
                                                columnIndex={index}
                                                onHeaderChange={handleHeaderChange}
                                                properties={properties}
                                            /> */}
                        <div
                          style={{ fontFamily: `${properties?.fontFamily}` }}
                        >
                          {col}
                        </div>
                        {sortConfig.key === col ? (
                          sortConfig.direction === "asc" ? (
                            <StraightIcon
                              className="cursor-pointer"
                              style={{ color: "green" }}
                              onClick={() => handleSortClick(index)}
                            />
                          ) : (
                            <StraightIcon
                              className="cursor-pointer"
                              style={{
                                transform: "rotateX(180deg)",
                                color: "green",
                              }}
                              onClick={() => handleSortClick(index)}
                            />
                          )
                        ) : (
                          <StraightIcon
                            className="cursor-pointer"
                            onClick={() => handleSortClick(index)}
                          />
                        )}
                      </DragHandle>
                    </th>
                  ))}
                  <th
                    style={{
                      // textAlign: 'center',
                      whiteSpace: "nowrap",
                      borderBottom: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                      borderTop: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                      borderRight: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                      padding: "3px 3px",
                      color: `${properties?.textColor}`,
                      backgroundColor: rgbaToRgb(
                        `${properties?.backgroundColor}`
                      ),
                      textAlign: `center`,
                      fontSize: `${properties?.fontSize + "px"}`,
                      fontWeight: `${properties?.fontWeight} `,
                      fontFamily: `${properties?.fontFamily}`,
                      paddingLeft: ` ${properties.paddingLeft + "pt"}`,
                      paddingRight: `${properties.paddingRight + "pt"}`,
                      paddingTop: ` ${properties.paddingTop + "pt"}`,
                      paddingBottom: `${properties.paddingBottom + "pt"}`,
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              {search && filteredData.length <= 0 ? (
                <tbody>
                  <div
                    className="d-flex align-Items-center justify-content-center absolute w-full py-4"
                    style={{ zIndex: 0 }}
                  >
                    <p className="text-base font-bold text-primarygrayborder">
                      {" "}
                      No Records Found
                    </p>
                  </div>
                </tbody>
              ) : (
                <tbody ref={tableRef}>
                  {(search ? filteredData : currentData).map(
                    (row, rowIndex) => (
                      <tr key={rowIndex}>
                        {currentColumns?.map((col, colIndex) => (
                          <td
                            id={`cell-${rowIndex}-${col}`}
                            key={colIndex}
                            style={{
                              borderBottom: `${properties.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                              padding: "3px 3px",
                              borderRight: `${properties.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                              color: `${
                                properties.columnProperties?.find(
                                  (item) => item.name === col
                                )?.textColor
                                  ? properties.columnProperties?.find(
                                      (item) => item.name === col
                                    )?.textColor
                                  : properties?.textColor
                              }`,
                              backgroundColor: `${
                                properties?.columnProperties?.find(
                                  (item) => item.name === col
                                )?.backgroundColor
                                  ? properties?.columnProperties?.find(
                                      (item) => item.name === col
                                    )?.backgroundColor
                                  : properties?.backgroundColor
                              }`,
                              textAlign: `${
                                properties?.columnProperties?.find(
                                  (item) => item.name === col
                                )?.align
                                  ? properties?.columnProperties?.find(
                                      (item) => item.name === col
                                    )?.align
                                  : properties?.align
                              }`,
                              fontSize: `${
                                properties.columnProperties?.find(
                                  (item) => item.name === col
                                )?.fontSize
                                  ? properties.columnProperties?.find(
                                      (item) => item.name === col
                                    )?.fontSize + "px"
                                  : properties?.fontSize + "px"
                              }`,
                              fontWeight: `${
                                properties.columnProperties?.find(
                                  (item) => item.name === col
                                )?.fontWeight
                                  ? properties.columnProperties?.find(
                                      (item) => item.name === col
                                    )?.fontWeight
                                  : properties?.fontWeight
                              } `,
                              cursor: "text",
                              paddingLeft: ` ${properties.paddingLeft + "pt"}`,
                              paddingRight: `${properties.paddingRight + "pt"}`,
                              paddingTop: ` ${properties.paddingTop + "pt"}`,
                              paddingBottom: `${
                                properties.paddingBottom + "pt"
                              }`,
                              verticalAlign: `${properties?.verticalAlign}`,
                            }}
                          >
                            {/* <div style={{ verticalAlign: properties?.verticalAlign }}>*/}
                            <EditableCell
                              value={row[col]}
                              rowIndex={rowIndex}
                              column={col}
                              onCellChange={handleCellChange}
                              properties={properties}
                              onCellClick={handleCellClick}
                              setActiveRowIndex={setActiveRowIndex}
                              currentColumns={currentColumns}
                              isTableView={isTableView}
                              isChartView={isChartView}
                            />
                            {/* </div> */}
                          </td>
                        ))}
                        <td
                          onClick={() => {
                            setDeleteRowIndex(rowIndex);
                            setIsdeleteRowOpen(true);
                          }}
                          style={{
                            borderBottom: `${properties.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                            borderRight: `${properties.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                            padding: "3px 3px",
                            color: "#ff3310",
                            backgroundColor: `${properties?.backgroundColor}`,
                            textAlign: `center`,
                            fontSize: `${properties?.fontSize + "px"}`,
                            fontWeight: `${properties?.fontWeight} `,
                            cursor: "pointer",
                            paddingLeft: ` ${properties.paddingLeft + "pt"}`,
                            paddingRight: `${properties.paddingRight + "pt"}`,
                            paddingTop: ` ${properties.paddingTop + "pt"}`,
                            paddingBottom: `${properties.paddingBottom + "pt"}`,
                            verticalAlign: `${properties?.verticalAlign}`,
                          }}
                        >
                          <Tooltip title="Delete">
                            <DeleteIcon />
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default TableComponent;
