import 'react-pivottable/pivottable.css';

import { DndProvider, useDrag, useDrop } from "react-dnd";
import React, { useState } from "react";

import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { HTML5Backend } from "react-dnd-html5-backend";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import moment from "moment";

const TableComponent = ({
    component,
    columns,
    data,
    id,
    onPropertyChange,
    setSelectedComponent,
    showAlert,
    isFromChart,
    selectedColumn,
    handleApplyFilter,
    filterObj,
    setFilterObj,
    clearFilter,
    clearAllFilter,
    filters,
    handleSelectColumn,
    setPrimaryProperties,
    primaryProperties,
    dateformat,
    openDropdowns,
    setOpenDropdowns,
    handleAddColumnValues,
    dateFormats,
    fetchTableData
}) => {
    const [currentColumns, setCurrentColumns] = useState([]);
    const [currentData, setCurrentData] = useState(data);
    const [isdeleteRowOpen, setIsdeleteRowOpen] = React.useState(false);
    const [deleteRowIndex, setDeleteRowIndex] = React.useState(null);
    // const dropdownRef = React.useRef(null);
    // const [openDropdown, setOpenDropdown] = useState(null);
    const [sortColumn, setSortColumn] = useState(null);
    const [hasSortingApplied, setHasSortingApplied] = React.useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedCells, setSelectedCells] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [startCell, setStartCell] = useState(null);
    const [editCell, setEditCell] = React.useState(null); // Track the cell being edited
    const [cellWidth, setCellWidth] = React.useState(null);
    // const [openDropdowns, setOpenDropdowns] = useState({});
    const dropdownRefs = React.useRef({});
    const scrollRef = React.useRef();

    const isScrollbarClick = (e) => {
        const scrollElement = scrollRef.current;
        if (!scrollElement) return false;

        const rect = scrollElement.getBoundingClientRect();
        const isVerticalScrollbar = rect.height < scrollElement.scrollHeight;
        const isHorizontalScrollbar = rect.width < scrollElement.scrollWidth;

        const clickX = e.clientX;
        const clickY = e.clientY;

        // Check if the click is inside the vertical scrollbar
        if (isVerticalScrollbar) {
            if (clickX >= rect.right - 20 && clickX <= rect.right) {
                return true;
            }
        }
        // Check if the click is inside the horizontal scrollbar
        if (isHorizontalScrollbar) {
            if (clickY >= rect.bottom - 20 && clickY <= rect.bottom) {
                return true;
            }
        }
        return false;
    };
    const handleClickOutside = (e) => {
        if (isScrollbarClick(e)) {
            return;
        }
        Object.keys(dropdownRefs.current).forEach((column) => {
            if (dropdownRefs.current[column] && !dropdownRefs.current[column].contains(e.target)) {
                setOpenDropdowns((prevState) => ({ ...prevState, [column]: false }));
            }
        });
    };

    const handleColumnClick = (e, col) => {
        // setOpenDropdowns((prevState) => ({
        //     ...prevState,
        //     [col]: !prevState[col],
        // }));
        setOpenDropdowns((prevState) => {
            const updatedState = {};
            Object.keys(prevState).forEach((key) => {
                updatedState[key] = key === col ? !prevState[key] : false;
            });
            if (!(col in prevState)) {
                updatedState[col] = true;
            }
            return updatedState;
        });
        setSortColumn(col);
        handleSelectColumn(col);
        setFilterObj({ filter_values: component?.filters?.find((item) => item?.column_name === col)?.filter_values ?? [] })
    };

    const DragHandle = ({ index, moveItem, type, children }) => {
        const [{ isDragging }, ref] = useDrag({
            type,
            item: { index },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        });
        const [, drop] = useDrop({
            accept: type,
            hover: (draggedItem) => {
                if (draggedItem.index !== index) {
                    moveItem(draggedItem.index, index);
                    draggedItem.index = index;
                }
            },
        });
        return (
            <div
                ref={(node) => ref(drop(node))}
                style={{
                    cursor: 'grab',
                    opacity: isDragging ? 0.5 : 1,
                }}
            >
                {children}
            </div>
        );
    }
    const moveColumn = (draggedIndex, targetIndex) => {
        const updatedColumns = [...currentColumns];
        const [movedColumn] = updatedColumns.splice(draggedIndex, 1);
        updatedColumns.splice(targetIndex, 0, movedColumn);

        // Update the component.columnProperties with the new order
        const updatedColumnProperties = [...component.columnProperties];
        const [movedProperty] = updatedColumnProperties.splice(draggedIndex, 1);
        updatedColumnProperties.splice(targetIndex, 0, movedProperty);

        // Update the `component.columnProperties` object directly
        // component.columnProperties = updatedColumnProperties.map((prop, idx) => ({
        //     ...prop,
        //     new_index: idx
        // }));

        const updateproperties = updatedColumnProperties.map((prop, idx) => ({
            ...prop,
            new_index: idx
        }))
        setSelectedComponent({
            ...component,
            columnProperties: updateproperties
        });
        onPropertyChange({ columnProperties: updateproperties }, null);

        const filteredColumns = updatedColumns.filter((item) => item !== `${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id` && !item?.isDeleted && !(component?.columnProperties?.find(i => i?.name === item)?.isHidden)
        );
        // Reorder data based on the new column order
        const updatedData = currentData.map(row => {
            const newRow = {};
            updatedColumns.forEach((col) => {
                newRow[col] = row[col];
            });
            return newRow;
        });
        // setCurrentColumns(updatedColumns);
        setCurrentColumns(filteredColumns);
        setCurrentData(updatedData);
    };
    // const handleColumnClick = (event, column) => {
    //     setOpenDropdown(column);
    //     setSortColumn(column);
    //     handleSelectColumn(column);
    //     setFilterObj({ filter_values: component?.filters?.find((item) => item?.column_name === column)?.filter_values ?? [] })
    // };
    const handleCheckboxChange = (item) => {
        if (item == null || item == undefined) {
            return;
        }
        const existingFilters = component?.filters || [];
        const columnAlreadyFiltered = existingFilters?.some(
            (f) => f?.column_name === selectedColumn?.name
        );
        let updatedFilters;
        let finalFilters;
        const columnFilter = filters?.find((item) => item?.column_name === selectedColumn?.name)
        if (columnFilter?.filter_values?.includes(item)) {
            setFilterObj({
                ...filterObj,
                filter_values: filterObj?.filter_values?.filter((val) => val !== item),
            });
            updatedFilters = existingFilters?.map((f) =>
                f?.column_name === selectedColumn?.name
                    ? { ...f, filter_values: columnFilter?.filter_values?.filter((val) => val !== item), }
                    : f
            );
            finalFilters = columnAlreadyFiltered
                ? updatedFilters
                : [...existingFilters, {
                    filter_values: columnFilter?.filter_values?.filter((val) => val !== item),
                    column_name: selectedColumn?.name,
                    column_type: selectedColumn?.type
                }];
            finalFilters = finalFilters?.filter(
                (f) => f?.filter_values && f?.filter_values.length > 0
            );
            setSelectedComponent({
                ...component,
                filters: finalFilters
            })
            onPropertyChange({ filters: finalFilters }, null)
        } else {
            setFilterObj({ ...filterObj, filter_values: [...filterObj.filter_values, item] });
            updatedFilters = existingFilters?.map((f) =>
                f?.column_name === selectedColumn?.name
                    ? { ...f, filter_values: [...columnFilter.filter_values, item], }
                    : f
            );
            finalFilters = columnAlreadyFiltered
                ? updatedFilters
                : [...existingFilters, {
                    filter_values: [item],
                    column_name: selectedColumn?.name,
                    column_type: selectedColumn?.type
                }];
            finalFilters = finalFilters?.filter(
                (f) => f?.filter_values && f?.filter_values?.length > 0
            );
            setSelectedComponent({
                ...component,
                filters: finalFilters
            })
            onPropertyChange({ filters: finalFilters }, null)
        }
    };
    const handleSelectAll = (isChecked) => {
        const columnValues = selectedColumn?.values?.filter((item) => item !== null && item !== undefined);
        const columnFilter = filters?.find((filter) => filter?.column_name === selectedColumn?.name);
        let updatedFilters;
        if (isChecked) {
            // Add all values for the column
            updatedFilters = filters?.map((filter) =>
                filter?.column_name === selectedColumn?.name
                    ? { ...filter, filter_values: columnValues }
                    : filter
            );
            if (!columnFilter) {
                // If no filter exists for the column, add a new one
                updatedFilters = [
                    ...filters,
                    {
                        column_name: selectedColumn?.name,
                        column_type: selectedColumn?.type,
                        filter_values: columnValues,
                    },
                ];
            }
        } else {
            // Remove all values for the column
            updatedFilters = filters?.map((filter) =>
                filter?.column_name === selectedColumn?.name
                    ? { ...filter, filter_values: [] }
                    : filter
            );
        }

        // Filter out columns with no filter values
        updatedFilters = updatedFilters?.filter(
            (filter) => filter?.filter_values?.length > 0
        );

        setSelectedComponent({
            ...component,
            filters: updatedFilters,
        });

        onPropertyChange({ filters: updatedFilters }, null);
    };
    const handleHeaderClick = (column) => {
        handleSelectColumn(column);
    }
    const handleSort = (order) => {
        fetchTableData({ sortColumn: sortColumn, order: order === 'clear' ? 'clear' : order })
        // if (!sortColumn) return;
        if (order === 'clear') {
            //  setCurrentData(data);
            // setOpenDropdown(null);
            setHasSortingApplied(false);
            return;
        }
        // const sortedData = [...currentData]?.sort((a, b) => {
        //     const aValue = a[sortColumn] || '';
        //     const bValue = b[sortColumn] || '';

        //     if (order === 'asc') {
        //         return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        //     } else if (order === 'desc') {
        //         return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
        //     }
        //     return 0;
        // });
        // setCurrentData(sortedData);
        // // setOpenDropdown(null);
        setHasSortingApplied(true);
    };


    const handleCellMouseDown = (e, rowIndex, colIndex) => {
        // e.preventDefault();
        setStartCell({ rowIndex, colIndex });
        setSelectedCells([{ rowIndex, colIndex }]); // Start selection with the clicked cell
    };
    const handlePaste = async (e) => {
        try {
            if (selectedColumn?.isComputed) {
                showAlert(`Column ${selectedColumn?.name} is calculated column. It can not be updated.`, "error");
                setSelectedCells([]);
                return;
            }
            const updatedData = currentData?.map((row) => ({ ...row })); // Ensure each row is a shallow copy
            const columnValues = updatedData?.map((row) => row[selectedColumn?.name]);
            const filteredValues = columnValues?.filter((value) => value !== undefined && value !== null && value !== '');
            const uniqueValues = [...new Set(filteredValues)];
            const restProperties = {
                ...primaryProperties,
                columnProperties: (() => {
                    const present = primaryProperties?.columnProperties?.find(
                        (item) => item?.name === selectedColumn?.name
                    );
                    if (present) {
                        return primaryProperties?.columnProperties?.map((item) => {
                            if (item?.name === selectedColumn?.name) {
                                return { ...item, values: uniqueValues };
                            }
                            return item;
                        });
                    } else {
                        return [
                            ...(primaryProperties?.columnProperties || []),
                            { ...selectedColumn, values: uniqueValues }
                        ];
                    }
                })(),
            };
            // Parse pasted data
            const pastedData = e?.clipboardData
                ?.getData('Text')
                ?.split('\n')
                ?.map((row) => row?.replace(/\r/, '')?.trim())
                ?.filter((row) => row); // Remove empty rows
            // Step 1: Validate each pasted value before processing
            for (const pastedValue of pastedData) {
                const isValid = validateValue(pastedValue, selectedColumn?.type);
                if (!isValid) {
                    // Show an alert if any value is invalid
                    showAlert(`Invalid value "${pastedValue}" for column "${selectedColumn?.name}". Please correct the value.`);
                    return; // Stop further processing if invalid value is found
                }
            }
            const updatedSelectedCells = [];
            let pastedIndex = 0;
            const { rowIndex, colIndex } = selectedCells[0]; // Get the selected starting cell
            // Find the row where the selected cell exists
            const startingRowIndex = currentData?.findIndex(
                (row) =>
                    row[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`] === rowIndex
            );

            // Step 2: Start processing the pasted data
            if (selectedCells?.length === 1) {
                if (startingRowIndex !== -1) {
                    // Apply pasted data starting from the selected row
                    let i = startingRowIndex;
                    while (pastedIndex < pastedData?.length && i < updatedData?.length) {
                        const columnName = selectedColumn?.name; // Get dynamic column name
                        // Ensure both `updatedData[i]` and `columnName` are valid
                        if (columnName) {
                            // Create a shallow copy of the row before modifying it
                            const updatedRow = { ...updatedData[i] };

                            // Update only the existing data in `currentData`
                            updatedRow[columnName] = pastedData[pastedIndex];
                            updatedSelectedCells.push({
                                rowIndex: updatedRow[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`],
                                colIndex,
                                value: pastedData[pastedIndex],
                            });
                            updatedData[i] = updatedRow; // Update the row in the updatedData
                            pastedIndex++; // Move to the next pasted value
                            i++; // Move to the next row in currentData
                        } else {
                            console.error("Invalid column name:", columnName);
                            return; // Stop if there's an invalid column name
                        }
                    }
                }
            }
            // Step 3: Handle overflow data (remaining values)
            while (pastedIndex < pastedData?.length) {
                updatedSelectedCells?.push({
                    // rowIndex: updatedSelectedCells.length + 1, // Simulate a new row for overflow data
                    rowIndex: selectedCells[0]?.rowIndex + pastedIndex,
                    colIndex: selectedColumn?.index, // Adjust based on your column index logic
                    value: pastedData[pastedIndex],
                });
                pastedIndex++; // Move to the next pasted value
            }
            const updated_cell_data = updatedSelectedCells?.map((item) => {
                const processedValue =
                    selectedColumn?.type === 'int'
                        ? parseInt(item?.value, 10) || null // Convert to integer or null if invalid
                        : selectedColumn?.type === 'float'
                            ? parseFloat(item?.value) || null // Convert to float or null if invalid
                            : selectedColumn?.type === 'boolean'
                                ? item?.value === 'true' // Convert string "true" to boolean true
                                : item?.value; // For other types, keep as-is

                return {
                    [`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`]: item?.rowIndex,
                    [selectedColumn?.name]: processedValue, // Use the converted value
                };
            })
            // Step 4: API call to update the table with all pasted data, including overflow data
            // const result = await updateTableSkorboard({
            //     tableName: component?.table_name,
            //     columns: [
            //         {
            //             colName: selectedColumn?.name, // Use the dynamic column name
            //             type: selectedColumn?.type,
            //             newColName: selectedColumn?.new_column_name,
            //             allowNull: true,
            //         },
            //     ],
            //     properties: restProperties,
            //     tableData: updated_cell_data,
            // }).unwrap();
            const updatedColumnProperties = component?.columnProperties?.map((item) => {
                if (item?.name === selectedColumn?.name) {
                    return { ...item, values: uniqueValues };
                }
                return item;
            });
            setCurrentData(updatedData); // Update only the current data that was changed
            setSelectedCells([]); // Reset selected cells
            // setSelectedComponent({ ...component, data: updatedData })
            setSelectedComponent({ ...component, data: updatedData, columnProperties: updatedColumnProperties, tableData: [...component.tableData, ...updated_cell_data] })
            onPropertyChange({ data: updatedData, tableData: [...component.tableData, ...updated_cell_data] }, null)
            //  setPrimaryProperties(restProperties);

        } catch (err) {
            showAlert(err?.data?.message, "error")
            setSelectedCells([])
        }
    };
    const handleCellDoubleClick = (rowIndex, colName) => {
        const cell = document?.getElementById(`cell-${rowIndex}-${colName}`);
        if (cell) {
            const width = cell?.offsetWidth;
            setCellWidth(width);
        }
        setEditCell({ rowIndex, colName });
    };
    const validateValue = (value, colType) => {
        switch (colType) {
            // case "int":
            //     return /^-?\d+$/.test(value); // Checks if the value is an integer
            // case "float":
            //     return /^-?\d+(\.\d+)?$/.test(value); // Checks if the value is a float
            case "int":
            case "float":
                return /^-?\d+(\.\d+)?$/.test(value); // Allows integers and decimals
            case "varchar":
                return typeof value === "string"; // Checks if the value is a string
            case "date":
                return dateFormats?.some((format) => moment(value, format, true).isValid());
            case "timestamp":
                return dateFormats?.some((format) => moment(value, format, true).isValid());
            case "boolean":
                return ["true", "false", "1", "0", true, false, 1, 0]?.includes(value?.toLowerCase());
            default:
                return true; // Allow other types by default
        }
    };
    const handleCellBlur = async (rowIndex, colName, newValue, obj) => {
        if (obj?.isComputed) {
            showAlert(`Column ${colName} is calculated column. It can not be updated.`, "error");
            setEditCell(null);
            setSelectedCells([]);
            return;
        }
        let isValid = true;
        if (newValue !== null) {
            isValid = await validateValue(newValue, obj?.type);
        }
        if (!isValid) {
            showAlert(`Invalid value: "${newValue}" does not match the type "${obj?.type}".`, "error");
            setEditCell(null);
            return; // Stop execution
        }
        const momentDate = obj?.type === 'date' || obj?.type === 'timestamp' ? moment(newValue, obj?.format, true) : "";
        newValue = obj?.type === 'date' || obj?.type === 'timestamp' ? momentDate.utc(true).toISOString() : newValue;
        const updatedData = currentData?.map((row) =>
            row[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`] === rowIndex
                ? { ...row, [colName]: newValue } // Update the matching row
                : row // Return the row unchanged
        );
        const columnValues = updatedData?.map(row => row[colName]);
        const filteredValues = columnValues?.filter(value => value !== undefined && value !== null && value !== '');
        const uniqueValues = [...new Set(filteredValues)];


        const updated_cell_data = {
            [`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`]: rowIndex,
            [colName]: newValue,
        }
        try {
            const updatedColumnProperties = component?.columnProperties?.map((item) => {
                if (item?.name === obj?.name) {
                    return { ...item, values: uniqueValues };
                }
                return item;
            });
            let updatedTableData = component?.tableData;
            const doesExist = component?.tableData?.some(
                (item) =>
                    item[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`] === rowIndex &&
                    Object?.prototype?.hasOwnProperty?.call(item, colName)
            );
            if (doesExist) {
                updatedTableData = updatedTableData?.map((item) => {
                    if (item[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`] === rowIndex && Object?.prototype?.hasOwnProperty?.call(item, colName)) {
                        return {
                            ...item,
                            [colName]: newValue
                        }
                    }
                    return item
                })
            } else {
                updatedTableData?.push(updated_cell_data);
            }
            setCurrentData(updatedData);
            setSelectedComponent({ ...component, data: updatedData, columnProperties: updatedColumnProperties, tableData: updatedTableData })
            onPropertyChange({ data: updatedData, tableData: updatedTableData }, null)
            setEditCell(null); // Exit edit modec
        } catch (err) {
            console.log("err", err);
            setEditCell(null);
            showAlert(err?.data?.message, "error");
        }
    };
    const handleCellKeyPress = (e, rowIndex, colName, currentValue, obj) => {
        if (e?.key === "Enter" || (e?.ctrlKey && e?.key === 's')) {
            handleCellBlur(rowIndex, colName, e?.target?.value || null, obj);
            setEditCell(null);
        }
    };
    const isSelected = (rowIndex, colIndex) => {

        return selectedCells?.some(cell => cell?.rowIndex === rowIndex && cell?.colIndex === colIndex);
    };
    const isDataColumn = (col) => {
        const column = component?.columnProperties?.find(item => item?.name == col)
        return column?.isDataColumn
    }
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    React.useEffect(() => {
        const filteredColumns = columns?.filter((item) => {
            return item !== `${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id` && !item?.isDeleted && !(component?.columnProperties?.find(i => i?.name === item)?.isHidden)
        });
        const orderedColumns = filteredColumns?.sort((a, b) => {
            const aIndex = component?.columnProperties?.find(prop => prop?.name === a)?.new_index;
            const bIndex = component?.columnProperties?.find(prop => prop?.name === b)?.new_index;
            return aIndex - bIndex;
        });
        setCurrentColumns(orderedColumns);
        // setCurrentData(data);
        //  if (!hasSortingApplied) {
        setCurrentData(data);
        //     }

    }, [columns, data, component?.columnProperties]);

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <div
                    className="d-flex flex-col"
                    style={{ height: isFromChart ? '85vh' : '100%', width: "100%", maxWidth: isFromChart ? '70rem' : "", minWidth: isFromChart ? '70rem' : "" }}
                >
                    <div
                        style={{ width: "100%", height: '100%', overflow: "auto" }}
                        className={`datamanagement-custom-scrollbar dashboard-main relative element-${id} `}
                        ref={scrollRef}
                        id={id}
                    >
                        <table
                            id={`element-${id}`}
                            className="scroll tablestyle max-h-100 w-100 border border-gray-300"
                            onPaste={handlePaste}
                        >
                            <thead style={{ top: '-1px' }}>
                                <tr>
                                    {currentColumns?.map((col, index) => (
                                        <th
                                            key={index}
                                            id={`header-${index}`}
                                            style={{
                                                whiteSpace: "nowrap",
                                                padding: "4px",
                                                // textAlign: "center",
                                                backgroundColor: selectedColumn?.name === col ? '#979c9d' : '#edf0f5',
                                            }}
                                            className='tablestyle cursor-pointer'
                                            onClick={() => handleHeaderClick(col)}
                                        >
                                            <div
                                                className="flex items-center justify-between"
                                            >
                                                {/* <Tooltip title={col}>
                                                    <span className={`text-[black]`}>
                                                        {col?.length > 24 ? col?.slice(0, 20) + "..." : col}
                                                    </span>
                                                </Tooltip> */}
                                                <div className="flex-1 text-center">
                                                    <Tooltip title={col}>
                                                        <span className="text-black">
                                                            {col?.length > 24 ? col?.slice(0, 20) + "..." : col}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <span
                                                    style={{
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        border: "1px solid #D1D5DB",
                                                        backgroundColor: 'white',
                                                        height: '14px',
                                                        width: '14px',
                                                        marginLeft: '6px',
                                                        cursor: 'pointer',
                                                        position: 'relative'
                                                    }}
                                                    onClick={(e) => handleColumnClick(e, col)}
                                                    ref={(el) => (dropdownRefs.current[col] = el)}
                                                >
                                                    {filters?.find((item) => item?.column_name === col) !== undefined && filters?.find((item) => item?.column_name === col)?.isApplied === true ?
                                                        // <FilterListRoundedIcon
                                                        //     sx={{ fontSize: '16px' }} />
                                                        <FilterAltRoundedIcon sx={{ fontSize: '14px' }} />

                                                        : <KeyboardArrowDownIcon
                                                            sx={{ fontSize: '16px' }}
                                                        />
                                                    }
                                                    {openDropdowns[col] && selectedColumn?.name === col ? (
                                                        <Dropdown
                                                            dropdownRef={dropdownRefs.current[col]}
                                                            handleOptionClick={(option) => {
                                                                if (option === "sortAscending") handleSort("ASC");
                                                                if (option === "sortDescending") handleSort("DESC");
                                                                if (option === "clearSort") handleSort("clear");
                                                                if (option === 'clearfilter') {
                                                                    clearFilter();
                                                                }
                                                                if (option === 'clearallfilter') {
                                                                    clearAllFilter();
                                                                }
                                                            }}
                                                            columnData={selectedColumn}
                                                            filterObj={filterObj}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                            handleApplyFilter={handleApplyFilter}
                                                            isDisable={(option) => {
                                                                if (option === 'clearSort' && !hasSortingApplied) {
                                                                    return true;
                                                                }
                                                                if (option === 'clearfilter' && component?.filters?.length <= 0) {
                                                                    return true;
                                                                }
                                                                if (option === 'clearallfilter' && component?.filters?.length <= 0) {
                                                                    return true;
                                                                }
                                                                return false;
                                                            }}
                                                            filters={filters}
                                                            selectedColumn={selectedColumn}
                                                            index={index}
                                                            isLastColumn={index === currentColumns?.length - 1}
                                                            isLastSecondColumn={index === currentColumns?.length - 2}
                                                            handleSelectAll={handleSelectAll}
                                                            handleAddColumnValues={handleAddColumnValues}
                                                        />
                                                    ) : ""}

                                                </span>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            {(currentData?.length <= 0) ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={currentColumns?.length} className="py-4 text-center">
                                            <p className="text-base font-bold text-primarygrayborder">
                                                No Records Found
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {(currentData)?.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {currentColumns?.map((col, colIndex) => {
                                                const columnProperty = component?.columnProperties?.find(item => item?.name === col);
                                                let formattedValue = row[col];

                                                if (formattedValue === null && !columnProperty?.isDataColumn && !columnProperty?.isComputed) {
                                                    if (columnProperty?.type === 'int') {
                                                        formattedValue = "NA";
                                                    } else if (columnProperty?.type === 'date') {
                                                        formattedValue = "NA";
                                                    } else if (columnProperty?.type === 'varchar') {
                                                        formattedValue = "NA";
                                                    }
                                                } else if (formattedValue === null && columnProperty?.isDataColumn) {
                                                    formattedValue = "";
                                                } else if (formattedValue === null && columnProperty?.isComputed) {
                                                    formattedValue = 0;
                                                }
                                                if (columnProperty) {
                                                    const { format, currency, isPercentage, decimal, isComma } = columnProperty;

                                                    if (format === "Percentage" && isPercentage) {
                                                        // formattedValue = (row[col] * 100)?.toFixed(0);
                                                        formattedValue = (row[col]);
                                                        if (isComma) {
                                                            formattedValue = parseFloat(formattedValue)?.toLocaleString();
                                                        }
                                                        formattedValue = `${formattedValue}%`;
                                                    }

                                                    // if (format === "MM/DD/YYYY") {
                                                    //     formattedValue = moment(row[col])?.format("MM/DD/YYYY");
                                                    // } else if (format === "DD/MM/YYYY") {
                                                    //     formattedValue = moment(row[col])?.format("DD/MM/YYYY");
                                                    // } else if (format === "YYYY-MM-DD") {
                                                    //     formattedValue = moment(row[col])?.format("YYYY-MM-DD");
                                                    // } else if (format === "YYYY-MM") {
                                                    //     formattedValue = moment(row[col])?.format("YYYY-MM");
                                                    // } else if (format === "General Date") {
                                                    //     formattedValue = moment(row[col])?.format("M/D/YYYY h:mm:ss a");
                                                    // } else if (format === "Long Date") {
                                                    //     formattedValue = moment(row[col])?.format("dddd, MMMM D, YYYY");
                                                    // }
                                                    const dateFormats = dateformat.map(item => item.format);

                                                    if (dateFormats.includes(format)) {
                                                        formattedValue = moment(row[col]).isValid() ? moment(row[col]).format(format) : "Invalid Date";
                                                    }

                                                    else if (format === "Whole Number") {
                                                        formattedValue = Math?.round(row[col]);
                                                        if (isComma) {
                                                            formattedValue = formattedValue?.toLocaleString();
                                                        }
                                                    } else if (format === "General") {
                                                        formattedValue;
                                                    }
                                                    if (currency) {
                                                        formattedValue = `${currency}${parseFloat(formattedValue)?.toFixed(2)}`;
                                                        if (isComma) {
                                                            const [integerPart, decimalPart] = formattedValue.split(".");
                                                            const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                            formattedValue = `${formattedInteger}${decimalPart ? "." + decimalPart : ""}`;
                                                        }
                                                    }
                                                    if (format === "Decimal" && typeof decimal === "number") {
                                                        formattedValue = parseFloat(row[col])?.toFixed(decimal);
                                                        if (isComma) {
                                                            formattedValue = formattedValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                        }
                                                    }
                                                }
                                                if (editCell?.rowIndex === rowIndex && editCell?.colName === col) {
                                                    return (
                                                        <td
                                                            key={colIndex}
                                                            style={{
                                                                whiteSpace: "nowrap",
                                                                textAlign: "center",
                                                                // padding: "3px",
                                                                backgroundColor: "#e3f2fd",
                                                                width: `${cellWidth}px`,
                                                            }}
                                                            className="tablestyle"
                                                        >
                                                            <input
                                                                type="text"
                                                                autoFocus
                                                                defaultValue={formattedValue || ""}
                                                                onBlur={
                                                                    (e) =>
                                                                        handleCellBlur(
                                                                            row[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`],
                                                                            col,
                                                                            e?.target?.value || null,
                                                                            columnProperty)
                                                                }
                                                                onKeyDown={(e) => handleCellKeyPress(e, row[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`], col, row[col] || null, columnProperty)}
                                                                style={{ width: "100%", paddingLeft: '3px' }}
                                                            />
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td
                                                        id={`cell-${rowIndex}-${col}`}
                                                        key={colIndex}
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            textAlign: "left",
                                                            padding: "3px",
                                                            backgroundColor: isSelected(row[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`], colIndex) ? '#e3f2fd' : '',
                                                        }}
                                                        className="tablestyle"
                                                        onDoubleClick={() => handleCellDoubleClick(rowIndex, col)}
                                                        onMouseDown={(e) => {
                                                            handleCellMouseDown(e, row[`${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`], colIndex);
                                                            handleSelectColumn(col);
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: "100%",
                                                                outlineColor: '#2f80ed24 !important',
                                                                outlineWidth: 'thin !important',
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                // maxWidth: "150px",
                                                            }}
                                                            title={
                                                                typeof formattedValue === "object" || typeof formattedValue === "boolean"
                                                                    ? JSON.stringify(formattedValue)
                                                                    : formattedValue
                                                            }
                                                        >
                                                            {/* {typeof formattedValue === "object" || typeof formattedValue === "boolean"
                                                                ? JSON.stringify(formattedValue)
                                                                : formattedValue} */}
                                                            {typeof formattedValue === "object" || typeof formattedValue === "boolean"
                                                                ? JSON.stringify(formattedValue)
                                                                : formattedValue?.length > 26
                                                                    ? formattedValue?.slice(0, 24) + "..."
                                                                    : formattedValue}
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </DndProvider >
        </>
    );
};
const Dropdown = ({ dropdownRef, index, isLastColumn, isLastSecondColumn, isDisable, handleOptionClick, columnData, filterObj, handleCheckboxChange, handleApplyFilter, filters, selectedColumn, handleSelectAll, selectedValues, handleAddColumnValues }) => {
    const options = [
        { label: "Sort Ascending", value: "sortAscending" },
        { label: "Sort Descending", value: "sortDescending" },
        { label: "Clear Sort", value: "clearSort" },
        { label: "Clear filter", value: "clearfilter" },
        { label: "Clear all filter", value: "clearallfilter" },
        //{ label: "Filter", value: "filter" },
    ];
    const [searchQuery, setSearchQuery] = React.useState("");

    const filteredValues = columnData?.values?.filter((item) => {
        if (item === null || item === undefined) {
            return 'NA';
        }
        const itemString = typeof item === "string" ? item : item?.toString();
        return itemString?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });

    return (
        <div
            ref={dropdownRef}
            className="absolute d-flex flex-col text-sm  rounded border-1 border-gray-400 bg-white p-2 gap-1 items-start"
            style={{
                top: '20px',
                // left: '-10px',
                left: isLastColumn || isLastSecondColumn ? '-127px' : '-10px',
                // left: index === 0 ? "-10px" : "-183px",
                zIndex: 10,
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
            }}
            onClick={(e) => e.stopPropagation()}
        >
            {options.map((option) => (
                <button
                    key={option?.value}
                    onClick={() => handleOptionClick(option?.value)}
                    className={`w-full ${isDisable(option?.value) ? 'text-gray-600' : 'text-gray-800'} font-normal text-left px-2 pb-1 hover:bg-gray-200 ${option?.value == 'Clear all filter' ? 'filter-line' : ""}`}
                    style={{
                        transition: "background-color 0.2s",
                        cursor: "pointer",
                    }}
                >
                    {option?.label}
                </button>
            ))}
            <span className='sticky top-[32px] z-10'>
                <span className='w-full relative inline-flex mb-1'>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e?.target?.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleAddColumnValues(searchQuery, true)
                            }
                        }}
                        className='block p-[3px] pl-[5px] text-gary-500 bg-white border-1 border-gray-100 rounded  text-xs placeholder:text-[#c5c8cb] placeholder:font-normal focus:outline-none focus:border-gray-300 w-full'
                    />
                    <SearchIcon
                        style={{
                            position: 'absolute',
                            right: '5px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#c5c8cb',
                        }}
                    />
                </span>
                <div className='max-h-32 overflow-auto custom-scrollbar d-flex font-normal items-start flex-col'>
                    {/* <div className="mb-1 text-xs">
                        <input
                            type="checkbox"
                            id="select-all"
                            checked={
                                filters?.find((item) => item?.column_name === selectedColumn?.name) !== undefined && filters?.find((item) => item?.column_name === selectedColumn?.name)?.filter_values?.length ===
                                selectedColumn?.values?.length
                            }
                            onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                        <label htmlFor="select-all" style={{ marginLeft: "5px" }}>
                            Select All
                        </label>
                    </div> */}
                    {columnData?.values?.slice()
                        ?.sort((a, b) => {
                            if (a == null && b == null) return 0;
                            if (a == null) return 1;
                            if (b == null) return -1;
                            if (typeof a === 'string' && typeof b === 'string') {
                                return a.localeCompare(b);
                            }
                            return a > b ? 1 : -1;
                        })
                        ?.map((item, index,) => {
                            return (
                                <div key={index} className={`flex space-y-1 items-center gap-2 text-xs ${item == null || item == undefined ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer opacity-100'}`} >
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${index}`}
                                        value={item}
                                        checked={
                                            //filterObj?.filter_values?.includes(item) ? true : false
                                            filters?.find((item) => item?.column_name === selectedColumn?.name) !== undefined && filters?.find((item) => item?.column_name === selectedColumn?.name)?.filter_values?.includes(item)
                                        }
                                        onChange={() => {
                                            if (item !== null) {
                                                handleCheckboxChange(typeof item == 'object' ? JSON.stringify(item) : item)
                                            }
                                        }}
                                        className={`${item == null || item == undefined ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                    />
                                    <label htmlFor={`checkbox-${index}`}
                                        className={`${item == null || item == undefined ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                    >
                                        {/* {item == null || item == undefined ? 'NA' : item.toLocaleString()} */}
                                        {item == null || item == undefined
                                            ? 'NA'
                                            : item.toLocaleString().length > 26
                                                ? `${item.toLocaleString().slice(0, 24)}...`
                                                : item.toLocaleString()}
                                    </label>
                                </div>
                            );
                        })}
                </div>
                <span
                    className='flex justify-end text-xs text-blue-600 p-1 py-2 '
                >
                    {/* <button
                            onClick={() => handleAddColumnValues(searchQuery)}
                        >
                            20+ more
                        </button> */}
                    <button
                        onClick={() => handleAddColumnValues(searchQuery)}
                        disabled={selectedColumn?.values?.length >= selectedColumn?.totalCount}
                        className={`${selectedColumn?.values?.length >= selectedColumn?.totalCount ? 'opacity-40 cursor-not-allowed' : 'text-blue-600 cursor-pointer '}`}
                    >
                        20+ more
                    </button>

                </span>
            </span>
            <div className='d-flex justify-end w-full'>
                <button
                    className={`px-2 ml-2 py-[3px]  font-bold text-[12px] btn-bgcolor text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed  opacity-100 }`}
                    onClick={handleApplyFilter}
                >Apply</button>
            </div>
        </div>
    );
};
export default TableComponent;
