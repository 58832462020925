import React from "react";
import { useVerifyEmailMutation } from "../../../features/auth/authApiSlice";
import { skorboardmain_logo } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { PlayArrow } from "@mui/icons-material";
import Loading from "../../../components/Loading";

function EnterOtherEmail({ option, step, handleStepChange, email, setEmail }) {
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const [errMsg, setErrMsg] = React.useState(null);
  const navigate = useNavigate();
  const handleEmailAuth = async () => {
    try {
      await verifyEmail({
        email: email,
        path: option,
        provider: "Email",
      }).unwrap();
      handleStepChange(step + 1);
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };
  const handleForgot = async () => {
    navigate(`/forgot`);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <div className="relative flex flex-col gap-8 w-11/12 base:mt-36">
      <Loading loading={isLoading} />
      <div className="flex flex-col mr-auto w-full gap-10">
        <div className="flex flex-col w-full gap-5">
          <img
            src={skorboardmain_logo}
            alt="logo"
            className="-ml-2 logo-skorboard2"
          />
          <div className="flex flex-col w-full gap-2 m-0 text-left">
            <h1 className="m-0 text-2xl font-bold">
              {option === "signup"
                ? "Create your Skorboard Account"
                : option === "reset-password"
                ? "Reset your password"
                : "Signin to your Skorboard Account"}
            </h1>
            {option !== "reset-password" && (
              <h2 className="m-0 text-base font-normal">
                Start visualizing and analyzing your data in minutes,
                <br className="hidden sm:block" />
                not hours with our powerful Data Analytics Platform.
              </h2>
            )}
          </div>
        </div>
        <div className="flex flex-col w-80 gap-8 sm:gap-8">
          <div className="flex flex-col gap-2">
            <label htmlFor="email" className="text-sm font-semibold">
              Email address
              <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="p-2 text-sm border rounded-lg border-primarylightgrey"
              placeholder="john.doe@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {option !== "reset-password" && (
              <button
                className="self-end text-primaryblue text-sm"
                onClick={handleForgot}
              >
                <span>
                  <u>Forgot password</u>
                </span>
              </button>
            )}
            {errMsg && <p className="m-0 text-xs text-danger">{errMsg}</p>}
          </div>
          <button
            // disabled={isLoading}
            disabled={!isValidEmail(email)}
            onClick={handleEmailAuth}
            className="flex items-center justify-center w-full gap-2 p-2 font-semibold text-white transition-all duration-300 ease-in-out rounded-lg bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
          >
            {option === "reset-password"
              ? "Reset password"
              : `Sign ${option === "signup" ? "up" : "in"} with email`}
            <div className="relative flex flex-row">
              <PlayArrow
                style={{
                  color: "#fff",
                  width: "18px",
                  height: "18px",
                  objectFit: "contain",
                }}
              />
              <PlayArrow
                style={{
                  color: "#fff",
                  width: "18px",
                  height: "18px",
                  objectFit: "contain",
                  position: "absolute",
                  left: -5,
                }}
              />
            </div>
          </button>
          <p className="m-0 text-center">
            {option === "signup" ? "Already" : "Don't"} have an account?{" "}
            <a
              className="no-underline text-primaryblue"
              href={option === "signup" ? "/signin" : "/signup"}
            >
              Sign {option === "signup" ? "In" : "Up"}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default EnterOtherEmail;
