import AreaChartIcon from "../../../../assets/svgs/AreaChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChartProperties from './PropertiesPanel/ChartProperties';
import DoublebarChartIconsvg from "../../../../assets/svgs/DoublebarChartIconsvg";
import DraggableComponent from './DraggableComponent';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined'
import ImageProperties from './PropertiesPanel/ImageProperties';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import LinebarChartIconsvg from "../../../../assets/svgs/linebarChartIconsvg";
import PieChartIcon from "@mui/icons-material/PieChart";
import React from 'react'
import ScatterChart from "../../../../assets/svgs/ScatterChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import SummeryProperties from './PropertiesPanel/SummeryProperties';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import TableProperties from './PropertiesPanel/TableProperties';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TextboxProperties from './PropertiesPanel/TextboxProperties';
import { Tooltip } from "@mui/material";
import FunnelChartIcon from "../../../../assets/svgs/FunnelChartSvg";
import ScoreIcon from '@mui/icons-material/Score';
import SpeedIcon from '@mui/icons-material/Speed';
import TwoKOutlinedIcon from '@mui/icons-material/TwoKOutlined';
function WidgetsPanel({
    component,
    componentCount,
    getDefaultProperties,
    showAlert,
    onPropertyChange,
    setSelectedComponent,
    flag,
    setFlag,
    reportAreaRef,
    reportId,
    handleComponentDrop,
    isAnimated,
    setIsAnimated,
    generateChartData,
    echartsRef,
    generatePieChartData,
    generateDoubleChartData,
    drillData,
    pieDrillData,
    tables,
    isDisableTable,
    generateAdvanceChartData,
    handleAddColumnValues,
    values,
    setValues
}) {
    const [isExpandPropertyPanel, setIsExpandPropertyPanel] = React.useState(true);
    const [openSection, setOpenSection] = React.useState(null);
    const [openItem, setOpenItem] = React.useState(null);
    const [selectedChartType, setSelectedChartType] = React.useState(
        component?.properties?.chartType == "bar"
            ? { value: 1, type: "Bar", icon: <BarChartIcon color="primary" /> }
            : { value: 2, type: "Pie", icon: <PieChartIcon color="primary" /> }
    );
    const EnableIconColor = "#075985"
    const Charts = [
        {
            value: 1,
            type: "Bar",
            label: "bar",
            icon: (
                <Tooltip title={"Bar chart"}>
                    <BarChartIcon
                        style={{ color: EnableIconColor, padding: "0px", margin: "0px" }}
                    />
                </Tooltip>
            ),
        },
        {
            value: 2,
            type: "Pie",
            label: "pie",
            icon: (
                <Tooltip title={"Pie chart"}>
                    <PieChartIcon style={{ color: EnableIconColor }} />
                </Tooltip>
            ),
        },
        {
            value: 3,
            type: "Line with Bar",
            label: "barWithLine",
            icon: (
                <LinebarChartIconsvg
                    height="25px"
                    width="25px"
                    tooltipText="LineBar chart"
                    fill={EnableIconColor}
                />
            ),
        },
        {
            value: 4,
            type: "Stacked",
            label: "stacked",
            icon: (
                <Tooltip title={"StackedBar chart"}>
                    <StackedBarChartIcon
                        style={{ color: EnableIconColor }}
                    />
                </Tooltip>
            ),
        },

        // {
        //     value: 5,
        //     type: "Double",
        //     label: "double",
        //     icon: (
        //         <DoublebarChartIconsvg
        //             height="23px"
        //             width="23px"
        //             tooltipText="DoubleBar chart"
        //             color={EnableIconColor}
        //         />
        //     ),
        // },
        {
            value: 6,
            type: "Line",
            label: "line",
            icon: (
                <Tooltip title={"Line Chart"}>
                    <ShowChartIcon
                        style={{ color: EnableIconColor }}
                    />
                </Tooltip>
            ),
        },
        {
            value: 7,
            type: "Area",
            label: "area",
            icon: (
                <AreaChartIcon
                    height="24px" width="24px" tooltipText="Area Chart" fill={EnableIconColor}
                />
            ),
        },
        {
            value: 8,
            type: "Scatter",
            label: "scatter",
            icon: (
                <ScatterChart
                    height="24px" width="24px" tooltipText="Scatter Chart" fill={EnableIconColor}
                />
            ),
        },
        {
            value: 9,
            type: "Funnel",
            label: "funnel",
            icon: (
                <Tooltip title={"Funnel chart"}>
                    <FunnelChartIcon height="24px" width="24px" tooltipText="Funnel Chart" fill={EnableIconColor} />
                </Tooltip>
            ),
        },
        {
            value: 10,
            type: "Big Number",
            label: "big_number_trendline",
            icon: (
                <Tooltip title={"Big Number with Trendline"}>
                    <ScoreIcon
                        className="big-number-icon"
                    />
                </Tooltip>
            ),
        },
        {
            value: 11,
            type: "Gauge",
            label: "gauge",
            icon: (
                <Tooltip title={"gauge"}>
                    <SpeedIcon className="big-number-icon" />
                </Tooltip>
            ),
        },
        {
            value: 12,
            type: "Big Number",
            label: "big_number",
            icon: (
                <Tooltip title={"Big Number"}>
                    <TwoKOutlinedIcon className="big-number-icon" />
                </Tooltip>
            ),
        },
        // {value:5,type:'Scatter',icon:<ScatterPlotIcon style={{ fontSize: '2.2rem', color: '#1976d2' }} color='primary'/>}
    ];
    const handleChartTypeSelect = (item) => {
        setIsAnimated(true);
        setSelectedChartType(item);
        const headers = component?.properties?.columns;
        // updateChartAxis({
        //   data: drillData?.level === 0 ? matchedRecords : matchedRecordsSec,
        //   yAxis: properties?.rowFields[0],
        //   xAxis: drillData?.level === 0 ? properties?.colFields[1] : properties?.colFields[2],
        //   zAxis: [],
        //   rowFields: properties?.rowFields,
        //   colFields: properties?.colFields,
        //   drillData: newDrillData
        // });

        const xVal = drillData?.level === 1 ? drillData?.breadcrumbs[1]?.xAxisValue : drillData?.breadcrumbs[2]?.xAxisValue;

        const yVal = drillData?.level === 1 ? drillData?.breadcrumbs[1]?.yAxisValue : drillData?.breadcrumbs[2]?.yAxisValue;

        const drilledX = drillData?.level === 1 ? component?.properties?.colFields[0] : drillData?.level1?.xAxis

        const drilledY = drillData?.level === 2 ? component?.properties?.rowFields[0] : drillData?.level1?.yAxis

        const matchedRecords = component?.properties?.data?.filter(record =>
            record[drilledX] === xVal && record[drilledY] === yVal
        );
        const matchedRecordsSec = matchedRecords.filter(record =>
            record[drilledX] === xVal && record[drilledY] === yVal
        );
        const isDrilled = drillData?.level !== 0 ? true : false;

        const drilledData = drillData?.level == 1 ? matchedRecords : matchedRecordsSec;
        const colField = drillData?.level == 1 ? component?.properties?.colFields[1] : component?.properties?.colFields[2]

        const isPieDrilled = pieDrillData?.level !== 0 ? true : false;
        const sliceVal = pieDrillData?.level === 1 ? pieDrillData?.breadcrumbs[1]?.sliceValue : pieDrillData?.breadcrumbs[2]?.sliceValue;
        const matchedRecordsPie = component?.properties?.data?.filter(record =>
            record[component?.properties?.slice[0]] === sliceVal
        )
        const matchRecForSecPie = component?.properties?.data?.filter(record =>
            record[component?.properties?.slice[0]] === pieDrillData?.breadcrumbs[1]?.sliceValue
        )
        const matchedRecordsSecPie = matchRecForSecPie.filter(record =>
            record[component?.properties?.slice[1]] === sliceVal
        );
        const drilledDataPie = pieDrillData?.level == 1 ? matchedRecordsPie : matchedRecordsSecPie;
        const sliceField = pieDrillData?.level == 1 ? component?.properties?.slice[1] : component?.properties?.slice[2]

        if (item?.value === 1) {
            const result = generateChartData(
                isDrilled ? drilledData : component?.properties?.data,
                isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                component?.properties?.rowFields[0]?.columnName,
                component?.properties?.legends?.map(item => item?.columnName),
                null,
                true,
                false,
                false,
                component?.id
            );
            let newOption = {
                ...component?.properties?.option,
                series: result?.series,
                xAxis: {
                    ...component?.properties?.option.xAxis,
                    data: result?.xValues,
                },
                yAxis: {
                    ...component?.properties?.option.yAxis,
                    interval: result?.interval,
                },
                legend: {
                    ...component?.properties?.option.legend,
                    data: result?.series?.map((series) => series.name),
                },
            };
            onPropertyChange({
                option: newOption,
                chartType: "bar",
                xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                yAxis: component?.properties?.rowFields[0]?.columnName,
                //line: null,
                //zAxis: [],

                // rowFields: [headers[1]],
                // colFields: [headers[0]],
                // drillData: {
                //   level: 0,
                //   level1: { xAxis: "", yAxis: "" },
                //   level2: { xAxis: "", yAxis: "" },
                //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                // }
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "bar",
                    xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                    yAxis: component?.properties?.rowFields[0]?.columnName,
                    //line: null,
                    // rowFields: [headers[1]],
                    // colFields: [headers[0]],
                    // drillData: {
                    //   level: 0,
                    //   level1: { xAxis: "", yAxis: "" },
                    //   level2: { xAxis: "", yAxis: "" },
                    //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                    // }
                },
            }));
            const columns = component?.properties?.columns?.filter((item) => !component?.properties?.colFields.includes(item) && !component?.properties?.rowFields.includes(item))
            //   setSelectedCols(columns);
            setFlag(false);
        } else if (item?.value === 2) {
            const result = generatePieChartData(
                isPieDrilled ? drilledDataPie : component?.properties?.data,
                isPieDrilled ? sliceField?.columnName : component?.properties?.colFields[0]?.columnName);
            const newOption = {
                ...component?.properties?.option,
                series: result?.series,
                yAxis: {
                    ...component?.properties?.option.yAxis,
                    interval: result?.interval,
                },
                // xAxis: {
                //   ...component?.properties?.option?.xAxis,
                //   data: result?.xValues
                // },
                // legend: { data: result?.series[0]?.data?.map((item) => item?.name) },
                legend: { ...component?.properties?.option.legend, data: result?.series[0]?.data?.map((item) => item?.name) },

            };
            onPropertyChange({
                option: newOption,
                chartType: "pie",
                slice: component?.properties?.colFields
                //  xAxis: headers[0],
                // zAxis: [],

                // rowFields: [],
                // colFields: [headers[0]],
                // drillData: {
                //   level: 0,
                //   level1: { xAxis: "", yAxis: "" },
                //   level2: { xAxis: "", yAxis: "" },
                //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                // }
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "pie",
                    slice: component?.properties?.colFields
                    // xAxis: headers[0],
                    //zAxis: [],
                    // rowFields: [],
                    // colFields: [headers[0]],
                    // drillData: {
                    //   level: 0,
                    //   level1: { xAxis: "", yAxis: "" },
                    //   level2: { xAxis: "", yAxis: "" },
                    //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                    // }
                },
            }));
            const columns = component?.properties?.columns?.filter((item) => !component?.properties?.colFields.includes(item))
            //  const columns = component?.properties?.columns?.filter((item) => !component?.properties?.slice.includes(item))
            // setSelectedCols(columns);
            setFlag(false);
        } else if (item?.value === 3) {
            const result = generateChartData(
                isDrilled ? drilledData : component?.properties?.data,
                isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                component?.properties?.rowFields[0]?.columnName,
                component?.properties?.legends?.map(item => item?.columnName),
                component?.properties?.line?.columnName,
                true,
                false,
                false,
                component?.id
            );
            let newOption = {
                ...component?.properties?.option,
                series: result?.series,
                xAxis: {
                    ...component?.properties?.option?.xAxis,
                    data: result?.xValues,
                },
                yAxis: {
                    ...component?.properties?.option?.yAxis,
                    interval: result?.interval,
                },
                legend: {
                    ...component?.properties?.option.legend,
                    data: result?.series?.map((series) => series.name),
                },
            };
            onPropertyChange({
                option: newOption,
                chartType: "barWithLine",
                xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                yAxis: component?.properties?.rowFields[0]?.columnName,
                line: component?.properties?.line?.columnName,
                //   zAxis: [],

            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "barWithLine",
                    xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                    yAxis: component?.properties?.rowFields[0]?.columnName,
                    line: component?.properties?.line?.columnName,
                    // zAxis: [],
                },
            }));
            const columns = component?.properties?.columns?.filter((item) => !component?.properties?.colFields.includes(item) && !component?.properties?.rowFields.includes(item))
            // setSelectedCols(columns);
            setFlag(false);
        } else if (item.value == 4) {
            const result = generateChartData(
                isDrilled ? drilledData : component?.properties?.data,
                isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                component?.properties?.rowFields[0]?.columnName,
                component?.properties?.legends?.map(item => item?.columnName),
                null,
                true,
                false,
                false,
                component?.id
            );
            let newOption = {
                ...component?.properties?.option,
                series: result?.series,
                xAxis: {
                    ...component?.properties?.option.xAxis,
                    data: result?.xValues,
                },
                legend: {
                    ...component?.properties?.option.legend,
                    data: result?.series?.map((series) => series.name),
                },
            };
            onPropertyChange({
                option: newOption,
                chartType: "stacked",
                xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                yAxis: component?.properties?.rowFields[0]?.columnName,
                // line: null,
                // zAxis: [],
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "stacked",
                    xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                    yAxis: component?.properties?.rowFields[0]?.columnName,
                    //line: null,
                    // zAxis: [],
                },
            }));
            const columns = component?.properties?.columns?.filter((item) => !component?.properties?.colFields.includes(item) && !component?.properties?.rowFields.includes(item))
            //  setSelectedCols(columns);
            setFlag(false);
        } else if (item.value == 5) {
            // if (headers.length <= 2) {
            //     showAlert(
            //         "At least 3 headers are required to generate a multi-bar chart.",
            //         "error"
            //     );
            // }
            const result = generateDoubleChartData(
                isDrilled ? drilledData : component?.properties?.data,
                isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                component?.properties?.rowFields[0]?.columnName,
                component?.properties?.zAxis?.map(item => item?.column_name) || [],
                null,
                true,
                false,
                false,
                component?.id
            );
            let newOption = {
                ...component?.properties?.option,
                series: result?.series,
                xAxis: {
                    ...component?.properties?.option.xAxis,
                    data: result?.xValues,
                },
                legend: {
                    ...component?.properties?.option.legend,
                    data: result?.series?.map((series) => series.name),
                },
            };
            onPropertyChange({
                option: newOption,
                chartType: "double",
                xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                yAxis: component?.properties?.rowFields[0]?.columnName,
                // line: null,
                // zAxis: component?.properties?.rowFields,
                // rowFields: [headers[1]],
                // colFields: [headers[0]],
                // drillData: {
                //   level: 0,
                //   level1: { xAxis: "", yAxis: "" },
                //   level2: { xAxis: "", yAxis: "" },
                //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                // }
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "double",
                    xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                    yAxis: component?.properties?.rowFields[0]?.columnName,
                    //line: null,
                    // zAxis: component?.properties?.rowFields,
                    // rowFields: [headers[1]],
                    // colFields: [headers[0]],
                    // drillData: {
                    //   level: 0,
                    //   level1: { xAxis: "", yAxis: "" },
                    //   level2: { xAxis: "", yAxis: "" },
                    //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                    // }
                },
            }));
            const columns = component?.properties?.columns?.filter((item) => !component?.properties?.colFields.includes(item) && !component?.properties?.rowFields.includes(item))
            // setSelectedCols(columns);
            setFlag(false);

        } else if (item?.value == 6) {
            const result = generateChartData(
                isDrilled ? drilledData : component?.properties?.data,
                isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                component?.properties?.rowFields[0]?.columnName,
                component?.properties?.legends?.map(item => item?.columnName),
                null,
                true,
                false,
                false,
                component?.id
            );
            let newOption = {
                ...component?.properties?.option,
                series: result?.series,
                xAxis: {
                    ...component?.properties?.option.xAxis,
                    data: result?.xValues,
                },
                legend: {
                    ...component?.properties?.option.legend,
                    data: result?.series?.map((series) => series.name),
                },
            };
            onPropertyChange({
                option: newOption,
                chartType: "line",
                xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                yAxis: component?.properties?.rowFields[0]?.columnName,
                // line: null,
                //zAxis: [],

                // rowFields: [headers[1]],
                // colFields: [headers[0]],
                // drillData: {
                //   level: 0,
                //   level1: { xAxis: "", yAxis: "" },
                //   level2: { xAxis: "", yAxis: "" },
                //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                // }
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "line",
                    xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                    yAxis: component?.properties?.rowFields[0]?.columnName,
                    // line: null,
                    //  zAxis: [],

                    // rowFields: [headers[1]],
                    // colFields: [headers[0]],
                    // drillData: {
                    //   level: 0,
                    //   level1: { xAxis: "", yAxis: "" },
                    //   level2: { xAxis: "", yAxis: "" },
                    //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                    // }
                },
            }));
            const columns = component?.properties?.columns?.filter((item) => !component?.properties?.colFields.includes(item) && !component?.properties?.rowFields.includes(item))
            // setSelectedCols(columns);
            setFlag(false);
        } else if (item?.value == 7) {
            const result = generateChartData(
                isDrilled ? drilledData : component?.properties?.data,
                isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                component?.properties?.rowFields[0]?.columnName,
                component?.properties?.legends?.map(item => item?.columnName),
                null,
                true,
                false,
                false,
                component?.id
            );
            let newOption = {
                ...component?.properties?.option,
                series: result?.series,
                xAxis: {
                    ...component?.properties?.option.xAxis,
                    data: result?.xValues,
                },
                legend: {
                    ...component?.properties?.option.legend,
                    data: result?.series?.map((series) => series.name),
                },
            };
            onPropertyChange({
                option: newOption,
                chartType: "area",
                xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                yAxis: component?.properties?.rowFields[0]?.columnName,
                // line: null,
                //  zAxis: [],

                // rowFields: [headers[1]],
                // colFields: [headers[0]],
                // drillData: {
                //   level: 0,
                //   level1: { xAxis: "", yAxis: "" },
                //   level2: { xAxis: "", yAxis: "" },
                //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                // }
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "area",
                    xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                    yAxis: component?.properties?.rowFields[0]?.columnName,
                    //line: null,
                    //  zAxis: [],

                    // rowFields: [headers[1]],
                    // colFields: [headers[0]],
                    // drillData: {
                    //   level: 0,
                    //   level1: { xAxis: "", yAxis: "" },
                    //   level2: { xAxis: "", yAxis: "" },
                    //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                    // }
                },
            }));
            const columns = component?.properties?.columns?.filter((item) => !component?.properties?.colFields.includes(item) && !component?.properties?.rowFields.includes(item))
            //setSelectedCols(columns);
            setFlag(false);
        } else if (item?.value == 8) {
            const result = generateChartData(
                isDrilled ? drilledData : component?.properties?.data,
                isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                component?.properties?.rowFields[0]?.columnName,
                component?.properties?.legends?.map(item => item?.columnName),
                null,
                true,
                false,
                false,
                component?.id
            );
            let newOption = {
                ...component?.properties?.option,
                series: result?.series,
                xAxis: {
                    ...component?.properties?.option.xAxis,
                    data: result?.xValues,
                },
                legend: {
                    ...component?.properties?.option.legend,
                    data: result?.series?.map((series) => series.name),
                },
            };
            onPropertyChange({
                option: newOption,
                chartType: "scatter",
                xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                yAxis: component?.properties?.rowFields[0]?.columnName,
                // line: null,
                //zAxis: [],

            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    option: newOption,
                    chartType: "scatter",
                    xAxis: isDrilled ? colField?.columnName : component?.properties?.colFields[0]?.columnName,
                    yAxis: component?.properties?.rowFields[0]?.columnName,
                    // line: null,
                    // zAxis: [],
                },
            }));
            const columns = component?.properties?.columns?.filter((item) => !component?.properties?.colFields.includes(item) && !component?.properties?.rowFields.includes(item))
            //  setSelectedCols(columns);
            setFlag(false);
        }
        setFlag(false);
    };
    const [activeTab, setActiveTab] = React.useState("widgets");

    return (
        <div
            // className={`sidebar menu-main ${true ? "menu-show" : "menu-hide"}   d-flex flex-col ${isExpand ? 'min-w-52' : 'min-w-5 w-10'} overflow-auto bg-slate-100 border border-slate-300 custom-scrollbar`}
            className={`sidebar menu-main  ${true ? "menu-show" : "menu-hide"}   d-flex flex-col ${isExpandPropertyPanel ? 'w-56 min-w-56 ' : 'min-w-5 w-10'} no-scrollbar bg-[#edf0f5] border-l custom-scrollbar border-r border-slate-300`}
            style={{ overflow: 'auto', height: '100%' }}
        >
            <div style={{
                top: 0,
                position: 'sticky',
                backgroundColor: '#edf0f5',
                zIndex: 11,
            }}>
                <span className={`d-flex justify-center items-center opacity-100    ${isExpandPropertyPanel ? 'flex-row' : 'flex-col-reverse'} justify-between px-2 py-1 sticky top-0 bg-[#edf0f5] `}>
                    <p className={`m-0 text-sm font-bold text-gray-600 whitespace-nowrap ${!isExpandPropertyPanel ? 'rotate-90 translate-y-5 pl-8' : "rotate-0 translate-y-0 "}`}>Visualization</p>
                    <KeyboardDoubleArrowRightOutlinedIcon
                        className={`font-bold text-[#075985] cursor-pointer  ${isExpandPropertyPanel ? 'rotate-180' : 'rotate-0'}`}
                        // onClick={() => {
                        //     setIsExpandPropertyPanel(!isExpandPropertyPanel);
                        // }}
                        onClick={() => {
                            setIsExpandPropertyPanel(!isExpandPropertyPanel);
                        }}
                        sx={{
                            width: '30px !important'
                        }} />
                </span>

                {isExpandPropertyPanel && (
                    <div className="flex mt-[5px]">
                        <button
                            className={`px-4 py-1 text-sm font-semibold ${activeTab === "widgets" ? "border-b-2 border-[#075985] text-[#075985]" : "text-gray-500"}`}
                            onClick={() => setActiveTab("widgets")}
                        >
                            Widgets
                        </button>
                        <button
                            className={`px-4 py-1 text-sm font-semibold  ${activeTab === "customize" ? "border-b-2 border-[#075985] text-[#075985]" : "text-gray-500"}`}
                            onClick={() => setActiveTab("customize")}
                        >
                            Customize
                        </button>
                    </div>

                )}
                {isExpandPropertyPanel && activeTab === "widgets" && (
                    <div className='d-flex flex-wrap border border-blue-200 gap-[8px] rounded bg-white py-1  mx-2 mt-3 gap-x-[19px] px-2'>
                        <DraggableComponent
                            type="textbox"
                            id={`component-${componentCount}`}
                            properties={getDefaultProperties("textbox")}
                        >
                            <div
                                onClick={(e) => {
                                    e?.preventDefault();
                                    handleComponentDrop({
                                        type: "textbox",
                                        id: `component-${componentCount}`,
                                        properties: getDefaultProperties("textbox")
                                    }, null, true);
                                }}
                            >
                                <Tooltip title="You can drag or click the Textbox to select it.">
                                    <TextFieldsIcon className='widget-icons' />
                                </Tooltip>
                            </div>
                        </DraggableComponent>


                        <DraggableComponent
                            type="image"
                            className="d-flex justify-center w-50 grow"
                            id={`component-${componentCount}`}
                            properties={getDefaultProperties("image")}
                        >
                            <div
                                onClick={() => {
                                    handleComponentDrop({
                                        type: "image",
                                        id: `component-${componentCount}`,
                                        properties: getDefaultProperties("image")
                                    }, null, true);
                                }}
                            >
                                <Tooltip title="You can drag or click the Image to select it.">
                                    <InsertPhotoOutlinedIcon className='widget-icons' />
                                </Tooltip>
                            </div>
                        </DraggableComponent>
                        {Charts?.map((item) => (
                            <DraggableComponent
                                type="chart"
                                id={`component-${componentCount}`}
                                // properties={getDefaultProperties("chart")}
                                properties={{
                                    ...getDefaultProperties("chart"),
                                    chartType: item.label
                                }}
                                key={item.label} // Ensure a unique key for each chart item
                            >
                                <div
                                    onClick={() => {
                                        // if (component?.type === 'chart') {
                                        //     handleChartTypeSelect(item);
                                        // } else {
                                        let properties = getDefaultProperties("chart");
                                        properties.chartType = item?.label;
                                        handleComponentDrop({
                                            type: "chart",
                                            id: `component-${componentCount}`,
                                            properties: properties
                                        }, null, true);
                                        // }
                                    }}
                                    className={`rounded d-flex items-center justify-center ${item?.label === component?.properties?.chartType
                                        ? "bg-slate-300 "
                                        : ""
                                        }`}
                                    style={{
                                        height: "25px",
                                        width: "25px",
                                    }}
                                >
                                    {item?.icon}
                                </div>
                            </DraggableComponent>
                        ))}



                    </div>
                )}
                {/* {isExpandPropertyPanel && (
                    <div className='d-flex flex-wrap border border-blue-200 gap-[8px] rounded bg-white py-1 justify-around mx-2 mt-1 gap-x-[19px] px-2'>
                       
                        <DraggableComponent
                            type="textbox"
                            id={`component-${componentCount}`}
                            properties={getDefaultProperties("textbox")}
                        >
                            <div
                                onClick={(e) => {
                                    e?.preventDefault();
                                    handleComponentDrop({
                                        type: "textbox",
                                        id: `component-${componentCount}`,
                                        properties: getDefaultProperties("textbox")
                                    }, null, true);
                                }}
                            >
                                <Tooltip title="You can drag or click the Textbox to select it.">
                                    <TextFieldsIcon className='widget-icons' />
                                </Tooltip>
                            </div>
                        </DraggableComponent>

                       
                        <DraggableComponent
                            type="image"
                            className="d-flex justify-center w-50 grow"
                            id={`component-${componentCount}`}
                            properties={getDefaultProperties("image")}
                        >
                            <div
                                onClick={() => {
                                    handleComponentDrop({
                                        type: "image",
                                        id: `component-${componentCount}`,
                                        properties: getDefaultProperties("image")
                                    }, null, true);
                                }}
                            >
                                <Tooltip title="You can drag or click the Image to select it.">
                                    <InsertPhotoOutlinedIcon className='widget-icons' />
                                </Tooltip>
                            </div>
                        </DraggableComponent>

                       
                        {Charts?.map((item) => (
                            <DraggableComponent
                                type="chart"
                                id={`component-${componentCount}`}
                                properties={getDefaultProperties("chart")}
                                key={item.label} // Ensure a unique key for each chart item
                            >
                                <div
                                    onClick={() => {
                                        if (component?.type === 'chart') {
                                            handleChartTypeSelect(item);
                                        } else {
                                            let properties = getDefaultProperties("chart");
                                            properties.chartType = item?.label;
                                            handleComponentDrop({
                                                type: "chart",
                                                id: `component-${componentCount}`,
                                                properties: properties
                                            }, null, true);
                                        }
                                    }}
                                    className={`rounded d-flex items-center justify-center ${item?.label === component?.properties?.chartType
                                        ? "bg-slate-300 "
                                        : ""
                                        }`}
                                    style={{
                                        height: "25px",
                                        width: "25px",
                                    }}
                                >
                                    {item?.icon}
                                </div>
                            </DraggableComponent>
                        ))}
                    </div>
                )} */}

            </div>

            {isExpandPropertyPanel && activeTab === "customize" &&
                <div>
                    {component ? (
                        <>
                            {component?.type === "table" && (
                                <TableProperties
                                    component={component}
                                    showAlert={showAlert}
                                    onPropertyChange={onPropertyChange}
                                    setSelectedComponent={setSelectedComponent}
                                    flag={flag}
                                    setFlag={setFlag}
                                    reportAreaRef={reportAreaRef}
                                    //  setIsSaveClicked={setIsSavedClicked}
                                    //  isSaveClicked={isSaveClicked}
                                    //  selectedTemplate={selectedTemplate}
                                    //  setIsCreateNewTemplate={setIsCreateNewTemplate}
                                    //  isNewReportActive={isNewReportActive}
                                    reportId={reportId}
                                    //  setCurrentReportData={setCurrentReportData}
                                    //  getUserTemplates={getUserTemplates}
                                    openSection={openSection}
                                    setOpenSection={setOpenSection}
                                    openItem={openItem}
                                    setOpenItem={setOpenItem}
                                    //data={currentReportData?.data}
                                    data={component?.properties?.data}
                                />
                            )}
                            {component?.type === "textbox" && (
                                <TextboxProperties
                                    component={component}
                                    showAlert={showAlert}
                                    onPropertyChange={onPropertyChange}
                                    setSelectedComponent={setSelectedComponent}
                                    flag={flag}
                                    setFlag={setFlag}
                                    reportAreaRef={reportAreaRef}
                                    openSection={openSection}
                                    setOpenSection={setOpenSection}
                                />
                            )}
                            {component?.type === "image" && (
                                <ImageProperties
                                    component={component}
                                    onPropertyChange={onPropertyChange}
                                    setSelectedComponent={setSelectedComponent}
                                    setFlag={setFlag}
                                    reportAreaRef={reportAreaRef}
                                    openSection={openSection}
                                    setOpenSection={setOpenSection}
                                />
                            )}
                            {component?.type === "summery" && (
                                <SummeryProperties
                                    component={component}
                                    showAlert={showAlert}
                                    onPropertyChange={onPropertyChange}
                                    setSelectedComponent={setSelectedComponent}
                                    flag={flag}
                                    setFlag={setFlag}
                                    reportAreaRef={reportAreaRef}
                                    openSection={openSection}
                                    setOpenSection={setOpenSection}
                                    setOpenItem={setOpenItem}
                                    openItem={openItem}
                                />
                            )}
                            {component?.type === "chart" && (
                                <ChartProperties
                                    component={component}
                                    onPropertyChange={onPropertyChange}
                                    setSelectedComponent={setSelectedComponent}
                                    setFlag={setFlag}
                                    reportAreaRef={reportAreaRef}
                                    showAlert={showAlert}
                                    generateChartData={generateChartData}
                                    echartsRef={echartsRef}
                                    generatePieChartData={generatePieChartData}
                                    generateDoubleChartData={generateDoubleChartData}
                                    openSection={openSection}
                                    setOpenSection={setOpenSection}
                                    isAnimated={isAnimated}
                                    setIsAnimated={setIsAnimated}
                                    flag={flag}
                                    // setConfirmModalOpendata={setConfirmModalOpen}
                                    drillData={drillData}
                                    pieDrillData={pieDrillData}
                                    tables={tables}
                                    generateAdvanceChartData={generateAdvanceChartData}
                                    handleAddColumnValues={handleAddColumnValues}
                                    values={values}
                                    setValues={setValues}
                                />
                            )}
                        </>
                    ) : (
                        <p className="text-center text-gray-400 text-xs pt-3">Select a widget to customize it.</p>
                    )}

                </div>
            }

            {/* {isExpandPropertyPanel &&
                <div>
                    {component?.type === "table" && (
                        <TableProperties
                            component={component}
                            showAlert={showAlert}
                            onPropertyChange={onPropertyChange}
                            setSelectedComponent={setSelectedComponent}
                            flag={flag}
                            setFlag={setFlag}
                            reportAreaRef={reportAreaRef}
                            //  setIsSaveClicked={setIsSavedClicked}
                            //  isSaveClicked={isSaveClicked}
                            //  selectedTemplate={selectedTemplate}
                            //  setIsCreateNewTemplate={setIsCreateNewTemplate}
                            //  isNewReportActive={isNewReportActive}
                            reportId={reportId}
                            //  setCurrentReportData={setCurrentReportData}
                            //  getUserTemplates={getUserTemplates}
                            openSection={openSection}
                            setOpenSection={setOpenSection}
                            openItem={openItem}
                            setOpenItem={setOpenItem}
                            //data={currentReportData?.data}
                            data={component?.properties?.data}
                        />
                    )}
                    {component?.type === "textbox" && (
                        <TextboxProperties
                            component={component}
                            showAlert={showAlert}
                            onPropertyChange={onPropertyChange}
                            setSelectedComponent={setSelectedComponent}
                            flag={flag}
                            setFlag={setFlag}
                            reportAreaRef={reportAreaRef}
                            openSection={openSection}
                            setOpenSection={setOpenSection}
                        />
                    )}
                    {component?.type === "image" && (
                        <ImageProperties
                            component={component}
                            onPropertyChange={onPropertyChange}
                            setSelectedComponent={setSelectedComponent}
                            setFlag={setFlag}
                            reportAreaRef={reportAreaRef}
                            openSection={openSection}
                            setOpenSection={setOpenSection}
                        />
                    )}
                    {component?.type === "summery" && (
                        <SummeryProperties
                            component={component}
                            showAlert={showAlert}
                            onPropertyChange={onPropertyChange}
                            setSelectedComponent={setSelectedComponent}
                            flag={flag}
                            setFlag={setFlag}
                            reportAreaRef={reportAreaRef}
                            openSection={openSection}
                            setOpenSection={setOpenSection}
                            setOpenItem={setOpenItem}
                            openItem={openItem}
                        />
                    )}
                    {component?.type === "chart" && (
                        <ChartProperties
                            component={component}
                            onPropertyChange={onPropertyChange}
                            setSelectedComponent={setSelectedComponent}
                            setFlag={setFlag}
                            reportAreaRef={reportAreaRef}
                            showAlert={showAlert}
                            generateChartData={generateChartData}
                            echartsRef={echartsRef}
                            generatePieChartData={generatePieChartData}
                            generateDoubleChartData={generateDoubleChartData}
                            openSection={openSection}
                            setOpenSection={setOpenSection}
                            isAnimated={isAnimated}
                            setIsAnimated={setIsAnimated}
                            flag={flag}
                            // setConfirmModalOpendata={setConfirmModalOpen}
                            drillData={drillData}
                            pieDrillData={pieDrillData}
                            tables={tables}

                        />
                    )}
                </div>
            } */}
        </div >
    )
}
export default WidgetsPanel;