import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import Alert from '@mui/material/Alert';

function SubscriptionAlert() {
    const user = useSelector(selectUser);
    const [days, setDays] = React.useState("")
    React, useEffect(() => {
        const trialStartDate = new Date(user.createdAt.split("T")[0]);
        const trialEndDate = new Date(trialStartDate);
        trialEndDate.setDate(trialEndDate.getDate() + 30);
        const currentDate = new Date();
        const remainingDays = Math.ceil((trialEndDate - currentDate) / (1000 * 60 * 60 * 24));
        console.log("Remaining days until trial expiration:", remainingDays);
        setDays(remainingDays)
    }, [])
    return (
        <div className='mx-2'>
            <Alert
                severity="warning"
                style={{ padding: '2px 16px', backgroundColor: '#fdeded', justifyContent: 'center' }}
                sx={{
                    '& .MuiAlert-icon': {
                        color: '#d32f2f'
                    },
                }}
            >
                <span className='text-base font-medium' style={{ color: '#a72121' }}>Your 30-day free subscription expires in <b>{days} </b>days</span>
            </Alert>
        </div>
    )
}

export default SubscriptionAlert