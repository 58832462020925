import React from "react";
import IconComponent from "../../../../components/DataTable/Icon";
import classNames from "classnames";

const SelectedAppointmentTypesTable = React.forwardRef(
  ({ appointments, handleEditClick }, ref) => {
    return (
      <div
        ref={ref}
        className="flex flex-col items-start gap-4 px-4 overflow-auto md:px-8"
      >
        <p className="m-0 text-2xl font-bold">
          Visit Types{" "}
          <span className="ml-2 text-base font-bold text-primaryblue">
            {appointments.length}
          </span>
        </p>
        <table className="w-full my-2 overflow-hidden rounded-lg shadow-md">
          <thead>
            <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue">
              <th className="px-4 py-3">Visit Type</th>
              <th className="px-4 py-3">Rotation Schedule (Days)</th>
              <th className="px-4 py-3">Early Visit Window (Days)</th>
              <th className="px-4 py-3">Edit</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment, index) => (
              <tr
                className={classNames(
                  "text-left text-gray-900 h-24",
                  index !== appointments.length - 1 ? "border-b" : "border-none"
                )}
                key={index}
              >
                <td className="px-4 py-3 text-left">
                  {appointment.appointmentName}
                </td>
                <td className="px-4 py-3 text-left">
                  {appointment.rotation}{" "}
                  {appointment.rotation > 1 ? "days" : "day"}
                </td>
                <td className="px-4 py-3 text-left">
                  {appointment.earlyVisitWindow < 1
                    ? "NA"
                    : appointment.earlyVisitWindow}{" "}
                  {appointment.earlyVisitWindow < 1
                    ? ""
                    : appointment.earlyVisitWindow > 1
                    ? "days"
                    : "day"}
                </td>
                <td className="px-4 py-3 text-left">
                  <button
                    className="p-2 text-white rounded-md"
                    onClick={() => {
                      handleEditClick(appointment.id);
                    }}
                  >
                    <IconComponent
                      tooltip={"Edit"}
                      icon={"Edit_Icon"}
                      i={appointment.id}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);

export default SelectedAppointmentTypesTable;
