import React from "react";
import PatientVisitReportTable from "./components/PatientVisitReportTable";
import PatientVisitReportFilter from "./components/PatientVisitReportFilter";
import axios from "axios";
import ReportHeader from "../reportHeader";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import dayjs from "dayjs";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useGetPatientReportMutation } from "../../../features/report/reportApiSlice";

function index() {
  const [patients, setPatients] = React.useState([]);
  const [locations, setLocation] = React.useState([]);
  const [filteredPt, setFilteredPt] = React.useState([]);
  const [filteredLt, setFilteredLt] = React.useState([]);
  const [patientReports, setPatientReports] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [stateNames, setStateNames] = React.useState([]);
  const [providers, setProviders] = React.useState([]);
  const [filteredPv, setFilteredPv] = React.useState([]);
  const [visitType, setVisitType] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [totals, setTotals] = React.useState([
    { name: "Total Records Found", value: 0 },
  ]);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("Something went wrong");

  const today = dayjs();

  const [dateRange, setDateRange] = React.useState([
    today.startOf("month"),
    today,
  ]);
  const [isSelectedValue, setIsSelectedValue] = React.useState(false);

  const [dateRangeValue, setDateRangeValue] = React.useState("THIS_MONTH");

  const [selectedPatientsName, setSelectedPatientsName] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState([]);

  const [selectedCity, setSelectedCity] = React.useState([]);
  const [selectedStateName, setSelectedStateName] = React.useState([]);

  const [selectedVisitType, setSelecetdVisitType] = React.useState([]);
  const [selectedProviders, setSelectedProviders] = React.useState([]);

  const [clear, setClear] = React.useState(false);
  const [isClearCalled, setIsClearCalled] = React.useState(false);
  const [isSelectAllPatients, setIsSelectAllPatients] = React.useState(true);
  const [isSelectAllVisitType, setIsSelectAllVisitType] = React.useState(true);
  const [isSelectAllProviders, setIsSelectAllProviders] = React.useState(true);
  const [isSelectAllLocations, setIsSelectAllLocations] = React.useState(true);
  const [isSelectAllCity, setIsSelectAllCity] = React.useState(true);
  const [isSelectAllState, setIsSelectAllState] = React.useState(true);
  const [isSelectAllStatus, setIsSelectAllStatus] = React.useState(true);

  const [isTableView, setIsTableView] = React.useState(true);
  // const [isPivot, setIsPivot] = React.useState(false)

  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const [getPatientReport] = useGetPatientReportMutation();

  const getPatientReports = async (filters) => {

    try {
      console.log(filters.dateRange);
      const from_date = filters.dateRange
        ? dayjs(filters?.dateRange[0]).isValid()
          ? dayjs(filters?.dateRange[0])?.format("DD-MM-YYYY")
          : ""
        : "";
      const to_date = filters.dateRange
        ? dayjs(filters.dateRange[1]).isValid()
          ? dayjs(dateRange[1])?.format("DD-MM-YYYY")
          : ""
        : "";
      const patientReports = await getPatientReport({
        dateRangeValue: filters.dateRangeValue
          ? filters.dateRangeValue
          : dateRangeValue,
        from_date: dateRangeValue ? "" : from_date,
        to_date: dateRangeValue ? "" : to_date,
        patient_id: filters.setHeaders ? "" : selectedPatientsName,
        city: filters.setHeaders ? "" : selectedCity,
        state: filters.setHeaders ? "" : selectedStateName,
        visit_type: filters.setHeaders ? "" : selectedVisitType,
        provider_id: filters.setHeaders ? "" : selectedProviders,
        location: filters.setHeaders ? "" : selectedLocation,
        status: filters.setHeaders ? "" : selectedStatus,
      }).unwrap();

      console.log(selectedStatus, ">>> uuuuu");
      console.log(filters.setHeaders, ">>>>> JJJJJ");

      setPatientReports(
        [...patientReports.data]?.sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )
      );
      if (filters.setHeaders && filters.setHeaders === true) {
        const uniquePatients = patientReports?.data?.reduce((acc, item) => {
          const existingPatient = acc.find(
            (patient) => patient.id === item.patient_id
          );
          if (!existingPatient) {
            acc.push({ name: item.patient_name, id: item.patient_id });
          }
          return acc;
        }, []);
        setPatients(uniquePatients);
        setFilteredPt(uniquePatients);

        const uniqueLocation = patientReports?.data?.reduce(
          (acc, item) => {
            const existingLocation = acc.find(
              (data) => data.name === item.location
            );
            if (!existingLocation) {
              acc.push({ name: item.location, id: item.location });
            }
            return acc;
          },
          [{ name: "PRIVATE_HOME", id: "PRIVATE_HOME" }]
        );
        setLocation(uniqueLocation);
        setFilteredLt(uniqueLocation);

        const uniqueProviders = patientReports?.data?.reduce((acc, item) => {
          const existingProvider = acc.find(
            (provider) => provider.id === item.provider_id
          );
          if (!existingProvider) {
            acc.push({ name: item.provider_name, id: item.provider_id });
          }
          return acc;
        }, []);
        setProviders(uniqueProviders);
        setFilteredPv(uniqueProviders);
        const uniqueVisitT = patientReports?.data?.reduce((acc, item) => {
          const existingItem = acc.find((v) => v.id === item.visit_type_id);
          if (!existingItem) {
            acc.push({ name: item.visit_type, id: item.visit_type_id });
          }
          return acc;
        }, []);
        setVisitType(uniqueVisitT);
        const uniqueStatus = patientReports?.data?.reduce((acc, item) => {
          const existingItem = acc.find((data) => data?.id === item.status);
          if (!existingItem) {
            acc.push({ name: item.status == 'SCHEDULED' ? 'Pending' : item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase(), id: item.status });
          }
          return acc;
        }, []);
        setStatus(uniqueStatus);

        const cities = patientReports?.data?.map((item) => item.city);
        setCities([...new Set(cities)]);
        const stateNames = patientReports?.data?.map((item) => item.state);
        setStateNames([...new Set(stateNames)]);
        setSelectedPatientsName(uniquePatients.map(i => i.id));
        setSelecetdVisitType(uniqueVisitT.map(i => i.id));
        setSelectedLocation(uniqueLocation.map(i => i.id));
        setSelectedStatus(uniqueStatus.map(i => i.id))
        setSelectedCity([...new Set(cities)]);
        setSelectedStateName([...new Set(stateNames)]);
        setSelectedProviders(uniqueProviders.map(i => i.id));
        setIsSelectAllPatients(true)
        setIsSelectAllProviders(true)
      }
      setTotals([
        { name: "Total Records Found", value: patientReports.data.length },
      ]);
    } catch (err) {
      console.log(err);
      setOpen(true);
      setMessage(err?.response?.data?.message);
    }
  };
  //filters
  const handlePatientNameFilter = (patient, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedPatientsName(patients.map(i => i.id))
      } else {
        setSelectedPatientsName([])
      }
    } else {
      if (selectedPatientsName.includes(patient)) {
        setSelectedPatientsName(
          selectedPatientsName.filter((filter) => filter !== patient)
        );
      } else {
        setSelectedPatientsName([...selectedPatientsName, patient]);
      }
    }
  };

  const handleLocationFilter = (location, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedLocation(locations.map(i => i.id))
      } else {
        setSelectedLocation([])
      }
    } else {
      if (selectedLocation.includes(location)) {
        setSelectedLocation(
          selectedLocation.filter((filter) => filter !== location)
        );
      } else {
        setSelectedLocation([...selectedLocation, location]);
      }
    }
  };

  const handleStatusFilter = (statusit, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedStatus(status.map(i => i.id))
      } else {
        setSelectedStatus([])
      }
    } else {
      if (selectedStatus.includes(statusit)) {
        setSelectedStatus(
          selectedStatus.filter((filter) => filter !== statusit)
        );
      } else {
        setSelectedStatus([...selectedStatus, statusit]);
      }
    }
  };

  const handleCityNameFilter = (city, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedCity(cities)
      } else {
        setSelectedCity([])
      }
    } else {
      if (selectedCity.includes(city)) {
        setSelectedCity(selectedCity.filter((filter) => filter !== city));
      } else {
        setSelectedCity([...selectedCity, city]);
      }
    }
  };

  const handleStateNameFilter = (state, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedStateName(stateNames)
      } else {
        setSelectedStateName([])
      }
    } else {
      if (selectedStateName.includes(state)) {
        setSelectedStateName(
          selectedStateName.filter((filter) => filter !== state)
        );
      } else {
        setSelectedStateName([...selectedStateName, state]);
      }
    }
  };

  const handleVisitTypeFilter = (type, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelecetdVisitType(visitType.map(i => i.id))
      } else {
        setSelecetdVisitType([])
      }
    } else {
      if (selectedVisitType.includes(type)) {
        setSelecetdVisitType(
          selectedVisitType.filter((filter) => filter !== type)
        );
      } else {
        setSelecetdVisitType([...selectedVisitType, type]);
      }
    }
  };
  const handleProviderFilter = (provider, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedProviders(providers.map(i => i.id))
      } else {
        setSelectedProviders([])
      }
    } else {
      if (selectedProviders.includes(provider)) {
        setSelectedProviders(
          selectedProviders.filter((filter) => filter !== provider)
        );
      } else {
        setSelectedProviders([...selectedProviders, provider]);
      }
    }
  };
  const handleClear = () => {
    setSelectedPatientsName([]);
    setSelectedCity([]);
    setSelectedStateName([]);
    setSelecetdVisitType([]);
    setSelectedProviders([]);
    // setDateRange([today.startOf("month"), today]);
    setDateRange([null, null]);
    setDateRangeValue("");
    setCities([]);
    setVisitType([]);
    setPatients([]);
    setFilteredPt([]);
    setProviders([]);
    setFilteredPv([]);
    setStateNames([]);
    setSelectedLocation([]);
    setFilteredLt([]);
    setIsClearCalled(true);
    //getPatientReports({ setHeaders: true, dateRangeValue: "" });
    setPatientReports([])
    setClear(true)
    setIsTableView(true)
    // setIsPivot(false)

    setStatus([]);
    setSelectedStatus([]);
  };
  const exportPDF = () => {
    // if (!isPivot) {
    if (patientReports?.length > 0) {
      const doc = new jsPDF();
      doc.text("Patient Visit Report", 20, 10);
      doc.autoTable({
        styles: { halign: "center" },
        headStyles: { fillColor: [245, 247, 249] },
        html: "#patientVisitReport",
        theme: "plain",
        didParseCell: function (data) {
          if (data.row.index === 0 || data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [255, 255, 255];
          } else {
            data.cell.styles.fillColor = [245, 247, 249];
          }
        },
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(13);
      doc.setTextColor(0, 0, 0);
      doc.text(
        `Total Records Found: ${totals[0].value}`,
        15,
        doc.lastAutoTable.finalY + 10
      );
      doc.save("Patient-Report.pdf");
    } else {
      alert("No Records Found");
    }
    // } else {
    //   const doc = new jsPDF();
    //   doc.text("Patient Visit Report ", 20, 10);
    //   doc.autoTable({
    //     styles: { halign: "center" },
    //     html: "#patientVisitReport",
    //     theme: "plain",
    //   })
    //   doc.save("Patient-Report-Pivot.pdf");
    // }
  }
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("DD/MM/YYYY")
          : value;
  };

  const exportCSV = () => {
    // if (!isPivot) {
    if (patientReports?.length > 0) {
      let csvContent =
        "Appt_Date,Patient_Id,Patient_Name,Provider_Name,Visit_Type,Location,City,State,Zip,Date_of_Birth,Status\r\n";

      patientReports.forEach((patient) => {
        const row = [
          parseNull(patient.visit_date, "NA", "date"),
          patient.patient_ehr_id,
          `${patient.patient_name}`,
          parseNull(patient.provider_name),
          parseNull(patient.visit_type),
          parseNull(patient.location),
          parseNull(patient.city),
          parseNull(patient.state),
          parseNull(patient.zip),
          parseNull(patient.dob, "NA", "date"),
          parseNull(patient.status)
        ];
        csvContent += row.join(",") + "\r\n";
      });

      csvContent += `\r\nTotal_Records_Found: ${totals[0].value}\r\n`;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "Patient-Report.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      alert("No Records Found");
    }
    // } else {
    //   const aggregatedData = {};

    //   patientReports.forEach(record => {
    //     const date = new Date(record.visit_date).toLocaleDateString('en-US');
    //     const provider = record.provider_name;
    //     const visitType = record.visit_type;

    //     const key = `${date}|${provider}|${visitType}`;
    //     if (!aggregatedData[key]) {
    //       aggregatedData[key] = 0;
    //     }
    //     aggregatedData[key] += 1;
    //   });
    //   const result = [
    //     ["Date", "Provider", "Visit Type", "Patient Count"]
    //   ];
    //   Object.keys(aggregatedData).forEach(key => {
    //     const [date, provider, visitType] = key.split('|');
    //     result.push(`\n${[date, provider, visitType, aggregatedData[key]]}`);
    //   });
    //   const blob = new Blob([result], { type: "text/csv;charset=utf-8;" });
    //   const link = document.createElement("a");
    //   if (link.download !== undefined) {
    //     const url = URL.createObjectURL(blob);
    //     link.setAttribute("href", url);
    //     link.setAttribute("download", "Patient-Report.csv");
    //     link.style.visibility = "hidden";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   }
    // }
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!clear) {
      getPatientReports({ dateRange: dateRange });
    }
    if (
      selectedPatientsName?.length > 0 ||
      selectedCity?.length > 0 ||
      selectedStateName?.length > 0 ||
      selectedVisitType?.length > 0 ||
      selectedProviders?.length > 0 ||
      selectedStatus?.length > 0 ||
      selectedLocation?.length > 0
    ) {
      setIsSelectedValue(true);
    } else {
      setIsSelectedValue(false);
    }
  }, [
    selectedPatientsName,
    selectedCity,
    selectedStateName,
    selectedVisitType,
    selectedProviders,
    selectedLocation,
    selectedStatus
  ]);
  React.useEffect(() => {
    getPatientReports({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
    if (
      selectedPatientsName?.length > 0 ||
      selectedCity?.length > 0 ||
      selectedStateName?.length > 0 ||
      selectedVisitType?.length > 0 ||
      selectedProviders?.length > 0 ||
      selectedLocation?.length > 0 ||
      selectedStatus?.length > 0
    ) {
      setIsSelectedValue(true);
    } else {
      setIsSelectedValue(false);
    }
  }, []);
  return (
    <div className="relative flex flex-col w-full overflow-auto no-scrollbar standard-reports" style={{ height: "83vh" }}>
      <Snackbar
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        message={message ? message : "Something went wrong"}
        severity="error"
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message ? message : "Something went wrong"}
        </Alert>
      </Snackbar>
      <ReportHeader
        title="Patient Visit Report"
        exportCSV={exportCSV}
        exportPDF={exportPDF}
        totals={totals}
        total={patientReports.length}
      />
      <div
        className="p-2 d-flex flex-column align-items-center"
        style={{ height: "77vh" }}
      >
        <PatientVisitReportFilter
          getPatientReports={getPatientReports}
          dateRange={dateRange}
          setDateRange={setDateRange}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
          handleClear={handleClear}
          isSelectedValue={isSelectedValue}
          setClear={setClear}
          isTableView={isTableView}
          setIsTableView={setIsTableView}
        // setIsPivot={setIsPivot}
        // isPivot={isPivot}
        />
        <PatientVisitReportTable
          patients={patients}
          location={locations}
          cities={cities}
          stateNames={stateNames}
          visitType={visitType}
          providers={providers}
          patientReports={patientReports}
          handlePatientNameFilter={handlePatientNameFilter}
          handleLocationFilter={handleLocationFilter}
          handleCityNameFilter={handleCityNameFilter}
          handleStateNameFilter={handleStateNameFilter}
          handleVisitTypeFilter={handleVisitTypeFilter}
          handleProviderFilter={handleProviderFilter}
          handleStatusFilter={handleStatusFilter}
          selectedPatientsName={selectedPatientsName}
          selectedCity={selectedCity}
          selectedStateName={selectedStateName}
          selectedVisitType={selectedVisitType}
          selectedProviders={selectedProviders}
          isSelectedValue={isSelectedValue}
          setPatientReports={setPatientReports}
          filteredPt={filteredPt}
          setFilteredPt={setFilteredPt}
          filteredPv={filteredPv}
          setFilteredPv={setFilteredPv}
          selectedLocation={selectedLocation}
          filteredLt={filteredLt}
          setFilteredLt={setFilteredLt}
          isClearCalled={isClearCalled}
          isSelectAllPatients={isSelectAllPatients}
          setIsSelectAllPatients={setIsSelectAllPatients}
          isSelectAllProviders={isSelectAllProviders}
          setIsSelectAllProviders={setIsSelectAllProviders}
          isSelectAllLocations={isSelectAllLocations}
          setIsSelectAllLocations={setIsSelectAllLocations}
          isSelectAllVisitType={isSelectAllVisitType}
          setIsSelectAllVisitType={setIsSelectAllVisitType}
          isSelectAllCity={isSelectAllCity}
          setIsSelectAllCity={setIsSelectAllCity}
          isSelectAllState={isSelectAllState}
          setIsSelectAllState={setIsSelectAllState}
          isSelectAllStatus={isSelectAllStatus}
          setIsSelectAllStatus={setIsSelectAllStatus}
          isTableView={isTableView}
          status={status}
          selectedStatus={selectedStatus}

        // isPivot={isPivot}
        />
      </div>
    </div>
  );
}

export default index;
