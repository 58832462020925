import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "en",
  userData: null,
  token: null,
  loading: false,
  error: null,
  users: [],
  otpVerified: false,
  alertNote: "",
  alertType: "",
};

export const userDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    userLogin: (state, action) => {
      // state.token = action.payload.Token;
      // state.message = action.payload.message;
      state.userData = action.payload.data;
      state.error = action.payload.error;
      state.otpVerified = action.payload.otpVerified || false;
      // localStorage.setItem("token", action.payload.Token);
    },
    userLoginFailure: (state, action) => {
      // state.token = action.payload.Token;
      // state.message = action.payload.message;
      state.userData = null;
      state.error = action.payload;
      state.otpVerified = false;
      // localStorage.setItem("token", action.payload.Token);
    },
    logOut: (state, action) => {
      state.token = null;
      state.userData = null;
      state.error = null;
      state.otpVerified = false;
      // localStorage.removeItem("token");
    },
    fetchUserData: (state, action) => {
      state.userData = action.payload || {};
    },
    fetchUsers: (state, action) => {
      state.users = action.payload || [];
    },
    deleteUser: (state, action) => {
      state.users = state.users.filter((i) => i.id !== action.payload.id) || [];
    },
    refreshError: (state) => {
      state.error = null;
    },
    updateError: (state, action) => {
      state.error = action.payload;
    },
    onboardUser: (state, action) => {},
    otpVerify: (state) => {
      state.otpVerified = true;
      state.error = null;
    },
    setAlertNote: (state, action) => {
      state.alertNote = action.payload.text;
      state.alertType = action.payload.type;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  userLogin,
  logOut,
  fetchUserData,
  userLoginFailure,
  refreshError,
  fetchUsers,
  otpVerify,
  deleteUser,
  updateError,
  setAlertNote,
} = userDetailSlice.actions;

export default userDetailSlice.reducer;
