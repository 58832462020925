import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import styles from "./styles";
import "./ChatInput.css";
import CalendarSvg from "../../assets/svgs/CalendarSvg";
import UsersSvg from "../../assets/svgs/UsersSvg";
import HealthSvg from "../../assets/svgs/HealthSvg";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { Icon } from "@virtuslab/tetrisly-icons";

const ChatInput = ({
  className,
  message,
  onKeyDown,
  setMessage,
  users,
  hashTags,
  tagPatients,
  refList,
  onSend,
  disabled,
  onAddTag,
  editMessage,
  setEditMsg,
  conversationId,
  onUpdate,
}) => {
  const [heading, setHeading] = useState("");
  useEffect(() => {}, [users]);

  const isEdit = editMessage && editMessage?.conversationId === conversationId;

  const closeEdit = () => {
    setEditMsg(null);
    setMessage("");
  };

  const onEdit = () => {
    closeEdit();
    onUpdate();
  };

  return (
    <Grid
      container
      className={
        className
          ? "d-flex align-items-center justify-content-between bg-white"
          : "d-flex align-items-center justify-content-between mx-3 bg-white "
      }
      style={{
        borderRadius: 5,
      }}
    >
      <MentionsInput
        className="border-0 chat-input"
        style={styles}
        placeholder="Type a message"
        value={message}
        onKeyDown={onKeyDown}
        // onChange={onMessageChange}
        onChange={(e, newValue, newPlainTextValue, mentions) => {
          // if (newValue?.length > 4000) {
          //   customToast("You have exceeded the 4000 characters limit", "error");
          // }
          setMessage(newValue);
          // console.log(newPlainTextValue, "newPlainTextValue");
          const patientTag = newPlainTextValue.startsWith("<");
          const hashTag = newPlainTextValue.startsWith("#");
          if (patientTag) {
            setHeading("patients");
          }
          if (hashTag) {
            setHeading("tags");
          }
          if (!patientTag && !hashTag && heading) {
            setHeading("");
          }
        }}
        a11ySuggestionsListLabel={"Suggested mentions"}
        allowSuggestionsAboveCursor={true}
        // customSuggestionsContainer={(children) => {
        //   return (
        //     <div style={{ position: "relative" }}>
        //       <div style={{ position: "absolute", bottom: 200, zIndex: 9999 }}>
        //         Heading
        //       </div>
        //       <div>{children}</div>
        //     </div>
        //   );
        // }}
        customSuggestionsContainer={(data) => {
          // console.log(data, "data");
          const isPatients = heading === "patients";
          return (
            <div {...data.props}>
              {heading && (
                <div
                  className={`p-2 ${
                    isPatients ? "custom-bg-1" : "custom-bg-2"
                  }`}
                >
                  {heading === "patients" ? (
                    <div className="d-flex align-items-center gap-2">
                      <UsersSvg color={"#fff"} />
                      <span className="mention-heading-text">Patients</span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center gap-2">
                      <HealthSvg color={"#fff"} />
                      <span className="mention-heading-text">
                        CareTag Request Types
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div className="suggestion-child">
                {data.props?.children?.map((child) => {
                  const { props, key } = child || {};
                  const {
                    onClick,
                    onMouseEnter,
                    style,
                    suggestion,
                    renderSuggestion,
                  } = props || {};
                  // style && console.log(style?.style, "style");
                  if (renderSuggestion) {
                    return (
                      <div
                        key={child.key}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        style={{
                          ...style?.style,
                          backgroundColor: child.focused ? "#fff" : "#F2F3F3",
                        }}
                        role="button"
                        className={style?.className}
                      >
                        {renderSuggestion(suggestion)}
                      </div>
                    );
                  }
                  return (
                    <div
                      key={child.key}
                      onClick={onClick}
                      onMouseEnter={onMouseEnter}
                      style={{
                        ...style?.style,
                        backgroundColor: child.focused ? "#fff" : "#F2F3F3",
                      }}
                      role="button"
                      className={style?.className}
                    >
                      {suggestion.display}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      >
        <Mention
          trigger="@"
          data={users}
          renderSuggestion={(users) => {
            return <span>{users.display}</span>;
          }}
          markup="@[__display__](__id__)"
          // markup="{{__display__}}"
          displayTransform={(_, display) => `@${display}`}
          // onAdd={onAdd}
          style={{
            backgroundColor: "#fff",
            color: "#2F80ED",
            zIndex: 999,
          }}
          className="mention-div"
        />
        <Mention
          trigger="#"
          data={hashTags}
          // onAdd={onAddTag}
          markup="#[__display__](__id__)"
          displayTransform={(_, display) => `${display}`}
          style={{
            backgroundColor: "#fff",
            color: "#2F80ED",
            zIndex: 999,
          }}
        />
        <Mention
          trigger="<"
          data={tagPatients}
          renderSuggestion={(users) => {
            return (
              <div className="d-flex flex-column">
                <span style={{ fontWeight: 700 }} className="py-1">
                  {users.display?.split("(")?.[0]}
                </span>
                <div className="d-flex align-items-center gap-2">
                  <CalendarSvg color={"#46505B"} />
                  <span style={{ color: "#46505B" }}>{users.dob}</span>
                </div>
              </div>
            );
          }}
          markup="<[__display__](__id__)"
          displayTransform={(_, display) => `< ${display} >`}
          style={{
            backgroundColor: "#fff",
            color: "#F59300",
            zIndex: 999,
          }}
        />
        <Mention
          trigger="-"
          data={refList}
          markup="-[__display__](__id__)"
          displayTransform={(_, display) => `- ${display}`}
        />
      </MentionsInput>
      {!isEdit && (
        <button disabled={disabled} className="btn border-0" onClick={onSend}>
          <Typography className="px-3 send">Send message</Typography>
        </button>
      )}
      {isEdit && (
        <div className="d-flex">
          <button
            disabled={!isEdit || disabled}
            className="btn border-0"
            onClick={closeEdit}
          >
            <CloseSvg />
          </button>
          <button
            disabled={!isEdit || disabled}
            className="btn border-0"
            onClick={onEdit}
          >
            <Icon name="20-check-large" />
          </button>
        </div>
      )}
    </Grid>
  );
};

export default ChatInput;
