import 'react-pivottable/pivottable.css';

import { CircularProgress, Stack, Tooltip } from "@mui/material";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Default_Table } from "../../../../../src/assets/images"
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { HTML5Backend } from "react-dnd-html5-backend";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

const ReportListViewComponent = ({
    component,
    columns,
    data,
    id,
    onPropertyChange,
    setSelectedComponent,
    showAlert,
    isFromChart,
    selectedColumn,
    handleApplyFilter,
    filterObj,
    setFilterObj,
    clearFilter,
    clearAllFilter,
    filters,
    handleSelectColumn,
    setPrimaryProperties,
    primaryProperties,
    dateformat,
    getReportById,
    setIsListViewScreen,
    reportId,
    handleViewFilteredReport,
    isTableDataLoading,
    setIsTableLoading,
    handleListViewFilter,
    handleAddColumnValues,
    isFilterLoading,
    setIsFilterLoading
}) => {
    const [currentColumns, setCurrentColumns] = useState([]);
    const [currentData, setCurrentData] = useState(data);
    const [isdeleteRowOpen, setIsdeleteRowOpen] = React.useState(false);
    const [deleteRowIndex, setDeleteRowIndex] = React.useState(null);
    // const dropdownRef = React.useRef(null);
    // const [openDropdown, setOpenDropdown] = useState(null);
    const [sortColumn, setSortColumn] = useState(null);
    const [hasSortingApplied, setHasSortingApplied] = React.useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedCells, setSelectedCells] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [startCell, setStartCell] = useState(null);
    const [editCell, setEditCell] = React.useState(null); // Track the cell being edited
    const [cellWidth, setCellWidth] = React.useState(null);
    const [openDropdowns, setOpenDropdowns] = useState({});
    const dropdownRefs = React.useRef({});
    const scrollRef = React.useRef();

    const isScrollbarClick = (e) => {
        const scrollElement = scrollRef.current;
        if (!scrollElement) return false;

        const rect = scrollElement.getBoundingClientRect();
        const isVerticalScrollbar = rect.height < scrollElement.scrollHeight;
        const isHorizontalScrollbar = rect.width < scrollElement.scrollWidth;

        const clickX = e.clientX;
        const clickY = e.clientY;

        // Check if the click is inside the vertical scrollbar
        if (isVerticalScrollbar) {
            if (clickX >= rect.right - 20 && clickX <= rect.right) {
                return true;
            }
        }
        // Check if the click is inside the horizontal scrollbar
        if (isHorizontalScrollbar) {
            if (clickY >= rect.bottom - 20 && clickY <= rect.bottom) {
                return true;
            }
        }
        return false;
    };
    const handleClickOutside = (e) => {
        if (isScrollbarClick(e)) {
            return;
        }
        Object.keys(dropdownRefs.current).forEach((column) => {
            if (dropdownRefs.current[column] && !dropdownRefs.current[column].contains(e.target)) {
                setOpenDropdowns((prevState) => ({ ...prevState, [column]: false }));
            }
        });
    };

    const handleColumnClick = (e, col) => {
        // setOpenDropdowns((prevState) => ({
        //     ...prevState,
        //     [col]: !prevState[col],
        // }));
        setOpenDropdowns((prevState) => {
            const updatedState = {};
            Object.keys(prevState).forEach((key) => {
                updatedState[key] = key === col ? !prevState[key] : false;
            });
            if (!(col in prevState)) {
                updatedState[col] = true;
            }
            return updatedState;
        });
        setSortColumn(col);
        handleSelectColumn(col);
        //  setFilterObj({ filter_values: component?.filters?.find((item) => item?.column_name === col)?.filter_values ?? [] })
    };

    const moveColumn = (draggedIndex, targetIndex) => {
        const updatedColumns = [...currentColumns];
        const [movedColumn] = updatedColumns.splice(draggedIndex, 1);
        updatedColumns.splice(targetIndex, 0, movedColumn);

        // Update the component.columnProperties with the new order
        const updatedColumnProperties = [...component.columnProperties];
        const [movedProperty] = updatedColumnProperties.splice(draggedIndex, 1);
        updatedColumnProperties.splice(targetIndex, 0, movedProperty);

        // Update the `component.columnProperties` object directly
        // component.columnProperties = updatedColumnProperties?.map((prop, idx) => ({
        //     ...prop,
        //     new_index: idx
        // }));

        const updateproperties = updatedColumnProperties?.map((prop, idx) => ({
            ...prop,
            new_index: idx
        }))
        setSelectedComponent({
            ...component,
            columnProperties: updateproperties
        });
        onPropertyChange({ columnProperties: updateproperties }, null);

        const filteredColumns = updatedColumns.filter((item) => item !== `${String(component?.table_name?.replace(/ /g, "_"))?.toLowerCase()}_id` && !item?.isDeleted && !(component?.columnProperties?.find(i => i?.name === item)?.isHidden)
        );
        // Reorder data based on the new column order
        const updatedData = currentData?.map(row => {
            const newRow = {};
            updatedColumns.forEach((col) => {
                newRow[col] = row[col];
            });
            return newRow;
        });
        // setCurrentColumns(updatedColumns);
        setCurrentColumns(filteredColumns);
        setCurrentData(updatedData);
    };
    // const handleColumnClick = (event, column) => {
    //     setOpenDropdown(column);
    //     setSortColumn(column);
    //     handleSelectColumn(column);
    //     setFilterObj({ filter_values: component?.filters?.find((item) => item?.column_name === column)?.filter_values ?? [] })
    // };
    // const handleCheckboxChange = (item) => {
    //     if (item == null || item == undefined) {
    //         return;
    //     }
    //     const existingFilters = component?.filters?.otherFilters || {};

    // console.log(existingFilters,">>. Exxisiting Filter ");
    //     const columnAlreadyFiltered = existingFilters?.some(
    //         (f) => f?.column_name === selectedColumn?.name
    //     );
    //     let updatedFilters;
    //     let finalFilters;
    //     const columnFilter = filters?.find((item) => item?.column_name === selectedColumn?.name)
    //     if (columnFilter?.filter_values?.includes(item)) {
    //         setFilterObj({
    //             ...filterObj,
    //             filter_values: filterObj?.filter_values?.filter((val) => val !== item),
    //         });
    //         updatedFilters = existingFilters?.map((f) =>
    //             f?.column_name === selectedColumn?.name
    //                 ? { ...f, filter_values: columnFilter?.filter_values?.filter((val) => val !== item), }
    //                 : f
    //         );
    //         finalFilters = columnAlreadyFiltered
    //             ? updatedFilters
    //             : [...existingFilters, {
    //                 filter_values: columnFilter?.filter_values?.filter((val) => val !== item),
    //                 column_name: selectedColumn?.name,
    //                 column_type: selectedColumn?.type
    //             }];
    //         finalFilters = finalFilters?.filter(
    //             (f) => f?.filter_values && f?.filter_values.length > 0
    //         );
    //         setSelectedComponent({
    //             ...component,
    //             filters: finalFilters
    //         })
    //         onPropertyChange({ filters: finalFilters }, null)
    //     } else {
    //         setFilterObj({ ...filterObj, filter_values: [...filterObj.filter_values, item] });
    //         updatedFilters = existingFilters?.map((f) =>
    //             f?.column_name === selectedColumn?.name
    //                 ? { ...f, filter_values: [...columnFilter.filter_values, item], }
    //                 : f
    //         );
    //         finalFilters = columnAlreadyFiltered
    //             ? updatedFilters
    //             : [...existingFilters, {
    //                 filter_values: [item],
    //                 column_name: selectedColumn?.name,
    //                 column_type: selectedColumn?.type
    //             }];
    //         finalFilters = finalFilters?.filter(
    //             (f) => f?.filter_values && f?.filter_values?.length > 0
    //         );
    //         setSelectedComponent({
    //             ...component,
    //             filters: finalFilters
    //         })
    //         onPropertyChange({ filters: finalFilters }, null)
    //     }
    // };
    const handleCheckboxChange = (value) => {
        // Clone the existing filters object
        let updatedFilters = { ...component?.filters };
        let columnName = selectedColumn?.name

        // Ensure `otherFilters` exists
        if (!updatedFilters.otherFilters) {
            updatedFilters.otherFilters = {};
        }

        // Ensure an array exists for this column
        if (!updatedFilters.otherFilters[columnName]) {
            updatedFilters.otherFilters[columnName] = [];
        }

        // Check if value already exists in the column array
        const index = updatedFilters.otherFilters[columnName].indexOf(value);
        if (index > -1) {
            // Value exists -> Remove it (Uncheck case)
            updatedFilters.otherFilters[columnName] = updatedFilters.otherFilters[columnName].filter(
                (item) => item !== value
            );
            // Remove column if no values left
            if (updatedFilters.otherFilters[columnName].length === 0) {
                delete updatedFilters.otherFilters[columnName];
            }
        } else {
            // Value does not exist -> Add it (Check case)
            updatedFilters.otherFilters[columnName].push(value);
        }
        setSelectedComponent((prev) => {
            return {
                ...prev,
                filters: updatedFilters
            }
        })
        // return updatedFilters;
    };
    const handleSelectAll = (isChecked) => {
        let updatedFilters = { ...filters.otherFilters };

        if (isChecked) {
            // Add all values of the selected column to filters
            updatedFilters[selectedColumn?.name] = selectedColumn?.values?.map(item =>
                typeof item === "object" ? JSON.stringify(item) : item
            );
        } else {
            // Remove the column from filters when unchecked
            delete updatedFilters[selectedColumn?.name];
        }

        setSelectedComponent((prev) => ({
            ...prev,
            filters: {
                ...prev.filters,
                otherFilters: updatedFilters
            }
        }));
    };
    const handleHeaderClick = (column) => {
        handleSelectColumn(column);
    }
    const handleSort = (order) => {
        handleListViewFilter({ sortColumn: sortColumn, order: order === 'clear' ? 'ASC' : order })
        // if (!sortColumn) return;
        if (order === 'clear') {
            //  setCurrentData(data);
            // setOpenDropdown(null);
            setHasSortingApplied(false);
            return;
        }
        // const sortedData = [...currentData]?.sort((a, b) => {
        //     const aValue = a[sortColumn] || '';
        //     const bValue = b[sortColumn] || '';

        //     if (order === 'asc') {
        //         return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        //     } else if (order === 'desc') {
        //         return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
        //     }
        //     return 0;
        // });
        // setCurrentData(sortedData);
        // // setOpenDropdown(null);
        setHasSortingApplied(true);
    };

    // React.useEffect(() => {
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    const handleColumnSet = async () => {
        //  setIsTableLoading(true);
        const filteredColumns = await columns?.filter((item) => {
            return item !== `${String(component?.table_name?.replace(/ /g, "_"))?.toLowerCase()}_id` && !item?.isDeleted && !(component?.columnProperties?.find(i => i?.name === item)?.isHidden)
        });
        const orderedColumns = await columns?.sort((a, b) => {
            const aIndex = component?.columnProperties?.find(prop => prop?.name === a)?.new_index;
            const bIndex = component?.columnProperties?.find(prop => prop?.name === b)?.new_index;
            return aIndex - bIndex;
        });
        await setCurrentColumns(orderedColumns);
        // setCurrentData(data);
        // if (!hasSortingApplied) {
        await setCurrentData(data);
        //}
        //  setIsTableLoading(false);
    }
    const [columnWidths, setColumnWidths] = React.useState({});

    const headerRefs = React.useRef({});

    // const handleMouseDown = (col, e) => {
    //     e.preventDefault(); // Prevent text selection
    //     const table = document.getElementById(`element-${id}`);
    //     const startX = e.clientX;
    //     const startWidth = headerRefs.current[col].offsetWidth;
    //     const startScrollLeft = table ? table.scrollLeft : 0;

    //     const handleMouseMove = (e) => {
    //         const tableScrollLeft = table ? table.scrollLeft : 0;
    //         const scrollDiff = tableScrollLeft - startScrollLeft;
    //         const newWidth = startWidth + (e.clientX - startX) + scrollDiff;

    //         setColumnWidths((prevWidths) => ({
    //             ...prevWidths,
    //             [col]: newWidth > 50 ? newWidth : 50, // Ensure min width
    //         }));

    //         // Change cursor to col-resize while resizing
    //         document.body.style.cursor = 'col-resize';
    //     };

    //     const handleMouseUp = () => {
    //         document.removeEventListener("mousemove", handleMouseMove);
    //         document.removeEventListener("mouseup", handleMouseUp);
    //         // Reset cursor style after resizing
    //         document.body.style.cursor = 'default';
    //     };

    //     document.addEventListener("mousemove", handleMouseMove);
    //     document.addEventListener("mouseup", handleMouseUp);
    // };



    const handleMouseDown = (col, e) => {
        e.preventDefault();
        const table = document.getElementById(`element-${id}`);
        const startX = e.clientX;
        const startWidth = headerRefs.current[col].offsetWidth;
        const startScrollLeft = table ? table.scrollLeft : 0;

        const handleMouseMove = (e) => {
            const tableScrollLeft = table ? table.scrollLeft : 0;
            const scrollDiff = tableScrollLeft - startScrollLeft;
            const newWidth = startWidth + (e.clientX - startX) + scrollDiff;

            const minWidth = 50;
            const finalWidth = Math.max(newWidth, minWidth);

            setColumnWidths((prevWidths) => ({
                ...prevWidths,
                [col]: finalWidth,
            }));

            // Directly update th width to avoid layout shift issues
            if (headerRefs.current[col]) {
                headerRefs.current[col].style.width = `${finalWidth}px`;
            }

            document.body.style.cursor = "col-resize";
        };

        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.body.style.cursor = "default";
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };


    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    React.useEffect(() => {
        handleColumnSet();
    }, [columns, data]);

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <div
                    className="d-flex flex-col"
                    style={{ height: '100%', width: "100%", maxWidth: isFromChart ? '70rem' : "", minWidth: isFromChart ? '70rem' : "" }}
                >
                    <div
                        onClick={() => {
                            setIsListViewScreen(false);
                            reportId && getReportById(reportId);
                        }}
                        className='ml-auto cursor-pointer bg-white'
                    ><CloseIcon /></div>
                    <div
                        style={{ width: "100%", height: '100%', overflow: "auto", padding: '0px 5px 5px 5px' }}
                        className={`datamanagement-custom-scrollbar dashboard-main relative element-${id} `}
                        ref={scrollRef}
                        id={id}
                    >
                        {isTableDataLoading ? <Stack sx={{ color: 'grey.500', height: '100%', alignItems: 'center', justifyContent: 'center' }} spacing={2} direction="row">
                            <CircularProgress color="inherit" disableShrink />
                        </Stack> :
                            <table
                                id={`element-${id}`}
                                className="scroll tablestyle-reportview max-h-100 w-[100%] border-none"
                                style={{ minWidth: 'max-content' }}
                            >
                                <thead style={{ top: '-1px' }}>
                                    <tr>
                                        {currentColumns?.map((col, index) => (
                                            <th
                                                key={index}
                                                id={`header-${index}`}
                                                ref={(el) => (headerRefs.current[col] = el)}
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    padding: "4px",
                                                    backgroundColor: selectedColumn?.name === col ? '#979c9d' : '#edf0f5',
                                                    // width: columnWidths[col] || 'auto',
                                                    width: columnWidths[col] ? `${columnWidths[col]}px` : "auto",
                                                    minWidth: "100px",
                                                    maxWidth: "500px",
                                                }}
                                                className='tablestyle'
                                            >
                                                <div
                                                    className="flex items-center justify-between"
                                                >
                                                    <div className="flex-1 text-center">
                                                        <Tooltip title={col}>
                                                            <span className="text-black"
                                                            >
                                                                {col?.length > 24 ? col?.slice(0, 20) + "..." : col}
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <span
                                                        style={{
                                                            display: "inline-flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            border: "1px solid #D1D5DB",
                                                            backgroundColor: 'white',
                                                            height: '14px',
                                                            width: '14px',
                                                            marginLeft: '6px',
                                                            cursor: 'pointer',
                                                            position: 'relative'
                                                        }}
                                                        onClick={(e) => handleColumnClick(e, col)}
                                                        ref={(el) => (dropdownRefs.current[col] = el)}
                                                    >
                                                        {/* {filters?.find((item) => item?.column_name === col) !== undefined && filters?.find((item) => item?.column_name === col)?.isApplied === true ? */}
                                                        {false ?
                                                            // <FilterListRoundedIcon
                                                            //     sx={{ fontSize: '16px' }} />
                                                            <FilterAltRoundedIcon sx={{ fontSize: '14px' }} />

                                                            : <KeyboardArrowDownIcon
                                                                sx={{ fontSize: '16px' }}
                                                            />
                                                        }
                                                        {openDropdowns[col] && selectedColumn?.name === col ? (
                                                            <Dropdown
                                                                dropdownRef={dropdownRefs.current[col]}
                                                                handleOptionClick={(option) => {
                                                                    if (option === "sortAscending") handleSort("ASC");
                                                                    if (option === "sortDescending") handleSort("DESC");
                                                                    if (option === "clearSort") handleSort("clear");
                                                                    if (option === 'clearfilter') {
                                                                        // clearFilter();
                                                                        handleListViewFilter({ isClearFilter: true })
                                                                    }
                                                                    if (option === 'clearallfilter') {
                                                                        // clearAllFilter();
                                                                        handleListViewFilter({ isClearAllFilter: true })
                                                                    }
                                                                }}
                                                                columnData={selectedColumn}
                                                                filterObj={filterObj}
                                                                handleCheckboxChange={handleCheckboxChange}
                                                                handleApplyFilter={handleListViewFilter}
                                                                isDisable={(option) => {
                                                                    if (option === 'clearSort' && !hasSortingApplied) {
                                                                        return true;
                                                                    }
                                                                    if (option === 'clearfilter' && !filters?.otherFilters?.[selectedColumn?.name]?.length > 0) {
                                                                        return true;
                                                                    }
                                                                    if (option === 'clearallfilter' && !Object.keys(filters?.otherFilters || {}).length > 0) {
                                                                        return true;
                                                                    }
                                                                    return false;
                                                                }}
                                                                filters={filters}
                                                                selectedColumn={selectedColumn}
                                                                index={index}
                                                                isLastColumn={index === currentColumns?.length - 1}
                                                                isLastSecondColumn={index === currentColumns?.length - 2}
                                                                handleSelectAll={handleSelectAll}
                                                                handleAddColumnValues={handleAddColumnValues}
                                                                setOpenDropdowns={setOpenDropdowns}
                                                                isFilterLoading={isFilterLoading}
                                                            />
                                                        ) : ""}

                                                    </span>
                                                    <span
                                                        className="resize-handle"
                                                        style={{
                                                            height: '10px',
                                                            width: '10px',
                                                            position: 'relative'
                                                        }}
                                                        onMouseDown={(e) => handleMouseDown(col, e)}
                                                    >
                                                        <div style={{
                                                            cursor: 'col-resize',
                                                            width: '5px',
                                                            height: '100%',
                                                            position: 'absolute',
                                                            right: '0',
                                                            top: '0',
                                                            zIndex: 1,
                                                        }} />
                                                    </span>
                                                </div>
                                            </th>
                                        ))}
                                        {reportId !== null && component?.tableId !== null && component?.columns?.length !== 0 &&
                                            <th
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    padding: "4px",
                                                    textAlign: "center",
                                                    backgroundColor: '#edf0f5',
                                                }}
                                                className='tablestyle'
                                            >Action</th>
                                        }
                                    </tr>
                                </thead>
                                {(currentData == undefined || currentData?.length === 0 || component?.columns?.length === 0) ?
                                    (component?.tableId === null || component?.columns?.length === 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={currentColumns?.length} className="py-4 text-center">

                                                    <div className="h-[100%] w-[50%] ml-auto mr-auto">
                                                        <img
                                                            src={Default_Table}
                                                            alt="Selected"
                                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                                        />

                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            color: '#919090',
                                                            pointerEvents: 'none',
                                                            textAlign: 'center',
                                                            fontSize: '10px',
                                                            fontFamily: "Inter",
                                                            zIndex: 1,
                                                            backgroundColor: '#ffffff',
                                                            opacity: 1,
                                                            padding: '10px',
                                                            borderRadius: '5px'
                                                        }}>
                                                            Please select columns from the List View Columns of Data Source Panel to create table.
                                                        </div>

                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                        : <tbody>
                                            <tr>
                                                <td colSpan={currentColumns?.length + 1} className="py-4 text-center">
                                                    <p className="text-base font-bold text-primarygrayborder">
                                                        No Records Found
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>

                                    ) : (
                                        <tbody>
                                            {(currentData)?.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {currentColumns?.map((col, colIndex) => {
                                                        const columnProperty = component?.columnProperties?.find(item => item?.name === col);
                                                        let formattedValue = row[col];

                                                        if (formattedValue === null && !columnProperty?.isDataColumn && !columnProperty?.isComputed) {
                                                            if (columnProperty?.type === 'int') {
                                                                formattedValue = "NA";
                                                            } else if (columnProperty?.type === 'date') {
                                                                formattedValue = "NA";
                                                            } else if (columnProperty?.type === 'varchar') {
                                                                formattedValue = "NA";
                                                            }
                                                        } else if (formattedValue === null && columnProperty?.isDataColumn) {
                                                            formattedValue = "";
                                                        } else if (formattedValue === null && columnProperty?.isComputed) {
                                                            formattedValue = 0;
                                                        }
                                                        if (columnProperty) {
                                                            const { format, currency, isPercentage, decimal, isComma } = columnProperty;

                                                            if (format === "Percentage" && isPercentage) {
                                                                // formattedValue = (row[col] * 100)?.toFixed(0);
                                                                formattedValue = (row[col]);
                                                                if (isComma) {
                                                                    formattedValue = parseFloat(formattedValue)?.toLocaleString();
                                                                }
                                                                formattedValue = `${formattedValue}%`;
                                                            }
                                                            const dateFormats = dateformat?.map(item => item.format);

                                                            if (dateFormats?.includes(format)) {
                                                                formattedValue = moment(row[col]).isValid() ? moment(row[col]).format(format) : "Invalid Date";
                                                            }

                                                            else if (format === "Whole Number") {
                                                                formattedValue = Math?.round(row[col]);
                                                                if (isComma) {
                                                                    formattedValue = formattedValue?.toLocaleString();
                                                                }
                                                            } else if (format === "General") {
                                                                formattedValue;
                                                            }
                                                            if (currency) {
                                                                formattedValue = `${currency}${parseFloat(formattedValue)?.toFixed(2)}`;
                                                                if (isComma) {
                                                                    const [integerPart, decimalPart] = formattedValue.split(".");
                                                                    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                                    formattedValue = `${formattedInteger}${decimalPart ? "." + decimalPart : ""}`;
                                                                }
                                                            }
                                                            if (format === "Decimal" && typeof decimal === "number") {
                                                                formattedValue = parseFloat(row[col])?.toFixed(decimal);
                                                                if (isComma) {
                                                                    formattedValue = formattedValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                                }
                                                            }
                                                        }
                                                        if (editCell?.rowIndex === rowIndex && editCell?.colName === col) {
                                                            return (
                                                                <td
                                                                    key={colIndex}
                                                                    style={{
                                                                        whiteSpace: "nowrap",
                                                                        textAlign: "center",
                                                                        backgroundColor: "#e3f2fd",
                                                                        width: `${cellWidth}px`,
                                                                    }}
                                                                    className="tablestyle"
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        autoFocus
                                                                        defaultValue={formattedValue || ""}
                                                                    />
                                                                </td>
                                                            );
                                                        }
                                                        return (
                                                            <td
                                                                id={`cell-${rowIndex}-${col}`}
                                                                key={colIndex}
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    textAlign: "left",
                                                                    padding: "3px",
                                                                }}
                                                                className="tablestyle"
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: "100%",
                                                                        outlineColor: '#2f80ed24 !important',
                                                                        outlineWidth: 'thin !important',
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: "nowrap",
                                                                        maxWidth: `${columnWidths[col] ? columnWidths[col] : 'auto'}px`,
                                                                        minWidth: '100px'
                                                                    }}
                                                                    title={
                                                                        typeof formattedValue === "object" || typeof formattedValue === "boolean"
                                                                            ? JSON.stringify(formattedValue)
                                                                            : formattedValue
                                                                    }
                                                                >
                                                                    {/* {typeof formattedValue === "object" || typeof formattedValue === "boolean"
                                                                        ? JSON.stringify(formattedValue)
                                                                        : formattedValue?.length > 26
                                                                            ? formattedValue?.slice(0, 24) + "..."
                                                                            : formattedValue} */}
                                                                    {
                                                                        typeof formattedValue === "object" || typeof formattedValue === "boolean"
                                                                            ? JSON.stringify(formattedValue)
                                                                            : formattedValue
                                                                    }
                                                                </div>
                                                            </td>
                                                        );
                                                    })}
                                                    {reportId !== null && <td
                                                        colSpan={currentColumns?.length}
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center",
                                                            padding: "1px",
                                                        }}
                                                        className="tablestyle"
                                                    >
                                                        <span
                                                            onClick={() => handleViewFilteredReport(row)}
                                                            className="px-2 py-1 cursor-pointer underline text-xs font-bold text-[#075985] rounded-md w-fit"
                                                        >
                                                            <a>View</a>
                                                        </span>
                                                    </td>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                            </table>
                        }
                    </div>
                </div>
            </DndProvider >
        </>
    );
};
const Dropdown = ({ dropdownRef, index, isLastColumn, setOpenDropdowns, isLastSecondColumn, isDisable, handleOptionClick, columnData, filterObj, handleCheckboxChange, handleApplyFilter, filters, selectedColumn, handleSelectAll, selectedValues, handleAddColumnValues, isFilterLoading }) => {
    const options = [
        { label: "Sort Ascending", value: "sortAscending" },
        { label: "Sort Descending", value: "sortDescending" },
        { label: "Clear Sort", value: "clearSort" },
        { label: "Clear filter", value: "clearfilter" },
        { label: "Clear all filter", value: "clearallfilter" },
        //{ label: "Filter", value: "filter" },
    ];
    const [searchQuery, setSearchQuery] = React.useState("");

    const filteredValues = columnData?.values?.filter((item) => {
        if (item === null || item === undefined) {
            return 'NA';
        }
        const itemString = typeof item === "string" ? item : item?.toString();
        return itemString?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });
    const isApplyDisabled = Object.values(filters?.otherFilters || {}).every((values) => values.length === 0);

    return (
        <div
            ref={dropdownRef}
            className="absolute d-flex flex-col text-sm  rounded border-1 border-gray-400 bg-white p-2 gap-1 items-start"
            style={{
                top: '20px',
                // left: '-10px',
                left: isLastColumn || isLastSecondColumn ? '-127px' : '-10px',
                // left: index === 0 ? "-10px" : "-183px",
                zIndex: 10,
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
            }}
            onClick={(e) => e.stopPropagation()}
        >
            {options?.map((option) => (
                <button
                    key={option?.value}
                    onClick={() => !isDisable(option?.value) && handleOptionClick(option?.value)}
                    className={`w-full ${isDisable(option?.value) ? 'text-gray-500 cursor-not-allowed ' : ' text-gray-800 cursor-pointer hover:bg-gray-200 '} font-normal text-left px-2 pb-1  ${option?.value == 'Clear all filter' ? 'filter-line' : ""}`}
                    style={{
                        transition: "background-color 0.2s",
                        cursor: isDisable(option?.value) ? 'not-allowed' : 'pointer'
                    }}
                >
                    {option?.label}
                </button>
            ))}
            <span className='sticky top-[32px] z-10'>
                <span className='w-full relative inline-flex mb-1'>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e?.target?.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleAddColumnValues(searchQuery, true)
                            }
                        }}
                        className='block p-[3px] pl-[5px] text-gary-500 bg-white border-1 border-gray-100 rounded  text-xs placeholder:text-[#c5c8cb] placeholder:font-normal focus:outline-none focus:border-gray-300 w-full'
                    />
                    <SearchIcon
                        style={{
                            position: 'absolute',
                            right: '5px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#c5c8cb',
                        }}
                    />
                </span>
                <div className='max-h-32 overflow-auto custom-scrollbar d-flex font-normal items-start flex-col gap-[3px]'>
                    {/* <div className=" text-xs gap-2 flex cursor-pointer">
                        <input
                            className={'cursor-pointer'}
                            type="checkbox"
                            id="select-all"
                            checked={
                                selectedColumn?.name &&
                                filters?.otherFilters?.[selectedColumn?.name]?.length === columnData?.values.length
                            }
                            onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                        <label htmlFor="select-all">
                            Select All
                        </label>
                    </div> */}
                    {false ? (
                        <Stack sx={{ color: 'grey.500', height: '100%', width: '100%', paddingTop: '5px', alignItems: 'center', justifyContent: 'center' }} spacing={2} direction="row">
                            <CircularProgress color="inherit" disableShrink size="20px" />
                        </Stack>
                    ) :

                        columnData?.values?.slice()
                            ?.sort((a, b) => {
                                if (a == null && b == null) return 0;
                                if (a == null) return 1;
                                if (b == null) return -1;
                                if (typeof a === 'string' && typeof b === 'string') {
                                    return a.localeCompare(b);
                                }
                                return a > b ? 1 : -1;
                            })
                            ?.map((item, index,) => {
                                const itemValue = typeof item == "object" ? JSON.stringify(item) : item;

                                // Check if the item is present in any column inside filters.otherFilters
                                const isChecked = Object.values(filters?.otherFilters || {}).some(
                                    (values) => values.includes(itemValue)
                                );
                                return (
                                    <div key={index} className={`flex items-center gap-2 text-xs ${item == null || item == undefined ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer opacity-100'}`} >
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${index}`}
                                            value={item}
                                            checked={isChecked}
                                            onChange={() => {
                                                if (item !== null) {
                                                    handleCheckboxChange(typeof item == 'object' ? JSON.stringify(item) : item)
                                                }
                                            }}
                                            className={`${item == null || item == undefined ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                        />
                                        <label htmlFor={`checkbox-${index}`}
                                            className={`filter ${item == null || item == undefined ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                        >
                                            {/* {item == null || item == undefined ? 'NA' : item.toLocaleString()} */}
                                            {item == null || item == undefined
                                                ? 'NA'
                                                : item.toLocaleString().length > 26
                                                    ? `${item.toLocaleString().slice(0, 24)}...`
                                                    : item.toLocaleString()}
                                        </label>
                                    </div>
                                );
                            })}

                </div>
                {/* {selectedColumn?.values?.length < selectedColumn?.totalCount &&
                    <span className='flex justify-end text-xs text-blue-600 p-1 py-2'>
                        <button
                            onClick={() => handleAddColumnValues(searchQuery)}
                        >
                            20+ more...
                        </button>
                    </span>

                } */}
                <span
                    className='flex justify-end text-xs text-blue-600 p-1 py-2 '
                >
                    {/* <button
                            onClick={() => handleAddColumnValues(searchQuery)}
                        >
                            20+ more
                        </button> */}
                    <button
                        onClick={() => handleAddColumnValues(searchQuery)}
                        disabled={selectedColumn?.values?.length >= selectedColumn?.totalCount}
                        className={`${selectedColumn?.values?.length >= selectedColumn?.totalCount ? 'opacity-40 cursor-not-allowed' : 'text-blue-600 cursor-pointer '}`}
                    >
                        20+ more
                    </button>

                </span>
            </span>
            <div className='d-flex justify-end w-full'>
                <button
                    className={`px-2 ml-2 py-[3px]  font-bold text-[12px] btn-bgcolor text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed  }`}
                    // onClick={() => handleApplyFilter({isApplyFilter:true})}
                    onClick={() => {
                        handleApplyFilter({ isApplyFilter: true });
                        setOpenDropdowns({}); // Reset openDropdowns to an empty object
                    }}
                    // disabled={isApplyDisabled}
                    disabled={filters?.otherFilters?.[selectedColumn?.name] == undefined || filters?.otherFilters?.[selectedColumn?.name]?.length <= 0}
                >Apply</button>
            </div>
        </div >
    );
};
export default ReportListViewComponent;
