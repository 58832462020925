import { useRef, useState } from 'react';
import { CheckBox } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FunctionsIcon from '@mui/icons-material/Functions';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import React from 'react'
import ReusableDataModal from './ReusableDataModal'
import SearchIcon from '@mui/icons-material/Search';
import TableViewIcon from '@mui/icons-material/TableView';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
function PropertiesPanel({
    components,
    getTableData,
    selectedComponent,
    setSelectedComponent,
    setSelectedColumn,
    selectedColumn,
    onPropertyChange,
    isExpand,
    computedColumn,
    setComputedColumn,
    setQuery,
    showAlert,
    isModalOpen,
    setIsModalOpen,
    setErrorComputed,
    checkColumnsInExpression,
    query,
    errorComputed,
    addNewDataset,
    errorMessage,
    fetchSchemas,
    fetachTables,
    fetachTableMetadata,
    superset,
    isAddDatasetModalOpen,
    isDatasetLoading

}) {

    // const [isExpand, setIsExpand] = React.useState(true);
    const [isExpandPropertyPanel, setIsExpandPropertyPanel] = React.useState(true);
    const [openItem, setOpenItem] = React.useState(null);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [checkedColumns, setCheckedColumns] = React.useState({});
    const [loadedTables, setLoadedTables] = React.useState({});
    // const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [checkColumnName, setCheckColumnName] = React.useState(null)
    // const filteredTables = components?.filter((table) =>
    //     table.table_name.toLowerCase().includes(searchQuery.toLowerCase())
    // );


    const filteredTables = components?.filter((table) => {
        const tableNameToSearch = table?.new_table_name ? table?.new_table_name : table?.table_name;
        return tableNameToSearch?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });

    const DataBasesRef = useRef(null);
    const schemaRef = useRef(null);
    const tablesRef = useRef(null);




    const [databaseDropdownOpen, setDatabaseDropdownOpen] = useState(false);
    const [schemaDropdownOpen, setSchemaDropdownOpen] = useState(false);
    const [tablesDropdownOpen, setTablesDropdownOpen] = useState(false);


    const [loadingDb, setLoadingDb] = useState(false)
    const [loadingSchema, setLoadingSchema] = useState(false)
    const [loadingTables, setLoadingTables] = useState(false)


    // const handleItemToggle = (itemName) => {
    //     setOpenItem((prevOpenItem) =>
    //         prevOpenItem === itemName ? null : itemName
    //     );
    //     if (table) {
    //         const initialCheckedColumns = table.columnProperties?.reduce((acc, col) => {
    //             acc[col?.name] = true;
    //             return acc;
    //         }, {});

    //         setCheckedColumns(initialCheckedColumns || {});
    //     }
    //     getTableData(table);
    // };

    const handleToggle = (itemName) => {
        setOpenItem((prevOpenItem) => (prevOpenItem === itemName ? null : itemName));
    };

    const handleItemToggle = (itemName, table) => {
        setComputedColumn({ status: false });
        setQuery("");
        // setOpenItem((prevOpenItem) => (prevOpenItem === itemName ? null : itemName));
        if (table) {
            const initialCheckedColumns = table?.columnProperties?.reduce((acc, col) => {
                acc[col?.name] = true;
                return acc;
            }, {});

            setCheckedColumns(initialCheckedColumns || {});

            setLoadedTables((prev) => {
                const updatedLoadedTables = Object?.keys(prev)?.reduce((acc, tableName) => {
                    acc[tableName] = tableName === itemName;
                    return acc;
                }, {});
                updatedLoadedTables[itemName] = true;

                return updatedLoadedTables;
            });

            if (!loadedTables[itemName]) {
                getTableData(table);
            }
        }
    };



    // const handleItemToggle = (itemName, table) => {
    //     setOpenItem((prevOpenItem) => {
    //         const newOpenItem = prevOpenItem === itemName ? null : itemName;
    //         if (newOpenItem !== null && prevOpenItem !== itemName) {
    //             setLoadedTables((prev) => {
    //                 const updatedLoadedTables = Object.keys(prev).reduce((acc, tableName) => {
    //                     acc[tableName] = tableName === itemName;
    //                     return acc;
    //                 }, {});
    //                 updatedLoadedTables[itemName] = true;

    //                 return updatedLoadedTables;
    //             });

    //             if (!loadedTables[itemName]) {
    //                 getTableData(table);
    //             }
    //         }
    //         if (newOpenItem === null) {
    //             return null;
    //         }

    //         return newOpenItem;
    //     });
    //     if (table) {
    //         const initialCheckedColumns = table.columnProperties?.reduce((acc, col) => {
    //             acc[col?.name] = true;
    //             return acc;
    //         }, {});

    //         setCheckedColumns(initialCheckedColumns || {});
    //     }
    // };



    const handleCheckboxChange = async (columnName) => {
        let updatedColumns;
        await setCheckedColumns((prev) => {
            const newCheckedColumns = { ...prev, [columnName]: !prev[columnName] };

            // Find the index of the column in the original columnProperties array
            const columnIndex = selectedComponent?.columnProperties?.findIndex((col) => col.name === columnName);

            // Get currently checked columns
            updatedColumns = Object?.keys(newCheckedColumns)?.filter((col) => newCheckedColumns[col]);

            // Add column at the correct index if it is checked
            if (newCheckedColumns[columnName]) {
                // Remove the column if it already exists in the wrong position
                updatedColumns = updatedColumns?.filter((col) => col !== columnName);

                // Add it at the specified index
                updatedColumns?.splice(columnIndex, 0, columnName);
            } else {
                // Remove the column if it's unchecked
                selectedComponent?.columnProperties?.map((innerColumn) => {
                    if (innerColumn?.isComputed && innerColumn?.expression) {
                        if (innerColumn?.expression?.includes(`\`${columnName}\``)) {
                            // showAlert("The column is used in computed column")
                            setIsModalOpen(true);
                            setCheckColumnName(columnName)
                            return;
                        }

                    } else {
                        updatedColumns = updatedColumns?.filter((col) => col !== columnName);
                    }

                });

            }
            return newCheckedColumns;
        });
        await setSelectedComponent((prevComponent) => ({
            ...prevComponent,
            columns: updatedColumns,
        }));
        await onPropertyChange({ columns: updatedColumns }, null);
        // const errorInExpression = checkColumnsInExpression(selectedColumn?.expression, updatedColumns);
        if (selectedColumn?.isComputed) {
            // Check for missing columns in expression and query
            const errorInExpression = checkColumnsInExpression(selectedColumn?.expression, updatedColumns);
            const errorInQuery = checkColumnsInExpression(query, updatedColumns);

            if (errorInExpression?.length > 0 || errorInQuery?.length > 0) {
                setErrorComputed({
                    status: true,
                    message: `The following columns are missing in the table: ${[...new Set([...errorInExpression, ...errorInQuery])]?.join(", ")}`,
                });
                return;
            } else {
                setErrorComputed({
                    status: false,
                    message: "",
                });
            }

            // Check for missing columns in computed_expression
            const missingInComputedExpression = checkColumnsInExpression(selectedColumn?.computed_expression, updatedColumns);

            if (missingInComputedExpression?.length > 0) {
                setErrorComputed({
                    status: true,
                    message: `This Expression contain Computed columns that use missing columns: ${missingInComputedExpression?.join(", ")}`,
                });
                return;
            } else {
                setErrorComputed({
                    status: false,
                    message: "",
                });
            }
        }

    };

    const handleConfirmRemove = () => {

        if (selectedColumn?.isComputed) {
            // Check for missing columns in expression and query
            const errorInExpression = checkColumnsInExpression(selectedColumn?.expression, selectedComponent?.columns);
            const errorInQuery = checkColumnsInExpression(query, selectedComponent?.columns);

            if (errorInExpression?.length > 0 || errorInQuery?.length > 0) {
                setErrorComputed({
                    status: true,
                    message: `The following columns are missing in the table: ${[...new Set([...errorInExpression, ...errorInQuery])]?.join(", ")}`,
                });
                setIsModalOpen(false);
                return;
            } else {
                setErrorComputed({
                    status: false,
                    message: "",
                });
            }

            // Check for missing columns in computed_expression
            const missingInComputedExpression = checkColumnsInExpression(selectedColumn?.computed_expression, selectedComponent?.columns);

            if (missingInComputedExpression?.length > 0) {
                setErrorComputed({
                    status: true,
                    message: `This Expression contain Computed columns that use missing columns: ${missingInComputedExpression?.join(", ")}`,
                });
                setIsModalOpen(false);
                return;
            } else {
                setErrorComputed({
                    status: false,
                    message: "",
                });
            }
        }
        // Remove column permanently
        setCheckedColumns((prev) => {
            const newCheckedColumns = { ...prev };
            newCheckedColumns[checkColumnName] = false;
            return newCheckedColumns;
        });

        setSelectedComponent((prevComponent) => ({
            ...prevComponent,
            columns: prevComponent?.columns?.filter((col) => col !== checkColumnName),
        }));

        onPropertyChange(
            {
                columns: selectedComponent?.columns?.filter((col) => col !== checkColumnName),
            },
            null
        );

        setIsModalOpen(false);
        setCheckColumnName("");
    };

    // superset
    const handleDbSelect = async (option) => {
        superset?.setselectedDatabse(option);
        setLoadingSchema(true);
        const result = await fetchSchemas(option);
        console.log(result, ">>> Result ");
        if (!result || result?.length <= 0) {
            showAlert(result?.message, 'error');
            setLoadingSchema(false);
            return;
        }
        superset?.setSchemas(result);
        setLoadingSchema(false);
    }
    const handleSelectSchema = async (schema) => {
        superset?.setSelectedSchema(schema);
        setLoadingTables(true);
        const tables = await fetachTables(schema, superset?.selectedDatabase);
        console.log(tables, ">>> Tablesdddd");
        superset?.setTables(tables);
        setLoadingTables(false);
    }

    const handleTableSelect = async (table) => {
        console.log(table, ">>> Table");
        superset?.setSelectedTable(table);
        const response = await fetachTableMetadata(superset?.selectedSchema, superset?.selectedDatabase, table);
        console.log(response, ">>> Response METa data");
        // setTableMetadata(response?.columns);
    }

    const handleOutsideClick = (event) => {
        console.log("LLLLEVENT");
        console.log(DataBasesRef?.current, ">>> YYYYY")
        if (DataBasesRef?.current && !DataBasesRef?.current?.contains(event?.target)) {
            setDatabaseDropdownOpen(false);
        }
        if (schemaRef?.current && !schemaRef?.current?.contains(event?.target)) {
            setSchemaDropdownOpen(false);
        }
        if (tablesRef?.current && !tablesRef?.current?.contains(event?.target)) {
            setTablesDropdownOpen(false)
        }
    };


    React.useEffect(() => {
        if (selectedComponent) {
            const initialCheckedColumns = selectedComponent?.columns?.reduce((acc, col) => {
                acc[col] = true;
                return acc;
            }, {});
            setCheckedColumns(initialCheckedColumns || {});
        }
    }, [selectedComponent]);

    React.useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);


    return (
        <>
            <ReusableDataModal
                open={isModalOpen}
                onClose={() => {
                    if (checkColumnName) {
                        setCheckedColumns((prev) => {
                            const newCheckedColumns = { ...prev, [checkColumnName]: true };

                            let updatedColumns = Object?.keys(newCheckedColumns)?.filter(
                                (col) => newCheckedColumns[col]
                            );
                            updatedColumns = updatedColumns?.filter((col) => col !== checkColumnName);
                            const columnIndex = selectedComponent?.columnProperties?.findIndex(
                                (col) => col?.name === checkColumnName
                            );
                            updatedColumns.splice(columnIndex, 0, checkColumnName);
                            setSelectedComponent((prevComponent) => ({
                                ...prevComponent,
                                columns: updatedColumns,
                            }));
                            onPropertyChange({ columns: updatedColumns }, null);
                            return newCheckedColumns;
                        });
                        setCheckColumnName(null);
                    }
                    setIsModalOpen(false);
                    setErrorComputed({
                        status: false,
                        message: "",
                    });
                }}
                title={"Delete Column ?"}
            >
                <div className="mx-2 my-2 d-flex flex-col">
                    <div className="text-[15px] mt-1">{`The column is used in computed column.Are you sure you want to delete this Column ?`}</div>
                    <div className="self-end mt-2">
                        <button
                            onClick={handleConfirmRemove}
                            className="px-4 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
                        >
                            Yes
                        </button>
                        <button
                            onClick={() => {
                                if (checkColumnName) {
                                    setCheckedColumns((prev) => {
                                        const newCheckedColumns = { ...prev, [checkColumnName]: true };

                                        let updatedColumns = Object?.keys(newCheckedColumns)?.filter(
                                            (col) => newCheckedColumns[col]
                                        );

                                        updatedColumns = updatedColumns?.filter((col) => col !== checkColumnName);

                                        const columnIndex = selectedComponent?.columnProperties?.findIndex(
                                            (col) => col?.name === checkColumnName
                                        );

                                        updatedColumns?.splice(columnIndex, 0, checkColumnName);

                                        setSelectedComponent((prevComponent) => ({
                                            ...prevComponent,
                                            columns: updatedColumns,
                                        }));

                                        onPropertyChange({ columns: updatedColumns }, null);

                                        return newCheckedColumns;
                                    });
                                    setCheckColumnName(null);
                                }
                                setIsModalOpen(false);
                                setErrorComputed({
                                    status: false,
                                    message: "",
                                });
                            }}
                            className="px-4 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
                        >
                            No
                        </button>
                    </div>
                </div>
            </ReusableDataModal>
            <div
                // className={`sidebar menu-main ${true ? "menu-show" : "menu-hide"}   d-flex flex-col ${isExpand ? 'min-w-52' : 'min-w-5 w-10'} overflow-auto bg-slate-100 border border-slate-300 custom-scrollbar`}
                className={`sidebar menu-main ${superset?.isDatasetView ? 'px-2 py-2 ' : ""}   ${true ? "menu-show" : "menu-hide"}   d-flex flex-col ${isExpandPropertyPanel ? 'w-64' : 'min-w-5 w-10'} no-scrollbar bg-[#edf0f5] border-l border-slate-300 custom-scrollbar`}
                style={{ overflow: 'auto' }}
            >
                <span className={`d-flex items-center ${superset?.isDatasetView ? "justify-end" : "justify-between "} ${isExpandPropertyPanel ? 'flex-row' : 'flex-col-reverse'}  px-2 py-1 sticky top-0 bg-[#edf0f5] transition-all duration-300 ease-in-out`}>
                    {/* <p className={`m-0 text-sm font-bold text-gray-600 whitespace-nowrap ${!isExpandPropertyPanel ? 'rotate-90 translate-y-5' : "rotate-0 translate-y-0 "}`}>Data Sources</p> */}
                    {!superset?.isDatasetView && <p className={`m-0 text-sm font-bold text-gray-600 whitespace-nowrap ${!isExpandPropertyPanel ? 'rotate-90 translate-y-5 pl-8' : "rotate-0 translate-y-0 "}`}>Data Sources</p>}
                    <KeyboardDoubleArrowRightOutlinedIcon
                        // className={`font-bold text-[#075985] cursor-pointer ${isExpandPropertyPanel ? 'rotate-0' : 'rotate-180'}`}
                        className={`font-bold text-[#075985] cursor-pointer ${isExpandPropertyPanel ? 'rotate-180' : 'rotate-0'}`}
                        onClick={() => {
                            setIsExpandPropertyPanel(!isExpandPropertyPanel);
                        }}
                        sx={{
                            width: '30px !important'
                        }} />
                </span>
                {isExpandPropertyPanel &&
                    (superset?.isDatasetView ?
                        <>
                            {/* <span className="relative w-full" ref={DataBasesRef}>
                                <span className='text-gray-600 font-semibold text-sm'>Databases</span>
                                <span className={`block   menu-value h-[30px] bg-white px-2 pl-[3px] inputborder-custome w-full focus:outline-none  rounded-[3px] ${(selectedColumn?.name === 'id' || selectedColumn?.name?.endsWith('_id')) && selectedColumn?.type === 'int' ? "cursor-default" : " cursor-pointer focus:border-gray-600"}`}>
                                    {selectedDatabase?.backend} {" "} {selectedDatabase?.database_name}
                                </span>
                                <KeyboardArrowDownIcon
                                    className={`absolute right-0 top-1/2 transform -translate-y-1/2 ${(selectedColumn?.name === 'id' || selectedColumn?.name?.endsWith('_id')) && selectedColumn?.type === 'int' ? "text-[#c5c8cb] cursor-not-allowed" : "text-black cursor-pointer"}`}
                                    // onClick={() => setDataTypeDropdownOpen(!dataTypeDropdownOpen)}
                                    onClick={() => {
                                        setDatabaseDropdownOpen(!databaseDropdownOpen);
                                    }}
                                />
                                {console.log(dbConnections, ">>>>> Databse connections")}
                                {databaseDropdownOpen && (
                                    <span
                                        className={`absolute z-10 bg-white border border-gray-400 pr-2 pl-2 pb-1 pt-1 rounded-md shadow-md mt-1 w-full flex flex-col`}
                                    >
                                        {dbConnections?.map((option, index) => (
                                            <span
                                                key={index}
                                                className="cursor-pointer hover:bg-gray-100 menu-value p-1"
                                                onClick={() => handleDbSelect(option)}
                                            >
                                                {option?.backend}{" "}{option?.database_name}
                                            </span>
                                        ))}
                                    </span>
                                )}
                            </span>
                            <span className="relative w-full" ref={schemaRef}>
                                <span className='text-gray-600 font-semibold text-sm'>Schema</span>
                                <span className={`block   menu-value h-[30px] bg-white px-2 pl-[3px] inputborder-custome w-full focus:outline-none  rounded-[3px] ${(selectedColumn?.name === 'id' || selectedColumn?.name?.endsWith('_id')) && selectedColumn?.type === 'int' ? "cursor-default" : " cursor-pointer focus:border-gray-600"}`}>
                                    {selectedSchema}
                                </span>
                                <KeyboardArrowDownIcon
                                    className={`absolute right-0 top-1/2 transform -translate-y-1/2 ${(selectedColumn?.name === 'id' || selectedColumn?.name?.endsWith('_id')) && selectedColumn?.type === 'int' ? "text-[#c5c8cb] cursor-not-allowed" : "text-black cursor-pointer"}`}
                                    // onClick={() => setDataTypeDropdownOpen(!dataTypeDropdownOpen)}
                                    onClick={() => {
                                        setSchemaDropdown(!schemaDropdown)
                                    }}
                                />
                                {console.log(dbConnections, ">>>>> Databse connections")}
                                {schemaDropdown && (
                                    <span
                                        className={`absolute z-10 bg-white border border-gray-400 pr-2 pl-2 pb-1 pt-1 rounded-md shadow-md mt-1 w-full flex flex-col`}
                                    >
                                        {schemas?.map((option, index) => (
                                            <span
                                                key={index}
                                                className="cursor-pointer hover:bg-gray-100 menu-value p-1"
                                                onClick={() => handleSelectSchema(option)}
                                            >
                                                {option}
                                            </span>
                                        ))}
                                    </span>
                                )}
                            </span>

                            <span className="relative w-full" ref={tablesRef}>
                                <span className='text-gray-600 font-semibold text-sm'>Tables</span>
                                <span className={`block   menu-value h-[30px] bg-white px-2 pl-[3px] inputborder-custome w-full focus:outline-none  rounded-[3px] ${(selectedColumn?.name === 'id' || selectedColumn?.name?.endsWith('_id')) && selectedColumn?.type === 'int' ? "cursor-default" : " cursor-pointer focus:border-gray-600"}`}>
                                    {selectedTable?.value}
                                </span>
                                <KeyboardArrowDownIcon
                                    className={`absolute right-0 top-1/2 transform -translate-y-1/2 ${(selectedColumn?.name === 'id' || selectedColumn?.name?.endsWith('_id')) && selectedColumn?.type === 'int' ? "text-[#c5c8cb] cursor-not-allowed" : "text-black cursor-pointer"}`}
                                    // onClick={() => setDataTypeDropdownOpen(!dataTypeDropdownOpen)}
                                    onClick={() => {
                                        setTablesDropdown(!tablesDropdwn)
                                    }}
                                />
                                {console.log(dbConnections, ">>>>> Databse connections")}
                                {tablesDropdwn && (
                                    <span
                                        className={`absolute z-10 bg-white border border-gray-400 pr-2 pl-2 pb-1 pt-1 rounded-md shadow-md mt-1 w-full flex flex-col`}
                                    >
                                        {tables?.map((option, index) => (
                                            <span
                                                key={index}
                                                className="cursor-pointer hover:bg-gray-100 menu-value p-1"
                                                onClick={() => handleTableSelect(option)}
                                            >
                                                {option?.value}
                                            </span>
                                        ))}
                                    </span>
                                )}
                            </span> */}
                            <Dropdown
                                label="Databases"
                                selectedValue={(superset?.selectedDatabase?.backend || "") + " " + (superset?.selectedDatabase?.database_name || "Select Database")}
                                options={superset?.dbConnections}
                                onSelect={handleDbSelect}
                                isLoading={superset?.isDatabseLoading}
                                dropdownOpen={databaseDropdownOpen}
                                setDropdownOpen={setDatabaseDropdownOpen}
                                refi={DataBasesRef}
                                selectedDatabase={superset?.selectedDatabase}
                            />
                            <Dropdown
                                label="Schema"
                                selectedValue={superset?.selectedSchema}
                                options={superset?.schemas?.filter((item) => item !== 'information_schema')}
                                onSelect={handleSelectSchema}
                                isLoading={loadingSchema}
                                dropdownOpen={schemaDropdownOpen}
                                setDropdownOpen={setSchemaDropdownOpen}
                                refi={schemaRef}
                            />
                            <Dropdown
                                label="Tables"
                                selectedValue={superset?.selectedTable?.value}
                                options={superset?.tables}
                                onSelect={handleTableSelect}
                                isLoading={loadingTables}
                                dropdownOpen={tablesDropdownOpen}
                                setDropdownOpen={setTablesDropdownOpen}
                                refi={tablesRef}
                            />
                            <span className='flex self-end mx-2 mt-2 mb-1'>
                                <button
                                    className="pl-2 flex pr-3 py-0.5 font-semibold text-white rounded-md btn-bgcolor text-xs disabled:opacity-40 disabled:cursor-not-allowed"
                                    onClick={() => {
                                        addNewDataset()
                                    }}
                                    disabled={
                                        !superset?.selectedSchema ||
                                        !superset?.selectedSchema ||
                                        !superset?.selectedTable ||
                                        superset?.sqlQuery === '' ||
                                        superset?.tableMetaData?.tableData?.data?.length <= 0 ||
                                        errorMessage !== null ||
                                        superset?.datasettableloading ||
                                        isAddDatasetModalOpen ||
                                        isDatasetLoading
                                    }
                                >
                                    <AddIcon className='datasetbtn' />
                                    <p className='m-0 pl-1 self-center'>
                                        Create Dataview
                                    </p>
                                </button>
                            </span>
                        </>
                        : <>
                            <span className='sticky top-[32px] z-10'>
                                <span className='w-full relative inline-flex m-2'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e?.target?.value)}
                                        className='block p-[3px] pl-[5px] text-[#9ca3af] font-semibold bg-white border-1 border-gray-100 rounded  text-sm w-[92%] placeholder:text-[#c5c8cb] placeholder:font-normal focus:outline-none focus:border-gray-300'
                                    />
                                    <SearchIcon
                                        style={{
                                            position: 'absolute',
                                            right: '24px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            color: '#c5c8cb',
                                        }}
                                    />
                                </span>
                            </span>
                            <span style={{ overflowY: 'auto', maxHeight: '100%' }} className='no-scrollbar'>
                                {filteredTables?.map((table, index) => {
                                    return (
                                        <div key={`table-${index}`}>
                                            <span className='text-sm'>
                                                <SidebarSection
                                                    textColor={selectedComponent?.table_name === table?.table_name ? "text-[#075985]" : "text-gray-500"}
                                                    bgColor={selectedComponent?.table_name === table?.table_name ? "bg-[#d2d4d5]" : ""}
                                                    title={
                                                        <div className="flex items-center">
                                                            <TableViewIcon className={`mr-2 ${selectedComponent?.table_name === table?.table_name ? "text-[#075985]" : "text-gray-500"}`} />
                                                            <Tooltip title={table?.table_name}>
                                                                <span
                                                                    onClick={() => {
                                                                        handleItemToggle(table?.table_name, table)
                                                                    }}
                                                                    className={`${selectedComponent?.table_name === table?.table_name ? "text-[#075985] underline" : "text-gray-500"}`}>
                                                                    {/* {table?.new_table_name?.length > 25 ? table?.new_table_name?.slice(0, 24) + '...' : table?.new_table_name} */}
                                                                    {table?.table_name?.length > 21 ? table?.table_name?.slice(0, 20) + '...' : table?.table_name}
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    isOpen={openItem === table?.table_name}
                                                    onToggle={() => {
                                                        handleToggle(table?.table_name)
                                                    }}
                                                >
                                                    {table?.columnProperties?.filter((item) =>
                                                    // item?.name !== `${String(selectedComponent?.table_name?.replace(/ /g, "_"))?.toLowerCase()}_id` && !item?.isDeleted && !item?.isHidden)
                                                    {
                                                        const idColumnName = `${String(table?.table_name?.replace(/ /g, "_"))?.toLowerCase()}_id`;
                                                        return item?.name !== idColumnName && !item?.isDeleted && !item?.isHidden;
                                                    })
                                                        ?.map((item) => {
                                                            return (
                                                                <div className='d-flex flex-col ml-8 mt-1'>
                                                                    <span className='d-flex'
                                                                    // onClick={() => getTableData(table)}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            className={`${selectedComponent?.table_name === table?.table_name ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                                            checked={
                                                                                selectedComponent?.table_name === table?.table_name
                                                                                    ? checkedColumns[item?.name] || false
                                                                                    : false
                                                                            }
                                                                            disabled={selectedComponent?.table_name !== table?.table_name}
                                                                            onChange={() => {
                                                                                handleCheckboxChange(item?.name);
                                                                            }}
                                                                        />
                                                                        {/* <Tooltip title={item?.name}> */}
                                                                        <span
                                                                            className={`text-sm pl-3 ${selectedComponent?.table_name === table?.table_name && selectedColumn?.name === item?.name ? 'text-[#075985] font-bold' : 'text-gray-800'}
                                                                  `}
                                                                        //   ${computedColumn?.name === item?.name ? "pl-2" : "pl-3"}
                                                                        >
                                                                            {/* {computedColumn?.name === item?.name && (
                                                                    <FunctionsIcon className={`${selectedColumn?.name === item?.name ? 'text-[#075985]' : 'text-gray-500'}`} />
                                                                )} */}
                                                                            {item?.name?.length > 28 ? item?.name?.slice(0, 22) + '...' : item?.name}
                                                                        </span>
                                                                        {/* </Tooltip> */}
                                                                        {/* <Tooltip
                                                                title={
                                                                    table?.columnProperties.find((col) => col.name === item?.name)?.new_column_name || item?.name
                                                                }
                                                            >
                                                                <span className="text-sm pl-3">
                                                                    {(() => {
                                                                        const name =
                                                                            table?.columnProperties.find((col) => col.name === item?.name)?.new_column_name || item?.name;
                                                                        return name?.length > 28 ? name.slice(0, 24) + '...' : name;
                                                                    })()}
                                                                </span>
                                                            </Tooltip> */}
                                                                    </span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </SidebarSection>
                                            </span>
                                        </div>
                                    )
                                })}
                            </span>
                        </>)
                }
            </div>
        </>
    )
}
const SidebarSection = ({ title, isOpen, onToggle, children, isMenuExpand, icon, textColor, bgColor }) => {
    return (
        <div
            className={`sidebar-section`}
        >
            <div
                // className="sidebar-section-header cursor-pointer px-2 py-2"
                className={`sidebar-section-header cursor-pointer px-2 py-2 ${bgColor}`}
            >
                <div className={`d-flex ${isMenuExpand === false ? 'justify-content-center' : 'justify-between'}`}>
                    {true ? <div className="d-flex w-full  items-center">
                        <button
                            className="toggle-button"
                            onClick={onToggle}
                        >
                            {isOpen ? (
                                <ExpandMoreIcon className={textColor} />
                            ) : (
                                <KeyboardArrowRightIcon className={textColor} />
                            )}
                        </button>
                        <span
                            className="font-semibold d-flex items-center gap-2 text-sm text-gray-500"
                        >
                            {icon}{title}
                        </span>
                    </div> : <span className="font-semibold text-sm text-white">{icon}</span>}
                </div>
            </div>
            {isOpen && (
                <div className="sidebar-section-content">{children}</div>
            )}
        </div>
    );
};

export default PropertiesPanel

const Dropdown = ({
    label,
    selectedValue,
    options,
    onSelect,
    isLoading,
    dropdownOpen,
    setDropdownOpen,
    refi,
    selectedDatabase
}) => {
    return (
        <div className="relative w-full mb-2" ref={refi}>
            <span className="text-gray-600 font-semibold text-sm">{label}</span>
            <div
                className={`flex items-center justify-between menu-value h-[30px] bg-white pl-2 pr-1 border border-gray-300 w-full focus:outline-none rounded-[5px] cursor-pointer transition-all ${dropdownOpen ? "border-gray-600 shadow-md" : ""
                    }`}
                // onClick={() => setDropdownOpen(!dropdownOpen)}
                onClick={() => !isLoading && setDropdownOpen(!dropdownOpen)}
            >
                <span className='text-[13px] text-[#7e8387]'
                    title={selectedDatabase?.database_name && selectedDatabase?.database_name !== "Select Database"
                        ? selectedDatabase.database_name
                        : ""
                    }>
                    {/* {selectedValue || `Select ${label}`} */}
                    {selectedDatabase?.database_name && selectedDatabase?.database_name !== "Select Database" ? (
                        <>
                            <span className="bg-gray-200 text-gray-700 px-2 py-1 self-center rounded-md text-xs mr-2">
                                {selectedDatabase.backend}
                            </span>
                            {/* {selectedDatabase.database_name} */}
                            {selectedDatabase.database_name.length > 15
                                ? `${selectedDatabase.database_name.slice(0, 14)}...`
                                : selectedDatabase.database_name}
                        </>
                    ) : (
                        selectedValue || `Select ${label}`
                    )}
                </span>
                {isLoading ? (
                    <CircularProgress size={18} color="inherit" />
                ) : (
                    <KeyboardArrowDownIcon className="text-[#7e8387]" />
                )}
            </div>
            {dropdownOpen && (
                <div
                    className="absolute z-10 bg-white border border-gray-300 rounded-[5px] shadow-md mt-1 w-full max-h-40 overflow-y-auto  transition-all custom-scrollbar"
                >
                    {options?.length > 0 ? (
                        options.map((option, index) => (
                            <div
                                key={index}
                                className="cursor-pointer text-sm hover:bg-gray-100 px-3 py-2"
                                onClick={() => {
                                    onSelect(option);
                                    setDropdownOpen(false);
                                }}
                                title={label === 'Databases' ? option?.database_name : ""}
                            >
                                {/* {label === 'Databases' ? `${option?.backend + " " + option?.database_name}` : label === 'Tables' ? option?.value : option} */}

                                {label === 'Databases' ? (
                                    <>
                                        <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-md text-xs mr-2">
                                            {option?.backend}
                                        </span>
                                        {/* {option?.database_name} */}
                                        {option?.database_name?.length > 18
                                            ? `${option.database_name.slice(0, 15)}...`
                                            : option?.database_name}
                                    </>
                                ) : label === 'Tables' ? (
                                    option?.value
                                ) : (
                                    option
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500 p-2 text-center text-sm">No options available</div>
                    )}
                </div>
            )}
        </div>
    );
};