import React, { useEffect, useRef, useState } from "react";

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ColumnIcon from "../../../../../assets/svgs/ColumnSvg";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import FunctionIcon from "../../../../../assets/svgs/FunctionSvg";
import { Tooltip } from "@mui/material";

const SummeryComponent = (
    {
        id,
        stopTableDrag,
        properties,
        onPropertyChange,
        setSelectedComponent,
        tables,
        showAlert,
        calculateExpressions,
        selectedComId,
        component,
        isAddingMeasure,
        setIsAddingMeasure,
        newMeasure,
        handleAddMeasure,
        setNewMeasure,
        showDropdown,
        setShowDropdown,
        usedDataSources
    }) => {
    const aggregationFunctions = [
        "SUM",
        "AVG",
        "COUNT",
        "MAX",
        "MIN",
        "DISTINCTCOUNT"
    ];
    const [measures, setMeasures] = useState([]);

    const [isEditMesure, setIsEditMeasure] = useState(false);

    const suggestionDropDownRef = React.useRef();
    const [dropdownLeft, setDropdownLeft] = React.useState(0);
    const [filteredFunctions, setFilteredFunctions] = useState(aggregationFunctions);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [activeElementIndex, setActiveElementIndex] = React.useState(0);
    const [query, setQuery] = React.useState("")
    const inputRef = useRef(null);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
    const targetDivRef = useRef(null);

    const selectedComponentColumns = usedDataSources?.length > 0 ? tables?.filter(item => item?.tableMetaData?.table_id === usedDataSources[0])?.flatMap(table =>
        table?.columns
            ?.filter(item =>
                (item?.type === 'integer' || item?.type === 'int' || item?.type === 'numeric') &&
                item?.name !== 'id' &&
                !item?.name?.endsWith('_id')
            )
            ?.map(column => `${table?.table_name}[${column.name}]`)
    ) : [];
    // const handleAddMeasure = () => {
    //     setIsAddingMeasure(true); // Show the input fields for adding a measure
    //     setNewMeasure({ ...newMeasure, status: true })
    // };
    function parseExpression(expression) {
        const match = expression.match(/(\w+)\[(\w+)\]/);
        if (match) {
            return {
                tableName: match[1],
                columnName: match[2]
            };
        }
        return null;
    }
    const replaceTableAndColumnIds = (expr, meta, aggFuncs) => {
        const funcRegex = new RegExp(
            `\\b(${aggFuncs?.join("|")})\\(([^\\[\\]]+)\\[([^\\[\\]]+)\\]\\)`,
            "g"
        ); // Matches aggregation functions like SUM(table[column])

        return expr.replace(funcRegex, (match, func, tableName, columnName) => {
            // Find the table metadata
            const table = meta.find((t) => t.table_name === tableName);
            if (!table) return match; // Return original if table not found

            // Find the column metadata
            const column = table.columnMetaData.find(
                (col) => col.column_name === columnName
            );
            if (!column) return match; // Return original if column not found

            // Replace table_name[column_name] with table_id[column_id]
            return `${func}(<${table.tableMetaData.table_id}>[${column.column_id}])`;
        });
    };

    const handleProceed = async () => {
        try {
            if (newMeasure.name.trim() && query) {
                const parsedExpression = replaceTableAndColumnIds(query, tables, aggregationFunctions);
                const measureText = `${newMeasure.name} = ${query}`;

                const result = await calculateExpressions({
                    expressions: [
                        {
                            component_id: id,
                            expression: parsedExpression
                        }
                    ]
                });
                if (!result) return;

                if (isEditMesure === true) {
                    const updatedMeasures = properties.measures?.map((item) => {
                        if (item?.name === newMeasure?.oladName) {
                            item.name = newMeasure.name
                            item.expression = query
                            item.computed_expression = result?.data?.find(item => item?.component_id === id)?.expression
                            item.result = result?.data?.find(item => item?.component_id === id)?.result
                            return item
                        }
                        return item
                    });
                    onPropertyChange({ measures: updatedMeasures });
                    setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                            ...prev.properties,
                            measures: updatedMeasures,
                        },
                    }));
                    setIsEditMeasure(false);
                    setNewMeasure({ name: "", expression: "", status: false, oladName: "" });
                    setQuery("")
                    setIsAddingMeasure(false); // Hide the input fields
                } else {
                    const updatedMeasures = [...properties.measures, {
                        name: newMeasure.name,
                        expression: query,
                        computed_expression: result?.data?.find(item => item?.component_id === id)?.expression,
                        result: result?.data?.find(item => item?.component_id === id)?.result
                    }];
                    onPropertyChange({ measures: updatedMeasures });
                    setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                            ...prev.properties,
                            measures: updatedMeasures,
                        },
                    }));
                }
                setNewMeasure({ name: "", expression: "", status: false, oladName: "" });
                setQuery("")
                setIsAddingMeasure(false); // Hide the input fields
            } else {
                showAlert("Please provide both name and expression for the measure.");
            }
        } catch (err) {
            console.log(err);
            showAlert(err?.data?.message, "error");
        }
    };
    const handleEditMeasure = async (measure, index) => {
        setIsEditMeasure(true);
        setIsAddingMeasure(true);

        setQuery(measure?.expression);
        setNewMeasure({
            ...newMeasure,
            name: measure?.name,
            expression: measure?.expression,
            status: true,
            oladName: measure?.name,
            index: index
        });
    }
    const scrollToItem = (index) => {
        if (suggestionDropDownRef?.current) {
            const dropdown = suggestionDropDownRef?.current;
            const items = dropdown?.children;
            const selectedItem = items[index];

            if (selectedItem) {
                const itemTop = selectedItem?.offsetTop;
                const itemHeight = selectedItem?.offsetHeight;
                const dropdownScrollTop = dropdown?.scrollTop;
                const dropdownHeight = dropdown?.offsetHeight;

                // Ensure the selected item is in view
                if (itemTop < dropdownScrollTop) {
                    dropdown.scrollTop = itemTop;
                } else if (itemTop + itemHeight > dropdownScrollTop + dropdownHeight) {
                    dropdown.scrollTop = itemTop + itemHeight - dropdownHeight;
                }
            }
        }
    };
    const handleClickOutside = (event) => {
        if (
            suggestionDropDownRef?.current &&
            !suggestionDropDownRef?.current?.contains(event?.target) &&
            inputRef?.current &&
            !inputRef?.current?.contains(event?.target)
        ) {
            setShowDropdown(false);
        }
    };
    const handleFunctionSelect = (func) => {
        const inputElement = document?.querySelector("#inputField"); // Replace with your input's ID
        if (!inputElement) return;
        const cursorPosition = inputElement?.selectionStart; // Get current cursor position
        const queryBeforeCursor = query?.slice(0, cursorPosition)?.replace(/\w*$/, ""); // Text before cursor
        const queryAfterCursor = query?.slice(cursorPosition)?.replace(/^\w*/, ""); // Text after cursor

        // Check if the cursor is inside parentheses of an existing function
        const parts = query?.split(/(?=[()+\-*/%])|(?<=[()+\-*/%])/)
        const lastPart = parts[parts?.length - 1]; // Last token typed
        let updatedQuery;
        if (lastPart && lastPart?.endsWith("(")) {
            // Cursor is inside an open function, nest the new function
            updatedQuery = `${queryBeforeCursor}${func}()${queryAfterCursor}`;
        } else {
            // Add the new function at the cursor position
            updatedQuery = `${queryBeforeCursor}${func}()${queryAfterCursor}`;
        }

        setQuery(updatedQuery);
        setTimeout(() => {
            inputElement.selectionStart = inputElement.selectionEnd = queryBeforeCursor?.length + func?.length + 1;
            inputElement?.focus(); // Ensure the input is focused
        }, 0);
        setSelectedIndex(-1);
        setShowDropdown(false);
    };
    const handleColumnSelect = (column) => {
        const inputElement = document?.querySelector("#inputField"); // Replace with your input's ID

        if (!inputElement) return;

        const cursorPosition = inputElement?.selectionStart; // Get the cursor position
        const queryBeforeCursor = query?.slice(0, cursorPosition)?.replace(/\w*$/, ""); // Text before the cursor
        const queryAfterCursor = query?.slice(cursorPosition)?.replace(/^\w*/, ""); // Text after the cursor

        // Check if the cursor is inside a function's parentheses
        const functionRegex = /(\w+)\(\s*$/; // Matches a function with an open parenthesis
        const beforeCursorMatch = queryBeforeCursor?.match(functionRegex);

        let updatedQuery;

        // If the cursor is inside a function's parentheses, insert the column there
        if (beforeCursorMatch) {
            const functionName = beforeCursorMatch[1]; // Extract the function name
            const functionStartIndex = queryBeforeCursor?.lastIndexOf(functionName); // Get function's start index
            updatedQuery = `${queryBeforeCursor?.slice(0, functionStartIndex + functionName?.length + 1)}${column}${queryAfterCursor}`;
        } else {
            // Default behavior: Insert the column at the cursor position
            updatedQuery = `${queryBeforeCursor}${column}${queryAfterCursor}`;
        }
        setQuery(updatedQuery);
        setTimeout(() => {
            inputElement.selectionStart = inputElement.selectionEnd = queryBeforeCursor?.length + column?.length + 2;
            inputElement?.focus();
        }, 0);

        setShowDropdown(false);

        setSelectedIndex(-1);
    };
    const handleInputChange = (e) => {
        e?.preventDefault();
        const input = e?.target?.value;
        setQuery(input);
        const inputElement = e?.target;
        const cursorPosition = inputElement?.selectionStart;
        const parts = input?.split(/(?=[()+\-*/%])|(?<=[()+\-*/%])/); // Split into tokens
        let activeFunction = null;
        let startBracketIndex = -1;
        let endBracketIndex = -1;

        // Track open parentheses and associated functions
        const stack = [];
        for (let i = 0, cumulativeIndex = 0; i < parts?.length; i++) {
            const token = parts[i];
            const tokenStart = cumulativeIndex;
            const tokenEnd = cumulativeIndex + token?.length;

            // Update cumulative index
            cumulativeIndex = tokenEnd;

            // Check if token is a function name
            if (token?.match(/SUM|COUNT|AVG|MIN|MAX/i)) {
                stack?.push({ functionName: token, index: i });
            }

            // Handle open parenthesis
            if (token === "(" && stack?.length > 0) {
                stack[stack?.length - 1].startBracket = tokenStart;
            }

            // Handle close parenthesis
            if (token === ")" && stack?.length > 0) {
                const currentFunction = stack?.pop();
                currentFunction.endBracket = tokenEnd;

                // Check if cursor is inside this function's parentheses
                if (
                    cursorPosition >= currentFunction?.startBracket &&
                    cursorPosition <= currentFunction?.endBracket
                ) {
                    activeFunction = currentFunction?.functionName;
                    startBracketIndex = currentFunction?.startBracket;
                    endBracketIndex = currentFunction?.endBracket;
                    break;
                }
            }
        }
        if (activeFunction && startBracketIndex !== -1 && endBracketIndex !== -1) {
            const insideParentheses = input?.substring(startBracketIndex + 1, endBracketIndex);
            const insideParts = insideParentheses?.split(/,|\s+/);

            // Find the part being edited based on cursor position
            let editedPart = null;
            let cumulativeIndex = startBracketIndex + 1; // Start just after '('
            for (let part of insideParts) {
                const partStart = cumulativeIndex;
                const partEnd = cumulativeIndex + part?.length;

                if (cursorPosition >= partStart && cursorPosition <= partEnd) {
                    editedPart = part?.replace(/[^\w]/g, "");
                    break;
                }
                cumulativeIndex = partEnd + 1; // Account for separators
            }


            if (editedPart?.trim()) {
                // When there is an editedPart, filter columns and functions based on it
                const filteredCols = selectedComponentColumns?.filter((col) =>
                    col?.toLowerCase()?.includes(editedPart?.trim()?.toLowerCase())
                );
                setFilteredColumns(filteredCols);

                const filteredFuncs = aggregationFunctions?.filter((func) =>
                    func?.toLowerCase()?.includes(editedPart?.trim()?.toLowerCase())
                );
                setFilteredFunctions(filteredFuncs);
            } else {
                // When editedPart is empty, show all columns and functions
                setFilteredColumns(selectedComponentColumns);
                setFilteredFunctions(aggregationFunctions);
            }
        }
        else {
            // Cursor outside any function (main expression area)
            const editedPart = parts?.find((part, index) => {
                const partStart = parts?.slice(0, index)?.join("")?.length;
                const partEnd = partStart + part?.length;
                return cursorPosition >= partStart && cursorPosition <= partEnd;
            })?.trim();
            if (editedPart) {
                // Filter suggestions based on the part being edited
                const filteredCols = selectedComponentColumns?.filter((col) =>
                    col?.toLowerCase()?.includes(editedPart?.toLowerCase())
                );
                setFilteredColumns(filteredCols);

                const filteredFuncs = aggregationFunctions?.filter((func) =>
                    func?.toLowerCase()?.includes(editedPart?.toLowerCase())
                );
                setFilteredFunctions(filteredFuncs);
            } else {
                // Show all suggestions when no specific part is being edited
                setFilteredColumns(selectedComponentColumns);
                setFilteredFunctions(aggregationFunctions);
            }
        }
        // else {
        //     // General suggestions based on the last part typed
        //     const lastPart = parts[parts.length - 1];
        //     setFilteredFunctions(
        //         aggregationFunctions.filter((func) =>
        //             func.toLowerCase().startsWith(lastPart?.trim()?.toLowerCase())
        //         )
        //     );
        //     setFilteredColumns(
        //         selectedComponentColumns.filter((col) =>
        //             col.toLowerCase().startsWith(lastPart?.trim()?.toLowerCase())
        //         )
        //     );
        // }
        setShowDropdown(true)
    };

    const [filteredColumns, setFilteredColumns] = useState(
        tables?.flatMap(table =>
            table?.columns
                ?.filter(item =>
                    (item?.type === 'integer' || item?.type === 'int' || item?.type === 'numeric') &&
                    item?.name !== 'id' &&
                    !item?.name?.endsWith('_id')
                )
                ?.map(column => `${table?.table_name}[${column.name}]`)
        )
    );
    const handleCancel = () => {
        setNewMeasure({ name: "", expression: "", status: false, oladName: "" });
        setIsAddingMeasure(false); // Hide the input fields
        setQuery("")
        setIsEditMeasure(false);
    };
    const handleKeyDown = (e) => {
        if (!showDropdown) return;

        const totalItems = filteredFunctions?.length + filteredColumns?.length;

        switch (e.key) {
            case "ArrowDown":
                e?.preventDefault();
                setSelectedIndex((prevIndex) => {
                    const nextIndex = (prevIndex + 1) % totalItems;
                    scrollToItem(nextIndex);
                    return nextIndex;
                });
                break;
            case "ArrowUp":
                e?.preventDefault();
                setSelectedIndex((prevIndex) => {
                    const prevIndexAdjusted = prevIndex <= 0 ? totalItems - 1 : prevIndex - 1;
                    scrollToItem(prevIndexAdjusted);
                    return prevIndexAdjusted;
                });
                break;
            case "Enter":
                e?.preventDefault();
                if (selectedIndex >= 0) {
                    if (selectedIndex < filteredFunctions?.length) {
                        handleFunctionSelect(filteredFunctions[selectedIndex]);
                    } else {
                        handleColumnSelect(
                            filteredColumns[selectedIndex - filteredFunctions?.length]
                        );
                    }
                }
                // const inputElement = document?.getElementById("inputField");
                // if (inputElement) {
                //     inputElement?.blur();
                // }
                break;
            default:
                break;
        }
    };
    const getCursorPosition = () => {
        const inputElement = inputRef?.current;
        const cursorPosition = inputElement?.selectionStart;
        const textBeforeCursor = query?.slice(0, cursorPosition);

        const span = document?.createElement('span');
        span.style.visibility = 'hidden';
        span.style.position = 'absolute';
        span.style.whiteSpace = 'pre';
        span.innerText = textBeforeCursor;

        document?.body?.appendChild(span);

        const width = span?.offsetWidth;

        document?.body?.removeChild(span);
        const firstInputWidth = document.querySelector('.name-input')?.offsetWidth || 0;

        return width + firstInputWidth;
    };

    const updateButtonPosition = () => {
        // Get the position of the target div
        const com = document?.getElementById(id);
        if (com) {
            const rect = com.getBoundingClientRect();

            setButtonPosition({
                top: rect.top + 10, // Add offset as needed
                left: rect.left + 20, // Add offset as needed
            });
        }
    }
    useEffect(() => {
        // Initial position calculation
        updateButtonPosition();

        // Add resize event listener
        window.addEventListener("resize", updateButtonPosition);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener("resize", updateButtonPosition);
        };
    }, [component]);
    React.useEffect(() => {
        if (inputRef?.current) {
            const cursorLeftPosition = getCursorPosition();
            setDropdownLeft(cursorLeftPosition);
        } else {
            setDropdownLeft(0);
        }
    }, [query, isAddingMeasure, showDropdown]);
    return (
        <>
            <div
                className={`textbox-component ${selectedComId == id ? "cursor-default" : "cursor-pointer"}`}
                ref={targetDivRef}
                style={{
                    height: "100%",
                    width: "100%",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    position: 'relative',
                }}
                onMouseMove={() => stopTableDrag()}
            >
                {/* Add Measure Button */}
                {/* {!isAddingMeasure && selectedComId == id && (
                    <button
                        onClick={handleAddMeasure}
                        style={{
                            marginTop: "1px",
                            alignSelf: "flex-end",
                            color: "#075985",
                            cursor: "pointer",
                            fontSize: "10px",
                            position: 'absolute',
                            top: '-27px',
                            left: '178px'
                            // top: buttonPosition.top,
                            // left: buttonPosition.left,
                        }}
                    >
                        <Tooltip title="Add Measure">
                            <AddCardOutlinedIcon
                                sx={{
                                    height: '24px !important',
                                    width: '24px !important'
                                }}
                            />
                        </Tooltip>
                    </button>
                )} */}
                {/* Input Fields for New Measure */}
                {isAddingMeasure && selectedComId == id && (
                    <div className="d-flex relative bg-white" style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                        <input
                            type="text"
                            placeholder="Name of Measure"
                            value={newMeasure.name}
                            onChange={(e) =>
                                setNewMeasure({ ...newMeasure, name: e.target.value })
                            }
                            className="border name-input border-gray-200 text-sm w-full mb-1 px-1 py-1 rounded-[3px]"
                            style={{
                                flex: "1",
                            }}
                        />
                        <input
                            id="inputField"
                            type="text"
                            placeholder="Expression (e.g., SUM(table[column]))"
                            value={query}
                            // onChange={(e) =>
                            //     setNewMeasure({ ...newMeasure, expression: e.target.value })
                            // }
                            onChange={handleInputChange}
                            className="border  border-gray-200 text-sm w-full mb-1 px-2 py-1  rounded-[3px"
                            style={{
                                flex: "4",
                            }}
                            onFocus={() => {
                                if (newMeasure?.status && query === "") {
                                    setShowDropdown(true);
                                    setFilteredFunctions(aggregationFunctions)
                                    setFilteredColumns(selectedComponentColumns)
                                }
                                setActiveElementIndex(1)
                            }}
                            disabled={!newMeasure?.status}
                            // autocomplete="off"
                            autoComplete='off'
                            ref={inputRef}
                            onKeyDown={handleKeyDown}
                        />
                        {isAddingMeasure && showDropdown && newMeasure?.status && (
                            <ul
                                ref={suggestionDropDownRef}
                                className="top-10 bg-white border mt-1 shadow-md rounded agg-list max-h-52 overflow-auto custom-scrollbar"
                                style={{
                                    left: dropdownLeft,
                                    position: "absolute",
                                    zIndex: "10",
                                }}
                            >
                                {filteredFunctions?.map((func, index) => (
                                    <li
                                        key={index}
                                        className={`py-1 px-2 cursor-pointer text-sm flex gap-1 ${selectedIndex === index ? "bg-slate-200" : ""
                                            }`}
                                        onMouseEnter={() => setSelectedIndex(index)}
                                        onClick={() => handleFunctionSelect(func)}
                                    >
                                        <FunctionIcon width="20px" height="20px" />
                                        {func}
                                    </li>
                                ))}
                                {filteredColumns?.map((column, index) => (
                                    <li
                                        key={filteredFunctions?.length + index}
                                        className={`py-1 px-2 cursor-pointer text-sm flex gap-1 ${selectedIndex ===
                                            filteredFunctions?.length + index
                                            ? "bg-slate-200"
                                            : ""
                                            }`}
                                        onMouseEnter={() =>
                                            setSelectedIndex(filteredFunctions?.length + index)
                                        }
                                        onClick={() => handleColumnSelect(column)}
                                    >
                                        <ColumnIcon width="20px" height="20px" />
                                        {column}
                                    </li>
                                ))}
                            </ul>
                        )}
                        <button
                            onClick={handleProceed}
                            className="px-1 py-0.5  rounded-[3px] text-white border mb-1 border-gray-200 bg-[#075985] cursor-pointer"
                            style={{
                                flex: "none",
                            }}
                        >
                            <CheckIcon />
                        </button>
                        <button
                            onClick={handleCancel}
                            className="px-1 py-0.5 rounded-[3px] text-white border mb-1 border-gray-200 bg-[#075985] cursor-pointer"
                            style={{
                                flex: "none",
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                )}
                {/* Display Measures */}
                <div
                    className="custom-scrollbar"
                    // style={{
                    //     flex: 1,
                    //     overflowY: "auto",
                    //     border: "1px solid #ccc",
                    //     backgroundColor: "#fff",
                    //     height: "100%",
                    //     width: "100%",
                    // }}
                    style={{
                        height: "100%",
                        width: "100%",
                        border: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                        backgroundColor: properties?.backgroundColor,
                        // boxSizing: "border-box",
                        //  display: "flex",
                        flexDirection: "column",
                        //  padding: `${properties?.paddingTop}px ${properties?.paddingRight}px ${properties?.paddingBottom}px ${properties?.paddingLeft}px`,
                        fontFamily: properties?.fontFamily,
                        fontWeight: properties?.fontWeight,
                        fontSize: `${properties?.fontSize}px`,
                        color: properties?.textColor,
                        //  lineHeight: properties?.lineHeight,
                        // position: 'relative',
                        overflowY: "auto"
                    }}
                >
                    {properties?.measures.map((measure, index) => (
                        <div
                            className={`${index === newMeasure?.index && selectedComId == id ? 'bg-sky-100' : ''}   mb-0.5 px-1 py-1 relative`}
                            style={{
                                textAlign: `${properties?.align}`,
                            }}
                        >
                            <span
                                style={{

                                    fontWeight: `${properties?.measures?.find(
                                        (item) => item.name === measure?.name
                                    )?.isBold ? 'bold' : properties?.measures?.find(
                                        (item) => item.name === measure?.name
                                    )?.fontWeight
                                        ? properties?.measures?.find(
                                            (item) => item.name === measure?.name
                                        )?.fontWeight
                                        : properties?.fontWeight
                                        } `,
                                    fontSize: `${properties?.measures?.find(
                                        (item) => item.name === measure?.name
                                    )?.fontSize
                                        ? properties?.measures?.find(
                                            (item) => item.name === measure?.name
                                        )?.fontSize + "px"
                                        : properties?.fontSize + "px"
                                        }`,
                                    color: `${properties?.measures?.find(
                                        (item) => item.name === measure?.name
                                    )?.textColor
                                        ? properties?.measures?.find(
                                            (item) => item.name === measure?.name
                                        )?.textColor
                                        : properties?.textColor
                                        }`,
                                    fontStyle: properties?.measures?.find(
                                        (item) => item.name === measure?.name
                                    )?.isItalic ? 'italic' : 'inherit',
                                    textDecoration: `${properties?.measures?.find(
                                        (item) => item.name === measure?.name
                                    )?.isUnderline ? 'underline' : 'inherit'}`,
                                    // textDecoration: `${properties?.measures?.find(
                                    //     (item) => item.name === measure?.name
                                    // )?.textdecoration}`,


                                }}
                                key={index} >
                                <sapn
                                    style={{
                                        fontFamily: `${properties?.measures?.find((item) => item.name === measure?.name)
                                            ?.fontFamily
                                            ? properties?.measures?.find((item) => item.name === measure?.name)
                                                ?.fontFamily
                                            : properties?.fontFamily
                                            }`,
                                    }}
                                >{measure?.name}
                                </sapn>{": "}
                                <span
                                    style={{
                                        fontFamily: `${properties?.measures?.find((item) => item.name === measure?.name)
                                            ?.fontFamily
                                            ? properties?.measures?.find((item) => item.name === measure?.name)
                                                ?.fontFamily
                                            : properties?.fontFamily
                                            }`,
                                    }}
                                >{parseFloat(measure?.result).toFixed(2)}</span>
                            </span>

                            {selectedComId == id &&
                                <span
                                    className={`absolute  ${properties?.align === 'Right' ? 'left-0' : 'right-0'}`}
                                >
                                    <Tooltip
                                        title={"Edit measure"}
                                        onClick={() => handleEditMeasure(measure, index)}
                                    >
                                        <DriveFileRenameOutlineOutlinedIcon
                                            sx={{
                                                color: "#075985",
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </Tooltip>
                                </span>}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default SummeryComponent;
