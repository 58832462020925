import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import SignInPrimary from "../../../assets/images/SignInPrimary.png";
import SignUpPrimary from "../../../assets/images/SignUpPrimary.png";
import classNames from "classnames";

const AuthBanner = ({ option }) => {
  return (
    <div
      className={classNames(
        "justify-between hidden w-5/12 gap-8 px-8 py-16 overflow-hidden -z-[999] sm:flex sm:flex-col base:py-36",
        option === "signin" ? "bg-primaryorange" : "bg-primarytextgreen"
      )}
    >
      <div>
        <div className="mx-auto">
          <div className="relative mx-auto w-fit">
            <img
              src={option === "signup" ? SignUpPrimary : SignInPrimary}
              alt={option === "signup" ? "SignUpPrimary" : "SignInPrimary"}
            />
            <div
              className={classNames(
                "absolute w-[430px] h-[430px] -z-[100] rounded-full -top-1 left-10",
                option === "signin"
                  ? "bg-primaryyellow"
                  : "bg-primarylightgreen"
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-4">
        <p className="m-0 text-xl font-bold text-center text-white">
          “Choosing the CareSMS scheduling solution is a no-brainer. It's simple
          to use and it allows me to save time, while growing our home-based
          care practice.”
        </p>
        <div className="flex flex-col">
          <p className="m-0 text-lg font-bold text-center text-white">
            Serena Patel
          </p>
          <p className={classNames("m-0 text-center", option === 'signup' && 'text-white font-light')}>
            CFO at Transitions Home & Hospice
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuthBanner;
