import AddIcon from "@mui/icons-material/Add";
import CheckboxSelector from "../../../../Schedules/components/CheckboxSelector";
import { Icons } from "../../../../../assets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import ReusableModal from "../../../../../components/Modals/ReusableModal";
import { SketchPicker } from "react-color";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { useUpdateTemplatesMutation } from "../../../../../features/report/reportApiSlice";

const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
  // const [isOpen, setIsOpen] = React.useState(false);
  // const toggleOpen = () => {
  //     setIsOpen(!isOpen);
  // };
  return (
    <div
      className="sidebar-section"
    // style={{ borderTop: '0.5px solid #d2d9e3', borderBottom: '0.5px solid #d2d9e3' }}
    >
      {/* <div
                className={`sidebar-section-header hover:bg-slate-300 ${subSection ? ' bg-slate-100' : 'bg-slate-200 py-1'} px-2 `}
                onClick={toggleOpen}
            > */}
      <div
        className={`sidebar-section-header px-2 cursor-pointer  ${subSection ? "" : "py-1"
          }`}
        onClick={onToggle}
        style={{
          backgroundColor: !subSection ? "rgb(16 85 151)" : "#534a4a40",
        }}
      >
        <div className="d-flex justify-between items-center">
          <span
            className={`font-semibold text-sm ${subSection ? "text-sm text-gray-600" : "text-base text-gray-500"
              } `}
            style={{
              color: subSection ? "text-sm text-gray-600" : "white",
              paddingLeft: "8px",
            }}
          >
            {title}
          </span>
          <button className="toggle-button">
            {isOpen ? (
              <Tooltip title="Remove">
                <RemoveIcon
                  className={`${subSection ? "text-sm text-gray-600" : "text-white"
                    }`}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Add">
                <AddIcon
                  className={`${subSection ? "text-sm text-gray-600" : "text-white"
                    }`}
                />
              </Tooltip>
            )}
          </button>
        </div>
      </div>
      {isOpen && <div className="sidebar-section-content">{children}</div>}
    </div>
  );
};

function TextboxProperties({
  component,
  onPropertyChange,
  setSelectedComponent,
  showAlert,
  flag,
  setFlag,
  reportAreaRef,
  openSection,
  setOpenSection,
}) {
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [colorPickerBg, setColorPickerBg] = React.useState(false);
  const [colorPickerText, setColorPickerText] = React.useState(false);
  const [newCol, setNewCol] = React.useState("");
  const [columnListOpen, setColumnListOpen] = React.useState(false);
  const [selectedColumn, setSelectedColumn] = React.useState(
    component?.properties?.columns
  );
  const columnRef = React.useRef(null);
  const [deselectedColumns, setDeselectedColumns] = React.useState([]);
  const [updateTemplates] = useUpdateTemplatesMutation();
  const [open, setOpen] = React.useState(false);
  const backgroundColorRef = React.useRef(null);
  const textColorRef = React.useRef(null);
  const borderColorRef = React.useRef(null);
  const colorPickerRefs = React.useRef({});
  const columnDropdownRef = React.useRef(null);

  const [colorPickerState, setColorPickerState] = React.useState({});
  const Fonts = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Times",
    "Courier New",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Trebuchet MS",
    "Comic Sans MS",
    "Impac",
  ];
  const fontWeights = [
    { name: "Thin", value: 100 },
    { name: "Extra Light", value: 200 },
    { name: "Light", value: 300 },
    { name: "Normal", value: 400 },
    { name: "Medium", value: 500 },
    { name: "Semi Bold", value: 600 },
    { name: "Bold", value: 700 },
    { name: "Extra Bold", value: 800 },
  ];
  const TextDecoration = [
    "None",
    "Underline",
    "Line-through",
    "Overline",
  ];
  const handleColumnNameFilter = (column, obj) => {
    if (selectedColumn.includes(column)) {
      setDeselectedColumns((prev) => [...prev, column]);
      const newColumns = selectedColumn.filter((filter) => filter !== column);
      onPropertyChange({ columns: newColumns });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          columns: newColumns,
        },
      }));
      setSelectedColumn(selectedColumn.filter((filter) => filter !== column));
      setFlag(false);
    } else {
      setDeselectedColumns((prev) => prev.filter((item) => item !== column));
      onPropertyChange({ columns: [...selectedColumn, column] });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          columns: [...selectedColumn, column],
        },
      }));
      setSelectedColumn([...selectedColumn, column]);
      setFlag(false);
    }
  };

  const handlePositionChange = (e, property) => {
    const newValue = parseInt(e.target.value, 10);
    let updatedProperties = { [property]: newValue };
    if (newValue < 0) {
      updatedProperties[property] = 0;
    }
    if (property === "top") {
      updatedProperties.bottom =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure bottom doesn't go below 0
      if (updatedProperties.bottom < 0) {
        updatedProperties.bottom = 0;
        updatedProperties.top =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "bottom") {
      updatedProperties.top =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure top doesn't go below 0
      if (updatedProperties.top < 0) {
        updatedProperties.top = 0;
        updatedProperties.bottom =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "left") {
      updatedProperties.right =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.right < 0) {
        updatedProperties.right = 0;
        updatedProperties.left =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    } else if (property === "right") {
      updatedProperties.left =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.left < 0) {
        updatedProperties.left = 0;
        updatedProperties.right =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    }

    onPropertyChange(updatedProperties);
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        ...updatedProperties,
      },
    }));
    setFlag(false);
  };
  const handleColumnPropertyChange = (columnName, updatedProperties) => {
    setSelectedComponent((prev) => {
      const updatedColumnProperties = prev.properties.columnProperties.map(
        (col) =>
          col.name === columnName ? { ...col, ...updatedProperties } : col
      );
      return {
        ...prev,
        properties: {
          ...prev.properties,
          columnProperties: updatedColumnProperties,
        },
      };
    });
    const updatedColumnProperties = component.properties.columnProperties.map(
      (col) =>
        col.name === columnName ? { ...col, ...updatedProperties } : col
    );
    onPropertyChange({ columnProperties: updatedColumnProperties });
    setFlag(false);
  };
  const handleColorPickerToggle = (columnName, pickerType) => {
    setColorPickerState((prev) => ({
      ...prev,
      [columnName]: {
        ...prev[columnName],
        [pickerType]: !prev[columnName]?.[pickerType],
      },
    }));
  };
  const handleClickOutside = (event) => {
    // if (
    //     backgroundColorRef.current &&
    //     !backgroundColorRef.current.contains(event.target) &&
    //     textColorRef.current &&
    //     !textColorRef.current.contains(event.target) &&
    //     borderColorRef.current &&
    //     !borderColorRef.current.contains(event.target)
    // ) {
    //     //setColorPickerState({});
    //     setColorPickerBg(false)
    //     setColorPickerText(false);
    //     setColorPickerOpen(false);

    // }
    if (textColorRef.current && !textColorRef.current.contains(event.target)) {
      setColorPickerText(false);
    }
    if (
      backgroundColorRef.current &&
      !backgroundColorRef.current.contains(event.target)
    ) {
      setColorPickerBg(false);
    }
    if (
      borderColorRef.current &&
      !borderColorRef.current.contains(event.target)
    ) {
      setColorPickerOpen(false);
    }
    if (
      columnDropdownRef.current &&
      !columnDropdownRef.current.contains(event.target)
    ) {
      setColumnListOpen(false);
    }
    const isOutsideColorPickers = Object.keys(colorPickerRefs.current).every(
      (columnName) => {
        const refs = colorPickerRefs.current[columnName];
        return (
          !refs ||
          Object.keys(refs).every((pickerType) => {
            const ref = refs[pickerType];
            return ref && !ref.contains(event.target);
          })
        );
      }
    );

    if (isOutsideColorPickers) {
      setColorPickerState({});
    } else {
      // Only close color pickers that are not being targeted
      setColorPickerState((prevState) => {
        const newState = { ...prevState };
        Object.keys(colorPickerRefs.current).forEach((columnName) => {
          const refs = colorPickerRefs.current[columnName];
          Object.keys(refs).forEach((pickerType) => {
            const ref = refs[pickerType];
            if (ref && !ref.contains(event.target)) {
              if (newState[columnName]) {
                newState[columnName][pickerType] = false;
              }
            }
          });
        });
        return newState;
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const [openSection, setOpenSection] = React.useState(null);
  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <>
      <div>
        {/* <div className="flex flex-row items-start justify-start w-full gap-1 py-3 px-3 items-center">
                    <p className='font-semibold text-sm text-gray-500 m-0'>Name:</p>
                    <input
                        type="text"
                        className="block p-1 text-sm text-gray-900 bg-white border-custome  rounded w-full  focus:ring-blue-500 focus:border-blue-500"
                        value={component?.properties?.name || ''}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            onPropertyChange({ name: newValue });
                            setSelectedComponent((prev) => ({
                                ...prev,
                                properties: {
                                    ...prev.properties,
                                    name: newValue
                                }
                            }));
                            setFlag(false)
                        }}
                    />
                </div> */}
        <SidebarSection
          title="Position"
          subSection={false}
          isOpen={openSection === "position"}
          onToggle={() => handleToggle("position")}
        >
          <span className="d-flex px-2 py-2 justify-center">
            <span className="d-flex flex-col">
              <span className="d-flex  py-2">
                <p className="prop-label mb-1 self-end">Position (px):</p>
                <div className="mx-2">
                  <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">
                    Left
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                    value={Math?.round(component?.properties?.left)}
                    onChange={(e) => handlePositionChange(e, "left")}
                  />
                </div>
                <div className="mx-2">
                  <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                    Top
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                    value={Math?.round(component?.properties?.top)}
                    onChange={(e) => handlePositionChange(e, "top")}
                  />
                </div>
              </span>
              <span className="d-flex self-end py-2">
                <div className="mx-2">
                  <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">
                    Right
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                    value={Math?.round(component?.properties?.right)}
                    onChange={(e) => handlePositionChange(e, "right")}
                  />
                </div>
                <div className="mx-2">
                  <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                    Bottom
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                    value={Math?.round(component?.properties?.bottom)}
                    onChange={(e) => handlePositionChange(e, "bottom")}
                  />
                </div>
              </span>
            </span>
          </span>
        </SidebarSection>
        <SidebarSection
          title="Appearance"
          subSection={false}
          isOpen={openSection === "appearance"}
          onToggle={() => handleToggle("appearance")}
        >
          <span className="justify-center px-2 py-2 pt-3 space-x-2 flex sm:flex-row lg:flex-row ">
            <span className="m-0 lg:self-end lg:pb-0 mb-1 sm:self-center sm:pb-7">
              <p className="m-0 prop-label lg:self-end mb-0">Size (px):</p>
            </span>
            <span className="flex lg:flex-row sm:flex-col lg:space-x-2 sm:space-y-1 lg:space-y-0">
              <div className="flex flex-col space-x-2">
                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600 pl-2">
                  Height
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.height || 0}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onPropertyChange({ height: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        height: Number(newValue),
                      },
                    }));
                    setFlag(false);
                  }}
                />
              </div>
              <div className="flex flex-col space-x-2">
                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600 pl-2">
                  Width
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.width || 0}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    onPropertyChange({ width: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        width: Number(newValue),
                      },
                    }));
                    setFlag(false);
                  }}
                />
              </div>
            </span>
          </span>

          <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-4">
            <span className="flex  py-2 space-x-4 lg:ml-5 sm:ml-16">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Border:
              </p>
              <select
                value={component?.properties?.borderType || "solid"}
                onChange={(e) => {
                  onPropertyChange({ borderType: e.target.value });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      borderType: e.target.value,
                    },
                  }));
                  setFlag(false);
                }}
                className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded  focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={-1}>Select Border Type</option>
                {["Solid", "Dotted", "Dashed", "Double", "None"].map(
                  (item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  )
                )}
              </select>
            </span>
            {/* <div className="ml-1">
                            <div className="relative" ref={borderColorRef}>
                                <input
                                    type="text"
                                    className="block p-1 cursor-pointer text-sm text-gray-900  border border-gray-300 rounded-lg w-full focus:ring-blue-500 focus:border-blue-500"
                                    value={""}
                                    onClick={() => {
                                        setColorPickerOpen(!colorPickerOpen);
                                        setColorPickerBg(false);
                                        setColorPickerText(false);
                                    }}
                                    readOnly
                                    style={{ backgroundColor: component?.properties?.borderColor || '#000000', width: '30px' }}
                                />
                                {colorPickerOpen && (
                                    <div className="absolute z-10  right-0 mt-1 mx-1" style={{ top: '0px', left: '30px', cursor: 'pointer' }}>
                                        <SketchPicker
                                            color={component?.properties?.borderColor || 'rgba(0,0,0,1)'}
                                            onChange={(color) => {
                                                const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                                onPropertyChange({ borderColor: rgbaColor });
                                                setSelectedComponent((prev) => ({
                                                    ...prev,
                                                    properties: {
                                                        ...prev.properties,
                                                        borderColor: rgbaColor
                                                    }
                                                }));
                                                setFlag(false)
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div> */}
            <span
              ref={borderColorRef}
              className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-20 lg:pr-0"
            >
              <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                Border Color:
              </p>
              <span className="flex items-center border-custome">
                <div
                  className="w-6 h-6 sketch-border"
                  style={{
                    backgroundColor:
                      component?.properties?.borderColor || "#000000",
                  }}
                />

                <div
                  className="w-5 h-6 text-center cursor-pointer"
                  onClick={() => {
                    setColorPickerOpen(!colorPickerOpen);
                    setColorPickerBg(false);
                    setColorPickerText(false);
                  }}
                >
                  <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                </div>
              </span>
              {colorPickerOpen && (
                <div className="absolute z-10 right-0 mt-[32px] cursor-pointer">
                  <SketchPicker
                    color={
                      component?.properties?.borderColor || "rgba(0,0,0,1)"
                    }
                    onChange={(color) => {
                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      onPropertyChange({ borderColor: rgbaColor });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          borderColor: rgbaColor,
                        },
                      }));
                      setFlag(false);
                    }}
                  />
                </div>
              )}
            </span>
          </div>

          <span className="d-flex justify-center py-2 space-x-4 lg:mr-[126px] sm:mr-6">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Border Width:
            </p>
            <input
              type="number"
              className="block p-1  text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
              value={component?.properties?.borderWidth || 1}
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue !== "") {
                  newValue = newValue.replace(/^0+/, "") || "0";
                }
                if (parseInt(newValue) < 0) {
                  newValue = "0";
                }
                onPropertyChange({ borderWidth: newValue });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    borderWidth: newValue,
                  },
                }));
                setFlag(false);
              }}
            />
          </span>

          <div className="d-flex justify-center py-2 lg:mr-[70px] sm:ml-8 lg:ml-0 space-x-4">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Align:
            </p>
            <select
              value={component?.properties?.align}
              onChange={(e) => {
                onPropertyChange({ align: e.target.value });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    align: e.target.value,
                  },
                }));
                setFlag(false);
                //handleColumnPropertyChange(item.name, { align: e.target.value })
              }}
              className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded  focus:ring-blue-500 focus:border-blue-500"
            >
              <option value={-1}>Select Align</option>
              {["Center", "Left", "Right"].map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-center py-2 space-x-4 lg:mr-[196px] sm:mr-24">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Background Color:
            </p>
            {/* <div className="ml-1">
                            <div className="relative" ref={backgroundColorRef}>
                                <input
                                    type="text"
                                    className="block p-1 text-sm text-gray-900  border border-gray-300 rounded-lg w-full focus:ring-blue-500 focus:border-blue-500"
                                    value={""}
                                    onClick={() => {
                                        setColorPickerBg(!colorPickerBg)
                                        setColorPickerOpen(false)
                                        setColorPickerText(false)
                                    }}
                                    readOnly
                                    style={{ backgroundColor: component?.properties?.backgroundColor || '#fff', width: '30px', cursor: 'pointer' }}
                                />
                                {colorPickerBg && (
                                    <div className="absolute z-10  left-0 mt-1 mx-1" style={{ bottom: '40px', cursor: 'pointer' }}>
                                        <SketchPicker
                                            color={component?.properties?.backgroundColor || 'rgba(255,255,255,1)'}
                                            onChange={(color) => {
                                                const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                                onPropertyChange({ backgroundColor: rgbaColor });
                                                setSelectedComponent((prev) => ({
                                                    ...prev,
                                                    properties: {
                                                        ...prev.properties,
                                                        backgroundColor: rgbaColor
                                                    }
                                                }));
                                                setFlag(false)
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div> */}
            <span className="relative" ref={backgroundColorRef}>
              <div className="flex items-center border-custome">
                <div
                  className="w-6 h-6 sketch-border"
                  style={{
                    backgroundColor:
                      component?.properties?.backgroundColor || "#fff",
                  }}
                />
                <div
                  className="w-5 h-6 text-center cursor-pointer"
                  onClick={() => {
                    setColorPickerBg(!colorPickerBg);
                    setColorPickerOpen(false);
                    setColorPickerText(false);
                  }}
                >
                  <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                </div>
              </div>
              {colorPickerBg && (
                <div className="absolute z-10 bottom-0 mb-[32px] cursor-pointer">
                  <SketchPicker
                    color={
                      component?.properties?.backgroundColor ||
                      "rgba(255,255,255,1)"
                    }
                    onChange={(color) => {
                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      onPropertyChange({ backgroundColor: rgbaColor });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          backgroundColor: rgbaColor,
                        },
                      }));
                      setFlag(false);
                    }}
                  />
                </div>
              )}
            </span>
          </div>

          <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-3">
            <span className="flex  py-2 space-x-4 lg:ml-[30px] sm:ml-[72px]">
              <p className="m-0 whitespace-nowrap prop-label self-center">
                Font:
              </p>
              <select
                value={component?.properties?.fontFamily}
                onChange={(e) => {
                  onPropertyChange({ fontFamily: e.target.value });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      fontFamily: e.target.value,
                    },
                  }));
                  setFlag(false);
                  //handleColumnPropertyChange(item.name, { align: e.target.value })
                }}
                className="block  p-1 text-xs text-gray-900 bg-white border-custome  rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={"Inter"}>Select Font Family</option>
                {Fonts.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </span>
            {/* <div className="ml-1">
                            <div className="relative" ref={textColorRef}>
                                <input
                                    type="text"
                                    className="block p-1 text-sm text-gray-900  border border-gray-300 rounded-lg w-full focus:ring-blue-500 focus:border-blue-500"
                                    value={""}
                                    onClick={() => {
                                        setColorPickerText(!colorPickerText)
                                        setColorPickerBg(false);
                                        setColorPickerOpen(false)
                                    }}
                                    readOnly
                                    style={{ backgroundColor: component?.properties?.textColor || '#fff', width: '30px', cursor: 'pointer' }}
                                />
                                {colorPickerText && (
                                    <div className="absolute z-10  left-0 mt-1 mx-1" style={{ bottom: '40px', cursor: 'pointer' }}>
                                        <SketchPicker
                                            color={component?.properties?.textColor || 'rgba(0, 0, 0, 1)'}
                                            onChange={(color) => {
                                                const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                                onPropertyChange({ textColor: rgbaColor });
                                                setSelectedComponent((prev) => ({
                                                    ...prev,
                                                    properties: {
                                                        ...prev.properties,
                                                        textColor: rgbaColor
                                                    }
                                                }));
                                                setFlag(false)
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div> */}
            <span
              ref={textColorRef}
              className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-16 lg:pr-0"
            >
              <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                Font Color:
              </p>
              <span className="flex items-center border-custome ">
                <div
                  className="w-6 h-6 sketch-border"
                  style={{
                    backgroundColor: component?.properties?.textColor || "#fff",
                  }}
                />
                <div
                  className="w-5 h-6 text-center cursor-pointer"
                  onClick={() => {
                    setColorPickerText(!colorPickerText);
                    setColorPickerBg(false);
                    setColorPickerOpen(false);
                  }}
                >
                  <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                </div>
              </span>
              {colorPickerText && (
                <div className="absolute z-10 right-0 bottom-0 mb-[38px] cursor-pointer">
                  <SketchPicker
                    color={
                      component?.properties?.textColor || "rgba(0, 0, 0, 1)"
                    }
                    onChange={(color) => {
                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      onPropertyChange({ textColor: rgbaColor });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          textColor: rgbaColor,
                        },
                      }));
                      setFlag(false);
                    }}
                  />
                </div>
              )}
            </span>
          </div>
          {/* <div className="d-flex flex-row lg:flex-row pl-2 py-2 mt-[10px] md:mt-0 items-center ">
                            <p className="block lg:hidden m-0 font-medium text-sm whitespace-nowrap text-gray-600">
                                Font Size:
                            </p>
                            <div className='lg:px-0 lg:flex lg:items-center'>
                                <input
                                    type="number"
                                    className="block p-1  text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-32  focus:ring-blue-500 focus:border-blue-500 ml-2"
                                    value={component?.properties?.fontSize || 0}
                                    onChange={(e) => {
                                        let newValue = e.target.value;
                                        if (newValue !== '') {
                                            newValue = newValue.replace(/^0+/, '') || '0';
                                        }
                                        if (parseInt(newValue) < 0) {
                                            newValue = '0';
                                        }
                                        onPropertyChange({ fontSize: newValue });
                                        setSelectedComponent((prev) => ({
                                            ...prev,
                                            properties: {
                                                ...prev.properties,
                                                fontSize: newValue
                                            }
                                        }));
                                        setFlag(false)
                                    }}
                                />
                            </div>
                        </div> */}
          {/* </div> */}
          <div className="d-flex justify-center py-2 space-x-4 lg:mr-[103px]">
            <p className="block m-0 prop-label whitespace-nowrap  self-center">
              Font Size:
            </p>
            <input
              type="number"
              className="block p-1  text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500 "
              value={component?.properties?.fontSize || 15}
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue !== "") {
                  newValue = newValue.replace(/^0+/, "") || "1";
                }
                if (parseInt(newValue) < 0) {
                  newValue = "1";
                }
                onPropertyChange({ fontSize: newValue });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    fontSize: newValue,
                  },
                }));
                setFlag(false);
              }}
            />
          </div>
          <div className="d-flex justify-center py-2 pb-3 space-x-4 lg:mr-[68px] sm:ml-8 lg:ml-0">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Font Weight:
            </p>
            <select
              value={component?.properties?.fontWeight}
              onChange={(e) => {
                onPropertyChange({ fontWeight: e.target.value });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    fontWeight: e.target.value,
                  },
                }));
                setFlag(false);
                //handleColumnPropertyChange(item.name, { align: e.target.value })
              }}
              className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
            >
              <option value={"Inter"}>Select Font Weight</option>
              {fontWeights.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </SidebarSection>
        <SidebarSection
          title="Advance"
          subSection={false}
          isOpen={openSection === "advance"}
          onToggle={() => handleToggle("advance")}
        >
          <span className="d-flex px-2 py-2 justify-center">
            <span className="d-flex flex-col">
              <span className="d-flex py-2">
                <p className="prop-label mb-1 self-end mr-2">Padding (px):</p>
                <div className="mx-2">
                  <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">
                    Left
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-16 focus:ring-blue-500 focus:border-blue-500"
                    value={component?.properties?.paddingLeft}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (newValue !== "") {
                        newValue = newValue.replace(/^0+/, "") || "0";
                      }
                      if (parseInt(newValue) < 0) {
                        newValue = "0";
                      }
                      onPropertyChange({ paddingLeft: newValue });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          paddingLeft: newValue,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="mx-2">
                  <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                    Top
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                    value={component?.properties?.paddingTop}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (newValue !== "") {
                        newValue = newValue.replace(/^0+/, "") || "0";
                      }
                      if (parseInt(newValue) < 0) {
                        newValue = "0";
                      }
                      onPropertyChange({ paddingTop: newValue });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          paddingTop: newValue,
                        },
                      }));
                    }}
                  />
                </div>
              </span>
              <span className="d-flex py-1 self-end">
                <div className="mx-2">
                  <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">
                    Right
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-16 focus:ring-blue-500 focus:border-blue-500"
                    value={component?.properties?.paddingRight}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (newValue !== "") {
                        newValue = newValue.replace(/^0+/, "") || "0";
                      }
                      if (parseInt(newValue) < 0) {
                        newValue = "0";
                      }
                      onPropertyChange({ paddingRight: newValue });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          paddingRight: newValue,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="mx-2">
                  <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                    Bottom
                  </p>
                  <input
                    type="number"
                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                    value={component?.properties?.paddingBottom || 1}
                    onChange={(e) => {
                      let newValue = e.target.value;
                      if (newValue !== "") {
                        newValue = newValue.replace(/^0+/, "") || "0";
                      }
                      if (parseInt(newValue) < 0) {
                        newValue = "0";
                      }
                      onPropertyChange({ paddingBottom: newValue });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          paddingBottom: newValue,
                        },
                      }));
                    }}
                  />
                </div>
              </span>
            </span>
          </span>
          <div className="d-flex py-2 justify-center space-x-4 lg:mr-[132px] sm:mr-20">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Line Height (px):
            </p>
            <input
              type="number"
              className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px] focus:ring-blue-500 focus:border-blue-500"
              value={component?.properties?.lineHeight || 1}
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue !== "") {
                  newValue = newValue.replace(/^0+/, "") || "0";
                }
                if (parseInt(newValue) < 0) {
                  newValue = "0";
                }
                onPropertyChange({ lineHeight: newValue });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    lineHeight: newValue,
                  },
                }));
                setFlag(false);
              }}
            />
          </div>
          <div className="d-flex justify-center  py-2 pb-3 space-x-4 lg:mr-[117px] sm:mr-[65px]">
            <p className="m-0 whitespace-nowrap prop-label self-center">
              Text decoration:
            </p>
            <select
              value={component?.properties?.textdecoration}
              onChange={(e) => {
                onPropertyChange({ textdecoration: e.target.value });
                setSelectedComponent((prev) => ({
                  ...prev,
                  properties: {
                    ...prev.properties,
                    textdecoration: e.target.value,
                  },
                }));
                setFlag(false);
              }}
              className="block p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded  focus:ring-blue-500 focus:border-blue-500"
            >
              {/* <option value={"default"}>Select</option> */}
              {TextDecoration.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </SidebarSection>
      </div>
    </>
  );
}

export default TextboxProperties;
