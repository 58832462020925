import React from "react";
import { Icons } from "../../assets";
import { Grid, Typography } from "@mui/material";
import "./Search.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SearchComponent = ({
  placeholder,
  value,
  onChange,
  inputStyle,
  isFilter,
}) => {
  return (
    <Grid className="mx-1" style={{ flexGrow: 1 }}>
      <Grid className="content d-flex px-2 align-items-center">
        <img src={Icons.Search_Icon} alt="search" className="search-icon" />
        <input
          placeholder={placeholder || "Search..."}
          className="search-input-child px-2 justify-content-center w-100"
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
        {isFilter && (
          <Grid role="button" className="d-flex align-items-center">
            <Typography className="filterText">All</Typography>
            <KeyboardArrowDownIcon />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchComponent;
