import React from "react";
import classNames from "classnames";
import { useState } from "react";

// const DropArea = () => {
//   return (
//     <div>DropArea</div>
//   )
// }

// export default DropArea

export const DropArea = ({ onDrop }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showArea = () => {
    setIsVisible(true);
  };

  const hideArea = () => {
    setIsVisible(false);
  };

  return (
    <div
      className={classNames(
        "relative h-4 transition-[padding,opacity] before:absolute before:inset-2 before:rounded-xl before:border-2 before:border-dashed before:border-gray-300 before:bg-gray-200 only:h-28",
        isVisible && "py-8 opacity-100",
        !isVisible && "opacity-0"
      )}
      onDragEnter={showArea}
      onDragLeave={hideArea}
      onDrop={() => {
        onDrop();
        hideArea();
      }}
      onDragOver={(ev) => ev.preventDefault()}
    />
  );
};
