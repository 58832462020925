import { useMemo } from "react";
import { usePermissionsStore } from "../context/permissions.store";

const useGetPermissions = () => {
  const { permissions } = usePermissionsStore();
  const hasDBCreationPermission = useMemo(() => {
    return (
      permissions?.permissions?.includes("create_database") &&
      (permissions?.organisationLimits?.enterprises_databases?.isLimited ===
        false ||
        permissions?.organisationLimits?.enterprises_databases?.maxDataSources >
          permissions?.featureUsage?.enterprises_databases)
    );
  }, [permissions]);

  const hasDataviewCreationPermission = useMemo(() => {
    return (
      permissions?.permissions?.includes("create_dataview") &&
      (permissions?.organisationLimits?.dataviews?.isLimited === false ||
        permissions?.organisationLimits?.dataviews?.max >
          permissions?.featureUsage?.dataviews)
    );
  }, [permissions]);

  const hasDatasourceCreationPermission = useMemo(() => {
    return (
      permissions?.permissions?.includes("create_datasource") &&
      (permissions?.organisationLimits?.data_sources?.isLimited === false ||
        permissions?.organisationLimits?.data_sources?.maxDataSources >
          permissions?.featureUsage?.data_sources)
    );
  }, [permissions]);

  const hasGroupPermission = useMemo(() => {
    return (
      permissions?.permissions?.includes("view_group") &&
      (permissions?.organisationLimits?.groups?.isLimited === false ||
        permissions?.organisationLimits?.groups?.max >
          permissions?.featureUsage?.groups)
    );
  }, [permissions]);

  const hasRolePermission = useMemo(() => {
    return (
      permissions?.permissions?.includes("view_role") &&
      (permissions?.organisationLimits?.roles?.isLimited === false ||
        permissions?.organisationLimits?.roles?.max >
          permissions?.featureUsage?.roles)
    );
  }, [permissions]);

  const hasUserPermission = useMemo(() => {
    return (
      permissions?.permissions?.includes("view_user") &&
      (permissions?.organisationLimits?.users?.isLimited === false ||
        permissions?.organisationLimits?.users?.max >
          permissions?.featureUsage?.users)
    );
  }, [permissions]);

  const hasLocationPermission = useMemo(() => {
    return (
      permissions?.permissions?.includes("view_location") &&
      (permissions?.organisationLimits?.locations?.isLimited === false ||
        permissions?.organisationLimits?.locations?.max >
          permissions?.featureUsage?.locations)
    );
  }, [permissions]);

  return {
    hasDBCreationPermission,
    hasDataviewCreationPermission,
    hasDatasourceCreationPermission,
    hasGroupPermission,
    hasRolePermission,
    hasUserPermission,
    hasLocationPermission,
  };
};

export default useGetPermissions;
