import "./reportDesignerStyle.css";
import "jspdf-autotable";

import { Alert, Drawer, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useCalculateExpressionMutation,
  useCreteUserReportSbMutation,
  useGetAllTableSkorBoardMutation,
  useGetAllUserReportsSbMutation,
  useGetChartDataMutation,
  useGetDistValuesMutation,
  useGetFilteredTablesMutation,
  useGetListViewDataMutation,
  useGetLocationsMutation,
  useGetReportByIdSbMutation,
  useGetReportTableDataMutation,
  useGetTableDataMutation,
  useUpdateUserReportSbMutation
} from "../../../features/report/skorBoard";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate

import ChartComponent from "./components/draggableItems/ChartComponent";
import CloseIcon from "@mui/icons-material/Close";
import { Close_Icon1 } from "../../../assets/images";
import DataPanel from "./components/DataPanel";
import { DndProvider } from "react-dnd";
import FilterPanel from "./components/FilterPanel";
import { HTML5Backend } from "react-dnd-html5-backend";
import ImageComponent from "./components/draggableItems/ImageComponent";
import Menu from "../DataManagement/components/Menu";
import Nav from "../DataManagement/components/Nav";
import Papa from "papaparse";
import ReportBuildingArea from "./components/ReportBuildingArea";
import ReusableReportModal from "../ReportBuilder/components/ReusableReportModal";
import SummeryComponent from "./components/draggableItems/SummeryComponent";
import TableComponent from "./components/draggableItems/TableComponent";
import TextboxComponent from "./components/draggableItems/TextboxComponent";
import WidgetPanel from "./components/WidgetPanel";
import dayjs from "dayjs";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

function ReportDesigner() {
  const dateOptions = [
    { value: "TODAY", label: "Today" },
    { value: "WTD", label: "Week to Date" },
    { value: "MTD", label: "Month to Date" },
    { value: "YTD", label: "Year to Date" },
  ];
  const pageSizes = [
    { name: "Letter", width: "21.59", height: "27.94" },
    { name: "Legal", width: "21.59", height: "35.56" },
    { name: "A3", width: "29.7", height: "42.0" },
    { name: "A4", width: "21.0", height: "29.7" },
    { name: "A5", width: "14.8", height: "21.0" },
  ];

  const marginOptions = [
    {
      name: "Normal",
      top: "2.54",
      bottom: "2.54",
      left: "2.54",
      right: "2.54",
    },
    {
      name: "Narrow",
      top: "1.27",
      bottom: "1.27",
      left: "1.27",
      right: "1.27",
    },
    {
      name: "Moderate",
      top: "2.54",
      bottom: "2.54",
      left: "1.91",
      right: "1.91",
    },
    { name: "Wide", top: "2.54", bottom: "2.54", left: "5.08", right: "5.08" },
  ];
  const colors = [
    "#bc5090",
    "#ff6361",
    "#ff9d1c",
    "#6d6e75",
    "#6a5acd",
    "#483d8b",
    "#4169e1",
    "#4682b4",
    "#00bfff",
    "#1e90ff",
    "#87cefa",
    "#6495ed",
    "#006400",
    "#228b22",
    "#3cb371",
    "#a0522d",
    "#d2691e",
    "#cd5c5c",
    "#f08080",
    "#fa8072",
    "#e9967a",
    "#2e8b57",
    "#f4a460",
    "#deb887",
    "#d2b48c",
    "#20b2aa",
    "#ffdead",
    "#f5deb3",
    "#8b4513",
    "#d3d3d3",
    "#c0c0c0",
    "#a9a9a9",
    "#808080",
    "#708090",
    "#8b008b",
    "#800080",
    "#4b0082",
    "#7b68ee",
    "#3c1874",
    "#7a378b",
    "#b452cd",
    "#a020f0",
    "#9370db",
    "#5f9ea0",
    "#00ced1",
    "#40e0d0",
    "#48d1cc",
    "#00fa9a",
    "#32cd32",
    "#adff2f",
    "#9acd32",
    "#808000",
    "#6b8e23",
    "#556b2f",
    "#00ff00",
    "#7cfc00",
    "#7fff00",
    "#00ff7f",
    "#66cdaa",
    "#8fbc8f",
    "#90ee90",
    "#98fb98",
    "#ffe4c4",
    "#2f4f4f",
    "#778899",
    "#b0c4de",
    "#dcdcdc",
    "#c71585",
    "#f5f5dc",
    "#f0e68c",
    "#dcdcdc",
    "#ff1493",
    "#c71585",
    "#ff4500",
    "#ff6347",
    "#ff69b4",
    "#ffb6c1",
    "#db7093",
    "#ff8247",
    "#f0e68c",
    "#003f5c",
    "#665191",
    "#a05195",
    "#d45087",
    "#f95d6a",
    "#ff7c43",
    "#ffa600",
    "#58508d",
  ];
  const [activeNavItem, setActiveNavItem] = useState("Home");
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [multiSelectedComponents, setMultiSelectedComponents] = useState([]);

  const [isExpand, setIsExpand] = React.useState(true);
  const [components, setComponents] = React.useState([]);
  const [isExcelUpload, setIsExcelUpload] = React.useState(true);
  const [message, setMessage] = React.useState("Something went wrong");
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("error");
  const [flag, setFlag] = React.useState(false);
  const [componentCount, setComponentCount] = useState(1);
  const [disableRndDragging, setDisableRndDragging] = React.useState(false);
  const [isSelectReport, setIsSelectReport] = React.useState(false);
  const [reportId, setReportId] = React.useState(null);
  const [isLoadingReport, setIsLodingReport] = React.useState(false);
  const [isLoadingTemplate, setIsLoadingTemplate] = React.useState(false);
  const [tables, setTables] = React.useState([]);
  const [myReports, setMyReports] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [listData, setListData] = React.useState([]);
  const [isBorder, setIsBorder] = React.useState(true);
  const [selectedColumn, setSelectedColumn] = React.useState(null);

  const [isAddingMeasure, setIsAddingMeasure] = useState(false);
  const [newMeasure, setNewMeasure] = useState({
    name: "",
    expression: "",
    status: false,
    oladName: "",
  });
  const [showDropdown, setShowDropdown] = useState(false);

  const [isNewReportActive, setIsNewReportActive] = React.useState(false);
  const [isCloseReport, setIsCloseReport] = React.useState(false);
  const [isNewReport, setIsnewReport] = React.useState(false);
  const [isSaveAsReport, setIsSaveAsReport] = React.useState(false);
  const [isNewReportClicked, setIsNewReportClicked] = React.useState(false);
  const [isSaveAsClicked, setIsSaveAsClicked] = React.useState(false);
  const [isSaveChanges, setIsSaveChanges] = React.useState(false);
  const [isGetById, setIsGetById] = React.useState({
    status: false,
    reportId: null,
  });
  const [reportState, setReportState] = useState({
    name: "",
    description: "",
  });
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [loadingTables, setLoadingTables] = React.useState(true);
  const [isAnimated, setIsAnimated] = useState(true);
  const [pdfName, setPdfName] = React.useState("Report.pdf");
  const [hasValidData, setHasValidData] = React.useState(false);
  const customRef = React?.useRef(null);
  const [isDisableTable, setIsDisableTable] = React.useState(false);
  const [islistViewScreen, setIsListViewScreen] = React.useState(false);
  const [filteredReport, setFilteredReport] = React.useState({ status: false, filters: [] });
  const navigate = useNavigate();

  const [inputValue, setInputValue] = React.useState('');
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [dateR, setDateR] = React.useState(null);
  const [selectedColumnsFilters, setSelectedColumnsFilters] = React.useState([]);
  const [dateColumn, setDateColumn] = React.useState(null);

  // Menu Filters
  const [searchValue, setSearchValue] = React.useState('');
  const [dateRangeMenu, setDateRangeMenu] = React.useState([null, null]);
  const [dateRMenu, setDateRMenu] = React.useState(null);
  const [isListLoading, setIsListLoading] = React.useState(false);
  const [isSelectDateColumn, setIsSelectDateColumn] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [skip, setSkip] = React.useState(0);
  const [isTableDataLoading, setIsTableLoading] = React.useState(false)
  const [isFilterLoading, setIsFilterLoading] = React.useState(false);
  const dateformat = [
    { format: "YYYY-MM-DDTHH:mm:ss.SSSZ" },
    { format: "YYYY-MM-DDTHH:mm:ssZ" },
    { format: "D/M/YY" },
    { format: "D/M/YYYY" },
    { format: "DD-MM-YYYY" },
    { format: "MM-DD-YYYY" },
    { format: "YYYY-MM-DD" },
    { format: "DD/MM/YYYY" },
    { format: "MM/DD/YYYY" },
    { format: "YYYY/MM/DD" },
    { format: "D/M/YY HH:mm" },
    { format: "D/M/YYYY HH:mm" },
    { format: "DD-MM-YYYY HH:mm" },
    { format: "MM-DD-YYYY HH:mm" },
    { format: "YYYY-MM-DD HH:mm" },
    { format: "DD/MM/YYYY HH:mm" },
    { format: "MM/DD/YYYY HH:mm" },
    { format: "YYYY/MM/DD HH:mm" },
    { format: "D/M/YY HH:mm:ss" },
    { format: "D/M/YYYY HH:mm:ss" },
    { format: "DD-MM-YYYY HH:mm:ss" },
    { format: "YYYY-MM-DD HH:mm:ss" },
    { format: "YYYY-MM-DDTHH:mm:ssZ" },
    { format: "YYYY-MM-DDTHH:mm:ss.SSSZ" },
    { format: "YYYY-MM-DDTHH:mm:ssZ" },
    { format: "DD-MM-YY" },
    { format: "MM-DD-YY" },
    { format: "YY-MM-DD" },
    { format: "DD/MM/YY" },
    { format: "MM/DD/YY" },
    { format: "YY/MM/DD" },
    { format: "DD-MM-YY HH:mm" },
    { format: "MM-DD-YY HH:mm" },
    { format: "YY-MM-DD HH:mm" },
    { format: "DD/MM/YY HH:mm" },
    { format: "MM/DD/YY HH:mm" },
    { format: "YY/MM/DD HH:mm" },
    { format: "DD-MM-YY HH:mm:ss" },
    { format: "YY-MM-DD HH:mm:ss" },
    { format: "dddd, MMMM Do YYYY" },
    { format: "ddd, MMM D, YYYY" },
    { format: "MMMM D, YYYY" },
    { format: "Do MMMM YYYY" }
  ];
  const cmToPx = (cm) => cm * 37.795275;
  const today = dayjs();

  const defaultSize =
    pageSizes?.find((size) => size?.name === "A4") || pageSizes[3];
  const defaultWidthPx = cmToPx(parseFloat(defaultSize?.width));
  const defaultHeightPx = cmToPx(parseFloat(defaultSize?.height));

  const defaultMargin = marginOptions?.find(
    (margin) => margin?.name === "Narrow"
  );

  const [reportProperty, setReportProperty] = React.useState({
    reportId: "",
    name: "",
    size: {
      name: defaultSize?.name,
      width: defaultWidthPx?.toFixed(0),
      height: defaultHeightPx?.toFixed(0),
    },
    margin: {
      name: defaultMargin?.name,
      top: defaultMargin?.top
        ? (defaultMargin?.top * 37.795275).toFixed(0)
        : "0",
      bottom: defaultMargin?.bottom
        ? (defaultMargin?.bottom * 37.795275).toFixed(0)
        : "0",
      left: defaultMargin?.left
        ? (defaultMargin?.left * 37.795275).toFixed(0)
        : "0",
      right: defaultMargin?.right
        ? (defaultMargin?.right * 37.795275).toFixed(0)
        : "0",
    },

  });

  const [listViewData, setListViewData] = React.useState({
    tableId: null,
    columns: [],
    data: [],
    filters: {},
    dateColumn: {}
  })

  // Mutations
  const [getAllTablesSkorBoard] = useGetAllTableSkorBoardMutation();
  // const [getTableDataSkorBoard] = useGetTableDataMutation();
  const [getTableDataSkorBoard] = useGetReportTableDataMutation()
  const [getAllUserReports] = useGetAllUserReportsSbMutation();
  const [getUserReportsById] = useGetReportByIdSbMutation();
  const [createUserReport] = useCreteUserReportSbMutation();
  const [updateUserReport] = useUpdateUserReportSbMutation();
  const [calculateExpression] = useCalculateExpressionMutation();
  const [getLocations] = useGetLocationsMutation();
  const [getFilteredTables] = useGetFilteredTablesMutation();
  const [getListVewFilterData] = useGetListViewDataMutation();
  const [getDistValues] = useGetDistValuesMutation();
  const [getChartData] = useGetChartDataMutation();

  //Refs
  const dropRef = React.useRef(null);
  const targetDivRef = React.useRef(null);
  const echartsRef = React.useRef(null);

  const getTables = async () => {
    try {
      setLoadingTables(true);
      const result = await getAllTablesSkorBoard().unwrap();
      setTables(result?.data);
      setLoadingTables(false);
      return result?.data;
    } catch (error) {
      console.log(error, "Error");
    }
  };
  const getLocationsSb = async () => {
    try {
      const result = await getLocations().unwrap();
      setLocations(result?.data);
    } catch (err) {
      console.log(err, "Error");
    }
  };
  const getDefaultProperties = (type) => {
    switch (type) {
      case "table":
        return {
          height: 380,
          width: 925,
          left: 20,
          top: 20,
          bottom: 20,
          right: 20,
          borderType: "solid",
          borderColor: "#B7B7B7",
          borderWidth: 1,
          backgroundColor: "rgba(255, 255, 255, 1)",
          align: "center",
          textColor: "rgba(0,0, 0, 1)",
          fontFamily: "",
          fontWeight: "",
          fontSize: 13,
          name: `Table`,
          columns: [],
          columnProperties: getDefaultColumnsProperties([]),
          data: [],
          templateId: null,
          description: null,
          paddingLeft: 1,
          paddingTop: 1,
          paddingRight: 1,
          paddingBottom: 1,
          verticalAlign: "middle",
          allColumns: [],
          logColumns: [],
          layouts: {}
        };
      case "chart":
        return {
          chartType: "bar",
          //data: currentReportData?.data,
          columns: ["Column 1", "Column 2"],
          data: [],
          option: getDefaultChartOption(),
          height: 400,
          width: 800,
          left: 0,
          top: 20,
          bottom: 20,
          right: 20,
          borderRadius: 0,
          borderType: "solid",
          borderColor: "#c8c8c8",
          borderWidth: 0,
          name: "Chart",
          xAxis: undefined,
          yAxis: undefined,
          line: null,
          allColumns: [],
          zAxis: [],
          tableName: "",
          rowFields: [],
          colFields: [],
          legends: [],
          slice: [],
          tableView: "Pivot Table",
          layouts: {},
          drillData: {
            level: 0,
            level1: { xAxis: "", yAxis: "" },
            level2: { xAxis: "", yAxis: "" },
            breadcrumbs: [{ level: 0, xAxisValue: "Main" }],
          },
          pieDrillData: {
            level: 0,
            level1: { slice: "" },
            level2: { slice: "" },
            breadcrumbs: [{ level: 0, sliceValue: "Main" }],
          },
          aggType: "Count",
        };
      case "textbox":
        return {
          text: "",
          height: 120,
          width: 520,
          left: 20,
          top: 20,
          bottom: 20,
          right: 20,
          borderType: "solid",
          borderColor: "#B7B7B7",
          borderWidth: 0,
          backgroundColor: "transparent",
          align: "left",
          textColor: "#000",
          fontFamily: "",
          fontWeight: "",
          fontSize: 15,
          name: "Textbox",
          paddingLeft: 1,
          paddingTop: 1,
          paddingRight: 1,
          paddingBottom: 1,
          textdecoration: "none",
          lineHeight: 1,
          editorData: "",
          layouts: {}
        };
      case "image":
        return {
          src: "",
          name: "Image",
          left: 20,
          top: 20,
          bottom: 20,
          right: 20,
          height: 214, //170
          width: 195,
          borderType: "solid",
          borderColor: "#B7B7B7",
          borderWidth: 0,
          align: "center",
          textColor: "#000",
          fontFamily: "",
          fontWeight: "",
          fontSize: 16,
          backgroundColor: "transparent",
          layouts: {}
        };
      case "shape":
        return { type: "circle" };
      case "summery":
        return {
          text: "",
          height: 80,
          width: 920,
          left: 20,
          top: 20,
          bottom: 20,
          right: 20,
          borderType: "solid",
          borderColor: "#B7B7B7",
          borderWidth: 1,
          backgroundColor: "transparent",
          align: "left",
          textColor: "#000",
          fontFamily: "",
          fontWeight: "",
          fontSize: 15,
          name: "Summery",
          paddingLeft: 1,
          paddingTop: 1,
          paddingRight: 1,
          paddingBottom: 1,
          textdecoration: "none",
          lineHeight: 1,
          measures: [],
          layouts: {}
        };
      default:
        return {
          height: 100,
          width: 250,
          left: "0",
          top: "0",
          borderType: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          backgroundColor: "transparent",
          name: `Table`,
          columns: ["Column 1", "Column 2"],
          data: [{ "Column 1": "Data 1", "Column 2": "Data 2" }],
          templateId: null,
          layouts: {}
        };
    }
  };
  const getDefaultChartOption = () => ({
    // title: {
    //     text: 'Chart',
    // },
    backgroundColor: "transparent",
    tooltip: {},
    legend: {
      left: "center",
      data: ["Example"],
    },
    xAxis: {
      data: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"],
      rotate: "-45",
      axisLabel: {
        interval: 0,
        rotate: "-45",
        color: "#4b5563",
        fontSize: 12,
        fontFamily: "Inter",
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      interval: 2,
      axisLabel: {
        color: "#4b5563",
        fontSize: 12,
        fontFamily: undefined,
      },
    },
    series: [
      {
        name: "Example",
        type: "bar",
        category: "Column 2",
        data: [5, 4, 4, 5, 3, 2, 6, 7, 4, 3, 5],
        itemStyle: { color: "#ddd" },
      },
    ],
  });
  const getDefaultColumnsProperties = (columns) => {
    return columns?.map((item, index) => {
      return {
        style: {
          id: index,
          name: item,
          align: undefined,
          borderType: undefined,
          borderColor: undefined,
          borderWidth: 1,
          backgroundColor: undefined,
          textColor: undefined,
          active: true,
          fontFamily: undefined,
          fontWeight: undefined,
          fontSize: undefined,
          isIncoming: true,
          isComputedColumn: false,
          computedColumns: {},
        },
      };
    });
  };
  const handlePropertyChange = (propertyChange) => {
    const updatedComponents = components?.map((item) => {
      if (item?.id === selectedComponent?.id) {
        return {
          ...item,
          properties: {
            ...item?.properties,
            ...propertyChange,
          },
        };
      }
      return item;
    });
    console.log(updatedComponents, ">>> UPDATED COMPONENT");
    setComponents(updatedComponents);
  };
  const handleDeleteComponent = (id) => {
    setSelectedComponent(null);
    setMultiSelectedComponents((prev) => prev?.filter((item) => item !== id));
    const newComponents = components?.filter(
      (component) => component?.id !== id
    );
    // const hasTableComponent = newComponents.some(component => component.type === 'table');
    // const hasChartComponent = newComponents.some(component => component?.type === 'chart');
    // isTableView && !hasTableComponent ? setIsTableView(false) : "";
    // isChartView && !hasChartComponent ? setIsChartView(false) : "";
    setIsAddingMeasure(false);
    setNewMeasure({ name: "", expression: "", status: false, oladName: "" });
    setComponents(newComponents);
  };
  const handleAddMeasure = () => {
    setIsAddingMeasure(true); // Show the input fields for adding a measure
    setNewMeasure({ ...newMeasure, status: true });
  };
  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);

      if (file?.type === "text/csv") {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    });
  };

  const handleComponentDrop = async (item, size, isClick = false) => {
    if (isDisableTable && item?.type === "table") {
      return;
    }
    if (size) {
      item.properties.height = size?.height;
      item.properties.width = size?.width;
    }
    item?.type === "table" ? setIsDisableTable(true) : "";
    if (!components.some((component) => component?.id === item?.id)) {
      const parentEle = dropRef?.current;
      let top = 0;
      await components.forEach((component) => {
        const childElement = document.getElementById(component.id);
        if (childElement) {
          const childBottom =
            component.properties.top + childElement.offsetHeight + 10;
          top = Math.max(top, childBottom);
        }
      });
      setFlag(true);
      //    let propertiesI = await getDefaultProperties(item.type);
      let propertiesI = item?.properties;
      propertiesI.left = 0;
      if (isClick) {
        propertiesI.top = top;
      }
      propertiesI.layouts.w = 3;
      propertiesI.layouts.h = 3;
      propertiesI.layouts.i = `component-${componentCount}`;
      propertiesI.layouts.x = 0;
      propertiesI.layouts.y = 0;
      console.log(propertiesI, "propertiessssss")
      propertiesI.width = parentEle ? parentEle.offsetWidth : 1020;
      const newComponent = {
        id: `component-${componentCount}`,
        type: item?.type,
        properties: propertiesI,
      };
      setSelectedComponent(newComponent);
      setComponents([...components, newComponent]);
      setComponentCount(componentCount + 1);
      setIsAddingMeasure(false);
      setNewMeasure({ name: "", expression: "", status: false, oladName: "" });
      setShowDropdown(false);
    }
  };
  const handleComponentSelect = (event, item, id) => {
    if (filteredReport.status === true && item.type !== "table") {
      return;
    }
    if (event.ctrlKey) {
      setMultiSelectedComponents((prev) => {
        if (prev.includes(id)) {
          return prev.filter((compId) => compId !== id); // Deselect if already selected
        } else {
          if (selectedComponent !== null) {
            return [...prev, id, selectedComponent?.id]; // Select additional component
          } else {
            return [...prev, id]; // Select additional component
          }
        }
      });
    } else {
      // Normal selection: set only one component in object state
      // setSelectedComponent({ id, properties: components.find((item) => item.id === id)?.properties });
      // setMultiSelectedComponents([]); // Reset multi-selection
      if (selectedComponent?.id !== item?.id) {
        setIsAddingMeasure(false);
        setNewMeasure({
          name: "",
          expression: "",
          status: false,
          oladName: "",
        });
        setShowDropdown(false);
      }
      setSelectedComponent(item);
      setFlag(true);
    }
  };
  const showAlert = (msg, severity) => {
    setOpen(true);
    setMessage(msg);
    setSeverity(severity);
  };
  const getAllReports = async (tables) => {
    try {
      const result = await getAllUserReports().unwrap();
      setMyReports(result?.data);
      reportId === null && result?.data?.length > 0 && await getReportById(result?.data[0]?.id, tables);
      setIsNewReportActive(false);
      setIsSaveChanges(false);
    } catch (error) {
      setIsLodingReport(false)
      console.log(error, "Error");
    }
  };
  const getTableData = async ({ tableId, tableName, columnName, columnId }) => {
    const result = await getTableDataSkorBoard({
      name: tableName,
      tableId: tableId,
      skip: 0,
      limit: 200
    }).unwrap();
    const columnProToUp = result?.data?.tableProperties?.columnProperties?.map(
      (item) => {
        return {
          ...item,
          columnId: result?.data?.metaData?.columnMetaData?.find(
            (i) => i?.column_name === item?.name
          )?.column_id,
        };
      }
    );
    let top = 0;
    await components.forEach((component) => {
      const childElement = document.getElementById(component.id);
      if (childElement) {
        const childBottom =
          component.properties.top + childElement.offsetHeight + 10;
        top = Math.max(top, childBottom);
      }
    });

    if (components?.length <= 0) {
      let propertiesI = await getDefaultProperties("table");
      const parentEle = dropRef?.current;
      propertiesI.left = 0;
      propertiesI.top = top;
      propertiesI.layouts.w = 3;
      propertiesI.layouts.h = 3;
      propertiesI.layouts.i = `component-${componentCount}`;
      propertiesI.layouts.x = 0;
      propertiesI.layouts.y = 0;
      propertiesI.width = parentEle ? parentEle?.offsetWidth : 1020;
      setSelectedComponent({
        id: `component-${componentCount}`,
        type: "table",
        name: tableName,
        properties: {
          ...propertiesI,
          name: tableName,
          // tableId: result?.data?.metaData?.tableMetaData?.table_id,
          tableId: tableId,
          // data: result?.data?.tableData,
          data: result?.data?.data,
          columns: [{ columnName, columnId, count: 0 }],
          columnProperties: columnProToUp,
          allColumns: result?.data?.tableProperties?.columnProperties
            ?.filter((item) => !item?.isDeleted && !item?.isHidden)
            ?.map((item) => item?.name),
        },
      });
      setComponents([
        ...components,
        {
          id: `component-${componentCount}`,
          type: "table",
          properties: {
            ...propertiesI,
            name: tableName,
            // tableId: result?.data?.metaData?.tableMetaData?.table_id,
            tableId: tableId,
            // data: result?.data?.tableData,
            data: result?.data?.data,
            columns: [{ columnName, columnId, count: 0 }],
            columnProperties: columnProToUp,
            allColumns: result?.data?.tableProperties?.columnProperties
              ?.filter((item) => !item?.isDeleted && !item?.isHidden)
              ?.map((item) => item?.name),
          },
        },
      ]);
      setComponentCount(componentCount + 1);
    } else {
      setSelectedComponent({
        ...selectedComponent,
        properties: {
          ...selectedComponent?.properties,
          // tableId: result?.data?.metaData?.tableMetaData?.table_id,
          tableId: tableId,
          // data: result?.data?.tableData,
          data: result?.data?.data,
          name: tableName,
          columns: [{ columnName, columnId, count: 0 }],
          columnProperties: columnProToUp,
          allColumns: result?.data?.tableProperties?.columnProperties
            ?.filter((item) => !item?.isDeleted && !item?.isHidden)
            ?.map((item) => item?.name),
        },
      });
      handlePropertyChange({
        // data: result?.data?.tableData,
        data: result?.data?.data,
        // tableId: result?.data?.metaData?.tableMetaData?.table_id,
        tableId: tableId,
        columns: [{ columnName, columnId, count: 0 }],
        name: tableName,
        columnProperties: columnProToUp,
        allColumns: result?.data?.tableProperties?.columnProperties
          ?.filter((item) => !item?.isDeleted && !item?.isHidden)
          ?.map((item) => item?.name),
      });
    }
    setFlag(true);
  };
  const handleCloseReport = async () => {
    setIsnewReport(false);
    setIsCloseReport(false);
    setReportId(null);
    setIsNewReportActive(false);
    setComponents([]);
    setSelectedComponent(null);
  };
  const getReportById = async (id, myTables) => {
    setIsGetById({ status: false, reportId: null });
    setIsNewReportActive(false);
    setIsLodingReport(true);
    setDateRMenu(null);
    setSearchValue('');
    setDateRangeMenu([null, null])

    await setComponents([]);
    try {
      const response = await getUserReportsById({ id, limit: 200, skip: 0 }).unwrap(); // Fetch user reports by ID and unwrap the result
      setPdfName(response?.data?.name || "Report");
      const result = response?.data?.components || [];
      const targetComponent = result.find(
        component => component.type === "table" || component.type === "chart"
      );
      setReportProperty({
        reportId: response?.data?.id,
        name: response?.data?.name,
        size: response?.data?.report_property?.size,
        margin: response?.data?.report_property?.margin,

      });
      const listViewResultData = response?.data?.list_view_property?.tableId && await getListVewFilterData({
        tableId: response?.data?.list_view_property?.tableId,
        filters: {
          searchQuery: inputValue,
          dateFilter: {},
          columns: response?.data?.list_view_property?.columns?.map(item => item?.columnName),
          dateColumn: response?.data?.list_view_property?.dateColumn?.column_name
        },
        skip: 0,
        limit: 100
      }).unwrap();

      const Distvalues = response?.data?.list_view_property?.tableId && await getDistValues({
        tableId: response?.data?.list_view_property?.tableId,
        filters: {
          searchQuery: "",
          dateFilter: {},
          dateColumn: null,
          columns: response?.data?.list_view_property?.columns?.map((item => item?.columnName)),
          columnFilters: {
            column_name: null,
            searchQuery: ""
          },
        },
        skip: 0,
        limit: 20,
      })?.unwrap();
      setCount(listViewResultData?.data?.totalCount)
      const updatedColumns = listViewResultData?.data?.tableProperties?.columnProperties?.map(column => ({
        ...column,
        values: Distvalues?.data[column?.name]?.values,
        totalCount: Distvalues?.data[column?.name]?.total_count
      }));
      setListViewData({
        ...listViewData,
        data: listViewResultData?.data?.tableData,
        columns: response?.data?.list_view_property?.columns,
        dateColumn: response?.data?.list_view_property?.dateColumn,
        tableId: response?.data?.list_view_property?.tableId,
        columnProperties: updatedColumns,
        filters: {}
      })
      const tableComponentIndex = result.findIndex(
        (item) => item?.type === "table"
      );
      // if (tableComponentIndex !== -1) {
      //     const tableComponent = result[tableComponentIndex];
      //     const columns = Object.keys(tableComponent?.properties?.data[0] || {});

      //     const updatedProperties = { ...tableComponent.properties };
      //     const updatedComponents = [
      //         ...result.slice(0, tableComponentIndex),
      //         {
      //             ...tableComponent,
      //             properties: updatedProperties
      //         },
      //         ...result.slice(tableComponentIndex + 1),
      //     ];
      //     const result2 = Object.keys(updatedComponents?.find((item) => item?.type === "table")
      //         ?.properties?.data[0]).filter(column => updatedComponents?.find((item) => item?.type === "table")?.properties?.columns.some(col => column === col.trim()));

      //     const updatedComponents2 = updatedComponents?.map((item, index) => {
      //         return { ...item, id: `component-${index + 1}`, properties: { ...item?.properties, columns: result2 } };
      //     });
      //     await setComponents(updatedComponents2);
      //     // setReportDefaultState(updatedComponents);
      //     setComponentCount(updatedComponents2?.length + 1);

      // } else {
      let allExpressions = result
        ?.filter((item) => item?.type === "summery")
        ?.map((summery) =>
          summery?.properties?.measures?.map((i) => ({
            expression: i?.computed_expression,
            component_id: summery?.id,
          }))
        );

      allExpressions = allExpressions?.flatMap((group) =>
        group?.map(({ expression, component_id }) => ({
          expression,
          component_id,
        }))
      );
      const calculatedExp =
        allExpressions &&
        allExpressions?.length > 0 &&
        (await calculateExpression({ expressions: allExpressions })?.unwrap());

      let updatedComponents = result?.map((component, index) => {
        if (component?.type === "summery") {
          // Create a shallow copy of the summary
          return {
            ...component,
            id: `component-${index + 1}`,
            properties: {
              ...component?.properties,
              layouts: { ...component?.properties?.layouts, i: `component-${index + 1}` },
              measures: component?.properties.measures?.map((measure) => {
                // Find the matching updated result
                const updated = calculatedExp?.data?.find(
                  (res) =>
                    res.component_id === component?.id &&
                    res.expression === measure?.computed_expression
                );
                // Return a new measure object
                return updated
                  ? { ...measure, result: updated.result }
                  : { ...measure };
              }),
            },
          };
        }
        const tableToUse = myTables ? myTables : tables;
        if (component?.type === "table") {
          let table = tableToUse?.find(
            (item) =>
              item?.tableMetaData?.table_id === component?.properties?.tableId
          );
          return {
            ...component,
            id: `component-${index + 1}`,
            properties: {
              ...component?.properties,
              layouts: { ...component?.properties?.layouts, i: `component-${index + 1}` },
              columns: component?.properties?.columns?.map((item) => {

                return {
                  ...item,
                  columnName: table?.columnMetaData?.find(
                    (i) => i?.column_id === item?.columnId
                  )?.column_name,
                };
              }),
            },
          };
        }

        return {
          ...component, id: `component-${index + 1}`,
          properties: {
            ...component?.properties,
            layouts: { ...component?.properties?.layouts, i: `component-${index + 1}` }
          }
        };
      });


      setComponents(updatedComponents);
      setComponentCount(updatedComponents?.length + 1);
      //  }
      setFlag(true);
      setIsLodingReport(false);
      setReportId(response?.data?.id);
      setIsSelectReport(true);
      setSelectedComponent(null);
    } catch (error) {
      // Handle error appropriately (e.g., logging, displaying an error message)
      console.error("Error fetching report by ID:", error);
    }
  };
  const handleSaveReport = async ({ parentId }) => {
    if (components?.length <= 0) {
      showAlert("Report is Empty", "error");
      setReportState({ name: "", description: "" });
      setIsnewReport(false);
      return;
    }
    setIsnewReport(false);
    setSelectedComponent(null);
    const sanitizedComponents = components?.map((component) => {
      if (
        (component?.type === "table" || component?.type === "chart") &&
        component?.properties
      ) {
        const { data, ...restProperties } = component.properties;
        return { ...component, properties: restProperties };
      }
      return component;
    });
    setIsSaveChanges(false);
    const reportName = myReports?.find((item) => item?.id === reportId)?.name;
    setPdfName(`${reportName}` || "Report");

    const { data, ...restProp } = listViewData

    if (reportId !== null && !isSaveAsReport) {
      try {
        const result = await updateUserReport({
          reportId: reportId,
          components: sanitizedComponents,
          parentId: parentId,
          report_property: reportProperty,
          list_view_property: { ...restProp }
        });
        getAllReports();
        // showAlert("Report Updated Successfully.", "success");
        showAlert(
          `Report Updated Successfully "${myReports?.find((item) => item?.id === reportId)?.name
          }"`,
          "success"
        );
      } catch (err) {
        console.log(err, "Error while Updateing");
      }
    } else {
      try {
        if (myReports?.find((item) => item?.name === reportState?.name)) {
          showAlert(
            `The report named "${reportState?.name}" already exists. Please choose a different name.`,
            "info"
          );
          return;
        }

        // const payload = JSON.parse(JSON.stringify({
        //   name: reportState?.name,
        //   description: reportState?.description,
        //   components: sanitizedComponents,
        //   parentId: parentId,
        //   report_property: reportProperty,
        //   list_view_property: restProp
        // }))
        // console.log(payload, ">>..jkjjj")
        const result = await createUserReport({
          name: reportState?.name,
          description: reportState?.description,
          components: sanitizedComponents,
          parentId: parentId,
          report_property: reportProperty,
          list_view_property: restProp
        })?.unwrap();

        setReportState({ name: "", description: "" });
        setIsSaveAsReport(false);
        showAlert("Report Created Successfully.", "success");
        getAllReports();
        setReportId(result?.data?.id);
        setIsNewReportActive(false);
      } catch (err) {
        console.log(err, "Error while create")
        setReportId(null);
        setIsNewReportActive(false);
        showAlert(err?.data?.message, "error");
        setReportState({ name: "", description: "" });
        setComponents([]);
        setSelectedComponent(null);
      }
    }
  };
  const calculateExpressions = async (payload) => {
    try {
      const result = await calculateExpression(payload).unwrap();

      return result;
    } catch (err) {
      showAlert(err?.data?.message, "error");
    }
  };
  const toggleDrawer = (open) => () => {
    if (filteredReport?.status === true) return;
    setIsDrawerOpen(open);
  };
  const handleNewReport = async () => {
    // handleNewReport();
    setListViewData({
      tableId: null,
      columns: [],
      data: [],
      filters: {},
      dateColumn: {}
    })
    if (reportId !== null && components?.length > 0) {
      setIsnewReport(true);
      setIsSaveChanges(true);
      setIsCloseReport(true);
    } else {
      setIsNewReportActive(true);
      setIsNewReportClicked(true);
      setComponents([]);
      setSelectedComponent(null);
      setReportId(null);
    }
  };
  const handleSaveHit = () => {
    setIsnewReport(true);
    if (reportId === null) {
      setIsSaveAsReport(true);
    } else {
      handleSaveReport({ parentId: null });
    }
  };

  const handleSelectColumn = (column, name) => {
    let seCol = listViewData?.columnProperties?.find(item => item?.name === column)
    // seCol = name ? { ...seCol, name: seCol?.new_column_name } : seCol
    setSelectedColumn(seCol)
  }
  // charts
  const findOverallMaxValue = (series) => {
    // Flatten all data arrays into one and find the maximum value
    const allDataValues = series.flatMap((item) => item.data);
    return Math.max(...allDataValues);
  };

  // const generateChartData = (data, xHeader, yHeader, zHeaders, lineHeader) => {
  //     console.log(data, ">>>> data ")

  //     // Generate bar chart data
  //     const barResult = {};
  //     const yAxisColors = new Set();
  //     // data?.forEach((row) => {
  //     //     const xValue = row[xHeader];
  //     //     const yValue = row[yHeader];
  //     //     if (!barResult[xValue]) {
  //     //         barResult[xValue] = {};
  //     //     }
  //     //     if (!barResult[xValue][yValue]) {
  //     //         barResult[xValue][yValue] = 0;
  //     //     }
  //     //     barResult[xValue][yValue]++;
  //     // });
  //     data?.forEach((row) => {
  //         const xValue = row[xHeader];
  //         const yValue = row[yHeader];

  //         if (!barResult[xValue]) {
  //             barResult[xValue] = {};
  //         }

  //         // If yValue is a number, sum it; otherwise, count occurrences
  //         if (!barResult[xValue][yValue]) {
  //             barResult[xValue][yValue] = Number.isFinite(row[yHeader]) ? 0 : 0;
  //         }

  //         if (Number.isFinite(row[yHeader])) {
  //             barResult[xValue][yValue] += row[yHeader]; // Sum if numeric
  //         } else {
  //             barResult[xValue][yValue]++; // Count occurrences otherwise
  //         }
  //     });
  //     const series = [];
  //     const xValues = [...new Set(data?.map((row) => row[xHeader]))];
  //     let colorIndex = 0;
  //     // Generate bar series
  //     if (yHeader !== '') {
  //         // Object.keys(barResult).forEach((xValue) => {
  //         //     Object.keys(barResult[xValue] || {}).forEach((yValue) => {
  //         //         let seriesItem = series.find((s) => s.name === yValue && s.type === "bar");
  //         //         if (!seriesItem) {
  //         //             seriesItem = {
  //         //                 name: yValue,
  //         //                 type: "bar",
  //         //                 data: Array(xValues.length).fill(0),
  //         //                 itemStyle: {
  //         //                     color: colors[colorIndex % colors.length],
  //         //                 },
  //         //                 category: yHeader
  //         //             };
  //         //             series.push(seriesItem);
  //         //             colorIndex++;
  //         //         }
  //         //         const index = xValues.indexOf(xValue);
  //         //         if (index !== -1) {
  //         //             seriesItem.data[index] = barResult[xValue][yValue];
  //         //         }
  //         //     });
  //         // });
  //         Object.keys(barResult).forEach((xValue) => {
  //             Object.keys(barResult[xValue] || {}).forEach((yValue) => {
  //                 let seriesItem = series.find((s) => s.name === yValue && s.type === "bar");
  //                 if (!seriesItem) {
  //                     seriesItem = {
  //                         name: yValue,
  //                         type: "bar",
  //                         data: Array(xValues.length).fill(0),
  //                         itemStyle: {
  //                             color: colors[colorIndex % colors.length],
  //                         },
  //                         category: yHeader
  //                     };
  //                     series.push(seriesItem);
  //                     colorIndex++;
  //                 }
  //                 const index = xValues.indexOf(xValue);
  //                 if (index !== -1) {
  //                     seriesItem.data[index] = barResult[xValue][yValue]; // Now using sum instead of count
  //                 }
  //             });
  //         });
  //     }
  //     const createSeries = (name, color, header) => ({
  //         name,
  //         type: 'bar',
  //         data: Array(xValues.length).fill(0),
  //         itemStyle: { color },
  //         category: header
  //     });
  //     const fillSeriesData = (series, header, value, color) => {
  //         const seriesItem = createSeries(value, color, header);
  //         xValues.forEach((xValue, index) => {
  //             const filteredData = data.filter(row => row[xHeader] === xValue && row[header] === value);
  //             seriesItem.data[index] = filteredData.length; // Count occurrences
  //         });
  //         return seriesItem;
  //     };
  //     zHeaders?.filter((item) => item !== yHeader)?.forEach((header, headerIndex) => {
  //         // Get unique values for the current header
  //         const uniqueValues = [...new Set(data?.map(row => row[header]))];
  //         uniqueValues.forEach((value, valueIndex) => {
  //             let color;
  //             do {
  //                 color = true ? colors[(colorIndex + yAxisColors.size) % colors.length] : '#000';
  //                 colorIndex++;
  //             } while (yAxisColors.has(color))
  //             //const color = true ? colors[(headerIndex * uniqueValues.length + valueIndex ) % colors.length] : '#000';
  //             series.push(fillSeriesData(series, header, value, color));
  //         });
  //     });
  //     // Generate line series if lineHeader is provided
  //     if (lineHeader) {
  //         const lineResult = {};
  //         data.forEach((row) => {
  //             const xValue = row[xHeader];
  //             const lineValue = row[lineHeader];
  //             if (!lineResult[xValue]) {
  //                 lineResult[xValue] = {};
  //             }
  //             if (!lineResult[xValue][lineValue]) {
  //                 lineResult[xValue][lineValue] = 0;
  //             }
  //             lineResult[xValue][lineValue]++;
  //         });

  //         // Generate line series
  //         Object.keys(lineResult).forEach((xValue) => {
  //             Object.keys(lineResult[xValue] || {}).forEach((lineValue) => {
  //                 let seriesItem = series.find((s) => s.name === lineValue && s.type === "line");
  //                 if (!seriesItem) {
  //                     seriesItem = {
  //                         name: lineValue,
  //                         type: "line",
  //                         data: Array(xValues.length).fill(0),
  //                         itemStyle: {
  //                             color: colors[colorIndex % colors.length],
  //                         },
  //                         category: lineHeader
  //                     };
  //                     series.push(seriesItem);
  //                     colorIndex++;
  //                 }
  //                 const index = xValues.indexOf(xValue);
  //                 if (index !== -1) {
  //                     seriesItem.data[index] = lineResult[xValue][lineValue];
  //                 }
  //             });
  //         });
  //     }
  //     const overallMaxValue = findOverallMaxValue(series);

  //     const interval = overallMaxValue <= 5 ? 1 : overallMaxValue <= 10 ? 2 : overallMaxValue <= 20 ? 4 : overallMaxValue <= 100 ? 10 : 20;

  //     console.log(interval, ">>> Inetr val .....")
  //     return { series, xValues, interval };
  // };
  // const generateChartData = (data, xHeader, yHeader, zHeaders, lineHeader) => {
  //     console.log(data, ">>>> data ");
  //     console.log(zHeaders, ">>>> Z Headers hhhhhh");

  //     const barResult = {};
  //     const series = [];
  //     const xValues = [...new Set(data?.map(row => row[xHeader]))];
  //     let colorIndex = 0;

  //     // Step 1: Process Data and Categorize by Legend
  //     data?.forEach((row) => {
  //         const xValue = row[xHeader];
  //         const zValue = zHeaders.length ? row[zHeaders[0]] : "Total"; // Use first zHeader or default "Total"
  //         const yValue = row[yHeader];

  //         if (!barResult[xValue]) {
  //             barResult[xValue] = {};
  //         }

  //         if (!barResult[xValue][zValue]) {
  //             barResult[xValue][zValue] = 0;
  //         }

  //         if (Number.isFinite(yValue)) {
  //             barResult[xValue][zValue] += yValue; // Sum numeric values
  //         } else {
  //             barResult[xValue][zValue]++; // Count occurrences otherwise
  //         }
  //     });

  //     // Step 2: Generate Bar Series Categorized by Legend
  //     Object.keys(barResult).forEach((xValue) => {
  //         Object.keys(barResult[xValue] || {}).forEach((categoryKey) => {
  //             let seriesItem = series.find((s) => s.name === categoryKey && s.type === "bar");
  //             if (!seriesItem) {
  //                 seriesItem = {
  //                     name: categoryKey, // Legend category
  //                     type: "bar",
  //                     data: Array(xValues.length).fill(0),
  //                     itemStyle: {
  //                         color: colors[colorIndex % colors.length],
  //                     },
  //                     category: zHeaders[0] || yHeader, // Use zHeader if selected
  //                     label: {
  //                         show: true,
  //                         position: 'top',
  //                         formatter: (params) => `${params.value}`,
  //                         fontSize: 12,
  //                         fontWeight: 'bold',
  //                         color: '#000'
  //                     }
  //                 };
  //                 series.push(seriesItem);
  //                 colorIndex++;
  //             }
  //             const index = xValues.indexOf(xValue);
  //             if (index !== -1) {
  //                 seriesItem.data[index] = barResult[xValue][categoryKey]; // Use categorized sum
  //             }
  //         });
  //     });

  //     // Step 3: Generate Line Series if `lineHeader` is provided
  //     if (lineHeader) {
  //         const lineResult = {};
  //         data.forEach((row) => {
  //             const xValue = row[xHeader];
  //             const lineValue = row[lineHeader];
  //             if (!lineResult[xValue]) {
  //                 lineResult[xValue] = {};
  //             }
  //             if (!lineResult[xValue][lineValue]) {
  //                 lineResult[xValue][lineValue] = 0;
  //             }
  //             lineResult[xValue][lineValue]++;
  //         });

  //         Object.keys(lineResult).forEach((xValue) => {
  //             Object.keys(lineResult[xValue] || {}).forEach((lineValue) => {
  //                 let seriesItem = series.find((s) => s.name === lineValue && s.type === "line");
  //                 if (!seriesItem) {
  //                     seriesItem = {
  //                         name: lineValue,
  //                         type: "line",
  //                         data: Array(xValues.length).fill(0),
  //                         itemStyle: {
  //                             color: colors[colorIndex % colors.length],
  //                         },
  //                         category: lineHeader
  //                     };
  //                     series.push(seriesItem);
  //                     colorIndex++;
  //                 }
  //                 const index = xValues.indexOf(xValue);
  //                 if (index !== -1) {
  //                     seriesItem.data[index] = lineResult[xValue][lineValue];
  //                 }
  //             });
  //         });
  //     }

  //     // Step 4: Calculate Interval for Y-axis Scaling
  //     const overallMaxValue = Math.max(...series.flatMap(s => s.data));
  //     const interval = overallMaxValue <= 5 ? 1 : overallMaxValue <= 10 ? 2 : overallMaxValue <= 20 ? 4 : overallMaxValue <= 100 ? 10 : 20;

  //     console.log(interval, ">>> Interval value");
  //     return { series, xValues, interval };
  // };

  const generateChartData = async (
    data,
    xHeader,
    yHeader,
    zHeaders = [
      selectedComponent?.properties?.legend?.map(
        (item) => item?.columnName || []
      ),
    ],
    lineHeader,
    aggFunction = selectedComponent?.properties?.aggType,
    tableId
  ) => {
    const barResult = {};
    const countTracker = {}; // For calculating average
    const series = [];
    const xValues = [...new Set(data?.map((row) => row[xHeader]))];
    let colorIndex = 0;
    // try {
    //   console.log(listViewData, ">>>> List View Data  ....");
    //   const generateChartData = await getChartData({
    //     tableId: tableId ? tableId : selectedComponent?.properties?.tableId,
    //     chartProperties: {
    //       type: selectedComponent?.properties?.chartType,
    //       xHeader: xHeader,
    //       yHeader: yHeader,
    //       zHeaders: zHeaders,
    //       lineHeader: lineHeader,
    //       aggFunction: aggFunction
    //     },
    //     filters: {},
    //     skip: 0,
    //     limit: 200
    //   })?.unwrap();

    //   console.log(generateChartData?.data?.series, ">>> Generate Chart Data");
    //   return {
    //     series: generateChartData?.data?.series,
    //     xValues: generateChartData?.data?.xValues,
    //     interval: generateChartData?.data?.interval
    //   };
    // } catch (err) {
    //   console.log(err, "Error")
    // }

    // Step 1: Process Data and Categorize by Legend
    data?.forEach((row) => {
      const xValue = row[xHeader];
      const zValue = zHeaders?.length
        ? row[zHeaders[0]] !== undefined
          ? row[zHeaders[0]]
          : "Total"
        : "Total"; // Use first zHeader or default "Total"
      const yValue = row[yHeader];

      if (!barResult[xValue]) {
        barResult[xValue] = {};
        countTracker[xValue] = {}; // Initialize count tracker for average calculation
      }

      if (!barResult[xValue][zValue]) {
        barResult[xValue][zValue] =
          aggFunction === "Min"
            ? Infinity
            : aggFunction === "Max"
              ? -Infinity
              : 0;
        countTracker[xValue][zValue] = 0;
      }

      if (Number.isFinite(yValue)) {
        switch (aggFunction) {
          case "Sum":
            barResult[xValue][zValue] += yValue;
            break;
          case "Count":
            barResult[xValue][zValue]++; // Count occurrences
            break;
          case "Average":
            barResult[xValue][zValue] += yValue;
            countTracker[xValue][zValue]++;
            break;
          case "Minimum":
            barResult[xValue][zValue] = Math.min(
              barResult[xValue][zValue],
              yValue
            );
            break;
          case "Maximum":
            barResult[xValue][zValue] = Math.max(
              barResult[xValue][zValue],
              yValue
            );
            break;
          default:
            barResult[xValue][zValue] += yValue; // Default to Sum
            break;
        }
      } else {
        barResult[xValue][zValue]++; // For non-numeric yValues, count occurrences
      }
    });

    // Step 2: Generate Bar Series Categorized by Legend
    Object.keys(barResult).forEach((xValue) => {
      Object.keys(barResult[xValue] || {}).forEach((categoryKey) => {
        let seriesItem = series.find(
          (s) => s.name === categoryKey && s.type === "bar"
        );
        if (!seriesItem) {
          seriesItem = {
            name: categoryKey === undefined ? "Total" : categoryKey, // Legend category
            type: "bar",
            data: Array(xValues.length).fill(0),
            itemStyle: {
              color: colors[colorIndex % colors.length],
            },
            category: zHeaders[0] || yHeader, // Use zHeader if selected
            label: {
              show: true,
              position: "top",
              formatter: (params) => `${params.value}`,
              fontSize: 12,
              fontWeight: "bold",
              color: "#000",
            },
          };
          series.push(seriesItem);
          colorIndex++;
        }
        const index = xValues.indexOf(xValue);
        if (index !== -1) {
          if (aggFunction === "Avg" && countTracker[xValue][categoryKey] > 0) {
            seriesItem.data[index] = (
              barResult[xValue][categoryKey] / countTracker[xValue][categoryKey]
            ).toFixed(2); // Compute average
          } else {
            seriesItem.data[index] = barResult[xValue][categoryKey]; // Use calculated value
          }
        }
      });
    });

    // Step 3: Generate Line Series if `lineHeader` is provided
    if (lineHeader) {
      const lineResult = {};
      data.forEach((row) => {
        const xValue = row[xHeader];
        const lineValue = row[lineHeader];
        if (!lineResult[xValue]) {
          lineResult[xValue] = {};
        }
        if (!lineResult[xValue][lineValue]) {
          lineResult[xValue][lineValue] =
            aggFunction === "Min"
              ? Infinity
              : aggFunction === "Max"
                ? -Infinity
                : 0;
        }
        switch (aggFunction) {
          case "Sum":
            lineResult[xValue][lineValue] += 1;
            break;
          case "Count":
            lineResult[xValue][lineValue]++;
            break;
          case "Min":
            lineResult[xValue][lineValue] = Math.min(
              lineResult[xValue][lineValue],
              1
            );
            break;
          case "Max":
            lineResult[xValue][lineValue] = Math.max(
              lineResult[xValue][lineValue],
              1
            );
            break;
        }
      });

      Object.keys(lineResult).forEach((xValue) => {
        Object.keys(lineResult[xValue] || {}).forEach((lineValue) => {
          let seriesItem = series.find(
            (s) => s.name === lineValue && s.type === "line"
          );
          if (!seriesItem) {
            seriesItem = {
              name: lineValue,
              type: "line",
              data: Array(xValues.length).fill(0),
              itemStyle: {
                color: colors[colorIndex % colors.length],
              },
              category: lineHeader,
            };
            series.push(seriesItem);
            colorIndex++;
          }
          const index = xValues.indexOf(xValue);
          if (index !== -1) {
            seriesItem.data[index] = lineResult[xValue][lineValue];
          }
        });
      });
    }

    // Step 4: Calculate Interval for Y-axis Scaling
    const overallMaxValue = Math.max(...series.flatMap((s) => s.data));
    const interval =
      overallMaxValue <= 0 ? 0 :
        overallMaxValue <= 5
          ? 1
          : overallMaxValue <= 10
            ? 2
            : overallMaxValue <= 20
              ? 4
              : overallMaxValue <= 100
                ? 10
                : overallMaxValue <= 200
                  ? 20 :
                  overallMaxValue <= 400
                    ? 50
                    : overallMaxValue <= 1000
                      ? 100
                      : overallMaxValue <= 5000
                        ? 500
                        : overallMaxValue <= 10000
                          ? 1000
                          : overallMaxValue <= 50000
                            ? 5000
                            : 10000;

    return { series, xValues, interval };
  };

  // const generatePieChartData = (data, yHeader) => {
  //     //  const data = currentReportData?.data

  //     const result = {};
  //     // Aggregate data based on yHeader
  //     data?.forEach(row => {
  //         const yValue = row[yHeader];
  //         if (!result[yValue]) {
  //             result[yValue] = 0;
  //         }
  //         result[yValue]++;
  //     });
  //     // Convert aggregated data into the format needed for pie chart
  //     const series = [{
  //         name: yHeader,
  //         type: 'pie',
  //         radius: '50%',
  //         data: Object.keys(result)?.map((key, index) => ({
  //             value: result[key],
  //             name: key,
  //             itemStyle: {
  //                 color: colors[index % colors.length] // Set colors for each pie slice
  //             }
  //         })),
  //         emphasis: {
  //             itemStyle: {
  //                 shadowBlur: 10,
  //                 shadowOffsetX: 0,
  //                 shadowColor: 'rgba(0, 0, 0, 0.5)'
  //             }
  //         }
  //     }];
  //     console.log(series, ">>>>>> ");
  //     return { series };
  // };

  const generatePieChartData = (
    data,
    yHeader,
    aggFunction = selectedComponent?.properties?.aggType
  ) => {

    const result = {};
    const countTracker = {}; // For Avg calculation

    // Aggregate data based on yHeader and aggregation function
    data?.forEach((row) => {
      const yValue = row[yHeader];

      // Initialize result and count tracker for each yValue
      if (!result[yValue]) {
        result[yValue] =
          aggFunction === "Min"
            ? Infinity
            : aggFunction === "Max"
              ? -Infinity
              : 0;
        countTracker[yValue] = 0;
      }

      switch (aggFunction) {
        case "Sum":
          if (Number.isFinite(yValue)) result[yValue] += yValue;
          break;
        case "Count":
          result[yValue]++;
          break;
        case "Avg":
          if (Number.isFinite(yValue)) {
            result[yValue] += yValue;
            countTracker[yValue]++;
          }
          break;
        case "Min":
          result[yValue] = Math.min(result[yValue], yValue);
          break;
        case "Max":
          result[yValue] = Math.max(result[yValue], yValue);
          break;
        default:
          if (Number.isFinite(yValue)) result[yValue] += yValue; // Default to Sum
          break;
      }
    });

    // Convert aggregated data into the format needed for pie chart
    const series = [
      {
        name: yHeader,
        type: "pie",
        radius: "50%",
        data: Object.keys(result)?.map((key, index) => {
          const value =
            aggFunction === "Avg" && countTracker[key] > 0
              ? (result[key] / countTracker[key]).toFixed(2) // Compute average
              : result[key];

          return {
            value: value,
            name: key,
            itemStyle: {
              color: colors[index % colors.length], // Set colors for each pie slice
            },
          };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ];
    return { series };
  };

  const generateDoubleChartData = (
    data,
    xHeader,
    yHeader,
    zHeaders,
    aggFunction = "Sum"
  ) => {
    console.log(zHeaders)
    const xValues = [...new Set(data?.map((row) => row[xHeader]))]; // Get unique x-axis values
    const newZheader = zHeaders ? [yHeader, ...zHeaders] : [yHeader]; // Include yHeader in z-axis processing

    const zAxisResults = {};
    const countTracker = {}; // To track counts for Avg calculation

    // Step 1: Initialize Data Structure
    data.forEach((row) => {
      const xValue = row[xHeader];

      newZheader.forEach((zHeader) => {
        const zValue = row[zHeader];

        if (!zAxisResults[xValue]) {
          zAxisResults[xValue] = {};
          countTracker[xValue] = {};
        }

        if (!zAxisResults[xValue][zHeader]) {
          zAxisResults[xValue][zHeader] =
            aggFunction === "Min"
              ? Infinity
              : aggFunction === "Max"
                ? -Infinity
                : 0;
          countTracker[xValue][zHeader] = 0;
        }

        if (aggFunction === "Sum" || aggFunction === "Avg") {
          if (Number.isFinite(zValue)) {
            zAxisResults[xValue][zHeader] += zValue;
            countTracker[xValue][zHeader]++;
          }
        } else if (aggFunction === "Count") {
          zAxisResults[xValue][zHeader]++;
        } else if (aggFunction === "Min") {
          zAxisResults[xValue][zHeader] = Math.min(
            zAxisResults[xValue][zHeader],
            zValue
          );
        } else if (aggFunction === "Max") {
          zAxisResults[xValue][zHeader] = Math.max(
            zAxisResults[xValue][zHeader],
            zValue
          );
        }
      });
    });

    // Step 2: Calculate Average if aggFunction is "Avg"
    if (aggFunction === "Avg") {
      Object.keys(zAxisResults).forEach((xValue) => {
        Object.keys(zAxisResults[xValue]).forEach((zHeader) => {
          if (countTracker[xValue][zHeader] > 0) {
            zAxisResults[xValue][zHeader] = (
              zAxisResults[xValue][zHeader] / countTracker[xValue][zHeader]
            ).toFixed(2);
          }
        });
      });
    }

    // Step 3: Prepare Series Data for Chart Rendering
    const series = [];
    let colorIndex = 0;

    newZheader.forEach((zHeader, headerIndex) => {
      const uniqueValues = [...new Set(data?.map((row) => row[zHeader]))];

      uniqueValues.forEach((value, valueIndex) => {
        const color =
          colors[
          (headerIndex * uniqueValues.length + valueIndex) % colors.length
          ];

        const seriesItem = {
          name: value,
          type: "bar",
          data: Array(xValues.length).fill(0),
          itemStyle: { color },
          category: zHeader,
        };

        xValues.forEach((xValue, index) => {
          seriesItem.data[index] = zAxisResults[xValue][zHeader] || 0;
        });

        series.push(seriesItem);
      });
    });

    // Step 4: Determine Y-axis Interval
    const overallMaxValue = Math.max(...series.flatMap((s) => s.data));
    const interval =
      overallMaxValue <= 5
        ? 1
        : overallMaxValue <= 10
          ? 2
          : overallMaxValue <= 20
            ? 4
            : overallMaxValue <= 100
              ? 10
              : 20;

    return { series, xValues, interval };
  };
  function convertToCondition(data) {
    if (data.length === 0) return "";

    let conditions = data?.map((item) => `'${item.input}'`);
    let toggleOperator = data[0].toggle; // Use the first toggle type dynamically

    if (conditions.length > 1) {
      return `(${conditions.join(` ${toggleOperator} `)})`;
    } else {
      return conditions[0];
    }
  }

  const handleApplyFilter = async (isClear) => {
    if (reportId == null) {
      if (components?.length <= 0) {
        showAlert(
          "Your report does not contain any components. Please add some before proceeding.",
          "info"
        );
        return;
      } else {
        setIsnewReport(true);
        setIsSaveAsReport(true);
        showAlert(
          "Please save the report first before applying filters.",
          "warning"
        );
      }
    }
    if (reportId !== null) {
      setSelectedComponent(null);
      const from_date = dayjs(reportProperty?.filters?.dateFilter[0])?.format(
        "DD-MM-YYYY"
      );
      const to_date = dayjs(reportProperty?.filters?.dateFilter[1])?.format(
        "DD-MM-YYYY"
      );
      const searchAdd = convertToCondition(
        reportProperty?.filters?.searchQuery
      );
      const tables = components
        ?.filter(
          (item) =>
            item?.type === "table" ||
            (item?.type === "chart" && item?.properties?.tableId !== undefined)
        )
        ?.map((com) => com?.properties?.tableId);

      const result = await getFilteredTables({
        id: reportId,
        tables,
        filters: isClear
          ? {}
          : {
            dateFilter: {
              from_date,
              to_date,
              // date_range: "LAST_MONTH"
            },
            locationFilter: reportProperty?.filters?.locationFilter,
            searchQuery: searchAdd,
          },
      }).unwrap();

      const upComps = components?.map((item) => {
        let apiResult = result?.data?.find(
          (i) => i?.tableId === item?.properties?.tableId
        );
        apiResult = apiResult == undefined ? [] : apiResult
        if ((item?.type === "chart" || item?.type === "table")) {
          if (item?.type === 'chart') {
            if (item?.properties?.chartType === 'bar' || item?.properties?.chartType === 'barWithLine' || item?.properties?.chartType === 'stacked' || item?.properties?.chartType === 'line' || item?.properties?.chartType === 'area' || item?.properties?.chartType === 'scatter') {
              const result = generateChartData(
                apiResult?.data || [],
                item?.properties?.colFields[0]?.columnName,
                item?.properties?.rowFields[0]?.columnName,
                item?.properties?.legends?.map(item => item?.columnName),
                item?.properties?.chartType === 'barWithLine' ?
                  item?.properties?.line
                  : null,
                item?.aggType
              );
              let options = {
                ...item.properties.option,
                series: result?.series,
                xAxis: {
                  ...item.properties.option.xAxis,
                  data: result?.xValues,
                },
                yAxis: {
                  ...item.properties.option.yAxis,
                  interval: result?.interval,
                },
                legend: {
                  ...item.properties.option.legend,
                  data: result?.series?.map((series) => series.name),
                },
              };
              return {
                ...item,
                properties: {
                  ...item.properties,
                  option: options,
                  xAxis: item?.properties?.colFields[0]?.columnName,
                  yAxis: item?.properties?.rowFields[0]?.columnName,
                  line: item?.properties?.chartType === 'barWithLine' ? item?.properties?.line?.column_name
                    : null,
                  data: apiResult?.data || [],
                  drillData: {
                    level: 0,
                    level1: { xAxis: "", yAxis: "" },
                    level2: { xAxis: "", yAxis: "" },
                    breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                  },
                  pieDrillData: {
                    level: 0,
                    level1: { slice: "" },
                    level2: { slice: "" },
                    breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                  }
                },
              };
            } else if (item?.properties?.chartType === 'double') {
              const result = generateDoubleChartData(
                apiResult?.data || [],
                item?.properties?.colFields[0]?.columnName,
                item?.properties?.rowFields[0]?.columnName,
                item?.properties?.zAxis?.map(item => item?.column_name) || [],
                item?.aggType,
                true,
                [],
                item?.id
              );
              let options = {
                ...item.properties.option,
                series: result?.series,
                xAxis: {
                  ...item.properties.option.xAxis,
                  data: result?.xValues,
                },
                yAxis: {
                  ...item.properties.option.yAxis,
                  interval: result?.interval,

                },
                legend: {
                  ...item.properties.option.legend,
                  data: result?.series?.map((series) => series.name),
                },
              };
              return {
                ...item,
                properties: {
                  ...item.properties,
                  //  data: currentReportData?.data,
                  option: options,
                  xAxis: item?.properties?.colFields[0],
                  yAxis: item?.properties?.rowFields[0],
                  zAxis: item?.properties?.zAxis,
                  line: item?.properties?.line,
                  data: apiResult?.data || [],
                  drillData: {
                    level: 0,
                    level1: { xAxis: "", yAxis: "" },
                    level2: { xAxis: "", yAxis: "" },
                    breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                  },
                  pieDrillData: {
                    level: 0,
                    level1: { slice: "" },
                    level2: { slice: "" },
                    breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                  }
                },
              };
            } else {
              const result = generatePieChartData(
                apiResult?.data || [],
                item?.properties?.slice[0], item?.aggType)
              let options = {
                ...item.properties.option,
                series: result?.series,
                yAxis: {
                  ...item.properties.option.yAxis,
                  interval: result?.interval,
                },
                legend: { data: result?.series[0]?.data?.map((item) => item?.name) },
              };

              return {
                ...item,
                properties: {
                  ...item.properties,
                  //  data: currentReportData?.data,
                  option: options,
                  slice: item?.properties?.colFields,
                  data: apiResult?.data || [],
                  drillData: {
                    level: 0,
                    level1: { xAxis: "", yAxis: "" },
                    level2: { xAxis: "", yAxis: "" },
                    breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                  },
                  pieDrillData: {
                    level: 0,
                    level1: { slice: "" },
                    level2: { slice: "" },
                    breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                  }
                },
              };
            }
          } else {

            return {
              ...item,
              properties: {
                ...item?.properties,
                data: result?.data?.find(
                  (i) => i?.tableId === item?.properties?.tableId
                )?.data,
              },
            };
          }
        } else {
          return item;
        }
      });
      setComponents(upComps);
      isClear
        ? setReportProperty((prev) => ({
          ...prev,
          filters: {
            dateFilter: [today.startOf("month"), today],
            locationFilter: [],
            searchQuery: [],
          },
        }))
        : "";
    }
  };

  // const exportPDF = async () => {
  //     setSelectedComponent(null);
  //     const reportBuildingArea = document.getElementById('rnd-container-custom');
  //     if (!reportBuildingArea) return;
  //     const clonedElement = reportBuildingArea.cloneNode(true);

  //     clonedElement.style.position = 'absolute';
  //     clonedElement.style.left = '-9999px';
  //     document.body.appendChild(clonedElement);

  //     const yAxisLabels = clonedElement.querySelectorAll('.y-axis-label');
  //     yAxisLabels.forEach(yAxisLabel => {
  //         yAxisLabel.style.transform = 'none';
  //         yAxisLabel.style.writingMode = 'horizontal-tb';
  //         yAxisLabel.style.textAlign = 'center';
  //     });
  //     const canvas = await html2canvas(clonedElement, {
  //         scale: 2,
  //         useCORS: true,
  //         allowTaint: true,
  //     });
  //     document.body.removeChild(clonedElement);
  //     const canvasWidth = canvas.width;
  //     const canvasHeight = canvas.height;
  //     const pdfWidth = 280;
  //     const pdfHeight = pdfWidth * 297 / 210;
  //     const scale = pdfWidth / canvasWidth;

  //     const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]);

  //     let yOffset = 0;
  //     let page = 0;
  //     while (yOffset < canvasHeight) {
  //         const pdfCanvas = document.createElement("canvas");
  //         pdfCanvas.width = canvasWidth;
  //         let pageHeight = pdfHeight / scale;
  //         if (yOffset + pageHeight > canvasHeight) {
  //             pageHeight = canvasHeight - yOffset;
  //         } else {
  //             const snapshotContext = canvas.getContext("2d");
  //             const imageData = snapshotContext.getImageData(0, yOffset + pageHeight - 5, canvasWidth, 10);
  //             const pixels = imageData.data;
  //             const nonEmptyPixelCount = pixels.filter((value, index) => index % 4 === 3 && value > 0).length;
  //             if (nonEmptyPixelCount > 0) {
  //                 pageHeight -= 10;
  //             }
  //         }
  //         pdfCanvas.height = pageHeight;
  //         const pdfCtx = pdfCanvas.getContext("2d");
  //         pdfCtx.drawImage(canvas, 0, -yOffset, canvasWidth, canvasHeight);
  //         const pdfImage = pdfCanvas.toDataURL("image/png");
  //         if (page > 0) pdf.addPage();
  //         pdf.addImage(pdfImage, "PNG", 0, 0, pdfWidth, pageHeight * scale);
  //         yOffset += pageHeight;
  //         page++;
  //     }
  //     const finalPdfName = reportId ? `${pdfName}` : "Report";
  //     pdf.save(finalPdfName);
  // };

  // const exportPDF = () => {
  //     const input = document.getElementById('rnd-container-custom');

  //     const scale = 2; // Increase scale for higher resolution

  //     const options = {
  //         quality: 1, // Set the highest quality
  //         width: input.clientWidth * scale,
  //         height: input.clientHeight * scale,
  //         style: {
  //             transform: `scale(${scale})`, // Scale the content
  //             transformOrigin: 'top left', // Ensure proper scaling
  //             fontFamily: window.getComputedStyle(input).fontFamily, // Preserve font
  //         }
  //     };

  //     domtoimage.toPng(input, options)
  //         .then((dataUrl) => {
  //             const pdf = new jsPDF('p', 'mm', 'a4');

  //             const imgWidth = 210;
  //             const imgHeight = (input.clientHeight * imgWidth) / input.clientWidth;

  //             pdf.addImage(dataUrl, 'PNG', 0, 0, imgWidth, imgHeight);
  //             const finalPdfName = reportId ? `${pdfName}` : "Report";
  //             pdf.save(finalPdfName);
  //         })
  //         .catch((error) => console.error('Error generating PDF:', error));
  // };

  const exportPDF = async () => {
    if (!hasValidData) {
      showAlert("No valid data available for export!", "error");
      return;
    }
    await setSelectedComponent(null);
    setIsBorder(false)
    const input = document.getElementById("rnd-container-custom");
    const scale = 2; // Higher scale for better quality

    const options = {
      quality: 1,
      width: input.clientWidth * scale,
      height: input.clientHeight * scale,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        fontFamily: window.getComputedStyle(input).fontFamily,
      },
    };
    domtoimage
      .toPng(input, options)
      .then((dataUrl) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (input.clientHeight * imgWidth) / input.clientWidth;
        let position = 0;

        // Splitting the image into multiple pages if it's taller than a single page
        while (position < imgHeight) {
          pdf.addImage(
            dataUrl,
            "PNG",
            0,
            position ? -position : 0, // Shift the image up for subsequent pages
            imgWidth,
            imgHeight
          );

          if (position + pageHeight < imgHeight) {
            pdf.addPage();
          }
          position += pageHeight;
        }
        const finalPdfName = reportId ? `${pdfName}` : "Report";
        pdf.save(finalPdfName);
        setIsBorder(true)
      })
      .catch((error) => console.error("Error generating PDF:", error));
  };

  // const exportPDF = async () => {
  //     await setSelectedComponent(null);

  //     // Get the report building area
  //     const reportBuildingArea = document.getElementById('rnd-container-custom');
  //     if (!reportBuildingArea) return;

  //     // Clone the element to avoid modifying the original
  //     const clonedElement = reportBuildingArea.cloneNode(true);

  //     // Position the cloned element off-screen
  //     clonedElement.style.position = 'absolute';
  //     clonedElement.style.left = '-9999px';
  //     document.body.appendChild(clonedElement);
  //     const tableCells = clonedElement.querySelectorAll('td, th');
  //     const ele = clonedElement.querySelectorAll('.padding-bottom-cu');
  //     ele.forEach(cell => {
  //         cell.style.verticalAlign = 'middle';
  //         cell.style.paddingBottom = '10px';
  //     });
  //     tableCells.forEach(cell => {
  //         cell.style.verticalAlign = 'middle';
  //         cell.style.paddingBottom = '10px';
  //     });
  //     const textbox = clonedElement.querySelectorAll('.ck-content');
  //     textbox.forEach(cell => {
  //         cell.style.verticalAlign = 'middle';
  //         cell.style.paddingBottom = '10px';
  //     });
  //     // Apply necessary styles to the cloned element
  //     const yAxisLabels = clonedElement.querySelectorAll('.y-axis-label');
  //     yAxisLabels.forEach(yAxisLabel => {
  //         yAxisLabel.style.transform = 'none';
  //         yAxisLabel.style.writingMode = 'horizontal-tb';
  //         yAxisLabel.style.textAlign = 'center';
  //     });

  //     // Wait for fonts and dynamic content to load
  //     await document.fonts.ready;
  //     await new Promise(resolve => setTimeout(resolve, 1000));

  //     // Capture the cloned element as an image using html2canvas
  //     const canvas = await html2canvas(clonedElement, {
  //         scale: 4, // Increase scale for better quality
  //         useCORS: true, // Allow cross-origin images
  //         allowTaint: true, // Allow tainted canvas
  //         logging: true, // Enable logging for debugging
  //         letterRendering: true, // Improve text rendering
  //     });

  //     // Remove the cloned element from the DOM
  //     document.body.removeChild(clonedElement);

  //     // Define PDF dimensions
  //     const canvasWidth = canvas.width;
  //     const canvasHeight = canvas.height;
  //     const pdfWidth = 210; // A4 width in mm
  //     const pdfHeight = 297; // A4 height in mm

  //     const scale = pdfWidth / canvasWidth;
  //     const bottomPadding = 25;
  //     // Create a new PDF instance
  //     const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]);

  //     // Handle pagination for large tables
  //     let yOffset = 0;
  //     let page = 0;
  //     const pageContentHeight = pdfHeight - bottomPadding;
  //     while (yOffset < canvasHeight) {
  //         const pdfCanvas = document.createElement("canvas");
  //         pdfCanvas.width = canvasWidth;

  //         // Calculate the height of the current page
  //         let pageHeight = pageContentHeight / scale;
  //         if (yOffset + pageHeight > canvasHeight) {
  //             pageHeight = canvasHeight - yOffset;
  //         } else {
  //             // Check if the next 5 pixels contain any content
  //             const snapshotContext = canvas.getContext("2d");
  //             const imageData = snapshotContext.getImageData(0, yOffset + pageHeight - 5, canvasWidth, 10);
  //             const pixels = imageData.data;
  //             const nonEmptyPixelCount = pixels.filter((value, index) => index % 4 === 3 && value > 0).length;

  //             // Adjust page height if content is detected
  //             if (nonEmptyPixelCount > 0) {
  //                 pageHeight -= 10;
  //             }
  //         }

  //         // Set the height of the PDF canvas
  //         pdfCanvas.height = pageHeight;

  //         // Draw the current page onto the PDF canvas
  //         const pdfCtx = pdfCanvas.getContext("2d");
  //         pdfCtx.drawImage(canvas, 0, -yOffset, canvasWidth, canvasHeight);

  //         // Convert the PDF canvas to an image
  //         const pdfImage = pdfCanvas.toDataURL("image/png");

  //         // Add a new page if necessary
  //         if (page > 0) pdf.addPage();

  //         // Add the image to the PDF
  //         pdf.addImage(pdfImage, "PNG", 0, 0, pdfWidth, pageHeight * scale);

  //         // Update the yOffset and page counter
  //         yOffset += pageHeight;
  //         page++;
  //     }

  //     // Save the PDF
  //     const finalPdfName = reportId ? `${pdfName}` : "Report";
  //     pdf.save(finalPdfName);
  // };
  const handleAddColumnValues = async (searchString, isSearch) => {
    try {
      setIsFilterLoading(true)
      const Distvalues = await getDistValues({
        tableId: listViewData?.tableId,
        filters: {
          searchQuery: searchValue,
          dateFilter: {
            from_date: listViewData?.filters?.dateRangeMenu ? listViewData?.filters?.dateRangeMenu[0] : null,
            to_date: listViewData?.filters?.dateRangeMenu ? listViewData?.filters?.dateRangeMenu[1] : null
          },
          dateColumn: dateColumn?.column_name,
          // otherFilters:otherFiltersApply,
          columns: listViewData?.columns?.map(item => item?.columnName),
          columnFilters: {
            column_name: selectedColumn?.name,
            searchQuery: searchString
          }
        },
        skip: 0,
        limit: isSearch ? 20 : selectedColumn?.values?.length + 20,
      })?.unwrap();

      const updatedColumns = listViewData?.columnProperties?.map(column => {
        if (column?.name === selectedColumn?.name) {
          return {
            ...column,
            values: Distvalues?.data == null ? [] : Distvalues?.data[column?.name]?.values,
            totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[column?.name]?.total_count
          }
        }
        return column
      });

      setListViewData((prev) => ({
        ...prev,
        columnProperties: updatedColumns
      }));
      setSelectedColumn((prev) => {
        return {
          ...prev,
          values: Distvalues?.data == null ? [] : Distvalues?.data[prev?.name]?.values,
          totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[prev?.name]?.total_count
        }
      })
      setIsFilterLoading(false);
    } catch (err) {
      console.log(err);
      showAlert(err?.data?.message, "error")
    }
  }
  const handleListViewFilter = async (obj, isClear) => {

    setIsListLoading(true)
    if (dateRangeMenu[0] !== null && dateRangeMenu[1] !== null && dateColumn === null) {

      setIsSelectDateColumn(true);
      return;
    }
    try {
      setIsTableLoading(true)
      const tableId = components?.find(item => item?.type === 'table')?.properties?.tableId ?
        components?.find(item => item?.type === 'table')?.properties?.tableId
        : components?.find(item => item?.type === 'chart')?.properties?.tableId ? components?.find(item => item?.type === 'chart')?.properties?.tableId
          : null;
      if (obj?.tableId === null && tableId == null || tableId === undefined) {
        showAlert("No data source detected. Please select one before creating a chart or table to proceed further.", 'warning');
      }
      const from_date = isClear ? null : dayjs(dateRangeMenu[0])?.format(
        "DD-MM-YYYY"
      );
      const to_date = isClear ? null : dayjs(dateRangeMenu[1])?.format(
        "DD-MM-YYYY"
      );

      const otherFiltersApply = obj?.isClearAllFilter
        ? {} // Reset all filters if isClearAllFilter is true
        : obj?.isClearFilter
          ? (() => {
            let updatedFilters = { ...listViewData?.filters?.otherFilters };
            delete updatedFilters[selectedColumn?.name]; // Remove the selected column
            return updatedFilters;
          })()
          : listViewData?.filters?.otherFilters;

      const result = await getListVewFilterData({
        id: reportId,
        tableId: obj?.tableId ? obj?.tableId : listViewData?.tableId,
        filters: {
          searchQuery: isClear ? "" : searchValue,
          dateFilter: {
            from_date,
            to_date
            // date_range: 'YTD'
          },
          otherFilters: otherFiltersApply,
          columns: listViewData?.columns?.map(item => item?.columnName),
          dateColumn: isClear ? null : dateColumn?.column_name,
          // "loadDistinctValue": true
        },
        sortBy: obj?.order && obj?.order == 'clear' ? null : obj?.sortColumn ? obj?.sortColumn : null,
        direction: obj?.order && obj?.order === 'clear' ? 'ASC' : obj?.order,
        skip: isClear ? 0 : obj?.skip ? obj?.skip : 0,
        limit: 100
      }).unwrap();

      const Distvalues = await getDistValues({
        tableId: obj?.tableId ? obj?.tableId : listViewData?.tableId,
        filters: {
          searchQuery: isClear ? "" : searchValue,
          dateFilter: {
            from_date,
            to_date
          },
          dateColumn: isClear ? null : dateColumn?.column_name,
          // otherFilters:otherFiltersApply,
          columns: listViewData?.columns?.map(item => item?.columnName),
          columnFilters: {
            column_name: null,
            searchQuery: ""
          }
        },
        sortBy: obj?.order && obj?.order == 'clear' ? null : obj?.sortColumn ? obj?.sortColumn : null,
        direction: obj?.order && obj?.order === 'clear' ? 'ASC' : obj?.order,
        skip: 0,
        limit: 20,
      })?.unwrap();

      const updatedColumns = result?.data?.tableProperties?.columnProperties?.map(column => ({
        ...column,
        values: Distvalues?.data == null ? [] : Distvalues?.data[column?.name]?.values,
        totalCount: Distvalues?.data == null ? 0 : Distvalues?.data[column?.name]?.total_count
      }));
      setListViewData((prev) => ({
        ...prev,
        tableId: obj?.tableId ? obj?.tableId : tableId === null ? prev?.tableId : tableId,
        columns: obj?.columnId ? [{ columnId: obj?.columnId, columnName: obj?.columnName }] : listViewData?.columns,
        data: result?.data?.tableData,
        columnProperties: updatedColumns,
        filters: {
          ...prev?.filters,
          otherFilters: otherFiltersApply,
          searchQuery: isClear ? "" : searchValue,
          dateRangeMenu: isClear ? [null, null] : dateRangeMenu,
          dateRMenu: isClear ? null : dateRMenu,
          columns: listViewData?.columns?.map(item => item?.columnName),
          dateColumn: isClear ? null : dateColumn?.column_name,
        }
      }))
      setListData(result?.data?.tableData);
      setIsListLoading(false)
      setCount(result?.data?.totalCount);
      obj?.skip && setSkip(obj?.skip)
      setIsTableLoading(false)
      if (isClear) {
        setDateRMenu(null);
        setSearchValue('');
        setDateRangeMenu([null, null]);
        setDateColumn(null)
      }
    } catch (err) {

      setIsListLoading(false)
      setIsTableLoading(false)
      showAlert(err?.data?.message, "error");
    }
  }
  const handleListViewFilterForSelectAll = async (obj) => {
    setIsListLoading(true);

    if (dateRangeMenu[0] !== null && dateRangeMenu[1] !== null && dateColumn === null) {

      setIsSelectDateColumn(true);
    }

    try {
      const tableId = components?.find(item => item?.type === 'table')?.properties?.tableId ||
        components?.find(item => item?.type === 'chart')?.properties?.tableId ||
        null;

      if ((obj?.tableId === null && tableId == null) || tableId === undefined) {
        showAlert("No data source detected. Please select one before creating a chart or table to proceed further.", 'warning');
      }

      const from_date = dayjs(dateRangeMenu[0])?.format("DD-MM-YYYY");
      const to_date = dayjs(dateRangeMenu[1])?.format("DD-MM-YYYY");

      const result = await getListVewFilterData({
        id: reportId,
        tableId: obj?.tableId || listViewData?.tableId,
        filters: {
          searchQuery: searchValue,
          dateFilter: { from_date, to_date },
          columns: listViewData?.columns?.map(item => item?.columnName),
          dateColumn: null
        },
        skip: 0,
        limit: 100
      }).unwrap();


      // const Distvalues = getDistValues({ 
      //   tableId: obj?.tableId ? obj?.tableId : listViewData?.tableId
      // })?.unwrap();
      const Distvalues = (obj?.tableId || listViewData?.tableId) && await getDistValues({
        tableId: obj?.tableId ? obj?.tableId : listViewData?.tableId,
        filters: {
          searchQuery: "",
          dateFilter: {
            from_date,
            to_date
          },
          dateColumn: null,
          columns: listViewData?.columns?.map(item => item?.columnName),
          columnFilters: {
            column_name: null,
            searchQuery: ""
          },
        },
        skip: 0,
        limit: 20,
      })?.unwrap();

      const updatedColumns = result?.data?.tableProperties?.columnProperties?.map(column => ({
        ...column,
        values: Distvalues?.data[column?.name]?.values,
        totalCount: Distvalues?.data[column?.name]?.total_count
      }));


      setListViewData((prev) => ({
        ...prev,
        filters: {
          searchQuery: "",
          dateRangeMenu: [null, null],
          dateRMenu: null,
          columns: listViewData?.columns?.map(item => item?.columnName),
          dateColumn: null
        },
        tableId: obj?.tableId || tableId,
        columns: obj?.columns || listViewData?.columns,
        data: result?.data?.tableData,
        columnProperties: updatedColumns,

      }));
      console.log(listViewData, "listviewdata")
      setListData(result?.data?.tableData);
      setIsListLoading(false);
      console.log(result?.data?.totalCount, "Total Count");
      setCount(result?.data?.totalCount);
      obj?.skip && setSkip(obj?.skip);

    } catch (err) {
      setIsListLoading(false);
      showAlert(err?.data?.message, "error");
    }
  };


  const handleViewFilteredReport = async (item, isFromMenu, isClearFilter) => {

    const fromDate = isFromMenu ? dayjs(dateRangeMenu[0])?.format("DD-MM-YYYY") : dayjs(item[dateColumn?.column_name])?.format("DD-MM-YYYY");
    const toDate = isFromMenu ? dayjs(dateRangeMenu[1])?.format("DD-MM-YYYY") : dayjs(item[dateColumn?.column_name])?.format("DD-MM-YYYY");


    setIsLodingReport(true);
    const filteredItem = Object.keys(item)
      .filter(key => listViewData?.columns.some(col => col.columnName === key))
      .reduce((acc, key) => {
        acc[key] = item[key];
        return acc;
      }, {});

    try {
      const response = await getUserReportsById({
        id: reportId,
        limit: 200,
        skip: 0,
        filters: {
          // dateFilter: {
          //   from_date: isClearFilter ? filteredReport?.filters?.dateFilter?.from_date : fromDate,
          //   to_date: isClearFilter ? filteredReport?.filters?.dateFilter?.to_date : toDate,
          //   // date_range: "LAST_MONTH"
          // },
          searchQuery: isClearFilter ? filteredReport?.filters?.searchQuery : isFromMenu == true ? searchValue : inputValue,
          dateColumn: dateColumn?.column_name,
          otherFilters: isClearFilter ? filteredReport?.filters?.otherFilters : isFromMenu ? {} : filteredItem
        },
      }).unwrap();
      setPdfName(response?.data?.name || "Report");
      const result = response?.data?.components || [];
      setReportProperty({
        reportId: response?.data?.id,
        name: response?.data?.name,
        size: response?.data?.report_property?.size,
        margin: response?.data?.report_property?.margin,

      });

      // const listViewResultData = response?.data?.list_view_property?.tableId && await getListVewFilterData({
      //   tableId: response?.data?.list_view_property?.tableId,
      //   filters: {
      //     searchQuery: inputValue,
      //     dateFilter: {},
      //     columns: response?.data?.list_view_property?.columns?.map(item => item?.columnName),
      //     dateColumn: response?.data?.list_view_property?.dateColumn?.column_name
      //   },
      //   skip: 0,
      //   limit: 100
      // }).unwrap();
      // setListViewData({
      //   data: listViewResultData?.data?.tableData,
      //   columns: response?.data?.list_view_property?.columns,
      //   dateColumn: response?.data?.list_view_property?.dateColumn,
      //   tableId: response?.data?.list_view_property?.tableId,
      //   filters: response?.data?.list_view_property?.filters
      // })
      let allExpressions = result
        ?.filter((item) => item?.type === "summery")
        ?.map((summery) =>
          summery?.properties?.measures?.map((i) => ({
            expression: i?.computed_expression,
            component_id: summery?.id,
          }))
        );

      allExpressions = allExpressions?.flatMap((group) =>
        group?.map(({ expression, component_id }) => ({
          expression,
          component_id,
        }))
      );
      const calculatedExp =
        allExpressions &&
        allExpressions?.length > 0 &&
        (await calculateExpression({ expressions: allExpressions })?.unwrap());

      let updatedComponents = result?.map((item, index) => {
        if (item?.type === "summery") {
          // Create a shallow copy of the summary
          return {
            ...item,
            id: `component-${index + 1}`,
            properties: {
              ...item?.properties,
              measures: item?.properties.measures?.map((measure) => {
                // Find the matching updated result
                const updated = calculatedExp?.data?.find(
                  (res) =>
                    res.component_id === item?.id &&
                    res.expression === measure?.computed_expression
                );
                // Return a new measure object
                return updated
                  ? { ...measure, result: updated.result }
                  : { ...measure };
              }),
            },
          };
        }
        if (item?.type === "table") {
          let table = tables?.find(
            (i) =>
              i?.tableMetaData?.table_id === item?.properties?.tableId
          );
          return {
            ...item,
            id: `component-${index + 1}`,
            properties: {
              ...item?.properties,
              columns: item?.properties?.columns?.map((item2) => {
                return {
                  ...item2,
                  columnName: table?.columnMetaData?.find(
                    (i) => i?.column_id === item2?.columnId
                  )?.column_name,
                };
              }),
            },
          };
        }
        if (item?.type === 'chart') {
          if (item?.properties?.chartType === 'bar' || item?.properties?.chartType === 'barWithLine' || item?.properties?.chartType === 'stacked' || item?.properties?.chartType === 'line' || item?.properties?.chartType === 'area' || item?.properties?.chartType === 'scatter') {
            const result = generateChartData(
              item?.properties?.data || [],
              item?.properties?.colFields[0]?.columnName,
              item?.properties?.rowFields[0]?.columnName,
              item?.properties?.legends?.map(item => item?.columnName),
              item?.properties?.chartType === 'barWithLine' ?
                item?.properties?.line?.column_name
                : null,
              item?.properties?.aggType
            );
            let options = {
              ...item.properties.option,
              series: result?.series,
              xAxis: {
                ...item.properties.option.xAxis,
                data: result?.xValues,
              },
              yAxis: {
                ...item.properties.option.yAxis,
                interval: result?.interval,
              },
              legend: {
                ...item.properties.option.legend,
                data: result?.series?.map((series) => series.name),
              },
            };
            return {
              ...item,
              properties: {
                ...item.properties,
                option: options,
                xAxis: item?.properties?.colFields[0]?.columnName,
                yAxis: item?.properties?.rowFields[0]?.columnName,
                line: item?.properties?.chartType === 'barWithLine' ? item?.properties?.line?.column_name
                  : null,
                data: item?.properties?.data || [],
                drillData: {
                  level: 0,
                  level1: { xAxis: "", yAxis: "" },
                  level2: { xAxis: "", yAxis: "" },
                  breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                },
                pieDrillData: {
                  level: 0,
                  level1: { slice: "" },
                  level2: { slice: "" },
                  breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                }
              },
            };
          } else if (item?.properties?.chartType === 'double') {
            const result = generateDoubleChartData(
              item?.properties?.data || [],
              item?.properties?.colFields[0]?.columnName,
              item?.properties?.rowFields[0]?.columnName,
              item?.properties?.zAxis?.map(item => item?.column_name) || [],
              item?.properties?.aggType,
              true,
              [],
              item?.id
            );
            let options = {
              ...item.properties.option,
              series: result?.series,
              xAxis: {
                ...item.properties.option.xAxis,
                data: result?.xValues,
              },
              yAxis: {
                ...item.properties.option.yAxis,
                interval: result?.interval,

              },
              legend: {
                ...item.properties.option.legend,
                data: result?.series?.map((series) => series.name),
              },
            };
            return {
              ...item,
              properties: {
                ...item.properties,
                //  data: currentReportData?.data,
                option: options,
                xAxis: item?.properties?.colFields[0]?.columnName,
                yAxis: item?.properties?.rowFields[0]?.columnName,
                zAxis: item?.properties?.zAxis,
                line: item?.properties?.line,
                data: item?.properties?.data || [],
                drillData: {
                  level: 0,
                  level1: { xAxis: "", yAxis: "" },
                  level2: { xAxis: "", yAxis: "" },
                  breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                },
                pieDrillData: {
                  level: 0,
                  level1: { slice: "" },
                  level2: { slice: "" },
                  breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                }
              },
            };
          } else {
            const result = generatePieChartData(
              item?.properties?.data || [],
              item?.properties?.slice[0]?.columnName,
              item?.properties?.aggType)
            let options = {
              ...item.properties.option,
              series: result?.series,
              yAxis: {
                ...item.properties.option.yAxis,
                interval: result?.interval,
              },
              legend: { data: result?.series[0]?.data?.map((item) => item?.name) },
            };

            return {
              ...item,
              properties: {
                ...item.properties,
                //  data: currentReportData?.data,
                option: options,
                slice: item?.properties?.colFields,
                data: item?.properties?.data || [],
                drillData: {
                  level: 0,
                  level1: { xAxis: "", yAxis: "" },
                  level2: { xAxis: "", yAxis: "" },
                  breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                },
                pieDrillData: {
                  level: 0,
                  level1: { slice: "" },
                  level2: { slice: "" },
                  breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                }
              },
            };
          }
        }
        return { ...item, id: `component-${index + 1}` };
      });
      setComponents(updatedComponents);
      setComponentCount(updatedComponents?.length + 1);

      setDateRMenu(null);
      setSearchValue('');
      setDateRangeMenu([null, null]);
      //  }
      setFlag(true);
      setIsLodingReport(false);
      setReportId(response?.data?.id);
      setIsSelectReport(true);
      setSelectedComponent(null);
      setIsLodingReport(false);
      isClearFilter && setDateColumn(null)
      const convertToDayjs = (dateString) => dayjs(dateString, "DD/MM/YYYY");
      setFilteredReport((prev) => {
        if (!isFromMenu && !isClearFilter) {
          console.log(dateRange, ">>>> Date Range RRR");
          console.log(fromDate, toDate, ">>> From Toooo")
          //  setDateRangeMenu([convertToDayjs(fromDate), convertToDayjs(toDate)]);
          return {
            status: true,
            filters: {
              dateFilter: {
                from_date: fromDate,
                to_date: toDate,
                // date_range: "LAST_MONTH"
              },
              searchQuery: inputValue,
              dateColumn: dateColumn?.column_name,
              otherFilters: filteredItem
            }
          }
        } else if (isClearFilter) {
          // setDateRangeMenu([convertToDayjs(filteredReport?.filters?.dateFilter?.from_date), convertToDayjs(filteredReport?.filters?.dateFilter?.to_date)]);
          return { ...prev }
        } else {
          return { ...prev }
        }
      })
      if (isClearFilter) {
        handleClearMenu();
      }

    } catch (error) {
      // Handle error appropriately (e.g., logging, displaying an error message)
      showAlert("Error fetching report by ID", "error")
      console.error("Error fetching report by ID:", error);
      setIsLodingReport(false);
    }
  }
  const handleClearFilter = () => {
    setInputValue("");
    setDateRange([null, null]);
    setListData([]);
    setDateR(null)
    setSelectedColumnsFilters([]);
    setDateColumn(null);
  }
  const handleClearMenu = () => {
    setDateRMenu(null);
    setSearchValue("");
    setDateRangeMenu([null, null])
    //setDateRMenu(listViewData?.filters?.)


  }
  const handleSetAgain = () => {
    try {
      listViewData?.filters?.dateRMenu && setDateRMenu(listViewData?.filters?.dateRMenu);
      listViewData?.filters?.searchQuery && setSearchValue(listViewData?.filters?.searchQuery);
      listViewData?.filters?.dateRangeMenu && setDateRangeMenu([listViewData?.filters?.dateRangeMenu[0], listViewData?.filters?.dateRangeMenu[1]])
      //setDateRMenu(listViewData?.filters?.)
      console.log(listViewData?.filters, ">>>> Filters");
    } catch (err) {
      console.log(">>> ", err)
    }

  }

  // const handleSetAgain = () => {
  //   try {
  //     if (listViewData?.filters?.dateRMenu) {
  //       setDateRMenu(listViewData.filters.dateRMenu);
  //     }

  //     if (listViewData?.filters?.searchQuery) {
  //       setSearchValue(listViewData.filters.searchQuery);
  //     }

  //     // Check if both dates are valid
  //     if (dateRange && dateRange.length === 2 && dayjs(dateRange[0]).isValid() && dayjs(dateRange[1]).isValid()) {
  //       setDateRangeMenu(listViewData?.filters?.dateRangeMenu[0], listViewData?.filters?.dateRangeMenu[1]);
  //     } else {
  //       setDateRangeMenu([null, null]);
  //       console.log("Invalid date range format, skipping setDateRangeMenu.");
  //     }

  //     console.log(listViewData?.filters, ">>>> Filters");
  //   } catch (err) {
  //     console.log(">>> ", err);
  //   }
  // };
  const handleReportGet = (item) => {
    setIsListViewScreen(true);
    handleClearFilter();
    handleClearMenu();
    if (reportId !== item?.id) {
      getReportById(item?.id);
      // setIsLodingReport(true);
      navigate(`/analytics/report-builder/${item?.id}`);
      setIsNewReportActive(false);
      setIsSaveChanges(false);
    }
    setIsDrawerOpen(false);
    console.log(myReports, ">>> REports ,");
    const updatedReports = [
      item,
      ...myReports.filter(report => report.id !== item.id)
    ];
    setMyReports(updatedReports);

  }
  const handleListViewOpen = () => {
    console.log(">>>> Handle List View Open");
    console.log(listViewData, ">>> List View data >>>>");
    setIsListViewScreen(true);
  }
  const getTableDataForSelectAll = async ({ tableId, tableName, columns }) => {
    console.log(tableId, "tableidddd")
    const result = await getTableDataSkorBoard({
      name: tableName,
      tableId: tableId,
      skip: 0,
      limit: 200
    }).unwrap();
    const columnProToUp = result?.data?.tableProperties?.columnProperties?.map((item) => ({
      ...item,
      columnId: result?.data?.metaData?.columnMetaData?.find((i) => i?.column_name === item?.name)?.column_id,
    }));

    // const allColumns = columnProToUp.map((item) => ({
    //   columnName: item?.name,
    //   columnId: item?.columnId,
    //   count: 0,
    // }));

    const allColumns = columnProToUp
      ?.filter((item) => !item?.isDeleted && !item?.isHidden && item?.name !== `${String(tableName?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`)
      .map((item) => ({
        columnName: item?.name,
        columnId: item?.columnId,
        count: 0,
      }));

    let top = 0;
    await components.forEach((component) => {
      const childElement = document.getElementById(component.id);
      if (childElement) {
        const childBottom =
          component.properties.top + childElement.offsetHeight + 10;
        top = Math.max(top, childBottom);
      }
    });
    if (components?.length <= 0) {
      let propertiesI = await getDefaultProperties("table");
      const parentEle = dropRef?.current;
      propertiesI.left = 0;
      propertiesI.top = top;
      propertiesI.layouts.w = 3;
      propertiesI.layouts.h = 3;
      propertiesI.layouts.i = `component-${componentCount}`;
      propertiesI.layouts.x = 0;
      propertiesI.layouts.y = 0;
      propertiesI.width = parentEle ? parentEle?.offsetWidth : 1020;

      setSelectedComponent({
        id: `component-${componentCount}`,
        type: "table",
        name: tableName,
        properties: {
          ...propertiesI,
          name: tableName,
          tableId: tableId,
          data: result?.data?.data,
          // columns: allColumns, // Use all columns instead of a single one
          columns: columns,
          columnProperties: columnProToUp,
          allColumns: result?.data?.tableProperties?.columnProperties
            ?.filter((item) => !item?.isDeleted && !item?.isHidden)
            ?.map((item) => item?.name),
        },
      });

      setComponents([
        ...components,
        {
          id: `component-${componentCount}`,
          type: "table",
          properties: {
            ...propertiesI,
            name: tableName,
            tableId: tableId,
            data: result?.data?.data,
            // columns: allColumns, // Use all columns
            columns: columns,
            columnProperties: columnProToUp,
            allColumns: result?.data?.tableProperties?.columnProperties
              ?.filter((item) => !item?.isDeleted && !item?.isHidden)
              ?.map((item) => item?.name),
          },
        },
      ]);

      setComponentCount(componentCount + 1);
    } else {
      setSelectedComponent({
        ...selectedComponent,
        properties: {
          ...selectedComponent?.properties,
          tableId: tableId,
          data: result?.data?.data,
          name: tableName,
          // columns: allColumns, // Use all columns
          columns: columns,
          columnProperties: columnProToUp,
          allColumns: result?.data?.tableProperties?.columnProperties
            ?.filter((item) => !item?.isDeleted && !item?.isHidden)
            ?.map((item) => item?.name),
        },
      });

      handlePropertyChange({
        data: result?.data?.data,
        tableId: tableId,
        // columns: allColumns, // Use all columns
        columns: columns,
        name: tableName,
        columnProperties: columnProToUp,
        allColumns: result?.data?.tableProperties?.columnProperties
          ?.filter((item) => !item?.isDeleted && !item?.isHidden)
          ?.map((item) => item?.name),
      });
    }

    setFlag(false);

    return allColumns; // Return the entire columns array
  };

  const handleSelectDateColumn = (value) => {
    console.log(value, ">>> Id Of the columnnn")
    const table = tables?.filter((table) => table?.tableMetaData?.table_id === listViewData?.tableId)
    const columns = table[0]?.columnMetaData
    const column = columns?.find((item) => item?.column_id === value);
    setDateColumn({ column_name: column?.column_name, column_id: column?.column_id });
    console.log({ column_name: column?.column_name, column_id: column?.column_id }, ">>>");

  }

  React.useEffect(() => {
    const checkValidData = components.some((component) => {
      if (component.type === "table" || component.type === "chart") {
        return component.properties?.data?.length > 0;
      }
      if (component.type === "textbox") {
        return component.properties?.editorData?.trim() !== "";
      }
      if (component.type === "summary") {
        return component.properties?.measure?.length > 0;
      }
      if (component.type === "image") {
        return component.properties?.src?.trim() !== "";
      }
      return false;
    });

    setHasValidData(checkValidData);
  }, [components]);

  React.useEffect(() => {
    const handleKeyDown = async (event) => {
      if (filteredReport?.status === true) return;
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        setIsnewReport(true);

        if (reportId === null) {
          setIsSaveAsReport(true);
        } else {
          handleSaveReport({ parentId: null });
        }
        // } else {
        //     console.error("Cannot save: selectedComponent is null or undefined");
        //     showAlert("No Report found.", "error");
        // }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    selectedComponent,
    components,
    reportId,
    reportProperty,
    pdfName,
    myReports,
    islistViewScreen,
    listViewData
  ]);
  const handleReportFirst = async () => {
    const tables = await getTables();
    await getAllReports(tables);
    await getLocationsSb();
  }
  React.useEffect(() => {
    handleReportFirst();
  }, []);
  React.useEffect(() => {
    const hasTableComponent = components.some(
      (component) => component.type === "table"
    );
    setIsDisableTable(hasTableComponent);
  }, [components]);
  const { id } = useParams();
  React.useEffect(() => {
    if (id !== null && reportId !== null) {
      getReportById(id ? id : reportId);
      // setIsLodingReport(true);
      navigate(`/analytics/report-builder/${id ? id : reportId}`);
      setIsNewReportActive(false);
      setIsSaveChanges(false);
      setIsListViewScreen(false);
      setFilteredReport({ status: false, filters: [] });

    } else {
      navigate(`/analytics/report-builder`);
    }
  }, []);
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="d-flex flex-col" style={{ height: "calc(100vh - 57px)" }}>
        {/* <div className="d-flex flex-col" style={{ height: "89vh" }}>  */}
        <Snackbar
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={5000}
          message={message ? message : "Something went wrong"}
          severity="error"
        >
          <Alert
            onClose={() => setOpen(false)}
            severity={severity ? severity : "error"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message ? message : "Something went wrong"}
          </Alert>
        </Snackbar>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{
            style: {
              top: "72px",
            },
          }}
        >
          <div style={{ width: "255px" }}>
            <div className="bg-[#efefef] flex items-center justify-between border-b border-[#ddd]">
              <span className="text-sm pl-[30px] font-semibold text-[#0a3a67]">
                Reports
              </span>
              <span
                onClick={toggleDrawer(false)}
                className="m-2 pr-1 cursor-pointer"
              >
                <CloseIcon className="text-[#0a3a67]" />
              </span>
            </div>
            <>
              {myReports?.length > 0 ? (
                <ul className=" overflow-auto no-scrollbar dashboarddata-list-ul mb-0 pl-0">
                  {myReports?.map((item) => (
                    <li
                      onClick={() => {
                        setIsListViewScreen(true);
                        handleClearFilter();
                        handleClearMenu();
                        if (reportId !== item?.id) {
                          getReportById(item?.id);
                          // setIsLodingReport(true);
                          navigate(`/analytics/report-builder/${item?.id}`);
                          setIsNewReportActive(false);
                          setIsSaveChanges(false);
                        }
                        setIsDrawerOpen(false);
                      }}
                      className={`dashboarddata-list ${reportId === item?.id
                        ? "text-[#0a3a67] bg-[#d2d4d5] font-semibold"
                        : "text-[#3a3a3a]"
                        } cursor-pointer`}
                    >
                      {item?.name}
                      {/* <MoreVertIcon
                                            className="text-sm info-icon"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleMenuOpen(e, item);
                                            }}
                                        /> */}
                    </li>
                  ))}
                </ul>
              ) : (
                <span
                  className=" px-3 mb-1 text-sm font-bold text-center"
                  style={{ color: "#0a3a6761" }}
                >
                  <p className="m-0">No Reports Found.</p>
                </span>
              )}
            </>
          </div>
        </Drawer>
        {isNewReport && (
          <ReusableReportModal
            open
            onClose={() => {
              setIsnewReport(!isNewReport);
              setIsSaveAsReport(false);
              setIsSaveChanges(false);
              setIsCloseReport(false);
              setIsGetById({ status: false, reportId: null });
            }}
            // title={`${!isCloseReport
            //     ? reportId === null || isSaveAsReport
            //         ? "Create New Report"
            //         : "Update Report"
            //     : ""
            //     }`}
            title={`${isCloseReport
              ? isSaveChanges
                ? reportId !== null
                  ? `Save changes to the ${(myReports?.find((item) => item?.id === reportId)?.name || "")
                    .length > 14
                    ? myReports?.find((item) => item?.id === reportId)?.name.substring(0, 14) + "..."
                    : myReports?.find((item) => item?.id === reportId)?.name
                  }`
                  : "Save changes"
                : "Close Report"
              : !isCloseReport
                ? reportId === null || isSaveAsReport
                  ? "Create New Report"
                  : "Update Report"
                : ""
              }`}

          // title={`${isCloseReport
          //   ? isSaveChanges
          //     ? reportId !== null
          //       ? `Save changes to the ${myReports?.find((item) => item?.id === reportId)?.name}`
          //       : "Save changes"
          //     : "Close Report"
          //   : !isCloseReport
          //     ? reportId === null || isSaveAsReport
          //       ? "Create New Report"
          //       : "Update Report"
          //     : ""
          //   }`}
          >
            {isCloseReport ? (
              <div className=" my-2 text-[15px] d-flex flex-col">
                {/* <div className="px-3">
                  {`${isSaveChanges || isGetById?.status == true
                    ? reportId !== null
                      ? `Would you like to save the changes to the ${myReports?.find((item) => item?.id === reportId)
                        ?.name
                      }.We are going to close the report?`
                      : "Would you like to save your current changes?"
                    : "Closing this report will discard all changes. Proceed ?"
                    }`}
                </div> */}
                <div className="px-3">
                  {`${isSaveChanges || isGetById?.status === true
                    ? reportId !== null
                      ? `Would you like to save the changes to the ${(myReports?.find((item) => item?.id === reportId)?.name || "").length > 14
                        ? myReports?.find((item) => item?.id === reportId)?.name.substring(0, 14) + "..."
                        : myReports?.find((item) => item?.id === reportId)?.name
                      }? We are going to close the report.`
                      : "Would you like to save your current changes?"
                    : "Closing this report will discard all changes. Proceed?"
                    }`}
                </div>

                <div className="self-end mt-2">
                  <button
                    onClick={() => {
                      // if (reportId === null) {
                      //     setIsnewReport(true);
                      //     setIsSaveAsReport(true);
                      // } else {
                      setListViewData({
                        tableId: null,
                        columns: [],
                        data: [],
                        filters: {},
                        dateColumn: {}
                      })
                      if (isGetById?.status === true) {
                        setIsCloseReport(false);
                      } else {
                        if (isSaveChanges) {
                          handleSaveReport({ parentId: null });
                          setIsCloseReport(false);
                          // reportId === null ? setIsSaveAsReport(true) : "";
                          // if (isSaveChanges) {
                          setIsNewReportClicked(true);
                          setComponents([]);
                          setReportId(null);
                          setSelectedComponent(null);
                          // }
                        } else {
                          handleCloseReport();
                        }
                        // }
                      }
                    }}
                    className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => {
                      setIsnewReport(false);
                      setIsCloseReport(false);
                      if (isGetById?.status == true) {
                        getReportById(isGetById?.reportId);
                        setIsLodingReport(true);
                      }
                      if (isSaveChanges) {
                        setReportId(null);
                        setIsNewReportClicked(true);
                        setIsSaveChanges(false);
                        setComponents([]);
                        setSelectedComponent(null);
                        setIsNewReportActive(true);
                        setListViewData({
                          tableId: null,
                          columns: [],
                          data: [],
                          filters: {},
                          dateColumn: {}
                        })
                      }
                    }}
                    className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                  >
                    No
                  </button>
                </div>
              </div>
            ) : isSaveAsReport ? (
              <>
                <div style={{ width: "30rem", height: "14rem" }}>
                  <div className="flex flex-row items-center  mt-2">
                    <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                      <p className="m-0 font-bold text-sm whitespace-nowrap">
                        Name
                      </p>
                      <input
                        type="text"
                        className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                        value={reportState?.name}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setReportState((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row items-center mt-2">
                    <div className="flex flex-col items-start justify-start w-full gap-1 py-1 pb-2 px-4">
                      <p className="m-0 text-sm font-bold whitespace-nowrap">
                        Description
                      </p>
                      <input
                        autoComplete="false"
                        type="text"
                        className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                        value={reportState?.description}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setReportState((prevState) => ({
                            ...prevState,
                            description: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="pb-2 px-4 mt-3">
                    <button
                      disabled={!reportState?.name || !reportState?.description}
                      onClick={async () => {
                        await handleSaveReport({ parentId: reportId });
                        setPdfName(reportState?.name)
                        if (isGetById?.status === true) {
                          getReportById(isGetById?.reportId);
                          setIsLodingReport(true);
                        }
                        if (isSaveChanges) {
                          setReportId(null);
                          setIsNewReportClicked(true);
                          setComponents([]);
                          setSelectedComponent(null);
                        }
                      }}
                      className={`px-3 text-sm btn-bgcolor py-2 font-bold text-white rounded-md  disabled:cursor-not-allowed disabled:opacity-40`}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="mx-2 my-2 d-flex flex-col">
                <span className="text-[15px] mt-1 ">{`Would you like to update the existing report or create a new one ?`}</span>
                <span className="self-end mt-2">
                  <button
                    onClick={() => {
                      //setReportId(null);
                      setIsSaveAsReport(true);
                    }}
                    className="px-3 text-sm btn-bgcolor py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                  >
                    Create
                  </button>
                  <button
                    onClick={() => {
                      handleSaveReport({ parentId: null });
                      if (isGetById?.status == true) {
                        getReportById(isGetById?.reportId);
                        setIsLodingReport(true);
                      }
                      if (isSaveChanges) {
                        setIsNewReportClicked(true);
                        setComponents([]);
                        setReportId(null);
                        setSelectedComponent(null);
                      }
                    }}
                    className="px-3 text-sm btn-bgcolor  py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                  >
                    Save
                  </button>
                </span>
              </div>
            )}
          </ReusableReportModal>
        )}
        {isSelectDateColumn && (
          <ReusableReportModal
            open
            onClose={() => {
              setIsSelectDateColumn(false)
            }}

            title={`Select Date Column`}
          >
            <div className=" my-2 text-[15px] d-flex flex-col w-[500px]">

              <span className="px-3">
                Please select date column:
                <span className="grow">
                  <select
                    className={` opacity-100 cursor-pointer' text-sm block px-2 py-1 w-50  bg-white input-border-custome rounded-[5px] `}
                    onChange={(e) => {
                      handleSelectDateColumn(e?.target?.value)
                    }}
                    placeholder='select Date Column'
                  >

                    {dateColumn === null ? <option disabled selected>Select Value</option> : ""}
                    {
                      tables?.filter((table) => table?.tableMetaData?.table_id === listViewData?.tableId)
                        ?.map(item => item?.columnMetaData)[0]
                        ?.filter((column) => column?.column_type === "timestamp with time zone" || column?.column?.column_type === 'date')
                        ?.map((option) => (
                          <option key={option.column_id} value={option.column_id}>
                            {option.column_name}
                          </option>
                        ))}
                  </select>
                </span>
              </span>
              <div className="self-end mt-2">
                <button
                  onClick={() => {
                    setIsSelectDateColumn(false);
                    handleListViewFilter();
                  }}
                  className="px-4 py-1 text-base btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                  disable={dateColumn === null}
                >
                  Ok
                </button>
              </div>
            </div>

          </ReusableReportModal>
        )}
        <Nav
          isTableTool={null}
          activeNavItem={activeNavItem}
          setActiveNavItem={setActiveNavItem}
          selectedColumn={null}
          // setSelectedColumn={setSelectedColumn}
          isReportBuilder={true}
          reportId={reportId}
        />
        <Menu
          activeNavItem={activeNavItem}
          selectedComponent={selectedComponent}
          setIsExpand={setIsExpand}
          isExpand={isExpand}
          onPropertyChange={handlePropertyChange}
          setSelectedComponent={setSelectedComponent}
          showAlert={showAlert}
          isReportBuilder={true}
          setIsnewReport={setIsnewReport}
          reportId={reportId}
          setIsSaveAsReport={setIsSaveAsReport}
          toggleDrawer={toggleDrawer}
          handleNewReport={handleNewReport}
          handleSaveHit={handleSaveHit}
          setReportProperty={setReportProperty}
          reportProperty={reportProperty}
          pageSizes={pageSizes}
          marginOptions={marginOptions}
          exportPDF={exportPDF}
          hasValidData={hasValidData}
          islistViewScreen={islistViewScreen}
          filteredReport={filteredReport}
          components={components}
          reports={myReports}
          handleReportGet={handleReportGet}
          dateOptions={dateOptions}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          dateRangeMenu={dateRangeMenu}
          setDateRangeMenu={setDateRangeMenu}
          dateRMenu={dateRMenu}
          setDateRMenu={setDateRMenu}
          handleViewFilteredReport={handleViewFilteredReport}
          dateColumn={dateColumn}
          handleListViewFilter={handleListViewFilter}
          count={count}
          fetchTableData={handleListViewFilter}
          listViewData={listViewData}
          tables={tables}
        />
        <div
          className="d-flex grow overflow-auto"
          style={{ margin: "1px", flexGrow: "1" }}
        >
          <DataPanel
            components={components}
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            onPropertyChange={handlePropertyChange}
            isExpand={isExpand}
            showAlert={showAlert}
            tables={tables}
            getTableData={getTableData}
            setFlag={setFlag}
            flag={flag}
            loadingTables={loadingTables}
            islistViewScreen={islistViewScreen}
            filteredReport={filteredReport}
            reportId={reportId}
            listViewData={listViewData}
            setListViewData={setListViewData}
            handleListViewOpen={handleListViewOpen}
            setIsListViewScreen={setIsListViewScreen}
            getReportById={getReportById}
            handleListViewFilter={handleListViewFilter}
            getTableDataForSelectAll={getTableDataForSelectAll}
            dateColumn={dateColumn}
            setDateColumn={setDateColumn}
            handleListViewFilterForSelectAll={handleListViewFilterForSelectAll}
            isLoadingReport={isLoadingReport}
          />
          <WidgetPanel
            componentCount={componentCount}
            getDefaultProperties={getDefaultProperties}
            showAlert={showAlert}
            onPropertyChange={handlePropertyChange}
            setSelectedComponent={setSelectedComponent}
            flag={flag}
            setFlag={setFlag}
            reportAreaRef={dropRef}
            reportId={reportId}
            component={selectedComponent}
            handleComponentDrop={handleComponentDrop}
            generateChartData={generateChartData}
            generatePieChartData={generatePieChartData}
            generateDoubleChartData={generateDoubleChartData}
            echartsRef={echartsRef}
            isAnimated={isAnimated}
            setIsAnimated={setIsAnimated}
            drillData={
              components?.find((item) => item?.id === selectedComponent?.id)
                ?.properties?.drillData
            }
            pieDrillData={
              components?.find((item) => item?.id === selectedComponent?.id)
                ?.properties?.pieDrillData
            }
            tables={tables}
            isDisableTable={isDisableTable}
            islistViewScreen={islistViewScreen}
            setReportProperty={setReportProperty}
          />
          {/* <FilterPanel
            handleApplyFilter={handleApplyFilter}
            reportProperty={reportProperty}
            setReportProperty={setReportProperty}
            locations={locations}
            isEmplty={components?.length <= 0}
          /> */}
          <div
            className="custom-scrollbar"
            style={{
              height: "100%",
              overflow: "auto",
              outline: "none",
              flexGrow: "1",
              width: '100%'
            }}
          >
            <ReportBuildingArea
              onDrop={handleComponentDrop}
              onDelete={handleDeleteComponent}
              components={components}
              setComponents={setComponents}
              selectedComponent={
                selectedComponent !== null ? selectedComponent : null
              }
              setSelectedComponent={setSelectedComponent}
              onPropertyChange={handlePropertyChange}
              disableRndDragging={disableRndDragging}
              setFlag={setFlag}
              dropRef={dropRef}
              isSelectReport={isSelectReport}
              setIsSelectReport={setIsSelectReport}
              reportId={reportId}
              isLoadingReport={isLoadingReport}
              isLoadingTemplate={isLoadingTemplate}
              handleAddMeasure={handleAddMeasure}
              isAddingMeasure={isAddingMeasure}
              showDropdown={showDropdown}
              reportProperty={reportProperty}
              multiSelectedComponents={multiSelectedComponents}
              setMultiSelectedComponents={setMultiSelectedComponents}
              showAlert={showAlert}
              customRef={customRef}
              islistViewScreen={islistViewScreen}
              setIsListViewScreen={setIsListViewScreen}
              handleListViewFilter={handleListViewFilter}
              listData={listData}
              setListData={setListData}
              handleViewFilteredReport={handleViewFilteredReport}
              handleClearFilter={handleClearFilter}
              dateOptions={dateOptions}
              inputValue={inputValue}
              setInputValue={setInputValue}
              dateRange={dateRange}
              setDateRange={setDateRange}
              dateR={dateR}
              setDateR={setDateR}
              filteredReport={filteredReport}
              setFilteredReport={setFilteredReport}
              myReports={myReports}
              getReportById={getReportById}
              tables={tables}
              selectedColumnsFilters={selectedColumnsFilters}
              setSelectedColumnsFilters={setSelectedColumnsFilters}
              dateColumn={dateColumn}
              setDateColumn={setDateColumn}
              handleClearMenu={handleClearMenu}
              isListLoading={isListLoading}
              listViewData={listViewData}
              setListViewData={setListViewData}
              isTableDataLoading={isTableDataLoading}
              setIsTableLoading={setIsTableLoading}
              handleSetAgain={handleSetAgain}
              isBorder={isBorder}
              handleSelectColumn={handleSelectColumn}
              selectedColumn={selectedColumn}
              handleAddColumnValues={handleAddColumnValues}
              dateformat={dateformat}
              setIsFilterLoading={setIsFilterLoading}
              isFilterLoading={isFilterLoading}
              handleComponentSelect={handleComponentSelect}
            >
              {components?.map((component, index) => {
                return (
                  <div
                    key={component.id}
                    className={`report-component ${index === selectedComponent ? "selected" : ""
                      } no-scrollbar`}
                    onClick={(e) =>
                      handleComponentSelect(e, component, component.id)
                    } Rsa

                    style={{
                      height: "100%",
                      width: "100%",
                      overflow:
                        component?.type === "textbox" ||
                          component?.type === "summery"
                          ? "unset"
                          : "auto",
                    }}
                  >
                    {component.type === "table" && (
                      <>
                        <TableComponent
                          type={component.properties.type}
                          columns={component.properties.columns}
                          data={component.properties.data}
                          //data={currentReportData.data}
                          key={component.id}
                          properties={component.properties}
                          id={component.id}
                          stopTableDrag={() => {
                            setDisableRndDragging(true);
                          }}
                          onPropertyChange={handlePropertyChange}
                          setSelectedComponent={setSelectedComponent}
                          component={
                            selectedComponent !== null
                              ? selectedComponent
                              : null
                          }
                          showAlert={showAlert}
                          selectedComId={selectedComponent?.id}
                          isListViewReport={filteredReport?.status}
                        />
                      </>
                    )}
                    {component.type === "textbox" && (
                      <TextboxComponent
                        type={component.properties.type}
                        text={component.properties.text}
                        key={component.id}
                        properties={component.properties}
                        id={component.id}
                        onPropertyChange={handlePropertyChange}
                        setSelectedComponent={setSelectedComponent}
                        stopTableDrag={() => {
                          setDisableRndDragging(true);
                        }}
                        selectedComId={selectedComponent?.id}
                        component={selectedComponent}
                      />
                    )}
                    {component.type === "image" && (
                      <ImageComponent
                        type={component.properties.type}
                        src={component.properties.src}
                        key={component.id}
                        properties={component.properties}
                        onPropertyChange={handlePropertyChange}
                        setSelectedComponent={setSelectedComponent}
                        id={component.id}
                        selectedComId={selectedComponent?.id}
                      />
                    )}
                    {component.type === "summery" && (
                      <SummeryComponent
                        type={component.properties.type}
                        text={component.properties.text}
                        key={component.id}
                        properties={component.properties}
                        id={component.id}
                        selectedComId={selectedComponent?.id}
                        onPropertyChange={handlePropertyChange}
                        setSelectedComponent={setSelectedComponent}
                        tables={tables}
                        showAlert={showAlert}
                        stopTableDrag={() => {
                          setDisableRndDragging(true);
                        }}
                        calculateExpressions={calculateExpressions}
                        targetDivRef={targetDivRef}
                        component={selectedComponent}
                        isAddingMeasure={isAddingMeasure}
                        setIsAddingMeasure={setIsAddingMeasure}
                        newMeasure={newMeasure}
                        setNewMeasure={setNewMeasure}
                        handleAddMeasure={handleAddMeasure}
                        showDropdown={showDropdown}
                        setShowDropdown={setShowDropdown}
                        // usedDataSources={reportId !== null
                        //   ? components
                        //     ?.filter(component => component?.type === "table" || component?.type === "chart")
                        //     ?.map(component => component?.properties?.tableId)?.filter(id => id !== undefined)
                        //   : []}
                        usedDataSources={components?.length > 0
                          ? components
                            ?.filter(component => component?.type === "table" || component?.type === "chart")
                            ?.map(component => component?.properties?.tableId)
                            ?.filter(id => id !== undefined)
                          : []}
                      />
                    )}
                    {component.type === "chart" && (
                      <ChartComponent
                        type={component?.properties?.type}
                        option={component?.properties?.option}
                        data={component?.properties?.data}
                        key={index}
                        properties={component?.properties}
                        echartsRef={echartsRef}
                        showAlert={showAlert}
                        // isChartView={isChartView}
                        // isTableView={isTableView}
                        isAnimated={isAnimated}
                        setIsAnimated={setIsAnimated}
                        stopTableDrag={() => {
                          setDisableRndDragging(true);
                        }}
                        generateChartData={generateChartData}
                        id={component.id}
                        setFlag={setFlag}
                        setSelectedComponent={setSelectedComponent}
                        onPropertyChange={handlePropertyChange}
                        generatePieChartData={generatePieChartData}
                        generateDoubleChartData={generateDoubleChartData}
                        drillData={component?.properties?.drillData}
                        selectedId={selectedComponent?.id}
                        pieDrillData={component?.properties?.pieDrillData}
                        isListViewReport={filteredReport?.status}
                      />
                    )}
                  </div>
                );
              })}
            </ReportBuildingArea>
          </div>
        </div>
      </div>
    </DndProvider >
  );
}

export default ReportDesigner;
