import React from "react";
// import { Trash2, Download } from "lucide-react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReusableDataModal from './ReusableDataModal'
function DbConnections({ handleRemoveDatabse, setActiveNavItem, superset }) {
    const [isDeleteDatabaseModal, setIsdeleteDatabaseOpen] = React.useState(false);
    const [selectedDb, setSelectedDb] = React.useState(null);
    return (
        <>
            {isDeleteDatabaseModal &&
                <ReusableDataModal
                    open
                    onClose={() => {
                        setIsdeleteDatabaseOpen(!isDeleteDatabaseModal);
                    }}
                    title={"Remove Connection"}
                >
                    <div className="mx-2 my-2 d-flex flex-col">
                        <div className="text-[15px] mt-1">{`Are you sure you want to remove the connection ?`}</div>
                        <div className="self-end mt-2">
                            <button
                                onClick={() => {
                                    //handleUpdateTamplate({ isUpdateData: false });
                                    handleRemoveDatabse(selectedDb?.id)
                                    setIsdeleteDatabaseOpen(false);
                                }}
                                className="px-4 py-1 font-bold text-white rounded-md bg-[#075985] text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => {
                                    setIsdeleteDatabaseOpen(false)
                                }}
                                className="px-4 py-1 font-bold text-white rounded-md bg-[#075985]  text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </ReusableDataModal>
            }

            <div className="h-full bg-white">
                <div className=" mx-auto px-4 pb-4 pt-3 h-full flex flex-col">
                    {/* <h2 className="text-[16px] font-semibold  text-[#075985]">Database Connections</h2> */}
                    <div className="flex justify-between items-center">
                        <h2 className="text-[16px] font-semibold text-[#075985]">
                            Database Connections
                        </h2>
                        <Tooltip title={'Close'}>
                            <CloseIcon
                                className="cursor-pointer text-[#075985]"
                                onClick={() => {
                                    setActiveNavItem("Home")
                                    superset?.setIsDatabaseView(false)
                                }}
                            />
                        </Tooltip>

                    </div>
                    <div className="overflow-x-auto overflow-y-auto flex-grow custom-scrollbar mt-3">
                        <table className="min-w-full bg-white border border-[#d1d5db]">
                            <thead className="border border-[#d1d5db] text-[14px] bg-[#edf0f5] sticky top-[-1px] z-10">
                                <tr>
                                    <th className="px-4 py-1 text-left text-gray-700 border-r border-[#d1d5db]">Name</th>
                                    <th className="px-4 py-1 text-left text-gray-700 border-r border-[#d1d5db]">Database Type</th>
                                    <th className="px-4 py-1 text-left text-gray-700 border-r border-[#d1d5db]">Last Modified</th>
                                    <th className="px-4 py-1 text-center text-gray-700 ">Actions</th>
                                </tr>
                            </thead>
                            {(superset?.dbConnections?.length ?? 0) <= 0 ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={4} className="py-3 text-center">
                                            <p className="text-[13px] text-gray-500 font-semibold">
                                                No Database connection found
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {console.log(superset?.dbConnections, "dbconnectionnnn")}
                                    {superset?.dbConnections?.map((db) => (
                                        <tr key={db.id} className="border-b text-sm">
                                            <td className="px-4 py-1 border-r border-[#d1d5db]">{db.database_name}</td>
                                            <td className="px-4 py-1 border-r border-[#d1d5db]">{db.backend}</td>
                                            <td className="px-4 py-1 border-r border-[#d1d5db]">{db.changed_on_delta_humanized}</td>
                                            <td className="px-4 py-1 text-center">
                                                <button
                                                    className="text-[#075985] mx-2"
                                                    onClick={() => {
                                                        setIsdeleteDatabaseOpen(true);
                                                        setSelectedDb(db);
                                                    }}
                                                >
                                                    <Tooltip title={'Delete'}>
                                                        <DeleteOutlineIcon size={18} />
                                                    </Tooltip>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}

                            {/* <tbody>
                                {console.log(superset?.dbConnections,"dbconnectionnnn")}
                                {superset?.dbConnections?.map((db, index) => (
                                    <tr key={db.id} className="border-b text-sm">
                                        <td className="px-4 py-1 border-r border-[#d1d5db]">{db.database_name}</td>
                                        <td className="px-4 py-1 border-r border-[#d1d5db]">{db.backend}</td>
                                        <td className="px-4 py-1 border-r border-[#d1d5db]">{db.changed_on_delta_humanized}</td>
                                        <td className="px-4 py-1 text-center">
                                            <button
                                                className="text-[#075985] mx-2"
                                                onClick={() => {
                                                    setIsdeleteDatabaseOpen(true);
                                                    setSelectedDb(db)
                                                }}
                                            >
                                                <Tooltip title={'Delete'}>
                                                    <DeleteOutlineIcon size={18} />
                                                </Tooltip>

                                            </button>
                                          
                                        </td>
                                    </tr>
                                ))}
                            </tbody> */}
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DbConnections;
