import React, { useEffect, useState } from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import { useGetProvidersMutation } from "../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import ReusableModal from "../../../components/Modals/ReusableModal";

const ProviderUserCreateModal = ({
  handleProviderUserModal,
  saveProviderUser,
}) => {
  const [errMsg, setErrMsg] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [providerId, setProviderId] = useState("");
  const [providers, setProviders] = useState(null);
  const user = useSelector(selectUser);

  const [getProviders] = useGetProvidersMutation();

  const isButtonDisabled = () => {
    return !email || !phone || !providerId;
  };

  const handleSave = async () => {
    try {
      await saveProviderUser(email, phone, providerId);
      handleProviderUserModal();
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();

      setProviders(data);
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    setErrMsg(null);
    fetchProviders();
  }, []);

  return (
    <ReusableModal
      open
      title={"Add Provider User"}
      onClose={handleProviderUserModal}
      boxClasses="xs:w-[300px] md:w-[400px] overflow-y-auto"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4 pt-4">
          <p className="m-0 font-bold">Name</p>
          <select
            value={providerId}
            onChange={(e) => setProviderId(e.target.value)}
            className="block  p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Select Provider</option>
            {providers &&
              providers.map((provider) => (
                <option key={provider.id} value={provider.id}>
                  {provider.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      {/* <div className="w-full border border-primarygrayborder" /> */}
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">Email</p>
            <input
              type="text"
              className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">
              Contact (SMS) Number
            </p>
            <input
              type="text"
              className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">
              Contact (Whatsapp) Number
            </p>
            <input
              type="text"
              className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="w-full border border-primarygrayborder" />
      {errMsg && (
        <div className="flex flex-col items-start justify-start w-full gap-4 p-2 px-4">
          <p className="m-0 text-sm text-danger">{errMsg}</p>
        </div>
      )}
      <div className="flex flex-col items-start justify-between gap-2 p-4">
        <div className="flex flex-row items-center justify-end w-full gap-4">
          <button
            onClick={handleProviderUserModal}
            className="px-4 py-2 font-bold text-white bg-gray-400 rounded-md"
          >
            Cancel
          </button>
          <button
            disabled={isButtonDisabled()}
            onClick={handleSave}
            className="px-4 py-2 font-bold text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
          >
            Save
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default ProviderUserCreateModal;
