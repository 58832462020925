import React from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";
import { AddSharp, CancelSharp } from "@mui/icons-material";
import classNames from "classnames";
import Loading from "../../../../components/Loading";

const SubscriptionActionModal = ({
  open,
  handleOpen,
  handleAction,
  action,
  isLoading,
  selectedSubscription,
}) => {
  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col gap-3 p-3">
        {isLoading && <Loading loading={isLoading} />}
        <div className="flex flex-row gap-2">
          <div
            className={classNames(
              "flex items-center justify-center w-10 h-10 rounded-full",
              action === "cancel" ? "bg-subtlered" : "bg-subtlegreen"
            )}
          >
            {action === "cancel" ? (
              <CancelSharp
                style={{
                  color: "#EB5757",
                }}
              />
            ) : (
              <AddSharp style={{ color: "#27AE60" }} />
            )}
          </div>
          <div className="flex flex-col">
            <p className="m-0 font-semibold">
              {action === "cancel"
                ? "Cancel Subscription"
                : "Select Subscription"}
            </p>
            <p className="m-0 font-light">
              {action === "cancel" ? (
                "Are you sure you want to cancel this subscription?"
              ) : (
                <>
                  Great, you've selected a subscription! <br /> You'll be
                  charged ${selectedSubscription?.price} per month.
                </>
              )}
            </p>
          </div>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-row items-center justify-end gap-3">
          <button
            onClick={handleOpen}
            className="px-4 py-2 font-medium border rounded-md bg-primarylightgrey border-primarygray"
          >
            {action === "cancel" ? "Don't Cancel" : "Cancel"}
          </button>
          <button
            disabled={isLoading}
            className="px-4 py-2 font-medium text-white border rounded-md bg-primaryblue border-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
            onClick={async () => {
              await handleAction();
              handleOpen();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default SubscriptionActionModal;
