import * as XLSX from "xlsx";
import { Alert, Checkbox, FormControlLabel, Hidden, Snackbar, Tooltip } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
    useCreateDashboardMutation,
    useCreateTemplatesMutation,
    useGetAllDashboardsMutation,
    useGetAllTablesMutation,
    useGetDashboardByIdMutation,
    useGetReportByIdMutation,
    useGetTableDataByNameMutation,
    useGetTemplateByIdMutation,
    useGetTemplatesByColumnsMutation,
    useGetTemplatesMutation,
    useUpdateDashboardMutation,
    useUpdateTemplatesMutation,
    useFilterTableDataMutation,
} from "../../../features/report/reportApiSlice";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from "@mui/icons-material/Add";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined'
import BarChartIcon from "@mui/icons-material/BarChart";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChartComponent from "./components/draggableItems/ChartComponent";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from "@mui/icons-material/Close";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DatasetLinkedOutlinedIcon from '@mui/icons-material/DatasetLinkedOutlined';
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import DehazeIcon from '@mui/icons-material/Dehaze';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DndProvider } from "react-dnd";
import DraggableComponent from "./components/DraggableComponent";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { HTML5Backend } from "react-dnd-html5-backend";
import ImageComponent from "./components/draggableItems/ImageComponent";
import ImageIcon from "@mui/icons-material/Image";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotChart from "../../../assets/svgs/NotChartIcon";
import NotTable from "../../../assets/svgs/NotTableIcon";
import Papa from "papaparse";
import PropertiesPanel from "./components/PropertiesPanel";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RemoveIcon from "@mui/icons-material/Remove";
import ReportBuildingArea from "./components/ReportBuildingArea";
import ReusableModal from "../../../components/Modals/ReusableModal";
import ReusableReportModal from "./components/ReusableReportModal";
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from "@mui/icons-material/Settings";
import ShapeComponent from "./components/draggableItems/ShapeComponent";
import StorageIcon from '@mui/icons-material/Storage';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TableChartIcon from "@mui/icons-material/TableChart";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import TableComponent from "./components/draggableItems/TableComponent";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import Tabs from "../../../../src/components/Tabs";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TextboxComponent from "./components/draggableItems/TextboxComponent";
import TuneIcon from '@mui/icons-material/Tune';
import UploadCSVModal from "../Pivot/components/uploadCSVModal";
import classNames from "classnames";
import dayjs from 'dayjs'
import moment from "moment";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Box, Menu, MenuItem } from "@mui/material";
import "jspdf-autotable";

const ReportBuilder = () => {
    const [componentCount, setComponentCount] = useState(1);
    const [isUploadCsv, setIsUploadCsv] = useState(false)
    const [tmpName, setTmpName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [selectedTmp, setselectedTmp] = React.useState(null);
    const [templates, setTemplates] = React.useState([]);

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("Something went wrong");
    const [severity, setSeverity] = React.useState("error");
    const [csvData, setCsvData] = React.useState([]);
    const [flag, setFlag] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const [isActiveDataSource, setIsActiveDataSource] = React.useState(false);
    const [isAvailableData, setIsAvailableData] = React.useState(false);
    const dropRef = React.useRef(null);
    const [isDisableTable, setIsDisableTable] = React.useState(false);
    const [isSaveClicked, setIsSavedClicked] = React.useState(false);
    const [components, setComponents] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(components[0]);
    const [disableRndDragging, setDisableRndDragging] = React.useState(false);
    const [isMyTemplatesOpen, setIsMyTemplatesOpen] = React.useState(false);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [isSaveAsClicked, setIsSaveAsClicked] = React.useState(false);
    const [infoview, setInfoView] = React.useState("T_INFO");
    const echartsRef = React.useRef(null);

    //upload file
    const [availableTemplates, setAvailableTemplates] = React.useState([]);
    const [fileData, setFileData] = React.useState([]);
    const [isCreateNewTemplate, setIsCreateNewTemplate] = React.useState(false);

    //My Reports
    const [isMyreportOpen, setIsMyreportOpen] = React.useState([]);
    const [myDashboards, setMyDashboards] = React.useState([]);
    const [dashboardId, setDashboardId] = React.useState(null);
    const [dashboardState, setDashboardState] = useState({
        name: "",
        description: "",
    });
    const [isNewDashboardActive, setIsNewDashboardActive] = React.useState(false);
    //const [isNewDashboardClicked, setIsNewReportClicked] = React.useState(false);
    const [isCloseReport, setIsCloseReport] = React.useState(false);
    const [isNewDashboard, setIsnewDashboard] = React.useState(false);
    const [isSaveAsDashboard, setIsSaveAsDashboard] = React.useState(false);
    const [isNewDashboardClicked, setIsNewDashboardClicked] = React.useState(false);
    const [selectTmpToUplod, setSelectTmpToUpload] = React.useState(null);
    const [isSaveChanges, setIsSaveChanges] = React.useState(false);
    const [reportDefaultState, setReportDefaultState] = React.useState([]);
    const [isSelectReport, setIsSelectReport] = React.useState(false);
    const [isLoadingReport, setIsLodingReport] = React.useState(false);
    const [isLoadingTemplate, setIsLoadingTemplate] = React.useState(false);
    const [currentReportData, setCurrentReportData] = React.useState([]);
    const [isGetById, setIsGetById] = React.useState({ status: false, dashboardId: null });
    const [isUploadOpen, setisUploadOpen] = React.useState(false);
    const [isInfoModelOpen, setIsInfoModelOpen] = React.useState(false);
    const [isFilterModelOpen, setIsFilterModelOpen] = React.useState(false);
    const [templateInfoState, setTemplateInfoState] = React.useState({
        name: "",
        description: "",
        id: "",
        parentId: "",
        columns: [],
        isReport: false,
    });
    const [columnListOpen, setColumnListOpen] = useState({ status: false, isFromExisting: false });
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [getTemplates] = useGetTemplatesMutation();
    const [getAllTables] = useGetAllTablesMutation();
    const [getTableData] = useGetTableDataByNameMutation();
    const [creteTemplates] = useCreateTemplatesMutation();
    const [updateTemplates] = useUpdateTemplatesMutation();
    const [getTemplatesByColumns] = useGetTemplatesByColumnsMutation();
    const [getTemplateById] = useGetTemplateByIdMutation();
    const [getAllDashboards] = useGetAllDashboardsMutation();
    const [getUserReportsById] = useGetReportByIdMutation();
    const [createDashboard] = useCreateDashboardMutation();
    const [updateDashboard] = useUpdateDashboardMutation();
    const [getDashboardById] = useGetDashboardByIdMutation();
    const [filterData] = useFilterTableDataMutation();

    const [openSection, setOpenSection] = useState(null);
    const [isMenuExpand, setIsMenuExpand] = useState(false);
    const [isChartView, setIsChartView] = useState(true);
    const [isTableView, setIsTableView] = useState(true);
    const [isAnimated, setIsAnimated] = useState(true);
    const [viewableTemplate, setViewableTemplate] = React.useState(null);
    const [isView, setIsview] = React.useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [rangeValue, setRangeValue] = useState(0); // State for range input
    const backgroundColor = `linear-gradient(to right, #0a3a67 ${rangeValue}%, #ddd ${rangeValue}%)`;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [exportAnchorEl, setexportAnchorEl] = useState(null);
    const [calendarOpenEnd, setCalendarOpenEnd] = useState(false);
    const [isSelectTableTypeModal, setIsSelectTableTypeModal] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState('Data Table');
    const [selectedDataSources, setSelectedDataSources] = React.useState([]);
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
    const [isPivotOpen, setIsPivotOpen] = React.useState(false);
    const [selectedDSToFilter, setSelectedDSToFilter] = React.useState(null);
    const [columnToFilter, setColumnToFilter] = React.useState({ name: "", type: "" });
    const [filterObj, setFilterObj] = React.useState(null);
    const [filteredItems, setFilteredItems] = React.useState(columnToFilter?.colData || []);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [anchorElOption, setAnchorElOption] = React.useState(null);
    const [pdfName, setPdfName] = React.useState("NewDashboard.pdf");
    const [dataSourcesName, setDataSourcesName] = React.useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleRangeChange = (event) => {
        const value = event.target.value;
        setRangeValue(value);
        setFilterObj({ ...filterObj, max: Number(value) })
    };

    const handleSectionClick = (sectionName) => {
        setOpenSection(openSection === sectionName ? null : sectionName);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [selectedItem, setSelectedItem] = React.useState(null);
    // Handle menu open on MoreVertIcon click
    const handleMenuOpen = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };
    const [calendarOpenStart, setCalendarOpenStart] = useState(false);
    const calendarRefStart = React.useRef(null);
    const calendarRefEnd = React.useRef(null);
    const openExportMenu = Boolean(exportAnchorEl);

    const handleIconClick = (obj) => {
        if (obj?.start == true) {
            setCalendarOpenStart(!calendarOpenStart);
        } else {
            setCalendarOpenEnd(!calendarOpenEnd);
        }
    };
    const colors = [
        '#bc5090',
        '#ff6361',
        '#ff9d1c',
        '#6d6e75',
        '#6a5acd',
        '#483d8b',
        '#4169e1',
        '#4682b4',
        '#00bfff',
        '#1e90ff',
        '#87cefa',
        '#6495ed',
        '#006400',
        '#228b22',
        '#3cb371',
        '#a0522d',
        '#d2691e',
        '#cd5c5c',
        '#f08080',
        '#fa8072',
        '#e9967a',
        '#2e8b57',
        '#f4a460',
        '#deb887',
        '#d2b48c',
        '#20b2aa',
        '#ffdead',
        '#f5deb3',
        '#8b4513',
        '#d3d3d3',
        '#c0c0c0',
        '#a9a9a9',
        '#808080',
        '#708090',
        '#8b008b',
        '#800080',
        '#4b0082',
        '#7b68ee',
        '#3c1874',
        '#7a378b',
        '#b452cd',
        '#a020f0',
        '#9370db',
        '#5f9ea0',
        '#00ced1',
        '#40e0d0',
        '#48d1cc',
        '#00fa9a',
        '#32cd32',
        '#adff2f',
        '#9acd32',
        '#808000',
        '#6b8e23',
        '#556b2f',
        '#00ff00',
        '#7cfc00',
        '#7fff00',
        '#00ff7f',
        '#66cdaa',
        '#8fbc8f',
        '#90ee90',
        '#98fb98',
        '#ffe4c4',
        '#2f4f4f',
        '#778899',
        '#b0c4de',
        '#dcdcdc',
        '#c71585',
        '#f5f5dc',
        '#f0e68c',
        '#dcdcdc',
        '#ff1493',
        '#c71585',
        '#ff4500',
        '#ff6347',
        '#ff69b4',
        '#ffb6c1',
        '#db7093',
        '#ff8247',
        '#f0e68c',
        '#003f5c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
        '#58508d',
    ];
    const menuBgColor = 'rgb(16 85 151)'
    const DAY_MAP = {
        MONDAY: "M",
        TUESDAY: "T",
        WEDNESDAY: "W",
        THURSDAY: "Th",
        FRIDAY: "F",
        SATURDAY: "Sa",
        SUNDAY: "Su",
    };
    const parseNull = (value, replacement = "NA", type = "string") => {
        return value === null || (Array.isArray(value) && value.length === 0)
            ? replacement
            : Array.isArray(value)
                ? value.map((day) => DAY_MAP[day]).join(", ")
                : type === "date"
                    ? moment(value).format("MM/DD/YYYY")
                    : value;
    };
    const showAlert = (msg, severity) => {
        setOpen(true);
        setMessage(msg);
        setSeverity(severity);
    };
    const handleComponentDrop = async (item, size) => {
        if (size) {
            item.properties.height = size?.height;
            item.properties.width = size?.width;
        }
        item?.type === "table" ? setIsDisableTable(true) : "";
        if (!components.some((component) => component?.id === item?.id)) {
            setFlag(true);
            let propertiesI = await getDefaultProperties(item.type);
            propertiesI.left = item?.properties?.left;
            propertiesI.top = item?.properties?.top;
            const newComponent = {
                id: `component-${componentCount}`,
                type: item?.type,
                properties: propertiesI,
            };
            if (item?.type === "table") {
                if (currentReportData?.data?.length > 0) {
                    const columnProperties = getDefaultColumnsProperties(
                        Object?.keys(currentReportData?.data[0])
                    );
                    const columnTypes = getColumnTypes(currentReportData?.data);
                    const updatedColumnProperties = columnProperties?.map((item) => {
                        return {
                            ...item,
                            type: columnTypes[item?.name] || "VARCHAR",
                        };
                    });
                    const parentEle = dropRef?.current;
                    let top = 0;
                    await components.forEach((component) => {
                        const childElement = document.getElementById(component.id);
                        if (childElement) {
                            const childBottom =
                                component.properties.top + childElement.offsetHeight + 10;
                            top = Math.max(top, childBottom);
                        }
                    });
                    newComponent.properties.columns = currentReportData?.columns;
                    newComponent.properties.allColumns = Object.keys(currentReportData?.data[0]);
                    newComponent.properties.data = currentReportData?.data;
                    newComponent.properties.width = parentEle ? parentEle.offsetWidth - 50 : 1020;
                    newComponent.properties.height = 450;
                    newComponent.properties.top = top;
                    newComponent.properties.left = 20;
                    newComponent.properties.columnProperties = updatedColumnProperties;
                    newComponent.properties.name = currentReportData?.tableName;
                    newComponent.properties.tableName = currentReportData?.tableName;
                    newComponent.properties.description = templates?.find((item) => item?.id === currentReportData?.templateId)?.description;
                } else {
                    showAlert("Please select data source", "info");
                    setIsNewDashboardClicked(true);
                    return;
                }
            }
            if (item?.type === "chart") {
                //if (currentReportData?.data?.length > 0) {
                //  const headers = currentReportData?.columns
                // const result = generateChartData(
                //     headers[0],
                //     headers[1],
                //     [],
                //     null,
                //     true,
                //     false,
                //     false,
                //     `component-${componentCount}`
                // );
                // const columnProperties = getDefaultColumnsProperties(
                //     Object?.keys(currentReportData?.data[0])
                // );
                // const columnTypes = getColumnTypes(currentReportData?.data);
                // const updatedColumnProperties = columnProperties?.map((item) => {
                //     return {
                //         ...item,
                //         type: columnTypes[item?.name] || "VARCHAR",
                //     };
                // });
                let top = 20;
                await components.forEach((component) => {
                    const childElement = document.getElementById(component.id);
                    if (childElement) {
                        const childBottom =
                            component.properties.top + childElement.offsetHeight + 10;
                        top = Math.max(top, childBottom);
                    }
                });
                const parentEle = dropRef?.current;
                newComponent.properties.top = top;
                newComponent.properties.left = 10;
                // newComponent.properties.width = parentEle ? parentEle.offsetWidth - 50 : 1020;
                // newComponent.properties.height = 450;
                newComponent.properties.width = 1024;
                newComponent.properties.height = 362
                // newComponent.properties.columns = currentReportData?.columns;
                // newComponent.properties.data = currentReportData?.data;
                // newComponent.properties.allColumns = Object.keys(currentReportData?.data[0]);
                // newComponent.properties.option.series = result?.series;
                // newComponent.properties.option.xAxis.data = result?.xValues;
                // newComponent.properties.option.legend.data = result?.series?.map(
                //     (series) => series.name
                // );
                // newComponent.properties.xAxis = headers[0];
                // newComponent.properties.yAxis = headers[1];
                // newComponent.properties.isLogScaleY = false;
                // newComponent.properties.templateId = currentReportData?.templateId
                // newComponent.properties.columnProperties = updatedColumnProperties;
                // newComponent.properties.tableName = currentReportData?.tableName;
                // } else {
                //     showAlert("Please select data source", "info");
                //     setIsNewDashboardClicked(true);
                //     return;
                // }
            }
            setSelectedComponent(newComponent);
            setComponents([...components, newComponent]);
            setComponentCount(componentCount + 1);
        }
    };
    const handleComponentDrop1 = async (item, size) => {
        if (size) {
            item.properties.height = size?.height;
            item.properties.width = size?.width;
        }
        item?.type === "textbox" ? setIsDisableTable(true) : "";
        if (!components.some((component) => component?.id === item?.id)) {
            setFlag(true);
            const parentEle = dropRef?.current;
            let propertiesI = await getDefaultProperties(item.type);
            propertiesI.left = item?.properties?.left;
            propertiesI.top = item?.properties?.top;
            const newComponent = {
                id: `component-${componentCount}`,
                type: item?.type,
                properties: propertiesI,
            };
            if (item?.type === "textbox") {
                newComponent.properties.width = parentEle.offsetWidth - 50;
                newComponent.properties.height = 50;
                newComponent.properties.top = 5;
                newComponent.properties.left = 20;
                newComponent.properties.align = 'center';
                newComponent.properties.backgroundColor = " #E2E8F0";
                newComponent.properties.borderColor = "#fff";
                newComponent.properties.borderWidth = 1;
                newComponent.properties.text = 'New Dashboard';
                newComponent.properties.textColor = 'rgb(10, 58, 103, 1)';
                newComponent.properties.fontWeight = "bold";
                newComponent.properties.lineHeight = '29px';
            }
            setSelectedComponent(null);
            setComponents([newComponent]);
            setComponentCount(componentCount + 1);
        }
    };
    const handlePropertyChange = (propertyChange) => {
        const updatedComponents = components?.map((item) => {
            if (item?.id === selectedComponent?.id) {
                return {
                    ...item,
                    properties: {
                        ...item?.properties,
                        ...propertyChange,
                    },
                };
            }
            return item;
        });
        console.log(updatedComponents, "UPDETED COMPONETTTT....");
        setComponents(updatedComponents);
    };
    const getDefaultProperties = (type) => {
        switch (type) {
            case "table":
                return {
                    height: 100,
                    width: 250,
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "solid",
                    borderColor: "#B7B7B7",
                    borderWidth: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    align: "center",
                    textColor: "rgba(0,0, 0, 1)",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 13,
                    name: `Table`,
                    columns: ["Column 1", "Column 2"],
                    columnProperties: getDefaultColumnsProperties([
                        "Column 1",
                        "Column 2",
                    ]),
                    data: [{ "Column 1": "Data 1", "Column 2": "Data 2" }],
                    templateId: null,
                    description: null,
                    paddingLeft: 1,
                    paddingTop: 1,
                    paddingRight: 1,
                    paddingBottom: 1,
                    verticalAlign: "middle",
                    allColumns: [],
                    logColumns: [],
                    rowFields: [],
                    colFields: [],
                    aggType: 'Count',
                    isPivot: true,
                    pivotData: [],
                    tableView: "Data Table",
                    tableName: "",
                    drilledData: [],
                    drilledColumns: [{ id: 1, columnToFilter: '' }],
                };
            case "chart":
                return {
                    chartType: 'bar',
                    //data: currentReportData?.data,
                    columns: ["Column 1", "Column 2"],
                    data: [],
                    option: getDefaultChartOption(),
                    height: 400,
                    width: 800,
                    left: 0,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "",
                    borderColor: "",
                    borderWidth: 1,
                    name: "Chart",
                    xAxis: "Column 1",
                    yAxis: "Column 2",
                    line: null,
                    allColumns: [],
                    zAxis: [],
                    tableName: "",
                    rowFields: [],
                    colFields: [],
                    slice: [],
                    tableView: "Pivot Table",
                    drillData: {
                        level: 0,
                        level1: { xAxis: "", yAxis: "" },
                        level2: { xAxis: "", yAxis: "" },
                        breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                    },
                    pieDrillData: {
                        level: 0,
                        level1: { slice: "" },
                        level2: { slice: "" },
                        breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                    }
                };
            case "textbox":
                return {
                    text: "",
                    height: 100,
                    width: 250,
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "",
                    borderColor: "",
                    borderWidth: 1,
                    backgroundColor: "#fff",
                    align: "left",
                    textColor: "#000",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 15,
                    name: "Textbox",
                    paddingLeft: 1,
                    paddingTop: 1,
                    paddingRight: 1,
                    paddingBottom: 1,
                    textdecoration: "none",
                    lineHeight: 1,
                };
            case "image":
                return {
                    src: "",
                    name: "Image",
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    height: 214,  //170
                    width: 195,
                    borderType: "",
                    borderColor: "",
                    borderWidth: 1,
                    align: "center",
                    textColor: "#000",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 16,
                    backgroundColor: "#fff",
                };
            case "shape":
                return { type: "circle" };
            default:
                return {
                    height: 100,
                    width: 250,
                    left: "0",
                    top: "0",
                    borderType: "solid",
                    borderColor: "#000000",
                    borderWidth: 1,
                    backgroundColor: "#fff",
                    name: `Table`,
                    columns: ["Column 1", "Column 2"],
                    data: [{ "Column 1": "Data 1", "Column 2": "Data 2" }],
                    templateId: null,
                };
        }
    };
    const getDefaultColumnsProperties = (columns) => {
        return columns.map((item, index) => {
            return {
                id: index,
                name: item,
                align: undefined,
                borderType: undefined,
                borderColor: undefined,
                borderWidth: 1,
                backgroundColor: undefined,
                textColor: undefined,
                active: true,
                fontFamily: undefined,
                fontWeight: undefined,
                fontSize: undefined,
                isIncoming: true,
                isComputedColumn: false,
                computedColumns: {}
            };
        });
    };
    const getDefaultChartOption = () => ({
        // title: {
        //     text: 'Chart',
        // },
        backgroundColor: "#fff",
        tooltip: {},
        legend: {
            left: "center",
            data: ["Example"],
        },
        xAxis: {
            data: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"],
            rotate: '-45',
            axisLabel: {
                interval: 0,
                rotate: '-45',
                color: "#4b5563",
                fontSize: 12,
                fontFamily: 'Inter',
            },
        },
        yAxis: {
            type: "value",
            min: 0,
            interval: 2,
            axisLabel: {
                color: "#4b5563",
                fontSize: 12,
                fontFamily: undefined,
            },
        },
        series: [
            {
                name: "Example",
                type: "bar",
                category: 'Column 2',
                data: [5, 4, 4, 5, 3, 2, 6, 7, 4, 3, 5],
                itemStyle: { color: '#ddd' }
            },
        ],
    });
    const getUserTemplates = async () => {
        try {
            const result = await getAllTables().unwrap();
            setTemplates(result?.data);
        } catch (error) {
            console.log(error, "Error");
        }
    };
    const getAllDashboard = async () => {
        try {
            const result = await getAllDashboards().unwrap();
            setMyDashboards(result?.data);
        } catch (error) {
            console.log(error, "Error");
        }
    };
    const transformToJSON = (data) => {
        const headers = data ? data[0] : [];
        const rows = data?.slice(1);

        const allKeys = new Set(headers);
        return rows?.map((row) => {
            const obj = {};
            row?.forEach((cell, i) => {
                obj[headers[i]] = cell;
            });
            allKeys?.forEach((key) => {
                if (!(key in obj)) {
                    obj[key] = null;
                }
            });
            return obj;
        });
    };
    const isValidDate = (value) => {
        const date = new Date(value);
        return !isNaN(date.getTime());
    };
    const determineColumnType = (value) => {
        if (value === null || value === undefined || value === "" || value === 'character varying') {
            return "VARCHAR";
        }
        if (value === 'integer') {
            return "INTEGER";
        }
        if (value === "boolean") {
            return "BOOLEAN";
        }
        if (value === 'timestamp without time zone') {
            return "DATE";
        }
        return "VARCHAR";
    };
    const getColumnTypes = (data) => {
        const columnTypes = {};
        data.forEach((column) => {
            // for (let row of data) {
            //     if (row[column] !== undefined && row[column] !== null) {
            //         columnTypes[column] = determineColumnType(row[column]);
            //         break;
            //     }
            // } 
            columnTypes[column?.name] = determineColumnType(column?.type);
        });
        return columnTypes;
    };
    const removeDuplicates = (data) => {
        const uniqueData = [];
        const seen = new Set();
        const stringifySortedObject = (obj) => {
            // Sort the keys of the object to ensure consistent string representation
            const sortedKeys = Object.keys(obj).sort();
            const sortedObj = {};
            for (const key of sortedKeys) {
                sortedObj[key] = obj[key];
            }
            return JSON.stringify(sortedObj);
        };
        for (const item of data) {
            const identifier = stringifySortedObject(item);
            if (!seen.has(identifier)) {
                seen.add(identifier);
                uniqueData.push(item);
            }
        }
        return uniqueData;
    };
    const filterDataByColumns = (data, columns) => {
        return data.map((row) => {
            let filteredRow = {};
            columns.forEach((column) => {
                filteredRow[column] = Object.prototype.hasOwnProperty.call(row, column)
                    ? row[column]
                    : ""; // Add empty string for missing columns
            });
            return filteredRow;
        });
    };
    const handleChange = async (file) => {
        // setIsUploadCsv(false);
        //setLoading(true)
        setIsActiveDataSource(true);
        try {
            const fileSizeInKB = file.size / 1024;
            const fileSizeInMB = fileSizeInKB / 1024;
            console.log(`File size: ${fileSizeInKB.toFixed(2)} KB`);
            console.log(`File size: ${fileSizeInMB.toFixed(2)} MB`);
            if (fileSizeInMB > 3) {
                setOpen(true);
                setMessage("File size is more than 3 MB.");
                return new Error("File size is more than 3 MB.");
            } else {
                if (file) {
                    const fileExtension = file.name.split(".").pop().toLowerCase();
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        let jsonData;
                        if (fileExtension === "csv") {
                            const data = e.target.result;
                            const parsedData = Papa.parse(data, { header: true });
                            if (
                                parsedData.data.length > 0 &&
                                Object.keys(parsedData.data[0]).length > 0
                            ) {
                                const headers = Object.keys(parsedData.data[0]);
                                const values = parsedData.data.map((row) => Object.values(row));
                                jsonData = [headers, ...values];
                                const headers2 = jsonData[0]?.filter(
                                    (header) =>
                                        header !== "" && header !== undefined && header !== null
                                );
                                const adjustedData = jsonData?.map((row, rowIndex) => {
                                    if (rowIndex === 0) return headers2;
                                    return row?.filter(
                                        (_, colIndex) =>
                                            headers2[colIndex] !== "" &&
                                            headers2[colIndex] !== undefined &&
                                            headers2[colIndex] !== null
                                    );
                                });
                                jsonData = adjustedData;
                            } else {
                                setOpen(true);
                                setSeverity("error");
                                setMessage("Something went wrong");
                            }
                        }
                        // else {
                        //     const data = new Uint8Array(e.target.result);
                        //     const workbook = XLSX.read(data, { type: "array" });
                        //     const sheetName = workbook.SheetNames[0];
                        //     const worksheet = workbook.Sheets[sheetName];
                        //     jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        //     const headers2 = jsonData[0]?.filter(
                        //         (header) =>
                        //             header !== "" && header !== undefined && header !== null
                        //     );
                        //     const adjustedData = jsonData.map((row, rowIndex) => {
                        //         if (rowIndex === 0) return headers2;
                        //         return row?.filter(
                        //             (_, colIndex) =>
                        //                 jsonData[0][colIndex] !== "" &&
                        //                 jsonData[0][colIndex] !== undefined &&
                        //                 jsonData[0][colIndex] !== null
                        //         );
                        //     });
                        //     jsonData = adjustedData;
                        // }
                        else {
                            const data = new Uint8Array(e.target.result);
                            const workbook = XLSX.read(data, { type: 'array' });
                            const sheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[sheetName];
                            jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                            // Replace empty cells with "NA"
                            jsonData.forEach((row, rowIndex) => {
                                row.forEach((cell, colIndex) => {
                                    if (cell === "" || cell === undefined || cell === null || cell === " ") {
                                        row[colIndex] = "NA";
                                    }
                                });
                            });
                            const headers = jsonData[0];
                            const dataRows = jsonData.slice(1);

                            // Create a new array with the data in the format you want
                            const formattedData = dataRows.map((row) => {
                                const obj = {};
                                headers.forEach((header, index) => {
                                    obj[header] = row[index];
                                });
                                return obj;
                            });
                            // Use the formattedData array as needed
                        }
                        jsonData = jsonData?.filter((row) =>
                            row.some(
                                (cell) => cell !== "" && cell !== undefined && cell !== null
                            )
                        );
                        const headers = jsonData[0];
                        // const analysis = generateChartData(jsonData, headers[1], headers[0]);
                        const jsonLikeData = await transformToJSON(jsonData);
                        setFileData(jsonLikeData.map((row) => {
                            return Object.fromEntries(Object.entries(row).map(([key, value]) => {
                                if (value === undefined || value === null) {
                                    return [key, "NA"];
                                }
                                return [key, value];
                            }));
                        }));
                        if (!jsonData) {
                            setOpen(true);
                            setSeverity("error");
                            setMessage("Something went wrong.");
                            setIsUploadCsv(false);
                            return;
                        }
                        const result = await getTemplatesByColumns({
                            columns: jsonData[0],
                        });
                        if (result?.data?.data.length <= 0) {
                            setIsCreateNewTemplate(true);
                            setAvailableTemplates([]);
                        } else {
                            setIsCreateNewTemplate(false);
                            setAvailableTemplates(result?.data?.data);
                        }
                        await getUserTemplates();
                        setCsvData(jsonData);
                        // setTotals([{ name: "Total Records Found", value: jsonLikeData?.length }])
                        setTmpName("");
                        setselectedTmp(null);
                        setDescription("");
                    };
                    reader.onerror = (error) => {
                        console.error("Error reading file:", error);
                    };
                    if (fileExtension === "csv") {
                        reader.readAsText(file);
                    } else {
                        reader.readAsArrayBuffer(file);
                    }
                }
            }
        } catch (err) {
            setOpen(true);
            setSeverity("error");
            setMessage("Something went wrong");
        }
    };
    const extractLogColumns = (components) => {
        const logColumns = [];
        components.forEach(component => {
            if (component.type === 'chart' && component.properties.logColumns) {
                const doubleLogs = component.properties.logColumns.double || [];
                logColumns.push(...doubleLogs);
                if (component.properties.logColumns.zAxis) {
                    logColumns.push(component.properties.logColumns.zAxis);
                }
                if (component.properties.logColumns.yAxis) {
                    logColumns.push(component.properties.logColumns.yAxis);
                }
            }
        });
        return [...new Set(logColumns)];
    };

    const updateLogTable = (logHeaders) => {
        //  console.log(components.find((item) => item?.type === 'table')?.properties?.data, "data")
        console.log(logHeaders)
    }
    const findOverallMaxValue = (series) => {
        // Flatten all data arrays into one and find the maximum value
        const allDataValues = series.flatMap(item => item.data);
        return Math.max(...allDataValues);
    };
    const generateChartData = (data, xHeader, yHeader, zHeaders, lineHeader) => {
        // Generate bar chart data
        const barResult = {};
        const yAxisColors = new Set();
        data?.forEach((row) => {
            const xValue = row[xHeader];
            const yValue = row[yHeader];
            if (!barResult[xValue]) {
                barResult[xValue] = {};
            }
            if (!barResult[xValue][yValue]) {
                barResult[xValue][yValue] = 0;
            }
            barResult[xValue][yValue]++;
        });
        const series = [];
        const xValues = [...new Set(data.map((row) => row[xHeader]))];
        let colorIndex = 0;
        // Generate bar series
        if (yHeader !== '') {
            Object.keys(barResult).forEach((xValue) => {
                Object.keys(barResult[xValue] || {}).forEach((yValue) => {
                    let seriesItem = series.find((s) => s.name === yValue && s.type === "bar");
                    if (!seriesItem) {
                        seriesItem = {
                            name: yValue,
                            type: "bar",
                            data: Array(xValues.length).fill(0),
                            itemStyle: {
                                color: colors[colorIndex % colors.length],
                            },
                            category: yHeader
                        };
                        series.push(seriesItem);
                        colorIndex++;
                    }
                    const index = xValues.indexOf(xValue);
                    if (index !== -1) {
                        seriesItem.data[index] = barResult[xValue][yValue];
                    }
                });
            });
        }
        const createSeries = (name, color, header) => ({
            name,
            type: 'bar',
            data: Array(xValues.length).fill(0),
            itemStyle: { color },
            category: header
        });
        const fillSeriesData = (series, header, value, color) => {
            const seriesItem = createSeries(value, color, header);
            xValues.forEach((xValue, index) => {
                const filteredData = data.filter(row => row[xHeader] === xValue && row[header] === value);
                seriesItem.data[index] = filteredData.length; // Count occurrences
            });
            return seriesItem;
        };
        zHeaders?.filter((item) => item !== yHeader)?.forEach((header, headerIndex) => {
            // Get unique values for the current header
            const uniqueValues = [...new Set(data.map(row => row[header]))];
            uniqueValues.forEach((value, valueIndex) => {
                let color;
                do {
                    color = true ? colors[(colorIndex + yAxisColors.size) % colors.length] : '#000';
                    colorIndex++;
                } while (yAxisColors.has(color))
                //const color = true ? colors[(headerIndex * uniqueValues.length + valueIndex ) % colors.length] : '#000';
                series.push(fillSeriesData(series, header, value, color));
            });
        });
        // Generate line series if lineHeader is provided
        if (lineHeader) {
            const lineResult = {};
            data.forEach((row) => {
                const xValue = row[xHeader];
                const lineValue = row[lineHeader];
                if (!lineResult[xValue]) {
                    lineResult[xValue] = {};
                }
                if (!lineResult[xValue][lineValue]) {
                    lineResult[xValue][lineValue] = 0;
                }
                lineResult[xValue][lineValue]++;
            });

            // Generate line series
            Object.keys(lineResult).forEach((xValue) => {
                Object.keys(lineResult[xValue] || {}).forEach((lineValue) => {
                    let seriesItem = series.find((s) => s.name === lineValue && s.type === "line");
                    if (!seriesItem) {
                        seriesItem = {
                            name: lineValue,
                            type: "line",
                            data: Array(xValues.length).fill(0),
                            itemStyle: {
                                color: colors[colorIndex % colors.length],
                            },
                            category: lineHeader
                        };
                        series.push(seriesItem);
                        colorIndex++;
                    }
                    const index = xValues.indexOf(xValue);
                    if (index !== -1) {
                        seriesItem.data[index] = lineResult[xValue][lineValue];
                    }
                });
            });
        }
        const overallMaxValue = findOverallMaxValue(series);



        const interval = overallMaxValue <= 5 ? 1 : overallMaxValue <= 10 ? 2 : overallMaxValue <= 20 ? 4 : overallMaxValue <= 100 ? 10 : 20;

        console.log(interval, ">>> Inetr val .....")
        return { series, xValues, interval };
    };
    const generatePieChartData = (data, yHeader) => {
        //  const data = currentReportData?.data

        const result = {};
        // Aggregate data based on yHeader
        data?.forEach(row => {
            const yValue = row[yHeader];
            if (!result[yValue]) {
                result[yValue] = 0;
            }
            result[yValue]++;
        });
        // Convert aggregated data into the format needed for pie chart
        const series = [{
            name: yHeader,
            type: 'pie',
            radius: '50%',
            data: Object.keys(result).map((key, index) => ({
                value: result[key],
                name: key,
                itemStyle: {
                    color: colors[index % colors.length] // Set colors for each pie slice
                }
            })),
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }];
        console.log(series, ">>>>>> ");
        return { series };
    };
    const generateDoubleChartData = (data, xHeader, yHeader, zHeaders, isSetColors, logColumns, id) => {
        // const filteredComponents = components.filter(item => item?.type === 'chart' && item?.id !== id);
        //  const extractedLogColumns = extractLogColumns(filteredComponents);
        //   const combinedLogColumns = [...new Set([...logColumns, ...extractedLogColumns])];

        const xValues = [...new Set(data.map(row => row[xHeader]))];
        const newZheader = [yHeader, ...zHeaders];
        const zAxisResults = {};
        const applyLogScale = (value) => {
            return value > 0 ? parseFloat(Math.log(value + 1).toFixed(2)) : 0;
        };
        data.forEach((row) => {
            const xValue = row[xHeader];
            newZheader?.forEach((zHeader) => {
                const zValue = row[zHeader];
                if (!zAxisResults[xValue]) {
                    zAxisResults[xValue] = {};
                }
                if (!zAxisResults[xValue][zHeader]) {
                    zAxisResults[xValue][zHeader] = {};
                }
                if (!zAxisResults[xValue][zHeader][zValue]) {
                    zAxisResults[xValue][zHeader][zValue] = 0;
                }
                zAxisResults[xValue][zHeader][zValue]++;
            });
        });
        const series = [];
        let colorIndex = 0;
        newZheader.forEach((zHeader, headerIndex) => {
            const uniqueValues = [...new Set(data.map(row => row[zHeader]))];

            uniqueValues.forEach((value, valueIndex) => {
                const color = true ? colors[(headerIndex * uniqueValues.length + valueIndex) % colors.length] : '#000';

                const seriesItem = {
                    name: value,
                    type: 'bar',
                    data: Array(xValues.length).fill(0),
                    itemStyle: { color },
                    category: zHeader
                };

                xValues.forEach((xValue, index) => {
                    const occurrence = zAxisResults[xValue][zHeader][value] || 0;
                    seriesItem.data[index] = occurrence;
                });

                series.push(seriesItem);
            });
        });
        const overallMaxValue = findOverallMaxValue(series);
        const interval = overallMaxValue <= 5 ? 1 : overallMaxValue <= 10 ? 2 : overallMaxValue <= 20 ? 4 : overallMaxValue <= 100 ? 10 : 20;

        return { series, xValues, interval };
    };
    const updateTableDataWithLogColumns = (logColumns, xHeader, headers, lineHeader) => {
        const tableComponent = components.find((item) => item?.type === 'table');
        if (!tableComponent) {
            console.error("Table component not found.");
            return;
        }
        let updatedData = [...tableComponent.properties.data];
        const applyLogScale = (value) => {
            return parseFloat(Math.log(value + 1).toFixed(2));
        };
        updatedData = updatedData.map((row) => {
            const newRow = { ...row };
            if (logColumns?.length > 0) {
                logColumns.forEach((logColumn) => {
                    const originalColumn = logColumn.replace(/^Log\(/, '').replace(/\)$/, '');
                    const originalValue = newRow[originalColumn];

                    if (originalValue !== undefined && !isNaN(originalValue)) {
                        newRow[logColumn] = applyLogScale(originalValue);
                    }
                });
            }
            return newRow;
        });
        tableComponent.properties.data = updatedData;
        tableComponent.properties.columns = tableComponent?.properties?.columns?.filter(column => Object.keys(updatedData[0])?.includes(column))
        tableComponent.properties.columns = tableComponent?.properties?.columns?.filter(column => {
            const isLogColumnInTable = column.startsWith('Log(');
            if (isLogColumnInTable) {
                return logColumns.includes(column);
            }
            return true;
        });
    };
    const handleComponentSelect = (item) => {
        setIsAnimated(false);
        setSelectedComponent(item);
        setFlag(true)
    };
    const handleDeleteComponent = (id) => {
        setSelectedComponent(null);
        const newComponents = components?.filter(
            (component) => component?.id !== id
        );
        const hasNonTableOrChart = newComponents?.filter(
            (item) => item?.type == "chart" || item?.type == "table"
        );
        if (hasNonTableOrChart?.length <= 0) {
            setCurrentReportData({ data: [], isNewDashboard: false, columns: [], templateId: null });
        }
        setComponents(newComponents);
    };
    const toggleDrawer = (newOpen) => () => {
        setDrawer(newOpen);
    };
    const handleSaveDashboard = async ({ parentId }) => {
        if (components?.length <= 0) {
            showAlert("Dashboard is Empty", "error");
            setDashboardState({ name: "", description: "" });
            setIsnewDashboard(false);
            return;
        }
        setIsnewDashboard(false);
        setSelectedComponent(null);
        const sanitizedComponents = components.map((component) => {
            if (
                (component?.type === "table" || component?.type === "chart") &&
                component?.properties
            ) {
                const { data, ...restProperties } = component.properties;
                return { ...component, properties: restProperties };
            }
            return component;
        });
        setIsSaveChanges(false);
        if (dashboardId !== null && !isSaveAsDashboard) {
            try {
                const result = await updateDashboard({
                    dashboardId: dashboardId,
                    components: sanitizedComponents,
                    isTableView: isTableView,
                    isChartView: isChartView,
                    parentId: parentId,
                    selectedDataSources: selectedDataSources
                });
                getAllDashboard();
                showAlert("Dashboard Updated Successfully.", "success");
            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const result = await createDashboard({
                    name: dashboardState?.name,
                    description: dashboardState?.description,
                    components: sanitizedComponents,
                    isTableView: isTableView,
                    isChartView: isChartView,
                    parentId: parentId,
                    selectedDataSources: selectedDataSources
                }).unwrap();
                setDashboardState({ name: "", description: "" });
                setIsSaveAsDashboard(false);
                showAlert("Dashboard Created Successfully.", "success");
                getAllDashboard();
                setDashboardId(result?.data?.id);
                setIsNewDashboardActive(false);
            } catch (err) {
                setDashboardId(null);
                setIsNewDashboardActive(false);
                showAlert(err?.data?.message, "error");
                setDashboardState({ name: "", description: "" });
                setComponents([]);
                setSelectedTemplate(null);
                setSelectedComponent(null);
            }
        }
    };
    const handleCloseReport = async () => {
        setIsnewDashboard(false);
        setIsCloseReport(false);
        setDashboardId(null);
        setIsNewDashboardActive(false);
        setComponents([]);
        setSelectedComponent(null);
    };
    const getDashboard = async (id) => {
        setIsGetById({ status: false, dashboardId: null })
        setIsNewDashboardActive(false);
        await setComponents([]);
        try {
            const response = await getDashboardById(id).unwrap();
            //console.log(response, ">>>> response, ");
            setSelectedTemplate(null);
            //  setIsTableView(response?.data?.data?.isTableView);
            // setIsChartView(response?.data?.data?.isChartView);
            const result = response?.data?.data?.components || [];
            setPdfName(response?.data?.data?.name || "Dashboard");
            const tableComponentIndex = result.findIndex(
                (item) => item?.type === "table"
            );
            const dataSources = [];
            const updatedComponents = result?.map((item, index) => {
                if (item?.type == 'chart' || item?.type == 'table') {
                    dataSources?.push(item?.properties?.tableName);
                    return {
                        ...item,
                        id: `component-${index + 1}`,
                        properties: {
                            ...item.properties,
                            data: item?.properties?.data,
                            allColumns: Object.keys(item?.properties?.data[0]),
                            // xAxis: item?.properties?.colFields[0],
                            // yAxis: item?.properties?.rowFields[0],
                            // drillData: {
                            //     level: 0,
                            //     level1: { xAxis: "", yAxis: "" },
                            //     level2: { xAxis: "", yAxis: "" },
                            //     breadcrumbs: [{ level: 0, xAxisValue: "main" }]
                            // },
                            // pieDrillData: {
                            //     level: 0,
                            //     level1: { slice: "" },
                            //     level2: { slice: "" },
                            //     breadcrumbs: [{ level: 0, sliceValue: "main" }]
                            // }
                        }
                    }
                }
                return {
                    ...item,
                    id: `component-${index + 1}`
                }
            });
            if (tableComponentIndex !== -1) {
                setCurrentReportData({
                    // data: response?.data?.tableData,
                    // columns: Object?.keys(response?.data?.tableData[0]),
                    isNewDashboard: true,
                    templateId: updatedComponents?.find((item) => item?.type === "table")
                        ?.properties?.tableName,
                    tableName: updatedComponents?.find((item) => item?.type === "table")
                        ?.properties?.tableName
                });
            } else {
                setCurrentReportData({
                    // data: response?.data?.tableData,
                    // columns: Object?.keys(response?.data?.tableData[0]),
                    isNewDashboard: true,
                    templateId: updatedComponents?.find((item) => item?.type === "chart")
                        ?.properties?.tableName,
                    tableName: updatedComponents?.find((item) => item?.type === "chart")
                        ?.properties?.tableName
                });
            }
            setSelectedDataSources(() => {
                const uniqueDataSources = [...new Set(dataSources)];
                const templatesFound = uniqueDataSources.map(item => {
                    return templates?.find(i => i?.table_name === item);
                });
                const dataSrs = templatesFound?.map((item) => {
                    const columnTypes = getColumnTypes(item?.columns);
                    const columns = item?.columns?.map((item) => {
                        return {
                            name: item,
                            type: columnTypes[item] || "VARCHAR",
                        };
                    })
                    return item ? { name: item?.table_name, columns: columns } : null;
                }).filter(item => item !== null);
                return dataSrs;
            });
            setComponents(updatedComponents);
            setComponentCount(updatedComponents?.length + 1);
            setFlag(true);
            setIsLodingReport(false);
            setDashboardId(response?.data?.data?.id);
            setIsSelectReport(true);
            setSelectedComponent(null);
        } catch (error) {
            console.error("Error fetching report by ID:", error);
        }
    };
    const handleTemplateSelect = async ({ id, name, isFromData, tableView, isFromExisting }) => {
        try {
            setIsLoadingTemplate(true);
            const result = await getTableData({ id, name }).unwrap();

            if (Array.isArray(result?.data?.tableData) && result.data.tableData.length === 0) {
                showAlert("The table is empty. No data available.", "info");
                return;
            }
            const columnTypes = getColumnTypes(result?.data?.columns);
            const hasName = selectedDataSources?.some((i) => i?.name === name);
            isFromExisting === true || hasName ? "" : setSelectedDataSources([...selectedDataSources, {
                name: name, columns: selectTmpToUplod?.columns?.map((item) => {
                    return {
                        name: item,
                        type: columnTypes[item] || "VARCHAR",
                    };
                })
            }]);

            const columnProperties = getDefaultColumnsProperties(
                Object?.keys(result?.data?.tableData[0])
            );
            const updatedColumnProperties = columnProperties?.map((item) => {
                return {
                    name: item?.name,
                    type: columnTypes[item?.name] || "VARCHAR",
                };
            });
            const parentEle = dropRef?.current;
            const newComponent = {
                id: `component-${componentCount}`,
                type: "table",
                properties: {
                    ...getDefaultProperties("table"),
                    columns: Object?.keys(result?.data?.tableData[0]),
                    allColumns: Object?.keys(result?.data?.tableData[0]),
                    data: result?.data?.tableData,
                    // width: 1020,
                    // height: '82vh',
                    width: parentEle ? parentEle.offsetWidth - 50 : 1020,
                    height: 450,
                    left: 10,
                    templateId: id,
                    columnProperties: updatedColumnProperties,
                    name: name,
                    tableName: name,
                    description: templates?.find((item) => item?.id === id)?.description,
                    tableView: tableView
                },
            };
            const selectedCols = await Object.entries(selectedColumns)
                .filter(([key, value]) => value === true) // Filter entries with true values
                .map(([key]) => key);

            handlePropertyChange({
                columns: selectedCols,
                data: result?.data?.tableData,
                allColumns: Object?.keys(result?.data?.tableData[0]),
                columnProperties: updatedColumnProperties,
                tableName: name,
                rowFields: [],
                colFields: [],
                option: getDefaultChartOption(),
                xAxis: "Column 1",
                yAxis: "Column 2",
                slice: [],
                zAxis: []
            });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    columns: selectedCols,
                    data: result?.data?.tableData,
                    allColumns: Object?.keys(result?.data?.tableData[0]),
                    columnProperties: updatedColumnProperties,
                    tableName: name,
                    rowFields: [],
                    colFields: [],
                    option: getDefaultChartOption(),
                    xAxis: "Column 1",
                    yAxis: "Column 2",
                    slice: [],
                    zAxis: []
                },
            }));
            setFlag(true);
            setDrawer(true);
            setFlag(true);
            setIsNewDashboardClicked(false);
            setSelectTmpToUpload(null);
            setIsLoadingTemplate(false);
            setSelectedColumns({});
        } catch (err) {
            console.log(err);
        }
    };
    const handleNameChange = (e) => {
        const name = e.target.value;
        if (/\s/.test(name)) {
            setErrorMessage("Template name cannot contain spaces.");
        } else {
            setErrorMessage("");
        }
        setTmpName(name);
    };
    const handleDesignClick = () => {
        if (selectedComponent?.properties?.data?.length <= 0) {
            if (selectedDataSources?.length <= 0) {
                setIsNewDashboardClicked(true);
            } else {
                setConfirmModalOpen(true);
            }
        } else {
            setDrawer(true);
        }
    }
    const handleDataClick = () => {
        setIsPivotOpen(true);
    }

    const handleSelectColumnToFilter = async (value) => {
        await clearFilter();
        const data = components?.find((item) => item?.properties?.tableName === selectedDSToFilter?.name)?.properties?.data
        const colType = selectedDSToFilter?.columns?.find((item) => item?.name === value)?.type;
        const colData = data?.map((item) => item[value]);
        value == -1 ?
            setColumnToFilter({ name: null, type: null, min: 0, max: 0, colData: [...new Set(colData)] }) : setColumnToFilter({ name: value, type: colType, min: 0, max: 0, colData: [...new Set(colData)] });
        setFilterObj({ columnName: value, columnType: colType, condition: null, conditionValue: "", min: null, max: null, from: null, to: null, values: [] });
        if (colType == "INTEGER") {
            const col = data.map(item => parseInt(item[value]));
            const min = Math.min(...col);
            const max = Math.max(...col);
            min === max ? setRangeValue(max) : setRangeValue(min);
            setColumnToFilter({
                name: value,
                type: colType,
                min: min,
                max: max,
                colData: [...new Set(colData)]
            });
            setFilterObj({
                columnName: value,
                columnType: "INT",
                condition: null,
                conditionValue: "",
                min: min,
                max: max,
                from: null,
                to: null,
                values: []
            });
        }
    }
    const clearAllFilters = async () => {
        let obj = {};
        for (const item of dataSourcesName) {
            const result = await getTableData({ name: item?.name }).unwrap();
            // Assigning the data dynamically to the object
            obj[item?.name] = result?.data?.tableData;
        }
        applyFilter({ data: obj, isClearAll: true })
        setDataSourcesName([]);
        setFilterObj(null);
        setColumnToFilter(null);
        setSearchQuery("");
        setIsFilterModelOpen(false);
        setDateRange([null, null])
    }
    const clearFilter = async (obj) => {
        if (filterObj?.columnName !== undefined) {
            try {
                const result = await getTableData({ name: obj?.isClearAll ? dataSourcesName[0]?.name : selectedDSToFilter?.name }).unwrap();
                applyFilter({ data: result?.data?.tableData, name: dataSourcesName[0]?.name, isClear: true });
                // Object?.entries(component?.properties?.drilledData).length > 0 ? setDrillDownData(component?.properties?.drilledColumns, result?.data) : ""
                setFlag(false);
                obj?.isClearAll ? setDataSourcesName([]) : setDataSourcesName(dataSourcesName?.filter((item) => item?.name !== selectedDSToFilter?.name))
            } catch (err) {
                showAlert(err?.data?.message, "error")
            }
        }
        setFilterObj(null);
        setColumnToFilter(null);
        setSearchQuery("");
        setDateRange([null, null]);
        obj?.isClearAll ? setSelectedDSToFilter(null) : "";
    }

    const applyFilter = async (apiResult) => {
        const tableNames = Object?.keys(apiResult?.data);
        const newComponents = components?.map((item) => {
            if ((!apiResult?.isClear && !apiResult?.isClearAll && item?.properties?.tableName === selectedDSToFilter?.name) || (apiResult?.isClearAll && tableNames?.includes(item?.properties?.tableName)) || apiResult?.name === item?.properties?.tableName) {
                if (item?.type === 'chart') {
                    if (item?.properties?.chartType === 'bar' || item?.properties?.chartType === 'barWithLine' || item?.properties?.chartType === 'stacked' || item?.properties?.chartType === 'line' || item?.properties?.chartType === 'area' || item?.properties?.chartType === 'scatter') {
                        console.log(apiResult?.data[item?.properties?.tableName], "PPPPPP");
                        const result = generateChartData(
                            apiResult?.isClearAll ? apiResult?.data[item?.properties?.tableName] : apiResult?.data,
                            item?.properties?.colFields[0],
                            item?.properties?.rowFields[0],
                            [],
                            item?.properties?.chartType === 'barWithLine' ?
                                item?.properties?.line
                                : null,
                        );
                        let options = {
                            ...item.properties.option,
                            series: result?.series,
                            xAxis: {
                                ...item.properties.option.xAxis,
                                data: result?.xValues,
                            },
                            yAxis: {
                                ...item.properties.option.yAxis,
                                interval: result?.interval,
                            },
                            legend: {
                                ...item.properties.option.legend,
                                data: result?.series?.map((series) => series.name),
                            },
                        };
                        return {
                            ...item,
                            properties: {
                                ...item.properties,
                                option: options,
                                xAxis: item?.properties?.colFields[0],
                                yAxis: item?.properties?.rowFields[0],
                                line: item?.properties?.chartType === 'barWithLine' ? item?.properties?.line
                                    : null,

                                data: apiResult?.isClearAll ? apiResult?.data[item?.properties?.tableName] : apiResult?.data,
                                drillData: {
                                    level: 0,
                                    level1: { xAxis: "", yAxis: "" },
                                    level2: { xAxis: "", yAxis: "" },
                                    breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                                },
                                pieDrillData: {
                                    level: 0,
                                    level1: { slice: "" },
                                    level2: { slice: "" },
                                    breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                                }
                            },
                        };
                    } else if (item?.properties?.chartType === 'double') {
                        const result = generateDoubleChartData(
                            apiResult?.isClearAll ? apiResult?.data[item?.properties?.tableName] : apiResult?.data,
                            item?.properties?.colFields[0],
                            item?.properties?.rowFields[0],
                            item?.properties?.zAxis,
                            isNewDashboard ? false : true,
                            [],
                            item?.id
                        );
                        let options = {
                            ...item.properties.option,
                            series: result?.series,
                            xAxis: {
                                ...item.properties.option.xAxis,
                                data: result?.xValues,
                            },
                            yAxis: {
                                ...item.properties.option.yAxis,
                                interval: result?.interval,

                            },
                            legend: {
                                ...item.properties.option.legend,
                                data: result?.series?.map((series) => series.name),
                            },
                        };
                        return {
                            ...item,
                            properties: {
                                ...item.properties,
                                //  data: currentReportData?.data,
                                option: options,
                                xAxis: item?.properties?.colFields[0],
                                yAxis: item?.properties?.rowFields[0],
                                zAxis: item?.properties?.zAxis,
                                line: item?.properties?.line,
                                data: apiResult?.isClearAll ? apiResult?.data[item?.properties?.tableName] : apiResult?.data,
                                drillData: {
                                    level: 0,
                                    level1: { xAxis: "", yAxis: "" },
                                    level2: { xAxis: "", yAxis: "" },
                                    breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                                },
                                pieDrillData: {
                                    level: 0,
                                    level1: { slice: "" },
                                    level2: { slice: "" },
                                    breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                                }
                            },
                        };
                    } else {
                        const result = generatePieChartData(
                            apiResult?.isClearAll ? apiResult?.data[item?.properties?.tableName] : apiResult?.data,
                            item?.properties?.slice[0])
                        let options = {
                            ...item.properties.option,
                            series: result?.series,
                            yAxis: {
                                ...item.properties.option.yAxis,
                                interval: result?.interval,

                            },
                            legend: { data: result?.series[0]?.data.map((item) => item?.name) },
                        };

                        return {
                            ...item,
                            properties: {
                                ...item.properties,
                                //  data: currentReportData?.data,
                                option: options,
                                slice: item?.properties?.colFields,
                                data: apiResult?.isClearAll ? apiResult?.data[item?.properties?.tableName] : apiResult?.data,
                                drillData: {
                                    level: 0,
                                    level1: { xAxis: "", yAxis: "" },
                                    level2: { xAxis: "", yAxis: "" },
                                    breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
                                },
                                pieDrillData: {
                                    level: 0,
                                    level1: { slice: "" },
                                    level2: { slice: "" },
                                    breadcrumbs: [{ level: 0, sliceValue: "Main" }]
                                }
                            },
                        };
                    }
                }
            }
            return item;
        });
        console.log(newComponents, ">>>> New Component .....");

        setComponents(newComponents);
        setFlag(false);
    }
    const handleCheckboxChange = (item) => {
        if (filterObj?.values?.includes(item)) {
            setFilterObj({
                ...filterObj,
                values: filterObj?.values?.filter((val) => val !== item),
            });
        } else {
            setFilterObj({ ...filterObj, values: [...filterObj.values, item] });
        }
    };

    const calculateFillPercentage = () => {
        const min = columnToFilter?.min ?? 0;
        const max = columnToFilter?.max ?? 100;
        const percentage = ((rangeValue - min) / (max - min)) * 100;
        return `${isNaN(percentage) ? 100 : percentage}%`;
    };
    const settings = [
        {
            id: 1,
            name: "Export as PDF",
        },
        {
            id: 2,
            name: "Export as SVG",
        },
        {
            id: 3,
            name: "Export as PNG",
        },
    ];
    const handleOpenUserMenu = (event) => {
        setSelectedComponent(null);
        setAnchorElOption(event.currentTarget);
    };
    const handleCloseUserMenu = ({ id }) => {
        setAnchorElOption(null);
        if (id === 1) {
            exportPDF();
        } else if (id === 2) {
            exportSVG();
        } else if (id === 3) {
            exportPNG();
        }
    };
    // const exportPDF = async () => {
    //     const reportElement = document.getElementById('report-building-area');
    //     if (!reportElement) return;

    //     const canvas = await html2canvas(reportElement, { scale: 2 });
    //     const imgData = canvas.toDataURL('image/png');

    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //     const finalPdfName = dashboardId !== null ? `${pdfName}.pdf` : "NewDashboard.pdf";
    //     pdf.save(finalPdfName);
    // };
    const exportPDF = async () => {
        const reportBuildingArea = document.getElementById('report-building-area');
        if (!reportBuildingArea) return;
        const clonedElement = reportBuildingArea.cloneNode(true);

        clonedElement.style.position = 'absolute';
        clonedElement.style.left = '-9999px';
        document.body.appendChild(clonedElement);

        const yAxisLabels = clonedElement.querySelectorAll('.y-axis-label');
        yAxisLabels.forEach(yAxisLabel => {
            yAxisLabel.style.transform = 'none';
            yAxisLabel.style.writingMode = 'horizontal-tb';
            yAxisLabel.style.textAlign = 'center';
        });
        const canvas = await html2canvas(clonedElement, {
            scale: 2,
            useCORS: true,
            allowTaint: true,
        });
        document.body.removeChild(clonedElement);
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        const pdfWidth = 280;
        const pdfHeight = pdfWidth * 297 / 210;
        const scale = pdfWidth / canvasWidth;

        const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]);

        let yOffset = 0;
        let page = 0;
        while (yOffset < canvasHeight) {
            const pdfCanvas = document.createElement("canvas");
            pdfCanvas.width = canvasWidth;
            let pageHeight = pdfHeight / scale;
            if (yOffset + pageHeight > canvasHeight) {
                pageHeight = canvasHeight - yOffset;
            } else {
                const snapshotContext = canvas.getContext("2d");
                const imageData = snapshotContext.getImageData(0, yOffset + pageHeight - 5, canvasWidth, 10);
                const pixels = imageData.data;
                const nonEmptyPixelCount = pixels.filter((value, index) => index % 4 === 3 && value > 0).length;
                if (nonEmptyPixelCount > 0) {
                    pageHeight -= 10;
                }
            }
            pdfCanvas.height = pageHeight;
            const pdfCtx = pdfCanvas.getContext("2d");
            pdfCtx.drawImage(canvas, 0, -yOffset, canvasWidth, canvasHeight);
            const pdfImage = pdfCanvas.toDataURL("image/png");
            if (page > 0) pdf.addPage();
            pdf.addImage(pdfImage, "PNG", 0, 0, pdfWidth, pageHeight * scale);
            yOffset += pageHeight;
            page++;
        }
        const finalPdfName = dashboardId ? `${pdfName}.pdf` : "NewDashboard.pdf";
        pdf.save(finalPdfName);
    };

    const exportSVG = async () => {
        const reportElement = document.getElementById('report-building-area');
        if (!reportElement) return;
        const clonedElement = reportElement.cloneNode(true);

        clonedElement.style.position = 'absolute';
        clonedElement.style.left = '-9999px';
        document.body.appendChild(clonedElement);

        const yAxisLabels = clonedElement.querySelectorAll('.y-axis-label');
        yAxisLabels.forEach(yAxisLabel => {
            yAxisLabel.style.transform = 'none';
            yAxisLabel.style.writingMode = 'horizontal-tb';
            yAxisLabel.style.textAlign = 'center';
        });
        const canvas = await html2canvas(clonedElement, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const svgWidth = canvas.width;
        const svgHeight = canvas.height;
        document.body.removeChild(clonedElement);

        const svgImage = `
            <svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}">
                <image href="${imgData}" width="${svgWidth}" height="${svgHeight}" />
            </svg>`;

        const finalSvgName = dashboardId !== null ? `${pdfName}.svg` : "NewDashboard.svg";
        const blob = new Blob([svgImage], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = finalSvgName;
        link.click();
        URL.revokeObjectURL(url);
    };
    const exportPNG = async () => {
        const reportElement = document.getElementById('report-building-area');
        if (!reportElement) return;
        const clonedElement = reportElement.cloneNode(true);
        clonedElement.style.position = 'absolute';
        clonedElement.style.left = '-9999px';
        document.body.appendChild(clonedElement);
        const yAxisLabels = clonedElement.querySelectorAll('.y-axis-label');

        yAxisLabels.forEach(yAxisLabel => {
            yAxisLabel.style.transform = 'none';
            yAxisLabel.style.writingMode = 'horizontal-tb';
            yAxisLabel.style.textAlign = 'center';
        });
        const canvas = await html2canvas(clonedElement, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        document.body.removeChild(clonedElement);
        const finalPngName = dashboardId !== null ? `${pdfName}.png` : "NewDashboard.png";
        const link = document.createElement("a");
        link.href = imgData;
        link.download = finalPngName;
        link.click();
    };
    React.useEffect(() => {
        getUserTemplates();
        getAllDashboard();
    }, []);
    React.useEffect(() => {
        const hasTableComponent = components.some(
            (component) => component.type === "table"
        );
        setIsDisableTable(hasTableComponent);
    }, [components]);

    React.useEffect(() => {
        if (currentReportData?.data?.length > 0) {
            const newComponents = components?.map((item) => {
                const headers = currentReportData?.isColumnChanged ? currentReportData?.columns : currentReportData?.isNewDashboard ? item?.properties?.columns : currentReportData?.columns;
                if (item?.properties?.chartType === 'bar' || item?.properties?.chartType === 'barWithLine' || item?.properties?.chartType === 'stacked' || item?.properties?.chartType === 'line' || item?.properties?.chartType === 'area' || item?.properties?.chartType === 'scatter') {
                    if (item?.type === "chart") {
                        const result = generateChartData(
                            item?.properties?.data,
                            currentReportData?.isColumnChanged ?
                                (currentReportData?.columns.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers?.filter(item => item !== item?.properties?.yAxis && item !== item?.properties?.line)[0])
                                : currentReportData?.isNewDashboard
                                    ? item?.properties?.colFields[0]
                                    : headers[0],
                            currentReportData?.isColumnChanged ?
                                (currentReportData?.columns.includes(item?.properties?.yAxis) ? item?.properties?.yAxis : headers?.filter(item => item !== item?.properties?.xAxis && item !== item?.properties?.line)[1])
                                : currentReportData?.isNewDashboard
                                    ? item?.properties?.rowFields[0]
                                    : headers[1],
                            currentReportData?.isColumnChanged ?
                                (item?.properties?.zAxis?.every((element) => currentReportData?.columns?.includes(element)) ? item?.properties?.zAxis : [])
                                : currentReportData?.isNewDashboard
                                    ? item?.properties?.zAxis
                                    : [],
                            currentReportData?.isColumnChanged && item?.properties?.chartType === 'barWithLine' ?
                                (currentReportData?.columns.includes(item?.properties?.line) ? item?.properties?.line : headers?.filter(item => item !== item?.properties?.xAxis && item !== item?.properties?.yAxis)[2])
                                : currentReportData?.isNewDashboard
                                    ? item?.properties?.line
                                    : null,
                        );
                        let options = {};
                        if (currentReportData?.isNewDashboard) {
                            options = {
                                ...item.properties.option,
                                series: item?.properties?.option?.series,
                                xAxis: {
                                    ...item.properties.option.xAxis,
                                    data: result?.xValues,
                                },
                                yAxis: {
                                    ...item.properties.option.yAxis,
                                    interval: result?.interval,

                                },
                                legend: {
                                    ...item.properties.option.legend,
                                    data: result?.series?.map((series) => series.name),
                                },
                            };
                        } else {
                            options = {
                                ...item.properties.option,
                                series: result?.series,
                                xAxis: {
                                    ...item.properties.option.xAxis,
                                    data: result?.xValues,
                                },
                                yAxis: {
                                    ...item.properties.option.yAxis,
                                    interval: result?.interval,

                                },
                                legend: {
                                    ...item.properties.option.legend,
                                    data: result?.series?.map((series) => series.name),
                                },
                            };
                        }
                        return {
                            ...item,
                            properties: {
                                ...item.properties,
                                columns: currentReportData?.isColumnChanged ?
                                    currentReportData?.columns
                                    : currentReportData?.isNewDashboard
                                        ? item?.properties?.columns
                                        : currentReportData?.columns,
                                option: options,
                                xAxis: currentReportData?.isColumnChanged ?
                                    (currentReportData?.columns.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers?.filter(item => item !== item?.properties?.yAxis && item !== item?.properties?.line)[0])
                                    : currentReportData?.isNewDashboard
                                        ? item?.properties?.colFields[0]
                                        : headers[0],
                                yAxis: currentReportData?.isColumnChanged ?
                                    (currentReportData?.columns.includes(item?.properties?.yAxis) ? item?.properties?.yAxis : headers?.filter(item => item !== item?.properties?.xAxis && item !== item?.properties?.line)[1])
                                    : currentReportData?.isNewDashboard
                                        ? item?.properties?.rowFields[0]
                                        : headers[1],
                                line: currentReportData?.isColumnChanged && item?.properties?.chartType === 'barWithLine' ?
                                    (currentReportData?.columns.includes(item?.properties?.line) ? item?.properties?.line : headers?.filter(item => item !== item?.properties?.xAxis && item !== item?.properties?.yAxis)[2])
                                    : currentReportData?.isNewDashboard
                                        ? item?.properties?.line
                                        : null,
                                zAxis: currentReportData?.isColumnChanged ?
                                    (item?.properties?.zAxis?.every((element) => currentReportData?.columns?.includes(element)) ? item?.properties?.zAxis : [])
                                    : currentReportData?.isNewDashboard
                                        ? item?.properties?.zAxis
                                        : [],
                                templateId: currentReportData?.templateId,
                            },
                        };
                    }
                } else if (item?.properties?.chartType === 'double') {
                    if (item?.type === "chart") {
                        const result = generateDoubleChartData(
                            item?.properties?.data,
                            currentReportData?.isColumnChanged ?
                                (currentReportData?.columns?.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers[0])
                                : currentReportData?.isNewDashboard
                                    ? item?.properties?.colFields[0]
                                    : headers[0],
                            currentReportData?.isNewDashboard
                                ? item?.properties?.zAxis
                                : [headers[1]],
                            isNewDashboard ? false : true,
                            currentReportData?.isNewDashboard
                                ? item?.properties?.logColumns?.double
                                : [],
                            item?.id
                        );
                        let options = {};
                        if (currentReportData?.isNewDashboard) {
                            options = {
                                ...item.properties.option,
                                series: item?.properties?.option?.series,
                                xAxis: {
                                    ...item.properties.option.xAxis,
                                    data: result?.xValues,
                                },
                                yAxis: {
                                    ...item.properties.option.yAxis,
                                    interval: result?.interval,

                                },
                                legend: {
                                    ...item.properties.option.legend,
                                    data: result?.series?.map((series) => series.name),
                                },
                            };
                        } else {
                            options = {
                                ...item.properties.option,
                                series: result?.series,
                                xAxis: {
                                    ...item.properties.option.xAxis,
                                    data: result?.xValues,
                                },
                                yAxis: {
                                    ...item.properties.option.yAxis,
                                    interval: result?.interval,

                                },
                                legend: {
                                    ...item.properties.option.legend,
                                    data: result?.series?.map((series) => series.name),
                                },
                            };
                        } return {
                            ...item,
                            properties: {
                                ...item.properties,
                                //  data: currentReportData?.data,
                                columns: currentReportData?.isNewDashboard
                                    ? item?.properties?.columns
                                    : currentReportData?.columns,
                                option: options,
                                xAxis: currentReportData?.isColumnChanged ?
                                    (currentReportData?.columns?.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers[0])
                                    : currentReportData?.isNewDashboard
                                        ? item?.properties?.colFields[0]
                                        : headers[0],
                                yAxis: item?.properties.rowFields,
                                zAxis: currentReportData?.isNewDashboard
                                    ? item?.properties?.zAxis
                                    : [headers[1]],
                                line: currentReportData?.isNewDashboard
                                    ? item?.properties?.line
                                    : null,
                            },
                        };
                    }
                } else {
                    if (item?.type === "chart") {
                        const result = generatePieChartData(
                            item?.properties?.data,
                            currentReportData?.isColumnChanged ?
                                (currentReportData?.columns?.includes(item?.properties?.slice[0]) ? item?.properties?.slice[0] : headers[0])
                                : currentReportData?.isNewDashboard
                                    ? item?.properties?.colFields[0]
                                    : headers[0])
                        let options = {};
                        if (currentReportData?.isNewDashboard) {
                            options = {
                                ...item.properties.option,
                                series: item?.properties?.option?.series,
                                legend: { data: item?.properties?.option?.series[0]?.data.map((item) => item?.name) },
                                yAxis: {
                                    ...item.properties.option.yAxis,
                                    interval: result?.interval,

                                },
                            };
                        } else {
                            options = {
                                ...item.properties.option,
                                series: result?.series,
                                legend: { data: result?.series[0]?.data.map((item) => item?.name) },
                                yAxis: {
                                    ...item.properties.option.yAxis,
                                    interval: result?.interval,

                                },
                            };
                        }
                        return {
                            ...item,
                            properties: {
                                ...item.properties,
                                //  data: currentReportData?.data,
                                columns: currentReportData?.isNewDashboard
                                    ? item?.properties?.columns
                                    : currentReportData?.columns,
                                option: options,
                                slice: currentReportData?.isColumnChanged ?
                                    (currentReportData?.columns?.includes(item?.properties?.xAxis) ? item?.properties?.slice[0] : headers[0])
                                    : currentReportData?.isNewDashboard
                                        ? item?.properties?.colFields
                                        : headers[0],
                                templateId: currentReportData?.templateId
                            },
                        };
                    }
                }
                return item;
            });
            setComponents(newComponents);
            if (selectedComponent?.type === "chart") {
                setSelectedComponent(
                    newComponents?.find((item) => item.id === selectedComponent?.id)
                );
            }
        }
    }, [currentReportData]);

    React.useEffect(() => {
        if (searchQuery === '') {
            setFilteredItems(columnToFilter?.colData || []);
        } else {
            setFilteredItems(
                columnToFilter?.colData?.filter((item) => {
                    // Convert item to string and then apply toLowerCase
                    return item.toString().toLowerCase().includes(searchQuery.toLowerCase());
                }) || []
            );
        }
    }, [searchQuery, columnToFilter]);

    const [isSelectAll, setIsSelectAll] = React.useState(false);
    const dropdownRef = React.useRef(null);
    const handleSelectAll = () => {
        const newSelectedColumns = {};
        if (!isSelectAll) {
            selectTmpToUplod?.columns?.forEach((item) => {
                newSelectedColumns[item] = true;
            });
        }
        setSelectedColumns(newSelectedColumns);
        setIsSelectAll(!isSelectAll);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setexportAnchorEl(null);
        setSelectedItem(null);
    };
    const handleExportMenuClick = (event) => {
        setexportAnchorEl(event.currentTarget);
    };
    const handleExportMenuClose = () => {
        setexportAnchorEl(null);
    };
    const handleInfoClick = () => {
        if (selectedItem) {
            setTemplateInfoState({
                name: selectedItem?.name,
                description: selectedItem?.description,
                id: selectedItem?.id,
                parentId: selectedItem?.parent_id,
                columns: [],
                isReport: true,
            });
            setIsInfoModelOpen(true); // Open the info modal
        }
        handleMenuClose(); // Close the menu after selection
    };
    const handleToggleDropdown = (event) => {
        event.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };
    const stringTypeConditions = [
        { value: "CONTAINS", label: "Contains" },
        { value: "BEGIN_WITH", label: "Begin with" },
        { value: "MATCHES", label: "Matches" },
        { value: "ENDS_WITH", label: "Ends with" },
        { value: "DOES_NOT_CONTAINS", label: 'Does not contain' },
        { value: "DOES_NOT_BEGIN_WITH", label: "Does not begin with" },
        { value: "DOES_NOT_MATCHES", label: "Does not match" },
        { value: "DOES_NOT_ENDS_WITH", label: "Does not end with" }
    ]
    const numericTypeConditions = [
        { value: "EQUALS_TO", label: "Equals to" },
        { value: "GREATER_THAN", label: "Greater than" },
        { value: "LESS_THAN", label: "Less than" },
        { value: "GREATER_THAN_EQUAL", label: "Greater than equal" },
        { value: "LESS_THAN_EQUAL", label: "Less than equal" },
        { value: "DOES_NOT_EQUAL", label: "Does not equal" }
    ]

    const handleClickOutside = (event) => {
        if (
            dropdownRef?.current &&
            !dropdownRef?.current?.contains(event.target)
        ) {
            setIsDropdownOpen(false);
        }
    };
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                style={{ width: "132px" }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right', // Align menu to the left of the icon
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left', // Transform to the right side of the icon
                }}
            >
                <MenuItem onClick={handleMenuClose} sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '13px' }}>Rename</MenuItem>
                {/* <MenuItem
                    // onClick={handleMenuClose}
                    sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '13px' }}
                >
                    <span className="d-flex gap-2 items-center">
                        <p className="p-0 m-0 text-black text-[13px]">Export as</p>
                        <ChevronRightIcon className="text-gray-500 text-[13px]" onClick={handleExportMenuClick} />
                    </span>
                </MenuItem> */}
                <MenuItem
                    onClick={handleInfoClick}
                    sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '13px' }}
                >
                    Info
                </MenuItem>
                <MenuItem onClick={handleMenuClose} sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '13px' }}>Remove</MenuItem>
            </Menu>
            {/* <Menu
                anchorEl={exportAnchorEl}
                open={openExportMenu}
                onClose={handleExportMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleMenuClose} sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '13px' }}>
                    Export as Image
                </MenuItem>
                <MenuItem onClick={handleMenuClose} sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '13px' }}>
                    Export as PDF
                </MenuItem>
            </Menu> */}
            {isModalOpen && (
                <ReusableReportModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    title={"Apply or Clear Filter ?"}
                >
                    <div className="mx-2 my-2 d-flex flex-col">
                        <span className="text-[15px] mt-1 ">{`Would you like to keep the previous filter or clear it?`}</span>
                        <span className="self-end mt-3 space-x-2 ">
                            <button
                                // onClick={() => {
                                //     clearFilter({ isClearAll: true });
                                //     setIsModalOpen(false);
                                // }}
                                onClick={() => {
                                    // clearFilter({ isClearAll: true });
                                    dataSourcesName?.length <= 1 ? clearFilter({ isClearAll: true }) : clearAllFilters();

                                    setIsModalOpen(false);
                                }}
                                className="px-3 text-sm btn-bgcolor py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed "
                            >
                                Clear
                            </button>
                            <button
                                onClick={() => {
                                    setIsModalOpen(false);
                                    setFilterObj(null);
                                    setColumnToFilter(null);
                                    setSearchQuery("");
                                }}
                                className="px-3 text-sm btn-bgcolor  py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed "
                            >
                                Continue
                            </button>
                        </span>
                    </div>
                </ReusableReportModal>
            )}
            {isPivotOpen &&
                <ReusableReportModal
                    open
                    onClose={() => setIsPivotOpen(!isPivotOpen)}
                    title={"Pivot Table"}>
                    <div
                        className="mx-2 my-2 d-flex flex-col custom-scrollbar"
                    // style={{ maxHeightheight: '70%', overflow: 'auto', maxWidth: '70%' }}
                    >
                        <TableComponent
                            type={selectedComponent?.properties?.type}
                            columns={selectedComponent?.properties?.columns}
                            data={selectedComponent?.properties?.data}
                            //data={currentReportData.data}
                            key={selectedComponent?.id}
                            properties={{
                                ...selectedComponent.properties,
                                borderWidth: 1,
                                borderType: 'solid',
                                borderColor: '#0a3a67',
                                fontSize: 15,
                                fontFamily: ""
                            }}
                            id={selectedComponent?.id}
                            stopTableDrag={() => {
                                setDisableRndDragging(true);
                            }}
                            onPropertyChange={handlePropertyChange}
                            setSelectedComponent={setSelectedComponent}
                            component={
                                selectedComponent !== null
                                    ? selectedComponent
                                    : null
                            }
                            showAlert={showAlert}
                            setIsSavedClicked={setIsSavedClicked}
                            setIsSaveAsClicked={setIsSaveAsClicked}
                            setIsCreateNewTemplate={setIsCreateNewTemplate}
                            selectedTemplate={selectedTemplate}
                            isChartView={isChartView}
                            isTableView={isTableView}
                            //   pivotData={component?.properties?.pivotData}
                            isFromChart={true}
                        />
                    </div>
                </ReusableReportModal>
            }
            {confirmModalOpen &&
                <ReusableReportModal
                    open
                    onClose={() => setConfirmModalOpen(!confirmModalOpen)}
                    title={"Select Datasource"}>
                    <div className="mx-2 my-2 d-flex flex-col">
                        <span >
                            <p className='mb-2 text-[15px] '>Would you like to reuse a data source from another widget or select a new one? </p>
                            <p className='m-0 text-xs text-gray-400'>Choose from the existing data sources below, or add a new source for this widget.</p>
                        </span>
                        <div style={{ maxHeight: "40vh", overflow: "auto" }}
                            className="no-scrollbar"
                        >

                            {selectedDataSources
                                ?.filter((item, index, self) =>
                                    index === self.findIndex((t) => t.name === item.name)
                                )?.map((item) => {
                                    return (
                                        <div style={{ width: "40rem " }}>
                                            <div
                                                className={`d-flex justify-between  ${selectTmpToUplod?.name === item?.name
                                                    ? "bg-blue-200"
                                                    : "bg-gray-200 "
                                                    } font-medium text-sm  mt-1 px-2 py-2 cursor-pointer text-gray-700`}
                                                onClick={() => setSelectTmpToUpload({ name: item?.name, columns: item?.columns?.map((item) => item?.name) })}
                                            >
                                                <div>{item?.name}</div>
                                                <button className="px-2 py-1 font-bold text-sm text-white bg-[#105597] rounded-md" onClick={async () => {
                                                    const result = await getTableData({ name: item?.name }).unwrap();
                                                    setViewableTemplate(result?.data?.tableData);
                                                    setIsview(true)
                                                }}>view</button>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="self-end mt-2">
                            <button
                                onClick={() => {
                                    //   handleTemplateSelect({
                                    //     id: selectTmpToUplod?.id,
                                    //     name: selectTmpToUplod?.name,
                                    //     isFromData: false,
                                    //   });
                                    //  setDashboardId(null);
                                    //setIsNewDashboardActive(true);
                                    setColumnListOpen({ status: true, isFromExisting: true });
                                    setIsNewDashboardClicked(false);
                                    setConfirmModalOpen(false);
                                }}
                                className={`px-3 text-sm btn-bgcolor ml-2 py-1 font-bold text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed 
                                            ${selectTmpToUplod
                                        ? "opacity-100"
                                        : "opacity-60"
                                    }`}
                            >
                                Confirm
                            </button>
                            <button
                                className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                                onClick={() => {
                                    setIsNewDashboardClicked(true);
                                    setSelectTmpToUpload(null);
                                    setConfirmModalOpen(false);
                                }}
                            >
                                Add new
                            </button>
                        </div>
                    </div>
                </ReusableReportModal>
            }
            {isView &&
                <ReusableReportModal
                    open
                    onClose={() => setIsview(!isView)}
                    title={"Template Data"}>
                    <div className='max-w-4xl' style={{ minWidth: '45rem' }}>
                        <p className="m-0 mt-2 mb-2 font-bold whitespace-nowrap px-2 text-[15px] text-gray-500 text-center">{viewableTemplate?.name}</p>
                        <div className='max-h-96 overflow-y-auto mt-1 custom-scrollbar'>
                            <table className='w-full'>
                                {viewableTemplate?.length > 0 ?
                                    <>
                                        <thead className='bg-slate-200'>
                                            <tr className='text-center text-sm text-[#0a3a67]'>
                                                {
                                                    Object.keys(viewableTemplate?.[0])?.map((item) => (
                                                        <th className='px-3 py-1'>{item}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {viewableTemplate?.length > 0 &&
                                                viewableTemplate?.map((item, index) => (
                                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-slate-100' : 'bg-slate-200'}`}>
                                                        {Object.keys(item).map((key, subIndex) => (
                                                            <td key={subIndex} className='px-3 py-1 text-sm'>{item[key]}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                        </tbody></> :
                                    <tbody className='w-full text-gray-400 font-bold text-lg'>
                                        <div className='px-4 py-4'>No Data Available</div>
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </ReusableReportModal>
            }
            {columnListOpen?.status === true && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setColumnListOpen({ status: false, isFromExisting: false });
                        //setIsNewDashboardActive(false);
                        setSelectedColumns([])
                        setIsSelectAll(false);
                        // setIsChartView(true);
                        // setIsTableView(true);
                        setSelectTmpToUpload(null);
                        setIsNewDashboardActive(true);
                    }}
                    title="Select columns from Data Source"
                >
                    <div className="px-2 py-1 no-scrollbar" style={{ minWidth: "40rem", maxHeight: '30vh', overflow: 'auto' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSelectAll}
                                    onChange={(e) => {
                                        handleSelectAll()
                                    }}
                                    sx={{
                                        padding: '4px 12px'
                                    }}
                                />
                            }
                            label={<div className="whitespace-nowrap text-sm">Select All</div>}
                        />
                        {selectTmpToUplod?.columns?.map((item, index) => (
                            <div >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!selectedColumns[item]}
                                            onChange={() =>
                                                setSelectedColumns((prevCheckedItems) => ({
                                                    ...prevCheckedItems,
                                                    [item]: !prevCheckedItems[item], // Toggle the checkbox state
                                                }))

                                            }
                                            sx={{
                                                padding: '4px 12px'
                                            }}
                                        />
                                    }
                                    label={<div className="whitespace-nowrap text-sm">{item}</div>}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex mt-2 mb-2">
                        <button
                            onClick={() => {
                                //setIsNewDashboardActive(false);
                                // setDashboardId(null);
                                setColumnListOpen({ status: false, isFromExisting: false });
                                setSelectedColumns([])
                                setIsSelectAll(false);
                                // setIsChartView(true);
                                // setIsTableView(true);
                                setSelectTmpToUpload(null);
                                setIsNewDashboardActive(true);
                            }}
                            className={`px-3 ml-2 py-1 text-sm btn-bgcolor font-bold text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed 
                                            ${selectTmpToUplod
                                    ? "opacity-100"
                                    : "opacity-60"
                                }`}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                setConfirmModalOpen(false);
                                handleTemplateSelect({
                                    id: selectTmpToUplod?.id,
                                    name: selectTmpToUplod?.name,
                                    isFromData: false,
                                    tableView: selectedOption,
                                    isFromExisting: selectedDataSources?.length <= 0 ? false : columnListOpen?.isFromExisting
                                });
                                //setDashboardId(null);
                                setColumnListOpen({ status: false, isFromExisting: false });
                                setIsSelectAll(false);
                                // setIsTableView(true);
                                // setIsChartView(true);
                                //setIsSelectTableTypeModal(true); 

                            }}
                            className={`px-3 ml-2 py-1 font-bold text-sm btn-bgcolor text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed 
                                            ${selectTmpToUplod
                                    ? "opacity-100"
                                    : "opacity-60"
                                }`}
                        >
                            Apply
                        </button>
                    </div>
                </ReusableReportModal>
            )}
            {isNewDashboardClicked && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsNewDashboardClicked(false);
                        // setDashboardId(null);
                        // setIsNewDashboardActive(false);
                        // setIsTableView(false);
                        // setIsChartView(false);
                        // setSelectTmpToUpload(null);
                    }}
                    title={"Select Data source"}
                >
                    <div className="mx-2 my-2 d-flex flex-col">
                        <div style={{ maxHeight: "40vh", overflow: "auto" }} className="no-scrollbar" >
                            {templates?.length > 0 ? (
                                templates?.map((item) => {
                                    return (
                                        <div style={{ width: "40rem " }}>
                                            <div
                                                className={`d-flex justify-between  ${selectTmpToUplod?.name === item?.table_name
                                                    ? "bg-blue-200"
                                                    : "bg-gray-200 "
                                                    } font-medium text-sm  mt-1 px-2 py-2 cursor-pointer text-gray-700`}
                                                onClick={() => setSelectTmpToUpload({ name: item?.table_name, columns: item?.columns })}
                                            >
                                                <div>{item?.table_name}</div>
                                                <button className="px-2 py-1 font-bold text-sm text-white bg-[#105597] rounded-md" onClick={async () => {
                                                    const result = await getTableData({ name: item?.table_name }).unwrap();
                                                    setViewableTemplate(result?.data?.tableData);
                                                    setIsview(true)
                                                }}>view</button>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div
                                    className="bg-white px-2 mb-1 text-lg font-bold mt-2 text-gray-400"
                                    style={{ width: "40rem", textAlign: "center", color: '#bdbdbd6e' }}
                                >
                                    No Template Found
                                </div>
                            )}
                        </div>
                        <div className="px-2 pt-3 pb-2  d-flex ">
                            {/* <button
                                onClick={() => {
                                    setIsUploadCsv(!isUploadCsv);
                                    setIsNewDashboardClicked(false);
                                }}
                                className={`px-4 py-2 font-bold text-white rounded-md ${true ? 'bg-primaryblue' : 'bg-gray-600'
                                    } disabled:opacity-40 disabled:cursor-not-allowed`}>
                                Create New
                            </button> */}
                            <button
                                onClick={() => {
                                    //   handleTemplateSelect({
                                    //     id: selectTmpToUplod?.id,
                                    //     name: selectTmpToUplod?.name,
                                    //     isFromData: false,
                                    //   });
                                    //  setDashboardId(null);
                                    //setIsNewDashboardActive(true);
                                    setColumnListOpen({ status: true, isFromExisting: false });
                                    setIsNewDashboardClicked(false);
                                }}
                                className={`px-3 text-sm btn-bgcolor ml-2 py-2 font-bold text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed 
                                            ${selectTmpToUplod
                                        ? "opacity-100"
                                        : "opacity-60"
                                    }`}
                            >
                                Select
                            </button>
                        </div>
                    </div>
                </ReusableReportModal>
            )}
            {isCreateNewTemplate && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsCreateNewTemplate(!isCreateNewTemplate);
                        // isReportBuilder === true ? setAvailableTemplates([]) : '';
                        // setIsUploadCsv(!isUploadCsv)
                        setIsSaveAsClicked(false);
                        setIsSavedClicked(false);
                    }}
                    title={"Create New Template"}
                >
                    <div style={{ width: "30rem", height: "14rem" }}>
                        <div className="flex flex-row items-center  mt-2">
                            <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                                <p className="m-0 text-sm font-bold whitespace-nowrap">Name</p>
                                <input
                                    type="text"
                                    className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                    value={tmpName}
                                    onChange={handleNameChange}
                                />
                                <p
                                    className={`text-red-500 text-sm m-0 ${errorMessage ? "opacity-100" : "opacity-0"
                                        }`}
                                >
                                    {errorMessage}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="flex flex-col items-start justify-start w-full gap-1 py-1 pb-2 px-4">
                                <p className="m-0 font-bold text-sm whitespace-nowrap">Description</p>
                                <input
                                    type="text"
                                    className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="px-4 mt-3">
                            <button
                                disabled={!tmpName || !description || errorMessage}
                                onClick={() => {

                                    // setIsUploadCsv(false)
                                    // setIsCreateNew(true)
                                }}
                                className={`px-3 text-sm btn-bgcolor py-2 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed disabled:opacity-40`}
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </ReusableReportModal>
            )}
            {isNewDashboard && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsnewDashboard(!isNewDashboard);
                        setIsSaveAsDashboard(false);
                        setIsSaveChanges(false);
                        setIsCloseReport(false);
                        setIsGetById({ status: false, dashboardId: null })
                        setIsNewDashboardActive(true);
                    }}
                    // title={`${!isCloseReport
                    //     ? dashboardId === null || isSaveAsDashboard
                    //         ? "Create New Report"
                    //         : "Update Report"
                    //     : ""
                    //     }`}
                    title={`${isCloseReport
                        ? isSaveChanges
                            ? "Save changes ?"
                            : "Close Report ?"
                        : !isCloseReport
                            ? dashboardId === null || isSaveAsDashboard
                                ? "Create New Dashboard"
                                : "Update Dashboard"
                            : ""
                        }`}
                >
                    {isCloseReport ? (
                        <div className=" my-2 text-[15px] d-flex flex-col">
                            <div className="px-3">
                                {`${isSaveChanges || isGetById?.status == true
                                    ? "Would you like to save your current changes?"
                                    : "Closing this Dashboard will discard all changes. Proceed ?"
                                    }`}
                            </div>
                            <div className="self-end mt-3">

                                <button
                                    onClick={() => {
                                        // setIsnewDashboard(false);
                                        // setIsCloseReport(false);
                                        // if (isGetById?.status == true) {
                                        //     getDashboard(isGetById?.dashboardId);
                                        //     setIsLodingReport(true);
                                        // }
                                        // if (isSaveChanges) {
                                        //     setDashboardId(null);
                                        //     setIsNewDashboardClicked(true);
                                        //     setIsSaveChanges(false);
                                        //     setComponents([]);
                                        //     setSelectedComponent(null);
                                        //     setIsNewDashboardActive(true);
                                        //     setIsChartView(true);
                                        //     setIsTableView(true);
                                        // }
                                        setIsNewDashboardClicked(false);
                                        setIsnewDashboard(false);
                                        setDashboardId(null);
                                        setIsNewDashboardActive(true);
                                        // setIsTableView(false);
                                        // setIsChartView(false);
                                        setSelectTmpToUpload(null);

                                        const newTextboxComponent = {
                                            type: 'textbox',
                                            id: `component-${componentCount}`,
                                            properties: getDefaultProperties("textbox"),
                                        };
                                        handleComponentDrop1(newTextboxComponent);
                                        setComponentCount(prevCount => prevCount + 1);
                                        setSelectedDataSources([]);
                                    }}
                                    className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed mr-2"
                                >
                                    No
                                </button>
                                <button
                                    onClick={() => {
                                        if (isGetById?.status === true) {
                                            setIsCloseReport(false);
                                        } else {
                                            if (isSaveChanges) {
                                                setIsCloseReport(false);
                                                dashboardId === null ? setIsSaveAsDashboard(true) : "";
                                            } else {
                                                handleCloseReport();
                                            }
                                        }
                                    }}
                                    className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed mr-2"
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    ) : isSaveAsDashboard ? (
                        <>
                            <div style={{ width: "30rem", height: "14rem" }}>
                                <div className="flex flex-row items-center  mt-2">
                                    <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                                        <p className="m-0 font-bold text-sm whitespace-nowrap">Name</p>
                                        <input
                                            type="text"
                                            className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                            value={dashboardState?.name}
                                            onChange={(e) => {
                                                const { name, value } = e.target;
                                                setDashboardState((prevState) => ({
                                                    ...prevState,
                                                    name: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row items-center mt-2">
                                    <div className="flex flex-col items-start justify-start w-full gap-1 py-1 pb-2 px-4">
                                        <p className="m-0 text-sm font-bold whitespace-nowrap">
                                            Description
                                        </p>
                                        <input
                                            autoComplete="false"
                                            type="text"
                                            className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                            value={dashboardState?.description}
                                            onChange={(e) => {
                                                const { name, value } = e.target;
                                                setDashboardState((prevState) => ({
                                                    ...prevState,
                                                    description: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="pb-2 px-4 mt-3">
                                    <button
                                        disabled={!dashboardState?.name || !dashboardState?.description}
                                        onClick={async () => {
                                            await handleSaveDashboard({ parentId: dashboardId });
                                            if (isGetById?.status === true) {
                                                getDashboard(isGetById?.dashboardId);
                                                setIsLodingReport(true);
                                            }
                                            if (isNewDashboardActive) {
                                                setIsNewDashboardClicked(false);
                                                setIsnewDashboard(false);
                                                setDashboardId(null);
                                                setIsNewDashboardActive(true);
                                                // setIsTableView(false);
                                                // setIsChartView(false);
                                                setSelectTmpToUpload(null);
                                                const newTextboxComponent = {
                                                    type: 'textbox',
                                                    id: `component-${componentCount}`,
                                                    properties: getDefaultProperties("textbox"),
                                                };
                                                handleComponentDrop1(newTextboxComponent);
                                                setComponentCount(prevCount => prevCount + 1);
                                                setSelectedDataSources([]);
                                            }
                                        }}
                                        className={`px-3 text-sm btn-bgcolor py-2 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed disabled:opacity-40`}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (<div className="mx-2 my-2 d-flex flex-col">
                        <span className="text-[15px] mt-1 ">{`Would you like to update the existing Dashboard or create a new one ?`}</span>
                        <span className="self-end mt-2">
                            <button
                                onClick={() => {
                                    setIsSaveAsDashboard(true);
                                }}
                                className="px-3 text-sm btn-bgcolor py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                            >
                                Create
                            </button>
                            <button
                                onClick={() => {
                                    handleSaveDashboard({ parentId: null });
                                    if (isGetById?.status == true) {
                                        getDashboard(isGetById?.dashboardId);
                                        setIsLodingReport(true);
                                    }
                                    if (isSaveChanges) {
                                        setIsNewDashboardClicked(false);
                                        setIsnewDashboard(false);
                                        setDashboardId(null);
                                        setIsNewDashboardActive(true);
                                        // setIsTableView(false);
                                        // setIsChartView(false);
                                        setSelectTmpToUpload(null);

                                        const newTextboxComponent = {
                                            type: 'textbox',
                                            id: `component-${componentCount}`,
                                            properties: getDefaultProperties("textbox"),
                                        };
                                        handleComponentDrop1(newTextboxComponent);
                                        setComponentCount(prevCount => prevCount + 1);
                                        setSelectedDataSources([]);
                                    }
                                }}

                                className="px-3 text-sm btn-bgcolor  py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                            >
                                Save
                            </button>
                        </span>
                    </div>
                    )}
                </ReusableReportModal>
            )}
            {isInfoModelOpen && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsInfoModelOpen(!isInfoModelOpen);
                        setInfoView("T_INFO");
                    }}
                    title={`${templateInfoState?.isReport === true
                        ? "Report Information"
                        : "Template Information"
                        }`}
                >
                    <div className="max-w-lg text-[15px]" style={{ width: "32rem" }}>
                        <Tabs
                            tabs={[
                                `${templateInfoState?.isReport === true
                                    ? "Report Information "
                                    : "Template Information"
                                }`,
                                "Reference Information",
                            ]}
                            components={[
                                <span>
                                    <div className="px-4 px 4">
                                        <div className="d-flex flex-col px-1 pb-3">
                                            <span className="font-bold text-[#0a3a67] text-sm">Name :</span>
                                            <span className="font-semibold text-sm text-gray-800 ">
                                                {templateInfoState?.name}
                                            </span>
                                        </div>
                                        <div className="d-flex flex-col px-1 pb-3">
                                            <span className="font-bold text-[#0a3a67] text-sm">
                                                Description :
                                            </span>
                                            <span className="font-semibold text-sm text-gray-800">
                                                {templateInfoState?.description}
                                            </span>
                                        </div>
                                        {templateInfoState?.columns?.length > 0 && (
                                            <div className="d-flex flex-col px-1 pb-1">
                                                <span className="font-bold text-[#0a3a67] text-sm">
                                                    Columns :
                                                </span>
                                                <span className="font-semibold text-sm text-gray-800">
                                                    {templateInfoState?.columns?.map((item, index) =>
                                                        index === templateInfoState?.columns?.length - 1
                                                            ? item
                                                            : item + ", "
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </span>,
                                <span>
                                    <div className="px-4 px 4">
                                        {templateInfoState?.parentId ? (
                                            <>
                                                <div className="d-flex flex-col px-1 pb-3">
                                                    <span className="font-bold text-[#0a3a67] text-sm">
                                                        Name :
                                                    </span>
                                                    <span className="font-semibold text-gray-800 text-sm">
                                                        {templateInfoState?.isReport == true
                                                            ? myDashboards?.find(
                                                                (item) =>
                                                                    item?.id === templateInfoState?.parentId
                                                            )?.name
                                                            : templates?.find(
                                                                (item) =>
                                                                    item?.id === templateInfoState?.parentId
                                                            )?.name}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-col px-1 pb-3">
                                                    <span className="font-bold text-[#0a3a67] text-sm">
                                                        Description :
                                                    </span>
                                                    <span className="font-semibold  text-sm text-gray-800">
                                                        {templateInfoState?.isReport == true
                                                            ? myDashboards?.find(
                                                                (item) =>
                                                                    item?.id === templateInfoState?.parentId
                                                            )?.description
                                                            : templates?.find(
                                                                (item) =>
                                                                    item?.id === templateInfoState?.parentId
                                                            )?.description}
                                                    </span>
                                                </div>
                                                {templateInfoState?.isReport === false && (
                                                    <div className="d-flex flex-col px-1 pb-1">
                                                        <span className="font-bold text-[#0a3a67] text-sm">
                                                            Columns :
                                                        </span>
                                                        <span className="font-semibold  text-sm text-gray-800">
                                                            {templates
                                                                ?.find(
                                                                    (item) =>
                                                                        item?.id === templateInfoState?.parentId
                                                                )
                                                                ?.columns?.map((item, index) =>
                                                                    index ===
                                                                        templateInfoState?.columns.length - 1
                                                                        ? item
                                                                        : item + ", "
                                                                )}
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="font-semibold text-gray-400 px-2 py-4 text-center">
                                                {`${templateInfoState?.isReport !== true
                                                    ? "No reference template found."
                                                    : "No reference report found."
                                                    }`}
                                            </div>
                                        )}
                                    </div>
                                </span>,
                            ]}
                            value={infoview}
                            onChange={(e, newValue) => {
                                setInfoView(newValue);
                            }}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                            className="mb-1"
                        ></Tabs>
                    </div>
                </ReusableReportModal>
            )}
            {isFilterModelOpen && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsFilterModelOpen(!isFilterModelOpen);
                    }}
                    title={"Filters"}
                >
                    <span className="flex flex-col  gap-3 ml-4 mr-4 max-w-[600px] min-w-[400px]" >
                        <span className="d-flex mt-3  space-x-4 justify-start ">
                            <p className="m-0  whitespace-nowrap prop-label self-center">
                                Select Datasource:
                            </p>
                            <select
                                value={selectedDSToFilter?.name || -1}
                                onChange={(e) => {
                                    const selectedItem = selectedDataSources.find(item => item.name === e.target.value);
                                    setSelectedDSToFilter(selectedItem);
                                    const hasName = selectedDataSources?.some((i) => i?.name === e.target.value)
                                    if (dataSourcesName?.length > 0 && hasName) {
                                        setIsModalOpen(true);
                                    }
                                }}
                                className="block  cutom-scrollbar p-1 text-xs w-[150px] text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value={-1}>Select Datasource</option>
                                {selectedDataSources?.map((item, index) => (
                                    <option key={index} value={item?.name}>
                                        {item?.name}
                                    </option>
                                ))}
                            </select>
                        </span>
                        <span className="d-flex space-x-4 justify-start lg:ml-[22px] sm:ml-[22px]">
                            <p className="m-0  whitespace-nowrap prop-label self-center">
                                Select Column:
                            </p>
                            <select
                                value={columnToFilter?.name}
                                onChange={(e) => {
                                    // console.log(e?.target?.value)
                                    // const col = selectedDSToFilter?.columns?.find((item) => item?.name === e?.target?.value);
                                    // console.log(col, ">>> Seelcte Filter column");
                                    // setColumnToFilter({ name: col?.name, type: col?.type });
                                    handleSelectColumnToFilter(e?.target?.value);
                                }}
                                className="block  p-1 text-xs w-[150px] text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value={-1}>Select Column</option>
                                {selectedDSToFilter?.columns?.map((item, index) => (
                                    <option key={index} value={item?.name} >
                                        {item?.name}
                                    </option>
                                ))}
                            </select>
                        </span>
                        {columnToFilter?.name !== null && columnToFilter?.name !== undefined && columnToFilter?.name !== "" &&
                            <span className="d-flex gap-3 justify-start lg:ml-[29px] sm:ml-[29px]">
                                <p className="m-0 whitespace-nowrap  prop-label self-center">
                                    Column Filter:
                                </p>
                                <span className="flex relative flex-col mx-0 h-auto" ref={dropdownRef}>
                                    <span className="flex items-center relative" onClick={handleToggleDropdown}
                                    >
                                        <input
                                            type="text"
                                            // value={columnToFilter?.name}
                                            value={filterObj?.values.filter(item => (typeof item == 'object' || typeof item == 'boolean' ? JSON.stringify(item) : item)).join(', ') || ''}
                                            className="w-full pl-2 pr-10  text-xs text-[#374151] h-7 rounded border-custome cursor-pointer"
                                            readOnly
                                            placeholder="Select Values "
                                        />
                                        <KeyboardArrowDownIcon
                                            className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                            style={{ color: 'black' }}
                                        />
                                    </span>
                                    {isDropdownOpen && (
                                        <div
                                            className="absolute flex flex-col w-full overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-8 z-10"
                                            style={{
                                                boxShadow:
                                                    "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                                            }}
                                        >
                                            <div className="hover:bg-primarylightgrey d-flex px-2 py-2 items-center gap-2">
                                                <span className="relative w-full prop-label">
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        className="w-full pl-2 pr-10 py-1 rounded border-custome text-[#374151]"
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />
                                                    <SearchIcon
                                                        className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                                        style={{ color: '#374151' }}
                                                    />
                                                </span>
                                            </div>
                                            {filteredItems?.map((item, index) => (
                                                <div
                                                    className={classNames(
                                                        "hover:bg-primarylightgrey",
                                                        columnToFilter?.colData?.length - 1 &&
                                                        "border-b",
                                                        "d-flex px-2 py-2 items-center gap-2"
                                                    )}
                                                >
                                                    <label className="flex flex-row items-center gap-2 cursor-pointer">
                                                        <input
                                                            type="checkbox"
                                                            className="w-4 h-4 checked:lightblue"
                                                            checked={
                                                                filterObj?.values.includes(item) ? true : false
                                                            }
                                                            // onChange={() => {
                                                            //   if (filterObj?.values?.includes(item)) {
                                                            //     setFilterObj({ ...filterObj, values: filterObj?.values?.filter((val) => val !== item) })
                                                            //   } else {
                                                            //     setFilterObj({ ...filterObj, values: [...filterObj.values, item] })
                                                            //   }
                                                            // }}
                                                            onChange={() => handleCheckboxChange(typeof item == 'object' ? JSON.stringify(item) : item)}
                                                        />
                                                        <p
                                                            className={classNames(
                                                                "m-0 cursor-pointer text-sm font-base"
                                                            )}
                                                        >
                                                            {typeof item == 'object' ? JSON.stringify(item) : typeof item === 'boolean' ? JSON.stringify(item) : item}
                                                        </p>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </span>
                            </span>
                        }
                        {columnToFilter?.type === "DATE" &&
                            <span className="d-flex justify-start gap-3 lg:ml-[41px] sm:ml-[41px]">
                                <p className="m-0 whitespace-nowrap prop-label self-center ">
                                    Date Range:
                                </p>
                                <div className='d-flex flex-row justify-center filter-date '>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateRangePicker']}>
                                            <DateRangePicker
                                                localeText={{ start: '', end: '' }}
                                                calendars={2}
                                                value={dateRange}
                                                onChange={(newValue) => {
                                                    setDateRange(newValue);
                                                    setFilterObj({ ...filterObj, from: new Date(newValue[0]), to: new Date(newValue[1]) })
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </span>}
                        {columnToFilter?.type === "VARCHAR" &&
                            <span className="d-flex justify-start  space-x-4 lg:ml-[52px] sm:ml-[50px] ">
                                <p className="m-0  whitespace-nowrap prop-label self-center">
                                    Condition:
                                </p>
                                <span className="flex gap-2">
                                    <select
                                        value={filterObj?.condition}
                                        onChange={(e) => {
                                            setFilterObj({ ...filterObj, condition: e?.target?.value });
                                        }}
                                        className="block lg:w-fit sm:w-fit p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                                    >
                                        <option value={-1}>Select Condition</option>
                                        {stringTypeConditions.map((item, index) => (
                                            <option key={index} value={item?.value}>
                                                {item?.label}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                        value={filterObj?.conditionValue || ""}
                                        onChange={(e) => {
                                            setFilterObj({ ...filterObj, conditionValue: e?.target?.value })
                                        }}
                                    />
                                </span>
                            </span>}
                        {columnToFilter?.type === "INTEGER" && <>
                            <span className="d-flex justify-start  space-x-3 lg:ml-[71px] sm:ml-[70px] ">
                                <p className="m-0 prop-label whitespace-nowrap  self-center">
                                    Range:
                                </p>
                                <span className="d-flex w-[100px]">
                                    <p className="my-0 ml-1 mr-0 prop-label whitespace-nowrap self-center">
                                        {columnToFilter?.min}
                                    </p>
                                    <Tooltip title={rangeValue} placement="top">
                                        <input type="range"
                                            min={columnToFilter?.min}
                                            max={
                                                columnToFilter?.min === columnToFilter?.max
                                                    ? columnToFilter?.max + 1 // Slightly increase the max if min and max are the same
                                                    : columnToFilter?.max
                                            }
                                            className="custom-range mx-2 self-center"
                                            style={{
                                                background: `linear-gradient(to right,#0a3a67 ${calculateFillPercentage()}, #ddd ${calculateFillPercentage()})`,
                                                opacity: columnToFilter?.min === columnToFilter?.max ? 0.5 : 1,
                                                pointerEvents: columnToFilter?.min === columnToFilter?.max ? 'none' : 'auto',
                                            }}
                                            value={columnToFilter?.min === columnToFilter?.max ? rangeValue + 1 : rangeValue}
                                            onChange={handleRangeChange}
                                            disabled={columnToFilter?.min === columnToFilter?.max}
                                        />
                                    </Tooltip>
                                    <p className="m-0 prop-label whitespace-nowrap self-center">
                                        {" "}{columnToFilter?.max}
                                    </p>
                                </span>
                            </span>
                            <span className="d-flex justify-start  space-x-4 lg:ml-[51px] sm:ml-[50px]">
                                <p className="m-0  whitespace-nowrap prop-label self-center">
                                    Condition:
                                </p>
                                <span className="flex gap-2">
                                    <select
                                        value={filterObj?.condition}
                                        onChange={(e) => {
                                            setFilterObj({ ...filterObj, condition: e?.target?.value });
                                        }}
                                        className="block  p-1 lg:w-fit sm:w-fit text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                                    >
                                        <option value={-1}>Select Condition</option>
                                        {numericTypeConditions.map((item, index) => (
                                            <option key={index} value={item?.value}>
                                                {item?.label}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="number"
                                        value={filterObj?.conditionValue || ""}
                                        onChange={(e) => {
                                            setFilterObj({ ...filterObj, conditionValue: e?.target?.value })
                                        }}
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </span>
                            </span>
                            {/* <span className="d-flex lg:justify-center sm:justify-start py-2 space-x-4 lg:mr-[76px] lg:ml-0 sm:ml-[35px] mb-2">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Format As:
              </p>
              <select
                value={component?.properties?.formatType || "Currency"}
                onChange={(e) => {
                  onPropertyChange({ formatType: e.target.value });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      formatType: e.target.value,
                    },
                  }));
                  setFlag(false);
                }}
                className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={-1}>Select Format</option>
                {["Currency", "Percentage", "Duration", "Date/Time"].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </span> */}
                        </>}
                        <span className="flex justify-end  mt-2 gap-2 mb-2">
                            <button
                                onClick={() => {
                                    // clearFilter({ isClearAll: true });
                                    dataSourcesName?.length <= 1 ? clearFilter({ isClearAll: true }) : clearAllFilters();
                                    //  setSelectedDSToFilter(null);
                                }}
                                className="px-4 text-sm btn-bgcolor  py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed  "
                                disabled={dataSourcesName?.length === 0}
                            >
                                Clear
                            </button>
                            <button
                                onClick={async () => {
                                    try {
                                        console.log(filterObj, ">>>. Ffghhghh");
                                        console.log(selectedDSToFilter?.name, ">>>> dhgfh duhuhuhuh")
                                        const apiResult = await filterData({ ...filterObj, tableName: selectedDSToFilter?.name, columnType: filterObj?.columnType === 'BOOLEAN' ? 'VARCHAR' : filterObj?.columnType }).unwrap();

                                        await applyFilter(apiResult);
                                        // setDataSourcesName([{ name: selectedDSToFilter?.name, column: filterObj?.columanName }])
                                        setDataSourcesName((prev) => {
                                            const hasName = prev.some(item => item?.name === selectedDSToFilter?.name);

                                            const result = !hasName ? [...prev, { name: selectedDSToFilter?.name, column: filterObj?.columnName }] : prev;
                                            return result;
                                        })
                                        setIsFilterModelOpen(false);
                                    } catch (err) {
                                        showAlert(err?.data?.message, "error");
                                    }
                                }}
                                className="px-4 text-sm btn-bgcolor  py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed"
                                disabled={filterObj === null}
                            >
                                Apply
                            </button>
                        </span>
                    </span>
                </ReusableReportModal >
            )}
            <Snackbar
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={5000}
                message={message ? message : "Something went wrong"}
                severity="error"
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity={severity ? severity : "error"}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message ? message : "Something went wrong"}
                </Alert>
            </Snackbar>
            {
                isUploadCsv && (
                    <UploadCSVModal
                        setIsUploadCsv={setIsUploadCsv}
                        isUploadCsv={isUploadCsv}
                        handleFileChange={handleChange}
                        tmpName={tmpName}
                        setTmpName={setTmpName}
                        description={description}
                        setDescription={setDescription}
                        selectedTmp={selectedTmp}
                        setselectedTmp={setselectedTmp}
                        templates={templates}
                        isReportBuilder={true}
                        availableTemplates={availableTemplates}
                        setAvailableTemplates={setAvailableTemplates}
                        isCreateNewTemplate={isCreateNewTemplate}
                        setIsCreateNewTemplate={setIsCreateNewTemplate}
                    />
                )
            }
            <DndProvider backend={HTML5Backend}>
                <div className="report-builder">
                    <div className="d-flex  w-full mt-2">
                        <div
                            className={`sidebar menu-main ${true ? "menu-show" : "menu-hide"}  no-scrollbar d-flex flex-col ${isMenuExpand ? 'min-w-64' : 'min-w-16'} overflow-auto`}
                            style={{
                                height: "87vh",
                                backgroundColor: `${menuBgColor}`
                            }}
                        >
                            <span className="main-menu-header  px-2" style={{ justifyContent: isMenuExpand ? 'end' : 'center' }}>
                                <DehazeIcon
                                    className="py-2 cursor-pointer "
                                    style={{
                                        color: '#fff',
                                        fontSize: '40px',
                                        width: '40px !important'
                                    }}
                                    onClick={() => {
                                        setIsMenuExpand(!isMenuExpand);
                                        setOpenSection(null);
                                    }}
                                />
                            </span>
                            <SidebarSection
                                title="Dashboards"
                                isMenuExpand={isMenuExpand}
                                isOpen={openSection === 'Reports'}
                                onClick={() => {
                                    handleSectionClick('Reports')
                                    setIsMenuExpand(true);
                                }}
                                icon={<DashboardIcon />}
                            >
                                <div
                                    className="d-flex justify-between items-center  border-bottom"
                                    onClick={() => setIsMyreportOpen(!isMyreportOpen)}
                                >
                                    <div
                                        className={`px-3 py-2 cursor-pointer text-sm font-semibold text-white`}
                                        onClick={() => setIsActiveDataSource(true)}
                                    >
                                        My Dashboards
                                    </div>
                                    {isMyreportOpen ? (
                                        <KeyboardArrowUpIcon className="mr-2 text-white cursor-pointer" />
                                    ) : (
                                        <KeyboardArrowDownIcon className="mr-2 text-white cursor-pointer" />
                                    )}
                                </div>
                                {isMyreportOpen &&
                                    <>
                                        {myDashboards?.length > 0 ? (
                                            <ul
                                                className="mb-1 overflow-auto no-scrollbar data-list-ul">
                                                {myDashboards.map((item) => (
                                                    <li
                                                        onClick={() => {
                                                            // if (dashboardId !== null && dashboardId !== item?.id) {
                                                            // setIsGetById({ status: true, dashboardId: item?.id })
                                                            //  setIsCloseReport(true);
                                                            //setIsnewReport(true);
                                                            // } else {
                                                            // dashboardId !== item?.id && getDashboard(item?.id);
                                                            // dashboardId !== item?.id && setIsLodingReport(true);
                                                            // // }
                                                            // dashboardId !== item?.id && setDataSourcesName([])
                                                            if (dashboardId !== item?.id) {
                                                                getDashboard(item?.id);
                                                                setIsLodingReport(true);
                                                                setDataSourcesName([]);
                                                                setSelectedDSToFilter(null);
                                                                setFilterObj(null);
                                                                setColumnToFilter(null);
                                                                setSearchQuery("");

                                                            }
                                                        }}
                                                        className={`data-list ${dashboardId === item?.id ? 'text-[#b0c4df]' : 'text-[#fff]'}`}
                                                    >
                                                        ↪ {item?.name}
                                                        <MoreVertIcon
                                                            className="text-sm info-icon"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleMenuOpen(e, item);
                                                            }}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <span className=" px-3 mb-1 text-sm font-bold " style={{ color: '#bdbdbd6e' }}
                                            >
                                                No Dashboard Found.
                                            </span>
                                        )}
                                    </>
                                }
                                <div
                                    className={`px-3  py-2 cursor-pointer  text-sm font-semibold text-white
                                         ${isNewDashboardActive ? " " : ""} `}
                                    // onClick={() => {
                                    //     // handleNewReport();
                                    //     setSelectedTemplate(null);
                                    //     if (
                                    //         (isNewDashboardActive || dashboardId !== null) &&
                                    //         components?.length > 0
                                    //     ) {
                                    //         setIsnewDashboard(true);
                                    //         setIsSaveChanges(true);
                                    //         setIsCloseReport(true);
                                    //     } else {
                                    //         setIsNewDashboardActive(true);
                                    //         setIsNewDashboardClicked(true);
                                    //         setComponents([]);
                                    //         setSelectedComponent(null);
                                    //         setDashboardId(null);
                                    //     }
                                    // }}
                                    onClick={() => {
                                        // handleNewReport();
                                        setSelectedTemplate(null);
                                        if (
                                            (isNewDashboardActive || dashboardId !== null) &&
                                            components?.length > 0
                                        ) {
                                            setIsnewDashboard(true);
                                            setIsSaveChanges(true);
                                            setIsCloseReport(true);
                                        } else {
                                            handleComponentDrop1({
                                                type: 'textbox',
                                                id: `component-${componentCount}`,
                                                properties: getDefaultProperties("textbox")
                                            });
                                            setIsNewDashboardActive(true);
                                            setSelectedDataSources([]);
                                        }
                                    }}
                                >
                                    New Dashboard
                                </div>
                            </SidebarSection>
                            {/* <SidebarSection title="Data Source"
                                isMenuExpand={isMenuExpand}
                                isOpen={openSection === 'Data'}
                                onClick={() => {
                                    setIsMenuExpand(true);
                                    handleSectionClick('Data')
                                }}
                                icon={<TableViewOutlinedIcon />}>
                            </SidebarSection> */}
                            <SidebarSection
                                title="Widgets"
                                isMenuExpand={isMenuExpand}
                                isOpen={openSection === 'Components'}
                                onClick={() => {
                                    setIsMenuExpand(true);
                                    handleSectionClick('Components')
                                }}
                                className="d-fex flex-col"
                                icon={<AppsOutlinedIcon />}
                            >
                                <div className="d-flex justify-evenly bg-[#105597] ">
                                    <div className="d-flex justify-center w-50 grow"
                                        onClick={
                                            selectedTemplate !== null || !isTableView ||
                                                isDisableTable ||
                                                (dashboardId === null && !isNewDashboardActive && selectedTemplate === null)
                                                ? null
                                                : () => {
                                                    handleComponentDrop({
                                                        type: "table",
                                                        id: `component-${componentCount}`,
                                                        properties: getDefaultProperties("table")
                                                    });
                                                }
                                        }
                                    >
                                        <DraggableComponent
                                            // disabled={
                                            //     selectedTemplate !== null || !isTableView ||
                                            //     (dashboardId === null &&
                                            //         !isNewDashboardActive &&
                                            //         selectedTemplate === null)
                                            // }
                                            disabled={
                                                (dashboardId === null && !isNewDashboardActive)
                                            }
                                            type="table"
                                            id={`component-${componentCount}`}
                                            properties={getDefaultProperties("table")}
                                        >
                                            <div className="d-flex  flex-col items-center"
                                            // onClick={
                                            //     selectedTemplate !== null || !isTableView || isDisableTable ||
                                            //         (dashboardId === null && !isNewDashboardActive && selectedTemplate === null)
                                            //         ? null
                                            //         : () => {
                                            //             handleComponentDrop({
                                            //                 type: "table",
                                            //                 id: `component-${componentCount}`,
                                            //                 properties: getDefaultProperties("table")
                                            //             });
                                            //         }
                                            // }
                                            > <Tooltip
                                                title={
                                                    (dashboardId === null && !isNewDashboardActive && selectedTemplate === null)
                                                        ? "You can't drag the table. Please start a new dashboard to enable dragging."
                                                        : "You can drag or click the table to select it."
                                                }
                                                className="whitespace-nowrap"
                                            >
                                                    <TableChartIcon sx={{ fontSize: 25, color: '#fff' }} />
                                                </Tooltip>
                                                <span className="text-xs  font-semibold text-white">
                                                    Table
                                                </span>
                                            </div>
                                        </DraggableComponent>
                                    </div>
                                    <div className="d-flex justify-center w-50 grow"
                                        onClick={
                                            selectedTemplate !== null || !isChartView ||
                                                (dashboardId === null && !isNewDashboardActive && selectedTemplate === null)
                                                ? null
                                                : () => {
                                                    handleComponentDrop({
                                                        type: 'chart',
                                                        id: `component-${componentCount}`,
                                                        properties: getDefaultProperties("chart")
                                                    });
                                                }
                                        }
                                    >
                                        <DraggableComponent
                                            // disabled={
                                            //     selectedTemplate !== null || !isChartView ||
                                            //     (dashboardId === null &&
                                            //         !isNewDashboardActive &&
                                            //         selectedTemplate === null)
                                            // }
                                            disabled={
                                                (dashboardId === null && !isNewDashboardActive)
                                            }
                                            type="chart"
                                            className="d-flex justify-center w-50 grow"
                                            id={`component-${componentCount}`}
                                            properties={getDefaultProperties("chart")}
                                        >
                                            <div className={`d-flex flex-col  items-center `}
                                            // onClick={
                                            //     selectedTemplate !== null || !isChartView ||
                                            //         (dashboardId === null && !isNewDashboardActive && selectedTemplate === null)
                                            //         ? null
                                            //         : () => {
                                            //             handleComponentDrop({
                                            //                 type: 'chart',
                                            //                 id: `component-${componentCount}`,
                                            //                 properties: getDefaultProperties("chart")
                                            //             });
                                            //         }
                                            // }
                                            >
                                                <Tooltip
                                                    title={
                                                        !isChartView
                                                            ? "Chartview is closed"
                                                            : (dashboardId === null && !isNewDashboardActive && selectedTemplate === null)
                                                                ? "You can't drag the chart. Please start a new dashboard to enable dragging."
                                                                : "You can drag or click the Chart to select it."
                                                    }
                                                >
                                                    <BarChartIcon sx={{ fontSize: 25, color: '#fff' }} />
                                                </Tooltip>
                                                <span className="text-xs font-semibold text-white">
                                                    Chart
                                                </span>
                                            </div>
                                        </DraggableComponent>
                                    </div>
                                </div>
                                <div className="d-flex justify-evenly bg-[#105597]">
                                    <div className="d-flex justify-center w-50 grow"
                                        onClick={
                                            selectedTemplate !== null ||
                                                (dashboardId === null &&
                                                    !isNewDashboardActive &&
                                                    selectedTemplate === null)
                                                ? null // Disable onClick when the conditions match
                                                : () => handleComponentDrop({
                                                    type: 'textbox',
                                                    id: `component-${componentCount}`,
                                                    properties: getDefaultProperties("textbox")
                                                })
                                        }
                                    >
                                        <DraggableComponent
                                            //  disabled={selectedTemplate !== null}
                                            // disabled={
                                            //     selectedTemplate !== null ||
                                            //     (dashboardId === null &&
                                            //         !isNewDashboardActive &&
                                            //         selectedTemplate === null)
                                            // }
                                            disabled={
                                                (dashboardId === null && !isNewDashboardActive)
                                            }
                                            type="textbox"
                                            className="d-flex justify-center w-50 grow"
                                            id={`component-${componentCount}`}
                                            properties={getDefaultProperties("textbox")}
                                        >
                                            <div className="d-flex flex-col cursor-grab items-center"
                                            // onClick={
                                            //     selectedTemplate !== null ||
                                            //         (dashboardId === null &&
                                            //             !isNewDashboardActive &&
                                            //             selectedTemplate === null)
                                            //         ? null // Disable onClick when the conditions match
                                            //         : () => handleComponentDrop({
                                            //             type: 'textbox',
                                            //             id: `component-${componentCount}`,
                                            //             properties: getDefaultProperties("textbox")
                                            //         })
                                            // }
                                            >
                                                <Tooltip
                                                    // title={`${(dashboardId === null &&
                                                    // !isNewDashboardActive &&
                                                    //     selectedTemplate === null) ? "You can't drag the Text; no datasource selected." : ""}`}
                                                    title={
                                                        (dashboardId === null && !isNewDashboardActive && selectedTemplate === null)
                                                            ? "You can't drag the text box. Please start a new dashboard to enable dragging."
                                                            : "You can drag or click the Textbox to select it."
                                                    }
                                                >
                                                    <TextFieldsIcon sx={{ fontSize: 25, color: '#fff' }} />
                                                </Tooltip>
                                                <span className="text-xs font-semibold text-white">
                                                    Text
                                                </span>
                                            </div>
                                        </DraggableComponent>
                                    </div>
                                    <div className="d-flex justify-center w-50 grow"
                                        onClick={
                                            selectedTemplate !== null ||
                                                (dashboardId === null &&
                                                    !isNewDashboardActive &&
                                                    selectedTemplate === null)
                                                ? null // Disable onClick if the component is disabled
                                                : () => {
                                                    handleComponentDrop({
                                                        type: "image",
                                                        id: `component-${componentCount}`,
                                                        properties: getDefaultProperties("image")
                                                    });
                                                }
                                        }
                                    >
                                        <DraggableComponent
                                            // disabled={
                                            //     selectedTemplate !== null ||
                                            //     (dashboardId === null &&
                                            //         !isNewDashboardActive &&
                                            //         selectedTemplate === null)
                                            // }
                                            disabled={
                                                (dashboardId === null && !isNewDashboardActive)
                                            }
                                            type="image"
                                            className="d-flex justify-center w-50 grow"
                                            id={`component-${componentCount}`}
                                            properties={getDefaultProperties("image")}
                                        >
                                            <div className="d-flex flex-col cursor-grab items-center"
                                            // onClick={
                                            //     selectedTemplate !== null ||
                                            //         (dashboardId === null &&
                                            //             !isNewDashboardActive &&
                                            //             selectedTemplate === null)
                                            //         ? null // Disable onClick if the component is disabled
                                            //         : () => {
                                            //             handleComponentDrop({
                                            //                 type: "image",
                                            //                 id: `component-${componentCount}`,
                                            //                 properties: getDefaultProperties("image")
                                            //             });
                                            //         }
                                            // }
                                            >
                                                <Tooltip
                                                    // title={`${(dashboardId === null &&
                                                    // !isNewDashboardActive &&
                                                    //     selectedTemplate === null) ? "You can't drag the Image; no datasource selected." : ""}`}
                                                    title={
                                                        selectedTemplate !== null ||
                                                            (dashboardId === null &&
                                                                !isNewDashboardActive &&
                                                                selectedTemplate === null)
                                                            ? "You can't drag the image. Please start a new dashboard to enable dragging."
                                                            : "You can drag or click the image to select it."
                                                    }
                                                >
                                                    <ImageIcon sx={{ fontSize: 25, color: '#fff' }} />
                                                </Tooltip>
                                                <span className="text-xs font-semibold text-white">
                                                    Image
                                                </span>
                                            </div>
                                        </DraggableComponent>
                                    </div>

                                </div>
                            </SidebarSection>
                        </div>
                        <div className="main-content grow w-4/5 mx-2 overflow-auto">
                            {/* <div className="d-flex gap-1 mb-1 text-body-secondary justify-end">
                                <span
                                    className="report-builder-nav-icon"
                                >
                                    <Tooltip title={"Filter"}  >
                                        <TuneIcon />
                                    </Tooltip>
                                </span>
                                <span
                                    className="report-builder-nav-icon"
                                    onClick={() => {
                                        setIsnewDashboard(true);
                                        if (dashboardId === null) {
                                            setIsSaveAsDashboard(true);
                                        }
                                    }}
                                >
                                    <Tooltip title={"Save"}  >
                                        <SaveOutlinedIcon />
                                    </Tooltip>
                                </span>
                                <span
                                    className="report-builder-nav-icon"
                                    onClick={() => {
                                        setIsnewDashboard(true);
                                        setIsSaveAsDashboard(true);
                                    }}
                                >
                                    <Tooltip title={"Save As"}  >
                                        <SaveAsOutlinedIcon />
                                    </Tooltip>
                                </span>
                                <button
                                    className="py-1 px-4 text-white text-sm font-bold btn-bgcolor rounded-md disabled:opacity-60 "
                                >
                                    Export
                                </button>
                            </div> */}
                            <div className="d-flex gap-1 mb-1 text-body-secondary justify-between  mt-2 pl-2 pr-2">
                                <span className="d-flex gap-1">
                                    {/* <span
                                        className="report-builder-nav-icon"
                                        onClick={() => {
                                            setIsnewDashboard(true);
                                            if (dashboardId === null) {
                                                setIsSaveAsDashboard(true);
                                            }
                                            setIsCloseReport(false);
                                            setIsNewDashboardActive(false);
                                        }}
                                    >
                                        <Tooltip title={"Save"}  >
                                            <SaveOutlinedIcon />
                                        </Tooltip>
                                    </span>
                                    <span
                                        className="report-builder-nav-icon"
                                        onClick={() => {
                                            setIsnewDashboard(true);
                                            setIsSaveAsDashboard(true);
                                            setIsNewDashboardActive(false);
                                        }}
                                    >
                                        <Tooltip title={"Save As"}  >
                                            <SaveAsOutlinedIcon />
                                        </Tooltip>
                                    </span> */}
                                    <span
                                        className="report-builder-nav-icon"
                                        onClick={() => {
                                            if (isNewDashboardActive || dashboardId !== null) {
                                                setIsnewDashboard(true);
                                                if (dashboardId === null) {
                                                    setIsSaveAsDashboard(true);
                                                }
                                                setIsCloseReport(false);
                                                setIsNewDashboardActive(false);
                                            }
                                        }}
                                        style={{
                                            pointerEvents: (isNewDashboardActive || dashboardId !== null) ? 'auto' : 'none',
                                            opacity: (isNewDashboardActive || dashboardId !== null) ? 1 : 0.5,
                                        }}
                                    >
                                        <Tooltip title={"Save"}>
                                            <SaveOutlinedIcon />
                                        </Tooltip>
                                    </span>

                                    <span
                                        className="report-builder-nav-icon"
                                        onClick={() => {
                                            if (isNewDashboardActive || dashboardId !== null) {
                                                setIsnewDashboard(true);
                                                setIsSaveAsDashboard(true);
                                                setIsNewDashboardActive(false);
                                            }
                                        }}
                                        style={{
                                            pointerEvents: (isNewDashboardActive || dashboardId !== null) ? 'auto' : 'none',
                                            opacity: (isNewDashboardActive || dashboardId !== null) ? 1 : 0.5,
                                        }}
                                    >
                                        <Tooltip title={"Save As"}>
                                            <SaveAsOutlinedIcon />
                                        </Tooltip>
                                    </span>
                                    <span
                                        className="report-builder-nav-icon"
                                        onClick={(e) => {
                                            if (selectedDataSources?.length > 0) {
                                                setIsFilterModelOpen(!isFilterModelOpen);
                                            }
                                        }}
                                        style={{
                                            pointerEvents: selectedDataSources?.length > 0 ? 'auto' : 'none',
                                            opacity: selectedDataSources?.length > 0 ? 1 : 0.5,
                                        }}
                                    >
                                        <Tooltip title={"Filter"}>
                                            <TuneIcon />
                                        </Tooltip>
                                    </span>
                                    <span
                                        className="report-builder-nav-icon"
                                        onClick={() => {
                                            // handleNewReport();
                                            setSelectedTemplate(null);
                                            if (
                                                (isNewDashboardActive || dashboardId !== null) &&
                                                components?.length > 0
                                            ) {
                                                setIsnewDashboard(true);
                                                setIsSaveChanges(true);
                                                setIsCloseReport(true);
                                            } else {
                                                handleComponentDrop1({
                                                    type: 'textbox',
                                                    id: `component-${componentCount}`,
                                                    properties: getDefaultProperties("textbox")
                                                });
                                                setIsNewDashboardActive(true);
                                                setSelectedDataSources([]);
                                                // setIsNewDashboardClicked(true);
                                                // setComponents([]);
                                                // setSelectedComponent(null);
                                                // setDashboardId(null);
                                            }
                                        }}
                                    >
                                        <Tooltip title={"New Dashboard"}  >
                                            <DashboardCustomizeOutlinedIcon />
                                        </Tooltip>
                                    </span>
                                    {/* <span
                                        className="report-builder-nav-icon"
                                        onClick={async () => {
                                            setIsTableView(!isTableView);
                                            setSelectedComponent(null);
                                        }}
                                    >
                                        {isTableView ?
                                            <Tooltip title={"Table View"}  >
                                                <TableChartOutlinedIcon />
                                            </Tooltip>
                                            : <NotTable height='20px' width='20px' fill='#0a3a67' />
                                        }
                                    </span>
                                    <span
                                        className="report-builder-nav-icon"
                                        onClick={async () => {
                                            setIsChartView(!isChartView);
                                            setSelectedComponent(null);
                                        }}
                                    >
                                        {isChartView ?
                                            <Tooltip title={"Chart View"}  >
                                                <BarChartIcon />
                                            </Tooltip>
                                            : <NotChart height='20px' width='20px' fill='#0a3a67' />
                                        }
                                    </span> */}
                                </span>
                                <span className="flex gap-1 properties-panel-scrollbar">
                                    <Box className="d-flex align-items-center" sx={{ flexGrow: 0 }}>
                                        <button
                                            onClick={handleOpenUserMenu}
                                            style={{
                                                opacity: isNewDashboardActive || dashboardId !== null ? 1 : 0.5,
                                                pointerEvents: isNewDashboardActive || dashboardId !== null ? 'auto' : 'none',
                                            }}
                                            className="py-1 px-4 text-white text-sm font-bold btn-bgcolor rounded-md disabled:opacity-60"
                                        >
                                            Export
                                        </button>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorElOption}
                                            open={Boolean(anchorElOption)}
                                            onClose={handleCloseUserMenu}
                                            className="exportmenu mt-1"
                                        >
                                            {settings.map(({ name, id }) => (
                                                <MenuItem key={id} onClick={() => handleCloseUserMenu({ id })}>
                                                    <Typography className="mx-2" textAlign="center">
                                                        {name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                    <React.Fragment key="right">
                                        <Button
                                            // className="position-fixed  top-[70px] end-2 min-w-12 disabled:opacity-60 pt-0 pr-1 pb-0 pl-1"
                                            className="py-1 px-1 disabled:opacity-60  min-w-12 "
                                            onClick={toggleDrawer(true)}
                                            sx={{
                                                minWidth: "50px",
                                                backgroundColor: '#10559738 !important'
                                            }}
                                            disabled={components.length === 0 || selectedComponent === null || selectedComponent?.properties?.data?.length <= 0}
                                        >
                                            <SettingsIcon
                                                sx={{
                                                    color: "#105597",
                                                    fontSize: "20px",
                                                    animation:
                                                        components.length > 0 && selectedComponent !== null && selectedComponent?.properties?.data?.length > 0
                                                            ? "rotate 3s linear infinite"
                                                            : "none",
                                                    "@keyframes rotate": {
                                                        "0%": {
                                                            transform: "rotate(0deg)",
                                                        },
                                                        "100%": {
                                                            transform: "rotate(360deg)",
                                                        },
                                                    },
                                                }}
                                            ></SettingsIcon>
                                        </Button>
                                        <SwipeableDrawer
                                            anchor="right"
                                            open={drawer}
                                            onClose={toggleDrawer(false)}
                                            onOpen={toggleDrawer(true)}
                                            sx={{
                                                "& .MuiDrawer-paper": {
                                                    height: "100%",
                                                    backgroundColor: "rgb(236, 244, 247)",
                                                    width: {
                                                        sm: "40%",
                                                        md: "40%",
                                                        lg: "30%"
                                                    },
                                                }
                                            }}
                                        >
                                            <PropertiesPanel
                                                component={selectedComponent !== null ? selectedComponent : null}
                                                setSelectedComponent={setSelectedComponent}
                                                onPropertyChange={handlePropertyChange}
                                                showAlert={showAlert}
                                                flag={flag}
                                                setFlag={setFlag}
                                                onCloseDrawer={toggleDrawer(false)}
                                                reportAreaRef={dropRef}
                                                setIsSavedClicked={setIsSavedClicked}
                                                isSaveClicked={isSaveClicked}
                                                selectedTemplate={selectedTemplate}
                                                setIsCreateNewTemplate={setIsCreateNewTemplate}
                                                isNewDashboardActive={isNewDashboardActive}
                                                dashboardId={dashboardId}
                                                generateChartData={generateChartData}
                                                generatePieChartData={generatePieChartData}
                                                generateDoubleChartData={generateDoubleChartData}
                                                echartsRef={echartsRef}
                                                setCurrentReportData={setCurrentReportData}
                                                getUserTemplates={getUserTemplates}
                                                isAnimated={isAnimated}
                                                setIsAnimated={setIsAnimated}
                                                currentReportData={currentReportData}
                                                setConfirmModalOpen={setConfirmModalOpen}
                                                drillData={components?.find((item) => item?.id === selectedComponent?.id)?.properties?.drillData}
                                                pieDrillData={components?.find((item) => item?.id === selectedComponent?.id)?.properties?.pieDrillData}
                                            />
                                        </SwipeableDrawer>
                                    </React.Fragment>
                                </span>
                            </div>
                            <div
                                className="custom-scrollbar"
                                style={{ height: "80vh", overflow: "auto", outline: "none" }}
                            >
                                <ReportBuildingArea
                                    onDrop={handleComponentDrop}
                                    onDelete={handleDeleteComponent}
                                    components={components}
                                    setComponents={setComponents}
                                    selectedComponent={selectedComponent !== null ? selectedComponent : null}
                                    setSelectedComponent={setSelectedComponent}
                                    onPropertyChange={handlePropertyChange}
                                    disableRndDragging={disableRndDragging}
                                    setFlag={setFlag}
                                    dropRef={dropRef}
                                    setIsSavedClicked={setIsSavedClicked}
                                    selectedTemplate={selectedTemplate}
                                    setIsCreateNewTemplate={setIsCreateNewTemplate}
                                    setIsSaveAsClicked={setIsSaveAsClicked}
                                    isSelectReport={isSelectReport}
                                    setIsSelectReport={setIsSelectReport}
                                    dashboardId={dashboardId}
                                    isNewDashboardActive={isNewDashboardActive}
                                    isLoadingReport={isLoadingReport}
                                    isLoadingTemplate={isLoadingTemplate}
                                    isTableView={isTableView}
                                    isChartView={isChartView}
                                    setIsAnimated={setIsAnimated}
                                    handleDesignClick={handleDesignClick}
                                    handleDataClick={handleDataClick}
                                >
                                    {components?.map((component, index) => {
                                        return (
                                            <div
                                                key={component.id}
                                                className={`report-component ${index === selectedComponent ? "selected" : ""
                                                    } no-scrollbar`}
                                                onClick={() => handleComponentSelect(component)}
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    overflow:
                                                        component?.type === "textbox" ? "unset" : "auto",
                                                    display:
                                                        (isTableView && !isChartView && component?.type == 'chart') || (isChartView && !isTableView && component?.type == 'table') || (!isTableView && component?.type == 'table') || (!isChartView && component?.type == 'chart') ? 'none' : ''
                                                }}
                                            >
                                                {component.type === "table" && (
                                                    <>
                                                        <TableComponent
                                                            type={component.properties.type}
                                                            columns={component.properties.columns}
                                                            data={component.properties.data}
                                                            //data={currentReportData.data}
                                                            key={component.id}
                                                            properties={component.properties}
                                                            id={component.id}
                                                            stopTableDrag={() => {
                                                                setDisableRndDragging(true);
                                                            }}
                                                            onPropertyChange={handlePropertyChange}
                                                            setSelectedComponent={setSelectedComponent}
                                                            component={
                                                                selectedComponent !== null
                                                                    ? selectedComponent
                                                                    : null
                                                            }
                                                            showAlert={showAlert}
                                                            setIsSavedClicked={setIsSavedClicked}
                                                            setIsSaveAsClicked={setIsSaveAsClicked}
                                                            setIsCreateNewTemplate={setIsCreateNewTemplate}
                                                            selectedTemplate={selectedTemplate}
                                                            isChartView={isChartView}
                                                            isTableView={isTableView}
                                                            pivotData={component?.properties?.pivotData}

                                                        />
                                                    </>
                                                )}
                                                {component.type === "chart" && (
                                                    <ChartComponent
                                                        type={component?.properties?.type}
                                                        option={component?.properties?.option}
                                                        data={component?.properties?.data}
                                                        key={index}
                                                        properties={component?.properties}
                                                        echartsRef={echartsRef}
                                                        showAlert={showAlert}
                                                        isChartView={isChartView}
                                                        isTableView={isTableView}
                                                        isAnimated={isAnimated}
                                                        setIsAnimated={setIsAnimated}
                                                        stopTableDrag={() => {
                                                            setDisableRndDragging(true);
                                                        }}
                                                        generateChartData={generateChartData}
                                                        id={component.id}
                                                        setFlag={setFlag}
                                                        setSelectedComponent={setSelectedComponent}
                                                        onPropertyChange={handlePropertyChange}
                                                        generatePieChartData={generatePieChartData}
                                                        generateDoubleChartData={generateDoubleChartData}
                                                        drillData={component?.properties?.drillData}
                                                        selectedId={selectedComponent?.id}
                                                        pieDrillData={component?.properties?.pieDrillData}
                                                    />
                                                )}
                                                {component.type === "textbox" && (
                                                    <TextboxComponent
                                                        type={component.properties.type}
                                                        text={component.properties.text}
                                                        key={component.id}
                                                        properties={component.properties}
                                                        id={component.id}
                                                        onPropertyChange={handlePropertyChange}
                                                        setSelectedComponent={setSelectedComponent}

                                                    />
                                                )}
                                                {component.type === "image" && (
                                                    <ImageComponent
                                                        type={component.properties.type}
                                                        src={component.properties.src}
                                                        key={component.id}
                                                        properties={component.properties}
                                                        onPropertyChange={handlePropertyChange}
                                                        setSelectedComponent={setSelectedComponent}
                                                    />
                                                )}
                                                {component.type === "shape" && (
                                                    <ShapeComponent
                                                        type={component.properties.type}
                                                        key={component.id}
                                                    />
                                                )}
                                            </div>)
                                    })}
                                </ReportBuildingArea>
                            </div>
                        </div>
                    </div>
                </div>
            </DndProvider >
        </>
    );
};
export default ReportBuilder;
const SidebarSection = ({ title, isOpen, onClick, children, isMenuExpand, icon }) => {
    const menuBgColor = 'rgb(10 58 103)'
    return (
        <div
            className="sidebar-section"
        // style={{
        //     borderTop: "0.5px solid #d2d9e3",
        //     borderBottom: "0.5px solid #d2d9e3",
        // }}
        >
            <div
                className="sidebar-section-header cursor-pointer px-2 py-2"
                style={{ backgroundColor: `${menuBgColor}` }}
                onClick={onClick}
            >
                <div className={`d-flex ${isMenuExpand === false ? 'justify-content-center' : 'justify-between'}`}>
                    {isMenuExpand ? <div className="d-flex w-full justify-between items-center">
                        <span className="font-semibold d-flex items-center gap-2 text-sm text-white">{icon}{title}</span>
                        <button className="toggle-button">
                            {isOpen ? (
                                <RemoveIcon className="text-white" />
                            ) : (
                                <AddIcon className="text-white" />
                            )}
                        </button>
                    </div> : <span className="font-semibold text-sm text-white">{icon}</span>}

                </div>
            </div>
            {isOpen && (
                <div className="sidebar-section-content">{children}</div>
            )}
        </div>
    );
};
const inputWrapperStyle = {
    position: "relative",
    width: "35%",
};

const iconStyle = {
    position: "absolute",
    bottom: "2%",
    right: "-30px",
    fontSize: "18px",
    color: "#aaa",
};

const labelStyle = {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#333",
    fontSize: "13px",
};