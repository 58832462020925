import React, { useRef } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { Icons } from "../../assets";
import classNames from "classnames"

const ReportHeader = ({ title, exportCSV, exportPDF, totals, total, isPivot, selectedOption, setSelectedOption, setDisabled, selectedReport, setSelectedReport }) => {
  const [toggleBtns, setToggleBtns] = React.useState(false);
  const [anchorElOption, setAnchorElOption] = React.useState(null);
  const btnRef = useRef(null);
  const [optionListOpen, setOptionsListOpen] = React.useState(false);
  const [reportListOpen, setReportListOpen] = React.useState(false)

  const optionRef = useRef(null);
  const reportRef = useRef(null)

  const settings = [
    {
      id: 1,
      name: "Export as PDF",
    },
    {
      id: 2,
      name: "Export as CSV",
    },
  ];

  const handleClickOutside = (event) => {
    if (btnRef.current && !btnRef.current.contains(event.target)) {
      setToggleBtns(false);
    }
    if (optionRef.current && !optionRef.current.contains(event.target)) {
      setOptionsListOpen(false);
    }
    if (reportRef.current && !reportRef.current.contains(event.target)) {
      setReportListOpen(false)
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElOption(event.currentTarget);
  };
  const handleCloseUserMenu = ({ id }) => {
    setAnchorElOption(null);
    if (id === 1) {
      exportPDF();
    } else if (id === 2) {
      exportCSV();
    }
  };
  React.useEffect(() => {
    if (optionListOpen || reportListOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionListOpen, reportListOpen]);
  React.useEffect(() => {
    if (toggleBtns) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleBtns]);
  return (
    <div className={`flex flex-column flex-sm-row lg:items-center items-start justify-between h-fit  ${isPivot ? 'p-3' : 'p-4'}  border-b-[1px] border - b - secondarygrayborder`}>
      <div className="flex flex-row items-center justify-start gap-3">
        <p className="m-0 text-2xl font-bold text-gray-600 whitespace-nowrap sm:w-5 w-full ">{title}</p>
      </div>
      <div className="d-flex gap-3 items-center lg:flex-row flex-col-reverse sm:w-fit w-full">
        {/* {isPivot ?
          <div className="d-flex gap-3 self-end">
            <div className='d-flex flex-col' >
              <span className="font-semibold text-sm text-gray-700">Data source</span>
              <div className="d-flex sm:flex-row flex-col h-fit">
                <div className="relative flex  mt-1 flex-row items-center gap-2 divide-x-2 rounded-md cursor-grab outline outline-gray-200 sm:w-56 w-48" ref={optionRef}>
                  <button className="pl-2">
                    <img
                      src={Icons.Sort_Icon}
                      alt="search"
                      className="w-5 h-5 cursor-pointer search-icon"
                      onClick={() => {
                        setOptionsListOpen(!optionListOpen);
                      }}
                    />
                  </button>
                  <div className="flex flex-row items-center w-full h-full py-1 gap-2 px-2 overflow-scroll flex-nowrap no-scrollbar">
                    {selectedOption === null ? (
                      <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
                        Select Data Source
                      </p>
                    ) : (
                      <p className="m-0 text-base  whitespace-nowrap">
                        {selectedOption?.name}
                      </p>
                    )}
                  </div>
                  {optionListOpen && (
                    <div
                      className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-9"
                    >
                      {[
                        { value: 1, name: "Upload CSV" },
                        { value: 2, name: "Datasource" },
                        { value: 3, name: "Available Data" },
                      ].map((item, index) => (
                        <div
                          className={classNames(
                            "hover:bg-primarylightgrey z-10 bg-white",
                            index !== 7 && "border-b"
                          )}
                        >
                          <div
                            className="px-2 py-2"
                            onClick={() => {
                              setSelectedOption(item);
                            }}
                          >
                            {item?.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex flex-col'>
              <span className="font-semibold text-sm text-gray-700">Data Elements</span>
              <div className={`d-flex sm:flex-row flex-col h-fit ${selectedOption && selectedOption.value === 3 ? "" : 'disabled-dropdown'}`}>
                <div className="relative flex  mt-1 flex-row items-center gap-2 divide-x-2 rounded-md cursor-grab outline outline-gray-200 sm:w-56 w-48" ref={reportRef}>
                  <button className="pl-2">
                    <img
                      src={Icons.Sort_Icon}
                      alt="search"
                      className="w-5 h-5 cursor-pointer search-icon"
                      onClick={() => {
                        setReportListOpen(!reportListOpen)
                      }}
                    />
                  </button>
                  <div className="flex flex-row items-center w-full h-full py-1 gap-2 px-2 overflow-scroll flex-nowrap no-scrollbar ">
                    {selectedReport === null ? (
                      <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
                        Select Types of Data
                      </p>
                    ) : (
                      <p className="m-0 text-base  whitespace-nowrap">
                        {selectedReport?.name}
                      </p>
                    )}
                  </div>
                  {reportListOpen && (
                    <div
                      className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-9"
                    >
                      {[
                        { value: 1, name: "Patient Visit Data" },
                        { value: 2, name: "Provider Activity Data" },
                        { value: 3, name: "Revenue Data" },
                        { value: 4, name: "Appointment Cancellation Data" },
                      ].map((item, index) => (
                        <div
                          className={classNames(
                            "hover:bg-primarylightgrey z-10 bg-white",
                            index !== 7 && "border-b"
                          )}
                        >
                          <div
                            className="px-2 py-2"
                            onClick={() => {
                              setSelectedReport(item);
                            }}
                          >
                            {item?.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div> : ""} */}
        <div className="d-flex gap-3 self-end">
          <div className="d-flex flex-column justify-center">
            {totals.map((item) => (
              <div className="font-bold">
                {item.name}:
                <span className="font-normal">{" " + item.value}</span>
              </div>
            ))}
          </div>
          <Box className="d-flex align-items-center" sx={{ flexGrow: 0 }}>
            <button
              onClick={handleOpenUserMenu}
              className="py-2 px-2 text-white text-sm font-bold bg-primaryblue rounded-lg disabled:opacity-60 hover:bg-blue-700"
              disabled={total > 0 ? false : true}
            >
              Export Report
            </button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElOption}
              open={Boolean(anchorElOption)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(({ name, id }) => (
                <MenuItem key={id} onClick={() => handleCloseUserMenu({ id })}>
                  <Typography className="mx-2" textAlign="center">
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
