import { ArrowDownwardTwoTone, ArrowUpwardTwoTone } from "@mui/icons-material";
import {
  useCreateLocationMutation,
  useEditLocationMutation,
  useGetAllLocationsMutation,
} from "../../features/location/locationApiSlice";

import LocationModal from "./components/LocationModal";
import FilterSelect from "../../components/Filter/FilterSelect";
import IconComponent from "../../components/DataTable/Icon";
import Loading from "../../components/Loading";
import React from "react";
import Search from "../../components/Search/SearchNew";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { usePermissionsStore } from "../../context/permissions.store";
const Locations = () => {
  const { permissions: userPermissions } = usePermissionsStore();
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [locationModalOpen, setLocationModalOpen] = React.useState(false);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [statuses, setStatuses] = React.useState([]);

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const [getLocations, { isLoading }] = useGetAllLocationsMutation();

  const [edit, { isLoading: editLoading }] = useEditLocationMutation();

  const [create, { isLoading: createLoading }] = useCreateLocationMutation();

  const loading = editLoading || createLoading || isLoading;

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const [locations, setLocations] = React.useState([]);

  const handleLocationClick = (Location) => {
    // navigate(`/Locations/${Location.id}`);
  };

  const fetchLocations = async () => {
    try {
      const { data } = await getLocations().unwrap();

      setLocations(data);

      const statuses = Array.from(
        new Set(data.map((Location) => Location.status))
      );

      setStatuses(statuses);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    const statuses = Array.from(
      new Set(locations.map((location) => location.status))
    );

    setStatuses(statuses);
  }, [locations]);

  React.useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <Loading loading={loading} />
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Locations{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {
                locations
                  .filter((location) =>
                    location.name
                      .toLowerCase()
                      .includes(searchFilter.toLowerCase())
                  )
                  .filter(
                    (location) =>
                      statusFilter.length === 0 ||
                      statusFilter.indexOf(location.status) !== -1
                  )?.length
              }
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
            <FilterSelect
              value={statusFilter}
              handleChange={handleStatusFilter}
              data={statuses}
              placeholder="Filter by Status ..."
            />
          </div>
        </div>
        {userPermissions?.permissions?.includes("create_location") && (
          <button
            onClick={() => {
              setLocationModalOpen(true);
            }}
            className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
          >
            Create Location
          </button>
        )}
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortNameOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Address</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortStatusOrder === "none") {
                          setSortStatusOrder("asc");
                        }
                        if (sortStatusOrder === "asc") {
                          setSortStatusOrder("desc");
                        }
                        if (sortStatusOrder === "desc") {
                          setSortStatusOrder("none");
                        }
                      }}
                    >
                      {sortStatusOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortStatusOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">City</th>
                <th className="px-4 py-3">State</th>
                <th className="px-4 py-3">Zip</th>
                <th className="px-4 py-3">Country</th>
                {userPermissions?.permissions?.includes("edit_location") && (
                  <th className="px-4 py-3">Edit</th>
                )}
              </tr>
            </thead>
            <tbody>
              {locations
                .filter((location) =>
                  location.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .filter(
                  (location) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(location.status) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((location, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== locations.length - 1
                        ? "border-b"
                        : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <div className="flex flex-row items-center gap-4">
                        <button onClick={() => handleLocationClick(location)}>
                          <p className="m-0 font-semibold underline">
                            {location.name}
                          </p>
                        </button>
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {location?.address ?? "NA"}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {location?.city ?? "NA"}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {location?.state ?? "NA"}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {location?.zip ?? "NA"}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {location?.country ?? "NA"}
                    </td>
                    {userPermissions?.permissions?.includes(
                      "edit_location"
                    ) && (
                      <td className="px-4 py-3 text-left">
                        <button
                          onClick={() => {
                            setSelectedLocation(location);
                            setLocationModalOpen(true);
                          }}
                          className="text-white rounded-md"
                        >
                          <IconComponent
                            tooltip="Edit Location"
                            icon="Edit_Icon"
                            i={location.id}
                            key={location.id}
                          />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {locationModalOpen && (
        <LocationModal
          type={selectedLocation ? "update" : "create"}
          open={locationModalOpen}
          setOpen={setLocationModalOpen}
          data={selectedLocation}
          setData={setSelectedLocation}
          edit={edit}
          create={create}
          fetchLocations={fetchLocations}
        />
      )}
    </div>
  );
};

export default Locations;
