import React, { useState } from "react";
import SupportModal from "../../Welcome/components/SupportModal";

const SchedulingSettingsHeader = ({ title, setOpaque }) => {
  const [openSupport, setOpenSupport] = useState(false);
  const onSupport = () => {
    setOpenSupport(true);
    setOpaque && setOpaque(true);
  };
  const onCloseSupport = () => {
    setOpenSupport(false);
    setOpaque && setOpaque(false);
  };
  return (
    <div className="flex flex-row items-center justify-between h-20 p-4 border-b-[1px] border-b-secondarygrayborder">
      <div className="flex flex-row items-center justify-start gap-3">
        <p className="m-0 text-2xl font-bold text-gray-600">{title}</p>
      </div>
      <div>
        <button
          className="py-2 font-semibold bg-white border-2 rounded-md shadow-sm border-black w-32 md:w-60"
          onClick={onSupport}
        >
          <span className="text-primarytextgrey">Request Support</span>
        </button>
      </div>
      {openSupport && <SupportModal onCloseSupport={onCloseSupport} />}
    </div>
  );
};

export default SchedulingSettingsHeader;
