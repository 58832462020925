import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
  docTypes: [],
};

export const careTagsSlice = createSlice({
  name: "careTags",
  initialState,
  reducers: {
    addCareTag: (state, action) => {
      state.data = [...state.data, action.payload] || [];
      state.count++;
    },
    replaceCareTags: (state, action) => {
      state.data = action.payload.data || [];
      state.count = action.payload.count || 0;
    },
    replaceDocTypes: (state, action) => {
      state.docTypes = action.payload.data || [];
    },
    updateCareTag: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.id == action.payload.id) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
    deleteteCareTag: (state, action) => {
      const tmpUser = state.data.filter((i) => i.id !== action.payload.id);
      state.data = tmpUser || [];
      state.count--;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  addCareTag,
  updateCareTag,
  replaceCareTags,
  deleteteCareTag,
  replaceDocTypes,
} = careTagsSlice.actions;

export default careTagsSlice.reducer;
