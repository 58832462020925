import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useAttachPaymentMethodMutation } from "../../../features/billing/billingApiSlice";

const PaymentForm = ({ handleSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const [attachPaymentMethod, { isLoading }] = useAttachPaymentMethodMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(null);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      const { paymentMethod } = result;

      try {
        await attachPaymentMethod({
          paymentMethodId: paymentMethod.id,
        }).unwrap();
      } catch (error) {
        setError("Failed to save payment method");
      }

      await handleSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-[480px] p-4 flex flex-col gap-4">
      <div className="m-2 my-3">
        <CardElement />
      </div>
      <button
        type="submit"
        className="w-full p-1 font-medium text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
        disabled={!stripe || isLoading}
      >
        Save Payment Method
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};

export default PaymentForm;
