import React from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";

const POCModal = ({
  addPOCOpen,
  edit,
  clearInput,
  pocFirstName,
  setAddPOCOpen,
  setPocFirstName,
  pocLastName,
  setPocLastName,
  pocStreet,
  setPocStreet,
  pocCity,
  setPocCity,
  pocState,
  setPocState,
  pocZip,
  setPocZip,
  pocEmail,
  setPocEmail,
  pocPhone,
  setPocPhone,
  pocSecondaryPhone,
  setPocSecondaryPhone,
  pocCategory,
  setPocCategory,
  pocType,
  setPocType,
  onUpdatePOC,
  savePOC,
}) => {
  return (
    <ReusableModal
      open={addPOCOpen}
      title={edit ? "Edit point of contact" : "Add new point of contact"}
      onClose={() => {
        setAddPOCOpen(false);
        clearInput();
      }}
    >
      <div className="flex flex-col items-center min-w-[480px]">
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-col gap-4 p-6 overflow-auto max-h-[calc(100vh-200px)]">
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="email" className="m-0 text-base font-semibold">
                First Name
              </label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                placeholder="John"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                value={pocFirstName}
                onChange={(e) => setPocFirstName(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="phone" className="m-0 text-base font-semibold">
                Last Name
              </label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Doe"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                value={pocLastName}
                onChange={(e) => setPocLastName(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-1/2  gap-2">
              <label htmlFor="street" className="m-0 text-base font-semibold">
                Street
              </label>
              <input
                type="text"
                id="street"
                name="street"
                placeholder="Enter Street"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                value={pocStreet}
                onChange={(e) => setPocStreet(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="city" className="m-0 text-base font-semibold">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="Enter City"
                value={pocCity}
                onChange={(e) => {
                  setPocCity(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="state" className="m-0 text-base font-semibold">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="Enter State"
                value={pocState}
                onChange={(e) => {
                  setPocState(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="zip" className="m-0 text-base font-semibold">
                Zip
              </label>
              <input
                type="text"
                id="zip"
                name="zip"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="Zip code"
                value={pocZip}
                onChange={(e) => {
                  setPocZip(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-2">
            <label htmlFor="email" className="m-0 text-base font-semibold">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              placeholder="Enter Email"
              value={pocEmail}
              onChange={(e) => {
                setPocEmail(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="phone" className="m-0 text-base font-semibold">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="Enter Phone Number"
                value={pocPhone}
                onChange={(e) => {
                  setPocPhone(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <label
                htmlFor="secondaryphone"
                className="m-0 text-base font-semibold"
              >
                Secondary Phone
              </label>
              <input
                type="text"
                id="secondaryphone"
                name="secondaryphone"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="Enter secondary phone number"
                value={pocSecondaryPhone}
                onChange={(e) => {
                  setPocSecondaryPhone(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="category" className="m-0 text-base font-semibold">
                Category
              </label>
              <select
                id="category"
                name="category"
                value={pocCategory}
                onChange={(e) => setPocCategory(e.target.value)}
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              >
                <option value={""}>Select Category</option>
                <option value={"PRIMARY"}>Primary Contact</option>
                <option value={"SECONDARY"}>Secondary Contact</option>
              </select>
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <label
                htmlFor="secondaryphone"
                className="m-0 text-base font-semibold"
              >
                Type
              </label>
              <select
                id="role"
                name="role"
                value={pocType}
                onChange={(e) => setPocType(e.target.value)}
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              >
                <option value={""}>Select Role</option>
                <option value={"SON"}>Family Member - Son</option>
                <option value={"DAUGHTER"}>Family Member - Daughter</option>
                <option value={"OTHER"}>Family Member - Other</option>
                <option value={"LEGAL_GUARDIAN"}>
                  Non Family Legal Guardian
                </option>
                <option value={"COMMUNITY_STAFF"}>Community Staff</option>
              </select>
            </div>
          </div>
        </div>
        <div className="w-full d-flex justify-center mb-2">
          <button
            disabled={
              pocFirstName === "" ||
              pocLastName === "" ||
              pocStreet === "" ||
              pocCity === "" ||
              pocState === "" ||
              pocZip === "" ||
              pocEmail === "" ||
              pocPhone === "" ||
              pocCategory === "" ||
              pocType === ""
            }
            className="w-75 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            onClick={async () => {
              if (edit) {
                await onUpdatePOC();
              } else {
                await savePOC();
              }
              setAddPOCOpen(false);
              clearInput();
            }}
          >
            {edit ? "Save" : "Add"}
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default POCModal;
