import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const AddDatasetSvg = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.height}
            height={props.width}
            xmlns="http://www.w3.org/2000/svg"
            // viewBox="0 0 64 64"
            viewBox="0 0 48 48"
            fill={props.fill}
        // stroke="#075985"
        // stroke-width="1"
        // stroke-linecap="round"
        // stroke-linejoin="round"
        >
            <g fill="none" stroke={props?.fill} stroke-width="3">
                <path d="M38,7H10C8.9,7,8,7.9,8,9v6c0,1.1,0.9,2,2,2h28c1.1,0,2-0.9,2-2V9C40,7.9,39.1,7,38,7z" />
                <path d="M38,19H10c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h28c1.1,0,2-0.9,2-2v-6C40,19.9,39.1,19,38,19z" />
                <path d="M38,31H10c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h28c1.1,0,2-0.9,2-2v-6C40,31.9,39.1,31,38,31z" />
            </g>
            <circle fill={props.fill} cx="38" cy="38" r="10" />
            <g fill="#ffffff">
                <rect x="36" y="32" width="4" height="12" />
                <rect x="32" y="36" width="12" height="4" />
            </g>
        </svg>

    </Tooltip>
);

export default AddDatasetSvg;

