import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const PageMarginIcon = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.height}
            height={props.width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 60"

        >

            <rect x="7" y="6" width="33" height="47" fill="white" stroke={props.fill} stroke-width="3" />


            <line x1="8" y1="15" x2="40" y2="15" stroke={props.fill} stroke-width="2" />
            <line x1="8" y1="45" x2="39" y2="45" stroke={props.fill} stroke-width="2" />
            <line x1="15" y1="5" x2="15" y2="51" stroke={props.fill} stroke-width="2" />
            <line x1="30" y1="5" x2="30" y2="51" stroke={props.fill} stroke-width="2" />
        </svg>

    </Tooltip>
);

export default PageMarginIcon;

