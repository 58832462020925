import React, { useEffect } from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { AddAlertRounded } from "@mui/icons-material";
import { useLSStore } from "../../../context/ls-store";

const AvailibilityWarningModal = ({
  type = "availibility",
  open,
  closeWarningModal,
  onDrop,
  moveData,
}) => {
  const appointmentToDrop = useLSStore((state) => state.appointmentToDrop);

  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col gap-3 p-3">
        <div className="flex flex-row gap-2">
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-subtlered">
            <AddAlertRounded
              style={{
                color: "#EB5757",
              }}
            />
          </div>
          <div className="flex flex-col">
            <p className="m-0 font-semibold">
              {type === "availibility" ? "Provider Might Not be Available" : ""}
            </p>
            <p className="m-0 font-light">
              {type === "availibility" ? (
                <>
                  The provider isn't scheduled to work on this day. <br /> Would
                  you still like to schedule the appointment?
                </>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-row items-center justify-end gap-3">
          <button
            className="px-4 py-2 font-medium border rounded-md bg-primarylightgrey border-primarygray"
            onClick={closeWarningModal}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 font-medium text-white border rounded-md bg-primaryblue border-primaryblue"
            onClick={() => {
              onDrop(
                moveData.column,
                moveData.index,
                true,
                moveData.appointmentId
              );
              closeWarningModal();
            }}
          >
            Schedule
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AvailibilityWarningModal;
