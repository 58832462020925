import * as React from "react";
const HealthSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M7 2.75C7 1.784 7.784 1 8.75 1h2.5c.966 0 1.75.784 1.75 1.75V7h4.25c.966 0 1.75.784 1.75 1.75v2.5A1.75 1.75 0 0 1 17.25 13H13v4.25A1.75 1.75 0 0 1 11.25 19h-2.5A1.75 1.75 0 0 1 7 17.25V13H2.75A1.75 1.75 0 0 1 1 11.25v-2.5C1 7.784 1.784 7 2.75 7H7V2.75Zm1.75-.25a.25.25 0 0 0-.25.25v5a.75.75 0 0 1-.75.75h-5a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h5a.75.75 0 0 1 .75.75v5c0 .138.112.25.25.25h2.5a.25.25 0 0 0 .25-.25v-5a.75.75 0 0 1 .75-.75h5a.25.25 0 0 0 .25-.25v-2.5a.25.25 0 0 0-.25-.25h-5a.75.75 0 0 1-.75-.75v-5a.25.25 0 0 0-.25-.25h-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default HealthSvg;
