import { ArrowDownwardTwoTone, ArrowUpwardTwoTone } from "@mui/icons-material";
import {
  useCreateGroupMutation,
  useEditGroupMutation,
  useGetAllGroupsMutation,
} from "../../features/group/groupApiSlice";

import GroupModal from "./components/GroupModal";
import FilterSelect from "../../components/Filter/FilterSelect";
import IconComponent from "../../components/DataTable/Icon";
import Loading from "../../components/Loading";
import React, { useCallback, useMemo } from "react";
import Search from "../../components/Search/SearchNew";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { usePermissionsStore } from "../../context/permissions.store";

// Extracted table component for better reusability
const GroupsTable = React.memo(
  ({
    groups,
    onGroupClick,
    onEditClick,
    sortNameOrder,
    sortStatusOrder,
    onSortName,
    onSortStatus,
    searchFilter,
    statusFilter,
    permissions,
  }) => {
    const filteredGroups = useMemo(() => {
      return groups
        .filter((group) =>
          group.name.toLowerCase().includes(searchFilter.toLowerCase())
        )
        .filter(
          (group) =>
            statusFilter.length === 0 ||
            statusFilter.indexOf(group.status) !== -1
        )
        .sort((a, b) => {
          if (sortNameOrder === "none") return 0;
          return sortNameOrder === "asc"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        })
        .sort((a, b) => {
          if (sortStatusOrder === "none") return 0;
          return sortStatusOrder === "asc"
            ? a.status.localeCompare(b.status)
            : b.status.localeCompare(a.status);
        });
    }, [groups, searchFilter, statusFilter, sortNameOrder, sortStatusOrder]);

    return (
      <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
        <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
          <thead>
            <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
              <th className="px-4 py-3">
                <div className="flex flex-row items-center gap-2">
                  <p className="m-0">Name</p>
                  <button
                    className="flex flex-col items-center gap-1"
                    onClick={onSortName}
                  >
                    {sortNameOrder === "asc" ? (
                      <Tooltip title="Sort Descending">
                        <ArrowDownwardTwoTone />
                      </Tooltip>
                    ) : sortNameOrder === "desc" ? (
                      <Tooltip title="Sort Ascending">
                        <ArrowUpwardTwoTone />
                      </Tooltip>
                    ) : (
                      <div className="flex items-center providerlist-icon">
                        <SwapVertIcon />
                      </div>
                    )}
                  </button>
                </div>
              </th>
              <th className="px-4 py-3">
                <div className="flex flex-row items-center gap-2">
                  <p className="m-0">Status</p>
                  <button
                    className="flex flex-col items-center gap-1"
                    onClick={onSortStatus}
                  >
                    {sortStatusOrder === "asc" ? (
                      <Tooltip title="Sort Descending">
                        <ArrowDownwardTwoTone />
                      </Tooltip>
                    ) : sortStatusOrder === "desc" ? (
                      <Tooltip title="Sort Ascending">
                        <ArrowUpwardTwoTone />
                      </Tooltip>
                    ) : (
                      <div className="flex items-center providerlist-icon">
                        <SwapVertIcon />
                      </div>
                    )}
                  </button>
                </div>
              </th>
              {permissions?.permissions?.includes("edit_group") && (
                <th className="px-4 py-3">Edit</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredGroups.map((group, index) => (
              <tr
                className={classNames(
                  "text-left text-gray-900 h-24 whitespace-nowrap",
                  index !== groups.length - 1 ? "border-b" : "border-none"
                )}
                key={group.id}
              >
                <td className="px-4 py-3 text-left">
                  <div className="flex flex-row items-center gap-4">
                    <button onClick={() => onGroupClick(group)}>
                      <p className="m-0 font-semibold underline">
                        {group.name}
                      </p>
                    </button>
                  </div>
                </td>
                <td className="px-4 py-3 text-left text-gray-700">
                  <div
                    className={classNames(
                      group.status.toUpperCase() === "ACTIVE"
                        ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                        : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                      "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                    )}
                  >
                    <div
                      className={classNames(
                        "w-2 h-2 rounded-full",
                        group.status.toUpperCase() === "ACTIVE"
                          ? "bg-primarygreen"
                          : "bg-primarytextgrey"
                      )}
                    />
                    {group.status}
                  </div>
                </td>
                {permissions?.permissions?.includes("edit_group") && (
                  <td className="px-4 py-3 text-left">
                    <button
                      onClick={() => onEditClick(group)}
                      className="text-white rounded-md"
                    >
                      <IconComponent
                        tooltip="Edit group"
                        icon="Edit_Icon"
                        i={group.id}
                        key={group.id}
                      />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);

const Groups = () => {
  const navigate = useNavigate();
  const { permissions } = usePermissionsStore();
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [groupModalOpen, setGroupModalOpen] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [statuses, setStatuses] = React.useState([]);
  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");
  const [groups, setGroups] = React.useState([]);

  const [getGroups, { isLoading }] = useGetAllGroupsMutation();
  const [edit, { isLoading: editLoading }] = useEditGroupMutation();
  const [create, { isLoading: createLoading }] = useCreateGroupMutation();

  const loading = editLoading || createLoading || isLoading;

  const handleStatusFilter = useCallback((status) => {
    setStatusFilter((prev) =>
      prev.indexOf(status) === -1
        ? [...prev, status]
        : prev.filter((filter) => filter !== status)
    );
  }, []);

  const handleGroupClick = useCallback(
    (group) => {
      navigate(`/groups/${group.id}`);
    },
    [navigate]
  );

  const handleEditClick = useCallback((group) => {
    setSelectedGroup(group);
    setGroupModalOpen(true);
  }, []);

  const handleSortName = useCallback(() => {
    setSortNameOrder((prev) => {
      if (prev === "none") return "asc";
      if (prev === "asc") return "desc";
      return "none";
    });
  }, []);

  const handleSortStatus = useCallback(() => {
    setSortStatusOrder((prev) => {
      if (prev === "none") return "asc";
      if (prev === "asc") return "desc";
      return "none";
    });
  }, []);

  const fetchGroups = useCallback(async () => {
    try {
      const { data } = await getGroups().unwrap();
      setGroups(data);
      setStatuses(Array.from(new Set(data.map((group) => group.status))));
    } catch (error) {
      console.error(error.data.message);
    }
  }, [getGroups]);

  React.useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const filteredGroupsCount = useMemo(() => {
    return groups
      .filter((group) =>
        group.name.toLowerCase().includes(searchFilter.toLowerCase())
      )
      .filter(
        (group) =>
          statusFilter.length === 0 || statusFilter.indexOf(group.status) !== -1
      )?.length;
  }, [groups, searchFilter, statusFilter]);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <Loading loading={loading} />
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Groups{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {filteredGroupsCount}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
            <FilterSelect
              value={statusFilter}
              handleChange={handleStatusFilter}
              data={statuses}
              placeholder="Filter by Status ..."
            />
          </div>
        </div>
        {permissions?.permissions?.includes("create_group") && (
          <button
            onClick={() => setGroupModalOpen(true)}
            className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
          >
            Create Group
          </button>
        )}
      </div>
      <div className="w-full px-16">
        <GroupsTable
          groups={groups}
          onGroupClick={handleGroupClick}
          onEditClick={handleEditClick}
          sortNameOrder={sortNameOrder}
          sortStatusOrder={sortStatusOrder}
          onSortName={handleSortName}
          onSortStatus={handleSortStatus}
          searchFilter={searchFilter}
          statusFilter={statusFilter}
          permissions={permissions}
        />
      </div>
      <GroupModal
        type={selectedGroup ? "update" : "create"}
        open={groupModalOpen}
        setOpen={setGroupModalOpen}
        data={selectedGroup}
        setData={setSelectedGroup}
        groups={groups}
        setGroups={setGroups}
        edit={edit}
        create={create}
      />
    </div>
  );
};

export default Groups;
