// export const AUTHORIZATION = "x-access-token";
export const AUTHORIZATION = "authorization";
export const CONTENT_TYPE = "Content-Type";
export const ACCEPT = "Accept";
export const APPLICATION_JSON = "application/json";
export const MULTIPART_FORM_DATA = "multipart/form-data";
export const USERNAME = "username";
export const CORS = "Access-Control-Allow-Origin";
export const ACCESS_CONTROL = "*";
export const SB_URL = "https://skorboard.com/api/v1/";
// export const SB_URL = "http://localhost:4000/v1/";
