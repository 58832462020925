import Menu from "../DataManagement/components/Menu";
import Nav from "../DataManagement/components/Nav";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import React, { useEffect, useState } from "react";
import DataPanel from "./components/DataPanel";
import WidgetsPanel from "./components/WidgetsPanel";
import DashboardBuildingArea from "./components/DashboardBuildingArea";
import TableComponent from "./components/draggableItems/TableComponent";
import TextboxComponent from "./components/draggableItems/TextboxComponent";
import SummeryComponent from "./components/draggableItems/SummeryComponent";
import ImageComponent from "./components/draggableItems/ImageComponent";
import ChartComponent from "./components/draggableItems/ChartComponent";
import './DashboardBuilderStyle.css'
import {
    useCalculateExpressionMutation,
    useCreteUserReportSbMutation,
    useGetAllTableSkorBoardMutation,
    useGetAllUserReportsSbMutation,
    useGetChartDataMutation,
    useGetDistValuesMutation,
    useGetFilteredTablesMutation,
    useGetListViewDataMutation,
    useGetLocationsMutation,
    useGetReportByIdSbMutation,
    useGetReportTableDataMutation,
    useGetTableDataMutation,
    useUpdateUserReportSbMutation,
} from "../../../features/report/skorBoard";
import { useNavigate, useParams } from "react-router-dom";
import { FontSize } from "ckeditor5";
import { useCreateDashboardMutation, useGetAllDashboardsMutation, useGetDashboardByIdMutation, useUpdateDashboardMutation } from "../../../features/report/reportApiSlice";
import ReusableReportModal from "../ReportDesigner/components/ReusableReportModal";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { Alert, CircularProgress, Snackbar, Stack } from "@mui/material";

function DashboardBuilder() {

    const [activeNavItem, setActiveNavItem] = React.useState("Home");
    const [isExpand, setIsExpand] = React.useState(true);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [multiSelectedComponents, setMultiSelectedComponents] = useState([]);
    const [components, setComponents] = React.useState([]);
    const [isExcelUpload, setIsExcelUpload] = React.useState(true);
    const [message, setMessage] = React.useState("Something went wrong");
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("error");
    const [flag, setFlag] = React.useState(false);
    const [componentCount, setComponentCount] = useState(1);
    const [disableRndDragging, setDisableRndDragging] = React.useState(false);
    const [isSelectReport, setIsSelectReport] = React.useState(false);
    const [dashboardId, setDashboardId] = React.useState(null);
    const [isLoadingReport, setIsLodingReport] = React.useState(false);
    const [isLoadingTemplate, setIsLoadingTemplate] = React.useState(false);
    const [tables, setTables] = React.useState([]);
    const [myReports, setMyReports] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [listData, setListData] = React.useState([]);
    const [isBorder, setIsBorder] = React.useState(true);
    const [selectedColumn, setSelectedColumn] = React.useState(null);
    const [loadingStates, setLoadingStates] = useState({});
    const [isDashboardLoading, setIsDashboardLoading] = useState(false);

    const [isAddingMeasure, setIsAddingMeasure] = useState(false);
    const [newMeasure, setNewMeasure] = useState({
        name: "",
        expression: "",
        status: false,
        oladName: "",
    });
    const [showDropdown, setShowDropdown] = useState(false);

    const [isNewReportActive, setIsNewReportActive] = React.useState(false);
    const [isCloseReport, setIsCloseReport] = React.useState(false);
    const [isNewReport, setIsnewReport] = React.useState(false);
    const [isSaveAsReport, setIsSaveAsReport] = React.useState(false);
    const [isNewReportClicked, setIsNewReportClicked] = React.useState(false);
    const [isSaveAsClicked, setIsSaveAsClicked] = React.useState(false);
    const [isSaveChanges, setIsSaveChanges] = React.useState(false);
    const [isGetById, setIsGetById] = React.useState({
        status: false,
        dashboardId: null,
    });
    const [reportState, setReportState] = useState({
        name: "",
        description: "",
    });
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const [loadingTables, setLoadingTables] = React.useState(true);
    const [isAnimated, setIsAnimated] = useState(true);
    const [pdfName, setPdfName] = React.useState("Report.pdf");
    const [hasValidData, setHasValidData] = React.useState(false);
    const customRef = React?.useRef(null);
    const [isDisableTable, setIsDisableTable] = React.useState(false);
    const [islistViewScreen, setIsListViewScreen] = React.useState(false);
    const [filteredReport, setFilteredReport] = React.useState({ status: false, filters: [] });
    const navigate = useNavigate();

    const [inputValue, setInputValue] = React.useState('');
    const [dateRange, setDateRange] = React.useState([null, null]);
    const [dateR, setDateR] = React.useState(null);
    const [selectedColumnsFilters, setSelectedColumnsFilters] = React.useState([]);
    const [dateColumn, setDateColumn] = React.useState(null);
    const [isUpdateChartUi, setIsUpdateChartUi] = React.useState(false);
    const dropRef = React.useRef(null);
    const [values, setValues] = React.useState([]);

    // Menu Filters
    const [searchValue, setSearchValue] = React.useState('');
    const [dateRangeMenu, setDateRangeMenu] = React.useState([null, null]);
    const [dateRMenu, setDateRMenu] = React.useState(null);
    const [isListLoading, setIsListLoading] = React.useState(false);
    const [isSelectDateColumn, setIsSelectDateColumn] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [skip, setSkip] = React.useState(0);
    const [isTableDataLoading, setIsTableLoading] = React.useState(false)
    const [isFilterLoading, setIsFilterLoading] = React.useState(false);
    const [getAllTablesSkorBoard] = useGetAllTableSkorBoardMutation();
    // const [getTableDataSkorBoard] = useGetTableDataMutation();
    const [getTableDataSkorBoard] = useGetReportTableDataMutation()
    const [getAllUserReports] = useGetAllUserReportsSbMutation();
    const [getUserReportsById] = useGetReportByIdSbMutation();
    const [createUserReport] = useCreteUserReportSbMutation();
    const [updateUserReport] = useUpdateUserReportSbMutation();
    const [calculateExpression] = useCalculateExpressionMutation();
    const [getLocations] = useGetLocationsMutation();
    const [getFilteredTables] = useGetFilteredTablesMutation();
    const [getListVewFilterData] = useGetListViewDataMutation();
    const [getDistValues] = useGetDistValuesMutation();
    const [getChartData] = useGetChartDataMutation();


    const [getAllDashboards] = useGetAllDashboardsMutation();
    const [createDashboard] = useCreateDashboardMutation();
    const [updateDashboard] = useUpdateDashboardMutation();
    const [getDashboardById] = useGetDashboardByIdMutation();


    const targetDivRef = React.useRef(null);
    const echartsRef = React.useRef(null);
    const colors = [
        "#bc5090",
        "#ff6361",
        "#ff9d1c",
        "#6d6e75",
        "#6a5acd",
        "#483d8b",
        "#4169e1",
        "#4682b4",
        "#00bfff",
        "#1e90ff",
        "#87cefa",
        "#6495ed",
        "#006400",
        "#228b22",
        "#3cb371",
        "#a0522d",
        "#d2691e",
        "#cd5c5c",
        "#f08080",
        "#fa8072",
        "#e9967a",
        "#2e8b57",
        "#f4a460",
        "#deb887",
        "#d2b48c",
        "#20b2aa",
        "#ffdead",
        "#f5deb3",
        "#8b4513",
        "#d3d3d3",
        "#c0c0c0",
        "#a9a9a9",
        "#808080",
        "#708090",
        "#8b008b",
        "#800080",
        "#4b0082",
        "#7b68ee",
        "#3c1874",
        "#7a378b",
        "#b452cd",
        "#a020f0",
        "#9370db",
        "#5f9ea0",
        "#00ced1",
        "#40e0d0",
        "#48d1cc",
        "#00fa9a",
        "#32cd32",
        "#adff2f",
        "#9acd32",
        "#808000",
        "#6b8e23",
        "#556b2f",
        "#00ff00",
        "#7cfc00",
        "#7fff00",
        "#00ff7f",
        "#66cdaa",
        "#8fbc8f",
        "#90ee90",
        "#98fb98",
        "#ffe4c4",
        "#2f4f4f",
        "#778899",
        "#b0c4de",
        "#dcdcdc",
        "#c71585",
        "#f5f5dc",
        "#f0e68c",
        "#dcdcdc",
        "#ff1493",
        "#c71585",
        "#ff4500",
        "#ff6347",
        "#ff69b4",
        "#ffb6c1",
        "#db7093",
        "#ff8247",
        "#f0e68c",
        "#003f5c",
        "#665191",
        "#a05195",
        "#d45087",
        "#f95d6a",
        "#ff7c43",
        "#ffa600",
        "#58508d",
    ];
    const showAlert = (msg, severity) => {
        setOpen(true);
        setMessage(msg);
        setSeverity(severity);
    };
    const getTables = async () => {
        try {
            setLoadingTables(true);
            const result = await getAllTablesSkorBoard().unwrap();
            setTables(result?.data);
            setLoadingTables(false);
            return result?.data;
        } catch (error) {
            console.log(error, "Error");
        }
    };
    const handleReportFirst = async () => {
        const tables = await getTables();
        await getAllReports(tables);
        await getLocationsSb();
    }
    const calculateExpressions = async (payload) => {
        try {
            const result = await calculateExpression(payload).unwrap();

            return result;
        } catch (err) {
            showAlert(err?.data?.message, "error");
        }
    };
    const getAllReports = async (tables) => {
        try {
            const result = await getAllDashboards().unwrap();
            setMyReports(result?.data);
            console.log(result?.data[0]?.id, ">>>> KKKK");
            dashboardId === null && result?.data?.length > 0 && await getReportById(result?.data[0]?.id, tables);
            setIsNewReportActive(false);
            setIsSaveChanges(false);
        } catch (error) {
            setIsLodingReport(false)
            console.log(error, "Error");
        }
    };
    const getReportById = async (id, myTables) => {
        setIsGetById({ status: false, dashboardId: null });
        setIsNewReportActive(false);
        setIsLodingReport(true);
        setDateRMenu(null);
        setSearchValue('');
        setDateRangeMenu([null, null])

        await setComponents([]);
        try {
            const response = await getDashboardById({ id, limit: 200, skip: 0 }).unwrap(); // Fetch user reports by ID and unwrap the result
            setPdfName(response?.data?.name || "Report");
            const result = response?.data?.components || [];


            let allExpressions = result
                ?.filter((item) => item?.type === "summery")
                ?.map((summery) =>
                    summery?.properties?.measures?.map((i) => ({
                        expression: i?.computed_expression,
                        component_id: summery?.id,
                    }))
                );

            allExpressions = allExpressions?.flatMap((group) =>
                group?.map(({ expression, component_id }) => ({
                    expression,
                    component_id,
                }))
            );
            const calculatedExp =
                allExpressions &&
                allExpressions?.length > 0 &&
                (await calculateExpression({ expressions: allExpressions })?.unwrap());

            let updatedComponents = result?.map((component, index) => {

                if (component?.type === "summery") {
                    // Create a shallow copy of the summary
                    return {
                        ...component,
                        id: `component-${index + 1}`,
                        properties: {
                            ...component?.properties,
                            layouts: { ...component?.properties?.layouts, i: `component-${index + 1}` },
                            measures: component?.properties.measures?.map((measure) => {
                                // Find the matching updated result
                                const updated = calculatedExp?.data?.find(
                                    (res) =>
                                        res.component_id === component?.id &&
                                        res.expression === measure?.computed_expression
                                );
                                // Return a new measure object
                                return updated
                                    ? { ...measure, result: updated.result }
                                    : { ...measure };
                            }),
                        },
                    };
                }
                const tableToUse = myTables ? myTables : tables;
                if (component?.type === "table") {
                    let table = tableToUse?.find(
                        (item) =>
                            item?.tableMetaData?.table_id === component?.properties?.tableId
                    );

                    return {
                        ...component,
                        id: `component-${index + 1}`,
                        properties: {
                            ...component?.properties,
                            layouts: { ...component?.properties?.layouts, i: `component-${index + 1}` },
                            columns: component?.properties?.columns?.map((item) => {

                                return {
                                    ...item,
                                    columnName: table?.columnMetaData?.find(
                                        (i) => i?.column_id === item?.columnId
                                    )?.column_name,
                                };
                            }),
                        },
                    };
                }
                if (component?.type === 'chart') {
                    setLoadingStates((prev) => ({ ...prev, [`component-${index + 1}`]: true }));
                }
                return {
                    ...component, id: `component-${index + 1}`,
                    properties: {
                        ...component?.properties,
                        layouts: { ...component?.properties?.layouts, i: `component-${index + 1}` }
                    }
                };
            });


            setComponents(updatedComponents);
            setComponentCount(updatedComponents?.length + 1);
            //  }
            setFlag(true);
            setIsLodingReport(false);
            setDashboardId(response?.data?.id);
            setIsSelectReport(true);
            setSelectedComponent(null);
            setIsUpdateChartUi(true);
        } catch (error) {
            // Handle error appropriately (e.g., logging, displaying an error message)
            console.error("Error fetching report by ID:", error);
        }
    };

    const getLocationsSb = async () => {
        try {
            const result = await getLocations().unwrap();
            setLocations(result?.data);
        } catch (err) {
            console.log(err, "Error");
        }
    };

    const handleAddColumnValues = async (column) => {
        const Distvalues = await getDistValues({
            tableId: selectedComponent?.properties?.tableId,
            filters: {
                // columnFilters: {
                //     column_name: selectedColumn?.name,
                //     searchQuery: searchString
                // }
                columns: [column?.column_name]
            },
            //  skip: 0,
            limit: 100000,
        })?.unwrap();
        console.log(Distvalues, "distvaluesss")
        return Distvalues;
    }

    const getDefaultProperties = (type) => {
        switch (type) {
            case "table":
                return {
                    height: 380,
                    width: 925,
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "solid",
                    borderColor: "#B7B7B7",
                    borderWidth: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    align: "center",
                    textColor: "rgba(0,0, 0, 1)",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 13,
                    name: `Table`,
                    columns: [],
                    columnProperties: getDefaultColumnsProperties([]),
                    data: [],
                    templateId: null,
                    description: null,
                    paddingLeft: 1,
                    paddingTop: 1,
                    paddingRight: 1,
                    paddingBottom: 1,
                    verticalAlign: "middle",
                    allColumns: [],
                    logColumns: [],
                    layouts: {}
                };
            case "chart":
                return {
                    chartType: "bar",
                    //data: currentReportData?.data,
                    columns: ["Column 1", "Column 2"],
                    data: [],
                    option: getDefaultChartOption(),
                    height: 400,
                    width: 800,
                    left: 0,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderRadius: 0,
                    borderType: "solid",
                    borderColor: "#c8c8c8",
                    borderWidth: 1,
                    textColor: "rgba(0,0, 0, 1)",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    TrendLineColor: "rgba(0, 122, 255, 1)",
                    name: "Chart",
                    xAxis: undefined,
                    yAxis: undefined,
                    line: null,
                    allColumns: [],
                    zAxis: [],
                    tableName: "",
                    rowFields: [],
                    colFields: [],
                    dimensions: [],
                    legends: [],
                    slice: [],
                    tableView: "Pivot Table",
                    layouts: {},
                    filters: [],
                    bigValue: null,
                    drillData: {
                        level: 0,
                        level1: { xAxis: "", yAxis: "" },
                        level2: { xAxis: "", yAxis: "" },
                        breadcrumbs: [{ level: 0, xAxisValue: "Main" }],
                    },
                    pieDrillData: {
                        level: 0,
                        level1: { slice: "" },
                        level2: { slice: "" },
                        breadcrumbs: [{ level: 0, sliceValue: "Main" }],
                    },
                    aggType: "Count",
                    tempoXAxis: null,
                    timeGrain: 'day'
                };
            case "textbox":
                return {
                    text: "",
                    height: 120,
                    width: 520,
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "solid",
                    borderColor: "#B7B7B7",
                    borderWidth: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    align: "left",
                    textColor: "#000",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 15,
                    name: "Textbox",
                    paddingLeft: 1,
                    paddingTop: 1,
                    paddingRight: 1,
                    paddingBottom: 1,
                    textdecoration: "none",
                    lineHeight: 1,
                    editorData: "",
                    layouts: {}
                };
            case "image":
                return {
                    src: "",
                    name: "Image",
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    height: 214, //170
                    width: 195,
                    borderType: "solid",
                    borderColor: "#B7B7B7",
                    borderWidth: 1,
                    align: "center",
                    textColor: "#000",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 16,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    layouts: {}
                };
            case "shape":
                return { type: "circle" };
            case "summery":
                return {
                    text: "",
                    height: 80,
                    width: 920,
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "solid",
                    borderColor: "#B7B7B7",
                    borderWidth: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    align: "left",
                    textColor: "#000",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 15,
                    name: "Summery",
                    paddingLeft: 1,
                    paddingTop: 1,
                    paddingRight: 1,
                    paddingBottom: 1,
                    textdecoration: "none",
                    lineHeight: 1,
                    measures: [],
                    layouts: {}
                };
            default:
                return {
                    height: 100,
                    width: 250,
                    left: "0",
                    top: "0",
                    borderType: "solid",
                    borderColor: "#000000",
                    borderWidth: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    name: `Table`,
                    columns: ["Column 1", "Column 2"],
                    data: [{ "Column 1": "Data 1", "Column 2": "Data 2" }],
                    templateId: null,
                    layouts: {}
                };
        }
    };
    const getDefaultColumnsProperties = (columns) => {
        return columns?.map((item, index) => {
            return {
                style: {
                    id: index,
                    name: item,
                    align: undefined,
                    borderType: undefined,
                    borderColor: undefined,
                    borderWidth: 1,
                    backgroundColor: undefined,
                    textColor: undefined,
                    active: true,
                    fontFamily: undefined,
                    fontWeight: undefined,
                    fontSize: undefined,
                    isIncoming: true,
                    isComputedColumn: false,
                    computedColumns: {},
                },
            };
        });
    };

    const getDefaultChartOption = () => ({
        // backgroundColor: "transparent",
        backgroundColor: 'white',
        tooltip: {
            trigger: 'axis',
            enterable: true,
            textStyle: {
                fontSize: 12,
            },
            axisPointer: {
                type: 'shadow',
                label: {
                    show: true
                }
            },
            extraCssText: 'max-height: 300px; overflow-y: auto; z-index: 9999; pointer-events: auto;',
            appendToBody: true,
        },
        legend: {
            type: "scroll",
            top: "top",
            orient: "horizontal",
            pageButtonPosition: "end",
            pageIconSize: 12,
            textStyle: { color: "#4b5563", fontSize: 12 },
            pageFormatter: "{current}/{total}",
            animation: true,
            selectedMode: "multiple",
        },
        grid: {
            left: "10%",
            right: "10%",
            bottom: "15%",
            containLabel: true,
        },
        // xAxis: {
        //     data: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"],
        //     axisLabel: {
        //         interval: 0,
        //         rotate: "45",
        //         color: "#4b5563",
        //         fontSize: 11,
        //         fontFamily: "Inter",
        //         // formatter: function (value) {
        //         //     return value.length > 5 ? value.substring(0, 5) + "..." : value;
        //         // },
        //     },
        //     axisTick: {
        //         alignWithLabel: true
        //     }
        // },
        yAxis: [{
            type: "value",
            min: 0,
            interval: 2,
            axisLabel: {
                color: "#4b5563",
                fontSize: 11,
                fontFamily: undefined,
            },
        }],
        series: [
            {
                name: "Example",
                type: "bar",
                category: "Column 2",
                // data: [5, 4, 4, 5, 3, 2, 6, 7, 4, 3, 5],
                data: [],
                itemStyle: { color: "#ddd" },
                label: { show: false },
            },
        ],
        dataZoom: [
            {
                type: "slider",
                show: false,
                start: 0,
                // end: 2,
                height: 10,
                handleSize: "150%",
                handleStyle: {
                    color: "#4b5563",
                    borderColor: "#1e293b",
                    borderWidth: 1,
                },
                fillerColor: "rgba(59,130,246,0.4)",
                backgroundColor: "rgba(0,0,0,0.1)",
                borderColor: "rgba(0,0,0,0.2)",
                textStyle: { color: "#4b5563" },
            },
            {
                type: "inside",
                start: 0,
                //  end: 2,
            },
        ],
        progressive: 400,  // Progressive rendering
        progressiveThreshold: 3000, // Enable for large datasets
    });

    const getTableData = async ({ tableId, tableName, columnName, columnId }) => {
        const result = await getTableDataSkorBoard({
            name: tableName,
            tableId: tableId,
            // skip: 0,
            // limit: 200
        }).unwrap();
        const columnProToUp = result?.data?.tableProperties?.columnProperties?.map(
            (item) => {
                return {
                    ...item,
                    columnId: result?.data?.metaData?.columnMetaData?.find(
                        (i) => i?.column_name === item?.name
                    )?.column_id,
                };
            }
        );
        let top = 0;
        await components.forEach((component) => {
            const childElement = document.getElementById(component.id);
            if (childElement) {
                const childBottom =
                    component.properties.top + childElement.offsetHeight + 10;
                top = Math.max(top, childBottom);
            }
        });

        if (components?.length <= 0) {
            let propertiesI = await getDefaultProperties("table");
            const parentEle = dropRef?.current;
            propertiesI.left = 0;
            propertiesI.top = top;
            propertiesI.layouts.w = 2;
            propertiesI.layouts.h = 4;
            propertiesI.layouts.i = `component-${componentCount}`;
            propertiesI.layouts.x = 0;
            propertiesI.layouts.y = 0;
            propertiesI.width = parentEle ? parentEle?.offsetWidth : 1020;
            setSelectedComponent({
                id: `component-${componentCount}`,
                type: "table",
                name: tableName,
                properties: {
                    ...propertiesI,
                    name: tableName,
                    // tableId: result?.data?.metaData?.tableMetaData?.table_id,
                    tableId: tableId,
                    // data: result?.data?.tableData,
                    data: result?.data?.data,
                    columns: [{ columnName, columnId, count: 0 }],
                    columnProperties: columnProToUp,
                    allColumns: result?.data?.tableProperties?.columnProperties
                        ?.filter((item) => !item?.isDeleted && !item?.isHidden)
                        ?.map((item) => item?.name),
                },
            });
            setComponents([
                ...components,
                {
                    id: `component-${componentCount}`,
                    type: "table",
                    properties: {
                        ...propertiesI,
                        name: tableName,
                        // tableId: result?.data?.metaData?.tableMetaData?.table_id,
                        tableId: tableId,
                        // data: result?.data?.tableData,
                        data: result?.data?.data,
                        columns: [{ columnName, columnId, count: 0 }],
                        columnProperties: columnProToUp,
                        allColumns: result?.data?.tableProperties?.columnProperties
                            ?.filter((item) => !item?.isDeleted && !item?.isHidden)
                            ?.map((item) => item?.name),
                    },
                },
            ]);
            setComponentCount(componentCount + 1);
        } else {
            setSelectedComponent({
                ...selectedComponent,
                properties: {
                    ...selectedComponent?.properties,
                    // tableId: result?.data?.metaData?.tableMetaData?.table_id,
                    tableId: tableId,
                    // data: result?.data?.tableData,
                    data: result?.data?.data,
                    name: tableName,
                    columns: [{ columnName, columnId, count: 0 }],
                    columnProperties: columnProToUp,
                    allColumns: result?.data?.tableProperties?.columnProperties
                        ?.filter((item) => !item?.isDeleted && !item?.isHidden)
                        ?.map((item) => item?.name),
                },
            });
            handlePropertyChange({
                // data: result?.data?.tableData,
                data: result?.data?.data,
                // tableId: result?.data?.metaData?.tableMetaData?.table_id,
                tableId: tableId,
                columns: [{ columnName, columnId, count: 0 }],
                name: tableName,
                columnProperties: columnProToUp,
                allColumns: result?.data?.tableProperties?.columnProperties
                    ?.filter((item) => !item?.isDeleted && !item?.isHidden)
                    ?.map((item) => item?.name),
            });
        }
        setFlag(true);
    };
    const handlePropertyChange = (propertyChange) => {
        const updatedComponents = components?.map((item) => {
            if (item?.id === selectedComponent?.id) {
                return {
                    ...item,
                    properties: {
                        ...item?.properties,
                        ...propertyChange,
                    },
                };
            }
            return item;
        });
        console.log(updatedComponents, ">>> UPDATED COMPONENT");
        setComponents(updatedComponents);
    };
    const getTableDataForSelectAll = async ({ tableId, tableName, columns }) => {
        console.log(tableId, "tableidddd")
        const result = await getTableDataSkorBoard({
            name: tableName,
            tableId: tableId,
            // skip: 0,
            // limit: 200
        }).unwrap();
        const columnProToUp = result?.data?.tableProperties?.columnProperties?.map((item) => ({
            ...item,
            columnId: result?.data?.metaData?.columnMetaData?.find((i) => i?.column_name === item?.name)?.column_id,
        }));

        // const allColumns = columnProToUp.map((item) => ({
        //   columnName: item?.name,
        //   columnId: item?.columnId,
        //   count: 0,
        // }));

        const allColumns = columnProToUp
            ?.filter((item) => !item?.isDeleted && !item?.isHidden && item?.name !== `${String(tableName?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`)
            .map((item) => ({
                columnName: item?.name,
                columnId: item?.columnId,
                count: 0,
            }));

        let top = 0;
        await components.forEach((component) => {
            const childElement = document.getElementById(component.id);
            if (childElement) {
                const childBottom =
                    component.properties.top + childElement.offsetHeight + 10;
                top = Math.max(top, childBottom);
            }
        });
        if (components?.length <= 0) {
            let propertiesI = await getDefaultProperties("table");
            const parentEle = dropRef?.current;
            propertiesI.left = 0;
            propertiesI.top = top;
            propertiesI.layouts.w = 2;
            propertiesI.layouts.h = 4;
            propertiesI.layouts.i = `component-${componentCount}`;
            propertiesI.layouts.x = 0;
            propertiesI.layouts.y = 0;
            propertiesI.width = parentEle ? parentEle?.offsetWidth : 1020;

            setSelectedComponent({
                id: `component-${componentCount}`,
                type: "table",
                name: tableName,
                properties: {
                    ...propertiesI,
                    name: tableName,
                    tableId: tableId,
                    data: result?.data?.data,
                    // columns: allColumns, // Use all columns instead of a single one
                    columns: columns,
                    columnProperties: columnProToUp,
                    allColumns: result?.data?.tableProperties?.columnProperties
                        ?.filter((item) => !item?.isDeleted && !item?.isHidden)
                        ?.map((item) => item?.name),
                },
            });

            setComponents([
                ...components,
                {
                    id: `component-${componentCount}`,
                    type: "table",
                    properties: {
                        ...propertiesI,
                        name: tableName,
                        tableId: tableId,
                        data: result?.data?.data,
                        // columns: allColumns, // Use all columns
                        columns: columns,
                        columnProperties: columnProToUp,
                        allColumns: result?.data?.tableProperties?.columnProperties
                            ?.filter((item) => !item?.isDeleted && !item?.isHidden)
                            ?.map((item) => item?.name),
                    },
                },
            ]);

            setComponentCount(componentCount + 1);
        } else {
            setSelectedComponent({
                ...selectedComponent,
                properties: {
                    ...selectedComponent?.properties,
                    tableId: tableId,
                    data: result?.data?.data,
                    name: tableName,
                    // columns: allColumns, // Use all columns
                    columns: columns,
                    columnProperties: columnProToUp,
                    allColumns: result?.data?.tableProperties?.columnProperties
                        ?.filter((item) => !item?.isDeleted && !item?.isHidden)
                        ?.map((item) => item?.name),
                },
            });

            handlePropertyChange({
                data: result?.data?.data,
                tableId: tableId,
                // columns: allColumns, // Use all columns
                columns: columns,
                name: tableName,
                columnProperties: columnProToUp,
                allColumns: result?.data?.tableProperties?.columnProperties
                    ?.filter((item) => !item?.isDeleted && !item?.isHidden)
                    ?.map((item) => item?.name),
            });
        }

        setFlag(false);

        return allColumns; // Return the entire columns array
    };
    // const handleComponentDrop = async (item, size, isClick = false) => {
    //     if (isDisableTable && item?.type === "table") {
    //         return;
    //     }
    //     if (size) {
    //         item.properties.height = size?.height;
    //         item.properties.width = size?.width;
    //     }
    //     item?.type === "table" ? setIsDisableTable(true) : "";
    //     if (!components.some((component) => component?.id === item?.id)) {
    //         const parentEle = dropRef?.current;
    //         let top = 0;
    //         await components.forEach((component) => {
    //             const childElement = document.getElementById(component.id);
    //             if (childElement) {
    //                 const childBottom =
    //                     component.properties.top + childElement.offsetHeight + 10;
    //                 top = Math.max(top, childBottom);
    //             }
    //         });
    //         setFlag(true);
    //         //    let propertiesI = await getDefaultProperties(item.type);
    //         let propertiesI = item?.properties;
    //         propertiesI.left = 0;
    //         if (isClick) {
    //             propertiesI.top = top;
    //         }
    //         propertiesI.layouts.w = 2;
    //         propertiesI.layouts.h = 4;
    //         propertiesI.layouts.i = `component-${componentCount}`;
    //         propertiesI.layouts.x = 0;
    //         propertiesI.layouts.y = 0;
    //         console.log(propertiesI, "propertiessssss")
    //         propertiesI.width = parentEle ? parentEle.offsetWidth : 1020;
    //         const newComponent = {
    //             id: `component-${componentCount}`,
    //             type: item?.type,
    //             properties: propertiesI,
    //         };
    //         setSelectedComponent(newComponent);
    //         setComponents([...components, newComponent]);
    //         setComponentCount(componentCount + 1);
    //         setIsAddingMeasure(false);
    //         setNewMeasure({ name: "", expression: "", status: false, oladName: "" });
    //         setShowDropdown(false);
    //     }
    // };


    const handleComponentDrop = async (item, size, isClick = false) => {
        if (isDisableTable && item?.type === "table") {
            return;
        }
        if (size) {
            item.properties.height = size?.height;
            item.properties.width = size?.width;
        }
        item?.type === "table" ? setIsDisableTable(true) : "";

        if (!components.some((component) => component?.id === item?.id)) {
            const parentEle = dropRef?.current;

            // Calculate the next available grid position
            const cols = 4; // Number of columns in your grid
            const rowHeight = 40;
            let x = 0, y = 0;

            if (components.length > 0) {
                const lastComponent = components[components.length - 1];
                const lastX = lastComponent.properties.layouts.x;
                const lastY = lastComponent.properties.layouts.y;
                const lastW = lastComponent.properties.layouts.w;

                // Try placing in the next column first
                if (lastX + lastW < cols) {
                    x = lastX + lastW;
                    y = lastY;
                } else {
                    // Move to next row if no space in the current row
                    x = 0;
                    y = lastY + lastComponent.properties.layouts.h;
                }
            }

            setFlag(true);

            let propertiesI = item?.properties;
            propertiesI.left = 0;
            if (isClick) {
                propertiesI.top = y * rowHeight;
            }

            // Assign grid layout values
            propertiesI.layouts = {
                // w: 2, // Default width in grid units
                // h: 6, // Default height in grid units
                w: propertiesI?.chartType === "big_number" ? 1
                    : item?.type === "textbox" ? 2
                        : item?.type === "image" ? 1
                            : 2,

                h: propertiesI?.chartType === "big_number" ? 3
                    : item?.type === "textbox" ? 4
                        : item?.type === "image" ? 4
                            : 6,
                i: `component-${componentCount}`,
                x: x,
                y: y,
            };

            console.log(propertiesI, "Updated properties for grid");

            propertiesI.width = parentEle ? parentEle.offsetWidth / cols : 250;

            const newComponent = {
                id: `component-${componentCount}`,
                type: item?.type,
                properties: propertiesI,
            };
            setSelectedComponent(newComponent);
            setComponents([...components, newComponent]);
            setComponentCount(componentCount + 1);
            setIsAddingMeasure(false);
            setNewMeasure({ name: "", expression: "", status: false, oladName: "" });
            setShowDropdown(false);
        }
    };


    const handleComponentSelect = (event, item, id) => {
        if (filteredReport.status === true && item.type !== "table") {
            return;
        }
        if (event.ctrlKey) {
            setMultiSelectedComponents((prev) => {
                if (prev.includes(id)) {
                    return prev.filter((compId) => compId !== id); // Deselect if already selected
                } else {
                    if (selectedComponent !== null) {
                        return [...prev, id, selectedComponent?.id]; // Select additional component
                    } else {
                        return [...prev, id]; // Select additional component
                    }
                }
            });
        } else {
            // Normal selection: set only one component in object state
            // setSelectedComponent({ id, properties: components.find((item) => item.id === id)?.properties });
            // setMultiSelectedComponents([]); // Reset multi-selection
            if (selectedComponent?.id !== item?.id) {
                setIsAddingMeasure(false);
                setNewMeasure({
                    name: "",
                    expression: "",
                    status: false,
                    oladName: "",
                });
                setShowDropdown(false);
            }
            setSelectedComponent(item);
            setFlag(true);
        }
    };
    const handleDeleteComponent = (id) => {
        setSelectedComponent(null);
        setMultiSelectedComponents((prev) => prev?.filter((item) => item !== id));
        const newComponents = components?.filter(
            (component) => component?.id !== id
        );
        // const hasTableComponent = newComponents.some(component => component.type === 'table');
        // const hasChartComponent = newComponents.some(component => component?.type === 'chart');
        // isTableView && !hasTableComponent ? setIsTableView(false) : "";
        // isChartView && !hasChartComponent ? setIsChartView(false) : "";
        setIsAddingMeasure(false);
        setNewMeasure({ name: "", expression: "", status: false, oladName: "" });
        setComponents(newComponents);
    };
    const handleAddMeasure = () => {
        setIsAddingMeasure(true); // Show the input fields for adding a measure
        setNewMeasure({ ...newMeasure, status: true });
    };

    const handleCloseReport = async () => {
        setIsnewReport(false);
        setIsCloseReport(false);
        setDashboardId(null);
        setIsNewReportActive(false);
        setComponents([]);
        setSelectedComponent(null);
    };

    const handleSaveDashboard = async ({ parentId }) => {
        if (components?.length <= 0) {
            showAlert("Dashboard is Empty", "error");
            setReportState({ name: "", description: "" });
            setIsnewReport(false);
            return;
        }
        setIsnewReport(false);
        setSelectedComponent(null);
        const sanitizedComponents = components.map((component) => {
            if (
                (component?.type === "table" || component?.type === "chart") &&
                component?.properties
            ) {
                const { data, ...restProperties } = component.properties;
                return { ...component, properties: restProperties };
            }
            return component;
        });
        setIsSaveChanges(false);
        if (dashboardId !== null && !isSaveAsReport) {
            try {
                const result = await updateDashboard({
                    dashboardId: dashboardId,
                    components: sanitizedComponents,
                    parentId: parentId,
                    //  selectedDataSources: selectedDataSources
                });
                getAllReports();
                showAlert("Dashboard Updated Successfully.", "success");
            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const result = await createDashboard({
                    name: reportState?.name,
                    description: reportState?.description,
                    components: sanitizedComponents,

                    parentId: parentId,
                    // selectedDataSources: selectedDataSources
                }).unwrap();
                setReportState({ name: "", description: "" });
                setIsSaveAsReport(false);
                showAlert("Dashboard Created Successfully.", "success");
                getAllReports();
                setDashboardId(result?.data?.id);
                setIsNewReportActive(false);
            } catch (err) {
                setDashboardId(null);
                setIsNewReportActive(false);
                showAlert(err?.data?.message, "error");
                setReportState({ name: "", description: "" });
                setComponents([]);
                //setSelectedTemplate(null);
                setSelectedComponent(null);
            }
        }
    };
    // const handleDashboardGet = (item) => {
    //     // setIsListViewScreen(true);
    //     //  handleClearFilter();
    //     // handleClearMenu();
    //     console.log("LLLUUUiiuiui");
    //     if (dashboardId !== item?.id) {
    //         getReportById(item?.id);
    //         // setIsLodingReport(true);
    //         navigate(`/analytics/dashboard-builder/${item?.id}`);
    //         setIsNewReportActive(false);
    //         setIsSaveChanges(false);
    //     }
    //     setIsDrawerOpen(false);
    //     console.log(myReports, ">>> REports ,");
    //     const updatedReports = [
    //         item,
    //         ...myReports.filter(report => report.id !== item.id)
    //     ];
    //     setMyReports(updatedReports);

    // }
    const handleDashboardGet = async (item) => {
        console.log("Fetching Report...");

        if (dashboardId !== item?.id) {
            setIsDashboardLoading(true);

            try {
                await getReportById(item?.id); // Fetch report
                navigate(`/analytics/dashboard-builder/${item?.id}`);
                setIsNewReportActive(false);
                setIsSaveChanges(false);
            } finally {
                setIsDashboardLoading(false);
            }
        }
        setIsDrawerOpen(false);
        console.log(myReports, ">>> REports ,");
        const updatedReports = [
            item,
            ...myReports.filter(report => report.id !== item.id)
        ];
        setMyReports(updatedReports);

    }
    const handleSaveHit = () => {
        setIsnewReport(true);
        if (dashboardId === null) {
            setIsSaveAsReport(true);
        } else {
            handleSaveDashboard({ parentId: null });
        }
    };

    const handleNewDashboard = async () => {
        // handleNewReport();

        if (dashboardId !== null && components?.length > 0) {
            setIsnewReport(true);
            setIsSaveChanges(true);
            setIsCloseReport(true);
        } else {
            setIsNewReportActive(true);
            setIsNewReportClicked(true);
            setComponents([]);
            setSelectedComponent(null);
            setDashboardId(null);
        }
    };

    const generateChartData = async (
        xHeader,
        yHeader,
        zHeaders = [
            selectedComponent?.properties?.legend?.map(
                (item) => item?.columnName || []
            ),
        ],
        lineHeader,
        aggFunction = selectedComponent?.properties?.aggType,
        tableId,
        componentId,
        chartType,
        filters,
        seriesTemplate
    ) => {
        try {
            setLoadingStates((prev) => ({ ...prev, [componentId]: true }));
            if (tableId === null) {
                return;
            }
            const generateChartData = await getChartData({
                tableId: tableId ? tableId : selectedComponent?.properties?.tableId,
                chartProperties: {
                    type: chartType,
                    xHeader: xHeader,
                    yHeader: yHeader,
                    zHeaders: zHeaders,
                    lineHeader: lineHeader,
                    aggFunction: aggFunction,
                    seriesTemplate
                },
                filters,
                // skip: 0,
                // limit: 200
            })?.unwrap();
            setLoadingStates((prev) => ({ ...prev, [componentId]: false }));
            return {
                series: generateChartData?.data?.series,
                xValues: generateChartData?.data?.xValues,
                interval: generateChartData?.data?.interval
            };

        } catch (err) {
            console.log(err, "Error")
            setLoadingStates((prev) => ({ ...prev, [componentId]: false }));
        }

    };

    const generateAdvanceChartData = async (payload) => {
        try {
            setLoadingStates((prev) => ({ ...prev, [payload?.componentId]: true }));
            if (payload?.tableId === null) {
                return;
            }
            if (payload?.type === 'funnel' || payload?.type === 'gauge') {
                const generateChartData = await getChartData({
                    tableId: payload?.tableId ? payload?.tableId : selectedComponent?.properties?.tableId,
                    chartProperties: {
                        dimensions: payload?.dimensions?.map((i) => i?.columnName),
                        type: payload?.type,
                        seriesTemplate: payload?.seriesTemplate
                    },
                    filters: payload?.filters,
                    // skip: 0,
                    // limit: 200
                })?.unwrap();
                setLoadingStates((prev) => ({ ...prev, [payload?.componentId]: false }));
                return {
                    series: [generateChartData?.data],
                };
            } else if (payload?.type === 'big_number_trendline') {
                const generateChartData = await getChartData({
                    tableId: payload?.tableId ? payload?.tableId : selectedComponent?.properties?.tableId,
                    chartProperties: {
                        temporalXAxis: payload?.dimensions[0]?.columnName,
                        timeGrain: payload?.timeGrain,
                        type: payload?.type,
                        seriesTemplate: payload?.seriesTemplate
                    },
                    filters: payload?.filters,
                    // skip: 0,
                    // limit: 200
                })?.unwrap();
                setLoadingStates((prev) => ({ ...prev, [payload?.componentId]: false }));

                return {
                    series: [generateChartData?.data],
                };
            } else if (payload?.type === 'big_number') {
                const generateChartData = await getChartData({
                    tableId: payload?.tableId ? payload?.tableId : selectedComponent?.properties?.tableId,
                    chartProperties: {
                        dimensions: payload?.dimensions?.map((i) => i?.columnName),
                        type: payload?.type,

                    },
                    filters: payload?.filters,
                    // skip: 0,
                    // limit: 200
                })?.unwrap();
                setLoadingStates((prev) => ({ ...prev, [payload?.componentId]: false }));
                return generateChartData?.data;
            }

        } catch (err) {
            console.log(err, "Error")
            setLoadingStates((prev) => ({ ...prev, [payload?.componentId]: false }));
        }

    };
    const generatePieChartData = async (
        yHeader,
        aggFunction = selectedComponent?.properties?.aggType,
        tableId,
        componentId,
        chartType,
        filters,
        seriesTemplate
    ) => {

        // const result = {};
        // const countTracker = {}; // For Avg calculation
        console.log(">>> CHart Data gggggg Pi ");

        try {
            setLoadingStates((prev) => ({ ...prev, [componentId]: true }));
            if (tableId === null) {
                return;
            }
            const generateChartData = await getChartData({
                tableId: tableId ? tableId : selectedComponent?.properties?.tableId,
                chartProperties: {
                    type: chartType,
                    xHeader: selectedComponent?.properties?.xAxis,
                    yHeader: yHeader,
                    aggFunction: aggFunction,
                    seriesTemplate
                },
                filters: filters,
                // skip: 0,
                // limit: 200
            })?.unwrap();


            setLoadingStates((prev) => ({ ...prev, [componentId]: false }));
            return {
                series: generateChartData?.data?.series,
                xValues: generateChartData?.data?.xValues,
                interval: generateChartData?.data?.interval
            };
        } catch (err) {
            console.log(err, "Error")
            setLoadingStates((prev) => ({ ...prev, [componentId]: false }));
        }

    };

    const generateDoubleChartData = (
        data,
        xHeader,
        yHeader,
        zHeaders,
        aggFunction = "Sum"
    ) => {
        console.log(zHeaders)
        const xValues = [...new Set(data?.map((row) => row[xHeader]))]; // Get unique x-axis values
        const newZheader = zHeaders ? [yHeader, ...zHeaders] : [yHeader]; // Include yHeader in z-axis processing

        const zAxisResults = {};
        const countTracker = {}; // To track counts for Avg calculation

        // Step 1: Initialize Data Structure
        data.forEach((row) => {
            const xValue = row[xHeader];

            newZheader.forEach((zHeader) => {
                const zValue = row[zHeader];

                if (!zAxisResults[xValue]) {
                    zAxisResults[xValue] = {};
                    countTracker[xValue] = {};
                }

                if (!zAxisResults[xValue][zHeader]) {
                    zAxisResults[xValue][zHeader] =
                        aggFunction === "Min"
                            ? Infinity
                            : aggFunction === "Max"
                                ? -Infinity
                                : 0;
                    countTracker[xValue][zHeader] = 0;
                }

                if (aggFunction === "Sum" || aggFunction === "Avg") {
                    if (Number.isFinite(zValue)) {
                        zAxisResults[xValue][zHeader] += zValue;
                        countTracker[xValue][zHeader]++;
                    }
                } else if (aggFunction === "Count") {
                    zAxisResults[xValue][zHeader]++;
                } else if (aggFunction === "Min") {
                    zAxisResults[xValue][zHeader] = Math.min(
                        zAxisResults[xValue][zHeader],
                        zValue
                    );
                } else if (aggFunction === "Max") {
                    zAxisResults[xValue][zHeader] = Math.max(
                        zAxisResults[xValue][zHeader],
                        zValue
                    );
                }
            });
        });

        // Step 2: Calculate Average if aggFunction is "Avg"
        if (aggFunction === "Avg") {
            Object.keys(zAxisResults).forEach((xValue) => {
                Object.keys(zAxisResults[xValue]).forEach((zHeader) => {
                    if (countTracker[xValue][zHeader] > 0) {
                        zAxisResults[xValue][zHeader] = (
                            zAxisResults[xValue][zHeader] / countTracker[xValue][zHeader]
                        ).toFixed(2);
                    }
                });
            });
        }

        // Step 3: Prepare Series Data for Chart Rendering
        const series = [];
        let colorIndex = 0;

        newZheader.forEach((zHeader, headerIndex) => {
            const uniqueValues = [...new Set(data?.map((row) => row[zHeader]))];

            uniqueValues.forEach((value, valueIndex) => {
                const color =
                    colors[
                    (headerIndex * uniqueValues.length + valueIndex) % colors.length
                    ];

                const seriesItem = {
                    name: value,
                    type: "bar",
                    data: Array(xValues.length).fill(0),
                    itemStyle: { color },
                    category: zHeader,
                };

                xValues.forEach((xValue, index) => {
                    seriesItem.data[index] = zAxisResults[xValue][zHeader] || 0;
                });

                series.push(seriesItem);
            });
        });

        // Step 4: Determine Y-axis Interval
        const overallMaxValue = Math.max(...series.flatMap((s) => s.data));
        const interval =
            overallMaxValue <= 5
                ? 1
                : overallMaxValue <= 10
                    ? 2
                    : overallMaxValue <= 20
                        ? 4
                        : overallMaxValue <= 100
                            ? 10
                            : 20;

        return { series, xValues, interval };
    };

    const exportDashboardPdf = async () => {
        await setSelectedComponent(null);

        const input = document.getElementById("rnd-container-custom");
        const scale = 2; // Higher scale for better quality

        const options = {
            quality: 1,
            width: input.clientWidth * scale,
            height: input.clientHeight * scale,
            style: {
                transform: `scale(${scale})`,
                transformOrigin: "top left",
                fontFamily: window.getComputedStyle(input).fontFamily,
            },
        };
        domtoimage
            .toPng(input, options)
            .then((dataUrl) => {
                const pdf = new jsPDF("p", "mm", "a4");
                const imgWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm
                const imgHeight = (input.clientHeight * imgWidth) / input.clientWidth;
                let position = 0;

                // Splitting the image into multiple pages if it's taller than a single page
                while (position < imgHeight) {
                    pdf.addImage(
                        dataUrl,
                        "PNG",
                        0,
                        position ? -position : 0, // Shift the image up for subsequent pages
                        imgWidth,
                        imgHeight
                    );

                    if (position + pageHeight < imgHeight) {
                        pdf.addPage();
                    }
                    position += pageHeight;
                }
                const finalPdfName = dashboardId ? `${pdfName}` : "Dashboard";
                pdf.save(finalPdfName);
            })
            .catch((error) => console.error("Error generating PDF:", error));
    };

    const updateChartsUi = async (component, filters) => {
        try {


            let result;
            console.log(filters, ">>> Filter hhhhKKKK");
            const updateFilters = (existingFilters, newFilters) => {

                if (!newFilters || newFilters.length === 0) {
                    return existingFilters.filter((item) => !item?.isGlobal);
                }
                const updatedFilters = [...existingFilters];

                newFilters?.forEach((newFilter) => {
                    const index = updatedFilters.findIndex((item) => item.column === newFilter.column);

                    if (index !== -1) {
                        // Replace existing filter if column name matches
                        updatedFilters[index] = newFilter;
                    } else {
                        // Add new filter if no match is found
                        updatedFilters.push(newFilter);
                    }
                });

                return updatedFilters;
            };

            const isGlobalF = components?.filters?.map(item => item?.isGlobal)

            const filtersToApply = updateFilters(component?.properties?.filters || [], filters);
            if (
                component?.properties?.chartType === "bar" ||
                component?.properties?.chartType === "barWithLine" ||
                component?.properties?.chartType === "stacked" ||
                component?.properties?.chartType === "line" ||
                component?.properties?.chartType === "area" ||
                component?.properties?.chartType === "scatter"

            ) {
                if (component?.properties?.chartType !== 'barWithLine') {
                    result = await generateChartData(
                        component?.properties?.xAxis,
                        component?.properties?.yAxis,
                        [component?.properties?.legends[0]?.columnName],
                        null,
                        component?.properties?.aggType,
                        component?.properties?.tableId,
                        component?.id,
                        component?.properties?.chartType,
                        filtersToApply,
                        (filters?.length <= 0 && component?.properties?.seriesTemplate) ? component?.properties?.seriesTemplate : component?.properties?.option?.series
                    );
                } else {
                    result = await generateChartData(
                        component?.properties?.xAxis,
                        component?.properties?.yAxis,
                        [component?.properties?.legends[0]?.columnName],
                        component?.properties?.line?.column_name,
                        component?.properties?.aggType,
                        component?.properties?.tableId,
                        component?.id,
                        component?.properties?.chartType,
                        filtersToApply,
                        (filters?.length <= 0 && component?.properties?.seriesTemplate) ? component?.properties?.seriesTemplate : component?.properties?.option?.series
                    );
                }

                const dataLength = result?.xValues?.length || 0;
                const zoomEnd = dataLength > 200 ? 2 : 50;
                const newOption = {
                    ...component?.properties?.option,
                    // series: result?.series,
                    series: result?.series?.map((s) => ({
                        ...s,
                        label: { ...s.label, show: false },
                        itemStyle: { ...s?.itemStyle, opacity: 1 },
                        ...(s.lineStyle && {
                            lineStyle: { ...s?.lineStyle, opacity: 1 },
                        }),
                        ...(s.areaStyle && {
                            areaStyle: { ...s?.areaStyle, opacity: 0.4 },
                        }),
                    })),
                    xAxis: {
                        ...component?.properties?.option?.xAxis,
                        data: result?.xValues,
                    },
                    legend: { ...component?.properties?.option?.legend, data: result?.series?.map((series) => series.name) },
                    dataZoom: [
                        {
                            ...component?.properties?.option?.dataZoom?.[0],
                            type: "slider",
                            start: 0,
                            end: zoomEnd,
                        },
                        {
                            type: "inside",
                            start: 0,
                            end: zoomEnd,
                        },
                    ],
                };
                // handlePropertyChange({
                //     option: newOption,
                // });

                if (filters?.length > 0 && !component?.properties?.seriesTemplate) {
                    component.properties.seriesTemplate = component.properties.option.series;
                } else if (filters?.filter(i => i?.isGlobal)?.length === 0) {
                    component.properties.seriesTemplate = null;
                }
                component.properties.option = newOption
                component.properties.filters = filtersToApply
                // setSelectedComponent((prev) => ({
                //     ...prev,
                //     properties: {
                //         ...prev.properties,
                //         option: newOption,
                //     },
                // }));
                setIsAnimated(true);
                return component;
            } else if (component?.properties?.chartType === 'big_number_trendline') {

                result = await generateAdvanceChartData({
                    dimensions: component?.properties?.dimensions,
                    tableId: component?.properties?.tableId,
                    componentId: component?.id,
                    type: component?.properties?.chartType,
                    timeGrain: component?.properties?.timeGrain,
                    seriesTemplate: component?.properties?.option?.series,
                    filters: filtersToApply
                });

                const seriesData = result?.series?.[0]?.data || [];

                let latestValue = 0;
                let latestDate = null;

                if (seriesData.length > 0) {
                    const latestDataPoint = seriesData[seriesData.length - 1]?.value;
                    latestDate = new Date(latestDataPoint[0]).toISOString().split("T")[0] || null;
                    latestValue = latestDataPoint[1] || 0;
                }
                const formatNumber = (num) => {
                    if (num >= 1e9) return (num / 1e9).toFixed(1) + "B"; // Billion
                    if (num >= 1e6) return (num / 1e6).toFixed(1) + "M"; // Million
                    if (num >= 1e3) return (num / 1e3).toFixed(1) + "K"; // Thousand
                    return num && num.toString(); // No formatting needed
                };

                // Format the latestValue
                const formattedValue = formatNumber(latestValue);


                const newOption = {
                    ...component?.properties?.option,
                    series: result?.series,
                    title: {
                        ...component?.properties?.option?.title,
                        text: seriesData.length > 0 ? formattedValue : "No Data",
                        subtext: latestDate || "N/A",
                        left: "left",
                        top: "5%",
                        // textStyle: {
                        //     fontSize: 35,
                        //     fontWeight: "bold",
                        //     color: "#333" // Superset-style dark text
                        // },
                        // subtextStyle: {
                        //     fontSize: 15,
                        //     color: "#666"
                        // }
                    },

                    tooltip: {
                        trigger: "axis",
                        axisPointer: { type: "line" },
                        formatter: (params) => {
                            const data = params?.[0];

                            if (!data || !data.value || data.value.length < 2) {
                                return "No data available"; // Handle missing data
                            }

                            const rawDate = data.value[0];
                            const value = data.value[1];

                            const date = new Date(rawDate);
                            const formattedDate = isNaN(date.getTime()) ? "Invalid Date" : date.toISOString().split("T")[0];
                            const formattedValue = isNaN(value) ? "N/A" : value.toLocaleString();

                            return `${formattedDate}<br/><b>${formattedValue}</b>`;
                        }
                    }
                };
                // handlePropertyChange({
                //     option: newOption,
                // });
                component.properties.option = newOption;
                component.properties.filters = filters <= 0 ? component?.properties?.filters?.filter((item) => item?.isGlobal === false) : [...component.properties.filters, ...filters]
                setIsAnimated(true);
                return component;

            } else if (component?.properties?.chartType === 'funnel' || component?.properties?.chartType === 'gauge') {

                result = await generateAdvanceChartData({
                    dimensions: component?.properties?.dimensions,
                    tableId: component?.properties?.tableId,
                    componentId: component?.id,
                    type: component?.properties?.chartType,
                    seriesTemplate: (filters?.length <= 0 && component?.properties?.seriesTemplate) ? component?.properties?.seriesTemplate : component?.properties?.option?.series,
                    filters: filtersToApply,
                });


                console.log(result, ">>>> Result after calling the funnnn ...");
                const newOption = {
                    ...component?.properties?.option,
                    // series: result?.series,
                    series: component?.properties?.chartType === 'gauge' ?
                        result?.series[0]?.map((s) => ({
                            ...s,
                            ...(s.type === "gauge" && {
                                axisLine: {
                                    ...s?.axisLine,
                                    lineStyle: {
                                        ...s?.axisLine?.lineStyle,  // ✅ Corrected property reference
                                        opacity: 1
                                    }
                                }
                            })
                        })) : [{
                            ...result?.series[0][0],
                            data: result?.series[0][0]?.data.map(s => ({
                                ...s,
                                itemStyle: { ...s?.itemStyle, opacity: 1 }
                            }))
                        }],

                    legend: { ...component?.properties?.option?.legend, data: component?.properties?.chartType === 'gauge' ? result?.series[0]?.slice(1)?.map((series) => series.name) : result?.series[0]?.data?.map((series) => series.name) },
                };
                // handlePropertyChange({
                //     option: newOption
                // });
                if (filters?.length > 0 && !component?.properties?.seriesTemplate) {
                    component.properties.seriesTemplate = component.properties.option.series;
                } else if (filters?.filter(i => i?.isGlobal)?.length === 0) {
                    component.properties.seriesTemplate = null;
                }
                component.properties.option = newOption
                component.properties.filters = filtersToApply

                setIsAnimated(true);
                return component;
            }
            else if (component?.properties?.chartType === 'big_number') {
                // const filterToAdd = option?.isFilterApply
                //   ? [
                //     ...component.properties.filters.filter(f => f.columnId !== option?.column?.column_id),
                //     {
                //       column: option?.column?.column_name,
                //       operator: option?.operator?.value,
                //       value: option?.values,
                //       columnId: option?.column?.column_id
                //     }
                //   ]
                //   : component?.properties?.filters;


                // const filterToAdd = option?.isClear
                //     ? option.filters
                //     : option?.isFilterApply
                //         ? [
                //             ...component.properties.filters.filter(f => f.columnId !== option?.column?.column_id),
                //             {
                //                 column: option?.column?.column_name,
                //                 operator: option?.operator?.value,
                //                 value: option?.values,
                //                 columnId: option?.column?.column_id
                //             }
                //         ]
                //         : component?.properties?.filters;

                result = await generateAdvanceChartData({
                    dimensions: component?.properties?.dimensions,
                    tableId: component?.properties?.tableId,
                    componentId: component?.id,
                    type: component?.properties?.chartType,
                    filters: filtersToApply
                });

                component.properties.bigValue = result?.value;
                setIsAnimated(true);
                return component;
            }
            else if (component?.properties?.chartType === 'pie') {
                let result = await generatePieChartData(
                    component?.properties?.slice[0]?.columnName,
                    component?.properties?.aggType,
                    component?.properties?.tableId,
                    component?.id,
                    component?.properties?.chartType,
                    filtersToApply,
                    (filters?.length <= 0 && component?.properties?.seriesTemplate) ? component?.properties?.seriesTemplate : component?.properties?.option?.series
                );
                const newOption = {
                    ...component?.properties?.option,
                    series: [{
                        ...result?.series[0],
                        data: result?.series[0]?.data.map(s => ({
                            ...s,
                            itemStyle: { ...s?.itemStyle, opacity: 1 }
                        }))
                    }],

                    // xAxis: {
                    //     ...component?.properties?.option?.xAxis,
                    //     data: result?.xValues
                    // },
                    // legend: { data: result?.series[0]?.data?.map((item) => item?.name) },
                    legend: { ...component?.properties?.option?.legend, data: result?.series[0]?.data?.map((item) => item?.name) },
                    tooltip: {
                        trigger: "item",
                        enterable: true,
                        textStyle: {
                            fontSize: 12,
                        },
                        formatter: (params) => {
                            return `<b>${params.name}</b>: ${params.value} `;
                        },
                    },
                };
                delete newOption.dataZoom;
                // handlePropertyChange({
                //     option: newOption,
                // });

                if (filters?.length > 0 && !component?.properties?.seriesTemplate) {
                    component.properties.seriesTemplate = component.properties.option.series;
                } else if (filters?.filter(i => i?.isGlobal)?.length === 0) {
                    component.properties.seriesTemplate = null;
                }
                component.properties.option = newOption
                component.properties.filters = filtersToApply
                setIsAnimated(true);
                return component;
            }
        } catch (err) {
            console.log(err, ">>> Error");
            showAlert(err?.data?.message, "error");
        }
    }
    const handleApplyGlobalFilter = async (payload) => {
        setSelectedComponent(null);
        console.log(payload, ">>> Payload .....");
        console.log(payload?.id, ">>> Payload idd nnnnn");
        if (components.length > 0) {
            const updatedComps = await Promise.all(
                components.map(async (component) => {
                    console.log(payload?.id, ">>>>", component?.id);
                    if (component?.type === "chart" && component?.id !== payload?.id && component?.properties?.tableId === payload?.tableId) {
                        return await updateChartsUi(component, payload?.isClear ? [] : payload?.filters?.map((filter) => ({
                            column: filter.column,
                            operator: "IN",
                            value: [filter.value],
                            isGlobal: true,
                        })),);
                    }
                    return component;
                })
            );

            console.log(updatedComps, ">>> Updated components after global filter");
            setComponents(updatedComps);
        }

        console.log(payload, ">>> Payload for global filters .....");
    };

    React.useEffect(() => {
        handleReportFirst();
    }, []);

    React.useEffect(() => {
        const handleKeyDown = async (event) => {
            if (filteredReport?.status === true) return;
            if ((event.ctrlKey || event.metaKey) && event.key === "s") {
                event.preventDefault();
                setIsnewReport(true);

                if (dashboardId === null) {
                    setIsSaveAsReport(true);
                } else {
                    handleSaveDashboard({ parentId: null });
                }
                // } else {
                //     console.error("Cannot save: selectedComponent is null or undefined");
                //     showAlert("No Report found.", "error");
                // }
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [
        selectedComponent,
        components,
        dashboardId,
        pdfName,
        myReports,
        islistViewScreen,
    ]);

    useEffect(() => {
        if (components.length > 0 && isUpdateChartUi) {
            const updateComponents = async () => {
                const updatedComps = await Promise.all(
                    components.map(async (component) => {
                        if (component?.type === "chart") {
                            return await updateChartsUi(component, []);
                        }
                        return component;
                    })
                );
                console.log(updatedComps, ">>>> Updated Components");
                setComponents(updatedComps);
            };

            updateComponents();
            setIsUpdateChartUi(false);
        }
    }, [components, isUpdateChartUi]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Snackbar
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={5000}
                message={message ? message : "Something went wrong"}
                severity="error"
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity={severity ? severity : "error"}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message ? message : "Something went wrong"}
                </Alert>
            </Snackbar>
            {isNewReport && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsnewReport(!isNewReport);
                        setIsSaveAsReport(false);
                        setIsSaveChanges(false);
                        setIsCloseReport(false);
                        setIsGetById({ status: false, dashboardId: null });
                    }}
                    // title={`${!isCloseReport
                    //     ? dashboardId === null || isSaveAsReport
                    //         ? "Create New Report"
                    //         : "Update Report"
                    //     : ""
                    //     }`}
                    title={`${isCloseReport
                        ? isSaveChanges
                            ? dashboardId !== null
                                ? `Save changes to the ${(myReports?.find((item) => item?.id === dashboardId)?.name || "")
                                    .length > 14
                                    ? myReports?.find((item) => item?.id === dashboardId)?.name.substring(0, 14) + "..."
                                    : myReports?.find((item) => item?.id === dashboardId)?.name
                                }`
                                : "Save changes"
                            : "Close Dashboard"
                        : !isCloseReport
                            ? dashboardId === null || isSaveAsReport
                                ? "Create New Dashboard"
                                : "Update Dashboard"
                            : ""
                        }`}

                // title={`${isCloseReport
                //   ? isSaveChanges
                //     ? dashboardId !== null
                //       ? `Save changes to the ${myReports?.find((item) => item?.id === dashboardId)?.name}`
                //       : "Save changes"
                //     : "Close Report"
                //   : !isCloseReport
                //     ? dashboardId === null || isSaveAsReport
                //       ? "Create New Report"
                //       : "Update Report"
                //     : ""
                //   }`}
                >
                    {isCloseReport ? (
                        <div className=" my-2 text-[15px] d-flex flex-col">
                            {/* <div className="px-3">
                  {`${isSaveChanges || isGetById?.status == true
                    ? dashboardId !== null
                      ? `Would you like to save the changes to the ${myReports?.find((item) => item?.id === dashboardId)
                        ?.name
                      }.We are going to close the report?`
                      : "Would you like to save your current changes?"
                    : "Closing this report will discard all changes. Proceed ?"
                    }`}
                </div> */}
                            <div className="px-3">
                                {`${isSaveChanges || isGetById?.status === true
                                    ? dashboardId !== null
                                        ? `Would you like to save the changes to the ${(myReports?.find((item) => item?.id === dashboardId)?.name || "").length > 14
                                            ? myReports?.find((item) => item?.id === dashboardId)?.name.substring(0, 14) + "..."
                                            : myReports?.find((item) => item?.id === dashboardId)?.name
                                        }? We are going to close the Dashboard.`
                                        : "Would you like to save your current changes?"
                                    : "Closing this Dashboard will discard all changes. Proceed?"
                                    }`}
                            </div>

                            <div className="self-end mt-2">
                                <button
                                    onClick={() => {
                                        // if (dashboardId === null) {
                                        //     setIsnewReport(true);
                                        //     setIsSaveAsReport(true);
                                        // } else {

                                        if (isGetById?.status === true) {
                                            setIsCloseReport(false);
                                        } else {
                                            if (isSaveChanges) {
                                                handleSaveDashboard({ parentId: null });
                                                setIsCloseReport(false);
                                                // dashboardId === null ? setIsSaveAsReport(true) : "";
                                                // if (isSaveChanges) {
                                                setIsNewReportClicked(true);
                                                setComponents([]);
                                                setDashboardId(null);
                                                setSelectedComponent(null);
                                                // }
                                            } else {
                                                handleCloseReport();
                                            }
                                            // }
                                        }
                                    }}
                                    className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={() => {
                                        setIsnewReport(false);
                                        setIsCloseReport(false);
                                        if (isGetById?.status == true) {
                                            getReportById(isGetById?.dashboardId);
                                            setIsLodingReport(true);
                                        }
                                        if (isSaveChanges) {
                                            setDashboardId(null);
                                            setIsNewReportClicked(true);
                                            setIsSaveChanges(false);
                                            setComponents([]);
                                            setSelectedComponent(null);
                                            setIsNewReportActive(true);

                                        }
                                    }}
                                    className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    ) : isSaveAsReport ? (
                        <>
                            <div style={{ width: "30rem", height: "14rem" }}>
                                <div className="flex flex-row items-center  mt-2">
                                    <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                                        <p className="m-0 font-bold text-sm whitespace-nowrap">
                                            Name
                                        </p>
                                        <input
                                            type="text"
                                            className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                            value={reportState?.name}
                                            onChange={(e) => {
                                                const { name, value } = e.target;
                                                setReportState((prevState) => ({
                                                    ...prevState,
                                                    name: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row items-center mt-2">
                                    <div className="flex flex-col items-start justify-start w-full gap-1 py-1 pb-2 px-4">
                                        <p className="m-0 text-sm font-bold whitespace-nowrap">
                                            Description
                                        </p>
                                        <input
                                            autoComplete="false"
                                            type="text"
                                            className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                            value={reportState?.description}
                                            onChange={(e) => {
                                                const { name, value } = e.target;
                                                setReportState((prevState) => ({
                                                    ...prevState,
                                                    description: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="pb-2 px-4 mt-3">
                                    <button
                                        disabled={!reportState?.name || !reportState?.description}
                                        onClick={async () => {
                                            await handleSaveDashboard({ parentId: dashboardId });
                                            setPdfName(reportState?.name)
                                            if (isGetById?.status === true) {
                                                getReportById(isGetById?.dashboardId);
                                                setIsLodingReport(true);
                                            }
                                            if (isSaveChanges) {
                                                setDashboardId(null);
                                                setIsNewReportClicked(true);
                                                setComponents([]);
                                                setSelectedComponent(null);
                                            }
                                        }}
                                        className={`px-3 text-sm btn-bgcolor py-2 font-bold text-white rounded-md  disabled:cursor-not-allowed disabled:opacity-40`}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="mx-2 my-2 d-flex flex-col">
                            <span className="text-[15px] mt-1 ">{`Would you like to update the existing report or create a new one ?`}</span>
                            <span className="self-end mt-2">
                                <button
                                    onClick={() => {
                                        //setDashboardId(null);
                                        setIsSaveAsReport(true);
                                    }}
                                    className="px-3 text-sm btn-bgcolor py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                                >
                                    Create
                                </button>
                                <button
                                    onClick={() => {
                                        handleSaveDashboard({ parentId: null });
                                        if (isGetById?.status == true) {
                                            getReportById(isGetById?.dashboardId);
                                            setIsLodingReport(true);
                                        }
                                        if (isSaveChanges) {
                                            setIsNewReportClicked(true);
                                            setComponents([]);
                                            setDashboardId(null);
                                            setSelectedComponent(null);
                                        }
                                    }}
                                    className="px-3 text-sm btn-bgcolor  py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                                >
                                    Save
                                </button>
                            </span>
                        </div>
                    )}
                </ReusableReportModal>
            )}

            <div className="d-flex flex-col" style={{ height: "calc(100vh - 57px)" }}>
                <Nav
                    setActiveNavItem={setActiveNavItem}
                    isDashboardBuilder={true}
                    activeNavItem={activeNavItem}
                />
                <Menu
                    setActiveNavItem={setActiveNavItem}
                    activeNavItem={activeNavItem}
                    setIsExpand={setIsExpand}
                    isExpand={isExpand}
                    isDashboardBuilder={true}
                    handleSaveHit={handleSaveHit}
                    reportId={dashboardId}
                    setIsNewReportActive={setIsNewReportActive}
                    setIsnewReport={setIsnewReport}
                    setIsSaveAsReport={setIsSaveAsReport}
                    reports={myReports}
                    handleReportGet={handleDashboardGet}
                    handleNewReport={handleNewDashboard}
                    exportDashboardPdf={exportDashboardPdf}
                    setSelectedComponent={setSelectedComponent}
                />
                <div
                    className="d-flex grow overflow-auto"
                    style={{ margin: "1px", flexGrow: "1" }}
                >
                    <DataPanel
                        components={components}
                        component={selectedComponent}
                        setSelectedComponent={setSelectedComponent}
                        onPropertyChange={handlePropertyChange}
                        isExpand={isExpand}
                        showAlert={showAlert}
                        tables={tables}
                        getTableData={getTableData}
                        setFlag={setFlag}
                        flag={flag}
                        loadingTables={loadingTables}
                        islistViewScreen={islistViewScreen}
                        filteredReport={filteredReport}
                        dashboardId={dashboardId}
                        getTableDataForSelectAll={getTableDataForSelectAll}
                        dateColumn={dateColumn}
                        setDateColumn={setDateColumn}
                        isLoadingReport={isLoadingReport}
                    />
                    <WidgetsPanel
                        componentCount={componentCount}
                        getDefaultProperties={getDefaultProperties}
                        showAlert={showAlert}
                        onPropertyChange={handlePropertyChange}
                        setSelectedComponent={setSelectedComponent}
                        flag={flag}
                        setFlag={setFlag}
                        reportAreaRef={dropRef}
                        dashboardId={dashboardId}
                        component={selectedComponent}
                        handleComponentDrop={handleComponentDrop}
                        generateChartData={generateChartData}
                        generatePieChartData={generatePieChartData}
                        generateDoubleChartData={generateDoubleChartData}
                        echartsRef={echartsRef}
                        isAnimated={isAnimated}
                        setIsAnimated={setIsAnimated}
                        drillData={
                            components?.find((item) => item?.id === selectedComponent?.id)
                                ?.properties?.drillData
                        }
                        pieDrillData={
                            components?.find((item) => item?.id === selectedComponent?.id)
                                ?.properties?.pieDrillData
                        }
                        tables={tables}
                        isDisableTable={isDisableTable}
                        generateAdvanceChartData={generateAdvanceChartData}
                        handleAddColumnValues={handleAddColumnValues}
                        setValues={setValues}
                        values={values}
                    />
                    <div
                        className="custom-scrollbar"
                        style={{
                            height: "100%",
                            overflow: "auto",
                            outline: "none",
                            // flexGrow: "1",
                            width: '100%',
                            backgroundColor: '#f7f9fb'
                        }}
                    >
                        <DashboardBuildingArea
                            onDrop={handleComponentDrop}
                            onDelete={handleDeleteComponent}
                            components={components}
                            setComponents={setComponents}
                            selectedComponent={
                                selectedComponent !== null ? selectedComponent : null
                            }
                            setSelectedComponent={setSelectedComponent}
                            onPropertyChange={handlePropertyChange}
                            disableRndDragging={disableRndDragging}
                            setFlag={setFlag}
                            dropRef={dropRef}
                            isSelectReport={isSelectReport}
                            setIsSelectReport={setIsSelectReport}
                            dashboardId={dashboardId}
                            isLoadingReport={isLoadingReport}
                            isLoadingTemplate={isLoadingTemplate}
                            handleAddMeasure={handleAddMeasure}
                            isAddingMeasure={isAddingMeasure}
                            showDropdown={showDropdown}
                            multiSelectedComponents={multiSelectedComponents}
                            setMultiSelectedComponents={setMultiSelectedComponents}
                            showAlert={showAlert}
                            customRef={customRef}
                            myReports={myReports}
                            getReportById={getReportById}
                            tables={tables}
                            isTableDataLoading={isTableDataLoading}
                            setIsTableLoading={setIsTableLoading}
                            handleComponentSelect={handleComponentSelect}
                            isDashboardLoading={isDashboardLoading}
                        >
                            {components?.map((component, index) => {
                                return (
                                    <div
                                        key={component.id}
                                        className={`report-component ${index === selectedComponent ? "selected" : ""
                                            } no-scrollbar`}
                                        onClick={(e) =>
                                            handleComponentSelect(e, component, component.id)
                                        } Rsa

                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            overflow:
                                                component?.type === "textbox" ||
                                                    component?.type === "summery"
                                                    ? "unset"
                                                    : "auto",
                                        }}
                                    >
                                        {component.type === "table" && (
                                            <>
                                                <TableComponent
                                                    type={component.properties.type}
                                                    columns={component.properties.columns}
                                                    data={component.properties.data}
                                                    //data={currentReportData.data}
                                                    key={component.id}
                                                    properties={component.properties}
                                                    id={component.id}
                                                    stopTableDrag={() => {
                                                        setDisableRndDragging(true);
                                                    }}
                                                    onPropertyChange={handlePropertyChange}
                                                    setSelectedComponent={setSelectedComponent}
                                                    component={
                                                        selectedComponent !== null
                                                            ? selectedComponent
                                                            : null
                                                    }
                                                    showAlert={showAlert}
                                                    selectedComId={selectedComponent?.id}
                                                    isListViewReport={filteredReport?.status}
                                                />
                                            </>
                                        )}
                                        {component.type === "textbox" && (
                                            <TextboxComponent
                                                type={component.properties.type}
                                                text={component.properties.text}
                                                key={component.id}
                                                properties={component.properties}
                                                id={component.id}
                                                onPropertyChange={handlePropertyChange}
                                                setSelectedComponent={setSelectedComponent}
                                                stopTableDrag={() => {
                                                    setDisableRndDragging(true);
                                                }}
                                                selectedComId={selectedComponent?.id}
                                                component={selectedComponent}
                                            />
                                        )}
                                        {component.type === "image" && (
                                            <ImageComponent
                                                type={component.properties.type}
                                                src={component.properties.src}
                                                key={component.id}
                                                properties={component.properties}
                                                onPropertyChange={handlePropertyChange}
                                                setSelectedComponent={setSelectedComponent}
                                                id={component.id}
                                                selectedComId={selectedComponent?.id}
                                            />
                                        )}
                                        {component.type === "summery" && (
                                            <SummeryComponent
                                                type={component.properties.type}
                                                text={component.properties.text}
                                                key={component.id}
                                                properties={component.properties}
                                                id={component.id}
                                                selectedComId={selectedComponent?.id}
                                                onPropertyChange={handlePropertyChange}
                                                setSelectedComponent={setSelectedComponent}
                                                tables={tables}
                                                showAlert={showAlert}
                                                stopTableDrag={() => {
                                                    setDisableRndDragging(true);
                                                }}
                                                calculateExpressions={calculateExpressions}
                                                targetDivRef={targetDivRef}
                                                component={selectedComponent}
                                                isAddingMeasure={isAddingMeasure}
                                                setIsAddingMeasure={setIsAddingMeasure}
                                                newMeasure={newMeasure}
                                                setNewMeasure={setNewMeasure}
                                                handleAddMeasure={handleAddMeasure}
                                                showDropdown={showDropdown}
                                                setShowDropdown={setShowDropdown}
                                                // usedDataSources={dashboardId !== null
                                                //   ? components
                                                //     ?.filter(component => component?.type === "table" || component?.type === "chart")
                                                //     ?.map(component => component?.properties?.tableId)?.filter(id => id !== undefined)
                                                //   : []}
                                                usedDataSources={components?.length > 0
                                                    ? components
                                                        ?.filter(component => component?.type === "table" || component?.type === "chart")
                                                        ?.map(component => component?.properties?.tableId)
                                                        ?.filter(id => id !== undefined)
                                                    : []}
                                            />
                                        )}
                                        {component.type === "chart" && (
                                            loadingStates[component.id] == true ?
                                                // <Stack sx={{ color: 'grey.500', height: '100%', alignItems: 'center', justifyContent: 'center' }} spacing={2} direction="row">
                                                //     <CircularProgress color="inherit" disableShrink />
                                                // </Stack> 

                                                <Stack
                                                    sx={{
                                                        height: "100%",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <CircularProgress
                                                        thickness={3}
                                                        size={30}
                                                        sx={{
                                                            color: "transparent", // Hide default color
                                                            position: "relative",
                                                            "& .MuiCircularProgress-circle": {
                                                                stroke: "url(#multiColorGradient)", // Apply the gradient stroke
                                                            },
                                                        }}
                                                    />

                                                    <svg width={0} height={0}>
                                                        <defs>
                                                            <linearGradient id="multiColorGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                <stop offset="0%" stopColor="red" />
                                                                <stop offset="20%" stopColor="purple" />
                                                                <stop offset="35%" stopColor="yellow" />
                                                                <stop offset="55%" stopColor="orange" />
                                                                <stop offset="100%" stopColor="green" />
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </Stack>
                                                :
                                                <ChartComponent
                                                    type={component?.properties?.type}
                                                    option={component?.properties?.option}
                                                    data={component?.properties?.data}
                                                    key={index}
                                                    properties={component?.properties}
                                                    echartsRef={echartsRef}
                                                    showAlert={showAlert}
                                                    // isChartView={isChartView}
                                                    // isTableView={isTableView}
                                                    isAnimated={isAnimated}
                                                    setIsAnimated={setIsAnimated}
                                                    stopTableDrag={() => {
                                                        setDisableRndDragging(true);
                                                    }}
                                                    generateChartData={generateChartData}
                                                    id={component.id}
                                                    setFlag={setFlag}
                                                    setSelectedComponent={setSelectedComponent}
                                                    onPropertyChange={handlePropertyChange}
                                                    generatePieChartData={generatePieChartData}
                                                    generateDoubleChartData={generateDoubleChartData}
                                                    drillData={component?.properties?.drillData}
                                                    selectedId={selectedComponent?.id}
                                                    pieDrillData={component?.properties?.pieDrillData}
                                                    handleApplyGlobalFilter={handleApplyGlobalFilter}
                                                />
                                        )}
                                    </div>
                                );
                            })}
                        </DashboardBuildingArea>
                    </div>
                </div>
            </div>
        </DndProvider>
    )
}
export default DashboardBuilder;