import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const RelationIcon = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.height}
            height={props.width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.069 14.069"
            fill={props.fill}
            style={{ paddingTop: '2px', paddingBottom: '2px' }}
        >
            <polygon points="14.069,5.304 14.069,0.16 8.926,0.16 8.926,2.533 5.143,4.861 5.143,4.064 0,4.064 0,9.208 
		5.143,9.208 5.143,8.26 8.926,11.069 8.926,13.91 14.069,13.91 14.069,8.766 8.926,8.766 8.926,9.939 5.143,7.128 5.143,5.927 
		8.926,3.599 8.926,5.304"/>
        </svg>

    </Tooltip>
);

export default RelationIcon;

