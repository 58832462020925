import React, { useState } from "react";
import SignInSteps from "./signin-steps";
import classNames from "classnames";
import RightBanner from "../common/RightBanner";
import PricingPlans from "../common/PricingPlans";

const Signin = () => {
  const [plansModalOpen, setPlansModalOpen] = useState(false);
  const [minStep, setMinStep] = useState(0);

  const handlePlansModalOpen = (open) => {
    setPlansModalOpen(open);
  };

  return (
    <div
      className={classNames(
        "relative flex flex-row h-screen overflow-hidden"
        // !plansModalOpen && "divide-x-2"
      )}
    >
      {!plansModalOpen && (
        <SignInSteps
          handlePlansModalOpen={handlePlansModalOpen}
          minStep={minStep}
          setMinStep={setMinStep}
        />
      )}
      {plansModalOpen && (
        <PricingPlans
          plansModalOpen={plansModalOpen}
          handlePlansModalOpen={handlePlansModalOpen}
          minStep={minStep}
        />
      )}
      {/* <RightBanner /> */}
    </div>
  );
};

export default Signin;
