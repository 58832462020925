import {
  AddDataSource,
  ExcelIcon,
  SampleDataIcon,
  SqlIcon,
} from "../../../../assets/images";
import { CircularProgress, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ColumnIcon from "../../../../assets/svgs/ColumnSvg";
import FunctionIcon from "../../../../assets/svgs/FunctionSvg";
import TableComponent from "./TableComponent";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import DbConnections from "./dbConnections";
import AddDataset from "./AddDataset";
import { usePermissionsStore } from "../../../../context/permissions.store";
import classNames from "classnames";
import useGetPermissions from "../../../../hooks/useGetPermissions";

function MainContainer({
  onCareSMSClick,
  components,
  selectedComponent,
  isTableloading,
  selectedColumn,
  setSelectedColumn,
  onPropertyChange,
  setSelectedComponent,
  limit,
  filterData,
  setCount,
  computedColumn,
  setComputedColumn,
  showAlert,
  query,
  setQuery,
  handleSelectColumn,
  generateColumnName,
  setPrimaryProperties,
  primaryProperties,
  handleCreateComputedColumn,
  setErrorComputed,
  errorComputed,
  handleUploadExcelFile,
  checkColumnsInExpression,
  dateformat,
  handleAddColumnValues,
  dateFormats,
  fetchTableData,
  superset,
  activeNavItem,
  setActiveNavItem,
  getQueriedTableData,
  handleRemoveDatabse,
  errorMessage,
  handleAddNewDatabaseConnection,
  datasetLoadingStep,
  isDatasetLoading,
  setErrorMessage,
}) {
  const aggregationFunctions = [
    "SUM",
    "AVG",
    "COUNT",
    "MAX",
    "MIN",
    "DISTINCTCOUNT",
    "IF",
  ];
  const toolTips = {
    SUM: "SUM(ColumnName)",
    AVG: "AVG(ColumnName)",
    COUNT: "COUNT(ColumnName)",
    MAX: "MAX(ColumnName)",
    MIN: "MIN(ColumnName)",
    DISTINCTCOUNT: "DISTINCTCOUNT(ColumnName)",
    IF: "IF(LogicalTest, ResultIfTrue, [ResultIfFalse])",
  };
  const { hasDBCreationPermission, hasDatasourceCreationPermission } =
    useGetPermissions();
  const [height, setHeight] = React.useState(window?.innerHeight);
  const [filterObj, setFilterObj] = React.useState({ filter_values: [] });
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const suggestionDropDownRef = React.useRef();
  const [dropdownLeft, setDropdownLeft] = React.useState(0);
  const [activeElementIndex, setActiveElementIndex] = React.useState(0);
  const [openDropdowns, setOpenDropdowns] = React.useState({});
  const selectedComponentColumns = selectedComponent?.columnProperties
    ?.filter(
      (item) =>
        item?.name !==
          `${String(
            selectedComponent?.table_name?.replace(/[- ]/g, "_")
          )?.toLowerCase()}_id` &&
        !item?.isDeleted &&
        !item?.isHidden &&
        item?.type === "int" &&
        item?.name !== "id" &&
        !item?.name?.endsWith("_id") &&
        !(
          item?.name === selectedColumn?.name ||
          item?.name === computedColumn?.name
        )
    )
    ?.map((item) => item?.name);

  const [filteredColumns, setFilteredColumns] = useState(
    selectedComponent?.columnProperties
      ?.filter(
        (item) =>
          item?.name !==
            `${String(
              selectedComponent?.table_name?.replace(/[- ]/g, "_")
            )?.toLowerCase()}_id` &&
          !item?.isDeleted &&
          !item?.isHidden &&
          item?.type === "int" &&
          item?.name !== "id" &&
          !item?.name?.endsWith("_id") &&
          !(
            item?.name === selectedColumn?.name ||
            item?.name === computedColumn?.name
          )
      )
      ?.map((item) => item?.name)
  );
  const [filteredFunctions, setFilteredFunctions] =
    useState(aggregationFunctions);

  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [inputWidth, setInputWidth] = React.useState("50px");
  const hiddenSpanRef = React.useRef(null);
  const updateWidth = (value) => {
    if (hiddenSpanRef?.current) {
      hiddenSpanRef.current.textContent = value || "column";
      const newWidth = `${hiddenSpanRef?.current?.offsetWidth}px`;
      setInputWidth(newWidth);
    } else {
      setInputWidth("50px");
    }
  };

  // ----- Filters
  const handleApplyFilter = async () => {
    try {
      const existingFilters = selectedComponent?.filters || [];
      const updatedFilters = existingFilters?.map((f) =>
        f?.column_name === selectedColumn?.name ? { ...f, isApplied: true } : f
      );
      // If no existing filter matches, add the new filter
      const columnAlreadyFiltered = existingFilters?.some(
        (f) => f?.column_name === selectedColumn?.name
      );

      let finalFilters = columnAlreadyFiltered
        ? updatedFilters
        : [
            ...existingFilters,
            {
              ...filterObj,
              column_name: selectedColumn?.name,
              column_type: selectedColumn?.type,
            },
          ];
      finalFilters = finalFilters?.filter(
        (f) => f?.filter_values && f?.filter_values?.length > 0
      );

      const apiResult = await filterData({
        tableName: selectedComponent?.table_name,
        filters: selectedComponent?.filters,
        skip: 0,
        limit,
      }).unwrap();

      setSelectedComponent({
        ...selectedComponent,
        data: apiResult?.data?.data,
        filters: updatedFilters,
      });
      onPropertyChange(
        {
          data: apiResult?.data?.data,
          filters: updatedFilters,
        },
        null
      );
      setCount(apiResult?.data?.totalCount);
      setOpenDropdowns({});
    } catch (err) {
      // showAlert(err?.data?.message, "error");
      console.log(err);
    }
  };
  const handleClearFilter = async () => {
    try {
      if (selectedComponent?.filters?.length <= 0) {
        return;
      }
      const finalFilters = selectedComponent?.filters?.filter(
        (item) => item?.column_name !== selectedColumn?.name
      );

      const apiResult = await filterData({
        tableName: selectedComponent?.table_name,
        filters: finalFilters,
        skip: 0,
        limit,
      }).unwrap();

      setFilterObj({ filter_values: [] });
      setSelectedComponent({
        ...selectedComponent,
        data: apiResult?.data?.data,
        filters: finalFilters,
      });
      onPropertyChange(
        {
          data: apiResult?.data?.data,
          filters: finalFilters,
        },
        null
      );
      setCount(apiResult?.data?.totalCount);
    } catch (err) {
      // showAlert(err?.data?.message, "error");
      console.log(err);
    }
  };
  const handleClearAllFilter = async () => {
    try {
      if (selectedComponent?.filters?.length <= 0) {
        return;
      }
      const apiResult = await filterData({
        tableName: selectedComponent?.table_name,
        filters: [],
        skip: 0,
        limit,
      }).unwrap();

      setFilterObj({ filter_values: [] });
      setSelectedComponent({
        ...selectedComponent,
        data: apiResult?.data?.data,
        filters: [],
      });
      onPropertyChange(
        {
          data: apiResult?.data?.data,
          filters: [],
        },
        null
      );
      setCount(apiResult?.data?.totalCount);
    } catch (err) {
      // showAlert(err?.data?.message, "error");
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    e?.preventDefault();
    const input = e?.target?.value;
    setQuery(input);

    const inputElement = e?.target;
    const cursorPosition = inputElement?.selectionStart;

    const parts = input?.split(/(?=[()+\-*/%])|(?<=[()+\-*/%])/); // Split into tokens
    let activeFunction = null;
    let startBracketIndex = -1;
    let endBracketIndex = -1;

    const stack = [];
    for (let i = 0, cumulativeIndex = 0; i < parts?.length; i++) {
      const token = parts[i];
      const tokenStart = cumulativeIndex;
      const tokenEnd = cumulativeIndex + token?.length;
      cumulativeIndex = tokenEnd;

      if (token?.match(/SUM|COUNT|AVG|MIN|MAX|IF/i)) {
        stack?.push({ functionName: token, index: i });
      }

      if (token === "(" && stack?.length > 0) {
        stack[stack?.length - 1].startBracket = tokenStart;
      }

      if (token === ")" && stack?.length > 0) {
        const currentFunction = stack?.pop();
        currentFunction.endBracket = tokenEnd;

        if (
          cursorPosition >= currentFunction?.startBracket &&
          cursorPosition <= currentFunction?.endBracket
        ) {
          activeFunction = currentFunction?.functionName;
          startBracketIndex = currentFunction?.startBracket;
          endBracketIndex = currentFunction?.endBracket;
          break;
        }
      }
    }

    let availableColumns = selectedComponent?.columnProperties?.filter(
      (item) =>
        item?.name !==
          `${String(
            selectedComponent?.table_name?.replace(/[- ]/g, "_")
          )?.toLowerCase()}_id` &&
        !item?.isDeleted &&
        !item?.isHidden &&
        item?.name !== "id" &&
        !item?.name?.endsWith("_id") &&
        !(
          item?.name === selectedColumn?.name ||
          item?.name === computedColumn?.name
        )
    );

    // if (activeFunction && activeFunction.toUpperCase() === "IF") {
    //   // For IF function, show all columns
    //   availableColumns = availableColumns?.map((item) => item?.name);
    // } else {
    //   // For other functions, show only integer columns
    //   availableColumns = availableColumns
    //     ?.filter((item) => item?.type === "int")
    //     ?.map((item) => item?.name);
    // }
    const isInsideIfFunction =
      stack?.some((func) => func.functionName?.toUpperCase() === "IF") ||
      activeFunction?.toUpperCase() === "IF";

    if (isInsideIfFunction) {
      // If an IF function is active or inside an IF, show all columns
      availableColumns = selectedComponent?.columnProperties
        ?.filter(
          (item) =>
            item?.name !==
              `${String(
                selectedComponent?.table_name?.replace(/[- ]/g, "_")
              )?.toLowerCase()}_id` &&
            !item?.isDeleted &&
            !item?.isHidden &&
            item?.name !== "id" &&
            !item?.name?.endsWith("_id") &&
            !(
              item?.name === selectedColumn?.name ||
              item?.name === computedColumn?.name
            )
        )
        ?.map((item) => item?.name);
    } else {
      // Otherwise, show only integer columns
      availableColumns = selectedComponent?.columnProperties
        ?.filter(
          (item) =>
            item?.type === "int" &&
            item?.name !==
              `${String(
                selectedComponent?.table_name?.replace(/[- ]/g, "_")
              )?.toLowerCase()}_id` &&
            !item?.isDeleted &&
            !item?.isHidden &&
            item?.name !== "id" &&
            !item?.name?.endsWith("_id") &&
            !(
              item?.name === selectedColumn?.name ||
              item?.name === computedColumn?.name
            )
        )
        ?.map((item) => item?.name);
    }

    if (activeFunction && startBracketIndex !== -1 && endBracketIndex !== -1) {
      const insideParentheses = input?.substring(
        startBracketIndex + 1,
        endBracketIndex
      );
      // const insideParts = insideParentheses?.split(/,|\s+/);
      const insideParts = insideParentheses?.split(/,|\s+|[><=!]/);

      let editedPart = null;
      let cumulativeIndex = startBracketIndex + 1;
      for (let part of insideParts) {
        const partStart = cumulativeIndex;
        const partEnd = cumulativeIndex + part?.length;

        if (cursorPosition >= partStart && cursorPosition <= partEnd) {
          editedPart = part?.replace(/[^\w]/g, "");
          break;
        }
        cumulativeIndex = partEnd + 1;
      }

      if (editedPart?.trim()) {
        const filteredCols = availableColumns?.filter((col) =>
          col?.toLowerCase()?.includes(editedPart?.trim()?.toLowerCase())
        );
        setFilteredColumns(filteredCols);

        const filteredFuncs = aggregationFunctions?.filter((func) =>
          func?.toLowerCase()?.includes(editedPart?.trim()?.toLowerCase())
        );
        setFilteredFunctions(filteredFuncs);
      } else {
        setFilteredColumns(availableColumns);
        setFilteredFunctions(aggregationFunctions);
      }
    } else {
      const editedPart = parts
        ?.find((part, index) => {
          const partStart = parts?.slice(0, index)?.join("")?.length;
          const partEnd = partStart + part?.length;
          return cursorPosition >= partStart && cursorPosition <= partEnd;
        })
        ?.trim();

      if (editedPart) {
        const filteredCols = availableColumns?.filter((col) =>
          col?.toLowerCase()?.includes(editedPart?.toLowerCase())
        );
        setFilteredColumns(filteredCols);

        const filteredFuncs = aggregationFunctions?.filter((func) =>
          func?.toLowerCase()?.includes(editedPart?.toLowerCase())
        );
        setFilteredFunctions(filteredFuncs);
      } else {
        setFilteredColumns(availableColumns);
        setFilteredFunctions(aggregationFunctions);
      }
    }

    setShowDropdown(true);
  };

  // const handleInputChange = (e) => {
  //   e?.preventDefault();
  //   const input = e?.target?.value;
  //   setQuery(input);

  //   const inputElement = e?.target;
  //   const cursorPosition = inputElement?.selectionStart;

  //   const parts = input?.split(/(?=[()+\-*/%])|(?<=[()+\-*/%])/); // Split into tokens
  //   let activeFunction = null;
  //   let startBracketIndex = -1;
  //   let endBracketIndex = -1;

  //   // Track open parentheses and associated functions
  //   const stack = [];
  //   for (let i = 0, cumulativeIndex = 0; i < parts?.length; i++) {
  //     const token = parts[i];
  //     const tokenStart = cumulativeIndex;
  //     const tokenEnd = cumulativeIndex + token?.length;

  //     // Update cumulative index
  //     cumulativeIndex = tokenEnd;

  //     // Check if token is a function name
  //     if (token?.match(/SUM|COUNT|AVG|MIN|MAX|IF/i)) {
  //       stack?.push({ functionName: token, index: i });
  //     }

  //     // Handle open parenthesis
  //     if (token === "(" && stack?.length > 0) {
  //       stack[stack?.length - 1].startBracket = tokenStart;
  //     }

  //     // Handle close parenthesis
  //     if (token === ")" && stack?.length > 0) {
  //       const currentFunction = stack?.pop();
  //       currentFunction.endBracket = tokenEnd;

  //       // Check if cursor is inside this function's parentheses
  //       if (
  //         cursorPosition >= currentFunction?.startBracket &&
  //         cursorPosition <= currentFunction?.endBracket
  //       ) {
  //         activeFunction = currentFunction?.functionName;
  //         startBracketIndex = currentFunction?.startBracket;
  //         endBracketIndex = currentFunction?.endBracket;
  //         break;
  //       }
  //     }
  //   }
  //   if (activeFunction && startBracketIndex !== -1 && endBracketIndex !== -1) {
  //     const insideParentheses = input?.substring(
  //       startBracketIndex + 1,
  //       endBracketIndex
  //     );
  //     const insideParts = insideParentheses?.split(/,|\s+/);

  //     // Find the part being edited based on cursor position
  //     let editedPart = null;
  //     let cumulativeIndex = startBracketIndex + 1; // Start just after '('
  //     for (let part of insideParts) {
  //       const partStart = cumulativeIndex;
  //       const partEnd = cumulativeIndex + part?.length;

  //       if (cursorPosition >= partStart && cursorPosition <= partEnd) {
  //         editedPart = part?.replace(/[^\w]/g, "");
  //         break;
  //       }
  //       cumulativeIndex = partEnd + 1; // Account for separators
  //     }
  //     if (editedPart?.trim()) {
  //       // When there is an editedPart, filter columns and functions based on it
  //       const filteredCols = selectedComponentColumns?.filter((col) =>
  //         col?.toLowerCase()?.includes(editedPart?.trim()?.toLowerCase())
  //       );
  //       setFilteredColumns(filteredCols);

  //       const filteredFuncs = aggregationFunctions?.filter((func) =>
  //         func?.toLowerCase()?.includes(editedPart?.trim()?.toLowerCase())
  //       );
  //       setFilteredFunctions(filteredFuncs);
  //     } else {
  //       // When editedPart is empty, show all columns and functions
  //       setFilteredColumns(selectedComponentColumns);
  //       setFilteredFunctions(aggregationFunctions);
  //     }
  //   } else {
  //     // Cursor outside any function (main expression area)
  //     const editedPart = parts
  //       ?.find((part, index) => {
  //         const partStart = parts?.slice(0, index)?.join("")?.length;
  //         const partEnd = partStart + part?.length;
  //         return cursorPosition >= partStart && cursorPosition <= partEnd;
  //       })
  //       ?.trim();

  //     if (editedPart) {
  //       // Filter suggestions based on the part being edited
  //       const filteredCols = selectedComponentColumns?.filter((col) =>
  //         col?.toLowerCase()?.includes(editedPart?.toLowerCase())
  //       );
  //       setFilteredColumns(filteredCols);

  //       const filteredFuncs = aggregationFunctions?.filter((func) =>
  //         func?.toLowerCase()?.includes(editedPart?.toLowerCase())
  //       );
  //       setFilteredFunctions(filteredFuncs);
  //     } else {
  //       // Show all suggestions when no specific part is being edited
  //       setFilteredColumns(selectedComponentColumns);
  //       setFilteredFunctions(aggregationFunctions);
  //     }
  //   }
  //   setShowDropdown(true);
  // };

  const handleFunctionSelect = (func) => {
    const inputElement = document?.querySelector("#inputField"); // Replace with your input's ID

    if (!inputElement) return;

    const cursorPosition = inputElement?.selectionStart; // Get current cursor position
    const queryBeforeCursor = query
      ?.slice(0, cursorPosition)
      ?.replace(/\w*$/, ""); // Text before cursor
    const queryAfterCursor = query?.slice(cursorPosition)?.replace(/^\w*/, ""); // Text after cursor

    // Check if the cursor is inside parentheses of an existing function
    const parts = query?.split(/(?=[()+\-*/%])|(?<=[()+\-*/%])/);
    const lastPart = parts[parts?.length - 1]; // Last token typed
    let updatedQuery;
    if (lastPart && lastPart?.endsWith("(")) {
      // Cursor is inside an open function, nest the new function
      updatedQuery = `${queryBeforeCursor}${func}()${queryAfterCursor}`;
    } else {
      // Add the new function at the cursor position
      updatedQuery = `${queryBeforeCursor}${func}()${queryAfterCursor}`;
    }

    setQuery(updatedQuery);
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd =
        queryBeforeCursor?.length + func?.length + 1;
      inputElement?.focus(); // Ensure the input is focused
    }, 0);
    setSelectedIndex(-1);
    setShowDropdown(false);
  };

  const handleColumnSelect = (column) => {
    const inputElement = document?.querySelector("#inputField"); // Replace with your input's ID

    if (!inputElement) return;

    const cursorPosition = inputElement?.selectionStart; // Get the cursor position
    const queryBeforeCursor = query
      ?.slice(0, cursorPosition)
      ?.replace(/\w*$/, ""); // Text before the cursor
    const queryAfterCursor = query?.slice(cursorPosition)?.replace(/^\w*/, ""); // Text after the cursor

    // Check if the cursor is inside a function's parentheses
    const functionRegex = /(\w+)\(\s*$/; // Matches a function with an open parenthesis
    const beforeCursorMatch = queryBeforeCursor?.match(functionRegex);

    let updatedQuery;

    // If the cursor is inside a function's parentheses, insert the column there
    if (beforeCursorMatch) {
      const functionName = beforeCursorMatch[1]; // Extract the function name
      const functionStartIndex = queryBeforeCursor?.lastIndexOf(functionName); // Get function's start index
      updatedQuery = `${queryBeforeCursor?.slice(
        0,
        functionStartIndex + functionName?.length + 1
      )}\`${column}\`${queryAfterCursor}`;
    } else {
      // Default behavior: Insert the column at the cursor position
      updatedQuery = `${queryBeforeCursor}\`${column}\`${queryAfterCursor}`;
    }

    setQuery(updatedQuery);
    setTimeout(() => {
      inputElement.selectionStart = inputElement.selectionEnd =
        queryBeforeCursor?.length + column?.length + 2;
      inputElement?.focus();
    }, 0);

    setShowDropdown(false);

    setSelectedIndex(-1);
  };

  const getCursorPosition = () => {
    const inputElement = inputRef?.current;
    const cursorPosition = inputElement?.selectionStart;
    const textBeforeCursor = query?.slice(0, cursorPosition);

    const span = document?.createElement("span");
    span.style.visibility = "hidden";
    span.style.position = "absolute";
    span.style.whiteSpace = "pre";
    span.innerText = textBeforeCursor;

    document?.body?.appendChild(span);

    const width = span?.offsetWidth;
    document?.body?.removeChild(span);

    return width;
  };

  const handleLabelChange = (e) => {
    setComputedColumn({ ...computedColumn, name: e?.target?.value });
    updateWidth(e?.target?.value);
  };

  // Import File ----

  const scrollToItem = (index) => {
    if (suggestionDropDownRef?.current) {
      const dropdown = suggestionDropDownRef?.current;
      const items = dropdown?.children;
      const selectedItem = items[index];

      if (selectedItem) {
        const itemTop = selectedItem?.offsetTop;
        const itemHeight = selectedItem?.offsetHeight;
        const dropdownScrollTop = dropdown?.scrollTop;
        const dropdownHeight = dropdown?.offsetHeight;

        // Ensure the selected item is in view
        if (itemTop < dropdownScrollTop) {
          dropdown.scrollTop = itemTop;
        } else if (itemTop + itemHeight > dropdownScrollTop + dropdownHeight) {
          dropdown.scrollTop = itemTop + itemHeight - dropdownHeight;
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (!showDropdown) return;
    const totalItems = filteredFunctions?.length + filteredColumns?.length;

    switch (e.key) {
      case "ArrowDown":
        e?.preventDefault();
        setSelectedIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % totalItems;
          scrollToItem(nextIndex);
          return nextIndex;
        });
        break;
      case "ArrowUp":
        e?.preventDefault();
        setSelectedIndex((prevIndex) => {
          const prevIndexAdjusted =
            prevIndex <= 0 ? totalItems - 1 : prevIndex - 1;
          scrollToItem(prevIndexAdjusted);
          return prevIndexAdjusted;
        });
        break;
      case "Enter":
        e?.preventDefault();
        if (selectedIndex >= 0) {
          if (selectedIndex < filteredFunctions?.length) {
            handleFunctionSelect(filteredFunctions[selectedIndex]);
          } else {
            handleColumnSelect(
              filteredColumns[selectedIndex - filteredFunctions?.length]
            );
          }
        }
        // const inputElement = document?.getElementById("inputField");
        // if (inputElement) {
        //     inputElement?.blur();
        // }
        break;
      default:
        break;
    }
  };

  const handlekeyright = (e) => {
    const elements = document?.querySelectorAll(".focusable");
    const totalElements = elements?.length;
    const currentElement = elements[activeElementIndex];

    if (e.key === "ArrowRight") {
      if (currentElement?.tagName === "INPUT") {
        const cursorPosition = currentElement?.selectionStart;
        const valueLength = currentElement?.value?.length;

        // If cursor is not at the end, do not move focus or open dropdown
        if (cursorPosition < valueLength) return;

        // If the cursor is at the end of the current input, move to the next input
        if (activeElementIndex < totalElements - 1) {
          e?.preventDefault();
          const nextIndex = (activeElementIndex + 1) % totalElements;
          setActiveElementIndex(nextIndex);
          elements[nextIndex]?.focus();

          // Show dropdown only for the second input (index 1) if the value is not empty
          if (nextIndex === 1 && elements[nextIndex]?.value !== "") {
            setShowDropdown(false);
          } else {
            setShowDropdown(true);
          }
        }
      }
    } else if (e.key === "ArrowLeft") {
      if (currentElement?.tagName === "INPUT") {
        const cursorPosition = currentElement?.selectionStart;

        // If cursor is at the beginning and input has value, show dropdown
        if (cursorPosition === 0 && currentElement?.value?.length > 0) {
          setShowDropdown(false); // Hide dropdown
        }

        // If cursor is not at the beginning, allow normal movement
        if (cursorPosition > 0) return;

        // Move to the previous input element if cursor is at the beginning
        if (activeElementIndex > 0) {
          e?.preventDefault();
          const prevIndex =
            (activeElementIndex - 1 + totalElements) % totalElements;
          setActiveElementIndex(prevIndex);
          elements[prevIndex]?.focus();
        }
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      suggestionDropDownRef?.current &&
      !suggestionDropDownRef?.current?.contains(event?.target) &&
      inputRef?.current &&
      !inputRef?.current?.contains(event?.target)
    ) {
      setShowDropdown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    if (query && inputRef?.current) {
      const cursorLeftPosition = getCursorPosition();
      setDropdownLeft(cursorLeftPosition);
    } else {
      setDropdownLeft(0);
    }
  }, [query]);

  React.useEffect(() => {
    if (selectedComponent?.columnProperties) {
      const updatedFilteredColumns = selectedComponent?.columnProperties
        ?.filter(
          (item) =>
            item?.name !==
              `${String(
                selectedComponent?.table_name?.replace(/[- ]/g, "_")
              )?.toLowerCase()}_id` &&
            item?.type === "int" &&
            item?.name !== "id" &&
            !item?.name?.endsWith("_id") &&
            !(
              item?.name === selectedColumn?.name ||
              item?.name === computedColumn?.name
            )
        )
        ?.map((item) => item?.name);

      setFilteredColumns(updatedFilteredColumns);
    }
  }, [selectedComponent, selectedColumn, computedColumn]);

  React.useEffect(() => {
    setFilteredFunctions(aggregationFunctions);
    setShowDropdown(false);
    setSelectedIndex(-1);
  }, [selectedComponent]);

  React.useEffect(() => {
    updateWidth(computedColumn?.name || "column");
  }, [computedColumn?.name]);

  React.useEffect(() => {
    if (computedColumn?.status) {
      setShowDropdown(false);
    }
  }, [computedColumn]);

  React.useEffect(() => {
    const handleResize = () => setHeight(window?.innerHeight);
    window?.addEventListener("resize", handleResize);
    return () => window?.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      // className='main-container grow w-4/5 overflow-auto h-[76vh]'
      className={`main-container grow w-4/5 overflow-auto  ${
        activeNavItem === "Database Connections" ? "" : "mt-[1px]"
      }`}
    >
      {isTableloading ? (
        <Stack
          sx={{
            color: "grey.500",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
          direction="row"
        >
          <CircularProgress color="inherit" disableShrink />
        </Stack>
      ) : (
        <>
          {components?.length > 0 ||
          superset?.isDatabaseView ||
          superset?.isDatasetView ? (
            <>
              {isTableloading ? (
                <Stack
                  sx={{
                    color: "grey.500",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  spacing={2}
                  direction="row"
                >
                  <CircularProgress color="inherit" disableShrink />
                </Stack>
              ) : (
                <div
                  className={`h-100 relative flex flex-col ${
                    activeNavItem === "Database Connections" ? "" : "pt-1"
                  }`}
                >
                  {activeNavItem !== "Database Connections" &&
                    !superset?.isDatasetView && (
                      <>
                        <div
                          // className=' d-flex w-full px-1 bg-white border items-center '
                          className={`d-flex w-full gap-2 pr-1 bg-white border items-center ${
                            computedColumn?.status ? "" : "opacity-50"
                          }`}
                          style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)" }}
                        >
                          <span className="flex h-7 bg-slate-100">
                            <span
                              className={`px-1 ${
                                computedColumn?.status
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed"
                              }`}
                              onClick={() => {
                                setComputedColumn({ status: false });
                                setQuery("");
                                setShowDropdown(false);
                                setSelectedIndex(-1);
                                setDropdownLeft(0);
                              }}
                            >
                              {" "}
                              <CloseIcon />
                            </span>
                            <span
                              className={`px-1 ${
                                computedColumn?.status
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed"
                              }`}
                              // onClick={() => {
                              //     setShowDropdown(false)
                              //     handleCreateComputedColumn()
                              //     setSelectedIndex(-1)
                              // }}
                              onClick={() => {
                                const queryMissingColumns =
                                  checkColumnsInExpression(query);
                                if (queryMissingColumns?.length > 0) {
                                  showAlert(
                                    "Cannot proceed, missing columns in computed expression",
                                    "error"
                                  );
                                  return;
                                }
                                setErrorComputed({
                                  status: false,
                                  message: "",
                                });
                                setShowDropdown(false);
                                handleCreateComputedColumn();
                                setSelectedIndex(-1);
                              }}
                            >
                              <CheckIcon />
                            </span>
                          </span>
                          <span
                            className="flex items-center grow relative"
                            onKeyDown={handlekeyright}
                          >
                            {computedColumn?.status && (
                              <div className=" py-1 text-sm font-normal relative">
                                <span
                                  ref={hiddenSpanRef}
                                  style={{
                                    visibility: "hidden",
                                    whiteSpace: "pre",
                                    position: "absolute",
                                  }}
                                ></span>
                                <input
                                  type="text"
                                  value={computedColumn?.name}
                                  onChange={handleLabelChange}
                                  //  onBlur={handleBlurLabel}
                                  autoFocus
                                  // onFocus={() => {
                                  //     console.log(computedColumn, ">>> Computed Column  ...");
                                  //     const name = computedColumn?.isNew ? generateColumnName(selectedComponent?.columns) : computedColumn?.name;
                                  //     setComputedColumn({ ...computedColumn, name })
                                  // }}
                                  onFocus={() => {
                                    if (
                                      computedColumn?.isNew &&
                                      !computedColumn?.name
                                    ) {
                                      const name = generateColumnName(
                                        selectedComponent?.columns
                                      );
                                      setComputedColumn({
                                        ...computedColumn,
                                        name,
                                      });
                                    }
                                    setShowDropdown(false);
                                    setActiveElementIndex(0);
                                  }}
                                  className=" rounded focus:outline-none focusable"
                                  style={{
                                    width: inputWidth,
                                    minWidth: "50px",
                                  }}
                                />
                              </div>
                            )}
                            {computedColumn?.status && (
                              <span className="pl-1">=</span>
                            )}
                            <input
                              id="inputField"
                              type="text"
                              value={query}
                              className="flex-1 px-1 py-1 text-sm focus:outline-none focusable"
                              placeholder=""
                              onChange={handleInputChange}
                              // onFocus={() => {
                              //     if (computedColumn?.status) {
                              //         setShowDropdown(true);
                              //         setFilteredFunctions(aggregationFunctions)
                              //         setFilteredColumns(selectedComponentColumns)
                              //     }
                              //     setActiveElementIndex(1)
                              // }}
                              onFocus={() => {
                                if (computedColumn?.status && query === "") {
                                  setShowDropdown(true);
                                  setFilteredFunctions(aggregationFunctions);
                                  setFilteredColumns(selectedComponentColumns);
                                }
                                setActiveElementIndex(1);
                              }}
                              disabled={!computedColumn?.status}
                              // autocomplete="off"
                              autoComplete="off"
                              ref={inputRef}
                              onKeyDown={handleKeyDown}
                            />

                            {showDropdown && computedColumn?.status && (
                              <ul
                                ref={suggestionDropDownRef}
                                className="top-full bg-white border mt-1 shadow-md rounded agg-list max-h-52 overflow-auto custom-scrollbar"
                                style={{
                                  left: dropdownLeft,
                                  position: "absolute",
                                  zIndex: "10",
                                }}
                              >
                                {filteredFunctions?.map((func, index) => (
                                  <Tooltip title={toolTips[func]}>
                                    <li
                                      key={index}
                                      className={`py-1 px-2 cursor-pointer text-sm flex gap-1 ${
                                        selectedIndex === index
                                          ? "bg-slate-200"
                                          : ""
                                      }`}
                                      onMouseEnter={() =>
                                        setSelectedIndex(index)
                                      }
                                      onClick={() => handleFunctionSelect(func)}
                                    >
                                      <FunctionIcon
                                        width="20px"
                                        height="20px"
                                      />
                                      {func}
                                    </li>
                                  </Tooltip>
                                ))}
                                {filteredColumns?.map((column, index) => (
                                  <li
                                    key={filteredFunctions?.length + index}
                                    className={`py-1 px-2 cursor-pointer text-sm flex gap-1 ${
                                      selectedIndex ===
                                      filteredFunctions?.length + index
                                        ? "bg-slate-200"
                                        : ""
                                    }`}
                                    onMouseEnter={() =>
                                      setSelectedIndex(
                                        filteredFunctions?.length + index
                                      )
                                    }
                                    onClick={() => handleColumnSelect(column)}
                                  >
                                    <ColumnIcon width="20px" height="20px" />
                                    {column}
                                  </li>
                                ))}
                              </ul>
                            )}
                            {/* {showDropdown && computedColumn?.status && (
                                            <ul className=" top-full  bg-white border mt-1 shadow-md rounded agg-list max-h-52 overflow-auto custom-scrollbar"
                                                style={{
                                                    position: "absolute",
                                                    zIndex: '10',
                                                    // top: dropdownPosition.top,
                                                    left: dropdownPosition.left,
                                                }}
                                            >
                                                {filteredFunctions.map((func, index) => (
                                                    <li
                                                        key={index}
                                                        className="py-1 px-2 hover:bg-slate-100 cursor-pointer text-sm flex gap-1"
                                                        // onClick={() => {
                                                        //     // setSearchQuery(func);
                                                        //     const formattedQuery = `${query} ${func}(`; // Append the function to the current query
                                                        //     setQuery(formattedQuery);
                                                        //     setShowDropdown(false);
                                                        // }}
                                                        onClick={() => handleFunctionSelect(func)}
                                                    >
                                                        <FunctionIcon width="20px" height="20px" />
                                                        {func}
                                                    </li>
                                                ))}
                                                {filteredColumns?.map((column, index) => (
                                                    <li
                                                        key={index}
                                                        className="py-1 px-2 hover:bg-slate-100 cursor-pointer text-sm flex gap-1"
                                                        // onClick={() => {
                                                        //     const formattedQuery = `${query} ${selectedComponent?.table_name}[${column}]`; // Append the column to the current query
                                                        //     setQuery(formattedQuery);
                                                        //     setShowDropdown(false);
                                                        // }}
                                                        onClick={() => handleColumnSelect(column)}
                                                    >
                                                        <ColumnIcon width="20px" height="20px" />
                                                        {column}
                                                    </li>
                                                ))}
                                            </ul>
                                        )} */}
                          </span>
                        </div>
                        {errorComputed?.status && computedColumn?.status && (
                          <div
                            style={{
                              top: "25px",
                              width: "100%",
                              backgroundColor: "white",
                              zIndex: "8",
                            }}
                            className="text-orange-700 text-sm mt-1 absolute  flex pl-[128px]"
                          >
                            <WarningAmberRoundedIcon
                              sx={{
                                fontSize: "18px !important",
                              }}
                            />
                            {errorComputed?.message}
                          </div>
                        )}
                      </>
                    )}
                  <div
                    className={`${
                      superset?.isDatasetView ? "" : "mt-1"
                    } flex-grow h-[60vh]`}
                    // style={{ height: (superset?.isDatasetView && !superset?.isDatabaseView) ? '53vh' : '60vh' }}
                  >
                    {superset?.isDatabaseView &&
                      !superset?.isDatasetView &&
                      (superset?.isDatabseLoading ? (
                        <Stack
                          sx={{
                            color: "grey.500",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          spacing={2}
                          direction="row"
                        >
                          <CircularProgress color="inherit" disableShrink />
                        </Stack>
                      ) : (
                        <DbConnections
                          superset={superset}
                          handleRemoveDatabse={handleRemoveDatabse}
                          setActiveNavItem={setActiveNavItem}
                        />
                      ))}
                    {superset?.isDatasetView && !superset?.isDatabaseView && (
                      <AddDataset
                        // tableMetaData={tableMetaData}
                        getQueriedTableData={getQueriedTableData}
                        // datasettableloading={datasettableloading}
                        // query={sqlQuery}
                        // setQuery={setSqlQuery}
                        showAlert={showAlert}
                        superset={superset}
                        errorMessage={errorMessage}
                        datasetLoadingStep={datasetLoadingStep}
                        isDatasetLoading={isDatasetLoading}
                        setActiveNavItem={setActiveNavItem}
                        setErrorMessage={setErrorMessage}
                      />
                    )}
                    {!superset?.isDatabaseView && !superset?.isDatasetView && (
                      <TableComponent
                        component={selectedComponent}
                        setSelectedComponent={setSelectedComponent}
                        data={selectedComponent?.data}
                        columns={selectedComponent?.columns}
                        selectedColumn={selectedColumn}
                        setSelectedColumn={setSelectedColumn}
                        handleApplyFilter={handleApplyFilter}
                        filterObj={filterObj}
                        setFilterObj={setFilterObj}
                        clearFilter={handleClearFilter}
                        clearAllFilter={handleClearAllFilter}
                        filters={selectedComponent?.filters}
                        onPropertyChange={onPropertyChange}
                        showAlert={showAlert}
                        handleSelectColumn={handleSelectColumn}
                        primaryProperties={primaryProperties}
                        setPrimaryProperties={setPrimaryProperties}
                        dateformat={dateformat}
                        setOpenDropdowns={setOpenDropdowns}
                        openDropdowns={openDropdowns}
                        handleAddColumnValues={handleAddColumnValues}
                        dateFormats={dateFormats}
                        fetchTableData={fetchTableData}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-center items-center h-100 flex-col">
              <p className="m-0 font-semibold text-gray-500">Import Data</p>
              <p className="m-0 font-semibold text-gray-400 text-sm pt-1">
                Once loaded, your data will appear in Data Source pane
              </p>
              <span className=" d-flex gap-3 my-2 items-start justify-evenly">
                <input
                  disabled={!hasDatasourceCreationPermission}
                  ref={fileInputRef}
                  type="file"
                  accept=".csv, .xlsx, .xls"
                  onChange={(event) => {
                    const files = event?.target?.files;
                    //handleImportFile(files[0]);
                    handleUploadExcelFile(files[0]);
                  }}
                  style={{ display: "none" }}
                />
                <span
                  className={classNames(
                    "d-flex w-32 flex-col justify-center items-center",
                    hasDatasourceCreationPermission
                      ? "cursor-pointer hover-container"
                      : "cursor-not-allowed"
                  )}
                  onClick={() => {
                    if (!hasDatasourceCreationPermission) return;
                    fileInputRef?.current?.click();
                  }}
                >
                  <span
                    className={classNames(
                      "img-container h-20 w-28 d-flex items-center justify-center",
                      hasDatasourceCreationPermission
                        ? "bg-green-200 cursor-pointer"
                        : "bg-gray-200 cursor-not-allowed"
                    )}
                  >
                    <ExcelIcon
                      height={58}
                      width={58}
                      disabled={!hasDatasourceCreationPermission}
                    />
                  </span>
                  <p className="m-0 mt-1 text-xs text-gray-500">
                    Import data from files
                  </p>
                  <p className="m-0 text-xs text-gray-500">(CSV,XLSX,XLS)</p>
                </span>
                <span
                  className={classNames(
                    "d-flex w-32 flex-col justify-center items-center",
                    hasDBCreationPermission
                      ? "cursor-pointer hover-container"
                      : "cursor-not-allowed"
                  )}
                  onClick={
                    hasDBCreationPermission
                      ? handleAddNewDatabaseConnection
                      : null
                  }
                >
                  <span
                    className={classNames(
                      "img-container h-20 w-28 d-flex items-center justify-center",
                      hasDBCreationPermission
                        ? "bg-blue-200 cursor-pointer"
                        : "bg-gray-200 cursor-not-allowed"
                    )}
                  >
                    <SqlIcon
                      height={40}
                      width={40}
                      fill={hasDBCreationPermission ? "#0d6efd" : "#57585d"}
                    />
                  </span>
                  {/* <p className='m-0 mt-1 text-xs text-gray-400'>Import data from SQL</p> */}
                  <p
                    className={classNames(
                      "m-0 mt-1 text-xs",
                      hasDBCreationPermission
                        ? "text-gray-500"
                        : "text-gray-400"
                    )}
                  >
                    Connect Database
                  </p>
                </span>
                <span
                  className="d-flex w-32 flex-col justify-center items-center"
                  // onClick={onCareSMSClick}
                >
                  <span
                    // className='img-container h-20 w-28 d-flex items-center justify-center bg-orange-200 cursor-pointer'
                    className="img-container h-20 w-28 d-flex items-center justify-center bg-gray-200 cursor-not-allowed"
                  >
                    <AddDataSource height={40} width={40} />
                  </span>
                  <p className="m-0 mt-1 text-xs text-gray-400">
                    Import data from
                  </p>
                  <p className="m-0 text-xs text-gray-400">CareSMS</p>
                </span>
                <span className="d-flex w-32 flex-col justify-center items-center">
                  <span
                    // className='img-container h-20 w-28 d-flex items-center justify-center bg-blue-200 cursor-pointer'
                    className="img-container h-20 w-28 d-flex items-center justify-center bg-gray-200 cursor-not-allowed"
                  >
                    <SampleDataIcon height={40} width={40} fill="#57585d" />
                  </span>
                  <p className="m-0 mt-1 text-xs text-gray-400">
                    Use Sample Data
                  </p>
                </span>
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default MainContainer;
