import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const PageSizeIcon = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.height}
            height={props.width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 60"

        >
            <rect x="11" y="10" width="30" height="42" fill="white" stroke={props.fill} stroke-width="3" />


            <line x1="5" y1="5" x2="5" y2="55" stroke={props.fill} stroke-width="3" />
            <line x1="3" y1="5" x2="7" y2="5" stroke={props.fill} stroke-width="3" />
            <line x1="3" y1="55" x2="7" y2="55" stroke={props.fill} stroke-width="3" />


            <line x1="10" y1="2" x2="40" y2="2" stroke={props.fill} stroke-width="3" />
            <line x1="10" y1="0" x2="10" y2="4" stroke={props.fill} stroke-width="3" />
            <line x1="40" y1="0" x2="40" y2="4" stroke={props.fill} stroke-width="3" />
        </svg>

    </Tooltip>
);

export default PageSizeIcon;

