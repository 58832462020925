import React, { useEffect } from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { useLSStore } from "../../../context/ls-store";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import { useGetAllLocationsMutation } from "../../../features/location/locationApiSlice";

const UserModal = ({
  type = "update",
  open,
  setOpen,
  data = null,
  setData,
  edit,
  create,
  roles,
  fetchUsers,
}) => {
  const user = useSelector(selectUser);
  const [userFirstName, setUserFirstName] = React.useState("");
  const [userLastName, setUserLastName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userPhone, setUserPhone] = React.useState("");
  const [userRole, setUserRole] = React.useState(0);
  const [location, setLocation] = React.useState("");
  const [locations, setLocations] = React.useState([]);

  const handleClose = () => {
    setUserFirstName("");
    setUserLastName("");
    setUserEmail("");
    setUserPhone("");
    setUserRole(0);
    setLocation("");
    setOpen(false);
    setData(null);
  };

  const [getLocations, { isLoading }] = useGetAllLocationsMutation();

  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    if (open && data !== null) {
      setUserFirstName(data.firstname);
      setUserLastName(data.lastname);
      setUserEmail(data.email);
      setUserPhone(data.phone);
      setUserRole(data.type);
      setLocation(data.location_id);
    }
  }, [open, data]);

  // const updateUser = async () => {
  //   try {
  //     await edit({
  //       userId: data.id,
  //       name,
  //       permissions: selectedPermissions?.map((permission) => ({
  //         id: permission.value,
  //         action: permission.label,
  //       })),
  //     }).unwrap();

  //     fetchUsers();

  //     setAlert({
  //       message: "Updated User",
  //       severity: "success",
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     setAlert({
  //       message: "Failed to update User",
  //       severity: "error",
  //     });
  //   }
  // };

  const fetchLocations = async () => {
    try {
      const { data } = await getLocations().unwrap();

      setLocations(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    fetchLocations();
  }, []);

  const createUser = async () => {
    try {
      const { data } = await create({
        type: userRole,
        email: userEmail,
        phone: userPhone,
        organisation_id: user.organisation.id,
        firstname: userFirstName,
        lastname: userLastName,
        location_id: location,
      }).unwrap();

      fetchUsers();
      handleClose();

      setAlert({
        message: "Created User",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to create User",
        severity: "error",
      });
    }
  };

  const formatPhoneNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    return match
      ? [match[1], match[2], match[3]].filter(Boolean).join("-")
      : "";
  };

  return (
    <ReusableModal
      open={open}
      title={`${type === "update" ? "Update" : "Create"} User`}
      onClose={handleClose}
    >
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-row w-full gap-4">
          <div className="flex flex-col w-1/2 gap-2">
            <label htmlFor="email" className="m-0 text-base font-semibold">
              First Name
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              placeholder="John"
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              value={userFirstName}
              onChange={(e) => setUserFirstName(e.target.value)}
            />
          </div>
          <div className="flex flex-col w-1/2 gap-2">
            <label htmlFor="phone" className="m-0 text-base font-semibold">
              Last Name
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              placeholder="Doe"
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              value={userLastName}
              onChange={(e) => setUserLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="email" className="m-0 text-base font-semibold">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter Email"
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </div>
        <div className="flex flex-row w-full gap-4">
          <div className="flex flex-col w-1/2 gap-2">
            <label htmlFor="phone" className="m-0 text-base font-semibold">
              Phone
            </label>
            <input
              type="tel"
              id="tel"
              name="tel"
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              placeholder="xxx-xxx-xxxx"
              value={userPhone}
              onChange={(e) => {
                if (e.target.value.length >= 13) {
                  return;
                }
                setUserPhone(formatPhoneNumber(e.target.value));
              }}
            />
          </div>
          <div className="flex flex-col w-1/2 gap-2">
            <label htmlFor="role" className="m-0 text-base font-semibold">
              Role
            </label>
            <select
              id="role"
              name="role"
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            >
              <option value={0}>Select Role</option>
              {roles?.map((role) => {
                return (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-2">
          <label htmlFor="locations" className="m-0 text-base font-semibold">
            Locations
          </label>
          <select
            id="locations"
            name="locations"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
          >
            <option value={0}>Select Location</option>
            {locations?.map((location) => {
              return (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              );
            })}
          </select>
        </div>
        <button
          // disabled={!userId}
          className="w-full disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
          onClick={createUser}
        >
          Add
        </button>
      </div>
    </ReusableModal>
  );
};

export default UserModal;
