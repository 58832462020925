import * as React from "react";
const CalendarCheckSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.color || "#000"}
        fillRule="evenodd"
        d="M6.75 1a.75.75 0 0 1 .75.75V3h5V1.75a.75.75 0 0 1 1.5 0V3h2.25A2.75 2.75 0 0 1 19 5.75v3a.75.75 0 0 1-.75.75H2.5v6.75c0 .69.56 1.25 1.25 1.25h5a.75.75 0 0 1 0 1.5h-5A2.75 2.75 0 0 1 1 16.25V5.75A2.75 2.75 0 0 1 3.75 3H6V1.75A.75.75 0 0 1 6.75 1ZM6 4.5H3.75c-.69 0-1.25.56-1.25 1.25V8h15V5.75c0-.69-.56-1.25-1.25-1.25H14v.75a.75.75 0 0 1-1.5 0V4.5h-5v.75a.75.75 0 0 1-1.5 0V4.5Zm13.765 8.705a.75.75 0 0 1 .03 1.06l-4.25 4.5a.75.75 0 0 1-1.102-.013l-2.25-2.5a.75.75 0 1 1 1.114-1.004l1.706 1.896 3.692-3.909a.75.75 0 0 1 1.06-.03Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default CalendarCheckSvg;
