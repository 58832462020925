import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const AppendDataSvg = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.height}
            height={props.width}
            xmlns="http://www.w3.org/2000/svg"
            // viewBox="0 0 64 64"
            viewBox="0 0 70 70"
            fill={props.fill}
        // stroke="#075985"
        // stroke-width="1"
        // stroke-linecap="round"
        // stroke-linejoin="round"
        >
            <g>

                <g>

                    <path d="M59.942,64h-38.53c-1.383,0-2.504-1.121-2.504-2.504V30.652c0-1.174,0.815-2.19,1.961-2.445    c4.337-0.965,7.811-4.38,8.849-8.702c0.271-1.126,1.277-1.919,2.435-1.919h12.238c0.665,0,1.303,0.265,1.773,0.735l15.551,15.581    c0.469,0.469,0.732,1.106,0.732,1.769v25.824C62.447,62.879,61.325,64,59.942,64z M23.916,58.991h33.521V36.707L43.352,22.595    h-9.352c-1.725,4.612-5.449,8.281-10.084,9.936V58.991z" />

                </g>

                <g>

                    <path d="M58.747,38.175H44.391c-1.383,0-2.504-1.121-2.504-2.504V21.288c0-1.218,0.727-2.307,1.853-2.772    c1.126-0.465,2.409-0.21,3.27,0.653l13.86,13.886c0,0,0,0,0,0c0.86,0.862,1.114,2.145,0.648,3.269    C61.052,37.449,59.964,38.175,58.747,38.175z M57.325,36.594C57.325,36.594,57.325,36.594,57.325,36.594L57.325,36.594    L57.325,36.594z M46.895,33.166h7.009l-7.009-7.022V33.166z" />

                </g>

                <g>

                    <path d="M18.302,33.498c-9.236,0-16.749-7.514-16.749-16.749S9.067,0,18.302,0    c9.235,0,16.749,7.514,16.749,16.749S27.538,33.498,18.302,33.498z M18.302,5.009c-6.474,0-11.74,5.267-11.74,11.74    s5.267,11.74,11.74,11.74c6.473,0,11.74-5.267,11.74-11.74S24.776,5.009,18.302,5.009z" />

                </g>

                <g>

                    <path d="M23.285,19.253h-9.661c-1.383,0-2.504-1.121-2.504-2.504c0-1.383,1.121-2.504,2.504-2.504h9.661    c1.383,0,2.504,1.121,2.504,2.504C25.789,18.132,24.668,19.253,23.285,19.253z" />

                </g>

                <g>

                    <path d="M18.455,24.084c-1.383,0-2.504-1.121-2.504-2.504v-9.661c0-1.383,1.121-2.504,2.504-2.504    c1.383,0,2.504,1.121,2.504,2.504v9.661C20.959,22.962,19.838,24.084,18.455,24.084z" />

                </g>

            </g>
        </svg>

    </Tooltip>
);

export default AppendDataSvg;

