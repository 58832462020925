import React from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";

function DeleteModal({ onClose, open, name, onDelete }) {
  return (
    <ReusableModal open={open} title="Delete provider" onClose={onClose}>
      <p className="p-3 text-base">
        Are you sure you want to delete user: <b>{name}</b>?
      </p>
      <div className="flex justify-end">
        <div className="flex gap-2 m-3">
          <button
            className="w-28 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="w-28 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 rounded-md bg-primaryblue text-white whitespace-nowrap"
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </ReusableModal>
  );
}

export default DeleteModal;
