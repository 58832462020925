import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import AutoHideAlert from "./components/Alert/Alert";
import MenuHeader from "./components/Header/MenuHeader";
import SubscriptionAlert from "./components/Header/SubscriptionAlert";
import { selectUser } from "./features/auth/authSlice";
import { useSelector } from "react-redux";
import { useGetUserPermissionsMutation } from "./features/user/userApiSlice";
import { usePermissionsStore } from "./context/permissions.store";

const Layout = () => {
  const user = useSelector(selectUser);
  const location = useLocation();

  const [getUserPermissions] = useGetUserPermissionsMutation();
  const { setPermissions } = usePermissionsStore();

  const authPaths = ["/signup", "/signin", "/"];
  const rootPath = "/";

  React.useEffect(() => {
    if (user?.completedOnboarding || user?.step >= 5) {
      const fetchPermissions = async () => {
        const { data } = await getUserPermissions().unwrap();
        setPermissions(data);
      };
      fetchPermissions();
    }
  }, [user]);

  if (
    (user?.completedOnboarding || user?.step >= 5) &&
    authPaths.includes(location?.pathname)
  ) {
    return <Navigate to="/home" />;
  } else if (rootPath === location?.pathname) {
    return <Navigate to="/signin" />;
  }

  return (
    <main className="overflow-hidden no-scrollbar">
      {(user?.completedOnboarding || user?.step >= 5) &&
        location?.pathname !== "/home" && (
          <>
            {user.subscription !== null &&
              user?.subscription?.status === "trialing" && (
                <SubscriptionAlert />
              )}
            <MenuHeader user={user} />
          </>
        )}
      <AutoHideAlert />
      <Outlet />
    </main>
  );
};

export default Layout;
