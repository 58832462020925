import { apiSlice } from "../../app/api/apiSlice";

export const roleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createRole: builder.mutation({
      query: (credentials) => ({
        url: "/role",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    editRole: builder.mutation({
      query: ({ roleId, ...credentials }) => ({
        url: `/role/${roleId}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    getAllRoles: builder.mutation({
      query: () => ({
        url: `/role`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetAllRolesMutation,
} = roleApiSlice;
