
import React, { Component, useState } from 'react'

import { CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import TableViewIcon from '@mui/icons-material/TableView';
import { Tooltip } from "@mui/material";
import { useDrag } from 'react-dnd';
import HdrAutoOutlinedIcon from '@mui/icons-material/HdrAutoOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import TagIcon from '@mui/icons-material/Tag';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


function DataPanel({
    components,
    component,
    setSelectedComponent,
    tables,
    getTableData,
    onPropertyChange,
    setFlag,
    showAlert,
    flag,
    loadingTables,
    filteredReport,
    reportId,
    getTableDataForSelectAll,
    dateColumn,
    setDateColumn,
    isLoadingReport
}) {

    const [isExpandPropertyPanel, setIsExpandPropertyPanel] = React.useState(true);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [loadedTables, setLoadedTables] = React.useState({});
    const [openItem, setOpenItem] = React.useState(null);
    const [openSubItem, setOpenSubItem] = React.useState(null);
    const filteredTables = tables?.filter(table => {
        const tableNameToSearch = table?.new_table_name || table?.table_name;
        return tableNameToSearch?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });
    const [deselectedColumns, setDeselectedColumns] = React.useState([]);
    // const [selectedColumn, setSelectedColumn] = React.useState(component?.properties?.columns);
    const [selectedColumn, setSelectedColumn] = React.useState(
        component?.type === "table"
            ? component?.properties?.columns || []
            : component?.type === "chart"
                ? component?.properties?.chartType === 'pie' ? [...component.properties.slice] : [...(component?.properties?.rowFields || []), ...(component?.properties?.colFields || []), ...(component?.properties?.legends || []), 
                ...(component?.properties?.line
                    ? [{
                        columnId: component?.properties?.line.column_id,
                        columnName: component?.properties?.line.column_name,
                        index: 0
                    }]
                    : []
                )]
                : component?.properties?.dimensions || []
    );
    const [newCol, setNewCol] = React.useState("");
    const handleItemToggle = (itemName, table) => {
        // setOpenItem((prevOpenItem) => (prevOpenItem === itemName ? null : itemName));
        if (table) {
            const initialCheckedColumns = table?.columnProperties?.reduce((acc, col) => {
                acc[col?.name] = true;
                return acc;
            }, {});
            setLoadedTables((prev) => {
                const updatedLoadedTables = Object?.keys(prev)?.reduce((acc, tableName) => {
                    acc[tableName] = tableName === itemName;
                    return acc;
                }, {});
                updatedLoadedTables[itemName] = true;

                return updatedLoadedTables;
            });
            // if (!loadedTables[itemName]) {
            //     getTableData(table);
            // }
        }
    };
    const handleCheckChange = async ({ tableId, tableName, columnName, columnId }) => {
        // const firstDataComponent = components?.find(comp => comp.type === "chart" || comp.type === "table");
        // const existingTableId = firstDataComponent?.properties?.tableId;


        // // If a data source is already set, check if the new tableId matches
        // if (existingTableId && existingTableId !== tableId) {
        //     showAlert("Only one data source can be used per report.", "info");
        //     return;
        // }

        // // If no data source is set, store the tableId in the first found table/chart component
        // if (!existingTableId && firstDataComponent) {
        //     firstDataComponent.properties.tableId = tableId;
        // }
        // if (component?.type !== 'table' && components?.length > 0) {
        //     showAlert("Please select a table widget to add a column.", "info");
        //     return;
        // }
        if (component?.properties?.data?.length <= 0 || components?.length <= 0) {
            getTableData({ tableId, tableName, columnName, columnId });
        } else {
            handleColumnNameFilter(columnName, columnId)
        }
    }

    const handleSelectAll = async ({ table, tableId, tableName, columns }) => {
        // const firstDataComponent = components?.find(comp => comp.type === "chart" || comp.type === "table");
        // const existingTableId = firstDataComponent?.properties?.tableId;

        // // If a data source is already set, check if the new tableId matches
        // if (existingTableId && existingTableId !== tableId) {
        //     showAlert("Only one data source can be used per report.", "info");
        //     return;
        // }

        // // If no data source is set, store the tableId in the first found table/chart component
        // if (!existingTableId && firstDataComponent) {
        //     firstDataComponent.properties.tableId = tableId;
        // }
        // if (component?.type !== 'table' && components?.length > 0) {
        //     showAlert("Please select a table widget to add a column.", "info");
        //     return;
        // }
        if (component?.properties?.data?.length <= 0 || components?.length <= 0) {
            await getTableDataForSelectAll({ tableId, tableName, columns });
            const allColumns = table?.columnMetaData?.filter((item) =>
                item !== `${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id` &&
                !component?.properties?.columnProperties?.find(col => col?.name === item?.column_name)?.isDeleted
            );
            const newColumns = allColumns.map((col, index) => ({
                columnId: col.column_id,
                columnName: col.column_name,
                count: index
            }));
            console.log(newColumns, "newcolumnsssinfirsttt")
            setSelectedColumn(newColumns);
            setDeselectedColumns([]);
        } else {
            const allColumns = table?.columnMetaData?.filter((item) =>
                item !== `${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id` &&
                !component?.properties?.columnProperties?.find(col => col?.name === item?.column_name)?.isDeleted
            );

            const allSelected = allColumns.every(col => selectedColumn.some(selected => selected.columnId === col.column_id));

            if (allSelected) {
                // Deselect all columns
                setSelectedColumn([]);
                setDeselectedColumns(allColumns.map(col => ({ columnId: col.column_id, columnName: col.column_name })));

                setSelectedComponent((prev) => ({
                    ...prev,
                    properties: { ...prev.properties, columns: [] }
                }));

                onPropertyChange({ columns: [] });
            } else {
                // Select all columns
                const newColumns = allColumns.map((col, index) => ({
                    columnId: col.column_id,
                    columnName: col.column_name,
                    count: index
                }));

                setSelectedColumn(newColumns);
                setDeselectedColumns([]);

                setSelectedComponent((prev) => ({
                    ...prev,
                    properties: { ...prev.properties, columns: newColumns }
                }));

                onPropertyChange({ columns: newColumns });

            }
            setFlag(false)
        }
    };

    const handleColumnNameFilter = (column, columnId) => {
        let updatedCount = 0; // Initialize count outside
        // Check if column is already selected
        const columnExists = selectedColumn.some((col) => col.columnId === columnId);

        if (columnExists) {
            // Deselect column
            setDeselectedColumns((prev) => [...prev, { columnId, columnName: column }]);

            let newColumns = selectedColumn.filter((col) => col.columnId !== columnId);

            // Reassign count values sequentially after removal
            newColumns = newColumns.map((col) => ({ ...col, count: updatedCount++ }));

            onPropertyChange({ columns: newColumns });

            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    columns: newColumns,
                },
            }));
            setSelectedColumn(newColumns);
            setFlag(false);
        } else {
            setDeselectedColumns((prev) =>
                prev?.filter((item) => item.columnId !== columnId)
            );

            const newColumnObject = { columnId, columnName: column, count: selectedColumn.length };

            let newColumns = [...selectedColumn, newColumnObject];

            onPropertyChange({ columns: newColumns });

            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    columns: newColumns,
                },
            }));
            setSelectedColumn(newColumns);
            setFlag(false);
        }
    };

    const handleToggle = (itemName) => {
        setOpenItem((prevOpenItem) => (prevOpenItem === itemName ? null : itemName));
    };
    const handleToggleSubItem = (itemName) => {
        setOpenSubItem((prevOpenItem) => (prevOpenItem === itemName ? null : itemName));
    };
    React.useEffect(() => {
        if (flag) {
            // console.log(selectedColumn, "selectedcolumnnn")
            setDeselectedColumns([]);
            // setSelectedColumn(component?.properties?.columns);
            if (component?.type === "table") {
                setSelectedColumn(component?.properties?.columns || []);
            } else if (component?.type === "chart") {
                component?.properties?.chartType === 'pie' ?
                    setSelectedColumn([
                        ...(component?.properties?.slice || []),
                    ])
                    :
                    setSelectedColumn([
                        ...(component?.properties?.rowFields || []),
                        ...(component?.properties?.colFields || []),
                        ...(component?.properties?.legends || []),
                        ...(component?.properties?.dimensions || []),
                        ...(component?.properties?.line
                            ? [{
                                columnId: component?.properties?.line.column_id,
                                columnName: component?.properties?.line.column_name,
                                index: 0
                            }]
                            : []
                        )
                    ])
            }
        }
    }, [component]);
    return (
        <>
            <div
                className={`sidebar menu-main overflow-x-hidden  ${true ? "menu-show" : "menu-hide"}   d-flex flex-col ${isExpandPropertyPanel ? 'w-56 min-w-56 ' : 'min-w-5 w-10'} no-scrollbar transition-all duration-300 ease-in-out bg-[#edf0f5] border-l border-slate-300 custom-scrollbar`}
                style={{ overflow: 'auto', height: '100%' }}
            >
                <span className={`d-flex justify-center items-center ${isExpandPropertyPanel ? 'flex-row' : 'flex-col-reverse'} justify-between px-2 py-1 sticky top-0 bg-[#edf0f5] transition-all duration-300 ease-in-out`}>
                    <p className={`m-0 text-sm font-bold text-gray-600 whitespace-nowrap  ${!isExpandPropertyPanel ? 'rotate-90 translate-y-5 pl-8' : "rotate-0 translate-y-0 "}`}>Data Sources</p>
                    <KeyboardDoubleArrowRightOutlinedIcon
                        className={`font-bold text-[#075985] cursor-pointer transition-transform duration-300 ease-in-out  ${isExpandPropertyPanel ? 'rotate-180' : 'rotate-0'}`}
                        onClick={() => {
                            setIsExpandPropertyPanel(!isExpandPropertyPanel);
                        }}
                        sx={{
                            width: '30px !important'
                        }} />
                </span>
                {isExpandPropertyPanel &&
                    <>
                        <span className='sticky top-[32px] z-10'>
                            <span className='w-full relative inline-flex m-2'>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e?.target?.value)}
                                    className='block p-[3px] pl-[5px] text-[#9ca3af] font-semibold bg-white border-1 border-gray-100 rounded  text-sm w-[92%] placeholder:text-[#c5c8cb] placeholder:font-normal focus:outline-none focus:border-gray-300'
                                />
                                <SearchIcon
                                    style={{
                                        position: 'absolute',
                                        right: '24px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        color: '#c5c8cb',
                                    }}
                                />
                            </span>
                        </span>
                        <span style={{ overflowY: 'auto', maxHeight: '100%' }} className='no-scrollbar'>
                            {loadingTables ? (
                                <div className="flex justify-center items-center h-20">
                                    <Stack sx={{ color: 'grey.500' }}>
                                        <CircularProgress color="inherit" size="30px" />
                                    </Stack>
                                </div>
                            ) : (
                                tables?.length > 0 ? (
                                    filteredTables?.length > 0 ? (
                                        filteredTables.map((table, index) => (
                                            <div key={`table-${index}`}>
                                                <SidebarSection
                                                    textColor={"text-gray-500"}
                                                    title={
                                                        <div className="flex items-center">
                                                            <TableViewIcon
                                                                className={`mr-2 ${component?.properties?.tableId == table?.tableMetaData?.table_id ? "text-[#075985]" : "text-gray-500"}`}
                                                            />
                                                            <Tooltip title={table?.table_name}>
                                                                <span
                                                                    onClick={() => {
                                                                        handleItemToggle(table?.table_name, table);
                                                                        handleToggle(table?.table_name);
                                                                    }}
                                                                    className={`${component?.properties?.tableId == table?.tableMetaData?.table_id ? "text-[#075985] underline" : "text-gray-500"}`}
                                                                >
                                                                    {table?.table_name?.length > 18 ? table?.table_name?.slice(0, 15) + '...' : table?.table_name}
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    isOpen={openItem === table?.table_name}
                                                    onToggle={() => handleToggle(table?.table_name)}
                                                >
                                                    {/* Select All Checkbox */}
                                                    {/* <span className="flex ml-8 mt-1">
                                                        <input
                                                            type="checkbox"
                                                            className="cursor-pointer"
                                                            checked={
                                                                component?.properties?.tableId == table?.tableMetaData?.table_id &&
                                                                table?.columnMetaData?.length > 0 &&
                                                                table?.columnMetaData?.every(item =>
                                                                    selectedColumn.some(col => col.columnId === item.column_id)
                                                                )
                                                            }
                                                            onChange={() => {
                                                                const allColumns = table?.columnMetaData?.map(col => ({
                                                                    columnId: col?.column_id,
                                                                    columnName: col?.column_name
                                                                })) || [];
                                                                handleSelectAll({
                                                                    table,
                                                                    tableId: table?.tableMetaData?.table_id,
                                                                    tableName: table?.table_name,
                                                                    columns: allColumns
                                                                });
                                                            }}
                                                        />
                                                        <span className="pl-3 text-sm">Select All</span>
                                                    </span>

                                                    {/* Column Checkboxes */}
                                                    {table?.columnMetaData
                                                        ?.filter((item) =>
                                                            item !== `${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`
                                                            && !component?.properties?.columnProperties?.find(col => col?.name === item?.column_name)?.isDeleted
                                                        )
                                                        ?.map((item) => (
                                                            <ColumnItem
                                                                key={item.column_id}
                                                                item={item}
                                                                table={table}
                                                                component={component}
                                                                handleCheckChange={handleCheckChange}
                                                                selectedColumn={selectedColumn}
                                                                showAlert={showAlert}
                                                                components={components}
                                                                isListview={false}
                                                            />
                                                        ))}
                                                </SidebarSection>
                                            </div>
                                        ))
                                    ) : (
                                        <span className="text-gray-400 font-semibold text-sm flex justify-center items-center w-full">
                                            No Data Source found.
                                        </span>
                                    )
                                ) : (
                                    <span className="text-gray-400 font-semibold text-sm flex justify-center items-center w-full">
                                        No Data Source found.
                                    </span>
                                )
                            )}
                        </span>
                    </>
                }
            </div>
        </>
    )

}
const SidebarSection = ({ title, isOpen, onToggle, children, isMenuExpand, icon, textColor, bgColor }) => {
    return (
        <div
            className={`sidebar-section`}
        >
            <div
                // className="sidebar-section-header cursor-pointer px-2 py-2"
                className={`sidebar-section-header cursor-pointer px-2 py-2 ${bgColor}`}
            >
                <div className={`d-flex ${isMenuExpand === false ? 'justify-content-center' : 'justify-between'}`}>
                    {true ? <div className="d-flex w-full  items-center">
                        <button
                            className="toggle-button"
                            onClick={onToggle}
                        >
                            {isOpen ? (
                                <ExpandMoreIcon className={textColor} />
                            ) : (
                                <KeyboardArrowRightIcon className={textColor} />
                            )}
                        </button>
                        <span
                            className="font-semibold d-flex items-center gap-2 text-sm text-gray-500"
                        >
                            {icon}{title}
                        </span>
                    </div> : <span className="font-semibold text-sm text-white">{icon}</span>}
                </div>
            </div>
            {isOpen && (
                <div className="sidebar-section-content">{children}</div>
            )}
        </div>
    );
};

export default DataPanel;

const ColumnItem = ({ item, table, component, handleCheckChange, selectedColumn, showAlert, components, isListColumn, listViewData, isListview }) => {

    const [{ isDragging }, drag] = useDrag(() => ({
        type: "COLUMN",
        item: {
            columnName: item.column_name,
            tableName: table.table_name,
            columnId: item.column_id,
            tableId: table?.tableMetaData?.table_id,
            type: item.column_type
        },
        // canDrag: () => {
        //     if (isListview) return false;
        //     // Find the first chart or table component (whichever comes first)
        //     const firstDataComponent = components?.find(comp => comp.type === "chart" || comp.type === "table");
        //     const existingTableId = firstDataComponent?.properties?.tableId;

        //     // If a tableId is already set and differs, show an alert and prevent dragging
        //     if (existingTableId && existingTableId !== table?.tableMetaData?.table_id) {
        //         showAlert(
        //             "Only one data source can be used per report.",
        //             "info"
        //         );
        //         return false;
        //     }
        //     return true;
        // },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    const getDataTypeIcon = (dataType) => {
        switch (dataType) {
            case 'character varying':
                return <HdrAutoOutlinedIcon className="text-gray-700 mr-1 type-icon" />;
            case 'timestamp with time zone':
                return <WatchLaterOutlinedIcon className="text-gray-700 mr-1 type-icon" />;
            case 'integer':
                return <TagOutlinedIcon className="text-gray-700 mr-1 type-icon" />;
            case 'double precision':
                return <TagOutlinedIcon className="text-gray-700 mr-1 type-icon" />;
            default:
                return null;
        }
    };
    return (
        <div ref={drag} className='d-flex flex-col ml-8 mt-1' style={{ opacity: isDragging ? 0.5 : 1 }}>
            <span className='d-flex'>
                <input
                    type="checkbox"
                    checked={
                        component?.properties?.tableId == table?.tableMetaData?.table_id &&
                        selectedColumn?.some((col) => col?.columnId === item?.column_id)
                    }
                    className='cursor-pointer'
                    onChange={() => {

                        if (component?.properties?.tableId && component?.properties?.data?.length > 0 && component?.properties?.tableId !== table?.tableMetaData?.table_id) {
                            showAlert("Data source already selected for the widget", "info");
                        } else {
                            handleCheckChange({ tableName: table?.table_name, columnName: item?.column_name, columnId: item?.column_id, tableId: table?.tableMetaData?.table_id });
                        }

                    }}
                />
                <Tooltip title={item?.column_name}>
                    <span
                        className={`text-sm pl-3 ${component?.table_name === table?.table_name ? 'text-[#075985] font-bold' : 'text-gray-800'} ${component?.type === "chart" ? "cursor-grab" : "cursor-default"}`}>
                        {getDataTypeIcon(item.column_type)}
                        {item?.column_name?.length > 18 ? item?.column_name?.slice(0, 17) + '...' : item?.column_name}
                    </span>
                </Tooltip>
            </span>
        </div>
    );
};