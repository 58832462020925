import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { Tab, Tabs } from "@mui/material";
import { useFilterTableDataMutation, useGetTableDataByNameMutation } from "../../../../../features/report/reportApiSlice";
import { useGetReportTableDataMutation, useGetTableDataMutation } from "../../../../../features/report/skorBoard";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import AreaChartIcon from "../../../../../assets/svgs/AreaChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import { CheckBox } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DoubleBarChartImage from "../../../../../assets/images/doublebar-chart-icon.png";
import DoublebarChartIconsvg from "../../../../../assets/svgs/DoublebarChartIconsvg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LinebarChartIconsvg from "../../../../../assets/svgs/linebarChartIconsvg";
import PieChartIcon from "@mui/icons-material/PieChart";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import ReusableReportModal from "../ReusableReportModal";
import ScatterChart from "../../../../../assets/svgs/ScatterChart";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import SearchIcon from '@mui/icons-material/Search';
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { SketchPicker } from "react-color";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { Tooltip } from "@mui/material";
import { XAxis } from "recharts";
import classNames from "classnames";
import lineBarChartImage from "../../../../../assets/images/linebar-chart-icon.png";
import moment from "moment";
import scatteredChartImage from "../../../../../assets/images/scatter-chart-icon.png";

const ItemTypes = {
  COLUMN: 'column',
};

const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
  // const [isOpen, setIsOpen] = React.useState(false);
  // const toggleOpen = () => {
  //     setIsOpen(!isOpen);
  // };

  return (
    <div className="sidebar-section">
      <div
        className={`sidebar-section-header ${subSection ? "px-1" : "px-2"} cursor-pointer 
       }`}
        onClick={onToggle}
        style={{
          backgroundColor: !subSection ? "#c1c2c6" : "#e4e4eb",
          borderRadius: subSection ? "5px" : ""
        }}
      >
        <div className={`d-flex ${subSection ? "" : "justify-between"} items-center`}>
          <span
            className={`font-semibold  ${subSection ? "text-[12px] text-gray-600 py-1" : "text-[12px] text-[#4d5259]" //4b5563
              } `}
            style={{
              color: subSection ? "text-sm text-gray-600" : "text-[#4d5259]",
              paddingLeft: subSection ? "" : "8px",
            }}
          >
            {title}
          </span>
          <button className="toggle-button">
            {!subSection ? (
              isOpen ? (
                <Tooltip title="Remove">
                  <RemoveIcon
                    className="text-[#4d5259]"
                    sx={{ fontSize: "14px" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Add">
                  <AddIcon
                    className="text-[#4d5259]"
                    sx={{ fontSize: "14px" }}
                  />
                </Tooltip>
              )
            ) : null}
          </button>
        </div>
      </div>
      {isOpen && <div className="sidebar-section-content">{children}</div>}
    </div>
  );
};
const Column = ({ item, removeColumn, showCloseIcon }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.COLUMN,
    item: { column: item },  // Passing the column name only
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`relative pl-0.5 pr-0.5 text-[#374151] text-[10px] bg-white input-border-custome h-fit w-fit rounded-md flex items-center justify-between mb-0.5 ml-0.5  ${isDragging ? 'opacity-50' : ''
        }`}
      style={{ minWidth: '50px', fontSize: " 0.75rem", borderRadius: "5px" }}
    >
      {item?.columnName}
      {/* <button className="ml-2 text-gray-400 hover:text-red-600" onClick={() => removeColumn(item)}>
        <CloseIcon className="text-sm text-gray-400" />
      </button> */}
      {showCloseIcon && (
        <button className="ml-2 text-gray-400 hover:text-red-600" onClick={() => removeColumn(item)}>
          <CloseIcon className="text-sm text-gray-400" />
        </button>
      )}
    </div>
  );
};

const DropZone = ({ accept, onDrop, children, title, }) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: (item) => {
      onDrop(item)
    },
    // op: (item) => {
    //   // Call the onDrop callback if the condition is not met
    //   if (item !== null) {
    //     console.log(item, ">>> Ojjhju")
    //     onDrop(item);
    //   }
    // },
    // canDrop: (item) => {
    //   if (item === null) {
    //     return false; // Prevent dropping
    //   }
    //   return true; // Allow dropping
    // },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <>
      <p className="prop-label m-0">{title}</p>
      <div
        ref={drop}
        className={`flex flex-wrap p-1 overflow-auto custom-scrollbar min-h-12 max-h-14 input-border-custome rounded w-full ${isOver ? 'bg-blue-100' : ''
          }`}
      >
        {children}
      </div>
    </>
  );
};
function ChartProperties({
  component,
  onPropertyChange,
  setSelectedComponent,
  showAlert,
  flag,
  setFlag,
  reportAreaRef,
  generateChartData,
  echartsRef,
  generatePieChartData,
  generateDoubleChartData,
  openSection,
  setOpenSection,
  isAnimated,
  setIsAnimated,
  setConfirmModalOpendata,
  drillData,
  pieDrillData,
  tables,
  setReportProperty
}) {
  const borderColorRef = React.useRef(null);
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [colorPickerBg, setColorPickerBg] = React.useState(false);
  const [colorPickerText, setColorPickerText] = React.useState(false);
  const colorPickerRefs = React.useRef({});
  const columnDropdownRef = React.useRef(null);
  const [columnListOpen, setColumnListOpen] = React.useState(false);
  const [colorPickerState, setColorPickerState] = React.useState({});
  const [chartListOpen, setChartListOpen] = React.useState(false);
  const [zAxisListOpen, setzAxisListOpen] = React.useState(false);
  const textColorRef = React.useRef(null);
  const backgroundColorRef = React.useRef(null);
  const DropDownRef = React.useRef(null);
  const [selectedChartType, setSelectedChartType] = React.useState(
    component?.properties?.chartType == "bar"
      ? { value: 1, type: "Bar", icon: <BarChartIcon color="primary" /> }
      : { value: 2, type: "Pie", icon: <PieChartIcon color="primary" /> }
  );
  const [checkedItems, setCheckedItems] = React.useState(
    component?.properties?.yAxis || []
  );
  const zAxisRef = React.useRef(null);
  const [isLogScaleY, setIsLogScaleY] = React.useState(false);
  const [selectedLogValues, setSelectedLogValues] = React.useState([]); // State for selected log values
  const zAxisValues = component?.properties?.zAxis || []; // Get Z-axis values
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const logDropDownRef = React.useRef(null);

  const colForPie = component?.properties?.columns?.filter((item) => !component?.properties?.slice?.includes(item));
  const columnsMe = tables?.find((item) => item?.tableMetaData?.table_id == component?.properties?.tableId)?.columnMetaData?.map(item => ({ column_id: item?.column_id, column_name: item?.column_name })) || [];
  const [selectedCols, setSelectedCols] = React.useState(columnsMe);
  const [selectedColumn, setSelectedColumn] = React.useState(
    component?.properties?.columns
  );
  const [rowFields, setRowFields] = React.useState([]);
  const [colFields, setColFields] = React.useState([]);
  const [IsAddNewColumnOpen, setIsAddNewColumnOpen] = React.useState(false);
  const [deselectedColumns, setDeselectedColumns] = React.useState([]);
  const [selectedLine, setSelectedLine] = React.useState(component?.properties?.line || "none");

  // const [getTableDataSkorBoard] = useGetTableDataMutation();
  const [getTableDataSkorBoard] = useGetReportTableDataMutation()

  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const Charts = [
    {
      value: 1,
      type: "Bar",
      label: "bar",
      icon: (
        <Tooltip title={"Bar chart"}>
          <BarChartIcon
            style={{ color: "#1976d2", padding: "0px", margin: "0px" }}
          />
        </Tooltip>
      ),
    },
    {
      value: 2,
      type: "Pie",
      label: "pie",
      icon: (
        <Tooltip title={"Pie chart"}>
          <PieChartIcon style={{ color: "#1976d2" }} />
        </Tooltip>
      ),
    },
    {
      value: 3,
      type: "Line with Bar",
      label: "barWithLine",
      icon: (
        <LinebarChartIconsvg
          height="24px"
          width="24px"
          tooltipText="LineBar chart"
        />
      ),
    },
    {
      value: 4,
      type: "Stacked",
      label: "stacked",
      icon: (
        <Tooltip title={"StackedBar chart"}>
          <StackedBarChartIcon style={{ color: "#1976d2" }} />
        </Tooltip>
      ),
    },
    {
      value: 5,
      type: "Double",
      label: "double",
      icon: (
        <DoublebarChartIconsvg
          height="23px"
          width="23px"
          tooltipText="DoubleBar chart"
        />
      ),
    },
    {
      value: 6,
      type: "Line",
      label: "line",
      icon: (
        <Tooltip title={"Line Chart"}>
          <ShowChartIcon style={{ color: "#1976d2" }} />
        </Tooltip>
      ),
    },
    {
      value: 7,
      type: "Area",
      label: "area",
      icon: (
        <AreaChartIcon height="24px" width="24px" tooltipText="Area Chart" />
      ),
    },
    {
      value: 8,
      type: "Scatter",
      label: "scatter",
      icon: (
        <ScatterChart height="24px" width="24px" tooltipText="Scatter Chart" />
      ),
    },
    // {value:5,type:'Scatter',icon:<ScatterPlotIcon style={{ fontSize: '2.2rem', color: '#1976d2' }} color='primary'/>}
  ];
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value?.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("MM/DD/YYYY")
          : value;
  };
  const handlePositionChange = (e, property) => {
    const newValue = parseInt(e.target.value, 10);
    let updatedProperties = { [property]: newValue };
    if (newValue < 0) {
      updatedProperties[property] = 0;
    }
    if (property === "top") {
      updatedProperties.bottom =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure bottom doesn't go below 0
      if (updatedProperties.bottom < 0) {
        updatedProperties.bottom = 0;
        updatedProperties.top =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "bottom") {
      updatedProperties.top =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure top doesn't go below 0
      if (updatedProperties.top < 0) {
        updatedProperties.top = 0;
        updatedProperties.bottom =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "left") {
      updatedProperties.right =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.right < 0) {
        updatedProperties.right = 0;
        updatedProperties.left =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    } else if (property === "right") {
      updatedProperties.left =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.left < 0) {
        updatedProperties.left = 0;
        updatedProperties.right =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    }
    onPropertyChange(updatedProperties);
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        ...updatedProperties,
      },
    }));
    setFlag(false);
    setIsAnimated(false);
  };
  const handleClickOutside = (event) => {
    if (
      textColorRef?.current &&
      !textColorRef?.current?.contains(event.target)
    ) {
      setColorPickerText(false);
    }
    if (
      backgroundColorRef?.current &&
      !backgroundColorRef?.current?.contains(event.target)
    ) {
      setColorPickerBg(false);
    }
    if (
      borderColorRef.current &&
      !borderColorRef.current.contains(event.target)
    ) {
      setColorPickerOpen(false);
    }
    const colorPickerElements = Object.values(colorPickerRefs.current);
    if (!colorPickerElements.some((el) => el && el.contains(event.target))) {
      setColorPickerState({});
    }
    if (
      DropDownRef?.current &&
      !DropDownRef?.current?.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
    if (
      logDropDownRef?.current &&
      !logDropDownRef?.current?.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };
  const updateChartAxis = async (option) => {
    try {
      let data = [];
      let columnProp = [];
      let tableId;
      if (component?.properties?.data?.length <= 0) {
        let tableName = option?.rowFields?.length > 0 ? option?.rowFields[0]?.tableId : option?.colFields?.length > 0 ? option?.colFields[0]?.tableId : option?.slice?.length > 0 ? option?.slice[0]?.tableId : option?.legends?.length > 0 ? option?.legends[0]?.tableId : 'null'
        const result = await getTableDataSkorBoard({ tableId: tableName, skip: 0, limit: 200 }).unwrap();
        // data = result?.data?.tableData;
        data = result?.data?.data;
        // tableId = result?.data?.metaData?.tableMetaData?.table_id
        tableId = result?.data?.tableId
        columnProp = result?.data?.tableProperties?.columnProperties

        setReportProperty((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            tableId,
            filters: {
              dateFilter: {
                fromDate: result?.data?.filters?.dateFilter?.fromDate,
                toDate: result?.data?.filters?.dateFilter?.toDate
              },
              shift_type: result?.data?.filters?.shift_type
            },
          },
        }));
      }
      let result;
      const existingDrillData = option?.isDrill ? drillData : {
        level: 0,
        level1: { xAxis: "", yAxis: "" },
        level2: { xAxis: "", yAxis: "" },
        breadcrumbs: [{ level: 0, xAxisValue: "Main" }]
      };
      const xVal = drillData?.level === 1 ? drillData?.breadcrumbs[1]?.xAxisValue : drillData?.breadcrumbs[2]?.xAxisValue;

      const yVal = drillData?.level === 1 ? drillData?.breadcrumbs[1]?.yAxisValue : drillData?.breadcrumbs[2]?.yAxisValue;

      const drilledX = drillData?.level === 1 ? component?.properties?.colFields[0] : drillData?.level1?.xAxis

      const drilledY = drillData?.level === 2 ? component?.properties?.rowFields[0] : drillData?.level1?.yAxis

      const matchedRecords = component?.properties?.data?.filter(record =>
        record[drilledX] === xVal && record[drilledY] === yVal
      );
      const matchDataForSec = component?.properties?.data?.filter(record =>
        record[component?.properties?.colFields[0]] === drillData?.breadcrumbs[1]?.xAxisValue && record[component?.properties?.yAxis] === drillData?.breadcrumbs[1]?.yAxisValue
      )
      const matchedRecordsSec = matchDataForSec?.filter(record =>
        record[drilledX] === xVal && record[drilledY] === yVal
      );
      const isDrilled = drillData?.level !== 0 ? true : false;

      const drilledData = drillData?.level == 1 ? matchedRecords : matchedRecordsSec;
      const colField = drillData?.level == 1 ? component?.properties?.colFields[1]?.columnName : component?.properties?.colFields[2]?.columnName
      if (
        component?.properties?.chartType === "bar" ||
        component?.properties?.chartType === "barWithLine" ||
        component?.properties?.chartType === "stacked" ||
        component?.properties?.chartType === "line" ||
        component?.properties?.chartType === "area" ||
        component?.properties?.chartType === "scatter"
      ) {
        if (component?.properties?.chartType !== 'barWithLine') {
          result = await generateChartData(
            data?.length > 0 ? data : isDrilled && option?.isDrill ? drilledData : component?.properties?.data,
            isDrilled && option?.isDrill ? colField : option?.xAxis,
            option?.yAxis,
            option?.legends ? [option?.legends[0]?.columnName] : [component?.properties?.legends[0]?.columnName],
            null,
            option?.aggType ? option?.aggType : component?.properties?.aggType,
            tableId
          );
        } else {
          result = await generateChartData(
            isDrilled && option?.isDrill ? drilledData : component?.properties?.data,
            isDrilled && option?.isDrill ? colField : option?.xAxis,
            option?.yAxis,
            option?.legends ? [option?.legends[0]?.columnName] : [component?.properties?.legends[0]?.columnName],
            !option?.rowFields.some(obj => obj.columnId === option?.line?.column_id) && !option?.colFields.some(obj => obj.columnId === option?.line?.column_id) ? option?.line?.column_name : null,
            option?.aggType ? option?.aggType : component?.properties?.aggType,
            tableId
          );
        }

        console.log(result, ">>>> Result after calling the funnnn ...");
        const newOption = {
          ...component?.properties?.option,
          series: result?.series,
          xAxis: {
            ...component?.properties?.option?.xAxis,
            data: result?.xValues,
          },
          yAxis: {
            ...component?.properties?.option?.yAxis,
            interval: result?.interval,
          },
          legend: { data: result?.series?.map((series) => series.name) },
        };
        onPropertyChange({
          option: newOption,
          data: data?.length > 0 ? data : component?.properties?.data,
          columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
          tableId: data?.length > 0 ? tableId : component?.properties?.tableId,
          xAxis: isDrilled && option?.isDrill ? colField : option?.xAxis,
          yAxis: option?.yAxis,
          line: !option?.rowFields?.includes(option?.line) && !option?.colFields?.includes(option?.line) ? option?.line : null,
          zAxis: option?.zAxis,
          isLogScaleY: option?.isLogScaleY,
          isLogScaleZ: option?.isLogScaleZ,
          logColumns: {
            ...component?.properties?.logColumns,
            yAxis: option?.isLogScaleY ? `Log(${option?.yAxis})` : null,
            zAxis:
              option?.isLogScaleZ && option?.line == null
                ? `Log(${option?.zAxis[0]})`
                : option?.isLogScaleZ && option?.line !== null
                  ? `Log(${option?.line})`
                  : null,
          },
          rowFields: option?.rowFields,
          colFields: option?.colFields,
          drillData: existingDrillData,
          slice: option?.colFields,
          isFilter: false,
          legends: option?.legends ? option?.legends : component?.properties?.legends,
          aggType: option?.aggType ? option?.aggType : component?.properties?.aggType
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            data: data?.length > 0 ? data : prev?.properties?.data,
            columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
            tableId: data?.length > 0 ? tableId : component?.properties?.tableId,
            option: newOption,
            xAxis: isDrilled && option?.isDrill ? colField : option?.xAxis,
            yAxis: option?.yAxis,
            line: !option?.rowFields?.includes(option?.line) && !option?.colFields?.includes(option?.line) ? option?.line : null,
            zAxis: option?.zAxis,
            isLogScaleY: option?.isLogScaleY,
            isLogScaleZ: option?.isLogScaleZ,
            logColumns: {
              ...component?.properties?.logColumns,
              yAxis: option?.isLogScaleY ? `Log(${option?.yAxis})` : null,
              zAxis:
                option?.isLogScaleZ && option?.line == null
                  ? `Log(${option?.zAxis[0]})`
                  : option?.isLogScaleZ && option?.line !== null
                    ? `Log(${option?.line})`
                    : null,
            },
            rowFields: option?.rowFields,
            colFields: option?.colFields,
            slice: option?.colFields,
            // drillData: {
            //   level: 0,
            //   level1: { xAxis: "", yAxis: "" },
            //   level2: { xAxis: "", yAxis: "" },
            //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
            // }
            drillData: existingDrillData,
            isFilter: false,
            legends: option?.legends ? option?.legends : component?.properties?.legends,
            aggType: option?.aggType ? option?.aggType : component?.properties?.aggType
          },
        }));
        setIsAnimated(true);
      } else if (component?.properties?.chartType === "double") {
        //  result = generateChartData( option?.xAxis, option?.yAxis, option?.zAxis, null, false);
        const result = await generateDoubleChartData(
          data?.length > 0 ? data : isDrilled && option?.isDrill ? drilledData : component?.properties?.data,
          //  isDrilled && option?.isDrill ? drilledData : component?.properties?.data,
          isDrilled && option?.isDrill ? colField : option?.xAxis,
          option?.yAxis,
          option?.zAxis ? option?.zAxis?.map(item => item?.column_name) : component?.properties?.zAxis?.map(item => item?.column_name),
          true,
          component?.id
        );
        const newOption = {
          ...component?.properties?.option,
          series: result?.series,
          xAxis: {
            ...component?.properties?.option?.xAxis,
            data: result?.xValues,
          },
          yAxis: {
            ...component?.properties?.option?.yAxis,
            interval: result?.interval,
          },
          legend: { data: result?.series?.map((series) => series.name) },
        };
        onPropertyChange({
          option: newOption,
          tableId: data?.length > 0 ? tableId : component?.properties?.tableId,
          data: data?.length > 0 ? data : component?.properties?.data,
          columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
          xAxis: isDrilled && option?.isDrill ? colField : option?.xAxis,
          yAxis: option?.yAxis,
          // line: option?.line,
          zAxis: option?.zAxis,
          rowFields: option?.rowFields,
          colFields: option?.colFields,
          // drillData: {
          //   level: 0,
          //   level1: { xAxis: "", yAxis: "" },
          //   level2: { xAxis: "", yAxis: "" },
          //   breadcrumbs: [{ level: 0, xAxisValue: "main" }]
          // }
          drillData: existingDrillData,
          isFilter: false
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            tableId: data?.length > 0 ? tableId : component?.properties?.tableId,
            data: data?.length > 0 ? data : component?.properties?.data,
            columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
            option: newOption,
            xAxis: isDrilled && option?.isDrill ? colField : option?.xAxis,
            yAxis: option?.yAxis,
            //  line: option?.line,
            zAxis: option?.zAxis,
            rowFields: option?.rowFields,
            colFields: option?.colFields,
            drillData: existingDrillData,
            isFilter: false
          },
        }));
        // echartsRef.current.getEchartsInstance().setOption(newOption, true);
        setIsAnimated(true);
      } else {
        let result = await generatePieChartData(
          data?.length > 0 ? data : component?.properties?.data,
          option?.slice[0]?.columnName ? option?.slice[0]?.columnName : component?.properties?.slice[0]?.columnName,
          option?.aggType ? option?.aggType : component?.properties?.aggType);
        const newOption = {
          ...component?.properties?.option,
          series: result?.series,
          // xAxis: {
          //     ...component?.properties?.option?.xAxis,
          //     data: result?.xValues
          // },
          legend: { data: result?.series[0]?.data?.map((item) => item?.name) },
        };
        onPropertyChange({
          option: newOption,
          tableId: data?.length > 0 ? tableId : component?.properties?.tableId,
          data: data?.length > 0 ? data : component?.properties?.data,
          columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
          chartType: "pie",
          //   xAxis: option?.xAxis,
          slice: option?.slice,
          pieDrillData: {
            level: 0,
            level1: { slice: "" },
            level2: { slice: "" },
            breadcrumbs: [{ level: 0, sliceValue: "Main" }]
          },
          drillData: existingDrillData,
          colFields: option?.slice,
          isFilter: false,
          aggType: option?.aggType ? option?.aggType : component?.properties?.aggType
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            tableId: data?.length > 0 ? tableId : component?.properties?.tableId,
            data: data?.length > 0 ? data : component?.properties?.data,
            columnProperties: data?.length > 0 ? columnProp : component?.properties?.columnProperties,
            option: newOption,
            chartType: "pie",
            // xAxis: option?.xAxis,
            slice: option?.slice,
            pieDrillData: {
              level: 0,
              level1: { slice: "" },
              level2: { slice: "" },
              breadcrumbs: [{ level: 0, sliceValue: "Main" }]
            },
            drillData: existingDrillData,
            colFields: option?.slice,
            isFilter: false,
            aggType: option?.aggType ? option?.aggType : component?.properties?.aggType
          },
        }));
        setIsAnimated(true);

      }
      setFlag(true)
    } catch (err) {
      console.log(err, "Error")
    }
  };
  // const handleColorPickerToggle = (itemName) => {
  //   setColorPickerState((prev) => ({
  //     ...prev,
  //     [itemName]: !prev[itemName],
  //   }));
  // };

  const handleColorPickerToggle = (itemName) => {
    setColorPickerState((prev) => ({
      [itemName]: !prev[itemName], // Only keep this item open
    }));
  };

  const handleCheckboxChange = (item) => {
    // Check if the item is already in the array
    const itemIndex = component?.properties?.yAxis.indexOf(item);

    if (itemIndex === -1) {
      // Item is not in the array, add it
      const newValues = [...component.properties.yAxis, item];
      setCheckedItems(newValues);
      updateChartAxis({
        yAxis: newValues,
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
      });
    } else {
      // Item is in the array, remove it
      const newValues = component?.properties?.yAxis?.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      updateChartAxis({
        yAxis: newValues,
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
      });
    }
  };
  const handleCheckboxChangeLog = (item) => {
    const itemIndex = component?.properties?.logColumns?.double?.indexOf(item);
    if (itemIndex === -1) {
      const newValues = [...component.properties.logColumns.double, item];
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: component?.properties?.zAxis,
        yAxis: "",
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
        logColumns: newValues,
      });
    } else {
      const newValues = component?.properties?.logColumns?.double?.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: component?.properties?.zAxis,
        xAxis: component?.properties?.xAxis,
        yAxis: "",
        line: component?.properties?.line,
        logColumns: newValues,
      });
    }
    setIsAnimated(true);
  };
  const handleCheckboxChangeZAxis = (item) => {
    const itemIndex = component?.properties?.zAxis.indexOf(item);
    if (itemIndex === -1) {
      const newValues = [...component.properties.zAxis, item];
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: newValues,
        yAxis: "",
        xAxis: component?.properties?.xAxis,
        line: component?.properties?.line,
        logColumns: component?.properties?.logColumns?.double,
      });
    } else {
      const newValues = component?.properties?.zAxis?.filter(
        (value) => value !== item
      );
      setCheckedItems(newValues);
      updateChartAxis({
        zAxis: newValues,
        xAxis: component?.properties?.xAxis,
        yAxis: "",
        line: component?.properties?.line,
        logColumns: component?.properties?.logColumns?.double?.filter(
          (value) => value !== `Log(${item})`
        ),
      });
    }
    setIsAnimated(true);
  };
  const handleLineChange = (e) => {
    const newValue = e.target.value === "none" ? null : e.target.value;
    const columnMetaData = tables?.find((item) => item?.tableMetaData?.table_id === component?.properties?.tableId)?.columnMetaData
    const line = columnMetaData?.find((item) => item?.column_id === newValue)
    setSelectedLine(line);

    // updateChartAxis({
    //   ...component.properties,
    //   line: newValue,
    //   yAxis: component?.properties?.rowFields[0],
    //   xAxis: component?.properties?.colFields[0],
    //   zAxis: [],
    // });
    updateChartAxis({
      yAxis: component?.properties?.rowFields[0]?.columnName,
      xAxis: component?.properties?.colFields[0]?.columnName,
      zAxis: component?.properties?.zAxis,
      line: line,
      rowFields: component?.properties?.rowFields,
      colFields: component?.properties?.colFields,
      isDrill: true
    });

  };


  const displayedOptions = tables?.find((item) => item?.tableMetaData?.table_id == component?.properties?.tableId)?.columnMetaData || [];

  if (selectedLine && selectedLine !== "none" && !displayedOptions?.includes(selectedLine)) {
    displayedOptions?.push(selectedLine); // Add selected value back to display list if missing
  }

  const Fonts = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Times",
    "Courier New",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Trebuchet MS",
    "Comic Sans MS",
    "Impac",
  ];
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const [columnToFilter, setColumnToFilter] = React.useState({ name: "", type: "" });
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);
  const [filterObj, setFilterObj] = React.useState(null);
  const [filterData] = useFilterTableDataMutation();
  const [rangeValue, setRangeValue] = React.useState(0);
  const dropdownRef = React.useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState(columnToFilter?.colData || []);
  const [dateRange, setDateRange] = React.useState([null, null]);

  const stringTypeConditions = [
    { value: "CONTAINS", label: "Contains" },
    { value: "BEGIN_WITH", label: "Begin with" },
    { value: "MATCHES", label: "Matches" },
    { value: "ENDS_WITH", label: "Ends with" },
    { value: "DOES_NOT_CONTAINS", label: 'Does not contain' },
    { value: "DOES_NOT_BEGIN_WITH", label: "Does not begin with" },
    { value: "DOES_NOT_MATCHES", label: "Does not match" },
    { value: "DOES_NOT_ENDS_WITH", label: "Does not end with" }
  ]
  const numericTypeConditions = [
    { value: "EQUALS_TO", label: "Equals to" },
    { value: "GREATER_THAN", label: "Greater than" },
    { value: "LESS_THAN", label: "Less than" },
    { value: "GREATER_THAN_EQUAL", label: "Greater than equal" },
    { value: "LESS_THAN_EQUAL", label: "Less than equal" },
    { value: "DOES_NOT_EQUAL", label: "Does not equal" }
  ]

  const handleToggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setFilterObj({ ...filterObj, values: filteredItems });
    } else {
      setFilterObj({ ...filterObj, values: [] });
    }
  };
  function processData(data, selectedColumns) {
    const treeStructure = {};
    data.forEach(row => {
      let currentLevel = treeStructure;
      selectedColumns.forEach((column, index) => {
        const columnValue = row[column.columnToFilter];
        if (index === selectedColumns.length - 1) {
          currentLevel[columnValue] = (currentLevel[columnValue] || 0) + 1;
        } else {
          if (!currentLevel[columnValue]) {
            currentLevel[columnValue] = {};
          }
          currentLevel = currentLevel[columnValue];
        }
      });
    });
    return treeStructure;
  }
  const handleSelectColumnToFilter = async (value) => {
    await clearFilter();
    const colType = component?.properties?.columnProperties?.find((item) => item?.name === value)?.type;
    const colData = component?.properties?.data?.map((item) => item[value]);
    value == -1 ?
      setColumnToFilter({ name: null, type: null, min: 0, max: 0, colData: [...new Set(colData)] }) : setColumnToFilter({ name: value, type: colType, min: 0, max: 0, colData: [...new Set(colData)] });
    setFilterObj({ columnName: value, columnType: colType, condition: null, conditionValue: "", min: null, max: null, from: null, to: null, values: [] });
    if (colType == "INTEGER") {
      const col = component?.properties?.data?.map(item => parseInt(item[value]));
      const min = Math.min(...col);
      const max = Math.max(...col);
      min === max ? setRangeValue(max) : setRangeValue(min);
      setColumnToFilter({
        name: value,
        type: colType,
        min: min,
        max: max,
        colData: [...new Set(colData)]
      });
      setFilterObj({
        columnName: value,
        columnType: "INT",
        condition: null,
        conditionValue: "",
        min: min,
        max: max,
        from: null,
        to: null,
        values: []
      });
    }
  }
  const clearFilter = async () => {
    if (filterObj?.columnName !== undefined) {
      try {
        const result = await filterData({ ...filterObj, columnName: filterObj?.columnName, columnType: "VARCHAR", condition: null, conditionValue: null, values: [], tableName: component?.properties?.tableName }).unwrap();
        onPropertyChange({
          data: result?.data,
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            data: result?.data,
          },
        }));
        // Object?.entries(component?.properties?.drilledData).length > 0 ? setDrillDownData(component?.properties?.drilledColumns, result?.data) : ""
        setFlag(false);
      } catch (err) {
        showAlert(err?.data?.message, "error")
      }
    }
    setFilterObj(null);
    setColumnToFilter(null);
    setSearchQuery("");
    setSelectAll(false);
  }
  const handleFilterCheckboxChange = (item) => {
    if (filterObj?.values?.includes(item)) {
      setFilterObj({
        ...filterObj,
        values: filterObj?.values?.filter((val) => val !== item),
      });
    } else {
      setFilterObj({ ...filterObj, values: [...filterObj.values, item] });
    }
  };
  const calculateFillPercentage = () => {
    const min = columnToFilter?.min ?? 0;
    const max = columnToFilter?.max ?? 100;
    const percentage = ((rangeValue - min) / (max - min)) * 100;

    return `${isNaN(percentage) ? 100 : percentage}%`;
  };
  const handleRangeChange = (event) => {
    const value = event.target.value;
    setRangeValue(value);
    // Update state for the range value
    setFilterObj({ ...filterObj, max: Number(value) })
  };
  const handleTabChange = (event, newValue) => {
    onPropertyChange({ tableView: newValue });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        tableView: newValue
      },
    }));
    setFlag(false);
  }

  const handleDropToRow = (item) => {
    // Get the current row field
    // Allow up to 3 fields in colFields
    // if (component?.properties?.colFields?.length <= 1) {
    //   showAlert("The Column Field must contain at least one column.", "info")
    //   return;
    // }
    // component?.properties?.tableId && component?.properties?.data?.length > 0 && component?.properties?.tableId !== table?.tableMetaData?.table_id

    const currentRowField = component?.properties?.rowFields[0] ? component?.properties?.rowFields[0] : {};
    // If there is a current row field, add it back to selectedCols
    if (currentRowField) {
      setSelectedCols((prev) => [...prev, currentRowField]);
    }
    // Allow only one field in rowFields, replacing the existing one
    const newRowFields = [item];
    const updatedColFields = component?.properties?.colFields?.filter(col => col?.columnId !== item.columnId);

    setSelectedCols((prev) => prev?.filter((col) => col?.columnName !== item.column));
    onPropertyChange({
      rowFields: newRowFields,
      colFields: updatedColFields,
    });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        rowFields: newRowFields,
        colFields: updatedColFields,
      },
    }));
    setFlag(false);
    updateChartAxis({
      yAxis: newRowFields[0]?.columnName,
      xAxis: updatedColFields[0]?.columnName,
      zAxis: component?.properties?.zAxis?.filter((i) => i !== item?.column),
      legends: component?.properties?.legends,
      rowFields: newRowFields,
      colFields: updatedColFields
    })
  };
  const handleDropToLegend = (item) => {
    // if (item.column === component?.properties?.rowFields[0]) {
    //   showAlert("The Row Field must contain at least one column.", "info")
    //   return;
    // }

    const updatedRowFields = component?.properties?.rowFields?.filter(row => row?.columnId !== item?.columnId);
    const newLegends = [item];

    onPropertyChange({ legends: newLegends });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        legends: newLegends
      },
    }));
    setFlag(false);
    updateChartAxis({
      yAxis: component?.properties?.rowFields[0]?.columnName,
      xAxis: component?.properties?.colFields[0]?.columnName,
      //  zAxis: newLegends?.map((item) => item?.columnName),
      legends: newLegends,
      rowFields: component?.properties?.rowFields,
      colFields: component?.properties?.colFields
    })
  };
  const handleDropToCol = (item) => {
    if (component?.properties?.colFields.length < 3) {
      if (
        !component?.properties?.colFields.some((col) => col.columnId === item.columnId)// Prevent duplicates

      ) {
        const updatedRowFields = component?.properties?.rowFields?.filter(row => row?.columnId !== item?.columnId);
        const newColFields = [...component.properties.colFields, item];

        setSelectedCols((prev) => prev?.filter((col) => col?.columnName !== item.column));
        onPropertyChange({ rowFields: updatedRowFields, colFields: newColFields });

        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            rowFields: updatedRowFields,
            colFields: newColFields,
          },
        }));

        setFlag(false);

        updateChartAxis({
          yAxis: updatedRowFields[0]?.columnName,
          xAxis: newColFields[0]?.columnName,
          zAxis: component?.properties?.zAxis?.filter((i) => i !== item?.column),
          legends: component?.properties?.legends?.length <= 0 ? [item] : component?.properties?.legends,
          rowFields: updatedRowFields,
          colFields: newColFields
        });
      } else {
        // Show an alert if item is already present in colFields
        showAlert("This column is already added!", "warning");
      }
    }
  };


  const handleDropToColForPieChart = (item) => {
    if (component?.properties?.colFields.length < 3) {
      if (
        !component?.properties?.slice.some((sliceItem) => sliceItem.columnId === item.columnId) && // Prevent duplicates
        component?.properties?.slice.length < 3
      ) {
        const updatedSlice = [...component.properties.slice, item];

        setSelectedCols((prev) => prev?.filter((col) => col?.columnName !== item.column));
        onPropertyChange({ slice: updatedSlice });

        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            slice: updatedSlice,
          },
        }));

        updateChartAxis({
          slice: updatedSlice,
        });

        setFlag(false);
      } else {
        // Show an alert if item is already present in slice
        showAlert("This column is already added!", "warning");
      }
    }
  };

  const removeColFieldForPieChart = (column) => {
    // Remove the specified column from the slice array
    const updatedSlice = component?.properties?.slice?.filter((col) => col !== column);

    // Update the component state and properties
    setSelectedCols([...selectedCols, column]);
    onPropertyChange({ slice: updatedSlice });

    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        slice: updatedSlice,
      },
    }));

    // Update the pie chart using the updated slice
    updateChartAxis({
      //xAxis: updatedSlice[0], // Use the first element in slice for xAxis
      slice: updatedSlice,
    });

    setFlag(true);
  };
  const removeSelectedColumn = (column) => {
    setSelectedCols((prev) => prev?.filter((col) => col !== column));
  };
  const removeRowField = (column) => {
    const updatedRowFields = component?.properties?.rowFields?.filter(row => row?.columnId !== column?.columnId);
    setRowFields((prev) => prev?.filter((col) => col?.columnId !== column?.columnId));
    setSelectedCols([...selectedCols, column])
    // onPropertyChange({ rowFields: updatedRowFields, });
    // setSelectedComponent((prev) => ({
    //   ...prev,
    //   properties: {
    //     ...prev.properties,
    //     rowFields: updatedRowFields,
    //   },
    // }));
    setFlag(false);
    updateChartAxis({
      yAxis: updatedRowFields[0]?.columnName,
      xAxis: component?.properties?.colFields[0]?.columnName,
      zAxis: component?.properties?.zAxis,
      legends: component?.properties?.legends,
      rowFields: updatedRowFields,
      colFields: component?.properties?.colFields
    })
  };
  const removeColField = (column) => {
    // if (component?.properties?.colFields?.length <= 1) {
    //   showAlert("The Column Field must contain at least one column.", "info")
    //   return;
    // }
    const updatedColFields = component?.properties?.colFields?.filter(col => col?.columnId !== column?.columnId);
    let newLineValue = component?.properties?.line;

    if (component?.properties?.line === column) {
      // If the line matches the removed column, set it to "none"
      newLineValue = "none";
    } else if (updatedColFields.length > 0 && updatedColFields[0] === newLineValue) {
      // If the first column matches the current line after removal, set it to "none"
      newLineValue = "none";
    }
    setSelectedCols([...selectedCols, column])
    setColFields((prev) => prev?.filter((col) => col?.columnId !== column?.columnId));
    // onPropertyChange({ colFields: updatedColFields, line: newLineValue });
    // setSelectedComponent((prev) => ({
    //   ...prev,
    //   properties: {
    //     ...prev.properties,
    //     colFields: updatedColFields,
    //     line: newLineValue
    //   },
    // }));
    setFlag(false);
    updateChartAxis({
      yAxis: component?.properties?.rowFields[0]?.columnName,
      xAxis: updatedColFields[0]?.columnName,
      zAxis: component?.properties?.zAxis,
      legends: component?.properties?.legends,
      rowFields: component?.properties?.rowFields,
      colFields: updatedColFields,
      line: newLineValue === "none" ? null : newLineValue,
    })
  };
  const removeLegends = (column) => {
    const updatedLegends = component?.properties?.legends?.filter(row => row?.columnId !== column?.columnId);


    onPropertyChange({ legends: updatedLegends, });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        legends: updatedLegends,
      },
    }));
    setFlag(false);
    updateChartAxis({
      yAxis: component?.properties?.rowFields[0]?.columnName,
      xAxis: component?.properties?.colFields[0]?.columnName,
      zAxis: component?.properties?.zAxis,
      legends: updatedLegends,
      rowFields: component?.properties?.rowFields,
      colFields: component?.properties?.colFields
    })
  };

  const handleColumnSelect = (item) => {
    const isExist = component?.properties?.zAxis?.some((col) => col?.column_id === item?.column_id);
    if (!isExist) {
      // setSelectedCols(selectedColumn?.filter((col) => col !== item));
      const newZAxis = [...(component.properties.zAxis || []), item]
      updateChartAxis({
        yAxis: component?.properties?.rowFields[0]?.columnName,
        xAxis: component?.properties?.colFields[0]?.columnName,
        zAxis: newZAxis,
        line: null,
        rowFields: component?.properties?.rowFields,
        colFields: component?.properties?.colFields,
        isDrill: true
      });
    } else {
      // setSelectedCols([...selectedColumn, item]);
      const newZAxis = component?.properties?.zAxis?.filter((i) => i?.column_id !== item?.column_id)
      updateChartAxis({
        yAxis: component?.properties?.rowFields[0]?.columnName,
        xAxis: component?.properties?.colFields[0]?.columnName,
        zAxis: newZAxis,
        line: null,
        rowFields: component?.properties?.rowFields,
        colFields: component?.properties?.colFields,
        drillData: component?.properties?.drillData
      });
    }
  };
  React.useEffect(() => {
    if (flag) {
      // setDeselectedColumns([]);
      setSelectedColumn(columnsMe);
      const columns = columnsMe?.filter((item) => !component?.properties?.rowFields?.includes(item) && !component?.properties?.colFields?.includes(item));
      const colForPie = columnsMe?.filter((item) => !component?.properties?.slice?.includes(item));
      component?.properties?.chartType === 'pie' ? setSelectedCols(colForPie) : setSelectedCols(columns);
      setRowFields([]);
      setColFields([]);
    }
  }, [component]);
  React.useEffect(() => {
    if (searchQuery === '') {
      setFilteredItems(columnToFilter?.colData || []);
    } else {
      setFilteredItems(
        columnToFilter?.colData?.filter((item) => {
          return item.toString().toLowerCase().includes(searchQuery.toLowerCase());
        }) || []
      );
    }
  }, [searchQuery, columnToFilter]);

  return (
    <>
      <div>
        <span className="flex items-center gap-2 px-3 pt-3 pb-2">
          <p className="font-semibold text-[12px]  text-gray-500 m-0">Title:</p>
          <input
            type="text"
            className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-full"
            value={component?.properties?.name || ""}
            onChange={(e) => {
              const newValue = e.target.value;
              onPropertyChange({ name: newValue });
              setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                  ...prev.properties,
                  name: newValue,
                },
              }));
              setFlag(false);
              setIsAnimated(false);
            }}
          />
        </span>
        {/* <span className="d-flex items-center justify-center">
          <button
            onClick={(e) => {
              setConfirmModalOpendata(true)
            }}
            className={`px-3 ml-2 py-1  font-bold text-sm btn-bgcolor text-white rounded-md `}
          >
            Change Data Source
          </button>
        </span> */}
        {/* <span className="d-flex py-2 px-3 pb-3 gap-1 flex-col">
          <p className="font-semibold text-sm text-gray-500 m-0">
            Types of Charts:
          </p>
          <span className="d-flex flex-col items-center gap-2 mt-2">
            <span className="flex justify-center border border-gray-300 lg:w-full sm:w-72 px-2 py-1 bg-white rounded-md shadow-sm">
              <span className="flex flex-row items-center justify-center lg:gap-3 sm:gap-1">
                {Charts?.map((item) => {
                  return (
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleChartTypeSelect(item)}
                      className={`hover:bg-slate-200 d-flex items-center justify-center ${item?.label === component?.properties?.chartType
                        ? "bg-slate-300"
                        : "bg-slate-50"
                        }`}
                    >
                      {item?.icon}
                    </div>
                  );
                })}
              </span>
              
            </span>
          </span>
        </span> */}
        <span className="pivottab">
          <span>
            <div className="flex flex-col items-start justify-start w-full gap-2 pt-1 pb-2 px-3">
              {component?.properties?.chartType !== "pie" && (
                <>
                  <span className="w-full">
                    <DropZone accept="COLUMN"
                      onDrop={handleDropToCol}
                      // title={component?.properties?.chartType === 'pie' ? 'Slice:' : 'Column Field:'}
                      title="X-axis"
                    >
                      {component?.properties?.colFields?.map((item, index) => (
                        <Column key={item} item={item} index={index} removeColumn={() => removeColField(item)} showCloseIcon={true} />
                      ))}
                    </DropZone>
                  </span>
                  <span className="w-full">
                    <DropZone accept="COLUMN" onDrop={handleDropToRow}
                      title="Y-axis"
                    >
                      {component?.properties?.rowFields?.map((item, index) => (
                        <Column
                          key={item}
                          item={item}
                          index={index}
                          removeColumn={() => removeRowField(item)}
                          showCloseIcon={true}
                        />
                      ))}
                    </DropZone>
                  </span>
                  <span className="w-full">
                    <DropZone accept="COLUMN"
                      onDrop={handleDropToLegend}
                      // title={component?.properties?.chartType === 'pie' ? 'Slice:' : 'Column Field:'}
                      title="Legends"
                    >
                      {component?.properties?.legends?.map((item, index) => (
                        <Column key={item} item={item} index={index} removeColumn={() => removeLegends(item)} showCloseIcon={true} />
                      ))}
                    </DropZone>
                  </span>

                </>
              )}
              {component?.properties?.chartType === "pie" && (
                <span className="w-full">
                  <DropZone accept="COLUMN" onDrop={handleDropToColForPieChart}
                    title="Slice:"
                  >
                    {/* {component?.properties?.slice?.map((item, index) => (
                      <Column key={item} item={item} index={index} removeColumn={() => removeColFieldForPieChart(item)} showCloseIcon={true} />
                    ))} */}
                    {component?.properties?.slice?.map((item, index) => {
                      return (
                        <Column
                          key={item}
                          item={item}
                          index={index}
                          removeColumn={() => removeColFieldForPieChart(item)}
                          showCloseIcon={true}
                        />
                      );
                    })}
                  </DropZone>
                </span>
              )}
            </div>
            <div className="flex flex-col ">
              {component?.properties?.chartType === "barWithLine" && (
                <span className=" d-flex pb-2 px-3 gap-2 ml-[8px]">
                  <p className="m-0  whitespace-nowrap font-semibold text-[12px]  text-gray-500  self-center">
                    Select Line:
                  </p>
                  <select
                    // value={component?.properties?.line?.columnName || 'none'}
                    value={component?.properties?.line?.column_id || 'none'}
                    onChange={handleLineChange}
                    className={`block px-0.5 py-0.5 sidebar-section-text bg-white  cursor-pointer input-border-custome rounded-[5px] w-[110px]
                    ${component?.properties?.rowFields?.length <= 0 || component?.properties?.colFields?.length <= 0 ? "opacity-50 pointer-events-none" : "opacity-100 cursor-pointer"
                      }`}
                    disabled={component?.properties?.rowFields?.length <= 0 || component?.properties?.colFields?.length <= 0}
                  >
                    <option value="none">none</option>
                    {displayedOptions?.map((item, index) => (
                      <option key={index} value={item?.column_id}>
                        {item?.column_name}
                      </option>
                    ))}
                  </select>
                </span>
              )}
              <span className=" d-flex pb-2 px-3 gap-2">
                <p className="m-0  whitespace-nowrap font-semibold text-[12px]  text-gray-500  self-center">
                  Aggregation:
                </p>
                <select
                  value={component?.properties?.aggType || "Count"}
                  onChange={(e) => {
                    (component?.properties?.rowFields?.length > 0 || component?.properties?.colFields?.length > 0) && updateChartAxis({
                      yAxis: component?.properties?.yAxis,
                      xAxis: component?.properties?.xAxis,
                      zAxis: component?.properties?.zAxis,
                      legends: component?.propertis?.legends,
                      rowFields: component?.properties?.rowFields,
                      colFields: component?.properties?.colFields,
                      line: component?.properties?.line,
                      aggType: e.target.value,
                      slice: component.properties.slice
                    })
                    setFlag(false);
                  }}
                  disabled={component?.properties?.rowFields?.length <= 0 && component?.properties?.colFields?.length <= 0}
                  className={`${component?.properties?.rowFields?.length <= 0 && component?.properties?.colFields?.length <= 0 ? "cursor-not-allowed" : "cursor-pointer "} block disabled:opacity-75 px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-fit`}
                >
                  {["Count", "Sum", "Average", "Maximum", "Minimum"]?.map(
                    (item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </select>
              </span>
              {component?.properties?.chartType === "double" && (
                <span className=" d-flex pb-2 px-3 gap-2 ml-[20px]">
                  <p className="m-0  whitespace-nowrap font-semibold text-[12px]  text-gray-500  self-center">
                    Columns:
                  </p>
                  <div
                    className="relative flex bg-white input-border-custome h-[22px] flex-row items-center gap-1 divide-x-2 rounded-[5px] lg:w-[110px]"
                    ref={DropDownRef}
                  >
                    <span className="flex flex-row border-0 items-center h-full overflow-scroll flex-nowrap no-scrollbar border-gray-400 ">
                      <div className="m-0 d-flex items-center text-xs pr-1 pl-[7px] text-gray-800 whitespace-nowrap">
                        Select column
                      </div>
                    </span>
                    <button
                      className="border-0 dropdown"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <KeyboardArrowDownIcon className="text-gray-800" />
                    </button>
                    {isDropdownOpen && (
                      <div
                        className="absolute flex flex-col overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-7 z-10 -right-[2px]"
                        style={{
                          boxShadow:
                            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        {selectedCols?.map((item, index) => (
                          <div
                            key={index}
                            className="hover:bg-primarylightgrey border-b d-flex px-2 py-2 items-center gap-2"
                          >
                            <label className="flex flex-row items-center gap-2">
                              <input
                                type="checkbox"
                                className="w-4 h-4 checked:lightblue cursor-pointer"
                                checked={component?.properties?.zAxis?.some(i => i?.column_id === item?.column_id)}
                                onChange={() => handleColumnSelect(item)}
                              />
                              <p className="m-0 cursor-pointer text-xs font-base">
                                {item?.column_name}
                              </p>
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </span>
              )}
            </div>
          </span>
        </span>
        {/* <SidebarSection
          title="Filters"
          subSection={false}
          isOpen={openSection === "filters"}
          onToggle={() => handleToggle("filters")}
        >
          <span className="d-flex mt-3  space-x-4 lg:justify-center sm:justify-start lg:mr-[67px] lg:ml-0 sm:ml-2">
            <p className="m-0  whitespace-nowrap prop-label self-center">
              Select Column:
            </p>
            <select
              value={columnToFilter?.name || -1}
              onChange={(e) => {
                handleSelectColumnToFilter(e?.target?.value);
              }}
              className="block  p-1 text-xs w-[150px] text-gray-900 bg-white dashboard-border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
            >
              <option value={-1}>Select Column</option>
              {component?.properties?.columns?.map((item, index) => (
                <option key={index} value={item} >
                  {item}
                </option>
              ))}
            </select>
          </span>
          {columnToFilter?.name !== null && columnToFilter?.name !== undefined && columnToFilter?.name !== "" &&
            <span className="d-flex gap-3 justify-center  lg:w-full sm:w-64 lg:ml-1 sm:ml-[15px]  pt-3">
              <p className="m-0 whitespace-nowrap  prop-label self-center">
                Column Filter:
              </p>
              <span className="flex relative flex-col mx-0 h-auto" ref={dropdownRef}>
                <span className="flex items-center relative" onClick={handleToggleDropdown}
                >
                  <input
                    type="text"
                    // value={columnToFilter?.name}
                    value={filterObj?.values?.filter(item => (typeof item == 'object' || typeof item == 'boolean' ? JSON.stringify(item) : item)).join(', ') || ''}
                    className="w-full pl-2 pr-10  text-xs text-[#374151] h-7 rounded dashboard-border-custome cursor-pointer"
                    readOnly
                    placeholder="Select Values "
                  />
                  <KeyboardArrowDownIcon
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    style={{ color: 'black' }}
                  />
                </span>
                {isDropdownOpen && (
                  <div
                    className="absolute flex flex-col w-full overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-8 z-10"
                    style={{
                      boxShadow:
                        "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <div className="hover:bg-primarylightgrey d-flex px-2 py-2 items-center gap-2">
                      <span className="relative w-full prop-label">
                        <input
                          type="text"
                          placeholder="Search"
                          className="w-full pl-2 pr-10 py-1 rounded dashboard-border-custome text-[#374151]"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <SearchIcon
                          className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                          style={{ color: '#374151' }}
                        />
                      </span>
                    </div>
                    <div className="hover:bg-primarylightgrey d-flex px-2 py-2 items-center gap-2">
                      <label className="flex flex-row items-center gap-2 cursor-pointer">
                        <input
                          type="checkbox"
                          className="w-4 h-4"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                        <p className="m-0 cursor-pointer text-sm font-base">Select All</p>
                      </label>
                    </div>
                    {console.log(filteredItems, "filterirtemmmmm")}
                    {filteredItems?.map((item, index) => (
                      <div
                        className={classNames(
                          "hover:bg-primarylightgrey",
                          columnToFilter?.colData?.length - 1 &&
                          "border-b",
                          "d-flex px-2 py-2 items-center gap-2"
                        )}
                      >
                        <label className="flex flex-row items-center gap-2 cursor-pointer">
                          <input
                            type="checkbox"
                            className="w-4 h-4 checked:lightblue"
                            checked={
                              filterObj?.values.includes(item) ? true : false
                            }
                            // onChange={() => {
                            //   if (filterObj?.values?.includes(item)) {
                            //     setFilterObj({ ..?.filterObj, values: filterObj?.values?.filter((val) => val !== item) })
                            //   } else {
                            //     setFilterObj({ ..?.filterObj, values: [..?.filterObj.values, item] })
                            //   }
                            // }}
                            onChange={() => handleFilterCheckboxChange(typeof item == 'object' ? JSON.stringify(item) : item)}
                          />
                          <p
                            className={classNames(
                              "m-0 cursor-pointer text-sm font-base"
                            )}
                          >
                            {typeof item == 'object' ? JSON.stringify(item) : typeof item === 'boolean' ? JSON.stringify(item) : item}
                          </p>
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </span>
            </span>
          }
          {columnToFilter?.type === "DATE" &&
            <span className="d-flex lg:justify-center sm:justify-start my-3 gap-3 lg:ml-[37px] sm:ml-[27px]">
              <p className="m-0 whitespace-nowrap prop-label self-center ">
                Date Range:
              </p>
              <div className='d-flex flex-row justify-center filter-date '>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DateRangePicker']}>
                    <DateRangePicker
                      localeText={{ start: '', end: '' }}
                      calendars={2}
                      value={dateRange}
                      onChange={(newValue) => {
                        setDateRange(newValue);
                        setFilterObj({ ..?.filterObj, from: new Date(newValue[0]), to: new Date(newValue[1]) })
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </span>}
          {columnToFilter?.type === "VARCHAR" &&
            <span className="d-flex lg:justify-center sm:justify-start py-2  pt-3 space-x-4 lg:ml-[57px] sm:ml-[38px]">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Condition:
              </p>
              <span className="flex gap-2">
                <select
                  value={filterObj?.condition}
                  onChange={(e) => {
                    setFilterObj({ ..?.filterObj, condition: e?.target?.value });
                  }}
                  className="block lg:w-fit sm:w-[90px] p-1 text-xs text-gray-900 bg-white dashboard-border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={-1}>Select Condition</option>
                  {stringTypeConditions?.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
                <input
                  className="block p-1 text-xs text-gray-900 bg-white dashboard-border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                  value={filterObj?.conditionValue || ""}
                  onChange={(e) => {
                    setFilterObj({ ..?.filterObj, conditionValue: e?.target?.value })
                  }}
                />
              </span>
            </span>
          }
          {columnToFilter?.type === "INTEGER" && <>
            <span className="d-flex lg:justify-center sm:justify-start  py-2 space-x-4 mt-2 lg:mr-[68px] lg:ml-0 sm:ml-[56px] ">
              <p className="m-0 prop-label whitespace-nowrap  self-center">
                Range:
              </p>
              <span className="d-flex w-[100px]">
                <p className="my-0 ml-1 mr-0 prop-label whitespace-nowrap self-center">
                  {columnToFilter?.min}
                </p>
                <Tooltip title={rangeValue} placement="top">
                  <input type="range"
                    min={columnToFilter?.min}
                    max={
                      columnToFilter?.min === columnToFilter?.max
                        ? columnToFilter?.max + 1 // Slightly increase the max if min and max are the same
                        : columnToFilter?.max
                    }
                    className="custom-range mx-2 self-center"
                    style={{
                      background: `linear-gradient(to right,#0a3a67 ${calculateFillPercentage()}, #ddd ${calculateFillPercentage()})`,
                      opacity: columnToFilter?.min === columnToFilter?.max ? 0.5 : 1,
                      pointerEvents: columnToFilter?.min === columnToFilter?.max ? 'none' : 'auto',
                    }}
                    value={columnToFilter?.min === columnToFilter?.max ? rangeValue + 1 : rangeValue}
                    onChange={handleRangeChange}
                    disabled={columnToFilter?.min === columnToFilter?.max}
                  />
                </Tooltip>
                <p className="m-0 prop-label whitespace-nowrap self-center">
                  {" "}{columnToFilter?.max}
                </p>
              </span>
            </span>
            <span className="d-flex lg:justify-center sm:justify-start py-2 space-x-4 lg:ml-[57px] sm:ml-[38px]">
              <p className="m-0  whitespace-nowrap prop-label self-center">
                Condition:
              </p>
              <span className="flex gap-2">
                <select
                  value={component?.properties?.conditionType || "Equal To"}
                  onChange={(e) => {
                    setFilterObj({ ..?.filterObj, condition: e?.target?.value });
                    onPropertyChange({ conditionType: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        conditionType: e.target.value,
                      },
                    }));
                    setFlag(false);
                  }}
                  className="block  p-1 lg:w-fit sm:w-[90px] text-xs text-gray-900 bg-white dashboard-border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={-1}>Select Condition</option>
                  {numericTypeConditions?.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  //value={component?.properties?.columnvalue || ""}
                  value={filterObj?.conditionValue || ""}
                  onChange={(e) => {
                    setFilterObj({ ..?.filterObj, conditionValue: e?.target?.value })
                    onPropertyChange({ columnvalue: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        columnvalue: e.target.value,
                      },
                    }));
                    setFlag(false);
                  }}
                  className="block p-1 text-xs text-gray-900 bg-white dashboard-border-custome  rounded lg:w-[90px] sm:w-[60px] focus:ring-blue-500 focus:border-blue-500"
                />
              </span>
            </span>

          </>
          }
          <span className="d-flex items-center w-full justify-end pr-2 py-2">
            <button
              onClick={clearFilter}
              className={`px-3 ml-2 py-1 font-bold text-sm btn-bgcolor text-white rounded-md  disabled:opacity-60 disabled:cursor-not-allowed  }`}
              disabled={filterObj === null}
            >
              Clear
            </button>
            <button
              onClick={async () => {
                try {
                  const result = await filterData({ ..?.filterObj, tableName: component?.properties?.tableName, columnType: filterObj?.columnType === 'BOOLEAN' ? 'VARCHAR' : filterObj?.columnType }).unwrap();
                  onPropertyChange({
                    data: result?.data,
                  });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      data: result?.data,
                    },
                  }));
                  setFlag(false);

                } catch (err) {
                  showAlert(err?.data?.message, "error")
                }
              }}
              disabled={filterObj === null}
              className={`px-3 ml-2 py-1 font-bold text-sm btn-bgcolor text-white rounded-md   disabled:opacity-60 disabled:cursor-not-allowed }`}
            >
              Apply
            </button>
          </span>

        </SidebarSection> */}
        <SidebarSection
          title="Position"
          subSection={false}
          isOpen={openSection === "position"}
          onToggle={() => handleToggle("position")}
        >
          <span className="d-flex px-2 py-2 justify-center">
            <span className="d-flex flex-col">
              <span className="d-flex py-1">

                <span className="mx-2">
                  <p className="m-0 sidebar-section-label whitespace-nowrap">
                    Left
                  </p>
                  <input
                    type="number"
                    className=" block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]  "
                    value={Math?.round(component?.properties?.left)}
                    onChange={(e) => handlePositionChange(e, "left")}
                  />
                </span>
                <span className="mx-2">
                  <p className="m-0 sidebar-section-label whitespace-nowrap">
                    Top
                  </p>
                  <input
                    type="number"
                    className=" block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]  "
                    value={Math?.round(component?.properties?.top)}
                    onChange={(e) => handlePositionChange(e, "top")}
                  />
                </span>
              </span>
              <span className="d-flex py-2 self-end">
                <span className="mx-2">
                  <p className="m-0 sidebar-section-label whitespace-nowrap">
                    Right
                  </p>
                  <input
                    type="number"
                    className=" block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]  "
                    value={Math?.round(component?.properties?.right)}
                    onChange={(e) => handlePositionChange(e, "right")}
                  />
                </span>
                <span className="mx-2">
                  <p className="m-0 sidebar-section-label whitespace-nowrap">
                    Bottom
                  </p>
                  <input
                    type="number"
                    className=" block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]  "
                    value={Math?.round(component?.properties?.bottom)}
                    onChange={(e) => handlePositionChange(e, "bottom")}
                  />
                </span>
              </span>
            </span>
          </span>
        </SidebarSection>
        <SidebarSection
          title="Appearance"
          subSection={false}
          isOpen={openSection === "appearance"}
          onToggle={() => handleToggle("appearance")}
        >
          <span className="justify-center py-1 pt-2 space-x-1 flex flex-row ">
            <span className="flex flex-col ">
              <p className="m-0 sidebar-section-label whitespace-nowrap text-gray-700">
                Height
              </p>
              <input
                type="number"
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                value={component?.properties?.height || 0}
                onChange={(e) => {
                  const newValue = e.target.value;
                  onPropertyChange({ height: Number(newValue) });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      height: Number(newValue),
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
              />
            </span>
            <span className="flex flex-col space-x-2">
              <p className="m-0 sidebar-section-label whitespace-nowrap  pl-2">
                Width
              </p>
              <input
                type="number"
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                value={component?.properties?.width || 0}
                onChange={(e) => {
                  const newValue = e.target.value;
                  onPropertyChange({ width: Number(newValue) });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      width: Number(newValue),
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
              />
            </span>
          </span>


          <span className="flex flex-col py-1 ml-[27px]">
            <p className="m-0 sidebar-section-label whitespace-nowrap self-start">
              Border:
            </p>
            <span className="flex gap-2">
              <span>
                <select
                  value={component?.properties?.borderType || "solid"}
                  onChange={(e) => {
                    onPropertyChange({ borderType: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        borderType: e.target.value,
                      },
                    }));
                    setFlag(false);
                    setIsAnimated(false);
                  }}
                  className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-[120px]"
                >

                  {["Solid", "Dotted", "Dashed", "Double", "None"]?.map(
                    (item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </select>
              </span>
              <span
                className="relative flex "
                ref={borderColorRef}
              >

                <span className="flex items-center border-custome ">
                  <span
                    className="w-5 h-5 sketch-border"
                    style={{
                      backgroundColor:
                        component?.properties?.borderColor || "#000000",
                    }}
                  />
                  <span
                    className="w-5 h-4 flex items-center cursor-pointer"
                    onClick={() => {
                      setColorPickerOpen(!colorPickerOpen);
                      setColorPickerBg(false);
                      setColorPickerText(false);
                    }}
                  >
                    <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                  </span>
                </span>
                {colorPickerOpen && (
                  <div className="absolute z-10 right-0 bottom-0 mb-[26px] cursor-pointer">
                    <SketchPicker
                      color={
                        component?.properties?.borderColor || "rgba(0,0,0,1)"
                      }
                      onChange={(color) => {
                        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                        onPropertyChange({ borderColor: rgbaColor });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            borderColor: rgbaColor,
                          },
                        }));
                        setFlag(false);
                        setIsAnimated(false);
                      }}
                      className="color-picker"
                    />
                  </div>
                )}
              </span>
            </span>
          </span>

          <span className="flex gap-3">
            <span className="d-flex flex-col py-1 ml-[27px]">
              <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                Border Width:
              </p>
              <input
                type="number"
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                value={component?.properties?.borderWidth || 0}
                onChange={(e) => {
                  let newValue = e.target.value;
                  if (newValue !== "") {
                    newValue = newValue.replace(/^0+/, "") || "0";
                  }
                  if (parseInt(newValue) < 0) {
                    newValue = "0";
                  }
                  onPropertyChange({ borderWidth: newValue });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      borderWidth: newValue,
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
              />
            </span>
            <span className="d-flex py-1 flex-col ">
              <p className="m-0  whitespace-nowrap sidebar-section-label self-start">Border Radius:</p>
              <input
                type="number"
                value={component?.properties?.borderRadius || "0"}
                onChange={(e) => {
                  let newValue = e.target.value;
                  if (newValue !== '') {
                    newValue = newValue.replace(/^0+/, '') || '0';
                  }
                  onPropertyChange({ borderRadius: newValue });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      borderRadius: newValue
                    }
                  }));
                  setFlag(false);
                }}
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                min="0"
                max="100"
              />
            </span>
          </span>

          <div className="d-flex py-1 ml-[27px] flex-col pb-2 ">
            <p className="m-0  whitespace-nowrap  self-start sidebar-section-label">
              Background Color:
            </p>
            <span className="relative flex" ref={backgroundColorRef}>
              <span className="flex items-center border-custome ">
                <span
                  className="w-5 h-5 sketch-border"
                  style={{
                    backgroundColor:
                      component?.properties?.option?.backgroundColor || "transparent",
                  }}
                />
                <span
                  className="w-5 h-4 flex items-center cursor-pointer"
                  onClick={() => {
                    setColorPickerBg(!colorPickerBg);
                    setColorPickerOpen(false);
                    setColorPickerText(false);
                  }}
                >
                  <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                </span>
              </span>
              {colorPickerBg && (
                <div className="absolute z-10 bottom-0 mb-[32px] cursor-pointer">
                  <SketchPicker
                    color={
                      component?.properties?.option?.backgroundColor ||
                      "transparent"
                    }
                    onChange={(color) => {
                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                      const option = {
                        ...component?.properties?.option,
                        backgroundColor: rgbaColor,
                      };
                      onPropertyChange({ option: option });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          option: option,
                        },
                      }));
                      setFlag(false);
                      setIsAnimated(false);
                    }}
                    className="color-picker"
                  />
                </div>
              )}
            </span>
          </div>
        </SidebarSection>
        <SidebarSection
          title={component?.properties?.chartType === "pie" ? "Slice" : "Axis"}
          subSection={false}
          isOpen={openSection === "axis"}
          onToggle={() => handleToggle("axis")}
        >
          {/* {component?.properties?.chartType !== "pie" && (
            <span>
              <span className="d-flex justify-center py-2 space-x-3 mr-[15px]">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Min:
                </p>
                <input
                  type="number"
                  className="block p-1  text-xs text-gray-900 bg-white dashboard-border-custome  rounded w-[80px]  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.option?.yAxis?.min}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    const option = {
                      ...component?.properties?.option,
                      yAxis: {
                        ...component?.properties?.option?.yAxis,
                        min: Number(newValue),
                      },
                    };
                    onPropertyChange({ option: option });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        option: option,
                      },
                    }));
                    setFlag(false);
                    setIsAnimated(false);
                  }}
                />
              </span>
            </span>
          )} */}

          <span className="flex pt-2 gap-3">
            <span className=" py-1 flex flex-col ml-[27px]">
              <p className="m-0  whitespace-nowrap sidebar-section-label self-start ">
                Interval:
              </p>
              <input
                type="number"
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                value={component?.properties?.option?.yAxis?.interval}
                onChange={(e) => {
                  let newValue = e.target.value;
                  if (newValue !== "") {
                    newValue = newValue.replace(/^0+/, "") || "0";
                  }
                  if (parseInt(newValue) < 0) {
                    newValue = "0";
                  }
                  const option = {
                    ...component?.properties?.option,
                    yAxis: {
                      ...component?.properties?.option?.yAxis,
                      interval: Number(newValue),
                    },
                  };
                  onPropertyChange({ option: option });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      option: option,
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
              />
            </span>
            <span className=" py-1 flex flex-col">
              <p className="m-0  whitespace-nowrap sidebar-section-label self-start ">
                Labels Size:
              </p>
              <input
                type="number"
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                value={
                  component?.properties?.option?.xAxis?.axisLabel?.fontSize || 0
                }
                onChange={(e) => {
                  let newValue = e.target.value;
                  if (newValue !== "") {
                    newValue = newValue.replace(/^0+/, "") || "0";
                  }
                  if (parseInt(newValue) < 0) {
                    newValue = "0";
                  }
                  const option = {
                    ...component?.properties?.option,
                    xAxis: {
                      ...component?.properties?.option?.xAxis,
                      axisLabel: {
                        ...component?.properties?.option?.xAxis?.axisLabel,
                        fontSize: newValue,
                      },
                    },
                    yAxis: {
                      ...component?.properties?.option?.yAxis,
                      axisLabel: {
                        ...component?.properties?.option?.yAxis?.axisLabel,
                        fontSize: newValue,
                      },
                    },
                  };
                  onPropertyChange({ option: option });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      option: option,
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
              />
            </span>
          </span>
          <span className="flex flex-col py-1 ml-[27px]">
            <p className="m-0 sidebar-section-label whitespace-nowrap self-start">
              Labels:
            </p>
            <span className="flex gap-2">
              <span>
                <select
                  value={
                    component?.properties?.option?.xAxis?.axisLabel?.fontFamily
                  }
                  onChange={(e) => {
                    const option = {
                      ...component?.properties?.option,
                      xAxis: {
                        ...component?.properties?.option?.xAxis,
                        axisLabel: {
                          ...component?.properties?.option?.xAxis?.axisLabel,
                          fontFamily: e.target.value,
                        },
                      },
                      yAxis: {
                        ...component?.properties?.option?.yAxis,
                        axisLabel: {
                          ...component?.properties?.option?.yAxis?.axisLabel,
                          fontFamily: e.target.value,
                        },
                      },
                    };
                    onPropertyChange({ option: option });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        option: option,
                      },
                    }));
                    setFlag(false);
                    setIsAnimated(false);
                  }}
                  className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-[120px]"
                >
                  <option value={"Inter"}>Inter</option>
                  {Fonts?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </span>
              <span
                className="relative flex "
                ref={textColorRef}
              >

                <span className="flex items-center border-custome ">
                  <span
                    className="w-5 h-5 sketch-border"
                    style={{
                      backgroundColor:
                        component?.properties?.option?.xAxis?.axisLabel?.color ||
                        "#fff",
                    }}
                  />
                  <span
                    className="w-5 h-4 flex items-center cursor-pointer"
                    onClick={() => {
                      setColorPickerText(!colorPickerText);
                      setColorPickerBg(false);
                      setColorPickerOpen(false);
                    }}
                  >
                    <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                  </span>
                </span>
                {colorPickerText && (
                  <div className="absolute z-10 bottom-0 right-0 mb-[26px] cursor-pointer">
                    <SketchPicker
                      color={
                        component?.properties?.option?.xAxis?.axisLabel?.color ||
                        "rgba(0, 0, 0, 1)"
                      }
                      onChange={(color) => {
                        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                        const option = {
                          ...component?.properties?.option,
                          xAxis: {
                            ...component?.properties?.option?.xAxis,
                            axisLabel: {
                              ...component?.properties?.option?.xAxis?.axisLabel,
                              color: rgbaColor,
                            },
                          },
                          yAxis: {
                            ...component?.properties?.option?.yAxis,
                            axisLabel: {
                              ...component?.properties?.option?.yAxis?.axisLabel,
                              color: rgbaColor,
                            },
                          },
                        };
                        onPropertyChange({ option: option });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            option: option,
                          },
                        }));
                        setFlag(false);
                        setIsAnimated(false);
                      }}
                      className="color-picker"
                    />
                  </div>
                )}
              </span>
            </span>

          </span>
          {component?.properties?.chartType !== "pie" && (
            <span className="d-flex flex-col  py-1 ml-[27px] pb-2">
              <p className="block m-0  whitespace-nowrap  self-start sidebar-section-label">
                Labels Rotation(x):
              </p>
              <input
                type="number"
                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                value={component?.properties?.option?.xAxis?.axisLabel?.rotate}
                onChange={(e) => {
                  let newValue = e.target.value;
                  // if (newValue !== '') {
                  //     newValue = newValue.replace(/^0+/, '') || '0';
                  // }
                  // if (parseInt(newValue) < 0) {
                  //     newValue = '0';
                  // }
                  const option = {
                    ...component?.properties?.option,
                    xAxis: {
                      ...component?.properties?.option?.xAxis,
                      axisLabel: {
                        ...component?.properties?.option?.xAxis?.axisLabel,
                        rotate: newValue,
                      },
                    },
                  };
                  onPropertyChange({ option: option });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      option: option,
                    },
                  }));
                  setFlag(false);
                  setIsAnimated(false);
                }}
              />
            </span>
          )}
        </SidebarSection>
        <SidebarSection
          title="Legends"
          subSection={false}
          isOpen={openSection === "legends"}
          onToggle={() => handleToggle("legends")}
        >
          <div
            style={{ maxHeight: "44vh", overflow: "auto" }}
            className="py-2 custom-scrollbar"
          >
            {component?.properties?.chartType === "bar" ||
              component?.properties?.chartType === "barWithLine" ||
              component?.properties?.chartType === "stacked" ||
              component?.properties?.chartType === "double" ||
              component?.properties?.chartType === "line" ||
              component?.properties?.chartType === "area" ||
              component?.properties?.chartType === "scatter"
              ? component?.properties?.option?.series?.map((item) => (
                <div className="d-flex items-center gap-2 px-3 py-1">
                  <div
                    ref={(el) => (colorPickerRefs.current[item.name] = el)}
                    className="relative"
                  >
                    <input
                      type="text"
                      className="block p-1 text-sm  text-gray-900  border border-gray-300  w-full focus:ring-blue-500 focus:border-blue-500"
                      value={""}
                      onClick={() => {
                        handleColorPickerToggle(item?.name);
                      }}
                      readOnly
                      style={{
                        backgroundColor: item?.itemStyle?.color || "#fff",
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                    />
                    {colorPickerState[item.name] && (
                      <div
                        className="absolute z-10 mt-[30px] top-0 "
                        style={{
                          // top: "-180px",
                          // left: "40px",
                          cursor: "pointer",
                        }}
                      >
                        <SketchPicker
                          color={item?.itemStyle?.color || "rgba(0, 0, 0, 1)"}
                          onChange={(color) => {
                            const rgbaColor = `rgba(${color?.rgb?.r}, ${color?.rgb?.g}, ${color?.rgb?.b},${color?.rgb?.a})`;
                            const option = {
                              ...component?.properties?.option,
                              series:
                                component?.properties?.option?.series?.map(
                                  (seriesItem) => {
                                    if (seriesItem?.name === item?.name) {
                                      return {
                                        ...seriesItem, // Copy existing series item
                                        itemStyle: {
                                          ...seriesItem?.itemStyle, // Copy existing itemStyle
                                          color: rgbaColor, // Update color
                                        },
                                      };
                                    }
                                    return seriesItem;
                                  }
                                ),
                            };
                            onPropertyChange({ option: option });
                            setSelectedComponent((prev) => ({
                              ...prev,
                              properties: {
                                ...prev.properties,
                                option: option,
                              },
                            }));
                            setFlag(false);
                            setIsAnimated(false);
                          }}
                          className="color-picker"
                        />
                      </div>
                    )}
                  </div>
                  <div className="m-0 prop-label whitespace-nowrap  self-center">
                    {parseNull(item?.name, "NA")}
                  </div>
                </div>
              ))
              : component?.properties?.option?.series && component?.properties?.option?.series[0]?.data?.map((item) => (
                <div className="d-flex items-center gap-2 px-3 py-1">
                  <div
                    ref={(el) => (colorPickerRefs.current[item.name] = el)}
                  >
                    <input
                      type="text"
                      className="block p-1 text-sm text-gray-900  border border-gray-300  w-full focus:ring-blue-500 focus:border-blue-500"
                      value={""}
                      onClick={() => {
                        handleColorPickerToggle(item.name);
                      }}
                      readOnly
                      style={{
                        backgroundColor: item?.itemStyle?.color || "#fff",
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                    />
                    {colorPickerState[item.name] && (
                      <div
                        className="absolute z-10 mt-1"
                        style={{
                          // top: "-180px",
                          // left: "40px",
                          cursor: "pointer",
                        }}
                      >
                        <SketchPicker
                          color={item?.itemStyle?.color || "rgba(0, 0, 0, 1)"}
                          onChange={(color) => {
                            const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b},${color.rgb.a})`;
                            const updatedSeries =
                              component?.properties?.option?.series[0]?.data?.map(
                                (seriesItem) =>
                                  seriesItem.name === item.name
                                    ? {
                                      ...seriesItem,
                                      itemStyle: {
                                        ...seriesItem.itemStyle,
                                        color: rgbaColor,
                                      },
                                    }
                                    : seriesItem
                              );
                            const updatedOption = {
                              ...component?.properties?.option,
                              series: [
                                {
                                  ...component?.properties?.option?.series[0],
                                  data: updatedSeries,
                                },
                              ],
                            };
                            onPropertyChange({ option: updatedOption });
                            setSelectedComponent((prev) => ({
                              ...prev,
                              properties: {
                                ...prev.properties,
                                option: updatedOption,
                              },
                            }));
                            setFlag(false);
                            setIsAnimated(false);
                          }}
                          className="color-picker"
                        />
                      </div>
                    )}
                  </div>
                  <div className="m-0 prop-label whitespace-nowrap  self-center">
                    {parseNull(item?.name, "NA")}
                  </div>
                </div>
              ))}
          </div>
        </SidebarSection>
      </div>
    </>
  );
}
export default ChartProperties;