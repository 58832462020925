import * as React from "react";

import PropTypes from "prop-types";
import Tooltip from '@mui/material/Tooltip';

const AddDataSource = (props) => {
    const { width, height, tooltipText, fillColors } = props;

    return (
        <Tooltip title={tooltipText}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* Use dynamic colors */}
                <path
                    d="M638.056746 324.70827c0-3.526831-1.588573-6.394255-3.556805-6.394255l-299.540881-0.269758c-1.998205 0-3.566795 2.857433-3.576786 6.394255v51.154042c0 3.526831 1.588573 6.394255 3.556804 6.394255l299.540881 0.269757c1.998205 0 3.566795-2.857433 3.566796-6.394255zM637.866916 538.186473v-51.154041c0-3.526831-1.588573-6.394255-3.566795-6.394255l-300.21028-0.269758c-1.998205 0-3.576786 2.857433-3.586777 6.394255v51.154041c0 3.526831 1.588573 6.394255 3.566795 6.394256l300.21028 0.269757c1.978223 0 3.576786-2.857433 3.586777-6.394255zM331.572103 642.722554c-0.839246 0-1.528627 2.857433-1.528626 6.394255v51.154041c0 3.526831 0.67939 6.394255 1.518635 6.394256l127.885103 0.109901c0.839246 0 1.528627-2.857433 1.528627-6.394255v-51.144051c0-3.536822-0.67939-6.404246-1.518636-6.404246z"
                    fill={fillColors[0] || "#57585d"} // Default color if not provided
                />
                <path
                    d="M220.591812 151.064278l583.475784 0.519533-0.269758 297.732506 71.93537 0.069938 0.269758-301.728916a68.018889 68.018889 0 0 0-67.879015-67.998907l-591.168872-0.539515h-0.059946c-37.596222 0-68.208719 30.852281-68.238692 68.798189l-0.659407 727.516372c0 37.96589 30.55255 68.878117 68.178746 68.938063l249.475861 0.229794 0.069937-71.935371-245.779182-0.219802z"
                    fill={fillColors[1] || "#57585d"} // Default color if not provided
                />
                <path
                    d="M966.42173 702.239082l-175.842016-0.159856 0.159856-175.842017-71.935371-0.059946-0.159856 175.842017-175.842017-0.159857-0.059946 71.935371 175.842017 0.159856-0.159857 175.842017 71.935371 0.059946 0.159856-175.842017 175.842017 0.159857 0.059946-71.935371z"
                    fill={fillColors[2] || "#2479d1"} // Default color if not provided
                />
            </svg>
        </Tooltip>
    );
};

AddDataSource.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    // tooltipText: PropTypes.string.isRequired,
    fillColors: PropTypes.arrayOf(PropTypes.string), // Prop for colors
};

AddDataSource.defaultProps = {
    fillColors: ["#57585d", "#57585d", "#2479d1"], // Default colors
};

export default AddDataSource;

