import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const LinebarChartIconsvg = (props) => (
  <Tooltip title={props.tooltipText}>
    <svg
      width={props.height}
      height={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={props?.fill ? props?.fill : '#1976d2'}
    >
      <rect x="4" y="10" width="3" height="8" fill="currentColor" />
      <rect x="8" y="6" width="3" height="12" fill="currentColor" />
      <rect x="12" y="4" width="3" height="14" fill="currentColor" />

      <path
        d="M3 16L6 12L8 14L10 8L13 10L16 4"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
      />
    </svg>
  </Tooltip>
);

export default LinebarChartIconsvg;
