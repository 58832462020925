import { Checkbox, FormControlLabel, Grid } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CheckboxSelector from "../../../../Schedules/components/CheckboxSelector";
import CloseIcon from "@mui/icons-material/Close";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Icons } from "../../../../../assets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import RedoIcon from "@mui/icons-material/Redo";
import RemoveIcon from "@mui/icons-material/Remove";
import ReusableModal from "../../../../../components/Modals/ReusableModal";
import ReusableReportModal from "../ReusableReportModal";
import { SketchPicker } from "react-color";
import Tabs from "../../../../../components/Tabs";
import Tooltip from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";
import classNames from "classnames";
import moment from "moment";
import { useUpdateTemplatesMutation } from "../../../../../features/report/reportApiSlice";

// const SidebarSection = ({ title, children, subSection }) => {
//   const [isOpen, setIsOpen] = React.useState(false);
//   const toggleOpen = () => {
//     setIsOpen(!isOpen);
//   };
//   return (
//     <div
//       className="sidebar-section"
//       style={{
//         borderTop: "0.5px solid #d2d9e3",
//         borderBottom: "0.5px solid #d2d9e3",
//       }}
//     >
//       <div
//         className={`sidebar-section-header hover:bg-slate-300 ${subSection ? " bg-slate-100" : "bg-slate-200 py-1"
//           } px-2 `}
//         onClick={toggleOpen}
//       >
//         <div className="d-flex justify-between items-center">
//           <span
//             className={`font-semibold ${subSection ? "text-sm text-gray-600" : "text-base text-gray-500"
//               } `}
//           >
//             {title}
//           </span>
//           <button className="toggle-button">
//             {isOpen ? (
//               <RemoveIcon className="text-gray-500" />
//             ) : (
//               <AddIcon className="text-gray-500" />
//             )}
//           </button>
//         </div>
//       </div>
//       {isOpen && <div className="sidebar-section-content">{children}</div>}
//     </div>
//   );
// };

const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
  return (
    <div className="sidebar-section">
      {/* <div
        className={`sidebar-section-header  hover:bg-slate-300 ${subSection ? " bg-slate-100" : "bg-slate-200 py-1"
          } px-2 `}
        onClick={onToggle}
        style={{ backgroundColor: "rgb(16 85 151)" }}
      > */}
      <div
        className={`sidebar-section-header px-2 cursor-pointer  ${subSection ? "" : "py-1"
          }`}
        onClick={onToggle}
        style={{
          backgroundColor: !subSection ? "rgb(16 85 151)" : "#534a4a40",
        }}
      >
        <div className="d-flex justify-between items-center">
          <span
            className={`font-semibold text-sm ${subSection ? "text-sm text-gray-600" : "text-base text-gray-500"
              } `}
            style={{
              color: subSection ? "text-sm text-gray-600" : "white",
              paddingLeft: "8px",
            }}
          >
            {title}
          </span>
          <button className="toggle-button">
            {isOpen ? (
              <Tooltip title="Remove">
                <RemoveIcon
                  className={`${subSection ? "text-sm text-gray-600" : "text-white"
                    }`}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Add">
                <AddIcon
                  className={`${subSection ? "text-sm text-gray-600" : "text-white"
                    }`}
                />
              </Tooltip>
            )}
          </button>
        </div>
      </div>
      {isOpen && <div className="sidebar-section-content">{children}</div>}
    </div>
  );
};
function TableProperties({
  component,
  onPropertyChange,
  setSelectedComponent,
  showAlert,
  flag,
  setFlag,
  reportAreaRef,
  setIsSaveClicked,
  isSaveClicked,
  selectedTemplate,
  setIsCreateNewTemplate,
  reportId,
  isNewReportActive,
  setCurrentReportData,
  getUserTemplates,
  openItem,
  openSection,
  setOpenItem,
  setOpenSection,
}) {
  const [name, setName] = React.useState("");
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [colorPickerBg, setColorPickerBg] = React.useState(false);
  const [colorPickerText, setColorPickerText] = React.useState(false);
  const [borderStyle, setBorderStyle] = React.useState("solid");
  const [isAddColumn, setIsAddColumn] = React.useState(false);
  const [newCol, setNewCol] = React.useState("");
  const [columnListOpen, setColumnListOpen] = React.useState(false);
  const [selectedColumn, setSelectedColumn] = React.useState(
    component?.properties?.columns
  );
  const columnRef = React.useRef(null);
  const [deselectedColumns, setDeselectedColumns] = React.useState([]);
  const [updateTemplates] = useUpdateTemplatesMutation();
  const [open, setOpen] = React.useState(false);
  const backgroundColorRef = React.useRef(null);
  const textColorRef = React.useRef(null);
  const borderColorRef = React.useRef(null);
  const colorPickerRefs = React.useRef({});
  const columnDropdownRef = React.useRef(null);
  const [tempRow, setTempRow] = React.useState(null);
  const [tmpColumnName, setTmpColumnName] = React.useState("");

  const [newColType, setNewColType] = React.useState("VARCHAR");
  const [colorPickerState, setColorPickerState] = React.useState({});
  const [isAddRowOpen, setIsAddRowOpen] = React.useState(false);
  const [IsAddNewColumnOpen, setIsAddNewColumnOpen] = React.useState(false);
  const [computedColAListOpen, setComputedColAListOpen] = React.useState(false);
  const [computedColBListOpen, setComputedColBListOpen] = React.useState(false);
  // const [computedColumns, setComputedColumns] = React.useState({
  //   colA: null,
  //   colB: null,
  // });
  const [operationListOpen, setOperationListOpen] = React.useState(false);
  const [isError, setIserror] = React.useState(false);
  //const [selectedOperation, setSelectedOperation] = React.useState(null);
  const colADropdownRef = React.useRef(null);
  const colBDropdownRef = React.useRef(null);

  const [selectedOperation, setSelectedOperation] = React.useState(null);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [inputExpression, setInputExpression] = React.useState("");
  const [computedColumns, setComputedColumns] = React.useState({});
  const [computedColListOpen, setComputedColListOpen] = React.useState(false);
  const [expressionHistory, setExpressionHistory] = React.useState([]);
  const [redoHistory, setRedoHistory] = React.useState([]);
  const inputRef = React.useRef(null);
  const [newComputedColumn, setNewComputedColumn] = React.useState({
    status: null,
  });
  const [isEditing, setIsEditing] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [originalColumnName, setOriginalColumnName] = React.useState("");
  const [isColumnEdited, setIsColumnEdited] = React.useState(false);
  const [redoExpression, setRedoExpression] = React.useState([]);

  const Fonts = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Times",
    "Courier New",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Trebuchet MS",
    "Comic Sans MS",
    "Impac",
  ];
  const fontWeights = [
    { name: "Thin", value: 100 },
    { name: "Extra Light", value: 200 },
    { name: "Light", value: 300 },
    { name: "Normal", value: 400 },
    { name: "Medium", value: 500 },
    { name: "Semi Bold", value: 600 },
    { name: "Bold", value: 700 },
    { name: "Extra Bold", value: 800 },
  ];
  const operations = [
    { value: 1, name: "OpenBracket", icon: "(" },
    { value: 2, name: "CloseBracket", icon: ")" },
    { value: 3, name: "Addition", icon: "+" },
    { value: 4, name: "Subtraction", icon: "-" },
    { value: 5, name: "Multiplication", icon: "*" },
    { value: 6, name: "Division", icon: "/" },
  ];
  const handleColorChange = (color) => {
    // Handle color change logic here
    // Example: onPropertyChange({ color: color.hex });
    // Example: setSelectedComponent(prev => ({ ...prev, properties: { ...prev.properties, color: color.hex } }));
  };
  const handleColumnNameFilter = (column, newData, newColumnProperties) => {
    if (selectedColumn.includes(column)) {
      setDeselectedColumns((prev) => [...prev, column]);
      const newColumns = selectedColumn?.filter((filter) => filter !== column);
      if (newData && newData?.length > 0) {
        onPropertyChange({
          columns: newColumns,
          data: newData,
          columnProperties: newColumnProperties,
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            columns: newColumns,
            data: newData,
            columnProperties: newColumnProperties,
          },
        }));
        setCurrentReportData({
          data: newData,
          columns: newColumns,
          isNewReport: false,
          templateId: component?.properties?.templateId,
          isColumnChanged: true,
        });
      } else {
        onPropertyChange({ columns: newColumns });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            columns: newColumns,
          },
        }));
      }
      setSelectedColumn(selectedColumn?.filter((filter) => filter !== column));
      setCurrentReportData({
        columns: newColumns,
        data: component?.properties?.data,
        isNewReport: false,
        templateId: component?.properties?.templateId,
        isColumnChanged: true,
      });
      setFlag(false);
    } else {
      setDeselectedColumns((prev) => prev?.filter((item) => item !== column));
      if (newData && newData?.length > 0) {
        onPropertyChange({
          columns: [...selectedColumn, column],
          data: newData,
          columnProperties: newColumnProperties,
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            columns: [...selectedColumn, column],
            data: newData,
            columnProperties: newColumnProperties,
          },
        }));
        setCurrentReportData({
          columns: [...selectedColumn, column],
          data: component?.properties?.data,
          isNewReport: false,
          templateId: component?.properties?.templateId,
          isColumnChanged: true,
        });
      } else {
        const columnsToStore = [...selectedColumn, column];
        const columnExists = component?.properties?.columnProperties.some(
          (col) => col.name === column
        );

        if (!columnExists) {
          console.log(">> Adding new computed column");
          // Add the new column property
          const newColumnProperties =
            component?.properties?.columnProperties.concat([
              {
                id: columnsToStore.length - 1,
                name: column,
                align: "center",
                borderType: component?.properties?.borderType,
                borderColor: component?.properties?.borderColor,
                borderWidth: component?.properties?.borderWidth,
                isIncoming: false,
                type: "NUMBER",
                isComputedColumn: false,
                computedColumns: {},
              },
            ]);
          // Example state update (adjust as needed for your state management)
          onPropertyChange({
            columns: [...selectedColumn, column],
            columnProperties: newColumnProperties,
          });
          setSelectedComponent((prev) => ({
            ...prev,
            properties: {
              ...prev.properties,
              columns: [...selectedColumn, column],
              columnProperties: newColumnProperties,
            },
          }));
          setCurrentReportData({
            columns: [...selectedColumn, column],
            data: component?.properties?.data,
            isNewReport: false,
            templateId: component?.properties?.templateId,
            isColumnChanged: true,
          });
        } else {
          onPropertyChange({ columns: [...selectedColumn, column] });
          setSelectedComponent((prev) => ({
            ...prev,
            properties: {
              ...prev.properties,
              columns: [...selectedColumn, column],
            },
          }));
          setCurrentReportData({
            columns: [...selectedColumn, column],
            data: component?.properties?.data,
            isNewReport: false,
            templateId: component?.properties?.templateId,
            isColumnChanged: true,
          });
        }
        setSelectedColumn([...selectedColumn, column]);
        setFlag(false);
      }
    }
  };
  const handleAddNewColumn = async () => {
    if (component?.properties?.columns.includes(newCol)) {
      showAlert(`${newCol} already exists`, "error");
      setNewCol("");
      return;
    } else {
      if (reportId !== null || isNewReportActive) {
        const newColumn = component?.properties?.columns.concat([`${newCol}`]);
        const newData = component?.properties?.data.map((row) => ({
          ...row,
          [newCol]: "",
        }));
        const newColumnProperties =
          component?.properties?.columnProperties.concat([
            {
              id: newColumn.length - 1,
              name: newCol,
              align: "center",
              borderType: component?.properties?.borderType,
              borderColor: component?.properties?.borderColor,
              borderWidth: component?.properties?.borderWidth,
              isIncoming: false,
              type: newColType,
              isComputedColumn: false,
              computedColumns: {},
            },
          ]);
        onPropertyChange({
          columns: newColumn,
          data: newData,
          columnProperties: newColumnProperties,
        });
        setSelectedComponent((prev) => ({
          ...prev,
          properties: {
            ...prev.properties,
            columns: newColumn,
            data: newData,
            columnProperties: newColumnProperties,
          },
        }));
        handleColumnNameFilter(newCol, newData, newColumnProperties);
        setFlag(false);
        setNewCol("");
      } else {
        setOpen(true);
      }
    }
  };
  const filterDataByColumns = (data, columns) => {
    return data.map((row) => {
      let filteredRow = {};
      columns.forEach((column) => {
        filteredRow[column] = Object.prototype.hasOwnProperty.call(row, column)
          ? row[column]
          : ""; // Add empty string for missing columns
      });
      return filteredRow;
    });
  };
  const handleUpdateTamplate = async (obj) => {
    if (obj.isUpdateData === true) {
      try {
        const filteredData = filterDataByColumns(
          component?.properties?.data,
          component?.properties?.columns
        );
        const response = await updateTemplates({
          templateId: component?.properties?.templateId,
          tableData: filteredData,
          isOverride: true,
        }).unwrap();
        showAlert("Template updated successfully", "success");
        getUserTemplates();
      } catch (err) {
        showAlert(err?.data?.message, "error");
      }
      setIsSaveClicked(false);
      setOpen(false);
    } else {
      setOpen(false);
      const newColumn = component?.properties?.columns.concat([`${newCol}`]);
      const newData = component?.properties?.data.map((row) => ({
        ...row,
        [newCol]: "",
      }));
      const newColumnProperties =
        component?.properties?.columnProperties.concat([
          {
            id: newColumn.length - 1,
            name: newCol,
            align: "center",
            borderType: component?.properties?.borderType,
            borderColor: component?.properties?.borderColor,
            borderWidth: component?.properties?.borderWidth,
            type: newColType,
          },
        ]);
      try {
        const filteredData = filterDataByColumns(newData, newColumn);
        const response = await updateTemplates({
          templateId: component?.properties?.templateId,
          tableData: filteredData,
          isOverride: true,
        }).unwrap();
        showAlert("Template updated successfully", "success");
        getUserTemplates();
      } catch (err) {
        showAlert(err?.data?.message, "error");
      }
      onPropertyChange({
        columns: newColumn,
        data: newData,
        columnProperties: newColumnProperties,
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          columns: newColumn,
          data: newData,
          columnProperties: newColumnProperties,
        },
      }));
      handleColumnNameFilter(newCol, newData, newColumnProperties);
      setFlag(false);
      setNewCol("");
      setNewColType("VARCHAR");
    }
  };
  const handlePositionChange = (e, property) => {
    const newValue = parseInt(e.target.value, 10);
    let updatedProperties = { [property]: newValue };
    if (newValue < 0) {
      updatedProperties[property] = 0;
    }
    if (property === "top") {
      updatedProperties.bottom =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure bottom doesn't go below 0
      if (updatedProperties.bottom < 0) {
        updatedProperties.bottom = 0;
        updatedProperties.top =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "bottom") {
      updatedProperties.top =
        reportAreaRef.current.clientHeight -
        newValue -
        component.properties.height;
      // Ensure top doesn't go below 0
      if (updatedProperties.top < 0) {
        updatedProperties.top = 0;
        updatedProperties.bottom =
          reportAreaRef.current.clientHeight - component.properties.height;
      }
    } else if (property === "left") {
      updatedProperties.right =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.right < 0) {
        updatedProperties.right = 0;
        updatedProperties.left =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    } else if (property === "right") {
      updatedProperties.left =
        reportAreaRef.current.clientWidth -
        newValue -
        component.properties.width;
      if (updatedProperties.left < 0) {
        updatedProperties.left = 0;
        updatedProperties.right =
          reportAreaRef.current.clientWidth - component.properties.width;
      }
    }
    onPropertyChange(updatedProperties);
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        ...updatedProperties,
      },
    }));
    setFlag(false);
  };
  const handleColumnPropertyChange = (columnName, updatedProperties) => {
    setSelectedComponent((prev) => {
      const updatedColumnProperties = prev.properties.columnProperties.map(
        (col) =>
          col.name === columnName ? { ...col, ...updatedProperties } : col
      );
      return {
        ...prev,
        properties: {
          ...prev.properties,
          columnProperties: updatedColumnProperties,
        },
      };
    });
    const updatedColumnProperties = component.properties.columnProperties.map(
      (col) =>
        col.name === columnName ? { ...col, ...updatedProperties } : col
    );
    onPropertyChange({ columnProperties: updatedColumnProperties });
    setFlag(false);
  };
  const handleColorPickerToggle = (columnName, pickerType) => {
    setColorPickerState((prev) => ({
      ...prev,
      [columnName]: {
        ...prev[columnName],
        [pickerType]: !prev[columnName]?.[pickerType],
      },
    }));
  };
  const handleClickOutside = (event) => {
    if (textColorRef.current && !textColorRef.current.contains(event.target)) {
      setColorPickerText(false);
    }
    if (
      backgroundColorRef.current &&
      !backgroundColorRef.current.contains(event.target)
    ) {
      setColorPickerBg(false);
    }
    if (
      borderColorRef.current &&
      !borderColorRef.current.contains(event.target)
    ) {
      setColorPickerOpen(false);
    }
    if (
      columnDropdownRef.current &&
      !columnDropdownRef.current.contains(event.target)
    ) {
      setColumnListOpen(false);
    }
    if (
      colADropdownRef.current &&
      !colADropdownRef.current.contains(event.target)
    ) {
      setComputedColListOpen(false);
    }
    if (
      colBDropdownRef.current &&
      !colBDropdownRef.current.contains(event.target)
    ) {
      setComputedColBListOpen(false);
    }
    const isOutsideColorPickers = Object.keys(colorPickerRefs.current).every(
      (columnName) => {
        const refs = colorPickerRefs.current[columnName];
        return (
          !refs ||
          Object.keys(refs).every((pickerType) => {
            const ref = refs[pickerType];
            return ref && !ref.contains(event.target);
          })
        );
      }
    );
    if (isOutsideColorPickers) {
      setColorPickerState({});
    } else {
      // Only close color pickers that are not being targeted
      setColorPickerState((prevState) => {
        const newState = { ...prevState };
        Object.keys(colorPickerRefs.current).forEach((columnName) => {
          const refs = colorPickerRefs.current[columnName];
          Object.keys(refs).forEach((pickerType) => {
            const ref = refs[pickerType];
            if (ref && !ref.contains(event.target)) {
              if (newState[columnName]) {
                newState[columnName][pickerType] = false;
              }
            }
          });
        });
        return newState;
      });
    }
    // if (columnTextColorRef.current && !columnTextColorRef.current.contains(event.target)) {
    //     setColorPickerState({});
    // }
    // if (columnBackgroundRef.current && !columnBackgroundRef.current.contains(event.target)) {
    //     setColorPickerState({});
    // }
  };
  const validateRowTypes = (row, columnTypes) => {
    for (const column in row) {
      const value = row[column];
      const expectedType = columnTypes[column];

      if (expectedType === "VARCHAR" && typeof value !== "string") {
        return `You cannot enter "${value}" in the column "${column}" because it expects a string (Text) value.`;
      }
      if (expectedType === "INTEGER" && !Number.isInteger(Number(value))) {
        return `You cannot enter "${value}" in the column "${column}" because it expects an Integer (Number) value.`;
      }
      if (expectedType === "FLOAT" && isNaN(value)) {
        return `You cannot enter "${value}" in the column "${column}" because it expects a Float (Number with decimal) value.`;
      }
      // if (expectedType === 'DATE' && isNaN(Date.parse(value))) {
      //     return `You cannot enter "${value}" in the column "${column}" because it expects a date (DATE) value.`;
      // }
      if (expectedType === "DATE") {
        const formats = [
          "DD-MM-YYYY",
          "MM/DD/YYYY",
          "YYYY-MM-DD",
          "MM-DD-YYYY",
          "YYYY/MM/DD",
          "DD/MM/YYYY",
        ];
        if (!moment(value, formats, true).isValid()) {
          return `You cannot enter "${value}" in the column "${column}" because it expects a valid date value.`;
        }
      }
      if (
        expectedType === "BOOLEAN" &&
        ![
          "1",
          "0",
          "true",
          "false",
          "yes",
          "no",
          "YES",
          "NO",
          true,
          false,
        ].includes(value)
      ) {
        return `You cannot enter "${value}" in the column "${column}" because it expects a boolean (0,1,yes,no,true,false) value.`;
      }
    }
    return null;
  };
  const handleAddRow = () => {
    const columnTypes = component?.properties?.columnProperties?.reduce(
      (acc, item) => {
        acc[item.name] = item.type;
        return acc;
      },
      {}
    );

    const filterObject = (obj, keysToKeep) => {
      return Object.fromEntries(
        Object.entries(obj).filter(([key]) => keysToKeep.includes(key))
      );
    };
    const filteredData = filterObject(tempRow, component?.properties?.columns);

    const errorMessage = validateRowTypes(filteredData, columnTypes);
    if (errorMessage) {
      showAlert(errorMessage, "error");
      return;
    }
    setIsAddRowOpen(false);

    const newData = component?.properties?.data.concat(tempRow);
    onPropertyChange({ data: newData });
    setSelectedComponent((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        data: newData,
      },
    }));
    showAlert("New Row Added successfully!", "success");
    setFlag(false);
    setTempRow(
      component?.properties?.columns?.reduce(
        (acc, column) => ({ ...acc, [column]: "" }),
        {}
      )
    );
  };
  const handleInputChange = (column, value) => {
    setTempRow({ ...tempRow, [column]: value });
    // setTempRow((prev) => ({
    //     ...prev,
    //     [column]: value
    // }));
  };
  const handleHeaderChange = async (columnName, newName) => {
    console.log(columnName, newName);
    //handleColumnPropertyChange(columnName, { name: newName });
    const updatedColumns = component?.properties?.columns.map((item) =>
      item === columnName ? newName : item
    );
    const updatedData = component?.properties?.data.map((row) => {
      const newRow = { ...row };

      newRow[newName] = row[columnName];
      delete newRow[columnName];
      return newRow;
    });
    const updatedProperties = { name: newName };
    setSelectedComponent((prev) => {
      const updatedColumnProperties =
        component?.properties?.columnProperties.map((col) => {
          if (col.isComputedColumn) {
            // Check if this computed column uses the old column name
            const updatedComputedColumns = { ...col.computedColumns };
            if (updatedComputedColumns.colA === columnName) {
              updatedComputedColumns.colA = newName;
            }
            if (updatedComputedColumns.colB === columnName) {
              updatedComputedColumns.colB = newName;
            }
            return {
              ...col,
              name: col.name.replace(columnName, newName), // Update the name
              computedColumns: updatedComputedColumns,
            };
          }
          return {
            ...col,
            name: col.name === columnName ? newName : col.name,
          };
        });
      return {
        ...prev,
        properties: {
          ...prev.properties,
          columns: updatedColumns,
          data: updatedData,
          columnProperties: updatedColumnProperties,
        },
      };
    });
    const updatedColumnProperties = component?.properties?.columnProperties.map(
      (col) => {
        if (col.isComputedColumn) {
          // Check if this computed column uses the old column name
          const updatedComputedColumns = { ...col.computedColumns };
          if (updatedComputedColumns.colA === columnName) {
            updatedComputedColumns.colA = newName;
          }
          if (updatedComputedColumns.colB === columnName) {
            updatedComputedColumns.colB = newName;
          }
          return {
            ...col,
            name: col.name.replace(columnName, newName), // Update the name
            computedColumns: updatedComputedColumns,
          };
        }
        return {
          ...col,
          name: col.name === columnName ? newName : col.name,
        };
      }
    );
    onPropertyChange({
      data: updatedData,
      columns: updatedColumns,
      columnProperties: updatedColumnProperties,
    });
    setSelectedColumn(
      selectedColumn.map((item) => (item === columnName ? newName : item))
    );
    setDeselectedColumns(
      deselectedColumns.map((item) => (item === columnName ? newName : item))
    );
    setFlag(false);
  };
  // Convert string values to numbers
  const convertToNumber = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? null : number;
  };

  // Extract column names from the expression
  const extractColumnNames = (expression) => {
    // Extract the column names stored in expressionHistory (assuming it's an array of column objects)
    const currentColumn = expressionHistory
      ?.filter((item) => item?.type === "column")
      ?.map((item) => item?.value.trim().replace(/`/g, "")); // Remove backticks from each column name

    return [...new Set(currentColumn)]; // Remove duplicates
  };
  // Check if all columns used in the expression have numeric values in all rows
  const handleInputChangeComputed = (inputValue) => {
    // Ensure the inputValue is treated as a string
    inputValue = inputValue || "";
    console.log(inputValue, ">>> input value");
    const index = inputValue.indexOf(inputExpression);
    if (index === -1) {
      return;
    }
    let remainingString = inputValue
      .slice(index + inputExpression.length)
      .trim();

    const numberMatch = remainingString.match(/^\d+(\.\d+)?/);

    if (numberMatch || remainingString === ".") {
      const number = numberMatch ? parseFloat(numberMatch[0]) : remainingString;
      if (!isNaN(number) || number === ".") {
        setInputExpression(inputValue);
        setExpressionHistory((prevHistory) => [
          ...prevHistory,
          { type: "number", value: number },
        ]);
      } else {
        return;
      }
    } else {
      return;
    }
  };
  const handleColumnSelect = (column) => {
    setExpressionHistory((prevHistory) => [
      ...prevHistory,
      { type: "column", value: `\`${column}\`` },
    ]);
    setInputExpression((prev) => {
      // Add space before the column if the previous character is not a space or an operator
      const needsSpace =
        prev?.length > 0 && !/[\s+\-*/()]/?.test(prev?.slice(-1));
      const newExpression = `${prev}${needsSpace ? " " : ""}\`${column}\``;
      return newExpression;
    });
  };
  // Handle operation selection
  const handleOperationSelect = (operation) => {
    setExpressionHistory((prevHistory) => [
      ...prevHistory,
      { type: "operation", value: operation.icon },
    ]);
    setInputExpression((prev) => {
      const trimmedPrev = prev?.trim();
      // Ensure a space before and after the operation if the previous character is not a space or an operator
      const needsSpace =
        trimmedPrev?.length > 0 && !/[\s+\-*/()]/?.test(trimmedPrev?.slice(-1));
      const newExpression = `${trimmedPrev}${needsSpace ? " " : ""}${operation.icon
        } `;
      return newExpression;
    });
    setSelectedOperation(operation);
  };
  const validateColumns = (expression, data) => {
    const columnNames = extractColumnNames(expression);
    let invalidColumn = false;
    const newCols = columnNames.filter((item) => isNaN(item));

    newCols.forEach((column) => {
      data.forEach((row) => {
        const value = convertToNumber(row[column]);
        if (
          value === null &&
          row[column] !== "NA" &&
          row[column] !== "" &&
          row[column] !== undefined &&
          row[column] !== null
        ) {
          invalidColumn = true;
        }
      });
    });
    return !invalidColumn;
  };
  const generateUniqueColumnName = (baseName, existingColumns) => {
    let newName = baseName;
    let i = 1;
    while (existingColumns.includes(newName)) {
      newName = `${baseName}${i}`;
      i++;
    }
    return newName;
  };
  const safeEvaluate = (expression, data) => {
    try {
      const existingColumns = Object.keys(data[0] || {});
      let computedColumnName = generateUniqueColumnName(
        "Computed",
        existingColumns
      );

      // Validate the columns for numeric values
      if (!validateColumns(expression, data)) {
        showAlert(
          "Cannot perform operations with columns containing non-numeric values.",
          "error"
        );
        setInputExpression("");
        setExpressionHistory([]);
        setRedoHistory([]);
        setIsColumnEdited(false);
        setOriginalColumnName(null);

        return null;
      }

      // Evaluates each row based on the expression
      const evaluateRow = (row) => {
        const columnValues = {};
        Object.keys(row).forEach((key) => {
          columnValues[`[${key}]`] = convertToNumber(row[key]); // Wrap column names in brackets
        });

        // Replace column names in backticks with their corresponding values
        const sanitizedExpression = expression
          .replace(/\s+/g, " ") // Normalize spaces
          .replace(/`([^`]+)`/g, (match, columnName) => {
            let value = columnValues[`[${columnName}]`]; // Use the column name extracted from backticks
            value =
              value === null ||
                value === undefined ||
                value === "NA" ||
                value === ""
                ? 0
                : value;
            return value !== undefined ? value : match; // If found, replace with value; otherwise keep the match
          });
        try {
          return new Function("return " + sanitizedExpression)(); // Safely evaluate the expression
        } catch (error) {
          showAlert("Error evaluating expression", "error");
          setInputExpression("");
          setExpressionHistory([]);
          setRedoHistory([]);
          setIsColumnEdited(false);
          setOriginalColumnName(null);
          console.error("Error evaluating expression:", error);
          return null;
        }
      };
      const updatedData = data.map((row) => {
        const result = evaluateRow(row);
        if (result === null) {
          return null;
        }
        return {
          ...row,
          [isColumnEdited ? originalColumnName : computedColumnName]: result,
        };
      });
      if (updatedData.some((row) => row === null)) {
        showAlert(
          "Error in computing some rows. Ensure your expression is correct.",
          "error"
        );
        return null;
      }
      return { updatedData, computedColumnName, error: false };
    } catch (error) {
      console.error("Error evaluating expression:", error);
      showAlert("An error occurred while evaluating the expression.", "error");
      setInputExpression("");
      setExpressionHistory([]);
      setRedoHistory([]);
      setIsColumnEdited(false);
      setOriginalColumnName(null);
      return null;
    }
  };

  const handleProceedOperation = () => {
    const expression = inputExpression;
    if (!expression) {
      showAlert("Expression is empty.", "error");
      setInputExpression("");
      setExpressionHistory([]);
      setRedoHistory([]);
      setIsColumnEdited(false);
      setOriginalColumnName(null);
      return;
    }
    if (!validateColumns(expression, component?.properties?.data)) {
      showAlert(
        "Cannot perform operations with columns containing non-numeric values.",
        "error"
      );
      setInputExpression("");
      setExpressionHistory([]);
      setRedoHistory([]);
      setIsColumnEdited(false);
      setOriginalColumnName(null);
      return;
    }
    const result = safeEvaluate(expression, component?.properties?.data);
    if (result?.error == false) {
      setNewComputedColumn({
        status: true,
        colName: isColumnEdited
          ? originalColumnName
          : result?.computedColumnName,
        oldName: isColumnEdited
          ? originalColumnName
          : result?.computedColumnName,
        data: result?.updatedData,
        decimal: 2,
      });
      // const newName = isColumnEdited ? newComputedColumn?.colName : result?.computedColumnName;
      // setNewComputedColumn({
      //   status: true,
      //   colName: newName,
      //   oldName: originalColumnName || result?.computedColumnName,
      //   data: result?.updatedData,
      //   decimal: 2,
      // });
      setIsAddNewColumnOpen(false);
      const { updatedData, computedColumnName } = result;
      if (!updatedData || updatedData.length === 0) {
        showAlert(
          "Failed to compute the result. Please check your expression.",
          "error"
        );
        setInputExpression("");
        setExpressionHistory([]);
        setRedoHistory([]);
        setIsAddNewColumnOpen(false);
        setIsColumnEdited(false);
        setOriginalColumnName(null);
        return null;
      }
    } else {
      showAlert(
        "Failed to compute the result. Please check your expression.",
        "error"
      );
      setInputExpression("");
      setExpressionHistory([]);
      setRedoHistory([]);
      setIsColumnEdited(false);
      setOriginalColumnName(null);
    }
  };
  const updateTemplateWithComputedColumns = async () => {
    console.log("update tempate with computed columns", newComputedColumn, "O");

    const columnExists = Object.keys(component?.properties?.data[0]).some(
      (item) => item == newComputedColumn?.colName?.trimEnd()
    );

    if (
      columnExists &&
      newComputedColumn?.colName != newComputedColumn?.oldName
    ) {
      showAlert(`${newComputedColumn?.colName} already exists`);
      return; // Stop further execution if column already exists
    }
    function extractComputedData(data, computedColumns) {
      return data.map((item) => {
        const computedData = {};
        const usedColumns = {};
        Object?.keys(item)?.forEach((key) => {
          if (key === newComputedColumn?.colName?.trimEnd()) {
            computedData[key] = parseFloat(
              item[key]?.toFixed(newComputedColumn?.decimal)
            );
          }
        });
        Object.keys(item).forEach((key) => {
          if (computedColumns?.some((col) => key === col)) {
            usedColumns[key] = item[key];
          }
        });
        return { ...computedData, ...usedColumns };
      });
    }
    function replaceKeyName(data, oldName, newName) {
      const dataForCal = data.map((item) => {
        // Use Object.prototype.hasOwnProperty.call to safely check for the property
        if (Object.prototype.hasOwnProperty.call(item, oldName)) {
          const { [oldName]: oldValue, ...rest } = item;
          return {
            ...rest,
            [newName]: parseFloat(
              oldValue?.toFixed(newComputedColumn?.decimal)
            ),
          };
        }
        return item;
      });
      const dataForUse = data.map((item) => {
        // Use Object.prototype.hasOwnProperty.call to safely check for the property
        if (Object.prototype.hasOwnProperty.call(item, oldName)) {
          const { [oldName]: oldValue, ...rest } = item;
          return {
            ...rest,
            [newName]: parseFloat(
              oldValue?.toFixed(newComputedColumn?.decimal)
            ).toString(),
          };
        }
        return item;
      });
      return { dataForCal: dataForCal, dataForUse: dataForUse };
    }
    try {
      setNewComputedColumn({ ...newComputedColumn, status: null });
      const updatedData = replaceKeyName(
        newComputedColumn?.data,
        newComputedColumn?.oldName,
        newComputedColumn?.colName.trimEnd()
      );
      const usedColumns = extractColumnNames(inputExpression);
      const newUsedCols = usedColumns.filter((item) => isNaN(item));
      const computedData = extractComputedData(
        updatedData?.dataForCal,
        newUsedCols
      );
      await updateTemplates({
        templateId: component?.properties?.templateId,
        tableData: computedData,
        isComputedColumn: true,
        usedColumns: newUsedCols,
        isOverride: false,
        computedColumn: newComputedColumn?.colName.trimEnd(),
        oldColumn:
          isColumnEdited &&
            newComputedColumn?.oldName?.trimEnd() !==
            newComputedColumn?.colName.trimEnd()
            ? newComputedColumn?.oldName.trimEnd()
            : null,
      }).unwrap();
      getUserTemplates();
      const columns =
        component?.properties?.columns?.filter(
          (item) => item !== newComputedColumn?.oldName
        ) ?? []; // Default to an empty array if undefined
      const newCols = [...columns, newComputedColumn?.colName.trimEnd()];
      const newColumnProperties = (
        component?.properties?.columnProperties.filter(
          (item) => item?.name !== newComputedColumn?.oldName
        ) ?? []
      ).concat([
        {
          id: newCols.length - 1,
          name: newComputedColumn?.colName.trimEnd(),
          align: "center",
          borderType: component?.properties?.borderType,
          borderColor: component?.properties?.borderColor,
          borderWidth: component?.properties?.borderWidth,
          isIncoming: false,
          type: "INTEGER",
          isComputedColumn: true,
          expression: inputExpression,
        },
      ]);
      onPropertyChange({
        columns: newCols,
        data: updatedData.dataForUse,
        columnProperties: newColumnProperties,
      });
      setSelectedComponent((prev) => ({
        ...prev,
        properties: {
          ...prev.properties,
          columns: newCols,
          data: updatedData.dataForUse,
          columnProperties: newColumnProperties,
        },
      }));
      setCurrentReportData({
        data: updatedData.dataForUse,
        columns: newCols,
        isNewReport: false,
        templateId: component?.properties?.templateId,
        isColumnChanged: true,
      });
      setSelectedColumn(newCols);
      // showAlert("New column added successfully", "success");
      if (isColumnEdited) {
        showAlert("Column modified successfully", "success");
      } else {
        showAlert("New column added successfully", "success");
      }
      setFlag(false);
      setInputExpression("");
      setExpressionHistory([]);
      setRedoHistory([]);
      setIsColumnEdited(false);
      setOriginalColumnName(null);
    } catch (err) {
      setNewComputedColumn({ ...newComputedColumn, status: null });
      setInputExpression("");
      setExpressionHistory([]);
      setRedoHistory([]);
      setIsColumnEdited(false);
      setOriginalColumnName(null);
      console.log(err);
    }
  };



  // const handleUndo = () => {
  //   let lastEntry;
  //   setExpressionHistory((prevHistory) => {
  //     if (prevHistory.length === 0) {
  //       showAlert("No operation to undo.", "info");
  //       return prevHistory;
  //     }

  //     // Get the last entry from history
  //     lastEntry = prevHistory[prevHistory.length - 1];
  //     let newExpression = inputExpression;

  //     // Undo logic based on the type of the last entry
  //     if (lastEntry.type === "column") {
  //       const colIndex = newExpression?.lastIndexOf(lastEntry.value);
  //       if (colIndex !== -1) {
  //         newExpression = newExpression?.slice(0, colIndex).trim();
  //       }
  //     } else if (lastEntry.type === "operation") {
  //       const opIndex = newExpression?.lastIndexOf(lastEntry.value);
  //       if (opIndex !== -1) {
  //         newExpression = newExpression?.slice(0, opIndex).trim();
  //       }
  //     } else if (lastEntry.type === "number") {
  //       const numIndex = newExpression?.lastIndexOf(lastEntry.value);
  //       if (numIndex !== -1) {
  //         newExpression = newExpression?.slice(0, numIndex).trim();
  //       }
  //     } else if (lastEntry.type === "bracket") {
  //       // Handle undoing brackets
  //       const bracketIndex = newExpression?.lastIndexOf(lastEntry.value);
  //       if (bracketIndex !== -1) {
  //         newExpression =
  //           newExpression?.slice(0, bracketIndex) +
  //           newExpression.slice(bracketIndex + 1);
  //       }
  //     }

  //     // Update the input expression after undoing the operation
  //     setInputExpression(newExpression);

  //     // Remove the last entry from history and return the updated history
  //     return prevHistory.slice(0, -1);
  //   });

  //   // Move the last entry to redo history
  //   setRedoHistory((prevRedo) => [lastEntry, ...prevRedo]);
  // };

  // const handleRedo = () => {
  //   setRedoHistory((prevRedo) => {
  //     if (prevRedo.length === 0) {
  //       showAlert("No operation to redo.", "info");
  //       return prevRedo;
  //     }

  //     // Get the last undone expression from redoHistory
  //     const lastUndone = prevRedo[0]; // The most recent undo operation stored

  //     // Restore the entire expression from the redo history
  //     const redoneExpression = lastUndone.expression;
  //     console.log(redoneExpression, "redoexpression")

  //     // Set the new expression after redo
  //     setInputExpression(redoneExpression);

  //     // Update the expression history with the redone expression
  //     setExpressionHistory((prevHistory) => [
  //       ...prevHistory,
  //       { expression: redoneExpression },
  //     ]);

  //     // Remove the last redone entry from redo history
  //     return prevRedo.slice(1);
  //   });
  // };

  const handleUndo = () => {
    let lastEntry;
    setExpressionHistory((prevHistory) => {
      if (prevHistory.length === 0) {
        showAlert("No operation to undo.", "info");
        return prevHistory;
      }

      // Get the last entry from history
      lastEntry = prevHistory[prevHistory.length - 1];
      let newExpression = inputExpression;
      if (lastEntry.type === "column") {
        const colIndex = newExpression?.lastIndexOf(lastEntry.value);
        if (colIndex !== -1) {
          newExpression = newExpression?.slice(0, colIndex).trim();
        }
      } else if (lastEntry.type === "operation") {
        const opIndex = newExpression?.lastIndexOf(lastEntry.value);
        if (opIndex !== -1) {
          newExpression = newExpression?.slice(0, opIndex).trim();
        }
      } else if (lastEntry.type === "number") {
        const numIndex = newExpression?.lastIndexOf(lastEntry.value);
        if (numIndex !== -1) {
          newExpression = newExpression?.slice(0, numIndex).trim();
        }
      } else if (lastEntry.type === "bracket") {
        // Handle undoing brackets
        const bracketIndex = newExpression?.lastIndexOf(lastEntry.value);
        if (bracketIndex !== -1) {
          newExpression =
            newExpression?.slice(0, bracketIndex) +
            newExpression.slice(bracketIndex + 1);
        }
      }
      setInputExpression(newExpression);

      // Remove the last entry from history and return the updated history
      return prevHistory.slice(0, -1);
    });

    setRedoHistory((prevRedo) => [lastEntry, ...prevRedo]);
    setRedoExpression(prev => {
      return [inputExpression, ...prev]
    }
    )
    console.log(inputExpression, "redohistopry")
  };

  const handleRedo = () => {
    setRedoHistory((prevRedo) => {
      if (prevRedo.length === 0) {
        showAlert("No operation to redo.", "info");
        return prevRedo;
      }
      const lastUndone = prevRedo[0];
      let newExpression = inputExpression;

      // Redo logic for brackets
      if (lastUndone.type === "bracket") {
        // Reinsert the bracket at the saved position
        const insertPosition = lastUndone.position;
        newExpression =
          newExpression.slice(0, insertPosition) +
          lastUndone.value +
          newExpression.slice(insertPosition);
      } else if (
        lastUndone.type === "column" ||
        lastUndone.type === "operation"
      ) {
        newExpression += " " + lastUndone.value;
      } else if (lastUndone.type === "number") {
        newExpression += lastUndone.value; // No space for numbers
      }

      // Restore the redone entry to the history
      setExpressionHistory((prevHistory) => [...prevHistory, lastUndone]);

      // Remove the last undone entry from redo history

      return prevRedo.slice(1);
    });
    const exp = redoExpression?.shift();
    setInputExpression(exp);
    redoExpression?.length <= 1 ? InputHistory(exp) : '';
  };

  const insertBracket = (bracket) => {
    const textarea = inputRef.current;
    const { selectionStart } = textarea;
    const currentValue = inputExpression;

    // Function to check if the cursor is inside a column name
    const isInsideColumnName = (value, position) => {
      const columnRegex = /`[^`]+`/g; // Regex to match column names
      let match;

      // Use a loop to check all matches for the position
      while ((match = columnRegex.exec(value)) !== null) {
        if (
          position > match.index &&
          position < match.index + match[0].length
        ) {
          return true; // Inside a column name
        }
      }
      return false; // Not inside a column name
    };

    // Function to check if the cursor is immediately before a column name
    const isBeforeColumnName = (value, position) => {
      const columnRegex = /`[^`]+`/g; // Regex to match column names
      let match;

      // Use a loop to check all matches for the position
      while ((match = columnRegex.exec(value)) !== null) {
        if (position === match.index) {
          return true; // Cursor is right before a column name
        }
      }
      return false; // Cursor is not before a column name
    };

    // Check if we can insert the bracket
    if (!isInsideColumnName(currentValue, selectionStart)) {
      if (bracket === "(" || bracket === ")") {
        // Insert the bracket at the correct position
        const newValue =
          currentValue.slice(0, selectionStart) +
          bracket +
          currentValue.slice(selectionStart);

        // Save the current expression to history before changing it
        setExpressionHistory((prevHistory) => {
          console.log(
            [
              ...prevHistory,
              { type: "bracket", value: bracket, position: selectionStart },
            ],
            ">>>> hanfdle bracket"
          );
          return [
            ...prevHistory,
            { type: "bracket", value: bracket, position: selectionStart },
          ];
        });

        // Update the input expression
        setInputExpression(newValue);

        // Set the cursor position after the inserted bracket
        requestAnimationFrame(() => {
          textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
          textarea.focus();
        });
      }
    } else if (
      isBeforeColumnName(currentValue, selectionStart) &&
      bracket === "("
    ) {
      // Allow adding an opening bracket before a column name
      const newValue =
        currentValue.slice(0, selectionStart) +
        bracket +
        currentValue.slice(selectionStart);

      // Save the current expression to history before changing it
      setExpressionHistory((prevHistory) => [
        ...prevHistory,
        { type: "bracket", value: bracket, position: selectionStart },
      ]);

      // Update the input expression
      setInputExpression(newValue);

      // Set the cursor position after the inserted bracket
      requestAnimationFrame(() => {
        textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
        textarea.focus();
      });
    } else {
      console.log(
        "Cannot insert closing brackets or insert brackets inside column names"
      );
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setInputExpression(value);
    }
  };

  const InputHistory = (expression) => {
    console.log(expression, "expression");
    // Combined pattern to match numbers, columns (backticks), operations, and parentheses
    const combinedPattern = /(`[^`]+`)|(\d+(\.\d+)?)|([+\-*/()])|([^\s]+)/g;

    // Array to hold parsed parts
    let parts = [];
    let match;

    // Extract all parts in order
    while ((match = combinedPattern.exec(expression)) !== null) {
      if (match[1]) {
        // Column match
        parts.push({ type: "column", value: match[1] });
      } else if (match[2]) {
        // Number match
        parts.push({ type: "number", value: match[2] });
      } else if (match[4]) {
        // Operation match (including parentheses)
        parts.push({ type: "operation", value: match[4] });
      } else if (match[5]) {
        // Handle any unexpected text (e.g., `23+` with unexpected trailing text)
        console.warn(`Unexpected text part: "${match[5]}"`);
      }
    }

    // Log the parts for debugging
    console.log("Extracted Parts:", parts);

    // Temporary array to build history
    let tempHistory = [];

    // Process each part
    parts.forEach((part) => {
      if (part.type === "number") {
        // Process each digit individually for numbers
        for (const digit of part.value) {
          if (digit === ".") {
            tempHistory.push({ type: "number", value: digit });
          } else {
            tempHistory.push({ type: "number", value: parseFloat(digit) });
          }
        }
      } else if (part.type === "column") {
        // Store column in history
        tempHistory.push(part);
      } else if (part.type === "operation") {
        // Store operation in history
        tempHistory.push(part);
      }
    });

    // Update the state with the new history
    setExpressionHistory(tempHistory);

    // Log the history directly
    console.log("Updated Expression History:", tempHistory);
  };
  const handleEdit = (expression, name) => {
    console.log(name, "name");
    setInputExpression(expression);
    setIsAddNewColumnOpen(true);
    setActiveTab(1);
    setOriginalColumnName(name);
    setIsColumnEdited(true);

    InputHistory(expression);
    // setNewComputedColumn({ colName: name })
    //setExpressionHistory({ type: 'number', value: `\`${expression}\`` })
  };
  // setNewComputedColumn({ status: true, colName: name, decimal: 2 });
  React.useEffect(() => {
    if (flag) {
      setDeselectedColumns([]);
      setSelectedColumn(component?.properties?.columns);
      setTempRow(
        component?.properties?.columns?.reduce(
          (acc, column) => ({ ...acc, [column]: "" }),
          {}
        )
      );
    }
    if (tempRow == null) {
      setTempRow(
        component?.properties?.columns?.reduce(
          (acc, column) => ({ ...acc, [column]: "" }),
          {}
        )
      );
    }
  }, [component]);
  React.useEffect(() => {
    if (
      isSaveClicked === true &&
      component?.properties?.templateId != null &&
      component?.properties?.templateId != undefined
    ) {
      setOpen(true);
    } else {
      setIsSaveClicked(false);
    }
  }, [isSaveClicked]);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  React.useEffect(() => {
    if (inputRef.current && inputExpression !== "") {
      inputRef.current.focus();
    }
  }, [inputExpression]);

  const handleToggle = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  const handleItemToggle = (itemName) => {
    setOpenItem((prevOpenItem) =>
      prevOpenItem === itemName ? null : itemName
    );
  };

  return (
    <>
      {newComputedColumn?.status === true && (
        <ReusableReportModal
          // open
          open={newComputedColumn?.status === true}
          onClose={() => {
            setNewComputedColumn({ statue: null, colName: "" });
            setIsAddNewColumnOpen(false);
            setActiveTab(0);
            setInputExpression("");
            setIsColumnEdited(false);
            setOriginalColumnName(null);
          }}
          title={"Column name configuration"}
        >
          <div className="mx-[50px] mt-3 d-flex flex-col ">
            <p className="text-xs text-gray-700 text-medium">
              Enter the name and decimal places you want to use for saving this
              computed column.
            </p>
            <span className="d-flex justify-center items-center space-x-3 py-2">
              <p className="m-0  whitespace-nowrap font-semibold text-sm self-center">
                Name:
              </p>
              <input
                type="text"
                value={newComputedColumn?.colName}
                onChange={(e) => {
                  const value = e.target.value;
                  const hasSpecialChars = /[^a-zA-Z0-9_ ]/.test(value);
                  if (value.includes("  ")) {
                    //  setIserror(true);
                    return;
                  }
                  if (hasSpecialChars) {
                    setIserror(true);
                    return;
                  }
                  const filteredValue = value.replace(/[^a-zA-Z0-9_ ]/g, "");
                  setIserror(false);
                  setNewComputedColumn({
                    ...newComputedColumn,
                    colName: filteredValue,
                  });
                }}
                className=" block p-1  text-sm text-gray-900  border-custome rounded focus:ring-blue-500 focus:border-blue-500  outline-1"
                autoFocus
                placeholder="Add column Name"
                style={{ outlineWidth: "1px !important" }}
              />
            </span>
            {isError && (
              <p className="text-red-700 text-xs align-self-center">
                Special characters are not allowed
              </p>
            )}
            <span className="d-flex justify-center items-center space-x-3 py-2 mr-[60px]">
              <p className="m-0  whitespace-nowrap font-semibold text-sm self-center">
                Decimal Places:
              </p>
              <input
                type="number"
                value={newComputedColumn?.decimal}
                className=" block p-1  text-sm text-gray-900   border-custome  rounded focus:ring-blue-500 focus:border-blue-500 outline-1"
                style={{ outlineWidth: "1px !important" }}
                onChange={(e) => {
                  let newValue = e.target.value;
                  if (newValue !== "") {
                    newValue = newValue.replace(/^2+/, "") || 2;
                  }
                  if (parseInt(newValue) < 0) {
                    newValue = 0;
                  }
                  setNewComputedColumn({
                    ...newComputedColumn,
                    decimal: newValue,
                  });
                }}
              />
            </span>
            <span className="self-end mt-3 mb-3">
              <button
                onClick={() => {
                  updateTemplateWithComputedColumns();
                }}
                className="px-3 py-1 font-bold text-white rounded-md btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed"
              >
                Done
              </button>
            </span>
          </div>
        </ReusableReportModal>
      )}
      {open && (
        <ReusableReportModal
          open
          onClose={() => {
            setOpen(false);
            if (open) {
              setIsSaveClicked(false);
            }
          }}
          title={`Save ${component?.properties?.name} `}
        >
          {isSaveClicked ? (
            <div className="mx-2 my-2 d-flex flex-col">
              <span className="text-[15px] mt-1">
                {`Would you like to update the existing template or create a new one ?`}
              </span>
              <span className="self-end mt-2">
                <button
                  onClick={() => {
                    handleUpdateTamplate({ isUpdateData: true });
                  }}
                  className="px-3 py-1 font-bold text-white rounded-md bg-[#0a3a67] text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setOpen(false);
                    setIsCreateNewTemplate(true);
                  }}
                  className="px-3 py-1 font-bold text-white rounded-md bg-[#0a3a67] text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
                >
                  Create
                </button>
              </span>
            </div>
          ) : (
            <div className="mx-2 my-2 d-flex flex-col">
              <span className="text-[15px] mt-1">
                {`Do you want to add ${newCol} into existing template?`}
              </span>
              <div className="self-end mt-2">
                <button
                  onClick={() => {
                    handleUpdateTamplate({ isUpdateData: false });
                  }}
                  className="px-3 py-1 font-bold text-white rounded-md bg-[#0a3a67] text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setOpen(false);
                    setIsSaveClicked(false);
                  }}
                  className="px-3 py-1 font-bold text-white rounded-md bg-[#0a3a67] text-sm disabled:opacity-40 disabled:cursor-not-allowed m-2"
                >
                  No
                </button>
              </div>
            </div>
          )}
        </ReusableReportModal>
      )}
      {IsAddNewColumnOpen && (
        <ReusableReportModal
          open
          onClose={() => {
            setIsAddNewColumnOpen(false);
            setSelectedOperation(null);
            setComputedColumns({ colA: null, colB: null });
            setComputedColAListOpen(false);
            setComputedColBListOpen(false);
            setInputExpression("");
            setSelectedColumns("");
            setExpressionHistory([]);
            setRedoHistory([]);
            setActiveTab(0);
            setIsColumnEdited(false);
            setOriginalColumnName(null);
            setRedoExpression([])
          }}
          title={"Select Column Type You want to add"}
        >
          <div className="tabs-component">
            <div className="flex w-full border-b tabs-header justify-evenly">
              {["Data Columns", "Computed Column"].map((tab, idx) => (
                <button
                  key={idx}
                  className={`tab-button w-full py-2 px-4 focus:outline-none ${activeTab === idx
                    ? "border-blue-500 border-b-2 text-blue-500"
                    : ""
                    }`}
                  onClick={() => setActiveTab(idx)}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div className="tabs-content">
              <div
                className={`tab-content ${activeTab === 0 ? "" : "hidden"
                  } py-4`}
              >
                <div
                  className="px-3 text-sm no-scrollbar d-flex flex-col"
                  style={{
                    minWidth: "35rem",
                    maxHeight: "40vh",
                    overflow: "auto",
                  }}
                  fontColor="#105597"
                >
                  {Object.keys(component?.properties?.data[0])?.map(
                    (item, index) => (
                      <>
                        {/* xs=3 creates 4 columns (12/3=4) */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={component?.properties?.columns.includes(
                                item
                              )}
                              onChange={() =>
                                //  handleCheckboxChange(item)
                                handleColumnNameFilter(item)
                              }
                              sx={{
                                padding: "4px 9px",
                              }}
                            />
                          }
                          label={
                            <span className="whitespace-nowarp">{item}</span>
                          }
                        />
                      </>
                    )
                  )}
                </div>
                ,
              </div>
              <div
                className={`tab-content ${activeTab === 1 ? "" : "hidden"
                  } py-4`}
              >
                <div
                  className="mt-1  px-4 d-flex flex-col justify-between items-center"
                  style={{ minWidth: "35rem", minHeight: "36vh" }}
                >
                  <div className="d-flex grow flex-col gap-3">
                    {/* Content for "Computed Column" tab */}
                    <input
                      ref={inputRef}
                      type="text"
                      className="block px-2 py-2 w-72 md:w-96 p-1 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                      value={inputExpression}
                      //  readOnly
                      // onChange={(e) =>
                      //   handleInputChangeComputed(e.target.value)
                      // }
                      onChange={(e) => {
                        handleInputChangeComputed(e.target.value);
                        // setInputExpression(e.target.value)
                      }}
                    />
                    <div className="d-flex my-3 cursor-pointer justify-center">
                      {operations?.map((item) => {
                        return (
                          <div
                            className={`px-3 mx-1 py-2 my-1 mt-3 text-base font-bold hover:bg-slate-200 text-[#105597] rounded-md  ${selectedOperation?.name === item?.name
                              ? "bg-slate-300"
                              : "bg-slate-100"
                              }`}
                            // onClick={() => handleOperationSelect(item)}
                            onClick={() => {
                              if (
                                item.name === "OpenBracket" ||
                                item.name === "CloseBracket"
                              ) {
                                insertBracket(item.icon); // Handle bracket insertion
                              } else {
                                handleOperationSelect(item);
                              }
                            }}
                          >
                            {item?.icon}
                          </div>
                        );
                      })}
                      <Tooltip title="Undo">
                        <button
                          onClick={handleUndo}
                          disabled={expressionHistory.length === 0}
                          className="px-2  mx-1 my-1 mt-3 text-base font-bold disabled:opacity-40 text-white rounded-md bg-gray-500 hover:bg-gray-600 cursor-pointer"
                        >
                          <UndoIcon />
                        </button>
                      </Tooltip>
                      <Tooltip title="Redo">
                        <button
                          onClick={handleRedo}
                          disabled={redoHistory.length === 0}
                          className="px-2  mx-1 my-1 mt-3 text-base font-bold text-white disabled:opacity-40 rounded-md bg-gray-500 cursor-pointer hover:bg-gray-600"
                        >
                          <RedoIcon />
                        </button>
                      </Tooltip>
                    </div>
                    <div className={`d-flex flex-col items-center`}>
                      <div className={`d-flex sm:flex-row flex-col h-fit`}>
                        <span
                          className="relative flex  border border-gray-300 cursor-pointer  mt-1 flex-row items-center gap-2 divide-x-2 rounded-md w-72 md:w-96 "
                          ref={colADropdownRef}
                          onClick={() =>
                            setComputedColListOpen(!computedColListOpen)
                          }
                        >
                          <p className="m-0 py-2 px-2 w-full text-sm  text-gray-800 whitespace-nowrap">
                            Select Column
                          </p>
                          <button
                            className="pl-2 border-0"
                            onClick={() =>
                              setComputedColListOpen(!computedColListOpen)
                            }
                          >
                            <KeyboardArrowDownIcon className="text-gray-400"></KeyboardArrowDownIcon>
                          </button>
                          {computedColListOpen && (
                            <div
                              className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-10 z-10"
                              style={{
                                boxShadow:
                                  "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                              }}
                            >
                              {component?.properties?.columns.map(
                                (item, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      component?.properties?.columns.length -
                                      1 &&
                                      "border-b px-2 py-2 cursor-pointer text-sm"
                                    )}
                                    onClick={() => handleColumnSelect(item)}
                                  >
                                    {item}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={!inputExpression}
                    onClick={() => handleProceedOperation(inputExpression)}
                    // onClick={() => handleProceedOperation()}
                    className="px-4 py-1 my-1 mt-3 text-base font-bold text-white rounded-md bg-[#0a3a67] disabled:opacity-40 disabled:cursor-not-allowed"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReusableReportModal>
      )}
      <>
        <div className="flex flex-row items-start justify-start w-full gap-1 py-3 px-3 items-center">
          <p className="font-semibold text-sm  text-gray-500 m-0">Name:</p>
          <input
            type="text"
            className="block p-1 text-sm text-gray-900 bg-white border-custome rounded w-full  focus:ring-blue-500 focus:border-blue-500"
            value={component?.properties?.name || ""}
            onChange={(e) => {
              const newValue = e.target.value;
              onPropertyChange({ name: newValue });
              setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                  ...prev.properties,
                  name: newValue,
                },
              }));
              setFlag(false);
            }}
          />
        </div>
        {selectedTemplate == null ? (
          <>
            <SidebarSection
              title="Position"
              subSection={false}
              isOpen={openSection === "position"}
              onToggle={() => handleToggle("position")}
            >
              <span className="d-flex px-2 py-2 justify-center">
                <span className="d-flex flex-col">
                  <span className="d-flex py-2">
                    <p className="prop-label mb-1 self-end">Position (px):</p>
                    <div className="mx-2">
                      <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700">
                        Left
                      </p>
                      <input
                        type="number"
                        className=" border-custome block p-1 text-xs text-gray-900 bg-white rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                        value={Math?.round(component?.properties?.left)}
                        onChange={(e) => handlePositionChange(e, "left")}
                      />
                    </div>
                    <div className="mx-2">
                      <p className="m-0 font-medium text-tiny text-gray-700 whitespace-nowrap">
                        Top
                      </p>
                      <input
                        type="number"
                        className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                        value={Math?.round(component?.properties?.top)}
                        onChange={(e) => handlePositionChange(e, "top")}
                      />
                    </div>
                  </span>
                  <span className="d-flex py-2 self-end">
                    <div className="mx-2">
                      <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700">
                        Right
                      </p>
                      <input
                        type="number"
                        className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                        value={Math?.round(component?.properties?.right)}
                        onChange={(e) => handlePositionChange(e, "right")}
                      />
                    </div>
                    <div className="mx-2">
                      <p className="m-0 font-medium text-tiny text-gray-700 whitespace-nowrap">
                        Bottom
                      </p>
                      <input
                        type="number"
                        className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                        value={Math?.round(component?.properties?.bottom)}
                        onChange={(e) => handlePositionChange(e, "bottom")}
                      />
                    </div>
                  </span>
                </span>
              </span>
            </SidebarSection>
            <SidebarSection
              title="Appearance"
              subSection={false}
              isOpen={openSection === "appearance"}
              onToggle={() => handleToggle("appearance")}
            >
              <span className="justify-center px-2 py-2 pt-3 space-x-2 flex sm:flex-row lg:flex-row ">
                <span className="m-0 lg:self-end lg:pb-0 mb-1 sm:self-center sm:pb-7">
                  <p className="m-0 prop-label lg:self-end mb-0">Size (px):</p>
                </span>
                <span className="flex lg:flex-row sm:flex-col lg:space-x-2 sm:space-y-1 lg:space-y-0">
                  <div className="flex flex-col space-x-2">
                    <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700 pl-2">
                      Height
                    </p>
                    <input
                      type="number"
                      className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                      value={component?.properties?.height || 250}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue !== "") {
                          newValue = newValue.replace(/^0+/, "") || "0";
                        }
                        if (parseInt(newValue) < 0) {
                          newValue = "0";
                        }
                        onPropertyChange({ height: newValue });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            height: newValue,
                          },
                        }));
                        setFlag(false);
                      }}
                    />
                  </div>
                  <div className="flex flex-col space-x-2">
                    <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-700 pl-2">
                      Width
                    </p>
                    <input
                      type="number"
                      className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                      value={component?.properties?.width || 900}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue !== "") {
                          newValue = newValue.replace(/^0+/, "") || "0";
                        }
                        if (parseInt(newValue) < 0) {
                          newValue = "0";
                        }
                        onPropertyChange({ width: newValue });
                        setSelectedComponent((prev) => ({
                          ...prev,
                          properties: {
                            ...prev.properties,
                            width: newValue,
                          },
                        }));
                        setFlag(false);
                      }}
                    />
                  </div>
                </span>
              </span>

              <div className=" flex lg:flex-row sm:flex-col justify-center items-center space-x-4">
                <span className="flex  py-2 space-x-4 lg:ml-5 sm:ml-16">
                  <p className="m-0 prop-label whitespace-nowrap self-center">
                    Border:
                  </p>
                  <select
                    value={component?.properties?.borderType || "solid"}
                    onChange={(e) => {
                      onPropertyChange({ borderType: e.target.value });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          borderType: e.target.value,
                        },
                      }));
                      setFlag(false);
                    }}
                    className="block ml-2 p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={-1}>Select Border Type</option>
                    {["Solid", "Dotted", "Dashed", "Double", "None"].map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )}
                  </select>
                </span>
                <span
                  className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-20 lg:pr-0"
                  ref={borderColorRef}
                >
                  <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                    Border Color:
                  </p>
                  <span className="flex items-center border-custome ">
                    <div
                      className="w-6 h-6 sketch-border"
                      style={{
                        backgroundColor:
                          component?.properties?.borderColor || "#000000",
                      }}
                    />

                    <div
                      className="w-5 h-6 text-center cursor-pointer"
                      onClick={() => {
                        setColorPickerOpen(!colorPickerOpen);
                        setColorPickerBg(false);
                        setColorPickerText(false);
                      }}
                    >
                      <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                    </div>
                  </span>
                  {colorPickerOpen && (
                    <div className="absolute z-10 right-0 mt-[32px] cursor-pointer">
                      <SketchPicker
                        color={
                          component?.properties?.borderColor || "rgba(0,0,0,1)"
                        }
                        onChange={(color) => {
                          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                          onPropertyChange({ borderColor: rgbaColor });
                          setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                              ...prev.properties,
                              borderColor: rgbaColor,
                            },
                          }));
                          setFlag(false);
                        }}
                      />
                    </div>
                  )}
                </span>
              </div>

              <div className="d-flex justify-center py-2 space-x-4 lg:mr-[126px] sm:mr-6">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Border Width:
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.borderWidth || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ borderWidth: newValue });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        borderWidth: newValue,
                      },
                    }));
                    setFlag(false);
                  }}
                />
              </div>

              <div className="d-flex justify-center py-2 lg:mr-[70px] sm:ml-8 lg:ml-0 space-x-4">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Align:
                </p>
                <select
                  value={component?.properties?.align}
                  onChange={(e) => {
                    onPropertyChange({ align: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        align: e.target.value,
                      },
                    }));
                    setFlag(false);
                    //handleColumnPropertyChange(item.name, { align: e.target.value })
                  }}
                  className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={-1}>Select Align</option>
                  {["Center", "Left", "Right"].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div className="d-flex justify-center py-2 space-x-4 lg:mr-[196px] sm:mr-24">
                <p className="m-0 prop-label whitespace-nowrap  self-center">
                  Background Color:
                </p>
                <span className="relative" ref={backgroundColorRef}>
                  <div className="flex items-center border-custome ">
                    <div
                      className="w-6 h-6 sketch-border"
                      style={{
                        backgroundColor:
                          component?.properties?.backgroundColor || "#fff",
                      }}
                    />
                    <div
                      className="w-5 h-6 text-center cursor-pointer"
                      onClick={() => {
                        setColorPickerBg(!colorPickerBg);
                        setColorPickerOpen(false);
                        setColorPickerText(false);
                      }}
                    >
                      <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                    </div>
                  </div>
                  {colorPickerBg && (
                    <div className="absolute z-10   mt-2 cursor-pointer">
                      <SketchPicker
                        color={
                          component?.properties?.backgroundColor ||
                          "rgba(255,255,255,1)"
                        }
                        onChange={(color) => {
                          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                          onPropertyChange({ backgroundColor: rgbaColor });
                          setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                              ...prev.properties,
                              backgroundColor: rgbaColor,
                            },
                          }));
                          setFlag(false);
                        }}
                      />
                    </div>
                  )}
                </span>
              </div>

              <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-3">
                <span className="flex  py-2 space-x-4 lg:ml-[30px] sm:ml-[72px]">
                  <p className="m-0 prop-label whitespace-nowrap  self-center">
                    Font:
                  </p>
                  <select
                    value={component?.properties?.fontFamily}
                    onChange={(e) => {
                      onPropertyChange({ fontFamily: e.target.value });
                      setSelectedComponent((prev) => ({
                        ...prev,
                        properties: {
                          ...prev.properties,
                          fontFamily: e.target.value,
                        },
                      }));
                      setFlag(false);
                      //handleColumnPropertyChange(item.name, { align: e.target.value })
                    }}
                    className="block p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value={"Inter"}>Select Font Family</option>
                    {Fonts.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </span>
                <span
                  className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-16 lg:pr-0"
                  ref={textColorRef}
                >
                  <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                    Font Color:
                  </p>
                  <span className="flex items-center border-custome ">
                    <div
                      className="w-6 h-6 sketch-border"
                      style={{
                        backgroundColor:
                          component?.properties?.textColor || "#000000",
                      }}
                    />
                    <div
                      className="w-5 h-6 text-center cursor-pointer"
                      onClick={() => {
                        setColorPickerText(!colorPickerText);
                        setColorPickerBg(false);
                        setColorPickerOpen(false);
                      }}
                    >
                      <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                    </div>
                  </span>
                  {colorPickerText && (
                    <div className="absolute z-10 right-0 mt-[32px] cursor-pointer">
                      <SketchPicker
                        color={
                          component?.properties?.textColor || "rgba(0, 0, 0, 1)"
                        }
                        onChange={(color) => {
                          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                          onPropertyChange({ textColor: rgbaColor });
                          setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                              ...prev.properties,
                              textColor: rgbaColor,
                            },
                          }));
                          setFlag(false);
                        }}
                      />
                    </div>
                  )}
                </span>
              </div>

              <div className="d-flex justify-center py-2 space-x-4  lg:mr-[103px]">
                <p className="block m-0 prop-label whitespace-nowrap  self-center">
                  Font Size:
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px] focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.fontSize || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ fontSize: newValue });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        fontSize: newValue,
                      },
                    }));
                    setFlag(false);
                  }}
                />
              </div>

              <div className="d-flex justify-center py-2 pb-3 space-x-4 lg:mr-[68px] sm:ml-8 lg:ml-0">
                <p className="m-0  whitespace-nowrap prop-label self-center">
                  Font Weight:
                </p>
                <select
                  value={component?.properties?.fontWeight}
                  onChange={(e) => {
                    onPropertyChange({ fontWeight: e.target.value });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        fontWeight: e.target.value,
                      },
                    }));
                    setFlag(false);
                    //handleColumnPropertyChange(item.name, { align: e.target.value })
                  }}
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer  focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value={"Inter"}>Select Font Weight</option>
                  {fontWeights.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </SidebarSection>
          </>
        ) : (
          ""
        )}
        <SidebarSection
          title="Columns"
          subSection={false}
          isOpen={openSection === "columns"}
          onToggle={() => handleToggle("columns")}
        >
          <span className="d-flex flex-col gap-3 items-center justify-center px-2 py-2 pt-3">
            {selectedTemplate !== null && (
              <span className={`d-flex flex-col `}>
                <div className={`d-flex sm:flex-row flex-col h-fit`}>
                  <div
                    className="relative flex  border-custome  mt-1 flex-row items-center gap-2 divide-x-2 rounded w-72 md:w-96 "
                    ref={columnDropdownRef}
                  >
                    <span className="flex flex-row border-0 items-center w-full h-full py-1 gap-2 px-2 overflow-scroll flex-nowrap no-scrollbar border-gray-600 ">
                      <p className="m-0 text-sm text-gray-800 whitespace-nowrap">
                        Select Columns
                      </p>
                    </span>
                    <button
                      className="pl-2 border-0"
                      onClick={() => {
                        setColumnListOpen(!columnListOpen);
                      }}
                    >
                      <KeyboardArrowDownIcon className="text-gray-600"></KeyboardArrowDownIcon>
                    </button>
                    {columnListOpen && (
                      <div
                        className="absolute flex flex-col w-full overflow-auto custom-scrollbar bg-white border rounded-md max-h-52 top-9 z-10"
                        style={{
                          boxShadow:
                            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        {true ? (
                          <div className="d-flex px-1 py-2">
                            <input
                              type="text"
                              value={newCol}
                              onChange={(e) => {
                                setNewCol(e.target.value);
                              }}
                              className=" block  text-sm text-gray-900  border-custome rounded w-full focus:ring-blue-500 focus:border-blue-500 px-2 py-1 m-1  outline-1"
                              autoFocus
                              placeholder="Add column Name"
                              style={{ outlineWidth: "1px !important" }}
                            />
                            <select
                              value={newColType}
                              onChange={(e) => setNewColType(e.target.value)}
                              className="block text-sm text-gray-900 border-custome rounded outline-1 w-24 h-8 m-1"
                            >
                              <option value="VARCHAR" className="text-sm">
                                Text
                              </option>
                              <option value="INTEGER" className="text-sm">
                                Number
                              </option>
                              <option value="FLOAT" className="text-sm">
                                Float
                              </option>
                              <option value="DATE" className="text-sm">
                                Date
                              </option>
                              <option value="BOOLEAN" className="text-sm">
                                Boolean
                              </option>
                            </select>
                            <button
                              disabled={!newCol}
                              onClick={() => handleAddNewColumn()}
                              className="px-4 py-1 m-1 text-sm font-bold text-white rounded-md bg-[#105597] disabled:opacity-40 disabled:cursor-not-allowed"
                            >
                              Add
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                        {component?.properties?.columns
                          .concat(deselectedColumns)
                          .map((item, index) => (
                            // <div
                            //   className={classNames(
                            //     "hover:bg-primarylightgrey",
                            //     component?.properties?.columns.length - 1 &&
                            //     "border-b"
                            //   )}
                            // >
                            //   <CheckboxSelector
                            //     header={item}
                            //     isChecked={
                            //       selectedColumn.includes(item) ? true : false
                            //     }
                            //     handleChange={(_e) =>
                            //       handleColumnNameFilter(item)
                            //     }
                            //     isBold={false}
                            //   />

                            // </div>

                            <div
                              className={classNames(
                                "hover:bg-primarylightgrey",
                                component?.properties?.columns.length - 1 &&
                                "border-b",
                                "d-flex px-2 py-2 items-center gap-2"
                              )}
                            >
                              <label className="flex flex-row items-center gap-2 cursor-pointer">
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 checked:lightblue"
                                  checked={
                                    selectedColumn.includes(item) ? true : false
                                  }
                                  onChange={() => handleColumnNameFilter(item)}
                                />
                                <p
                                  className={classNames(
                                    "m-0 cursor-pointer text-sm font-base"
                                  )}
                                >
                                  {item}
                                </p>
                              </label>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </span>
            )}
            {selectedTemplate === null && (
              <div className="d-flex justify-center">
                <button
                  onClick={() => setIsAddNewColumnOpen(true)}
                  className="px-3 py-2  text-sm font-bold text-white rounded-md bg-[#105597] disabled:opacity-40 disabled:cursor-not-allowed properties-button"
                >
                  Add New Column
                </button>
              </div>
            )}
            <div className="w-full">
              {component?.properties?.columnProperties
                ?.filter((item) => selectedColumn.includes(item.name))
                .map((item, index) => (
                  <SidebarSection
                    title={item.name}
                    subSection={true}
                    isOpen={openItem === index}
                    onToggle={() => handleItemToggle(index)}
                  >
                    {selectedTemplate == null ? (
                      <>
                        <div className="flex justify-center py-2 space-x-3 pt-3 sm:ml-[50px] lg:ml-0">
                          <p className="m-0 prop-label whitespace-nowrap  self-center">
                            Name:
                          </p>
                          <input
                            type="text"
                            className="block p-1 text-xs text-gray-900 bg-white border-custome rounded sm:w-36 lg:w-fit focus:ring-blue-500 focus:border-blue-500"
                            value={item.name || ""}
                            onChange={(e) => {
                              //  handleColumnPropertyChange(item?.name, { name: e.target.value })
                              handleHeaderChange(item.name, e.target.value);
                            }}
                          />
                        </div>
                        <div className="d-flex justify-center py-2 lg:mr-[90px] sm:mr-2">
                          <div
                            className="d-flex"
                            style={{
                              opacity: item?.isIncoming === true ? "0.5" : "1",
                            }}
                          >
                            <p className="m-0  whitespace-nowrap prop-label self-center pr-3">
                              Type:
                            </p>
                            <Tooltip
                              title={
                                item?.isIncoming
                                  ? "You cannot change the type of the column"
                                  : ""
                              }
                              placement="top"
                            >
                              <select
                                value={item?.type}
                                onChange={(e) => {
                                  handleColumnPropertyChange(item.name, {
                                    type: e.target.value,
                                  });
                                }}
                                disabled={item?.isIncoming}
                                className="block p-1 text-gray-900 border-custome rounded text-xs  focus:ring-blue-500 focus:border-blue-500 outline-1 "
                              >
                                <option value="VARCHAR">Text</option>
                                <option value="INTEGER">Number</option>
                                <option value="FLOAT">Float</option>
                                <option value="DATE">Date</option>
                                <option value="BOOLEAN">Boolean</option>
                              </select>
                            </Tooltip>
                          </div>
                        </div>
                      </>
                    ) : (
                      <span className="flex lg:flex-row sm:flex-col justify-center py-3 space-x-3">
                        <div className="flex items-center space-x-2 sm:justify-center">
                          <p className="m-0 prop-label whitespace-nowrap self-center">
                            Name:
                          </p>
                          <input
                            type="text"
                            className="block p-1 text-xs text-gray-900 bg-white w-44 border-custome rounded focus:ring-blue-500 focus:border-blue-500"
                            value={item.name || ""}
                            onChange={(e) => {
                              handleHeaderChange(item.name, e.target.value);
                            }}
                          />
                        </div>
                        <div className="flex items-center space-x-2 sm:justify-center sm:mt-3 lg:mt-0 sm:pr-[102px] lg:pr-0">
                          <p className="m-0 whitespace-nowrap prop-label self-center">
                            Type:
                          </p>
                          <Tooltip
                            title={
                              item?.isIncoming
                                ? "You cannot change the type of the column"
                                : ""
                            }
                            placement="top"
                          >
                            <select
                              value={item?.type}
                              onChange={(e) => {
                                handleColumnPropertyChange(item.name, {
                                  type: e.target.value,
                                });
                              }}
                              disabled={item?.isIncoming}
                              className="block p-1 text-gray-900 border-custome rounded text-xs focus:ring-blue-500 focus:border-blue-500 outline-1"
                            >
                              <option value="VARCHAR">Text</option>
                              <option value="INTEGER">Number</option>
                              <option value="FLOAT">Float</option>
                              <option value="DATE">Date</option>
                              <option value="BOOLEAN">Boolean</option>
                            </select>
                          </Tooltip>
                        </div>
                      </span>
                    )}

                    {selectedTemplate === null && (
                      <>
                        <div className="d-flex justify-center py-2 space-x-3 lg:mr-[70px] sm:ml-3 lg:ml-0">
                          <p className="m-0  whitespace-nowrap prop-label self-center">
                            Align:
                          </p>
                          <select
                            value={
                              item?.align
                                ? item?.align
                                : component?.properties?.align
                            }
                            onChange={(e) => {
                              handleColumnPropertyChange(item.name, {
                                align: e.target.value,
                              });
                            }}
                            className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option value={-1}>Select Align</option>
                            {["Center", "Left", "Right"].map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="d-flex justify-center py-2 space-x-3 lg:mr-[198px] sm:mr-28">
                          <p className="m-0  whitespace-nowrap prop-label self-center">
                            Background Color:
                          </p>
                          <div className="ml-1">
                            <div
                              className="relative"
                              ref={(el) =>
                              (colorPickerRefs.current[item.name] = {
                                ...(colorPickerRefs.current[item.name] || {}),
                                backgroundColor: el,
                              })
                              }
                            >
                              <div className="flex items-center border-custome ">
                                <div
                                  className="w-6 h-6 sketch-border"
                                  style={{
                                    backgroundColor:
                                      item?.backgroundColor ||
                                      component?.properties?.backgroundColor,
                                  }}
                                />
                                <div
                                  className="w-5 h-6 text-center cursor-pointer"
                                  onClick={() => {
                                    handleColorPickerToggle(
                                      item.name,
                                      "backgroundColor"
                                    );
                                  }}
                                >
                                  <KeyboardArrowDownIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                </div>
                              </div>
                              {colorPickerState[item.name]?.backgroundColor && (
                                <div
                                  className="absolute z-10 bottom-0 mb-[32px]  cursor-pointer"
                                  ref={(el) =>
                                  (colorPickerRefs.current[item.name] = {
                                    ...(colorPickerRefs.current[item.name] ||
                                      {}),
                                    backgroundColor: el,
                                  })
                                  }
                                >
                                  <SketchPicker
                                    color={
                                      item.backgroundColor ||
                                      component?.properties?.backgroundColor
                                    }
                                    onChange={(color) => {
                                      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                      handleColumnPropertyChange(item.name, {
                                        backgroundColor: rgbaColor,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-3">
                          <span className="flex  py-2 space-x-3 lg:ml-[30px] sm:ml-14">
                            <p className="m-0  whitespace-nowrap prop-label self-center">
                              Font:
                            </p>
                            <select
                              value={
                                item?.fontFamily
                                  ? item?.fontFamily
                                  : component?.properties?.fontFamily
                              }
                              onChange={(e) => {
                                handleColumnPropertyChange(item.name, {
                                  fontFamily: e.target.value,
                                });
                              }}
                              className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
                            >
                              <option value={"Inter"}>
                                Select Font Family
                              </option>
                              {Fonts.map((item, index) => (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </span>
                          <div
                            className="relative flex sm:flex-row sm:space-x-3 lg:space-x-0 sm:py-2 sm:pr-20 lg:pr-0"
                            ref={(el) =>
                            (colorPickerRefs.current[item.name] = {
                              ...(colorPickerRefs.current[item.name] || {}),
                              textColor: el,
                            })
                            }
                          >
                            <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">
                              Font Color:
                            </p>
                            <span className="flex items-center border-custome">
                              <div
                                className="w-6 h-6 sketch-border"
                                style={{
                                  backgroundColor:
                                    item.textColor ||
                                    component?.properties?.textColor,
                                }}
                              />

                              <div
                                className="w-5 h-6 text-center cursor-pointer"
                                onClick={() => {
                                  handleColorPickerToggle(
                                    item.name,
                                    "textColor"
                                  );
                                }}
                              >
                                <KeyboardArrowDownIcon
                                  style={{ fontSize: "20px" }}
                                />
                              </div>
                            </span>
                            {colorPickerState[item.name]?.textColor && (
                              <div className="absolute z-10 bottom-0 right-0 mb-[38px] cursor-pointer">
                                <SketchPicker
                                  color={
                                    item.textColor ||
                                    component?.properties?.textColor
                                  }
                                  onChange={(color) => {
                                    const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                    handleColumnPropertyChange(item.name, {
                                      textColor: rgbaColor,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-center py-2 space-x-3 lg:mr-[103px] sm:mr-4">
                          <p className="block  m-0 prop-label whitespace-nowrap  self-center">
                            Font Size:
                          </p>
                          <input
                            type="number"
                            className="block p-1 text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                            value={
                              item?.fontSize
                                ? item?.fontSize
                                : component?.properties?.fontSize
                            }
                            onChange={(e) => {
                              let newValue = e.target.value;
                              if (newValue !== "") {
                                newValue = newValue.replace(/^0+/, "") || "0";
                              }
                              if (parseInt(newValue) < 0) {
                                newValue = "0";
                              }
                              handleColumnPropertyChange(item.name, {
                                fontSize: newValue,
                              });
                            }}
                          />
                        </div>
                        <div className="d-flex justify-center pt-2 pb-2 space-x-3 lg:mr-[70px] lg:ml-0 sm:ml-4">
                          <p className="m-0 prop-label whitespace-nowrap  self-center">
                            Font Weight:
                          </p>
                          <select
                            value={
                              item?.fontWeight
                                ? item?.fontWeight
                                : component?.properties?.fontWeight
                            }
                            onChange={(e) => {
                              handleColumnPropertyChange(item.name, {
                                fontWeight: e.target.value,
                              });
                            }}
                            className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option value={"Inter"}>Select Font Weight</option>
                            {fontWeights.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {item.isComputedColumn && (
                          <div className="d-flex justify-center pt-2 pb-3 space-x-3 lg:ml-[60px]  sm:ml-[52px]">
                            <p className="m-0 prop-label whitespace-nowrap  self-center">
                              Formula:
                            </p>
                            <Tooltip title={item.expression || ""}>
                              <input
                                type="text"
                                className="block p-1 text-xs text-gray-900 bg-white w-full lg:w-52 sm:w-28 border-custome rounded focus:ring-blue-500 focus:border-blue-500"
                                value={item.expression || ""}
                                onChange={(e) => {
                                  handleHeaderChange(item.name, e.target.value);
                                }}
                                disabled={!isEditing}
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              />
                            </Tooltip>
                            <span className="report-builder-nav-icon">
                              <Tooltip title={"Edit"}>
                                <DriveFileRenameOutlineIcon
                                  className="mx-1"
                                  onClick={() =>
                                    handleEdit(item.expression, item.name)
                                  }
                                />
                              </Tooltip>
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </SidebarSection>
                ))}
            </div>
          </span>
        </SidebarSection>
        <SidebarSection
          title="Rows"
          subSection={false}
          isOpen={openSection === "rows"}
          onToggle={() => handleToggle("rows")}
        >
          <div className="d-flex flex-col gap-3 items-center justify-center px-2 py-2">
            <div className={`d-flex flex-col `}>
              <div className={`d-flex  flex-col h-fit `}>
                <button
                  onClick={() => setIsAddRowOpen(true)}
                  className="px-3 py-1 mb-3 w-40 text-sm font-bold self-center text-white rounded-md bg-[#105597] disabled:opacity-40 disabled:cursor-not-allowed m-2 properties-button"
                >
                  Add Row
                </button>
                {isAddRowOpen && (
                  <div className="bg-[#10559714] px-2 py-2 rounded-sm mb-2 d-flex flex-col">
                    {component?.properties?.columns?.map((item) => (
                      <div className="d-flex mt-1">
                        <p className=" w-1/2 prop-label m-0 whitespace-nowrap">
                          {item} :
                        </p>
                        <input
                          type="text"
                          className="block p-1 pl-1 ml-2 text-xs text-gray-900 bg-white border border-gray-300 rounded-lg w-2/3  focus:ring-blue-500 focus:border-blue-500"
                          value={tempRow[item]}
                          onChange={(e) =>
                            handleInputChange(item, e.target.value)
                          }
                        />
                      </div>
                    ))}
                    <button
                      onClick={handleAddRow}
                      className="px-4 py-1 font-bold self-center w-20 text-sm text-white rounded-md bg-[#105597] disabled:opacity-40 disabled:cursor-not-allowed mt-3 mb-2 properties-button"
                    >
                      Add
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </SidebarSection>
        {selectedTemplate == null && (
          <SidebarSection
            title="Advance"
            subSection={false}
            isOpen={openSection === "advance"}
            onToggle={() => handleToggle("advance")}
          >
            <span className="flex justify-center  pb-2 pt-3 ">
              <p className="m-0  whitespace-nowrap prop-label self-end mb-2 pr-2">
                Indent (pt):
              </p>
              <span className="mx-2">
                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600 ">
                  Left
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.paddingLeft || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ paddingLeft: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        paddingLeft: Number(newValue),
                      },
                    }));
                  }}
                />
              </span>
              <span className="mx-2">
                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">
                  Right
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.paddingRight || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ paddingRight: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        paddingRight: Number(newValue),
                      },
                    }));
                  }}
                />
              </span>
            </span>
            <span className="flex justify-center pb-2 pt-1 mr-2 ">
              <p className="m-0 prop-label whitespace-nowrap  self-end mb-2 pr-2">
                Spacing (pt):
              </p>
              <span className="mx-2">
                <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                  Before
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.paddingTop || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ paddingTop: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        paddingTop: Number(newValue),
                      },
                    }));
                  }}
                />
              </span>
              <span className="mx-2">
                <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                  After
                </p>
                <input
                  type="number"
                  className="block p-1 text-xs text-gray-900 bg-white border-custome rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                  value={component?.properties?.paddingBottom || 0}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue !== "") {
                      newValue = newValue.replace(/^0+/, "") || "0";
                    }
                    if (parseInt(newValue) < 0) {
                      newValue = "0";
                    }
                    onPropertyChange({ paddingBottom: Number(newValue) });
                    setSelectedComponent((prev) => ({
                      ...prev,
                      properties: {
                        ...prev.properties,
                        paddingBottom: Number(newValue),
                      },
                    }));
                  }}
                />
              </span>
            </span>
            <div className="d-flex justify-center py-2 pb-3 space-x-4 lg:mr-[115px] sm:mr-16">
              <p className="m-0 prop-label whitespace-nowrap  self-center">
                Vertical Align:
              </p>
              <select
                value={component?.properties?.verticalAlign}
                onChange={(e) => {
                  onPropertyChange({ verticalAlign: e.target.value });
                  setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                      ...prev.properties,
                      verticalAlign: e.target.value,
                    },
                  }));
                  setFlag(false);
                }}
                className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
              >
                <option value={-1}>Select Align</option>
                {["Middle", "Top", "Bottom"].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </SidebarSection>
        )}
      </>
    </>
  );
}


export default TableProperties;
