import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const ExcelIcon = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.height}
            height={props.width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            fill="none"
            // stroke="#075985"
            stroke={props.strokeColor}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polyline className="cls-1" points="11.82 18.5 15.5 24 11.82 29.5" /><polyline className="cls-1" points="19.18 18.5 15.5 24 19.18 29.5" />
            <path className="cls-1" d="M5.5,16V32a2,2,0,0,0,2,2h16a2,2,0,0,0,2-2V16a2,2,0,0,0-2-2H7.5A2,2,0,0,0,5.5,16Z" />
            <path className="cls-1" d="M13.23,14V6.5a2,2,0,0,1,2-2H40.5a2,2,0,0,1,2,2v35a2,2,0,0,1-2,2H15.23a2,2,0,0,1-2-2V34" />
        </svg>

    </Tooltip>
);

export default ExcelIcon;

