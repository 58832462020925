import React, { useState } from "react";
import classNames from "classnames";
import { RightArrow_Icon } from "../../../../assets/images";
import VerifyEmail from "../../common/VerifyEmail";
import Auth from "../../common/Auth";
import OrganisationInfo from "../../common/OrganisationInfo";
import BusinessInfo from "../../common/BusinessInfo";
import AccountInfo from "../../common/AccountInfo";
import { useLocation } from "react-router-dom";
import EnterOtherEmail from "../../common/EnterOtherEmail";
import RightBanner from "../../common/RightBanner";
import AuthBanner from "../../common/AuthBanner";
import PricingPlans from "../../common/PricingPlans";
const steps = [
  "Signup",
  "Verify Email",
  "Account Info",
  "Business Info",
  // "Scheduling Info",
  "Select Plan",
  // "Get Started",
];

const OnboardingSteps = ({ handlePlansModalOpen }) => {
  const location = useLocation();
  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const step = queryParams.get("step");
  const decodedString = atob(step);
  const decodedNumber = parseInt(decodedString, 10);
  const [currentStep, setCurrentStep] = useState(step ? decodedNumber : -1);
  const [email, setEmail] = useState("");

  const handleStepChange = (index) => {
    if (index === 4) {
      handlePlansModalOpen(true);
      return;
    }
    setCurrentStep(index);
  };

  return (
    <>
      <div
        className={
          "sm:w-7/12 w-full overflow-y-auto" +
          " flex flex-col  h-screen sm:px-16 px-8 pt-4  overflow-x-clip no-scrollbar"
        }
      >
        {currentStep > 1 && (
          <div className="flex flex-row items-start w-full gap-2">
            {steps.slice(2).map((step, index) => (
              <div key={index} className="flex flex-row items-center gap-2">
                <div
                  className={classNames(
                    "flex flex-row text-xs items-center whitespace-nowrap justify-center w-fit h-8 font-semibold cursor-pointer transition-all duration-300 ease-in-out",
                    index === currentStep - 2
                      ? "bg-primaryblue text-white px-2 rounded-lg"
                      : "text-black"
                  )}
                >
                  {step}
                </div>
                {index !== 2 && (
                  <img
                    src={RightArrow_Icon}
                    alt="search"
                    className="rotate-22.5 cursor-pointer search-icon"
                  />
                )}
              </div>
            ))}
          </div>
        )}
        <div className="relative w-full">
          <div
            className={classNames(
              "absolute top-0 z-50 w-full transition-all ease-in-out",
              currentStep === -1 && "block",
              currentStep > -1 && "-translate-x-full right-28 hidden",
              currentStep < -1 && "translate-x-full left-28 hidden"
            )}
          >
            <Auth
              option={"signup"}
              handleStepChange={handleStepChange}
              step={currentStep}
              email={email}
              setEmail={setEmail}
            />
          </div>
          <div
            className={classNames(
              "absolute top-0 z-50 w-full transition-all ease-in-out",
              currentStep === 0 && "block",
              currentStep > 0 && "-translate-x-full right-28 hidden",
              currentStep < 0 && "translate-x-full left-28 hidden"
            )}
          >
            <EnterOtherEmail
              option={"signup"}
              handleStepChange={handleStepChange}
              step={currentStep}
              email={email}
              setEmail={setEmail}
            />
          </div>
          <div
            className={classNames(
              "absolute top-0 z-50 w-full transition-all ease-in-out",
              currentStep === 1 && "block",
              currentStep > 1 && "-translate-x-full right-28 hidden",
              currentStep < 1 && "translate-x-full left-28 hidden"
            )}
          >
            <VerifyEmail
              email={email}
              handleStepChange={handleStepChange}
              step={currentStep}
            />
          </div>
          <div
            className={classNames(
              "absolute top-0 z-50 w-full transition-all ease-in-out",
              currentStep === 2 && "block",
              currentStep > 2 && "-translate-x-full right-28 hidden",
              currentStep < 2 && "translate-x-full left-28 hidden"
            )}
          >
            <AccountInfo
              email={email}
              handleStepChange={handleStepChange}
              step={currentStep}
            />
          </div>
          <div
            className={classNames(
              "absolute top-0 z-50 w-full transition-all duration-300 ease-in-out",
              currentStep === 3 && "block",
              currentStep > 3 && "-translate-x-full right-28 hidden",
              currentStep < 3 && "translate-x-full left-28 hidden"
            )}
          >
            <BusinessInfo
              userEmail={email}
              handleStepChange={handleStepChange}
              step={currentStep}
            />
          </div>
          {/* <div
            className={classNames(
              "absolute top-0 z-50 w-full transition-all duration-300 ease-in-out",
              currentStep === 4 && "block",
              currentStep > 4 && "-translate-x-full right-28 hidden",
              currentStep < 4 && "translate-x-full left-28 hidden"
            )}
          >
            <OrganisationInfo />
          </div> */}
        </div>
      </div>
      {currentStep == -1 ? <AuthBanner option={"signup"} /> : ""}
      {currentStep >= 0 ? <RightBanner /> : ""}
    </>
  );
};

export default OnboardingSteps;
