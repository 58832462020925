import * as React from "react";

import PropTypes from "prop-types";
import Tooltip from '@mui/material/Tooltip';

const SampleDataIcon = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.width} // Ensure props are used correctly
            height={props.height} // Ensure props are used correctly
            //  viewBox="0 0 1024 1024"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
        //  className="icon" // Corrected from class to className
        >
            <path
                d="M692.992 375.616v-51.2c0-3.52-1.92-6.4-4.224-6.4H335.232c-2.368 0-4.224 2.88-4.224 6.4v51.2c0 3.52 1.92 6.4 4.224 6.4h353.536c2.368 0 4.224-2.88 4.224-6.4z m-360.96 168.896h141.888c0.896 0 1.664-2.88 1.664-6.4v-51.2c0-3.52-0.768-6.4-1.664-6.4H332.096c-0.96 0-1.728 2.88-1.728 6.4v51.2c0 3.52 0.768 6.4 1.728 6.4z m-2.048 156.096c0 3.52 0.768 6.4 1.728 6.4H474.24c0.96 0 1.728-2.88 1.728-6.4v-51.2c0-3.52-0.768-6.4-1.728-6.4H331.712c-0.96 0-1.728 2.88-1.728 6.4v51.2zM220.032 150.976h583.936v262.016c0 4.48 3.584 8 8.064 8h55.936a8 8 0 0 0 8.064-8V147.008c0-37.568-30.464-68.032-68.032-68.032H216.32c-37.696 0-68.288 30.912-68.288 68.928V876.16c0 38.016 30.592 68.928 68.288 68.928h257.664a8 8 0 0 0 8-8v-56a8 8 0 0 0-8-8H220.032V150.976zM868.992 480H574.976a32 32 0 0 0-32 32v414.976a32 32 0 0 0 51.52 25.408l127.488-97.856 127.552 97.92a32 32 0 0 0 51.456-25.472V512a32 32 0 0 0-32-32z m-32 382.08l-95.488-73.216a32 32 0 0 0-39.04 0L606.976 862.08V544h230.016v318.08z"
                fill={props.fill}
            />
        </svg>
    </Tooltip>
);

SampleDataIcon.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    // tooltipText: PropTypes.string.isRequired,
};

export default SampleDataIcon;
