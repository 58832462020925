import { Tooltip } from "@mui/material";
import React from "react";
import { Search_Icon } from "../../assets/images";
import classNames from "classnames";

const Search = ({ value, setValue, placeholder, customWidth }) => {
  return (
    <div
      className={classNames(
        `relative flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip`,
        !customWidth ? "w-1/3" : ""
      )}
    >
      <div className="pl-2">
        <Tooltip title="Search">
          <img
            src={Search_Icon}
            alt="search"
            className="w-5 h-5 cursor-pointer search-icon opacity-80"
          />
        </Tooltip>
      </div>
      <input
        type="text"
        placeholder={placeholder || "Search..."}
        className="w-full h-full p-2 text-base outline-none"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

export default Search;
