import React, { useRef, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckboxSelector from "../../../Schedules/components/CheckboxSelector";
import SearchIcon from "@mui/icons-material/Search";
import { Icons } from "../../../../assets";
import { Tooltip } from "@mui/material";

function ApptCancleReportTable({
  cancellationReports,
  setCancellationReports,
  providers,
  filteredPv,
  setFilteredPv,
  selectedProviders,
  handleProviderFilter,
  speciality,
  selectedSpeciality,
  handleSpecialityFilter,
  filteredSpe,
  setFilteredSpe,
  isSelecetdValue,
  visitType,
  selectedVisitType,
  handleVisitTypeFilter,
  isSelectAllVisitType,
  setIsSelectAllVisitType,
  isSelectAllProviders,
  setIsSelectAllProviders,
  isSelectAllSpe,
  setIsSelectAllSpe,
}) {
  const dropdownRef = useRef(null);
  const dropdownRefSpe = useRef(null);
  const dropdownRefVisit = useRef(null);

  const [providerListOpen, setProviderListOpen] = useState(false);

  const [visitTypeListOpen, setVisitTypeListOpen] = React.useState(false);
  const [speListOpen, setSpeListOpen] = useState(false);

  const [isApptDtAse, setIsAppDtAse] = React.useState(true);
  const [isPatientIdAse, setIsPatientIdAse] = React.useState(true);
  const [isBillingAse, setIsBillingAse] = React.useState(true);

  const [providerNameSearch, setProviderNameSearch] = useState("");
  const [speNameSearch, setSpeNameSearch] = useState("");

  const handleProviderListOpen = () => {
    setProviderListOpen(!providerListOpen);
    setSpeListOpen(false);
    setVisitTypeListOpen(false);
    if (providerListOpen) {
      setProviderNameSearch("");
      setFilteredPv(providers);
    }
    setSpeNameSearch("");
    setFilteredSpe(speciality);
  };

  const handleSpeListOpen = () => {
    setSpeListOpen(!speListOpen);
    setProviderListOpen(false);
    setVisitTypeListOpen(false);
    setProviderNameSearch("");
    setFilteredPv(providers);
    if (speListOpen) {
      setSpeNameSearch("");
      setFilteredSpe(speciality);
    }
  };
  const handleVisitTypeListOpen = () => {
    setVisitTypeListOpen(!visitTypeListOpen);
    setProviderListOpen(false);
    setSpeListOpen(false);
    setProviderNameSearch("");
    setFilteredPv(providers);
    setSpeNameSearch("");
    setFilteredSpe(speciality);
  };
  const handleSelectAllProviders = () => {
    handleProviderFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllProviders,
    });
    setIsSelectAllProviders(!isSelectAllProviders);
  };
  const handleSelectAllSpe = () => {
    handleSpecialityFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllSpe,
    });
    setIsSelectAllSpe(!isSelectAllSpe);
  };
  const handleSelectAllVisitType = () => {
    handleVisitTypeFilter(-1, {
      selectAll: true,
      isSelectAll: !isSelectAllVisitType,
    });
    setIsSelectAllVisitType(!isSelectAllVisitType);
  };
  const TABLE_HEADERS = [
    "Appt Date",
    "Provider Name",
    "Speciality",
    "Patient Id",
    "Patient Name",
    "Visit Type",
    "Reason For Cancellation",
    "Expected Billing",
  ];

  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };

  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(",")
        : type === "date"
          ? moment(value).format("MM/DD/YYYY")
          : value;
  };
  const handleClickOutside = (event) => {
    if (
      dropdownRefSpe.current &&
      !dropdownRefSpe.current.contains(event.target)
    ) {
      setSpeListOpen(false);
      setFilteredSpe(speciality);
      setSpeNameSearch("");
    }
    if (
      dropdownRefVisit.current &&
      !dropdownRefVisit.current.contains(event.target)
    ) {
      setVisitTypeListOpen(false);
    }
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setProviderListOpen(false);
      setProviderNameSearch("");
      setFilteredPv(providers);
    }
  };
  React.useEffect(() => {
    if (speListOpen || providerListOpen || visitTypeListOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [speListOpen, providerListOpen, visitTypeListOpen]);
  // React.useEffect(() => {
  //   if (!isSelecetdValue) {
  //     setProviderListOpen(false);
  //     setSpeListOpen(false);
  //     setProviderListOpen(false);
  //     setVisitTypeListOpen(false);
  //   }
  // }, [isSelecetdValue]);
  React.useEffect(() => {
    setIsAppDtAse(true);
    setIsBillingAse(true);
    setIsPatientIdAse(true);
    selectedProviders.length === providers.length
      ? setIsSelectAllProviders(true)
      : setIsSelectAllProviders(false);
    selectedSpeciality.length === speciality.length
      ? setIsSelectAllSpe(true)
      : setIsSelectAllSpe(false);
    selectedVisitType.length === visitType.length
      ? setIsSelectAllVisitType(true)
      : setIsSelectAllVisitType(false);
  }, [selectedProviders, selectedSpeciality, selectedVisitType]);
  return (
    <>
      <div className="h-full overflow-auto mt-2 w-full">
        <table
          className="w-full"
          style={{ whiteSpace: "nowrap" }}
          id="cancellationReport"
        >
          <thead>
            <tr className="bg-primarylightgrey">
              {TABLE_HEADERS.map((header, index) => {
                if (header === "Provider Name") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (selectedProviders.length > 0 || providerListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRef}
                    >
                      <div className="flex flex-row  items-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button
                            className="pr-2"
                            onClick={handleProviderListOpen}
                          >
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>{" "}
                        {providerListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 left-0">
                            <div className="d-flex align-center sticky top-0 bg-white">
                              <input
                                type="search"
                                value={providerNameSearch}
                                onChange={(e) => {
                                  setProviderNameSearch(e.target.value);
                                  setFilteredPv(
                                    providers.filter((provider) => {
                                      return provider.name
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase());
                                    })
                                  );
                                }}
                                className="search-input"
                                autoFocus
                                placeholder="Search"
                              />
                              {providerNameSearch === "" ? (
                                <SearchIcon className="search-icon-input" />
                              ) : (
                                ""
                              )}
                            </div>
                            {filteredPv.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllProviders ||
                                        selectedProviders.length ===
                                        providers.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllProviders();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {filteredPv.map((provider, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      providers.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={provider.name}
                                      isChecked={
                                        selectedProviders.includes(provider.id)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleProviderFilter(provider.id, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllProviders,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Providers Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Speciality") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (selectedSpeciality.length > 0 || speListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRefSpe}
                    >
                      <div className="flex flex-row justify-center items-center">
                        <p className="m-0">
                          {header}
                          <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button className="pr-2" onClick={handleSpeListOpen}>
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>{" "}
                        {speListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16">
                            <div className="d-flex align-center sticky top-0 bg-white">
                              <input
                                type="search"
                                value={speNameSearch}
                                onChange={(e) => {
                                  setSpeNameSearch(e.target.value);
                                  setFilteredSpe(
                                    speciality.filter((spe) => {
                                      return spe
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase());
                                    })
                                  );
                                }}
                                className="search-input"
                                autoFocus
                                placeholder="Search"
                              />
                              {speNameSearch === "" ? (
                                <SearchIcon className="search-icon-input" />
                              ) : (
                                ""
                              )}
                            </div>
                            {filteredSpe.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllSpe ||
                                        selectedSpeciality.length ===
                                        speciality.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllSpe();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {filteredSpe.map((spe, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      speciality.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={spe}
                                      isChecked={
                                        selectedSpeciality.includes(spe)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleSpecialityFilter(spe, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllSpe,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Specialities Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Visit Type") {
                  return (
                    <th
                      key={index}
                      className={
                        "px-2 py-3 text-base relative min-w-56 " +
                        (selectedVisitType.length > 0 || visitTypeListOpen
                          ? "bg-highlight"
                          : "")
                      }
                      ref={dropdownRefVisit}
                    >
                      <div className="flex flex-row items-center justify-center">
                        <p className="m-0">
                          {header} <span className="dropdown-icon ml-3"></span>
                        </p>
                        <Tooltip title="Sort">
                          <button
                            className="pr-2"
                            onClick={handleVisitTypeListOpen}
                          >
                            <img
                              src={Icons.Sort_Icon}
                              alt="search"
                              className="w-5 h-5 cursor-pointer search-icon"
                            />
                          </button>
                        </Tooltip>
                        {visitTypeListOpen && (
                          <div className="absolute z-50 flex flex-col w-full overflow-auto no-scrollbar bg-white border  max-h-52 top-16 ">
                            {visitType.length > 0 ? (
                              <>
                                <th
                                  className={classNames(
                                    "px-4 py-3 cursor-pointer text-sm"
                                  )}
                                >
                                  <label className="flex flex-row items-center gap-2 cursor-pointer">
                                    <input
                                      type="checkbox"
                                      className="w-4 h-4 checked:lightblue"
                                      checked={
                                        isSelectAllVisitType ||
                                        selectedVisitType.length ===
                                        visitType.length
                                      }
                                      onChange={(e) => {
                                        handleSelectAllVisitType();
                                      }}
                                    />
                                    <p
                                      className={classNames(
                                        "m-0 cursor-pointer text-sm font-bold"
                                      )}
                                    >
                                      Select All
                                    </p>
                                  </label>
                                </th>
                                {visitType.map((type, index) => (
                                  <div
                                    className={classNames(
                                      "hover:bg-primarylightgrey",
                                      visitType.length - 1 && "border-b"
                                    )}
                                  >
                                    <CheckboxSelector
                                      header={type.name}
                                      isChecked={
                                        selectedVisitType.includes(type.id)
                                          ? true
                                          : false
                                      }
                                      handleChange={(_e) =>
                                        handleVisitTypeFilter(type.id, {
                                          selectAll: false,
                                          isSelectAll: !isSelectAllVisitType,
                                        })
                                      }
                                      isBold={false}
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-record-found">
                                No Visit Types Found
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  );
                } else if (header === "Appt Date") {
                  return (
                    <th key={index} className="px-2 py-3 text-base min-w-40">
                      <div className="d-flex items-center flex-row relative">
                        <div className="d-flex align-items-center w-full justify-center">
                          <p className="m-0">{header}</p>
                          {isApptDtAse ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDropUpIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                  top: "-3px",
                                }}
                                onClick={() => {
                                  setIsAppDtAse(false);
                                  const result = [...cancellationReports].sort(
                                    (a, b) =>
                                      new Date(a.visit_date) -
                                      new Date(b.visit_date)
                                  );
                                  setCancellationReports(result);
                                }}
                                value="asc"
                                className="arrow-up"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sort Ascending">
                              <ArrowDropDownIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                  top: "-2px",
                                }}
                                value="des"
                                className="arrow-down"
                                onClick={() => {
                                  setIsAppDtAse(true);
                                  const result = [...cancellationReports].sort(
                                    (a, b) =>
                                      new Date(b.visit_date) -
                                      new Date(a.visit_date)
                                  );
                                  setCancellationReports(result);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                } else if (header === "Patient Id") {
                  return (
                    <th key={index} className="px-2 py-3 text-base">
                      <div className="d-flex  items-center flex-row  jusify-center relative ">
                        <div className="d-flex align-items-center w-full justify-center">
                          <p className="m-0">{header}</p>
                          {isPatientIdAse ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDropUpIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                  top: "-2px",
                                }}
                                onClick={() => {
                                  setIsPatientIdAse(false);
                                  const result = [...cancellationReports].sort(
                                    (a, b) => a.patient_id - b.patient_id
                                  );
                                  setCancellationReports(result);
                                }}
                                value="asc"
                                className="arrow-up"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sort Ascending">
                              <ArrowDropDownIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                  top: "-2px",
                                }}
                                value="des"
                                className="arrow-down"
                                onClick={() => {
                                  setIsPatientIdAse(true);
                                  const result = [...cancellationReports].sort(
                                    (a, b) => b.patient_id - a.patient_id
                                  );
                                  setCancellationReports(result);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                } else if (header === "Expected Billing") {
                  return (
                    <th key={index} className="px-2 py-3 text-base min-w-52">
                      <div className="d-flex flex-row  items-center  jusify-center relative ">
                        <div className="d-flex align-items-center w-full justify-center">
                          <p className="m-0">{header}</p>
                          {isBillingAse ? (
                            <Tooltip title="Sort Descending">
                              <ArrowDropUpIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                  top: "-2px",
                                }}
                                onClick={() => {
                                  setIsBillingAse(false);
                                  const result = [...cancellationReports].sort(
                                    (a, b) => a.rate - b.rate
                                  );
                                  setCancellationReports(result);
                                }}
                                value="asc"
                                className="arrow-up"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sort Ascending">
                              <ArrowDropDownIcon
                                style={{
                                  fontSize: 30,
                                  color: "#212529",
                                  cursor: "pointer",
                                  top: "-2px",
                                }}
                                value="des"
                                className="arrow-down"
                                onClick={() => {
                                  setIsBillingAse(true);
                                  const result = [...cancellationReports].sort(
                                    (a, b) => b.rate - a.rate
                                  );
                                  setCancellationReports(result);
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className="px-2 py-3 text-base">
                      <div className="flex flex-row items-center">
                        <p className="m-0">{header}</p>
                      </div>
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          {cancellationReports.length > 0 ? (
            <tbody>
              {cancellationReports.map((cancellation, index) => {
                const isEven = (index + 1) % 2 === 0;
                return (
                  <tr
                    className={`text-base border-bottom text-left text-gray-900 border-bottom ${isEven ? "bg-primarylightgrey" : ""
                      }`}
                  >
                    <td className="px-2 py-3">
                      {parseNull(cancellation.visit_date, "NA", "date")}
                    </td>
                    <td className="px-2 py-3  reportnamecolumn ">
                      {parseNull(cancellation.provider_name)}
                    </td>
                    <td className="px-2 py-3">
                      {parseNull(cancellation.provider_type)}
                    </td>
                    <td className="px-2 py-3">
                      {parseNull(cancellation.patient_id)}
                    </td>
                    <td className="px-2 py-3 reportnamecolumn">
                      {parseNull(cancellation.patient_name)}
                    </td>
                    <td className="px-2 py-3">
                      {parseNull(cancellation.visit_type)}
                    </td>
                    <td className="px-2 py-3">
                      {parseNull(cancellation.cancel_reason)}
                    </td>
                    <td className="px-2 py-3">
                      ${parseNull(cancellation.rate)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <div
                className="d-flex align-Items-center justify-content-center absolute w-full my-5 py-3"
                style={{ zIndex: 0 }}
              >
                <p className="text-xl font-bold text-primarygrayborder">
                  {" "}
                  No Records Found
                </p>
              </div>
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}

export default ApptCancleReportTable;
