import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
// import reportWebVitals from "./reportWebVitals";

import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { SocketProvider } from "./context/SocketProvider";
import { SupersetProvider } from "./context/superset";

TimeAgo.addDefaultLocale(en);

const root = ReactDOM.createRoot(document.getElementById("root"));

let persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SupersetProvider>
        <PersistGate loading={null} persistor={persistor}>
          <SocketProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </BrowserRouter>
          </SocketProvider>
        </PersistGate>
      </SupersetProvider>
    </Provider>
  </React.StrictMode>
);

// reportWebVitals();
