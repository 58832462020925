import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  visits: [],
  visitsCount: [],
  providersCount: [],
  isPublishEnabled: false,
  isEditEnabled: false,
  lockedUser: null,
  eligibleVisits: [],
  loading: false,
};

export const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    addProvider: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    replaceProviders: (state, action) => {
      state.data = action.payload.data || [];
      state.count = action.payload.count || 0;
    },
    replaceVisits: (state, action) => {
      state.visits = action.payload || [];
    },
    replaceVisitsCount: (state, action) => {
      state.visitsCount = action.payload || [];
    },
    replaceProvidersCount: (state, action) => {
      state.providersCount = action.payload || [];
    },
    updateProvidersCount: (state, action) => {
      const providers = state.data?.map((provider) => {
        if (provider.ProviderID == action.payload.id) {
          return { ...provider, liveTracking: action.payload };
        }
        return provider;
      });
      state.data = providers || [];
    },
    replaceEligibleVisits: (state, action) => {
      state.eligibleVisits = action.payload || [];
    },
    updateLoading: (state, action) => {
      state.loading = action.payload || [];
    },
    setIsPublishEnabled: (state, action) => {
      state.isPublishEnabled = action.payload || false;
    },
    setProviderLock: (state, action) => {
      state.isEditEnabled = !!action.payload;
      state.lockedUser = action.payload || null;
    },
    updateProvider: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.ProviderID && i.ProviderID === action.payload.ProviderID) {
          return { ...i, ...action.payload };
        }
        return i;
      });
      state.data = tmpUser || [];
    },
    deleteProvider: (state, action) => {
      const tmpUser = state.data.filter(
        (i) => i.ProviderID !== action.payload.ProviderID
      );
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const {
  addProvider,
  updateProvider,
  replaceProviders,
  replaceVisits,
  replaceVisitsCount,
  replaceProvidersCount,
  setIsPublishEnabled,
  setProviderLock,
  replaceEligibleVisits,
  updateProvidersCount,
  updateLoading,
  deleteProvider,
} = providersSlice.actions;

export default providersSlice.reducer;
