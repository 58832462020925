import React, { useState, useEffect, useContext } from "react";
import Reply_Icon from "../../assets/images/Reply.png";
import Play_Icon from "../../assets/images/Play.png";
import Users_Icon from "../../assets/images/Users.png";
// import Tag_Icon from "../../assets/images/Tag.png";
import Tag from "../Tags/Tag";
import MessageText from "../Text/MessageText";
// import MentionsRenderText from "../Text/MentionsRenderText";
import { Avatar, Grid, Typography, Tooltip } from "@mui/material";
import "./ChatCard.css";
import clsx from "clsx";
import { chatOptions } from "../../data/listOptions";
import MessageOptions from "./MessageOptions";
import moment from "moment";
import ImageSvg from "../../assets/svgs/ImageSvg";
import BoldString from "../Text/BoldString";
import useChats from "../../hooks/useMessenger";
import DeleteModal from "../Modals/DeleteModal";
import StarIcon from "@mui/icons-material/Star";
import UserSvg from "../../assets/svgs/user";
import TagSvg from "../../assets/svgs/TagSvg";
import WarningSvg from "../../assets/svgs/WarningSvg";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { parseMessage, removeSubstring } from "../../utilities/resuseFunctions";
import { SocketContext } from "../../context/SocketProvider";

const ChatCard = ({
  data = {},
  onClick,
  isFullScreen,
  selected,
  onSend,
  isMe,
  careTags = [],
  tagPatients = [],
}) => {
  const userData = useSelector(selectUser);
  const { isLastMessaged } = data;
  const { updateMessage } = useContext(SocketContext);
  // const { removeTag, removePatientTag, userData, getUnreadMessages, count } =
  //   useChats();
  const [isDelete, setIsDelete] = useState(null);

  const message = isFullScreen ? data?.lastMessage : data;

  const time = moment(message?.sentAt);
  const isToday = time.isSame(moment(), "day");
  const isSentOn = isToday ? time.format("hh:mm A") : time.format("MM/DD/YYYY");
  const isGroup = isFullScreen
    ? data?.type === "GROUP"
    : data?.conversation?.type === "GROUP";
  const isAdmin = userData.type === 1;

  const indexGroups = Object.keys(message?.content?.meta || {})?.map((m) => {
    const [startIndex, endIndex] = m.split("-");
    return {
      startIndex,
      endIndex,
      type: message?.content?.meta?.[m]?.type,
      id: message?.content?.meta?.[m]?.id,
    };
  });
  const taggedPatients = indexGroups?.filter((patient) => {
    return patient.type === "PATIENT";
  });

  const taggedCareTags = indexGroups
    ?.filter((tag) => {
      return tag.type === "CARETAG";
    })
    ?.map((tag) => {
      return {
        ...tag,
        ...(careTags?.find((i) => i.id === tag.id) || {}),
      };
    });

  const isChatSelected = selected?.id === data?.id;

  const isStarred = isFullScreen
    ? data?.lastMessage?.isStarred
    : data?.isStarred;

  const isUnread = false;
  // const isUnread =
  //   !data.lastMessage?.sender_id === userData.id &&
  //   moment(data.updatedAt).isBefore(data.lastMessage?.sentAt);

  // useEffect(() => {
  //   isUnread && getUnreadMessages(data);
  // }, []);

  // console.log(data, "data");

  const isAccessible = userData.type === 1;

  const isSentToEHR = message?.isSentToEHR;

  const onUpdate = async (message, editMessage) => {
    try {
      if (!message.trim()) {
        return;
      }

      const { taggedMembers, taggedPatients, currentHashTags, replaceHash } =
        parseMessage({
          message,
        });

      const meta = {};
      taggedMembers.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "USER",
          id: data.userId,
        };
      });
      currentHashTags.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "CARETAG",
          id: data.id,
        };
      });
      taggedPatients.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "PATIENT",
          id: data.id,
        };
      });

      updateMessage({
        message: {
          id: editMessage.id,
          conversationId: editMessage.conversationId,
          isForwarded: editMessage.isForwarded,
          isEdited: true,
          isUrgent: editMessage.isUrgent,
          attachmentId: editMessage?.attachmentId,
          content: {
            type: "MESSAGE",
            message: replaceHash,
            meta,
          },
          sender: {
            id: editMessage.sender?.id,
          },
          createdAt: new Date(editMessage.sentAt),
        },
        userIds: data.participants
          ?.filter((p) => p.user_id !== userData.id)
          ?.map((d) => `USER:${d.user_id}`),
        meta: {
          senderName: userData.details?.name || "",
          groupName:
            data.type === "GROUP" ? data.name : userData.details?.name || "",
        },
        updateMsg: {
          ...editMessage,
          content: {
            type: "MESSAGE",
            message: replaceHash,
            meta,
          },
        },
      });
    } catch (e) {
      console.log(e, "err");
    }
  };

  const onRemoveTag = () => {
    const { tag, type } = isDelete;
    console.log(isDelete, "isDelete");
    setIsDelete(null);
    if (!isSentToEHR) {
      const updatedMessage = removeSubstring(
        message.content?.message || "",
        tag.startIndex,
        tag.endIndex
      );
      onUpdate(updatedMessage, message);
    }
  };

  return (
    <Grid
      role="button"
      className={clsx(
        "d-flex p-2 w-100 main border-top",
        isFullScreen && selected?.id === data?.id && "selectedChat"
      )}
      onClick={() => isFullScreen && onClick(data)}
    >
      <Grid>
        <Avatar size={"md"} className="my-3" src={data.image} />
      </Grid>
      <Grid className="mx-2 position-relative w-100">
        <Grid
          className={"d-flex py-1 align-items-center justify-content-between"}
        >
          <Grid className={clsx("d-flex align-items-center")}>
            <Typography
              className={clsx(
                "title-part-1 mr-2",
                isLastMessaged && !isFullScreen && "lastMessaged"
              )}
            >
              {data?.name}
            </Typography>
            {!isFullScreen && data?.lastMessage?.sentAt && (
              <Typography className="font-14 time">
                &nbsp; ·
                {isStarred && (
                  <StarIcon className="star-icon text-black mx-1" />
                )}
                &nbsp;
                {isSentOn}
              </Typography>
            )}
          </Grid>
          <Grid>
            {isFullScreen && data?.lastMessage?.sentAt && (
              <Typography className="font-14 time">{isSentOn}</Typography>
            )}
            {!isFullScreen && (
              <MessageOptions
                options={chatOptions}
                message={data}
                data={data.conversation}
                noSendToAthena
                tagPatients={tagPatients}
              />
            )}
          </Grid>
        </Grid>
        <Grid className="d-flex align-items-center justify-content-between">
          {
            (data?.lastMessage || !isFullScreen) && (
              <Typography
                className={`flex items-center two-line-text ${
                  // isUnread && isFullScreen && !!count?.[data.conversationId]
                  true ? "w-90" : ""
                }`}
              >
                <span
                  className={`font-14 bold-600 ${
                    !isFullScreen || isChatSelected ? "primary" : "time"
                  }`}
                >
                  {isMe
                    ? "You:"
                    : isGroup
                    ? `${
                        isFullScreen
                          ? data?.lastMessage?.sender?.name
                          : data?.sender?.name || ""
                      }:`
                    : ""}
                </span>
                {(isGroup || isMe) && " "}
                {(isFullScreen
                  ? data?.lastMessage?.attachment
                  : data?.attachment) && <ImageSvg color="#666" />}
                {isGroup && " "}
                <BoldString
                  text={
                    (isFullScreen
                      ? data?.lastMessage?.content?.message
                      : data?.content?.message) || ""
                  }
                  indexGroups={indexGroups}
                  textColor={
                    isFullScreen && !isChatSelected && "rgba(74, 84, 94, 0.6)"
                  }
                />
              </Typography>
            )
            // ))
          }
          {/* {isUnread && isFullScreen && !!count?.[data.conversationId] && (
            <Grid className="unread">{count?.[data?.conversationId] || 0}</Grid>
          )} */}
        </Grid>

        {!isFullScreen && (
          <Grid className="d-flex py-2 justify-content-between">
            <Grid className="d-flex">
              <Tooltip title="Reply">
                <button
                  onClick={() => onClick(data)}
                  className="d-flex btn align-items-center"
                >
                  <img src={Reply_Icon} alt="reply" className="mx-1 icon" />
                  <Typography className="font-14">Reply</Typography>
                </button>
              </Tooltip>
              {data?.isUrgent && (
                <Tag
                  className="mx-1"
                  text={"URGENT"}
                  varient={isSentToEHR ? "urgentSuccess" : "urgent"}
                  // success={isSentToEHR}
                  isBold={true}
                  Icon={WarningSvg}
                  viewBox={"0 0 24 24"}
                />
              )}
              {taggedCareTags &&
                Array.isArray(taggedCareTags) &&
                taggedCareTags?.map((i, idx) => (
                  <Tag
                    key={idx}
                    className="mx-1"
                    text={i?.name}
                    varient={isSentToEHR ? "" : "metatag"}
                    success={isSentToEHR}
                    isBold={true}
                    onClose={() => setIsDelete({ tag: i, type: "tag" })}
                    isRem={!isSentToEHR && isAccessible}
                    Icon={TagSvg}
                    viewBox={"0 0 24 24"}
                  />
                ))}
              {taggedPatients &&
                Array.isArray(taggedPatients) &&
                taggedPatients?.map((i, idx) => (
                  <Tag
                    key={idx}
                    className="mx-1"
                    text={message?.content?.message?.slice(
                      i.startIndex,
                      i.endIndex
                    )}
                    varient={isSentToEHR ? "patientSuccess" : "patient"}
                    // success={isSentToEHR}
                    isBold={true}
                    onClose={() => setIsDelete({ tag: i, type: "patient-tag" })}
                    // isRem -> isRemovable
                    isRem={!isSentToEHR && isAccessible}
                    Icon={UserSvg}
                  />
                ))}
            </Grid>
            {/* {isAdmin && !!data?.lastMessage?.Metadata?.length && (
              <Tag
                text={!isPending ? "Sent to Athena" : "Send to Athena"}
                success={!isPending}
                varient="grey"
                onClick={onSend}
              />
            )} */}
          </Grid>
        )}
        {isDelete && (
          <DeleteModal
            open={isDelete}
            handleClose={() => {
              setIsDelete(false);
            }}
            handleDelete={onRemoveTag}
            title={"Remove Tag"}
            subtext={"Are you sure you want to remove this Tag?"}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChatCard;
