import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import "./ReferencedPatientCard.css";

const ReferencedPatientCard = ({
  patientName,
  patientID,
  onClick,
  messages,
  count = 0,
}) => {
  return (
    <Grid>
      <Grid
        role="button"
        className="m-1 p-2 bg-white position-relative"
        onClick={onClick}
      >
        <Grid className="card-arrow d-flex">
          <Grid className="unread">{messages?.length || count}</Grid>
          <KeyboardArrowRight />
        </Grid>
        <Typography
          className="font-14"
          style={{
            color: "#000",
          }}
        >
          <span className="name">{patientName}</span>{" "}
          {patientID ? `| ${patientID}` : ""}
        </Typography>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default ReferencedPatientCard;
