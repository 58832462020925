import React, { useState } from 'react'
import { Empty_Dataset } from '../../../../assets/images'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { CircularProgress } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from "@mui/material";
function AddDataset({
    superset,
    getQueriedTableData,
    errorMessage,
    datasetLoadingStep,
    isDatasetLoading,
    setActiveNavItem,
    showAlert,
    setErrorMessage
}) {


    const [filteredData, setFilteredData] = useState([]);
    const [db, setDb] = useState(null);

    const executeQuery = async () => {

        try {
            // const results = db.exec(query);
            // if (results.length > 0) {
            //     const columns = results[0].columns;
            //     const values = results[0].values;
            //     setFilteredData(values.map((row) => Object.fromEntries(columns.map((col, i) => [col, row[i]]))));
            // }

            const result = await getQueriedTableData(superset?.sqlQuery);
            //superset?.setSqlQuery(superset?.sqlQuery);
            console.log(result, ">>> Resulrttt ");
            result?.data?.length <= 0 && showAlert(`No data found for the given query. Please try again with different parameters.`, 'info');

        } catch (error) {
            alert("SQL Error: " + error.message);
        }
    };


    // React?.useEffect(() => {
    //     superset?.setSqlQuery(`${superset?.tableMetaData?.tableData?.query?.sql}`);

    // }, [superset?.tableMetaData]);

    return (
        // <div className='h-full w-full d-flex flex-col items-center px-1 py-1' style={{ height: '100%', width: "100%", }}>
        //     {console.log(superset?.tableMetaData, ">>> Table Metadata of the supoerset")}
        //     {isDatasetLoading && (
        //         <div
        //             style={{
        //                 position: "absolute",
        //                 top: 0,
        //                 left: 0,
        //                 right: 0,
        //                 bottom: 0,
        //                 backgroundColor: "rgba(0, 0, 0, 0.5)",
        //                 display: "flex",
        //                 justifyContent: "center",
        //                 alignItems: "center",
        //                 zIndex: 10,
        //             }}
        //         >
        //             <div
        //                 style={{
        //                     borderRadius: "8px",
        //                     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        //                 }}
        //                 className='bg-[#fff] pt-6 pb-3 w-[30%] text-center flex flex-col gap-4'
        //             >
        //                 <Stack alignItems="center">
        //                     {datasetLoadingStep === "Dataset created successfully!" ? (
        //                         <Box
        //                             display="flex"
        //                             justifyContent="center"
        //                             alignItems="center"
        //                             bgcolor="green"
        //                             borderRadius="50%"
        //                             width="50px"
        //                             height="50px"
        //                         >
        //                             <CheckIcon style={{ fontSize: "30px", color: "white" }} />
        //                         </Box>
        //                     ) : (
        //                         <CircularProgress size="30px" color="inherit" />
        //                     )}
        //                 </Stack>
        //                 <p>{datasetLoadingStep}</p>
        //             </div>
        //         </div>
        //     )}
        //     {superset?.datasettableloading ? (
        //         <div className="flex justify-center items-center w-full h-full">
        //             <CircularProgress size={40} />
        //         </div>
        //     ) :
        //         <>
        //             <span className="w-full flex justify-between items-center ">
        //                 <h2 className="text-[16px] font-bold text-[#075985]">
        //                     New Dataset
        //                 </h2>
        //                 <Tooltip title={'Close'}>
        //                     <CloseIcon
        //                         className="cursor-pointer text-[#075985]"
        //                         onClick={() => {
        //                             setActiveNavItem("Home")
        //                             superset?.setIsDatasetView(false)
        //                             superset?.setSelectedSchema(null)
        //                             superset?.setSelectedTable(null)
        //                             superset?.setselectedDatabse(null)
        //                             superset?.setTableMetadata({ metaData: [], tableData: [] })
        //                         }}
        //                     />
        //                 </Tooltip>

        //             </span>
        // {(superset?.selectedSchema &&
        //     superset?.selectedSchema &&
        //     superset?.selectedTable) &&
        //     <span className='relative d-flex w-full'>
        //         <textarea
        //             className="border w-full p-2 mb-2 text-sm max-h-80 min-h-20 overflow-auto"
        //             rows="3"
        //             placeholder="Write your SQL query here..."
        //             value={superset?.sqlQuery}
        //             onChange={(e) => superset?.setSqlQuery(e.target.value)}
        //         />
        //         <button
        //             className="absolute right-0 bg-[#105597] text-white py-0.5 pl-0.5 pr-1 text-sm"
        //             onClick={executeQuery}
        //         >
        //             <PlayArrowIcon className='queryicon' />
        //             Run Query
        //         </button>
        //     </span>}
        //             {superset?.tableMetaData?.tableData?.data?.length > 0 ?
        //                 <div className="h-full w-full flex flex-col items-center px-1">

        //                     <div style={{ width: "100%", height: '100%', overflow: "auto" }}
        //                         className={`datamanagement-custom-scrollbar dashboard-main relative `} >
        //                         {errorMessage ?
        //                             (
        //                                 <div className="text-[#6f212a] flex flex-col gap-2 px-4 py-3 mt-3 rounded-[5px]  error-msg bg-[#faedee]">
        //                                     <span className='flex gap-1'>
        //                                         <ReportIcon />

        //                                         <p className="m-0 font-semibold">
        //                                             {superset?.selectedDatabase?.backend ? `${superset?.selectedDatabase?.backend} Error` : "Error"}
        //                                         </p>
        //                                     </span>
        //                                     <span className='pl-6 text-sm'>
        //                                         {errorMessage}
        //                                     </span>

        //                                 </div>
        //                             )
        //                             :
        //                             <table className="scroll tablestyle max-h-100 w-100 border border-gray-300">
        //                                 <thead className='sticky top-[-1px]'>
        //                                     <tr className="bg-gray-200">
        //                                         {Object.keys(superset?.tableMetaData?.tableData?.data[0]).map((key) => (
        //                                             <th key={key} className="border p-1 text-xs text-left whitespace-nowrap">
        //                                                 {key.replace(/([A-Z])/g, " $1").trim()}
        //                                             </th>
        //                                         ))}
        //                                     </tr>
        //                                 </thead>
        //                                 <tbody>
        //                                     {superset?.tableMetaData?.tableData?.data?.map((row, index) => (
        //                                         <tr key={index} className="hover:bg-gray-100">
        //                                             {Object.values(row).map((value, idx) => (
        //                                                 <td key={idx} className="border text-xs p-1 whitespace-nowrap">
        //                                                     {value}
        //                                                 </td>
        //                                             ))}
        //                                         </tr>
        //                                     ))}
        //                                 </tbody>
        //                             </table>
        //                         }
        //                     </div>
        //                 </div>

        //                 :
        //                 <div className='m-auto'>
        //                     <img src={Empty_Dataset} />
        //                 </div>

        //             }
        //         </>
        //     }

        // </div>

        <div className='h-full w-full d-flex flex-col items-center justify-center' style={{ height: '100%', width: "100%" }}>
            {console.log(superset?.tableMetaData, ">>> Table Metadata of the supoerset")}
            {/* {isDatasetLoading && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        style={{
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                        className='bg-[#fff] pt-6 pb-3 w-[30%] text-center flex flex-col gap-4'
                    >
                        <Stack alignItems="center">
                            {datasetLoadingStep === "Dataset created successfully!" ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    bgcolor="green"
                                    borderRadius="50%"
                                    width="50px"
                                    height="50px"
                                >
                                    <CheckIcon style={{ fontSize: "30px", color: "white" }} />
                                </Box>
                            ) : (
                                <CircularProgress size="30px" color="inherit" />
                            )}
                        </Stack>
                        <p>{datasetLoadingStep}</p>
                    </div>
                </div>
            )} */}
            {superset?.datasettableloading ? (
                <div className="flex justify-center items-center w-full h-full">
                    <CircularProgress size={40} />
                </div>
            ) : (
                <div className="h-full w-full flex flex-col items-center px-1">
                    <span className="w-full flex justify-end items-center">
                        <Tooltip title={'Close'}>
                            <CloseIcon
                                className="cursor-pointer text-[#075985]"
                                onClick={() => {
                                    setActiveNavItem("Home")
                                    superset?.setIsDatasetView(false)
                                    superset?.setSelectedSchema(null)
                                    superset?.setSelectedTable(null)
                                    superset?.setselectedDatabse(null)
                                    superset?.setTableMetadata({ metaData: [], tableData: [] })
                                    setErrorMessage(null)
                                }}
                            />
                        </Tooltip>
                    </span>
                    {(superset?.selectedSchema &&
                        superset?.selectedSchema &&
                        superset?.selectedTable) &&
                        <span className='relative d-flex w-full'>
                            <textarea
                                className="border w-full p-2 mb-2 text-sm max-h-80 min-h-20 overflow-auto"
                                rows="3"
                                placeholder="Write your SQL query here..."
                                value={superset?.sqlQuery}
                                onChange={(e) => superset?.setSqlQuery(e.target.value)}
                            />
                            <button
                                className="absolute right-0 bg-[#105597] text-white py-0.5 pl-0.5 pr-1 text-sm"
                                onClick={executeQuery}
                            >
                                <PlayArrowIcon className='queryicon' />
                                Run Query
                            </button>
                        </span>}
                    {superset?.tableMetaData?.tableData?.data?.length > 0 ? (
                        <>
                            {/* <span className='relative d-flex w-full'>
                                <textarea
                                    className="border w-full p-2 mb-2 text-sm max-h-80 min-h-20 overflow-auto"
                                    rows="3"
                                    placeholder="Write your SQL query here..."
                                    value={superset?.sqlQuery}
                                    onChange={(e) => superset?.setSqlQuery(e.target.value)}
                                />
                                <button
                                    className="absolute right-0 bg-[#105597] text-white py-0.5 pl-0.5 pr-1 text-sm"
                                    onClick={executeQuery}
                                >
                                    <PlayArrowIcon className='queryicon' />
                                    Run Query
                                </button>
                            </span> */}

                            <div style={{ width: "100%", height: '100%', overflow: "auto" }}
                                className={`datamanagement-custom-scrollbar dashboard-main relative`} >
                                {errorMessage ? (
                                    <div className="text-[#6f212a] flex flex-col gap-2 px-4 py-3 mt-3 rounded-[5px] error-msg bg-[#faedee]">
                                        <span className='flex gap-1'>
                                            <ReportIcon />
                                            <p className="m-0 font-semibold">
                                                {superset?.selectedDatabase?.backend ? `${superset?.selectedDatabase?.backend} Error` : "Error"}
                                            </p>
                                        </span>
                                        <span className='pl-6 text-sm'>
                                            {errorMessage}
                                        </span>
                                    </div>
                                ) : (
                                    <table className="scroll tablestyle max-h-100 w-100 border border-gray-300">
                                        <thead className='sticky top-[-1px]'>
                                            <tr className="bg-gray-200">
                                                {Object.keys(superset?.tableMetaData?.tableData?.data[0]).map((key) => (
                                                    <th key={key} className="border p-1 text-xs text-left whitespace-nowrap">
                                                        {key.replace(/([A-Z])/g, " $1").trim()}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {superset?.tableMetaData?.tableData?.data?.map((row, index) => (
                                                <tr key={index} className="hover:bg-gray-100">
                                                    {Object.values(row).map((value, idx) => (
                                                        <td key={idx} className="border text-xs p-1 whitespace-nowrap">
                                                            {value}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className='m-auto'>
                            <img src={Empty_Dataset} />
                        </div>
                    )}
                </div>
            )}
        </div>

    )
}

export default AddDataset