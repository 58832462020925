import { apiSlice } from "../../app/api/apiSlice";

export const communityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCommunityById: builder.mutation({
      query: (credentials) => ({
        url: `/community/${credentials}`,
        method: "GET",
      }),
    }),
    fetchCommunityPatients: builder.mutation({
      query: (credentials) => ({
        url: `/community/patients/${credentials}`,
        method: "GET",
      }),
    }),
    fetchCommunityStaff: builder.mutation({
      query: (credentials) => ({
        url: `/community/staffs/${credentials}`,
        method: "GET",
      }),
    }),
    fetchCommunityProviders: builder.mutation({
      query: (credentials) => ({
        url: `/community/providers/${credentials}`,
        method: "GET",
      }),
    }),
    createCommunity: builder.mutation({
      query: (credentials) => ({
        url: `/community/`,
        method: "POST",
        body: credentials,
      }),
    }),
    updateCommunity: builder.mutation({
      query: (credentials) => ({
        url: `/community/${credentials.id}`,
        method: "PATCH",
        body: credentials,
      }),
    }),
    deleteCommunity: builder.mutation({
      query: (credentials) => ({
        url: `/community/${credentials?.id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useFetchCommunityByIdMutation,
  useFetchCommunityPatientsMutation,
  useFetchCommunityStaffMutation,
  useFetchCommunityProvidersMutation,
  useCreateCommunityMutation,
  useUpdateCommunityMutation,
  useDeleteCommunityMutation,
} = communityApiSlice;
