import moment from "moment";
import React from "react";
import IconComponent from "../../../../../components/DataTable/Icon";
import axios from "axios";
import { useDeletePatientConstraintMutation } from "../../../../../features/constraints/patient/patientConstraintApiSlice.js";

const DAY_MAP = {
  MONDAY: "M",
  TUESDAY: "T",
  WEDNESDAY: "W",
  THURSDAY: "Th",
  FRIDAY: "F",
  SATURDAY: "Sa",
  SUNDAY: "Su",
};

const PatientVisitRulesTable = ({
  rules,
  handleEditClick,
  visitRules,
  setVisitRules,
  isWelcomePage,
}) => {
  const [deletePatientConstraintAPI] = useDeletePatientConstraintMutation();

  const deletePatientConstraint = async (id) => {
    try {
      await deletePatientConstraintAPI({ id }).unwrap();

      const newRules = rules.filter((rule) => rule.id !== id);

      const allRules = {
        ...visitRules,
        patient: newRules,
      };

      setVisitRules(allRules);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("MM/DD/YYYY")
          : value;
  };

  return (
    <>
      {isWelcomePage ?
        <div
          className={
            isWelcomePage
              ? "max-h-96 min-h-40 overflow-auto"
              : "h-screen overflow-scroll no-scrollbar"
          }
        >
          <table className={`w-full ${isWelcomePage ? "" : "mb-[460px]"}`}>
            <thead>
              <tr className="text-left bg-primarylightgrey">
                <th className="px-4 py-3">Patient</th>
                <th className="px-4 py-3">Availability</th>
                <th className="px-4 py-3">From</th>
                <th className="px-4 py-3">To</th>
                <th className="px-4 py-3"></th>
              </tr>
            </thead>
            {rules.length > 0 ? (
              <tbody>
                {Array.isArray(rules) &&
                  rules.map((constraints, index) => (
                    <tr
                      className="text-left text-gray-900 border-bottom"
                      key={index}
                    >
                      <td className="px-4 py-3 text-left">{constraints.name}</td>
                      <td className="px-4 py-3 text-left">
                        {constraints.visitDays.map((x) => DAY_MAP[x]).join(", ")}
                      </td>
                      <td className="px-4 py-3 text-left">
                        {parseNull(constraints.startDate, "NA", "date")}
                      </td>
                      <td className="px-4 py-3 text-left">
                        {parseNull(constraints.endDate, "NA", "date")}
                      </td>
                      <td className="flex flex-row items-center gap-2 px-4 py-3">
                        <button
                          className="p-2 text-white bg-gray-200 rounded-md"
                          onClick={() => {
                            handleEditClick(constraints);
                          }}
                        >
                          <IconComponent
                            tooltip={"Edit visit rules"}
                            icon="Edit_Icon"
                            i={constraints.id}
                            key={constraints.id}
                          />
                        </button>
                        {/* <button
                      className="p-2 text-white bg-gray-200 rounded-md"
                      onClick={() => {
                        deletePatientConstraint(constraints.id);
                      }}
                    >
                      <IconComponent
                        icon="Delete_Icon"
                        i={constraints.id}
                        key={constraints.id}
                      />
                    </button> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            ) : (
              <tbody className="relative">
                <div
                  className="absolute w-full py-2 my-3 d-flex align-Items-center justify-content-center"
                  style={{ zIndex: 0 }}
                >
                  <p className="text-xl font-bold text-primarygrayborder">
                    {" "}
                    No Records Found
                  </p>
                </div>
              </tbody>
            )}
          </table>
        </div>
        : <div className="w-full ">
          <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow max-h-[calc(100vh-270px)] min-h-[270px]">
            <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
              <thead>
                <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                  <th className="px-4 py-3">Patient</th>
                  <th className="px-4 py-3">Availability</th>
                  <th className="px-4 py-3">From</th>
                  <th className="px-4 py-3">To</th>
                  <th className="px-4 py-3">Edit</th>
                </tr>
              </thead>
              {rules.length > 0 ? (
                <tbody>
                  {Array.isArray(rules) &&
                    rules.map((constraints, index) => (
                      <tr
                        className="text-left text-gray-900 border-b"
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">{constraints.name}</td>
                        <td className="px-4 py-3 text-left">
                          {constraints.visitDays.map((x) => DAY_MAP[x]).join(", ")}
                        </td>
                        <td className="px-4 py-3 text-left">
                          {parseNull(constraints.startDate, "NA", "date")}
                        </td>
                        <td className="px-4 py-3 text-left">
                          {parseNull(constraints.endDate, "NA", "date")}
                        </td>
                        <td className="  px-4 py-3">
                          <button
                            className="p-2 text-white  rounded-md flex"
                            onClick={() => {
                              handleEditClick(constraints);
                            }}
                          >
                            <IconComponent
                              tooltip={"Edit visit rules"}
                              icon="Edit_Icon"
                              i={constraints.id}
                              key={constraints.id}
                            />
                          </button>
                          {/* <button
                        className="p-2 text-white bg-gray-200 rounded-md"
                        onClick={() => {
                          deletePatientConstraint(constraints.id);
                        }}
                      >
                        <IconComponent
                          icon="Delete_Icon"
                          i={constraints.id}
                          key={constraints.id}
                        />
                      </button> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody className="relative">
                  <div
                    className="absolute w-full py-2 my-3 d-flex align-Items-center justify-content-center"
                    style={{ zIndex: 0 }}
                  >
                    <p className="text-xl font-bold text-primarygrayborder">
                      {" "}
                      No Records Found
                    </p>
                  </div>
                </tbody>
              )}
            </table>
          </div>
        </div>}
    </>
  );
};

export default PatientVisitRulesTable;
