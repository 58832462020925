import classNames from "classnames";
import React from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";

const PublishSuccessCard = ({ successCard, setSuccessCard }) => {
  return (
    <div
      className={classNames(
        "absolute z-50 w-[580px] top-3 transition-all",
        successCard ? "right-5 opacity-100" : "-right-[600px] opacity-30"
      )}
    >
      <div
        className="relative flex flex-row items-center justify-between px-4 py-3 bg-green-100 border rounded border-primarygreen text-primarygreen"
        role="alert"
      >
        <p className="m-0 text-base">
          <strong className="font-bold">Success!</strong>
          <span className="block sm:inline">
            {"    "}Schedules have been published to Athena
          </span>
        </p>
        <button
          onClick={() => {
            setSuccessCard(false);
          }}
          className="flex items-center justify-center w-10 rounded-full aspect-square bg-primarylightgreen"
        >
          <CloseSvg color={"#fff"} />
        </button>
      </div>
    </div>
  );
};

export default PublishSuccessCard;
