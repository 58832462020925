import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import {
  replaceCareTags,
  addCareTag,
  updateCareTag,
  deleteteCareTag,
  replaceDocTypes,
} from "../redux/reducers/caretags.reducer";
import { useDispatch, useSelector } from "react-redux";
import { queryString } from "../utilities/resuseFunctions";

export default function useCareTags() {
  const dispatch = useDispatch();
  const {
    data: careTags,
    count,
    docTypes,
  } = useSelector((state) => state.caretags);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const getCareTags = useCallback(
    ({ limit = "20", skip = "" } = {}) => {
      handleOpen();
      api.get(`/caretags?${queryString({ limit, skip })}`).then((res) => {
        dispatch(replaceCareTags(res?.data || []));
        handleClose();
      });
    },
    [dispatch]
  );

  useEffect(() => {
    getCareTags();
  }, []);

  const addCareTagApi = (data) => {
    handleOpen();
    return api
      .post("caretags", data)
      .then((res) => {
        dispatch(addCareTag(res?.data?.data || []));
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const updateCareTagApi = (data) => {
    handleOpen();
    return api
      .put(`caretags/${data.id}`, data)
      .then((res) => {
        dispatch(updateCareTag(res?.data?.data || []));
        handleClose();
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const deleteCareTagApi = (data) => {
    handleOpen();
    api
      .delete(`caretags/${data.id}`)
      .then((res) => {
        dispatch(deleteteCareTag(res?.data?.data || []));
        handleClose();
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getDocTypes = useCallback(({ limit = "20", skip = "" } = {}) => {
    handleOpen();
    api
      .get(`/caretags/doc/types?${queryString({ limit, skip })}`)
      .then((res) => {
        dispatch(replaceDocTypes(res?.data || []));
        handleClose();
      });
  }, []);

  return {
    careTags,
    addCareTagApi,
    updateCareTagApi,
    deleteCareTagApi,
    loading,
    handleClose,
    count,
    getCareTags,
    getDocTypes,
    docTypes,
  };
}
