import React, { useEffect } from "react";

import { skorboard_logo } from "../assets/images";
import { useNavigate } from "react-router-dom";

function HomeScreen() {

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/analytics");
        }, 3000);

        return () => clearTimeout(timer); // Cleanup in case the component unmounts
    }, [navigate]);
    // useEffect(() => {
    //     const isSignedOut = sessionStorage.getItem("signedOut") === "true";
    //     if (isSignedOut) return; // Stop redirection if signed out

    //     const timer = setTimeout(() => {
    //         navigate("/analytics");
    //     }, 3000);

    //     return () => clearTimeout(timer); // Cleanup
    // }, [navigate]);


    return (
        <div className="flex justify-center items-center h-screen bg-[#b6b9bd]">
            <span className="w-[50%]">
                <img
                    src={skorboard_logo}
                    alt="Skorboard Logo"
                />
            </span>
        </div>
    )
}

export default HomeScreen;
