import AddIcon from "@mui/icons-material/Add";
import CheckboxSelector from "../../../../Schedules/components/CheckboxSelector";
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { Icons } from "../../../../../assets";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { SketchPicker } from "react-color";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { useUpdateTemplatesMutation } from "../../../../../features/report/reportApiSlice";

// const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
//     // const [isOpen, setIsOpen] = React.useState(false);
//     // const toggleOpen = () => {
//     //     setIsOpen(!isOpen);
//     // };
//     return (
//         <div
//             className="sidebar-section"
//         // style={{ borderTop: '0.5px solid #d2d9e3', borderBottom: '0.5px solid #d2d9e3' }}
//         >
//             {/* <div
//                 className={`sidebar-section-header hover:bg-slate-300 ${subSection ? ' bg-slate-100' : 'bg-slate-200 py-1'} px-2 `}
//                 onClick={toggleOpen}
//             > */}
//             <div
//                 className={`sidebar-section-header ${subSection ? "px-1" : "px-2"} cursor-pointer
//           }`}
//                 onClick={onToggle}
//                 style={{
//                     backgroundColor: !subSection ? "#c1c2c6" : "#e4e4eb",
//                     borderRadius: subSection ? "5px" : ""
//                 }}
//             >
//                 <div className={`d-flex ${subSection ? "" : "justify-between"} items-center`}>
//                     <span
//                         className={`font-semibold  ${subSection ? "text-[12px] text-gray-600 py-1" : "text-[12px] text-[#4d5259]" //4b5563
//                             } `}
//                         style={{
//                             color: subSection ? "text-sm text-gray-600" : "text-[#4d5259]",
//                             paddingLeft: subSection ? "" : "8px",
//                         }}
//                     >
//                         {title}
//                     </span>
//                     <button className="toggle-button">
//                         {!subSection ? (
//                             isOpen ? (
//                                 <Tooltip title="Remove">
//                                     <RemoveIcon
//                                         className="text-[#4d5259]"
//                                         sx={{ fontSize: "14px" }}
//                                     />
//                                 </Tooltip>
//                             ) : (
//                                 <Tooltip title="Add">
//                                     <AddIcon
//                                         className="text-[#4d5259]"
//                                         sx={{ fontSize: "14px" }}
//                                     />
//                                 </Tooltip>
//                             )
//                         ) : null}
//                     </button>
//                 </div>
//             </div>
//             {isOpen && <div className="sidebar-section-content">{children}</div>}
//         </div>
//     );
// };
const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
    return (
        <div className="sidebar-section">
            {/* <div
          className={`sidebar-section-header  hover:bg-slate-300 ${subSection ? " bg-slate-100" : "bg-slate-200 py-1"
            } px-2 `}
          onClick={onToggle}
          style={{ backgroundColor: "rgb(16 85 151)" }}
        > */}
            <div
                className={`sidebar-section-header ${subSection ? "px-1" : "px-2"} cursor-pointer 
            }`}
                onClick={onToggle}
                style={{
                    backgroundColor: !subSection ? "#c1c2c6" : "#e4e4eb",
                    borderRadius: subSection ? "5px" : ""
                }}
            >
                <div
                    // className="d-flex justify-between items-center"
                    className={`d-flex ${subSection ? "" : "justify-between"} items-center`}
                >
                    {subSection ? (
                        isOpen ? (
                            <KeyboardArrowDownIcon
                                className="text-gray-600"
                                sx={{ fontSize: "16px" }}
                            />
                        ) : (
                            <KeyboardArrowRightIcon
                                className="text-gray-600 "
                                sx={{ fontSize: "16px" }}
                            />
                        )
                    ) : null}
                    <span
                        className={`font-semibold  ${subSection ? "text-[12px] text-gray-600 py-1" : "text-[12px] text-[#4d5259]" //4b5563
                            } `}
                        style={{
                            color: subSection ? "text-sm text-gray-600" : "text-[#4d5259]",
                            paddingLeft: subSection ? "" : "8px",
                        }}
                    >
                        {title}
                    </span>
                    {/* <button className="toggle-button">
              {isOpen ? (
                <Tooltip title="Remove">
                  <RemoveIcon
                    className={`${subSection ? " text-gray-600" : "text-[#4d5259]"
                      }`}
                    sx={{
                      fontSize: "14px"
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Add">
                  <AddIcon
                    className={`${subSection ? " text-gray-600" : "text-[#4d5259]"
                      }`}
                    sx={{
                      fontSize: "14px"
                    }}
                  />
                </Tooltip>
              )}
            </button> */}
                    <button className="toggle-button">
                        {!subSection ? (
                            isOpen ? (
                                <Tooltip title="Remove">
                                    <RemoveIcon
                                        className="text-[#4d5259]"
                                        sx={{ fontSize: "14px" }}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Add">
                                    <AddIcon
                                        className="text-[#4d5259]"
                                        sx={{ fontSize: "14px" }}
                                    />
                                </Tooltip>
                            )
                        ) : null}
                    </button>
                </div>
            </div>
            {isOpen && <div
                className="sidebar-section-content"
            >
                {children}
            </div>}
        </div>
    );
};

function SummeryProperties({
    component,
    onPropertyChange,
    setSelectedComponent,
    showAlert,
    flag,
    setFlag,
    reportAreaRef,
    openSection,
    setOpenSection,
    setOpenItem,
    openItem
}) {
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const [colorPickerBg, setColorPickerBg] = React.useState(false);
    const [colorPickerText, setColorPickerText] = React.useState(false);
    const [newCol, setNewCol] = React.useState("");
    const [columnListOpen, setColumnListOpen] = React.useState(false);
    const [selectedColumn, setSelectedColumn] = React.useState(
        component?.properties?.columns
    );
    const columnRef = React.useRef(null);
    const [deselectedColumns, setDeselectedColumns] = React.useState([]);
    const [updateTemplates] = useUpdateTemplatesMutation();
    const [open, setOpen] = React.useState(false);
    const backgroundColorRef = React.useRef(null);
    const textColorRef = React.useRef(null);
    const borderColorRef = React.useRef(null);
    const colorPickerRefs = React.useRef({});
    const columnDropdownRef = React.useRef(null);

    const [colorPickerState, setColorPickerState] = React.useState({});
    const Fonts = [
        "Inter",
        "Impac",
        "Arial",
        "Helvetica",
        "Times New Roman",
        "Times",
        "Courier New",
        "Courier",
        "Verdana",
        "Georgia",
        "Palatino",
        "Garamond",
        "Bookman",
        "Trebuchet MS",
        "Comic Sans MS",
    ];
    const fontWeights = [
        { name: "Normal", value: 400 },
        { name: "Thin", value: 100 },
        { name: "Extra Light", value: 200 },
        { name: "Light", value: 300 },
        { name: "Medium", value: 500 },
        { name: "Semi Bold", value: 600 },
        { name: "Bold", value: 700 },
        { name: "Extra Bold", value: 800 },
    ];
    const TextDecoration = [
        "none",
        "underline",
        "line-through",
        "overline",
    ];
    const handleColumnNameFilter = (column, obj) => {
        if (selectedColumn.includes(column)) {
            setDeselectedColumns((prev) => [...prev, column]);
            const newColumns = selectedColumn.filter((filter) => filter !== column);
            onPropertyChange({ columns: newColumns });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    columns: newColumns,
                },
            }));
            setSelectedColumn(selectedColumn.filter((filter) => filter !== column));
            setFlag(false);
        } else {
            setDeselectedColumns((prev) => prev.filter((item) => item !== column));
            onPropertyChange({ columns: [...selectedColumn, column] });
            setSelectedComponent((prev) => ({
                ...prev,
                properties: {
                    ...prev.properties,
                    columns: [...selectedColumn, column],
                },
            }));
            setSelectedColumn([...selectedColumn, column]);
            setFlag(false);
        }
    };

    const handlePositionChange = (e, property) => {
        const newValue = parseInt(e.target.value, 10);
        let updatedProperties = { [property]: newValue };
        if (newValue < 0) {
            updatedProperties[property] = 0;
        }
        if (property === "top") {
            updatedProperties.bottom =
                reportAreaRef.current.clientHeight -
                newValue -
                component.properties.height;
            // Ensure bottom doesn't go below 0
            if (updatedProperties.bottom < 0) {
                updatedProperties.bottom = 0;
                updatedProperties.top =
                    reportAreaRef.current.clientHeight - component.properties.height;
            }
        } else if (property === "bottom") {
            updatedProperties.top =
                reportAreaRef.current.clientHeight -
                newValue -
                component.properties.height;
            // Ensure top doesn't go below 0
            if (updatedProperties.top < 0) {
                updatedProperties.top = 0;
                updatedProperties.bottom =
                    reportAreaRef.current.clientHeight - component.properties.height;
            }
        } else if (property === "left") {
            updatedProperties.right =
                reportAreaRef.current.clientWidth -
                newValue -
                component.properties.width;
            if (updatedProperties.right < 0) {
                updatedProperties.right = 0;
                updatedProperties.left =
                    reportAreaRef.current.clientWidth - component.properties.width;
            }
        } else if (property === "right") {
            updatedProperties.left =
                reportAreaRef.current.clientWidth -
                newValue -
                component.properties.width;
            if (updatedProperties.left < 0) {
                updatedProperties.left = 0;
                updatedProperties.right =
                    reportAreaRef.current.clientWidth - component.properties.width;
            }
        }

        onPropertyChange(updatedProperties);
        setSelectedComponent((prev) => ({
            ...prev,
            properties: {
                ...prev.properties,
                ...updatedProperties,
            },
        }));
        setFlag(false);
    };
    const handleColumnPropertyChange = (columnName, updatedProperties) => {
        setSelectedComponent((prev) => {
            const updatedColumnProperties = prev.properties.measures.map(
                (col) =>
                    col.name === columnName ? { ...col, ...updatedProperties } : col
            );
            return {
                ...prev,
                properties: {
                    ...prev.properties,
                    measures: updatedColumnProperties,
                },
            };
        });
        const updatedColumnProperties = component.properties.measures.map(
            (col) =>
                col.name === columnName ? { ...col, ...updatedProperties } : col
        );
        onPropertyChange({ measures: updatedColumnProperties });
        setFlag(false);
    };
    const handleColorPickerToggle = (columnName, pickerType) => {
        setColorPickerState((prev) => ({
            ...prev,
            [columnName]: {
                ...prev[columnName],
                [pickerType]: !prev[columnName]?.[pickerType],
            },
        }));
    };
    const handleClickOutside = (event) => {
        // if (
        //     backgroundColorRef.current &&
        //     !backgroundColorRef.current.contains(event.target) &&
        //     textColorRef.current &&
        //     !textColorRef.current.contains(event.target) &&
        //     borderColorRef.current &&
        //     !borderColorRef.current.contains(event.target)
        // ) {
        //     //setColorPickerState({});
        //     setColorPickerBg(false)
        //     setColorPickerText(false);
        //     setColorPickerOpen(false);

        // }
        if (textColorRef.current && !textColorRef.current.contains(event.target)) {
            setColorPickerText(false);
        }
        if (
            backgroundColorRef.current &&
            !backgroundColorRef.current.contains(event.target)
        ) {
            setColorPickerBg(false);
        }
        if (
            borderColorRef.current &&
            !borderColorRef.current.contains(event.target)
        ) {
            setColorPickerOpen(false);
        }
        if (
            columnDropdownRef.current &&
            !columnDropdownRef.current.contains(event.target)
        ) {
            setColumnListOpen(false);
        }
        const isOutsideColorPickers = Object.keys(colorPickerRefs.current).every(
            (columnName) => {
                const refs = colorPickerRefs.current[columnName];
                return (
                    !refs ||
                    Object.keys(refs).every((pickerType) => {
                        const ref = refs[pickerType];
                        return ref && !ref.contains(event.target);
                    })
                );
            }
        );

        if (isOutsideColorPickers) {
            setColorPickerState({});
        } else {
            // Only close color pickers that are not being targeted
            setColorPickerState((prevState) => {
                const newState = { ...prevState };
                Object.keys(colorPickerRefs.current).forEach((columnName) => {
                    const refs = colorPickerRefs.current[columnName];
                    Object.keys(refs).forEach((pickerType) => {
                        const ref = refs[pickerType];
                        if (ref && !ref.contains(event.target)) {
                            if (newState[columnName]) {
                                newState[columnName][pickerType] = false;
                            }
                        }
                    });
                });
                return newState;
            });
        }
    };
    const handleItemToggle = (itemName) => {
        setOpenItem((prevOpenItem) =>
            prevOpenItem === itemName ? null : itemName
        );
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // const [openSection, setOpenSection] = React.useState(null);
    const handleToggle = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <>
            <div className="mt-2">

                <SidebarSection
                    title="Position"
                    subSection={false}
                    isOpen={openSection === "position"}
                    onToggle={() => handleToggle("position")}
                >
                    <span className="d-flex px-2 py-2 justify-center">
                        <span className="d-flex flex-col">
                            <span className="d-flex  py-1">

                                <div className="mx-2">
                                    <p className="m-0 sidebar-section-label whitespace-nowrap">
                                        Left
                                    </p>
                                    <input
                                        type="number"
                                        className=" block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]  "
                                        value={Math?.round(component?.properties?.left)}
                                        onChange={(e) => handlePositionChange(e, "left")}
                                    />
                                </div>
                                <div className="mx-2">
                                    <p className="m-0 sidebar-section-label whitespace-nowrap">
                                        Top
                                    </p>
                                    <input
                                        type="number"
                                        className=" block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]  "
                                        value={Math?.round(component?.properties?.top)}
                                        onChange={(e) => handlePositionChange(e, "top")}
                                    />
                                </div>
                            </span>
                            <span className="d-flex self-end py-1">
                                <div className="mx-2">
                                    <p className="m-0 sidebar-section-label whitespace-nowrap">
                                        Right
                                    </p>
                                    <input
                                        type="number"
                                        className=" block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]  "
                                        value={Math?.round(component?.properties?.right)}
                                        onChange={(e) => handlePositionChange(e, "right")}
                                    />
                                </div>
                                <div className="mx-2">
                                    <p className="m-0 sidebar-section-label whitespace-nowrap">
                                        Bottom
                                    </p>
                                    <input
                                        type="number"
                                        className=" block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]  "
                                        value={Math?.round(component?.properties?.bottom)}
                                        onChange={(e) => handlePositionChange(e, "bottom")}
                                    />
                                </div>
                            </span>
                        </span>
                    </span>
                </SidebarSection>
                <SidebarSection
                    title="Appearance"
                    subSection={false}
                    isOpen={openSection === "appearance"}
                    onToggle={() => handleToggle("appearance")}
                >
                    <span className="justify-center py-1 pt-2 space-x-1 flex flex-row ">
                        <div className="flex flex-col ">
                            <p className="m-0 sidebar-section-label whitespace-nowrap text-gray-700">
                                Height
                            </p>
                            <input
                                type="number"
                                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                                value={component?.properties?.height || 0}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    onPropertyChange({ height: Number(newValue) });
                                    setSelectedComponent((prev) => ({
                                        ...prev,
                                        properties: {
                                            ...prev.properties,
                                            height: Number(newValue),
                                        },
                                    }));
                                    setFlag(false);
                                }}
                            />
                        </div>
                        <div className="flex flex-col space-x-2">
                            <p className="m-0 sidebar-section-label whitespace-nowrap  pl-2">
                                Width
                            </p>
                            <input
                                type="number"
                                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                                value={component?.properties?.width || 0}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    onPropertyChange({ width: Number(newValue) });
                                    setSelectedComponent((prev) => ({
                                        ...prev,
                                        properties: {
                                            ...prev.properties,
                                            width: Number(newValue),
                                        },
                                    }));
                                    setFlag(false);
                                }}
                            />
                        </div>
                    </span>

                    <span className="flex flex-col py-1 ml-[27px]">
                        <p className="m-0 sidebar-section-label whitespace-nowrap self-start">
                            Border:
                        </p>
                        <span className="flex gap-2">
                            <span>
                                <select
                                    value={component?.properties?.borderType || "solid"}
                                    onChange={(e) => {
                                        onPropertyChange({ borderType: e.target.value });
                                        setSelectedComponent((prev) => ({
                                            ...prev,
                                            properties: {
                                                ...prev.properties,
                                                borderType: e.target.value,
                                            },
                                        }));
                                        setFlag(false);
                                    }}
                                    className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-[120px]"
                                >
                                    {["Solid", "Dotted", "Dashed", "Double", "None"].map(
                                        (item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        )
                                    )}
                                </select>
                            </span>

                            <span
                                ref={borderColorRef}
                                className="relative flex"
                            >

                                <span className="flex items-center border-custome">
                                    <div
                                        className="w-5 h-5 sketch-border"
                                        style={{
                                            backgroundColor:
                                                component?.properties?.borderColor || "#000000",
                                        }}
                                    />

                                    <div
                                        className="w-5 h-4 flex items-center cursor-pointer"
                                        onClick={() => {
                                            setColorPickerOpen(!colorPickerOpen);
                                            setColorPickerBg(false);
                                            setColorPickerText(false);
                                        }}
                                    >
                                        <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                                    </div>
                                </span>
                                {colorPickerOpen && (
                                    <div className="absolute z-10 right-0 mt-[26px] cursor-pointer">
                                        <SketchPicker
                                            color={
                                                component?.properties?.borderColor || "rgba(0,0,0,1)"
                                            }
                                            onChange={(color) => {
                                                const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                                onPropertyChange({ borderColor: rgbaColor });
                                                setSelectedComponent((prev) => ({
                                                    ...prev,
                                                    properties: {
                                                        ...prev.properties,
                                                        borderColor: rgbaColor,
                                                    },
                                                }));
                                                setFlag(false);
                                            }}
                                            className="color-picker"
                                        />
                                    </div>
                                )}
                            </span>
                        </span>

                    </span>

                    <span className="flex gap-3">
                        <span className="d-flex flex-col py-1 ml-[27px]">
                            <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                                Border Width:
                            </p>
                            <input
                                type="number"
                                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                                value={component?.properties?.borderWidth || 1}
                                onChange={(e) => {
                                    let newValue = e.target.value;
                                    if (newValue !== "") {
                                        newValue = newValue.replace(/^0+/, "") || "0";
                                    }
                                    if (parseInt(newValue) < 0) {
                                        newValue = "0";
                                    }
                                    onPropertyChange({ borderWidth: newValue });
                                    setSelectedComponent((prev) => ({
                                        ...prev,
                                        properties: {
                                            ...prev.properties,
                                            borderWidth: newValue,
                                        },
                                    }));
                                    setFlag(false);
                                }}
                            />
                        </span>

                        <span className="d-flex py-1 flex-col ">
                            <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                                Alignment:
                            </p>
                            <select
                                value={component?.properties?.align}
                                onChange={(e) => {
                                    onPropertyChange({ align: e.target.value });
                                    setSelectedComponent((prev) => ({
                                        ...prev,
                                        properties: {
                                            ...prev.properties,
                                            align: e.target.value,
                                        },
                                    }));
                                    setFlag(false);
                                    //handleColumnPropertyChange(item.name, { align: e.target.value })
                                }}
                                className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-[80px] h-[22.5px]"
                            >
                                {["Left", "Center", "Right"].map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </span>

                    <span className="flex flex-col py-1 ml-[27px]">
                        <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                            Font:
                        </p>
                        <span className="flex gap-2">
                            <span>
                                <select
                                    value={component?.properties?.fontFamily}
                                    onChange={(e) => {
                                        onPropertyChange({ fontFamily: e.target.value });
                                        setSelectedComponent((prev) => ({
                                            ...prev,
                                            properties: {
                                                ...prev.properties,
                                                fontFamily: e.target.value,
                                            },
                                        }));
                                        setFlag(false);
                                        //handleColumnPropertyChange(item.name, { align: e.target.value })
                                    }}
                                    className="block px-0.5 py-0.5 sidebar-section-text bg-white  cursor-pointer input-border-custome rounded-[5px] w-fit"
                                >
                                    {Fonts.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </span>

                            <span
                                ref={textColorRef}
                                className="relative flex "
                            >

                                <span className="flex items-center border-custome ">
                                    <div
                                        className="w-5 h-5 sketch-border"
                                        style={{
                                            backgroundColor: component?.properties?.textColor || "#fff",
                                        }}
                                    />
                                    <div
                                        className="w-5 h-4 flex items-center cursor-pointer"
                                        onClick={() => {
                                            setColorPickerText(!colorPickerText);
                                            setColorPickerBg(false);
                                            setColorPickerOpen(false);
                                        }}
                                    >
                                        <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                                    </div>
                                </span>
                                {colorPickerText && (
                                    <div className="absolute z-10 right-0 top-0 mt-[26px] cursor-pointer">
                                        <SketchPicker
                                            color={
                                                component?.properties?.textColor || "rgba(0, 0, 0, 1)"
                                            }
                                            onChange={(color) => {
                                                const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                                onPropertyChange({ textColor: rgbaColor });
                                                setSelectedComponent((prev) => ({
                                                    ...prev,
                                                    properties: {
                                                        ...prev.properties,
                                                        textColor: rgbaColor,
                                                    },
                                                }));
                                                setFlag(false);
                                            }}
                                            className="color-picker"
                                        />
                                    </div>
                                )}
                            </span>
                        </span>

                    </span>

                    <span className="flex gap-3">
                        <span className="d-flex flex-col  py-1 ml-[27px]">
                            <p className="block m-0  whitespace-nowrap  self-start sidebar-section-label">
                                Font Size:
                            </p>
                            <input
                                type="number"
                                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                                value={component?.properties?.fontSize || 0}
                                onChange={(e) => {
                                    let newValue = e.target.value;
                                    if (newValue !== "") {
                                        newValue = newValue.replace(/^0+/, "") || "0";
                                    }
                                    if (parseInt(newValue) < 0) {
                                        newValue = "0";
                                    }
                                    onPropertyChange({ fontSize: newValue });
                                    setSelectedComponent((prev) => ({
                                        ...prev,
                                        properties: {
                                            ...prev.properties,
                                            fontSize: newValue,
                                        },
                                    }));
                                    setFlag(false);
                                }}
                            />
                        </span>

                        <span className="d-flex flex-col py-1">
                            <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                                Font Weight:
                            </p>
                            <select
                                value={component?.properties?.fontWeight}
                                onChange={(e) => {
                                    onPropertyChange({ fontWeight: e.target.value });
                                    setSelectedComponent((prev) => ({
                                        ...prev,
                                        properties: {
                                            ...prev.properties,
                                            fontWeight: e.target.value,
                                        },
                                    }));
                                    setFlag(false);
                                    //handleColumnPropertyChange(item.name, { align: e.target.value })
                                }}
                                className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-fit h-[22.5px]"
                            >
                                {fontWeights.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </span>


                    <span className="d-flex py-1 ml-[27px] flex-col pb-2">
                        <p className="m-0  whitespace-nowrap  self-start sidebar-section-label">
                            Background Color:
                        </p>

                        <span className="relative flex" ref={backgroundColorRef}>
                            <div className="flex items-center border-custome">
                                <div
                                    className="w-5 h-5 sketch-border"
                                    style={{
                                        backgroundColor:
                                            component?.properties?.backgroundColor || "rgba(255, 255, 255, 1)",
                                    }}
                                />
                                <div
                                    className="w-5 h-4 flex items-center cursor-pointer"
                                    onClick={() => {
                                        setColorPickerBg(!colorPickerBg);
                                        setColorPickerOpen(false);
                                        setColorPickerText(false);
                                    }}
                                >
                                    <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                            {colorPickerBg && (
                                <div className="absolute z-10 bottom-0 mb-[26px] cursor-pointer">
                                    <SketchPicker
                                        color={
                                            component?.properties?.backgroundColor ||
                                            "rgba(255, 255, 255, 1)"
                                        }
                                        onChange={(color) => {
                                            const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                            onPropertyChange({ backgroundColor: rgbaColor });
                                            setSelectedComponent((prev) => ({
                                                ...prev,
                                                properties: {
                                                    ...prev.properties,
                                                    backgroundColor: rgbaColor,
                                                },
                                            }));
                                            setFlag(false);
                                        }}
                                        className="color-picker"
                                    />
                                </div>
                            )}
                        </span>
                    </span>
                </SidebarSection>
                <SidebarSection
                    title="Measures"
                    subSection={false}
                    isOpen={openSection === "measures"}
                    onToggle={() => handleToggle("measures")}
                >
                    <span className="d-flex flex-col gap-1 items-center justify-center px-2 py-2 pt-2">
                        <div className="w-full">
                            {component?.properties?.measures
                                // ?.filter((item) => selectedColumn.includes(item.name))
                                .map((item, index) => (
                                    <SidebarSection
                                        title={item?.name}
                                        subSection={true}
                                        isOpen={openItem === index}
                                        onToggle={() => handleItemToggle(index)}
                                    >
                                        <>
                                            <span className="flex py-1 flex-col ml-[19px]">
                                                <p className="m-0  whitespace-nowrap sidebar-section-label self-start">
                                                    Font:
                                                </p>
                                                <span className="flex gap-1">
                                                    <span>
                                                        <select
                                                            value={
                                                                item?.fontFamily
                                                                    ? item?.fontFamily
                                                                    : component?.properties?.fontFamily
                                                            }
                                                            onChange={(e) => {
                                                                handleColumnPropertyChange(item.name, {
                                                                    fontFamily: e.target.value,
                                                                });
                                                            }}
                                                            className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer "
                                                        >
                                                            {/* <option value={"Inter"}>
                                                                Select Font Family
                                                            </option> */}
                                                            {Fonts.map((item, index) => (
                                                                <option key={index} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </span>
                                                    <div
                                                        className="relative flex "
                                                        ref={(el) =>
                                                        (colorPickerRefs.current[item.name] = {
                                                            ...(colorPickerRefs.current[item.name] || {}),
                                                            textColor: el,
                                                        })
                                                        }
                                                    >
                                                        <span className="flex items-center border-custome">
                                                            <div
                                                                className="w-5 h-5 sketch-border"
                                                                style={{
                                                                    backgroundColor:
                                                                        item.textColor ||
                                                                        component?.properties?.textColor,
                                                                }}
                                                            />

                                                            <div
                                                                className="w-4 h-4 flex items-center justify-center cursor-pointer"
                                                                onClick={() => {
                                                                    handleColorPickerToggle(
                                                                        item.name,
                                                                        "textColor"
                                                                    );
                                                                }}
                                                            >
                                                                <KeyboardArrowDownIcon
                                                                    style={{ fontSize: "20px" }}
                                                                />
                                                            </div>
                                                        </span>
                                                        {colorPickerState[item.name]?.textColor && (
                                                            <div className="absolute z-10 top-0 right-0 mt-[26px] cursor-pointer">
                                                                <SketchPicker
                                                                    color={
                                                                        item.textColor ||
                                                                        component?.properties?.textColor
                                                                    }
                                                                    onChange={(color) => {
                                                                        const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                                                        handleColumnPropertyChange(item.name, {
                                                                            textColor: rgbaColor,
                                                                        });
                                                                    }}
                                                                    className="color-picker"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </span>
                                            </span>
                                            <div className="d-flex flex-col py-1 ml-[19px]">
                                                <span>
                                                    <p className="block  m-0 whitespace-nowrap sidebar-section-label self-start">
                                                        Font Size:
                                                    </p>
                                                    <span className="d-flex gap-1">
                                                        <input
                                                            type="number"
                                                            className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[80px]"
                                                            value={
                                                                item?.fontSize
                                                                    ? item?.fontSize
                                                                    : component?.properties?.fontSize
                                                            }
                                                            onChange={(e) => {
                                                                let newValue = e.target.value;
                                                                if (newValue !== "") {
                                                                    newValue = newValue.replace(/^0+/, "") || "0";
                                                                }
                                                                if (parseInt(newValue) < 0) {
                                                                    newValue = "0";
                                                                }
                                                                handleColumnPropertyChange(item.name, {
                                                                    fontSize: newValue,
                                                                });
                                                            }}
                                                        />
                                                        <span
                                                            className="cursor-pointer"

                                                            onClick={(e) => {
                                                                handleColumnPropertyChange(item.name, {
                                                                    isBold: !item?.isBold,
                                                                });
                                                            }}
                                                        >
                                                            <FormatBoldIcon
                                                                sx={{
                                                                    border: item?.isBold ? '1px solid #075985' : 'none',
                                                                    borderRadius: '2px'
                                                                }}
                                                            />
                                                        </span>
                                                        <span
                                                            className="cursor-pointer"

                                                            onClick={(e) => {
                                                                handleColumnPropertyChange(item.name, {
                                                                    isItalic: !item?.isItalic,
                                                                });
                                                            }}
                                                        >
                                                            <FormatItalicIcon
                                                                sx={{
                                                                    border: item?.isItalic ? '1px solid #075985' : 'none',
                                                                    borderRadius: '2px',
                                                                }}
                                                            />
                                                        </span>
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={(e) => {
                                                                handleColumnPropertyChange(item.name, {
                                                                    isUnderline: !item?.isUnderline,
                                                                });
                                                            }}
                                                        >
                                                            <FormatUnderlinedIcon
                                                                sx={{
                                                                    border: item?.isUnderline ? '1px solid #075985' : 'none',
                                                                    borderRadius: '2px',
                                                                }}
                                                            />
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex flex-col pb-2 py-1 ml-[19px]">
                                                <p className="m-0 whitespace-nowrap sidebar-section-label self-start">
                                                    Font Weight:
                                                </p>
                                                <select
                                                    value={
                                                        item?.fontWeight
                                                            ? item?.fontWeight
                                                            : component?.properties?.fontWeight
                                                    }
                                                    onChange={(e) => {
                                                        handleColumnPropertyChange(item.name, {
                                                            fontWeight: e.target.value,
                                                        });
                                                    }}
                                                    placeholder="Select font weight"
                                                    className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-fit"
                                                >
                                                    {/* <option value={"Inter"}>Select Font Weight</option> */}
                                                    {fontWeights.map((item, index) => (
                                                        <option key={index} value={item.value}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <div className="d-flex flex-col pb-2 py-1 ml-[17px]">
                                                <p className="m-0 whitespace-nowrap sidebar-section-label self-start">
                                                    Text decoration:
                                                </p>
                                                <select
                                                    value={item?.textdecoration || "Select"}
                                                    onChange={(e) => {
                                                        handleColumnPropertyChange(item.name, {
                                                            textdecoration: e.target.value
                                                        });
                                                    }}
                                                    className="block px-0.5 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] cursor-pointer w-fit"
                                                    placeholder="Select"
                                                // defaultValue={'none'}
                                                >

                                                    {TextDecoration.map((item, index) => (
                                                        <option key={index} value={item}
                                                            selected={item === 'none' ? true : false}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div> */}
                                        </>

                                    </SidebarSection>
                                ))}
                        </div>
                    </span>
                </SidebarSection>
                {/* <SidebarSection
                    title="Advance"
                    subSection={false}
                    isOpen={openSection === "advance"}
                    onToggle={() => handleToggle("advance")}
                >
                    <span className="d-flex px-2 py-2 justify-center">
                        <span className="d-flex flex-col">
                            <span className="d-flex py-2">
                                <p className="prop-label mb-1 self-end mr-2">Padding (px):</p>
                                <div className="mx-2">
                                    <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">
                                        Left
                                    </p>
                                    <input
                                        type="number"
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-16 focus:ring-blue-500 focus:border-blue-500"
                                        value={component?.properties?.paddingLeft}
                                        onChange={(e) => {
                                            let newValue = e.target.value;
                                            if (newValue !== "") {
                                                newValue = newValue.replace(/^0+/, "") || "0";
                                            }
                                            if (parseInt(newValue) < 0) {
                                                newValue = "0";
                                            }
                                            onPropertyChange({ paddingLeft: newValue });
                                            setSelectedComponent((prev) => ({
                                                ...prev,
                                                properties: {
                                                    ...prev.properties,
                                                    paddingLeft: newValue,
                                                },
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="mx-2">
                                    <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                                        Top
                                    </p>
                                    <input
                                        type="number"
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                                        value={component?.properties?.paddingTop}
                                        onChange={(e) => {
                                            let newValue = e.target.value;
                                            if (newValue !== "") {
                                                newValue = newValue.replace(/^0+/, "") || "0";
                                            }
                                            if (parseInt(newValue) < 0) {
                                                newValue = "0";
                                            }
                                            onPropertyChange({ paddingTop: newValue });
                                            setSelectedComponent((prev) => ({
                                                ...prev,
                                                properties: {
                                                    ...prev.properties,
                                                    paddingTop: newValue,
                                                },
                                            }));
                                        }}
                                    />
                                </div>
                            </span>
                            <span className="d-flex py-1 self-end">
                                <div className="mx-2">
                                    <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">
                                        Right
                                    </p>
                                    <input
                                        type="number"
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-16 focus:ring-blue-500 focus:border-blue-500"
                                        value={component?.properties?.paddingRight}
                                        onChange={(e) => {
                                            let newValue = e.target.value;
                                            if (newValue !== "") {
                                                newValue = newValue.replace(/^0+/, "") || "0";
                                            }
                                            if (parseInt(newValue) < 0) {
                                                newValue = "0";
                                            }
                                            onPropertyChange({ paddingRight: newValue });
                                            setSelectedComponent((prev) => ({
                                                ...prev,
                                                properties: {
                                                    ...prev.properties,
                                                    paddingRight: newValue,
                                                },
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="mx-2">
                                    <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">
                                        Bottom
                                    </p>
                                    <input
                                        type="number"
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded lg:w-[90px] sm:w-16  focus:ring-blue-500 focus:border-blue-500"
                                        value={component?.properties?.paddingBottom || 1}
                                        onChange={(e) => {
                                            let newValue = e.target.value;
                                            if (newValue !== "") {
                                                newValue = newValue.replace(/^0+/, "") || "0";
                                            }
                                            if (parseInt(newValue) < 0) {
                                                newValue = "0";
                                            }
                                            onPropertyChange({ paddingBottom: newValue });
                                            setSelectedComponent((prev) => ({
                                                ...prev,
                                                properties: {
                                                    ...prev.properties,
                                                    paddingBottom: newValue,
                                                },
                                            }));
                                        }}
                                    />
                                </div>
                            </span>
                        </span>
                    </span>
                    <div className="d-flex py-2 justify-center space-x-4 lg:mr-[132px] sm:mr-20">
                        <p className="m-0  whitespace-nowrap prop-label self-center">
                            Line Height (px):
                        </p>
                        <input
                            type="number"
                            className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px] focus:ring-blue-500 focus:border-blue-500"
                            value={component?.properties?.lineHeight || 1}
                            onChange={(e) => {
                                let newValue = e.target.value;
                                if (newValue !== "") {
                                    newValue = newValue.replace(/^0+/, "") || "0";
                                }
                                if (parseInt(newValue) < 0) {
                                    newValue = "0";
                                }
                                onPropertyChange({ lineHeight: newValue });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        lineHeight: newValue,
                                    },
                                }));
                                setFlag(false);
                            }}
                        />
                    </div>
                    <div className="d-flex justify-center  py-2 pb-3 space-x-4 lg:mr-[117px] sm:mr-[65px]">
                        <p className="m-0 whitespace-nowrap prop-label self-center">
                            Text decoration:
                        </p>
                        <select
                            value={component?.properties?.textdecoration}
                            onChange={(e) => {
                                onPropertyChange({ textdecoration: e.target.value });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        textdecoration: e.target.value,
                                    },
                                }));
                                setFlag(false);
                            }}
                            className="block p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded  focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value={"default"}>Select</option>
                            {TextDecoration.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </SidebarSection> */}
            </div>
        </>
    );
}

export default SummeryProperties;
