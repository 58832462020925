export const colors = {
  provider: {
    PRIMARY_COLOR: "#2F80ED",
  },
  office: {
    PRIMARY_COLOR: "#3062D4",
  },
  staff: {
    PRIMARY_COLOR: "#333333",
  },
  PROVIDER_COLOR: "#2F80ED",
  OFFICE_COLOR: "#3062D4", //'#56CCF2',
  STAFF_COLOR: "#333333",
  BLACK_COLOR: "#000",
  WHITE_COLOR: "#fff",
  SECONDARY_TEXT_COLOR: "#828282",
  ICON_INACTIVE_COLOR: "#D8D8D9",
  LIGHT_COLOR: "rgba(51, 51, 51, 0.47)",
  DANGER_COLOR: "#FE0606",
  DANGER_LIGHT_COLOR: "#FCD9D9",
  BORDER_COLOR: "#CFD6DD",
  TEXT_COLOR: "#272E35",
  SUB_TEXT_COLOR: "#555F6D",
  ICON_BOX_COLOR: "#F5F7F9",
  HOLD_COLOR: "#F2994A",
  SUCCESS_COLOR: "#27AE60",
  NO_BUTTON_COLOR: "#EB5757",
  SUCCESS_BG: "#C6F1DA",
  SUCCESS_TEXT_COLOR: "#062D1B",
};

export const fonts = {
  PRIMARY_FONT: "Inter",
  LIGHT_FONT: "Inter",
  MEDIUM_FONT: "Inter",
  SEMI_BOLD_FONT: "Inter",
  BOLD_FONT: "Inter",
  EXTRA_BOLD_FONT: "Inter",
  // PRIMARY_FONT: 'HindMadurai-Regular',
  // LIGHT_FONT: 'HindMadurai-Regular',
  // MEDIUM_FONT: 'HindMadurai-Medium',
  // SEMI_BOLD_FONT: 'HindMadurai-SemiBold',
  // BOLD_FONT: 'HindMadurai-Bold',
  // EXTRA_BOLD_FONT: 'HindMadurai-ExtraBold',
};
