import * as React from "react";
const FileSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M5.75 2.5c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h8.5c.69 0 1.25-.56 1.25-1.25V7.5h-4.25a.75.75 0 0 1-.75-.75V2.5H5.75ZM12 3.56 14.44 6H12V3.56Zm-9 .19A2.75 2.75 0 0 1 5.75 1h5.5a.75.75 0 0 1 .53.22l5 5c.141.14.22.331.22.53v9.5A2.75 2.75 0 0 1 14.25 19h-8.5A2.75 2.75 0 0 1 3 16.25V3.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default FileSvg;
