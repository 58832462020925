import * as XLSX from "xlsx";

import { Alert, Checkbox, FormControlLabel, Snackbar, Tooltip } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
    useCreateTemplatesMutation,
    useCreteUserReportMutation,
    useGetAllUserReportsMutation,
    useGetReportByIdMutation,
    useGetTemplateByIdMutation,
    useGetTemplatesByColumnsMutation,
    useGetTemplatesMutation,
    useUpdateTemplatesMutation,
    useUpdateUserReportMutation,
} from "../../../features/report/reportApiSlice";

import AddIcon from "@mui/icons-material/Add";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined'
import BarChartIcon from "@mui/icons-material/BarChart";
import Button from "@mui/material/Button";
import ChartComponent from "./components/draggableItems/ChartComponent";
import CloseIcon from "@mui/icons-material/Close";
import DatasetLinkedOutlinedIcon from '@mui/icons-material/DatasetLinkedOutlined';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { DndProvider } from "react-dnd";
import DraggableComponent from "./components/DraggableComponent";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { HTML5Backend } from "react-dnd-html5-backend";
import ImageComponent from "./components/draggableItems/ImageComponent";
import ImageIcon from "@mui/icons-material/Image";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import NotChart from "../../../assets/svgs/NotChartIcon";
import NotTable from "../../../assets/svgs/NotTableIcon";
import Papa from "papaparse";
import PropertiesPanel from "./components/PropertiesPanel";
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RemoveIcon from "@mui/icons-material/Remove";
import ReportBuildingArea from "./components/ReportBuildingArea";
import ReusableModal from "../../../components/Modals/ReusableModal";
import ReusableReportModal from "./components/ReusableReportModal";
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SettingsIcon from "@mui/icons-material/Settings";
import ShapeComponent from "./components/draggableItems/ShapeComponent";
import StorageIcon from '@mui/icons-material/Storage';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TableChartIcon from "@mui/icons-material/TableChart";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import TableComponent from "./components/draggableItems/TableComponent";
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import Tabs from "../../../../src/components/Tabs";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TextboxComponent from "./components/draggableItems/TextboxComponent";
import UploadCSVModal from "../Pivot/components/uploadCSVModal";
import classNames from "classnames";
import moment from "moment";

const ReportBuilder = () => {
    const [componentCount, setComponentCount] = useState(1);
    const [isUploadCsv, setIsUploadCsv] = useState(false)
    const [tmpName, setTmpName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [selectedTmp, setselectedTmp] = React.useState(null);
    const [templates, setTemplates] = React.useState([]);
    const [getTemplates] = useGetTemplatesMutation();
    const [creteTemplates] = useCreateTemplatesMutation();
    const [updateTemplates] = useUpdateTemplatesMutation();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("Something went wrong");
    const [severity, setSeverity] = React.useState("error");
    const [csvData, setCsvData] = React.useState([]);
    const [flag, setFlag] = React.useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const [isActiveDataSource, setIsActiveDataSource] = React.useState(false);
    const [isAvailableData, setIsAvailableData] = React.useState(false);
    const dropRef = React.useRef(null);
    const [isDisableTable, setIsDisableTable] = React.useState(false);
    const [isSaveClicked, setIsSavedClicked] = React.useState(false);
    const [components, setComponents] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(components[0]);
    const [disableRndDragging, setDisableRndDragging] = React.useState(false);
    const [isMyTemplatesOpen, setIsMyTemplatesOpen] = React.useState(false);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [isSaveAsClicked, setIsSaveAsClicked] = React.useState(false);
    const [infoview, setInfoView] = React.useState("T_INFO");
    const echartsRef = React.useRef(null);

    //upload file
    const [availableTemplates, setAvailableTemplates] = React.useState([]);
    const [fileData, setFileData] = React.useState([]);
    const [isCreateNewTemplate, setIsCreateNewTemplate] = React.useState(false);

    //My Reports
    const [isMyreportOpen, setIsMyreportOpen] = React.useState([]);
    const [myReports, setMyReports] = React.useState([]);
    const [reportId, setReportId] = React.useState(null);
    const [reportState, setReportState] = useState({
        name: "",
        description: "",
    });
    const [isNewReportActive, setIsNewReportActive] = React.useState(false);
    const [isCloseReport, setIsCloseReport] = React.useState(false);
    const [isNewReport, setIsnewReport] = React.useState(false);
    const [isSaveAsReport, setIsSaveAsReport] = React.useState(false);
    const [isNewReportClicked, setIsNewReportClicked] = React.useState(false);
    const [selectTmpToUplod, setSelectTmpToUpload] = React.useState(null);
    const [isSaveChanges, setIsSaveChanges] = React.useState(false);
    const [reportDefaultState, setReportDefaultState] = React.useState([]);
    const [isSelectReport, setIsSelectReport] = React.useState(false);
    const [isLoadingReport, setIsLodingReport] = React.useState(false);
    const [isLoadingTemplate, setIsLoadingTemplate] = React.useState(false);
    const [currentReportData, setCurrentReportData] = React.useState([]);
    const [isGetById, setIsGetById] = React.useState({ status: false, reportId: null });
    const [isUploadOpen, setisUploadOpen] = React.useState(false);
    const [isInfoModelOpen, setIsInfoModelOpen] = React.useState(false);
    const [templateInfoState, setTemplateInfoState] = React.useState({
        name: "",
        description: "",
        id: "",
        parentId: "",
        columns: [],
        isReport: false,
    });

    const [columnListOpen, setColumnListOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [getTemplatesByColumns] = useGetTemplatesByColumnsMutation();
    const [getTemplateById] = useGetTemplateByIdMutation();
    const [getAllUserReports] = useGetAllUserReportsMutation();
    const [getUserReportsById] = useGetReportByIdMutation();
    const [createUserReport] = useCreteUserReportMutation();
    const [updateUserReport] = useUpdateUserReportMutation();

    const [openSection, setOpenSection] = useState(null);
    const [isMenuExpand, setIsMenuExpand] = useState(false);
    const [isChartView, setIsChartView] = useState(true);
    const [isTableView, setIsTableView] = useState(true);
    const [isAnimated, setIsAnimated] = useState(true);

    const handleSectionClick = (sectionName) => {
        setOpenSection(openSection === sectionName ? null : sectionName);
    };
    const colors = [
        '#bc5090',
        '#ff6361',
        '#ff9d1c',
        '#6d6e75',
        '#6a5acd',
        '#483d8b',
        '#4169e1',
        '#4682b4',
        '#00bfff',
        '#1e90ff',
        '#87cefa',
        '#6495ed',
        '#006400',
        '#228b22',
        '#3cb371',
        '#a0522d',
        '#d2691e',
        '#cd5c5c',
        '#f08080',
        '#fa8072',
        '#e9967a',
        '#2e8b57',
        '#f4a460',
        '#deb887',
        '#d2b48c',
        '#20b2aa',
        '#ffdead',
        '#f5deb3',
        '#8b4513',
        '#d3d3d3',
        '#c0c0c0',
        '#a9a9a9',
        '#808080',
        '#708090',
        '#8b008b',
        '#800080',
        '#4b0082',
        '#7b68ee',
        '#3c1874',
        '#7a378b',
        '#b452cd',
        '#a020f0',
        '#9370db',
        '#5f9ea0',
        '#00ced1',
        '#40e0d0',
        '#48d1cc',
        '#00fa9a',
        '#32cd32',
        '#adff2f',
        '#9acd32',
        '#808000',
        '#6b8e23',
        '#556b2f',
        '#00ff00',
        '#7cfc00',
        '#7fff00',
        '#00ff7f',
        '#66cdaa',
        '#8fbc8f',
        '#90ee90',
        '#98fb98',
        '#ffe4c4',
        '#2f4f4f',
        '#778899',
        '#b0c4de',
        '#dcdcdc',
        '#c71585',
        '#f5f5dc',
        '#f0e68c',
        '#dcdcdc',
        '#ff1493',
        '#c71585',
        '#ff4500',
        '#ff6347',
        '#ff69b4',
        '#ffb6c1',
        '#db7093',
        '#ff8247',
        '#f0e68c',
        '#003f5c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
        '#58508d',
    ];
    const menuBgColor = 'rgb(16 85 151)'
    const DAY_MAP = {
        MONDAY: "M",
        TUESDAY: "T",
        WEDNESDAY: "W",
        THURSDAY: "Th",
        FRIDAY: "F",
        SATURDAY: "Sa",
        SUNDAY: "Su",
    };
    const parseNull = (value, replacement = "NA", type = "string") => {
        return value === null || (Array.isArray(value) && value.length === 0)
            ? replacement
            : Array.isArray(value)
                ? value.map((day) => DAY_MAP[day]).join(", ")
                : type === "date"
                    ? moment(value).format("MM/DD/YYYY")
                    : value;
    };
    const showAlert = (msg, severity) => {
        setOpen(true);
        setMessage(msg);
        setSeverity(severity);
    };
    const handleComponentDrop = async (item, size) => {
        if (size) {
            item.properties.height = size?.height;
            item.properties.width = size?.width;
        }
        item?.type === "table" ? setIsDisableTable(true) : "";
        if (!components.some((component) => component?.id === item?.id)) {
            setFlag(true);
            let propertiesI = await getDefaultProperties(item.type);
            propertiesI.left = item?.properties?.left;
            propertiesI.top = item?.properties?.top;
            const newComponent = {
                id: `component-${componentCount}`,
                type: item?.type,
                properties: propertiesI,
            };
            if (item?.type === "table") {
                if (currentReportData?.data?.length > 0) {
                    const columnProperties = getDefaultColumnsProperties(
                        Object?.keys(currentReportData?.data[0])
                    );
                    const columnTypes = getColumnTypes(currentReportData?.data);
                    const updatedColumnProperties = columnProperties?.map((item) => {
                        return {
                            ...item,
                            type: columnTypes[item?.name] || "VARCHAR",
                        };
                    });
                    const parentEle = dropRef?.current;
                    let top = 0;
                    await components.forEach((component) => {
                        const childElement = document.getElementById(component.id);
                        if (childElement) {
                            const childBottom =
                                component.properties.top + childElement.offsetHeight + 10;
                            top = Math.max(top, childBottom);
                        }
                    });
                    newComponent.properties.columns = currentReportData?.columns;
                    newComponent.properties.allColumns = Object.keys(currentReportData?.data[0]);
                    newComponent.properties.data = currentReportData?.data;
                    newComponent.properties.width = parentEle ? parentEle.offsetWidth - 50 : 1020;
                    newComponent.properties.height = 450;
                    newComponent.properties.top = top;
                    newComponent.properties.left = 20;
                    newComponent.properties.columnProperties = updatedColumnProperties;
                    newComponent.properties.name = name;
                    newComponent.properties.description = templates?.find((item) => item?.id === currentReportData?.templateId)?.description;
                } else {
                    showAlert("Please select data source", "info");
                    setIsNewReportClicked(true);
                    return;
                }
            }
            if (item?.type === "chart") {
                if (currentReportData?.data?.length > 0) {
                    const headers = currentReportData?.columns
                    const result = generateChartData(
                        headers[0],
                        headers[1],
                        [],
                        null,
                        true,
                        false,
                        false,
                        `component-${componentCount}`
                    );
                    let top = 0;
                    await components.forEach((component) => {
                        const childElement = document.getElementById(component.id);
                        if (childElement) {
                            const childBottom =
                                component.properties.top + childElement.offsetHeight + 10;
                            top = Math.max(top, childBottom);
                        }
                    });
                    const parentEle = dropRef?.current;
                    newComponent.properties.top = top;
                    newComponent.properties.left = 10;
                    newComponent.properties.width = parentEle ? parentEle.offsetWidth - 50 : 1020;
                    newComponent.properties.height = 450;
                    newComponent.properties.columns = currentReportData?.columns;
                    newComponent.properties.allColumns = Object.keys(currentReportData?.data[0]);
                    newComponent.properties.option.series = result?.series;
                    newComponent.properties.option.xAxis.data = result?.xValues;
                    newComponent.properties.option.legend.data = result?.series?.map(
                        (series) => series.name
                    );
                    newComponent.properties.xAxis = headers[0];
                    newComponent.properties.yAxis = headers[1];
                    newComponent.properties.isLogScaleY = false;
                    newComponent.properties.templateId = currentReportData?.templateId
                } else {
                    showAlert("Please select data source", "info");
                    setIsNewReportClicked(true);
                    return;
                }
            }
            setSelectedComponent(newComponent);
            setComponents([...components, newComponent]);
            setComponentCount(componentCount + 1);
        }
    };
    const handlePropertyChange = (propertyChange) => {
        const updatedComponents = components?.map((item) => {
            if (item?.id === selectedComponent?.id) {
                return {
                    ...item,
                    properties: {
                        ...item?.properties,
                        ...propertyChange,
                    },
                };
            }
            return item;
        });
        console.log(updatedComponents, ">>> UPDATED COMPONENT");
        setComponents(updatedComponents);
    };
    const getDefaultProperties = (type) => {
        switch (type) {
            case "table":
                return {
                    height: 100,
                    width: 250,
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "solid",
                    borderColor: "#B7B7B7",
                    borderWidth: 1,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    align: "center",
                    textColor: "rgba(0,0, 0, 1)",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 13,
                    name: `Table`,
                    columns: ["Column 1", "Column 2"],
                    columnProperties: getDefaultColumnsProperties([
                        "Column 1",
                        "Column 2",
                    ]),
                    data: [{ "Column 1": "Data 1", "Column 2": "Data 2" }],
                    templateId: null,
                    description: null,
                    paddingLeft: 1,
                    paddingTop: 1,
                    paddingRight: 1,
                    paddingBottom: 1,
                    verticalAlign: "middle",
                    allColumns: [],
                    logColumns: []
                };
            case "chart":
                return {
                    chartType: 'bar',
                    //data: currentReportData?.data,
                    columns: [],
                    option: getDefaultChartOption(),
                    height: 400,
                    width: 800,
                    left: 0,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "",
                    borderColor: "",
                    borderWidth: 0,
                    name: "Chart",
                    xAxis: null,
                    yAxis: null,
                    line: null,
                    allColumns: [],
                    zAxis: [],
                    isLogScaleY: false,
                    isLogScaleZ: false,
                    logColumns: { double: [] }
                };
            case "textbox":
                return {
                    text: "",
                    height: 100,
                    width: 250,
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    borderType: "",
                    borderColor: "",
                    borderWidth: 0,
                    backgroundColor: "#fff",
                    align: "left",
                    textColor: "#000",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 15,
                    name: "Textbox",
                    paddingLeft: 1,
                    paddingTop: 1,
                    paddingRight: 1,
                    paddingBottom: 1,
                    textdecoration: "none",
                    lineHeight: 1,
                };
            case "image":
                return {
                    src: "",
                    name: "Image",
                    left: 20,
                    top: 20,
                    bottom: 20,
                    right: 20,
                    height: 214,  //170
                    width: 195,
                    borderType: "",
                    borderColor: "",
                    borderWidth: 0,
                    align: "center",
                    textColor: "#000",
                    fontFamily: "",
                    fontWeight: "",
                    fontSize: 16,
                    backgroundColor: "#fff",
                };
            case "shape":
                return { type: "circle" };
            default:
                return {
                    height: 100,
                    width: 250,
                    left: "0",
                    top: "0",
                    borderType: "solid",
                    borderColor: "#000000",
                    borderWidth: 1,
                    backgroundColor: "#fff",
                    name: `Table`,
                    columns: ["Column 1", "Column 2"],
                    data: [{ "Column 1": "Data 1", "Column 2": "Data 2" }],
                    templateId: null,
                };
        }
    };
    const getDefaultColumnsProperties = (columns) => {
        return columns.map((item, index) => {
            return {
                id: index,
                name: item,
                align: undefined,
                borderType: undefined,
                borderColor: undefined,
                borderWidth: 1,
                backgroundColor: undefined,
                textColor: undefined,
                active: true,
                fontFamily: undefined,
                fontWeight: undefined,
                fontSize: undefined,
                isIncoming: true,
                isComputedColumn: false,
                computedColumns: {}
            };
        });
    };
    const getDefaultChartOption = () => ({
        // title: {
        //     text: 'Chart',
        // },
        backgroundColor: "#fff",
        tooltip: {},
        legend: {
            left: "center",
            data: ["Example"],
        },
        xAxis: {
            data: ["A", "B", "C", "D", "E"],
            rotate: '-45',
            axisLabel: {
                interval: 0,
                rotate: '-45',
                color: "#4b5563",
                fontSize: 12,
                fontFamily: undefined,
            },
        },
        yAxis: {
            type: "value",
            min: 0,
            interval: 2,
            axisLabel: {
                color: "#4b5563",
                fontSize: 12,
                fontFamily: undefined,
            },
        },
        series: [
            {
                name: "Example",
                type: "bar",
                data: [5, 4, 4, 4, 3],
            },
        ],
    });
    const getUserTemplates = async () => {
        try {
            const result = await getTemplates().unwrap();
            setTemplates(result?.data);
        } catch (error) {
            console.log(error, "Error");
        }
    };
    const getAllReports = async () => {
        try {
            const result = await getAllUserReports().unwrap();
            setMyReports(result?.data);
        } catch (error) {
            console.log(error, "Error");
        }
    };
    const transformToJSON = (data) => {
        const headers = data ? data[0] : [];
        const rows = data?.slice(1);

        const allKeys = new Set(headers);
        return rows?.map((row) => {
            const obj = {};
            row?.forEach((cell, i) => {
                obj[headers[i]] = cell;
            });
            allKeys?.forEach((key) => {
                if (!(key in obj)) {
                    obj[key] = null;
                }
            });
            return obj;
        });
    };
    const isValidDate = (value) => {
        const date = new Date(value);
        return !isNaN(date.getTime());
    };
    const determineColumnType = (value) => {
        if (value === null || value === undefined || value === "") {
            return "VARCHAR";
        }
        if (!isNaN(value)) {
            return Number.isInteger(Number(value)) ? "INTEGER" : "FLOAT";
        }
        if (typeof value === "boolean") {
            return "BOOLEAN";
        }
        if (isValidDate(value)) {
            return "DATE";
        }
        return "VARCHAR";
    };
    const getColumnTypes = (data) => {
        const columns = Object.keys(data[0]);
        const columnTypes = {};

        columns.forEach((column) => {
            for (let row of data) {
                if (row[column] !== undefined && row[column] !== null) {
                    columnTypes[column] = determineColumnType(row[column]);
                    break;
                }
            }
        });
        return columnTypes;
    };
    const removeDuplicates = (data) => {
        const uniqueData = [];
        const seen = new Set();

        const stringifySortedObject = (obj) => {
            // Sort the keys of the object to ensure consistent string representation
            const sortedKeys = Object.keys(obj).sort();
            const sortedObj = {};
            for (const key of sortedKeys) {
                sortedObj[key] = obj[key];
            }
            return JSON.stringify(sortedObj);
        };
        for (const item of data) {
            const identifier = stringifySortedObject(item);
            if (!seen.has(identifier)) {
                seen.add(identifier);
                uniqueData.push(item);
            }
        }
        return uniqueData;
    };
    const filterDataByColumns = (data, columns) => {
        return data.map((row) => {
            let filteredRow = {};
            columns.forEach((column) => {
                filteredRow[column] = Object.prototype.hasOwnProperty.call(row, column)
                    ? row[column]
                    : ""; // Add empty string for missing columns
            });
            return filteredRow;
        });
    };
    const handleUpsertTemplate = async ({ template, isCreate, isChild }) => {
        setIsUploadCsv(false);
        setAvailableTemplates([]);
        setIsSavedClicked(false);
        setIsSaveAsClicked(false);
        if (isCreate == false) {
            try {
                if (
                    components.some((component) => component.type === "table") &&
                    selectedComponent?.type !== "table" &&
                    components.some((component) => component.type === "chart") &&
                    selectedComponent?.type !== "chart"
                ) {
                    setSeverity("info");
                    setOpen(true);
                    setMessage("Please Select table or chart to insert data!");
                    return;
                } else {
                    let NewData = [];
                    if (Array.isArray(fileData) && fileData.length > 0) {
                        NewData = [...fileData];
                    }
                    if (Array.isArray(template?.data) && template.data.length > 0) {
                        NewData = [...NewData, ...template.data];
                    }
                    NewData = removeDuplicates(NewData);
                    const columnTypes = getColumnTypes(NewData);

                    const columns = Object.keys(NewData[0]);
                    const columnProperties = getDefaultColumnsProperties(columns);
                    const updatedColumnProperties = columnProperties?.map((item) => {
                        return {
                            ...item,
                            type: columnTypes[item?.name] || "VARCHAR",
                        };
                    });
                    const filteredData = await filterDataByColumns(NewData, columns);
                    const response = await updateTemplates({
                        templateId: template?.id,
                        isOverride: true,
                        tableData: filteredData,
                    }).unwrap();
                    setOpen(true);
                    setSeverity("success");
                    setMessage("Template updated successfully");

                    // if (isNewReportActive || reportId !== null) {
                    //     setSelectedTemplate(null)
                    //     setCurrentReportData(filteredData)
                    // } else {
                    //     setSelectedTemplate({
                    //         id: template?.id,
                    //         name: templates?.find((item) => item?.id === template?.id)?.name,
                    //     });
                    // }
                    await getUserTemplates();
                    const tmpNm = templates?.find(
                        (item) => item?.id === template?.id
                    )?.name;
                    const tmpDes = templates?.find(
                        (item) => item?.id === template?.id
                    )?.description;
                    const newComponent = {
                        id: `component-${componentCount}`,
                        type: "table", // Adjust type based on uploaded file type
                        properties: {
                            ...getDefaultProperties("table"),
                            columns: columns,
                            data: filteredData,
                            templateId: template?.id,
                            width: 1020,
                            height: 450,
                            left: 20,
                            columnProperties: updatedColumnProperties,
                            name: tmpNm,
                            description: tmpDes,
                        },
                    };
                    if (!isNewReportActive && reportId === null) {
                        setComponents([newComponent]);
                        setComponentCount(componentCount + 1);
                        setSelectedComponent(newComponent);
                        setSelectedTemplate({
                            id: template?.id,
                            name: templates?.find((item) => item?.id === template?.id)?.name,
                        });
                    } else {
                        if (components.length <= 0) {
                            setComponents([...components, newComponent]);
                            setComponentCount(componentCount + 1);
                            setSelectedComponent(newComponent);
                            setCurrentReportData({ data: filteredData, isNewReport: false, templateId: template?.id });
                        } else if (
                            components?.length > 0 &&
                            components.some((component) => component.type === "table") &&
                            selectedComponent?.type === "table"
                        ) {
                            setSelectedComponent((prev) => {
                                const data = {
                                    ...prev,
                                    properties: {
                                        ...(prev?.properties || getDefaultProperties("table")),
                                        columns: columns,
                                        data: filteredData,
                                        templateId: template?.id,
                                        columnProperties: updatedColumnProperties,
                                        name: tmpNm,
                                        description: tmpDes,
                                    },
                                };
                                return data;
                            });
                            handlePropertyChange({
                                columns: columns,
                                columnProperties: updatedColumnProperties,
                                data: filteredData,
                                templateId: template?.id,
                                name: tmpNm,
                                description: tmpDes,
                            });
                        } else if (
                            components?.length > 0 &&
                            components.some((component) => component.type === "table") &&
                            selectedComponent?.type !== "table"
                        ) {
                            const newComponents = components?.map((item) => {
                                if (item?.type == "table") {
                                    item.properties.columns = columns;
                                    item.properties.data = filteredData;
                                    item.properties.templateId = template?.id;
                                    item.properties.columnProperties = updatedColumnProperties;
                                    item.properties.name = tmpNm;
                                    item.properties.description = tmpDes;
                                }
                                return item;
                            });
                            setComponents(newComponents);
                            setCurrentReportData({ data: filteredData, isNewReport: false });
                        } else {
                            let top = 0;
                            await components.forEach((component) => {
                                const childElement = document.getElementById(component.id);
                                if (childElement) {
                                    const childBottom =
                                        component.properties.top + childElement.offsetHeight + 10;
                                    top = Math.max(top, childBottom);
                                }
                            });
                            newComponent.properties.top = top;
                            setComponents([...components, newComponent]);
                            setComponentCount(componentCount + 1);
                            setSelectedComponent(newComponent);
                            setCurrentReportData({ data: filteredData, isNewReport: false });
                        }
                    }
                }
                setFlag(true);
            } catch (err) {
                if (err?.data?.message?.includes("already exists")) {
                    setMessage(
                        "The template name already exists. Please choose a different name."
                    );
                } else if (err?.data?.message?.includes("syntax error")) {
                    setMessage(
                        "The uploaded file contains invalid data format or mismatched data types. Please check your file and try again."
                    );
                } else if (err?.data?.message) {
                    setMessage(err?.data?.message);
                } else {
                    setMessage("The uploaded file has missing or extra columns.");
                }
                setselectedTmp(null);
                setTmpName("");
                setDescription("");
                setSeverity("error");
                setOpen(true);
                //setLoading(false)
                return;
            }
        } else {
            setIsCreateNewTemplate(false);
            try {
                if (
                    components.some((component) => component.type === "table") &&
                    selectedComponent?.type !== "table" &&
                    components.some((component) => component.type === "chart") &&
                    selectedComponent?.type !== "chart"
                ) {
                    setSeverity("info");
                    setOpen(true);
                    setMessage("Please Select table or chart to insert data!");
                    return;
                } else {
                    const columns =
                        isChild == true
                            ? selectedComponent?.properties?.columns
                            : Object?.keys(fileData[0]);
                    const columnProperties = getDefaultColumnsProperties(columns);
                    const NewData = isChild
                        ? selectedComponent?.properties?.data
                        : fileData;
                    const columnTypes = getColumnTypes(NewData);
                    const updatedColumnProperties = columnProperties?.map((item) => {
                        return {
                            ...item,
                            type: columnTypes[item?.name] || "VARCHAR",
                        };
                    });
                    const filteredData = await filterDataByColumns(NewData, columns);
                    const result = await creteTemplates({
                        name: template?.tmpName,
                        description: template?.description,
                        tableData: filteredData,
                        parentId:
                            isChild == true
                                ? selectedComponent?.properties?.templateId
                                : null,
                    }).unwrap();
                    setOpen(true);
                    setSeverity("success");
                    setMessage("Template created successfully");
                    setTmpName("");
                    setDescription("");
                    await getUserTemplates();
                    if (!isChild) {
                        // if (isNewReportActive || reportId !== null) {
                        //     setSelectedTemplate(null)
                        //     setCurrentReportData(filteredData)
                        // } else {
                        //     setSelectedTemplate({
                        //         id: template?.id,
                        //         name: templates?.find((item) => item?.id === template?.id)?.name,
                        //     });
                        // }
                        const newComponent = {
                            id: `component-${componentCount}`,
                            type: "table", // Adjust type based on uploaded file type
                            properties: {
                                ...getDefaultProperties("table"),
                                columns: columns,
                                data: filteredData,
                                templateId: result?.data?.id,
                                width: 1020,
                                height: 450,
                                left: 20,
                                columnProperties: updatedColumnProperties,
                                name: result?.data?.name,
                                description: result?.data?.description,
                            },
                        };
                        if (!isNewReportActive && reportId === null) {
                            setComponents([newComponent]);
                            setComponentCount(componentCount + 1);
                            setSelectedComponent(newComponent);
                            setSelectedTemplate({
                                id: template?.id,
                                name: templates?.find((item) => item?.id === template?.id)
                                    ?.name,
                            });
                        } else {
                            if (components.length <= 0) {
                                setComponents([...components, newComponent]);
                                setComponentCount(componentCount + 1);
                                setSelectedComponent(newComponent);
                                setCurrentReportData({
                                    data: filteredData,
                                    isNewReport: false,
                                });
                            } else if (
                                components?.length > 0 &&
                                components.some((component) => component.type === "table") &&
                                selectedComponent?.type === "table"
                            ) {
                                setSelectedComponent((prev) => {
                                    const data = {
                                        ...prev,
                                        properties: {
                                            ...(prev?.properties || getDefaultProperties("table")),
                                            columns: columns,
                                            data: filteredData,
                                            templateId: result?.data?.id,
                                            columnProperties: updatedColumnProperties,
                                            name: result?.data?.name,
                                            description: result?.data?.description,
                                        },
                                    };
                                    return data;
                                });
                                handlePropertyChange({
                                    columns: columns,
                                    data: filteredData,
                                    templateId: result?.data?.id,
                                    columnProperties: updatedColumnProperties,
                                    name: result?.data?.name,
                                    description: result?.data?.description,
                                });
                                setCurrentReportData({
                                    data: filteredData,
                                    isNewReport: false,
                                });
                            } else if (
                                components?.length > 0 &&
                                components.some((component) => component.type === "table") &&
                                selectedComponent?.type !== "table"
                            ) {
                                const newComponents = components?.map((item) => {
                                    if (item?.type == "table") {
                                        item.properties.columns = columns;
                                        item.properties.data = filteredData;
                                        item.properties.templateId = result?.data?.id;
                                        item.properties.columnProperties = updatedColumnProperties;
                                        item.properties.name = result?.data?.name;
                                        item.properties.description = result?.data?.description;
                                    }
                                    return item;
                                });
                                setComponents(newComponents);
                                setCurrentReportData({
                                    data: filteredData,
                                    isNewReport: false,
                                });
                            } else {
                                let top = 0;
                                await components.forEach((component) => {
                                    const childElement = document.getElementById(component.id);
                                    if (childElement) {
                                        const childBottom =
                                            component.properties.top + childElement.offsetHeight + 10;
                                        top = Math.max(top, childBottom);
                                    }
                                });
                                newComponent.properties.top = top;
                                setComponents([...components, newComponent]);
                                setComponentCount(componentCount + 1);
                                setSelectedComponent(newComponent);
                                setCurrentReportData({
                                    data: filteredData,
                                    isNewReport: false,
                                });
                            }
                        }
                    }
                }
                getUserTemplates();
                setFlag(true);
            } catch (err) {
                if (
                    err?.data?.message &&
                    err?.data?.message?.includes("already exists")
                ) {
                    setMessage(err?.data?.message);
                } else if (err.message?.includes("syntax error")) {
                    setMessage(
                        "The uploaded file contains invalid data format or mismatched data types. Please check your file and try again."
                    );
                } else if (err.message?.includes("INSERT")) {
                    setMessage("The uploaded file has missing or extra columns.");
                } else {
                    setMessage("An unexpected error occurred. Please try again.");
                }
                setselectedTmp(null);
                setTmpName("");
                setDescription("");
                setSeverity("error");
                setOpen(true);
                //  setLoading(false)
                return;
            }
        }
    };
    const handleChange = async (file) => {
        // setIsUploadCsv(false);
        //setLoading(true)
        setIsActiveDataSource(true);
        try {
            const fileSizeInKB = file.size / 1024;
            const fileSizeInMB = fileSizeInKB / 1024;
            console.log(`File size: ${fileSizeInKB.toFixed(2)} KB`);
            console.log(`File size: ${fileSizeInMB.toFixed(2)} MB`);
            if (fileSizeInMB > 3) {
                setOpen(true);
                setMessage("File size is more than 3 MB.");
                return new Error("File size is more than 3 MB.");
            } else {
                if (file) {
                    const fileExtension = file.name.split(".").pop().toLowerCase();
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        let jsonData;
                        if (fileExtension === "csv") {
                            const data = e.target.result;
                            const parsedData = Papa.parse(data, { header: true });
                            if (
                                parsedData.data.length > 0 &&
                                Object.keys(parsedData.data[0]).length > 0
                            ) {
                                const headers = Object.keys(parsedData.data[0]);
                                const values = parsedData.data.map((row) => Object.values(row));
                                jsonData = [headers, ...values];
                                const headers2 = jsonData[0]?.filter(
                                    (header) =>
                                        header !== "" && header !== undefined && header !== null
                                );
                                const adjustedData = jsonData?.map((row, rowIndex) => {
                                    if (rowIndex === 0) return headers2;
                                    return row?.filter(
                                        (_, colIndex) =>
                                            headers2[colIndex] !== "" &&
                                            headers2[colIndex] !== undefined &&
                                            headers2[colIndex] !== null
                                    );
                                });
                                jsonData = adjustedData;
                            } else {
                                setOpen(true);
                                setSeverity("error");
                                setMessage("Something went wrong");
                            }
                        }
                        // else {
                        //     const data = new Uint8Array(e.target.result);
                        //     const workbook = XLSX.read(data, { type: "array" });
                        //     const sheetName = workbook.SheetNames[0];
                        //     const worksheet = workbook.Sheets[sheetName];
                        //     jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                        //     const headers2 = jsonData[0]?.filter(
                        //         (header) =>
                        //             header !== "" && header !== undefined && header !== null
                        //     );
                        //     const adjustedData = jsonData.map((row, rowIndex) => {
                        //         if (rowIndex === 0) return headers2;
                        //         return row?.filter(
                        //             (_, colIndex) =>
                        //                 jsonData[0][colIndex] !== "" &&
                        //                 jsonData[0][colIndex] !== undefined &&
                        //                 jsonData[0][colIndex] !== null
                        //         );
                        //     });
                        //     jsonData = adjustedData;
                        // }
                        else {
                            const data = new Uint8Array(e.target.result);
                            const workbook = XLSX.read(data, { type: 'array' });
                            const sheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[sheetName];
                            jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                            // Replace empty cells with "NA"
                            jsonData.forEach((row, rowIndex) => {
                                row.forEach((cell, colIndex) => {
                                    if (cell === "" || cell === undefined || cell === null || cell === " ") {
                                        row[colIndex] = "NA";
                                    }
                                });
                            });

                            const headers = jsonData[0];
                            const dataRows = jsonData.slice(1);

                            // Create a new array with the data in the format you want
                            const formattedData = dataRows.map((row) => {
                                const obj = {};
                                headers.forEach((header, index) => {
                                    obj[header] = row[index];
                                });
                                return obj;
                            });

                            // Use the formattedData array as needed
                            console.log(formattedData);
                        }
                        jsonData = jsonData?.filter((row) =>
                            row.some(
                                (cell) => cell !== "" && cell !== undefined && cell !== null
                            )
                        );
                        const headers = jsonData[0];
                        // const analysis = generateChartData(jsonData, headers[1], headers[0]);
                        const jsonLikeData = await transformToJSON(jsonData);
                        setFileData(jsonLikeData.map((row) => {
                            return Object.fromEntries(Object.entries(row).map(([key, value]) => {
                                if (value === undefined || value === null) {
                                    return [key, "NA"];
                                }
                                return [key, value];
                            }));
                        }));
                        if (!jsonData) {
                            setOpen(true);
                            setSeverity("error");
                            setMessage("Something went wrong.");
                            setIsUploadCsv(false);
                            return;
                        }
                        const result = await getTemplatesByColumns({
                            columns: jsonData[0],
                        });
                        if (result?.data?.data.length <= 0) {
                            setIsCreateNewTemplate(true);
                            setAvailableTemplates([]);
                        } else {
                            setIsCreateNewTemplate(false);
                            setAvailableTemplates(result?.data?.data);
                        }
                        await getUserTemplates();
                        setCsvData(jsonData);
                        // setTotals([{ name: "Total Records Found", value: jsonLikeData?.length }])
                        setTmpName("");
                        setselectedTmp(null);
                        setDescription("");
                    };
                    reader.onerror = (error) => {
                        console.error("Error reading file:", error);
                    };
                    if (fileExtension === "csv") {
                        reader.readAsText(file);
                    } else {
                        reader.readAsArrayBuffer(file);
                    }
                    //reader.readAsArrayBuffer(file);
                }
            }
        } catch (err) {
            setOpen(true);
            setSeverity("error");
            setMessage("Something went wrong");
        }
    };
    const extractLogColumns = (components) => {
        const logColumns = [];

        components.forEach(component => {
            if (component.type === 'chart' && component.properties.logColumns) {
                // Extract double log columns
                const doubleLogs = component.properties.logColumns.double || [];
                logColumns.push(...doubleLogs);

                // Check for zAxis log column
                if (component.properties.logColumns.zAxis) {
                    logColumns.push(component.properties.logColumns.zAxis);
                }

                // Check for yAxis log column
                if (component.properties.logColumns.yAxis) {
                    logColumns.push(component.properties.logColumns.yAxis);
                }
            }
        });

        return [...new Set(logColumns)]; // Return unique log columns
    };

    const generateChartData = (xHeader, yHeader, zHeaders, lineHeader, isSetColors, isLogScaleY, isLogScaleZ, id) => {
        // Generate bar chart data
        const data = currentReportData?.data
        const allLogColumns = extractLogColumns(components?.filter((item) => (item?.type === 'chart' && item?.id !== id)))

        // isLogScaleY == true ? allLogColumns.push(`Log(${yHeader})`) : "";
        // isLogScaleZ == true && lineHeader === null ? allLogColumns.push(`Log(${zHeaders[0]})`) : allLogColumns.pop(`Log(${zHeaders[0]})`);
        // isLogScaleZ == true && lineHeader !== null ? allLogColumns.push(`Log(${(lineHeader)})`) : allLogColumns.pop(`Log(${(lineHeader)})`);

        console.log(allLogColumns, ">>> all Columnsssss");

        const combinedLogColumns = [...new Set([...allLogColumns])];

        const CheckY = components?.filter((item) => ((item?.type === 'chart' && item?.id !== id) && (item?.properties?.yAxis === yHeader && item?.properties?.isLogScaleY === true)));
        const CheckZ = components?.filter((item) => ((item?.type === 'chart' && item?.id !== id) &&
            ((item?.properties?.zAxis[0] === zHeaders[0] ||
                item?.properties?.line === zHeaders[0] ||
                item?.properties?.zAxis[0] === lineHeader ||
                (item?.properties?.line !== null && item?.properties?.line === lineHeader))
                && item?.properties?.isLogScaleZ === true)))

        // updateLogTable([CheckY?.map((item) => item?.properties?.yAxis), CheckZ?.properties?.zAxis?.map((item) => item?.properties?.zAxis[0])]);
        const barResult = {};
        const yAxisColors = new Set();
        const lineResult = {};
        // Count occurrences of xHeader and yHeader combinations
        data.forEach((row) => {
            const xValue = row[xHeader];
            const yValue = row[yHeader];
            if (!barResult[xValue]) {
                barResult[xValue] = {};
            }
            if (!barResult[xValue][yValue]) {
                barResult[xValue][yValue] = 0;
            }
            barResult[xValue][yValue]++;
        });
        data.forEach((row) => {
            const xValue = row[xHeader];
            const lineValue = row[lineHeader];
            if (!lineResult[xValue]) {
                lineResult[xValue] = {};
            }
            if (!lineResult[xValue][lineValue]) {
                lineResult[xValue][lineValue] = 0;
            }
            lineResult[xValue][lineValue]++;
        });
        const series = [];
        const xValues = [...new Set(data.map((row) => row[xHeader]))];
        let colorIndex = 0;
        const applyLogScaleY = (value) => {
            return isLogScaleY || CheckY?.length > 0 ? parseFloat(Math.log(value + 1).toFixed(2)) : value;
        };
        const applyLogScaleZ = (value) => {
            return isLogScaleZ || CheckZ?.length > 0 ? parseFloat(Math.log(value + 1).toFixed(2)) : value;
        }
        let updatedData = components.find((item) => item?.type === 'table')?.properties?.data?.map((row) => {
            const newRow = { ...row };
            // isLogScaleY = CheckY?.length > 0 ? true : isLogScaleY;
            // isLogScaleZ = CheckZ?.length > 0 ? true : isLogScaleZ;
            if (isLogScaleY || CheckY?.length > 0) {
                const yValue = newRow[yHeader];
                const occurrence = barResult[newRow[xHeader]][yValue] || 0;
                newRow[`Log(${yHeader})`] = applyLogScaleY(occurrence);
            }
            if (isLogScaleZ && zHeaders?.length || CheckZ?.length > 0) {
                zHeaders.forEach((header) => {
                    const zValue = newRow[header];
                    const occurrence = data.filter(row => row[xHeader] === newRow[xHeader] && row[header] === zValue).length || 0;
                    newRow[`Log(${header})`] = applyLogScaleZ(occurrence);
                });
            }
            if (isLogScaleZ && lineHeader !== null && zHeaders?.length <= 0 || CheckZ?.length > 0) {
                const lValue = newRow[lineHeader];
                const occurrence = lineResult[newRow[xHeader]][lValue] || 0;
                newRow[`Log(${lineHeader})`] = applyLogScaleZ(occurrence);
            }
            return newRow;
        });
        if (yHeader !== '') {
            Object.keys(barResult).forEach((xValue) => {
                Object.keys(barResult[xValue] || {}).forEach((yValue) => {
                    let seriesItem = series.find((s) => s.name === yValue && s.type === "bar");
                    if (!seriesItem) {
                        seriesItem = {
                            name: yValue,
                            type: "bar",
                            data: Array(xValues.length).fill(0),
                            itemStyle: {
                                color: colors[colorIndex % colors.length],
                            },
                            category: yHeader
                        };
                        series.push(seriesItem);
                        colorIndex++;
                    }
                    const index = xValues.indexOf(xValue);
                    if (index !== -1) {
                        // Apply log scale transformation here
                        seriesItem.data[index] = applyLogScaleY(barResult[xValue][yValue]);
                    }
                });
            });
        }

        const createSeries = (name, color, header) => ({
            name,
            type: 'bar',
            data: Array(xValues.length).fill(0),
            itemStyle: { color },
            category: header,
        });

        const fillSeriesData = (series, header, value, color) => {
            const seriesItem = createSeries(value, color, header);
            xValues.forEach((xValue, index) => {
                const filteredData = data.filter(row => row[xHeader] === xValue && row[header] === value);
                seriesItem.data[index] = applyLogScaleZ(filteredData.length); // Apply log to occurrence count
            });
            return seriesItem;
        };

        zHeaders?.filter((item) => item !== yHeader)?.forEach((header, headerIndex) => {
            const uniqueValues = [...new Set(data.map(row => row[header]))];

            uniqueValues.forEach((value, valueIndex) => {
                let color;
                do {
                    color = colors[(colorIndex + yAxisColors.size) % colors.length];
                    colorIndex++;
                } while (yAxisColors.has(color));

                series.push(fillSeriesData(series, header, value, color));
            });
        });

        if (lineHeader) {
            Object.keys(lineResult).forEach((xValue) => {
                Object.keys(lineResult[xValue] || {}).forEach((lineValue) => {
                    let seriesItem = series.find((s) => s.name === lineValue && s.type === "line");
                    if (!seriesItem) {
                        seriesItem = {
                            name: lineValue,
                            type: "line",
                            data: Array(xValues.length).fill(0),
                            itemStyle: {
                                color: colors[colorIndex % colors.length],
                            },
                            category: lineHeader
                        };
                        series.push(seriesItem);
                        colorIndex++;
                    }
                    const index = xValues.indexOf(xValue);
                    if (index !== -1) {
                        seriesItem.data[index] = applyLogScaleZ(lineResult[xValue][lineValue]);
                    }
                });
            });
        }
        const tableComponent = components.find((item) => item?.type === 'table');
        if (tableComponent) {
            tableComponent.properties.data = updatedData;
            if (isLogScaleY || CheckY?.length > 0) {
                const logColumn = `Log(${yHeader})`;
                if (!tableComponent.properties.columns.includes(logColumn)) {
                    tableComponent.properties.columns = [...tableComponent.properties.columns, logColumn];
                }
            } else {
                tableComponent.properties.columns = tableComponent?.properties?.columns?.filter(column =>
                    Object.keys(updatedData[0])?.includes(column) && column.startsWith('Log(') ? (combinedLogColumns.includes(column) ? true : false) : true);
            }
            if (isLogScaleZ || CheckZ?.length > 0) {
                if (lineHeader !== null && zHeaders?.length <= 0) {
                    const logColumn = `Log(${lineHeader})`;
                    if (!tableComponent.properties.columns.includes(logColumn)) {
                        tableComponent.properties.columns = [...tableComponent.properties.columns, logColumn];
                    }
                } else {
                    zHeaders.forEach((header) => {
                        const logColumn = `Log(${header})`;
                        if (!tableComponent.properties.columns.includes(logColumn)) {
                            tableComponent.properties.columns = [...tableComponent.properties.columns, logColumn];
                        }
                    });
                }
            } else {
                tableComponent.properties.columns = tableComponent?.properties?.columns?.filter(column =>
                    Object.keys(updatedData[0])?.includes(column) && column.startsWith('Log(') ? (combinedLogColumns.includes(column) ? true : false) : true);
            }
        }
        return { series, xValues };
    };

    const updateLogTable = (logHeaders) => {

        //  console.log(components.find((item) => item?.type === 'table')?.properties?.data, "data")
        console.log(logHeaders)

    }
    // const generateChartData = (data, xHeader, yHeader, zHeaders, lineHeader, isSetColors) => {
    //     // Generate bar chart data
    //     const barResult = {};
    //     const yAxisColors = new Set();
    //     data.forEach((row) => {
    //         const xValue = row[xHeader];
    //         const yValue = row[yHeader];
    //         if (!barResult[xValue]) {
    //             barResult[xValue] = {};
    //         }
    //         if (!barResult[xValue][yValue]) {
    //             barResult[xValue][yValue] = 0;
    //         }
    //         barResult[xValue][yValue]++;
    //     });
    //     const series = [];
    //     const xValues = [...new Set(data.map((row) => row[xHeader]))];
    //     let colorIndex = 0;
    //     // Generate bar series
    //     if (yHeader !== '') {
    //         Object.keys(barResult).forEach((xValue) => {
    //             Object.keys(barResult[xValue] || {}).forEach((yValue) => {
    //                 let seriesItem = series.find((s) => s.name === yValue && s.type === "bar");
    //                 if (!seriesItem) {
    //                     seriesItem = {
    //                         name: yValue,
    //                         type: "bar",
    //                         data: Array(xValues.length).fill(0),
    //                         itemStyle: {
    //                             color: colors[colorIndex % colors.length],
    //                         },
    //                         category: yHeader
    //                     };

    //                     series.push(seriesItem);
    //                     colorIndex++;
    //                 }
    //                 const index = xValues.indexOf(xValue);
    //                 if (index !== -1) {
    //                     seriesItem.data[index] = barResult[xValue][yValue];
    //                 }
    //             });
    //         });
    //     }
    //     const createSeries = (name, color, header) => ({
    //         name,
    //         type: 'bar',
    //         data: Array(xValues.length).fill(0),
    //         itemStyle: { color },
    //         category: header
    //     });
    //     const fillSeriesData = (series, header, value, color) => {
    //         const seriesItem = createSeries(value, color, header);
    //         xValues.forEach((xValue, index) => {
    //             const filteredData = data.filter(row => row[xHeader] === xValue && row[header] === value);
    //             seriesItem.data[index] = filteredData.length; // Count occurrences
    //         });

    //         return seriesItem;
    //     };
    //     zHeaders?.filter((item) => item !== yHeader)?.forEach((header, headerIndex) => {
    //         // Get unique values for the current header
    //         const uniqueValues = [...new Set(data.map(row => row[header]))];

    //         uniqueValues.forEach((value, valueIndex) => {
    //             let color;
    //             do {
    //                 color = true ? colors[(colorIndex + yAxisColors.size) % colors.length] : '#000';
    //                 colorIndex++;
    //             } while (yAxisColors.has(color))
    //             //const color = true ? colors[(headerIndex * uniqueValues.length + valueIndex ) % colors.length] : '#000';
    //             series.push(fillSeriesData(series, header, value, color));
    //         });
    //     });
    //     // Generate line series if lineHeader is provided
    //     if (lineHeader) {
    //         const lineResult = {};
    //         data.forEach((row) => {
    //             const xValue = row[xHeader];
    //             const lineValue = row[lineHeader];
    //             if (!lineResult[xValue]) {
    //                 lineResult[xValue] = {};
    //             }
    //             if (!lineResult[xValue][lineValue]) {
    //                 lineResult[xValue][lineValue] = 0;
    //             }

    //             lineResult[xValue][lineValue]++;
    //         });

    //         // Generate line series
    //         Object.keys(lineResult).forEach((xValue) => {
    //             Object.keys(lineResult[xValue] || {}).forEach((lineValue) => {
    //                 let seriesItem = series.find((s) => s.name === lineValue && s.type === "line");
    //                 if (!seriesItem) {
    //                     seriesItem = {
    //                         name: lineValue,
    //                         type: "line",
    //                         data: Array(xValues.length).fill(0),
    //                         itemStyle: {
    //                             color: colors[colorIndex % colors.length],
    //                         },
    //                         category: lineHeader
    //                     };
    //                     series.push(seriesItem);
    //                     colorIndex++;
    //                 }
    //                 const index = xValues.indexOf(xValue);
    //                 if (index !== -1) {
    //                     seriesItem.data[index] = lineResult[xValue][lineValue];
    //                 }
    //             });
    //         });
    //     }
    //     return { series, xValues };
    // };
    const generatePieChartData = (yHeader) => {
        const data = currentReportData?.data
        const result = {};
        // Aggregate data based on yHeader
        data.forEach(row => {
            const yValue = row[yHeader];
            if (!result[yValue]) {
                result[yValue] = 0;
            }
            result[yValue]++;
        });
        // Convert aggregated data into the format needed for pie chart
        const series = [{
            name: yHeader,
            type: 'pie',
            radius: '50%',
            data: Object.keys(result).map((key, index) => ({
                value: result[key],
                name: key,
                itemStyle: {
                    color: colors[index % colors.length] // Set colors for each pie slice
                }
            })),
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }];
        return { series };
    };

    const generateDoubleChartData = (xHeader, zHeaders, isSetColors, logColumns, id) => {
        // Ensure logColumns is an array
        // logColumns = Array.isArray(logColumns) ? logColumns : [];

        const filteredComponents = components.filter(item => item?.type === 'chart' && item?.id !== id);
        const extractedLogColumns = extractLogColumns(filteredComponents);

        const combinedLogColumns = [...new Set([...logColumns, ...extractedLogColumns])];

        console.log(combinedLogColumns, ">>>shfdhufhdushfyuh ");

        const data = currentReportData?.data;
        // Extract unique x-values
        const xValues = [...new Set(data.map(row => row[xHeader]))];

        // Prepare the structure to count occurrences
        const zAxisResults = {};

        // Count occurrences of xHeader and each zHeader
        data.forEach((row) => {
            const xValue = row[xHeader];
            zHeaders.forEach((zHeader) => {
                const zValue = row[zHeader];

                if (!zAxisResults[xValue]) {
                    zAxisResults[xValue] = {};
                }
                if (!zAxisResults[xValue][zHeader]) {
                    zAxisResults[xValue][zHeader] = {};
                }
                if (!zAxisResults[xValue][zHeader][zValue]) {
                    zAxisResults[xValue][zHeader][zValue] = 0;
                }
                zAxisResults[xValue][zHeader][zValue]++;
            });
        });

        // Helper function to apply log scale
        const applyLogScale = (value) => {
            return value > 0 ? parseFloat(Math.log(value + 1).toFixed(2)) : 0;
        };

        // Prepare the series for the chart
        const series = [];
        let colorIndex = 0;

        // Fill series data for each zHeader
        zHeaders.forEach((zHeader, headerIndex) => {
            const uniqueValues = [...new Set(data.map(row => row[zHeader]))];

            uniqueValues.forEach((value, valueIndex) => {
                const color = true ? colors[(headerIndex * uniqueValues.length + valueIndex) % colors.length] : '#000';

                const seriesItem = {
                    name: value,
                    type: 'bar',
                    data: Array(xValues.length).fill(0),
                    itemStyle: { color },
                    category: zHeader
                };

                xValues.forEach((xValue, index) => {
                    const occurrence = zAxisResults[xValue][zHeader][value] || 0;
                    seriesItem.data[index] = logColumns.includes(`Log(${zHeader})`) ? applyLogScale(occurrence) : occurrence;
                });

                series.push(seriesItem);
            });
        });

        // Update the table component with the new data and log-transformed values
        const tableComponent = components.find((item) => item?.type === 'table');

        if (tableComponent) {
            let updatedData = [...tableComponent.properties.data];

            updatedData = updatedData.map((row) => {
                const newRow = { ...row };

                zHeaders.forEach((zHeader) => {
                    const zValue = newRow[zHeader];
                    const occurrence = data.filter(r => r[xHeader] === newRow[xHeader] && r[zHeader] === zValue).length;

                    // If log transformation is needed, apply it
                    if (logColumns.includes(`Log(${zHeader})`)) {
                        newRow[`Log(${zHeader})`] = applyLogScale(occurrence);
                    }
                });

                return newRow;
            });

            // Update table columns: include log columns if specified in logColumns array
            tableComponent.properties.data = updatedData;
            tableComponent.properties.columns = [...new Set([...tableComponent.properties.columns, ...combinedLogColumns])];

            tableComponent.properties.columns = tableComponent?.properties?.columns?.filter((item) => {
                if (item?.startsWith('Log(')) {
                    return combinedLogColumns.includes(item)
                }
                return true;
            })
        }

        // Return the updated chart series and x-axis values
        return { series, xValues };
    };

    const updateTableDataWithLogColumns = (logColumns, xHeader, headers, lineHeader) => {
        console.log(logColumns, ">>> log columnsnbbbb");
        const tableComponent = components.find((item) => item?.type === 'table');

        if (!tableComponent) {
            console.error("Table component not found.");
            return;
        }

        let updatedData = [...tableComponent.properties.data];


        const applyLogScale = (value) => {
            return parseFloat(Math.log(value + 1).toFixed(2));
        };

        updatedData = updatedData.map((row) => {
            const newRow = { ...row };
            if (logColumns?.length > 0) {
                logColumns.forEach((logColumn) => {
                    const originalColumn = logColumn.replace(/^Log\(/, '').replace(/\)$/, ''); // Extract original column name
                    const originalValue = newRow[originalColumn]; // Get the value of the original column

                    // Check if the original column exists in the data
                    if (originalValue !== undefined && !isNaN(originalValue)) {
                        newRow[logColumn] = applyLogScale(originalValue); // Add log-transformed value to the log column
                    }
                });
            }

            return newRow;
        });

        // Update the table component with the new data
        tableComponent.properties.data = updatedData;
        tableComponent.properties.columns = tableComponent?.properties?.columns?.filter(column => Object.keys(updatedData[0])?.includes(column))

        tableComponent.properties.columns = tableComponent?.properties?.columns?.filter(column => {
            // Check if the column is a log column by seeing if it starts with "Log("
            const isLogColumnInTable = column.startsWith('Log(');

            // If it's a log column, keep it only if it's in the logColumns array
            if (isLogColumnInTable) {
                return logColumns.includes(column);
            }

            // If it's not a log column, keep it (non-log columns should not be filtered out)
            return true;
        });

        console.log("Updated table data with log columns:", updatedData);
    };

    const handleComponentSelect = (item) => {
        setIsAnimated(false);
        setSelectedComponent(item);
    };
    const handleDeleteComponent = (id) => {
        setSelectedComponent(null);
        const newComponents = components?.filter(
            (component) => component?.id !== id
        );
        const hasNonTableOrChart = newComponents?.filter(
            (item) => item?.type == "chart" || item?.type == "table"
        );
        if (hasNonTableOrChart?.length <= 0) {
            setCurrentReportData({ data: [], isNewReport: false, columns: [], templateId: null });
        }
        // const hasTableComponent = newComponents.some(component => component.type === 'table');
        // const hasChartComponent = newComponents.some(component => component?.type === 'chart');
        // isTableView && !hasTableComponent ? setIsTableView(false) : "";
        // isChartView && !hasChartComponent ? setIsChartView(false) : "";
        setComponents(newComponents);
    };
    const toggleDrawer = (newOpen) => () => {
        setDrawer(newOpen);
    };
    const handleSaveReport = async ({ parentId }) => {
        if (components?.length <= 0) {
            showAlert("Report is Empty", "error");
            setReportState({ name: "", description: "" });
            setIsnewReport(false);
            return;
        }
        setIsnewReport(false);
        setSelectedComponent(null);
        const sanitizedComponents = components.map((component) => {
            if (
                (component?.type === "table" || component?.type === "chart") &&
                component?.properties
            ) {
                const { data, ...restProperties } = component.properties;
                return { ...component, properties: restProperties };
            }
            return component;
        });
        setIsSaveChanges(false);
        if (reportId !== null && !isSaveAsReport) {
            try {
                const result = await updateUserReport({
                    reportId: reportId,
                    components: sanitizedComponents,
                    isTableView: isTableView,
                    isChartView: isChartView,
                    parentId: parentId,
                });
                getAllReports();
                showAlert("Report Updated Successfully.", "success");
            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const result = await createUserReport({
                    name: reportState?.name,
                    description: reportState?.description,
                    components: sanitizedComponents,
                    isTableView: isTableView,
                    isChartView: isChartView,
                    parentId: parentId,
                }).unwrap();
                setReportState({ name: "", description: "" });
                setIsSaveAsReport(false);
                showAlert("Report Created Successfully.", "success");
                getAllReports();
                setReportId(result?.data?.id);
                setIsNewReportActive(false);
            } catch (err) {
                setReportId(null);
                setIsNewReportActive(false);
                showAlert(err?.data?.message, "error");
                setReportState({ name: "", description: "" });
                setComponents([]);
                setSelectedTemplate(null);
                setSelectedComponent(null);
            }
        }
    };
    const handleCloseReport = async () => {
        setIsnewReport(false);
        setIsCloseReport(false);
        setReportId(null);
        setIsNewReportActive(false);
        setComponents([]);
        setSelectedComponent(null);
    };
    const getReportById = async (id) => {
        setIsGetById({ status: false, reportId: null })
        setIsNewReportActive(false);
        await setComponents([]);
        try {
            const response = await getUserReportsById(id).unwrap(); // Fetch user reports by ID and unwrap the result
            setSelectedTemplate(null);
            setIsTableView(response?.data?.isTableView);
            setIsChartView(response?.data?.isChartView);
            const result = response?.data?.components || [];
            const tableComponentIndex = result.findIndex(
                (item) => item?.type === "table"
            );
            if (tableComponentIndex !== -1) {
                const tableComponent = result[tableComponentIndex];
                const columns = Object.keys(tableComponent?.properties?.data[0] || {});

                const updatedProperties = { ...tableComponent.properties, };
                const updatedComponents = [
                    ...result.slice(0, tableComponentIndex),
                    {
                        ...tableComponent,
                        properties: updatedProperties
                    },
                    ...result.slice(tableComponentIndex + 1),
                ];
                const result2 = Object.keys(updatedComponents?.find((item) => item?.type === "table")
                    ?.properties?.data[0]).filter(column => updatedComponents?.find((item) => item?.type === "table")
                        ?.properties?.columns.some(col => column === col.trim()));

                const updatedComponents2 = updatedComponents.map((item, index) => {
                    return { ...item, id: `component-${index + 1}`, properties: { ...item?.properties, columns: result2 } };
                });
                await setComponents(updatedComponents2);
                // setReportDefaultState(updatedComponents);
                setComponentCount(updatedComponents2?.length + 1);

                setCurrentReportData({
                    data: updatedComponents2?.find((item) => item?.type === "table")
                        ?.properties?.data,
                    columns: result2,
                    isNewReport: true,
                    templateId: updatedComponents2?.find((item) => item?.type === "table")
                        ?.properties?.templateId,
                });
            } else {
                const updatedComponents2 = result.map((item, index) => {
                    return { ...item, id: `component-${index + 1}` };
                });
                setCurrentReportData({
                    data:
                        updatedComponents2?.find((item) => item?.type === "chart")
                            ?.properties?.data,
                    columns: updatedComponents2?.find((item) => item?.type === "chart")
                        ?.properties?.columns,
                    isNewReport: true,
                    templateId: updatedComponents2?.find((item) => item?.type === "chart")
                        ?.properties?.templateId
                });
                setComponents(updatedComponents2);
                setComponentCount(updatedComponents2?.length + 1);
            }
            setFlag(true);
            setIsLodingReport(false);
            setReportId(response?.data?.id);
            setIsSelectReport(true);
            setSelectedComponent(null);
        } catch (error) {
            // Handle error appropriately (e.g., logging, displaying an error message)
            console.error("Error fetching report by ID:", error);
        }
    };
    // const handleNewReport = async () => {
    //     setReportId(null);
    //     setComponents([]);
    //     setSelectedTemplate(null);
    //     setSelectedComponent(null);
    // }
    const handleTemplateSelect = async ({ id, name, isFromData }) => {
        try {
            setIsLoadingTemplate(true);
            const result = await getTemplateById({ id, name }).unwrap();
            const columnTypes = getColumnTypes(result?.data);
            const columnProperties = getDefaultColumnsProperties(
                Object?.keys(result?.data[0])
            );
            const updatedColumnProperties = columnProperties?.map((item) => {
                return {
                    ...item,
                    type: columnTypes[item?.name] || "VARCHAR",
                };
            });
            const parentEle = dropRef?.current;
            const newComponent = {
                id: `component-${componentCount}`,
                type: "table", // Adjust type based on uploaded file type
                properties: {
                    ...getDefaultProperties("table"),
                    columns: Object?.keys(result?.data[0]),
                    allColumns: Object?.keys(result?.data[0]),
                    data: result?.data,
                    // width: 1020,
                    // height: '82vh',
                    width: parentEle ? parentEle.offsetWidth - 50 : 1020,
                    height: 450,
                    left: 10,
                    templateId: id,
                    columnProperties: updatedColumnProperties,
                    name: name,
                    description: templates?.find((item) => item?.id === id)?.description,
                },
            };
            const selectedCols = await Object.entries(selectedColumns)
                .filter(([key, value]) => value === true) // Filter entries with true values
                .map(([key]) => key);
            if (isFromData) {
                newComponent.properties.height = parentEle?.offsetHeight;
                setComponents([newComponent]);
                setComponentCount(componentCount + 1);
                setSelectedComponent(newComponent);
                setSelectedTemplate({ id, name });
                setIsLoadingTemplate(false);
            } else {
                setSelectedTemplate(null);
                if (
                    components.some((component) => component.type === "table") &&
                    selectedComponent?.type !== "table" &&
                    components.some((component) => component.type === "chart") &&
                    selectedComponent?.type !== "chart"
                ) {
                    setSeverity("info");
                    setOpen(true);
                    setMessage("Please Select table or chart to insert data!",);
                    return;
                } else {
                    if (components.length <= 0) {
                        newComponent.properties.columns = selectedCols;
                        setComponents([newComponent]);
                        setComponentCount(componentCount + 1);
                        setSelectedComponent(newComponent);
                        setCurrentReportData({
                            data: result?.data,
                            isNewReport: false,
                            columns: selectedCols,
                            templateId: id,
                        });
                        //setReportId(null);
                    } else if (
                        components?.length > 0 &&
                        components.some((component) => component.type === "table") &&
                        selectedComponent?.type === "table"
                    ) {
                        setSelectedComponent((prev) => {
                            const data = {
                                ...prev,
                                properties: {
                                    ...(prev?.properties || getDefaultProperties("table")),
                                    columns: selectedCols,
                                    data: result?.data,
                                    templateId: id,
                                    columnProperties: updatedColumnProperties,
                                    name: name,
                                    allColumns: Object?.keys(result?.data[0]),
                                    description: templates?.find((item) => item?.id === id)
                                        ?.description,
                                },
                            };
                            return data;
                        });
                        handlePropertyChange({
                            columns: selectedCols,
                            data: result?.data,
                            templateId: id,
                            allColumns: Object?.keys(result?.data[0]),
                            columnProperties: updatedColumnProperties,
                            name: name,
                            description: templates?.find((item) => item?.id === id)
                                ?.description,
                        });
                        setCurrentReportData({
                            data: result?.data,
                            isNewReport: false,
                            columns: selectedCols,
                            templateId: id,
                        });
                    } else if (
                        components?.length > 0 &&
                        components.some((component) => component.type === "table") &&
                        selectedComponent?.type !== "table"
                    ) {
                        const newComponents = components?.map((item) => {
                            if (item?.type == "table") {
                                item.properties.columns = selectedCols;
                                item.properties.data = result?.data;
                                item.properties.allColumns = Object?.keys(result?.data[0]);
                                item.properties.templateId = id;
                                item.properties.columnProperties = updatedColumnProperties;
                                item.properties.name = name;
                                item.properties.description = templates?.find(
                                    (item) => item?.id === id
                                )?.description;
                            }
                            return item;
                        });
                        await setComponents(newComponents);
                        setCurrentReportData({
                            data: result?.data,
                            isNewReport: false,
                            columns: selectedCols,
                            templateId: id,
                        });
                    } else {
                        let top = 0;
                        await components.forEach((component) => {
                            const childElement = document.getElementById(component.id);
                            if (childElement) {
                                const childBottom =
                                    component.properties.top + childElement.offsetHeight + 10;
                                top = Math.max(top, childBottom);
                            }
                        });
                        newComponent.properties.top = top;
                        newComponent.properties.columns = selectedCols;
                        setComponents([...components, newComponent]);
                        setComponentCount(componentCount + 1);
                        setSelectedComponent(newComponent);
                        setCurrentReportData({
                            data: result?.data,
                            isNewReport: false,
                            columns: selectedCols,
                            templateId: id,
                        });
                    }
                }
            }
            // isFromData
            //     ? setSelectedTemplate({ id, name })
            //     : setSelectedTemplate(null);
            setFlag(true);
            setIsNewReportClicked(false);
            setSelectTmpToUpload(null);
            setIsLoadingTemplate(false);
            setSelectedColumns({});
        } catch (err) {
            console.log(err);
        }
    };
    const handleNameChange = (e) => {
        const name = e.target.value;
        if (/\s/.test(name)) {
            setErrorMessage("Template name cannot contain spaces.");
        } else {
            setErrorMessage("");
        }
        setTmpName(name);
    };
    React.useEffect(() => {
        getUserTemplates();
        getAllReports();
    }, []);
    React.useEffect(() => {
        const hasTableComponent = components.some(
            (component) => component.type === "table"
        );
        setIsDisableTable(hasTableComponent);
    }, [components]);

    React.useEffect(() => {
        if (currentReportData?.data?.length > 0) {
            // const headers = Object?.keys(currentReportData?.data[0]);
            const newComponents = components?.map((item) => {
                const headers = currentReportData?.isColumnChanged ? currentReportData?.columns : currentReportData?.isNewReport ? item?.properties?.columns : currentReportData?.columns;
                if (item?.properties?.chartType === 'bar' || item?.properties?.chartType === 'barWithLine' || item?.properties?.chartType === 'stacked' || item?.properties?.chartType === 'line' || item?.properties?.chartType === 'area' || item?.properties?.chartType === 'scatter') {
                    if (item?.type === "chart") {
                        const result = generateChartData(
                            currentReportData?.isColumnChanged ?
                                (currentReportData?.columns.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers?.filter(item => item !== item?.properties?.yAxis && item !== item?.properties?.line)[0])
                                : currentReportData?.isNewReport
                                    ? item?.properties?.xAxis
                                    : headers[0],
                            currentReportData?.isColumnChanged ?
                                (currentReportData?.columns.includes(item?.properties?.yAxis) ? item?.properties?.yAxis : headers?.filter(item => item !== item?.properties?.xAxis && item !== item?.properties?.line)[1])
                                : currentReportData?.isNewReport
                                    ? item?.properties?.yAxis
                                    : headers[1],
                            currentReportData?.isColumnChanged ?
                                (item?.properties?.zAxis?.every((element) => currentReportData?.columns?.includes(element)) ? item?.properties?.zAxis : [])
                                : currentReportData?.isNewReport
                                    ? item?.properties?.zAxis
                                    : [],
                            currentReportData?.isColumnChanged && item?.properties?.chartType === 'barWithLine' ?
                                (currentReportData?.columns.includes(item?.properties?.line) ? item?.properties?.line : headers?.filter(item => item !== item?.properties?.xAxis && item !== item?.properties?.yAxis)[2])
                                : currentReportData?.isNewReport
                                    ? item?.properties?.line
                                    : null,
                            isNewReport ? false : true,
                            currentReportData?.isNewReport
                                ? item?.properties?.isLogScaleY
                                : false,
                            currentReportData?.isNewReport
                                ? item?.properties?.isLogScaleZ
                                : false,
                            item?.id
                        );
                        let options = {};
                        if (currentReportData?.isNewReport) {
                            options = {
                                ...item.properties.option,
                                series: item?.properties?.option?.series,
                                xAxis: {
                                    ...item.properties.option.xAxis,
                                    data: result?.xValues,
                                },
                                legend: {
                                    ...item.properties.option.legend,
                                    data: result?.series?.map((series) => series.name),
                                },
                            };
                        } else {
                            options = {
                                ...item.properties.option,
                                series: result?.series,
                                xAxis: {
                                    ...item.properties.option.xAxis,
                                    data: result?.xValues,
                                },
                                legend: {
                                    ...item.properties.option.legend,
                                    data: result?.series?.map((series) => series.name),
                                },
                            };
                        }
                        return {
                            ...item,
                            properties: {
                                ...item.properties,
                                // data: currentReportData?.data,
                                columns: currentReportData?.isColumnChanged ?
                                    currentReportData?.columns
                                    : currentReportData?.isNewReport
                                        ? item?.properties?.columns
                                        : currentReportData?.columns,
                                option: options,
                                xAxis: currentReportData?.isColumnChanged ?
                                    (currentReportData?.columns.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers?.filter(item => item !== item?.properties?.yAxis && item !== item?.properties?.line)[0])
                                    : currentReportData?.isNewReport
                                        ? item?.properties?.xAxis
                                        : headers[0],
                                yAxis: currentReportData?.isColumnChanged ?
                                    (currentReportData?.columns.includes(item?.properties?.yAxis) ? item?.properties?.yAxis : headers?.filter(item => item !== item?.properties?.xAxis && item !== item?.properties?.line)[1])
                                    : currentReportData?.isNewReport
                                        ? item?.properties?.yAxis
                                        : headers[1],
                                line: currentReportData?.isColumnChanged && item?.properties?.chartType === 'barWithLine' ?
                                    (currentReportData?.columns.includes(item?.properties?.line) ? item?.properties?.line : headers?.filter(item => item !== item?.properties?.xAxis && item !== item?.properties?.yAxis)[2])
                                    : currentReportData?.isNewReport
                                        ? item?.properties?.line
                                        : null,
                                zAxis: currentReportData?.isColumnChanged ?
                                    (item?.properties?.zAxis?.every((element) => currentReportData?.columns?.includes(element)) ? item?.properties?.zAxis : [])
                                    : currentReportData?.isNewReport
                                        ? item?.properties?.zAxis
                                        : [],
                                templateId: currentReportData?.templateId,
                                isLogScaleY: currentReportData?.isNewReport
                                    ? item?.properties?.isLogScaleY
                                    : false,
                                isLogScaleZ: currentReportData?.isNewReport
                                    ? item?.properties?.isLogScaleZ
                                    : false
                            },
                        };
                    }
                } else if (item?.properties?.chartType === 'double') {
                    if (item?.type === "chart") {
                        const result = generateDoubleChartData(
                            currentReportData?.isColumnChanged ?
                                (currentReportData?.columns?.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers[0])
                                : currentReportData?.isNewReport
                                    ? item?.properties?.xAxis
                                    : headers[0],
                            currentReportData?.isNewReport
                                ? item?.properties?.zAxis
                                : [headers[1]],
                            isNewReport ? false : true,
                            currentReportData?.isNewReport
                                ? item?.properties?.logColumns?.double
                                : [],
                            item?.id
                        );
                        let options = {};
                        if (currentReportData?.isNewReport) {
                            options = {
                                ...item.properties.option,
                                series: item?.properties?.option?.series,
                                xAxis: {
                                    ...item.properties.option.xAxis,
                                    data: result?.xValues,
                                },
                                legend: {
                                    ...item.properties.option.legend,
                                    data: result?.series?.map((series) => series.name),
                                },
                            };
                        } else {
                            options = {
                                ...item.properties.option,
                                series: result?.series,
                                xAxis: {
                                    ...item.properties.option.xAxis,
                                    data: result?.xValues,
                                },
                                legend: {
                                    ...item.properties.option.legend,
                                    data: result?.series?.map((series) => series.name),
                                },
                            };
                        } return {
                            ...item,
                            properties: {
                                ...item.properties,
                                //  data: currentReportData?.data,
                                columns: currentReportData?.isNewReport
                                    ? item?.properties?.columns
                                    : currentReportData?.columns,
                                option: options,
                                xAxis: currentReportData?.isColumnChanged ?
                                    (currentReportData?.columns?.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers[0])
                                    : currentReportData?.isNewReport
                                        ? item?.properties?.xAxis
                                        : headers[0],
                                yAxis: "",
                                zAxis: currentReportData?.isNewReport
                                    ? item?.properties?.zAxis
                                    : [headers[1]],
                                line: currentReportData?.isNewReport
                                    ? item?.properties?.line
                                    : null,
                                templateId: currentReportData?.templateId,
                                logColumns: currentReportData?.isNewReport
                                    ? item?.properties?.logColumns
                                    : { double: [] }
                            },
                        };
                    }
                } else {
                    if (item?.type === "chart") {
                        const result = generatePieChartData(currentReportData?.data,
                            currentReportData?.isColumnChanged ?
                                (currentReportData?.columns?.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers[0])
                                : currentReportData?.isNewReport
                                    ? item?.properties?.xAxis
                                    : headers[0])
                        let options = {};
                        if (currentReportData?.isNewReport) {
                            options = {
                                ...item.properties.option,
                                series: item?.properties?.option?.series,
                                legend: { data: item?.properties?.option?.series[0]?.data.map((item) => item?.name) },
                            };
                        } else {
                            options = {
                                ...item.properties.option,
                                series: result?.series,
                                legend: { data: result?.series[0]?.data.map((item) => item?.name) },
                            };
                        }
                        return {
                            ...item,
                            properties: {
                                ...item.properties,
                                //  data: currentReportData?.data,
                                columns: currentReportData?.isNewReport
                                    ? item?.properties?.columns
                                    : currentReportData?.columns,
                                option: options,
                                xAxis: currentReportData?.isColumnChanged ?
                                    (currentReportData?.columns?.includes(item?.properties?.xAxis) ? item?.properties?.xAxis : headers[0])
                                    : currentReportData?.isNewReport
                                        ? item?.properties?.xAxis
                                        : headers[0],
                                templateId: currentReportData?.templateId
                            },
                        };
                    }
                }
                return item;
            });
            setComponents(newComponents);
            if (selectedComponent?.type === "chart") {
                setSelectedComponent(
                    newComponents?.find((item) => item.id === selectedComponent?.id)
                );
            }
        }
    }, [currentReportData]);
    // const handleSelectAll = (e) => {
    //   const isChecked = e.target.checked;
    //   const updatedSelection = Object.keys(selectedColumns).reduce((acc, column) => {
    //     acc[column] = isChecked;
    //     return acc;
    //   }, {});
    //   setSelectedColumns(updatedSelection);
    // };
    const [isSelectAll, setIsSelectAll] = React.useState(false);
    const handleSelectAll = () => {
        const newSelectedColumns = {};
        if (!isSelectAll) {
            selectTmpToUplod?.columns?.forEach((item) => {
                newSelectedColumns[item] = true;
            });
        }
        setSelectedColumns(newSelectedColumns);
        setIsSelectAll(!isSelectAll);
    };

    return (
        <>
            {columnListOpen && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setColumnListOpen(false);
                        setIsNewReportActive(false);
                        setSelectedColumns([])
                        setIsSelectAll(false);
                        setIsChartView(true);
                        setIsTableView(true);
                    }}
                    title="Select columns from Data Source"
                >
                    <div className="px-2 py-1 no-scrollbar" style={{ minWidth: "40rem", maxHeight: '30vh', overflow: 'auto' }}>
                        {/* <label className="flex flex-row items-center gap-2 cursor-pointer">
        <input
          type="checkbox"
          className="w-4 h-4 checked:lightblue"
          checked={Object.values(selectTmpToUplod?.columns).every(value => value)}
          onChange={handleSelectAll}
        />
        <p
          className={classNames(
            "m-0 cursor-pointer text-sm font-bold"
          )}
        >
          Select All
        </p>
      </label> */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isSelectAll}
                                    onChange={(e) => {
                                        handleSelectAll()
                                    }}
                                    sx={{
                                        padding: '4px 12px'
                                    }}
                                />
                            }
                            label={<div className="whitespace-nowrap text-sm">Select All</div>}
                        />
                        {selectTmpToUplod?.columns?.map((item, index) => (
                            <div >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!selectedColumns[item]}
                                            onChange={() =>
                                                setSelectedColumns((prevCheckedItems) => ({
                                                    ...prevCheckedItems,
                                                    [item]: !prevCheckedItems[item], // Toggle the checkbox state
                                                }))

                                            }
                                            sx={{
                                                padding: '4px 12px'
                                            }}
                                        />
                                    }
                                    label={<div className="whitespace-nowrap text-sm">{item}</div>}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex mt-2 mb-2">
                        <button
                            onClick={() => {
                                handleTemplateSelect({
                                    id: selectTmpToUplod?.id,
                                    name: selectTmpToUplod?.name,
                                    isFromData: false,
                                });
                                //setReportId(null);
                                setColumnListOpen(false);
                                setIsSelectAll(false);
                                setIsTableView(true);
                                setIsChartView(true);
                            }}
                            className={`px-3 ml-2 py-1 font-bold text-sm btn-bgcolor text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed 
                                            ${selectTmpToUplod
                                    ? "opacity-100"
                                    : "opacity-60"
                                }`}
                        >
                            Apply
                        </button>
                        <button
                            onClick={() => {
                                setIsNewReportActive(false);
                                // setReportId(null);
                                setColumnListOpen(false);
                                setSelectedColumns([])
                                setIsSelectAll(false);
                                setIsChartView(true);
                                setIsTableView(true);
                            }}
                            className={`px-3 ml-2 py-1 text-sm btn-bgcolor font-bold text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed 
                                            ${selectTmpToUplod
                                    ? "opacity-100"
                                    : "opacity-60"
                                }`}
                        >
                            Cancel
                        </button>
                    </div>
                </ReusableReportModal>
            )}
            {isNewReportClicked && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsNewReportClicked(false);
                        setReportId(null);
                        setIsNewReportActive(false);
                        setIsTableView(false);
                        setIsChartView(false);
                    }}
                    title={"Select Data source"}
                >
                    <div className="mx-2 my-2 d-flex flex-col">
                        <div
                            style={{ maxHeight: "40vh", overflow: "auto" }}
                            className="no-scrollbar"
                        >
                            {templates?.length > 0 ? (
                                templates?.map((item) => {
                                    return (
                                        <div style={{ width: "40rem " }}>
                                            <div
                                                className={`d-flex justify-between  ${selectTmpToUplod?.id === item.id
                                                    ? "bg-blue-200"
                                                    : "bg-gray-200 "
                                                    } font-medium text-sm  mt-1 px-2 py-2 cursor-pointer text-gray-700`}
                                                onClick={() => setSelectTmpToUpload(item)}
                                            >
                                                <div>{item.name}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div
                                    className="bg-white px-2 mb-1 text-lg font-bold mt-2 text-gray-400"
                                    style={{ width: "40rem", textAlign: "center", color: '#bdbdbd6e' }}
                                >
                                    No Template Found
                                </div>
                            )}
                        </div>
                        <div className="px-2 pt-3 pb-2  d-flex ">
                            {/* <button
                                onClick={() => {
                                    setIsUploadCsv(!isUploadCsv);
                                    setIsNewReportClicked(false);
                                }}
                                className={`px-4 py-2 font-bold text-white rounded-md ${true ? 'bg-primaryblue' : 'bg-gray-600'
                                    } disabled:opacity-40 disabled:cursor-not-allowed`}>
                                Create New
                            </button> */}
                            <button
                                onClick={() => {
                                    //   handleTemplateSelect({
                                    //     id: selectTmpToUplod?.id,
                                    //     name: selectTmpToUplod?.name,
                                    //     isFromData: false,
                                    //   });
                                    //  setReportId(null);
                                    //setIsNewReportActive(true);
                                    setColumnListOpen(true);
                                    setIsNewReportClicked(false);
                                }}
                                className={`px-3 text-sm btn-bgcolor ml-2 py-2 font-bold text-white rounded-md   disabled:opacity-40 disabled:cursor-not-allowed 
                                            ${selectTmpToUplod
                                        ? "opacity-100"
                                        : "opacity-60"
                                    }`}
                            >
                                Select
                            </button>
                        </div>
                    </div>
                </ReusableReportModal>
            )}
            {isCreateNewTemplate && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsCreateNewTemplate(!isCreateNewTemplate);
                        // isReportBuilder === true ? setAvailableTemplates([]) : '';
                        // setIsUploadCsv(!isUploadCsv)
                        setIsSaveAsClicked(false);
                        setIsSavedClicked(false);
                    }}
                    title={"Create New Template"}
                >
                    <div style={{ width: "30rem", height: "14rem" }}>
                        <div className="flex flex-row items-center  mt-2">
                            <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                                <p className="m-0 text-sm font-bold whitespace-nowrap">Name</p>
                                <input
                                    type="text"
                                    className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                    value={tmpName}
                                    onChange={handleNameChange}
                                />
                                <p
                                    className={`text-red-500 text-sm m-0 ${errorMessage ? "opacity-100" : "opacity-0"
                                        }`}
                                >
                                    {errorMessage}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="flex flex-col items-start justify-start w-full gap-1 py-1 pb-2 px-4">
                                <p className="m-0 font-bold text-sm whitespace-nowrap">Description</p>
                                <input
                                    type="text"
                                    className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="px-4 mt-3">
                            <button
                                disabled={!tmpName || !description || errorMessage}
                                onClick={() => {
                                    handleUpsertTemplate({
                                        template: { tmpName, description },
                                        isCreate: true,
                                        isChild: isSaveClicked || isSaveAsClicked ? true : false,
                                    });
                                    // setIsUploadCsv(false)
                                    // setIsCreateNew(true)
                                }}
                                className={`px-3 text-sm btn-bgcolor py-2 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed disabled:opacity-40`}
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </ReusableReportModal>
            )}
            {isNewReport && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsnewReport(!isNewReport);
                        setIsSaveAsReport(false);
                        setIsSaveChanges(false);
                        setIsCloseReport(false);
                        setIsGetById({ status: false, reportId: null })
                    }}
                    // title={`${!isCloseReport
                    //     ? reportId === null || isSaveAsReport
                    //         ? "Create New Report"
                    //         : "Update Report"
                    //     : ""
                    //     }`}
                    title={`${isCloseReport
                        ? isSaveChanges
                            ? "Save changes ?"
                            : "Close Report ?"
                        : !isCloseReport
                            ? reportId === null || isSaveAsReport
                                ? "Create New Report"
                                : "Update Report"
                            : ""
                        }`}
                >
                    {isCloseReport ? (
                        <div className=" my-2 text-[15px] d-flex flex-col">
                            <div className="px-3">
                                {`${isSaveChanges || isGetById?.status == true
                                    ? "Would you like to save your current changes?"
                                    : "Closing this report will discard all changes. Proceed ?"
                                    }`}
                            </div>
                            <div className="self-end mt-2">
                                <button
                                    onClick={() => {
                                        if (isGetById?.status === true) {
                                            setIsCloseReport(false);
                                        } else {
                                            if (isSaveChanges) {
                                                setIsCloseReport(false);
                                                reportId === null ? setIsSaveAsReport(true) : "";
                                            } else {
                                                handleCloseReport();
                                            }
                                        }
                                    }}
                                    className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={() => {
                                        setIsnewReport(false);
                                        setIsCloseReport(false);
                                        if (isGetById?.status == true) {
                                            getReportById(isGetById?.reportId);
                                            setIsLodingReport(true);
                                        }
                                        if (isSaveChanges) {
                                            setReportId(null);
                                            setIsNewReportClicked(true);
                                            setIsSaveChanges(false);
                                            setComponents([]);
                                            setSelectedComponent(null);
                                            setIsNewReportActive(true);
                                            setIsChartView(true);
                                            setIsTableView(true);
                                        }
                                    }}
                                    className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    ) : isSaveAsReport ? (
                        <>
                            <div style={{ width: "30rem", height: "14rem" }}>
                                <div className="flex flex-row items-center  mt-2">
                                    <div className="flex flex-col  items-start justify-start w-full gap-1 py-1 px-4">
                                        <p className="m-0 font-bold text-sm whitespace-nowrap">Name</p>
                                        <input
                                            type="text"
                                            className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                            value={reportState?.name}
                                            onChange={(e) => {
                                                const { name, value } = e.target;
                                                setReportState((prevState) => ({
                                                    ...prevState,
                                                    name: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row items-center mt-2">
                                    <div className="flex flex-col items-start justify-start w-full gap-1 py-1 pb-2 px-4">
                                        <p className="m-0 text-sm font-bold whitespace-nowrap">
                                            Description
                                        </p>
                                        <input
                                            autoComplete="false"
                                            type="text"
                                            className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                            value={reportState?.description}
                                            onChange={(e) => {
                                                const { name, value } = e.target;
                                                setReportState((prevState) => ({
                                                    ...prevState,
                                                    description: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="pb-2 px-4 mt-3">
                                    <button
                                        disabled={!reportState?.name || !reportState?.description}
                                        onClick={async () => {
                                            await handleSaveReport({ parentId: reportId });
                                            if (isGetById?.status === true) {
                                                getReportById(isGetById?.reportId);
                                                setIsLodingReport(true);
                                            }
                                            if (isSaveChanges) {
                                                setReportId(null);
                                                setIsNewReportClicked(true);
                                                setComponents([]);
                                                setSelectedComponent(null);
                                            }
                                        }}
                                        className={`px-3 text-sm btn-bgcolor py-2 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed disabled:opacity-40`}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (<div className="mx-2 my-2 d-flex flex-col">
                        <span className="text-[15px] mt-1 ">{`Would you like to update the existing report or create a new one ?`}</span>
                        <span className="self-end mt-2">
                            <button
                                onClick={() => {
                                    //setReportId(null);
                                    setIsSaveAsReport(true);
                                }}
                                className="px-3 text-sm btn-bgcolor py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                            >
                                Create
                            </button>
                            <button
                                onClick={() => {
                                    handleSaveReport({ parentId: null });
                                    if (isGetById?.status == true) {
                                        getReportById(isGetById?.reportId);
                                        setIsLodingReport(true);
                                    }
                                    if (isSaveChanges) {
                                        setIsNewReportClicked(true);
                                        setComponents([]);
                                        setSelectedComponent(null);
                                    }
                                }}
                                className="px-3 text-sm btn-bgcolor  py-1 font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2"
                            >
                                Save
                            </button>
                        </span>
                    </div>
                    )}
                </ReusableReportModal>
            )}
            {isInfoModelOpen && (
                <ReusableReportModal
                    open
                    onClose={() => {
                        setIsInfoModelOpen(!isInfoModelOpen);
                        setInfoView("T_INFO");
                    }}
                    title={`${templateInfoState?.isReport === true
                        ? "Report Information"
                        : "Template Information"
                        }`}
                >
                    <div className="max-w-lg text-[15px]" style={{ width: "32rem" }}>
                        <Tabs
                            tabs={[
                                `${templateInfoState?.isReport === true
                                    ? "Report Information "
                                    : "Template Information"
                                }`,
                                "Reference Information",
                            ]}
                            components={[
                                <span>
                                    <div className="px-4 px 4">
                                        <div className="d-flex flex-col px-1 pb-3">
                                            <span className="font-bold text-[#0a3a67] text-sm">Name :</span>
                                            <span className="font-semibold text-sm text-gray-800 ">
                                                {templateInfoState?.name}
                                            </span>
                                        </div>
                                        <div className="d-flex flex-col px-1 pb-3">
                                            <span className="font-bold text-[#0a3a67] text-sm">
                                                Description :
                                            </span>
                                            <span className="font-semibold text-sm text-gray-800">
                                                {templateInfoState?.description}
                                            </span>
                                        </div>
                                        {templateInfoState?.columns?.length > 0 && (
                                            <div className="d-flex flex-col px-1 pb-1">
                                                <span className="font-bold text-[#0a3a67] text-sm">
                                                    Columns :
                                                </span>
                                                <span className="font-semibold text-sm text-gray-800">
                                                    {templateInfoState?.columns?.map((item, index) =>
                                                        index === templateInfoState?.columns?.length - 1
                                                            ? item
                                                            : item + ", "
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </span>,
                                <span>
                                    <div className="px-4 px 4">
                                        {templateInfoState?.parentId ? (
                                            <>
                                                <div className="d-flex flex-col px-1 pb-3">
                                                    <span className="font-bold text-[#0a3a67] text-sm">
                                                        Name :
                                                    </span>
                                                    <span className="font-semibold text-gray-800 text-sm">
                                                        {templateInfoState?.isReport == true
                                                            ? myReports?.find(
                                                                (item) =>
                                                                    item?.id === templateInfoState?.parentId
                                                            )?.name
                                                            : templates?.find(
                                                                (item) =>
                                                                    item?.id === templateInfoState?.parentId
                                                            )?.name}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-col px-1 pb-3">
                                                    <span className="font-bold text-[#0a3a67] text-sm">
                                                        Description :
                                                    </span>
                                                    <span className="font-semibold  text-sm text-gray-800">
                                                        {templateInfoState?.isReport == true
                                                            ? myReports?.find(
                                                                (item) =>
                                                                    item?.id === templateInfoState?.parentId
                                                            )?.description
                                                            : templates?.find(
                                                                (item) =>
                                                                    item?.id === templateInfoState?.parentId
                                                            )?.description}
                                                    </span>
                                                </div>
                                                {templateInfoState?.isReport === false && (
                                                    <div className="d-flex flex-col px-1 pb-1">
                                                        <span className="font-bold text-[#0a3a67] text-sm">
                                                            Columns :
                                                        </span>
                                                        <span className="font-semibold  text-sm text-gray-800">
                                                            {templates
                                                                ?.find(
                                                                    (item) =>
                                                                        item?.id === templateInfoState?.parentId
                                                                )
                                                                ?.columns?.map((item, index) =>
                                                                    index ===
                                                                        templateInfoState?.columns.length - 1
                                                                        ? item
                                                                        : item + ", "
                                                                )}
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="font-semibold text-gray-400 px-2 py-4 text-center">
                                                {`${templateInfoState?.isReport !== true
                                                    ? "No reference template found."
                                                    : "No reference report found."
                                                    }`}
                                            </div>
                                        )}
                                    </div>
                                </span>,
                            ]}
                            value={infoview}
                            onChange={(e, newValue) => {
                                setInfoView(newValue);
                            }}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                            className="mb-1"
                        ></Tabs>
                    </div>
                </ReusableReportModal>
            )}
            <Snackbar
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={5000}
                message={message ? message : "Something went wrong"}
                severity="error"
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity={severity ? severity : "error"}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {message ? message : "Something went wrong"}
                </Alert>
            </Snackbar>
            {isUploadCsv && (
                <UploadCSVModal
                    setIsUploadCsv={setIsUploadCsv}
                    isUploadCsv={isUploadCsv}
                    handleFileChange={handleChange}
                    tmpName={tmpName}
                    setTmpName={setTmpName}
                    description={description}
                    setDescription={setDescription}
                    selectedTmp={selectedTmp}
                    setselectedTmp={setselectedTmp}
                    templates={templates}
                    isReportBuilder={true}
                    availableTemplates={availableTemplates}
                    handleUpsertTemplate={handleUpsertTemplate}
                    setAvailableTemplates={setAvailableTemplates}
                    isCreateNewTemplate={isCreateNewTemplate}
                    setIsCreateNewTemplate={setIsCreateNewTemplate}
                />
            )}
            <DndProvider backend={HTML5Backend}>
                <div className="report-builder">
                    <div className="d-flex  w-full mt-2">
                        <div
                            className={`sidebar menu-main ${true ? "menu-show" : "menu-hide"}  no-scrollbar d-flex flex-col ${isMenuExpand ? 'min-w-64' : 'min-w-16'} overflow-auto`}
                            style={{
                                height: "87vh",
                                backgroundColor: `${menuBgColor}`
                            }}
                        >
                            <span className="main-menu-header  px-2" style={{ justifyContent: isMenuExpand ? 'end' : 'center' }}>
                                <DehazeIcon
                                    className="py-2 cursor-pointer "
                                    style={{
                                        color: '#fff',
                                        fontSize: '40px',
                                        width: '40px !important'
                                    }}
                                    onClick={() => {
                                        setIsMenuExpand(!isMenuExpand);
                                        setOpenSection(null);
                                    }}
                                />
                            </span>
                            <SidebarSection title="Data"
                                isMenuExpand={isMenuExpand}
                                isOpen={openSection === 'Data'}
                                onClick={() => {
                                    setIsMenuExpand(true);
                                    handleSectionClick('Data')
                                }}
                                icon={<TableViewOutlinedIcon />}>
                                <div
                                    className="d-flex justify-between items-center  border-bottom"
                                    onClick={() => setIsMyTemplatesOpen(!isMyTemplatesOpen)}
                                >
                                    <div
                                        className={`px-3 py-2 cursor-pointer text-sm font-semibold text-white`}
                                        onClick={() => setIsActiveDataSource(true)}
                                    >
                                        {/* <Data /> */}
                                        My Data
                                    </div>
                                    {isMyTemplatesOpen ? (
                                        <KeyboardArrowUpIcon className="mr-2 text-white cursor-pointer" />
                                    ) : (
                                        <KeyboardArrowDownIcon className="mr-2 text-white cursor-pointer" />
                                    )}
                                </div>
                                {isMyTemplatesOpen && (
                                    <>
                                        {templates?.length > 0 ? (
                                            <ul
                                                className="mb-1 overflow-auto no-scrollbar data-list-ul">
                                                {templates?.map((item) => (
                                                    <li
                                                        onClick={async () => {
                                                            setIsNewReportActive(false)
                                                            setReportId(null);
                                                            handleTemplateSelect({
                                                                id: item?.id,
                                                                name: item?.name,
                                                                isFromData: true,
                                                            });
                                                        }}
                                                        className={`data-list hover:text-[#b0c4df]  ${selectedTemplate?.id === item?.id ? 'text-[#b0c4df]' : 'text-[#fff]'}`}
                                                    > ↪ {item?.name}
                                                        <InfoOutlinedIcon
                                                            className="text-sm info-icon"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setTemplateInfoState({
                                                                    name: item?.name,
                                                                    description: item?.description,
                                                                    id: item?.id,
                                                                    parentId: item?.parent_id,
                                                                    columns: item?.columns,
                                                                    isReport: false,
                                                                });
                                                                setIsInfoModelOpen(!isInfoModelOpen);
                                                            }}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <span className=" px-3 mb-1 text-sm font-bold " style={{ color: '#bdbdbd6e' }}>
                                                No Template Found
                                            </span>
                                        )}
                                    </>
                                )}
                                <span
                                    className="d-flex mt-1 justify-between items-center"
                                    onClick={() => setisUploadOpen(!isUploadOpen)}  >
                                    <span
                                        className={`px-3 py-2 cursor-pointer text-sm font-semibold text-white `}
                                        onClick={() => setIsActiveDataSource(true)} >
                                        Upload Data
                                    </span>
                                    {isUploadOpen ? (
                                        <KeyboardArrowUpIcon className="mr-2 text-white cursor-pointer" />
                                    ) : (
                                        <KeyboardArrowDownIcon className="mr-2 text-white cursor-pointer" />
                                    )}
                                </span>
                                {isUploadOpen && (
                                    <>
                                        <div
                                            // className={`px-2 py-1 text-white font-medium  ${isUploadCsv ? "bg-slate-300" : ""
                                            //     }`}
                                            className={`data-list justify-start ${isUploadCsv ? 'text-[#b0c4df]' : 'text-[#fff]'}`}
                                            style={{
                                                paddingInlineStart: '30px'
                                            }}
                                            onClick={() => {
                                                if (isNewReportActive || reportId !== null) {
                                                    showAlert(
                                                        "You cannot upload data while the report is open. Please close the report first.",
                                                        "info"
                                                    );
                                                } else {
                                                    setIsUploadCsv(!isUploadCsv);
                                                }
                                            }}
                                        >
                                            <span className="d-flex items-center gap-2">
                                                <FileUploadOutlinedIcon fontSize="small" sx={{ width: '18px !important' }} />
                                                Upload CSV
                                            </span>
                                        </div>
                                        <div
                                            className="data-list text-[#fff]"
                                            style={{
                                                paddingInlineStart: '30px'
                                            }}
                                        // onClick={() => {
                                        //     setSelectedTemplate(null)
                                        //     setIsUploadCsv(!isUploadCsv);
                                        // }}
                                        >
                                            <span className="d-flex items-center gap-2">
                                                <DatasetLinkedOutlinedIcon fontSize="small" sx={{ width: '18px !important' }} />
                                                <span className="w-44">Connect to Enterprise Database</span>
                                            </span>
                                        </div>
                                    </>
                                )}
                            </SidebarSection>
                            <SidebarSection
                                title="Reports"
                                isMenuExpand={isMenuExpand}
                                isOpen={openSection === 'Reports'}
                                onClick={() => {
                                    handleSectionClick('Reports')
                                    setIsMenuExpand(true);
                                }}
                                icon={<ReceiptLongOutlinedIcon />}
                            >
                                <div
                                    className="d-flex justify-between items-center  border-bottom"
                                    onClick={() => setIsMyreportOpen(!isMyreportOpen)}
                                >
                                    <div
                                        className={`px-3 py-2 cursor-pointer text-sm font-semibold text-white`}
                                        onClick={() => setIsActiveDataSource(true)}
                                    >
                                        My Reports
                                    </div>
                                    {isMyreportOpen ? (
                                        <KeyboardArrowUpIcon className="mr-2 text-white cursor-pointer" />
                                    ) : (
                                        <KeyboardArrowDownIcon className="mr-2 text-white cursor-pointer" />
                                    )}
                                </div>
                                {isMyreportOpen &&
                                    <>
                                        {myReports?.length > 0 ? (
                                            <ul
                                                className="mb-1 overflow-auto no-scrollbar data-list-ul">
                                                {myReports.map((item) => (
                                                    <li
                                                        onClick={() => {
                                                            // if (reportId !== null && reportId !== item?.id) {
                                                            // setIsGetById({ status: true, reportId: item?.id })
                                                            //  setIsCloseReport(true);
                                                            //setIsnewReport(true);
                                                            // } else {
                                                            reportId !== item?.id && getReportById(item?.id);
                                                            reportId !== item?.id && setIsLodingReport(true);
                                                            // }
                                                        }}
                                                        className={`data-list ${reportId === item?.id ? 'text-[#b0c4df]' : 'text-[#fff]'}`}
                                                    >
                                                        ↪ {item?.name}
                                                        <InfoOutlinedIcon
                                                            className="text-sm info-icon"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setTemplateInfoState({
                                                                    name: item?.name,
                                                                    description: item?.description,
                                                                    id: item?.id,
                                                                    parentId: item?.parent_id,
                                                                    columns: [],
                                                                    isReport: true,
                                                                });
                                                                setIsInfoModelOpen(!isInfoModelOpen);
                                                            }}
                                                        />
                                                    </li>

                                                ))}

                                            </ul>
                                        ) : (
                                            <span className=" px-3 mb-1 text-sm font-bold " style={{ color: '#bdbdbd6e' }}
                                            >
                                                No Report Found.
                                            </span>
                                        )}
                                    </>
                                }
                                <div
                                    className={`px-3  py-2 cursor-pointer  text-sm font-semibold text-white
                                         ${isNewReportActive ? " " : ""} `}
                                    onClick={() => {
                                        // handleNewReport();
                                        setSelectedTemplate(null);
                                        if (
                                            (isNewReportActive || reportId !== null) &&
                                            components?.length > 0
                                        ) {
                                            setIsnewReport(true);
                                            setIsSaveChanges(true);
                                            setIsCloseReport(true);
                                        } else {
                                            setIsNewReportActive(true);
                                            setIsNewReportClicked(true);
                                            setComponents([]);
                                            setSelectedComponent(null);
                                            setReportId(null);
                                        }
                                    }}
                                >
                                    New Report
                                </div>
                            </SidebarSection>
                            <SidebarSection
                                title="Components"
                                isMenuExpand={isMenuExpand}
                                isOpen={openSection === 'Components'}
                                onClick={() => {
                                    setIsMenuExpand(true);
                                    handleSectionClick('Components')
                                }}
                                className="d-fex flex-col"
                                icon={<AppsOutlinedIcon />}
                            >
                                <div className="d-flex justify-evenly bg-[#105597] ">
                                    <DraggableComponent
                                        disabled={
                                            selectedTemplate !== null || !isTableView ||
                                            isDisableTable ||
                                            (reportId === null &&
                                                !isNewReportActive &&
                                                selectedTemplate === null)
                                        }
                                        type="table"
                                        id={`component-${componentCount}`}
                                        properties={getDefaultProperties("table")}
                                    >
                                        <div className="d-flex  flex-col items-center"
                                            onClick={
                                                selectedTemplate !== null || !isTableView || isDisableTable ||
                                                    (reportId === null && !isNewReportActive && selectedTemplate === null)
                                                    ? null
                                                    : () => {
                                                        handleComponentDrop({
                                                            type: "table",
                                                            id: `component-${componentCount}`,
                                                            properties: getDefaultProperties("table")
                                                        });
                                                    }
                                            }
                                        >
                                            <Tooltip
                                                // title={`${!isTableView ? "You can't drag the table" : isDisableTable ? "Already have one table." : (reportId === null &&
                                                //     !isNewReportActive &&
                                                //     selectedTemplate === null) ? "You can't drag the table; no datasource selected." : ""}`} className="whitespace-nowrap"
                                                title={
                                                    !isTableView
                                                        ? "You can't drag the table"
                                                        : isDisableTable
                                                            ? "Already have one table."
                                                            : (reportId === null && !isNewReportActive && selectedTemplate === null)
                                                                ? "You can't drag the table; no datasource selected."
                                                                : "You can drag or click the image to select it."
                                                }
                                                className="whitespace-nowrap"
                                            >
                                                <TableChartIcon sx={{ fontSize: 25, color: '#fff' }} />
                                            </Tooltip>
                                            <span className="text-xs  font-semibold text-white">
                                                Table
                                            </span>
                                        </div>
                                    </DraggableComponent>
                                    <DraggableComponent
                                        disabled={
                                            selectedTemplate !== null || !isChartView ||
                                            (reportId === null &&
                                                !isNewReportActive &&
                                                selectedTemplate === null)
                                        }
                                        type="chart"
                                        className="d-flex justify-center w-50 grow"
                                        id={`component-${componentCount}`}
                                        properties={getDefaultProperties("chart")}
                                    >
                                        <div className={`d-flex flex-col  items-center `}
                                            onClick={
                                                selectedTemplate !== null || !isChartView ||
                                                    (reportId === null && !isNewReportActive && selectedTemplate === null)
                                                    ? null
                                                    : () => {
                                                        handleComponentDrop({
                                                            type: 'chart',
                                                            id: `component-${componentCount}`,
                                                            properties: getDefaultProperties("chart")
                                                        });
                                                    }
                                            }
                                        >
                                            <Tooltip
                                                // title={`${!isChartView ? "Chartview is closed" : (reportId === null &&
                                                // !isNewReportActive &&
                                                //     selectedTemplate === null) ? "You can't drag the Chart; no datasource selected." : ""}`}
                                                title={
                                                    !isChartView
                                                        ? "Chartview is closed"
                                                        : (reportId === null && !isNewReportActive && selectedTemplate === null)
                                                            ? "You can't drag the Chart; no datasource selected."
                                                            : "You can drag or click the Chart to select it."
                                                }
                                            >
                                                <BarChartIcon sx={{ fontSize: 25, color: '#fff' }} />
                                            </Tooltip>
                                            <span className="text-xs font-semibold text-white">
                                                Chart
                                            </span>
                                        </div>
                                    </DraggableComponent>
                                </div>
                                <div className="d-flex justify-evenly bg-[#105597]">
                                    <DraggableComponent
                                        //  disabled={selectedTemplate !== null}
                                        disabled={
                                            selectedTemplate !== null ||
                                            (reportId === null &&
                                                !isNewReportActive &&
                                                selectedTemplate === null)
                                        }
                                        type="textbox"
                                        className="d-flex justify-center w-50 grow"
                                        id={`component-${componentCount}`}
                                        properties={getDefaultProperties("textbox")}
                                    >
                                        <div className="d-flex flex-col cursor-grab items-center"
                                            onClick={
                                                selectedTemplate !== null ||
                                                    (reportId === null &&
                                                        !isNewReportActive &&
                                                        selectedTemplate === null)
                                                    ? null // Disable onClick when the conditions match
                                                    : () => handleComponentDrop({
                                                        type: 'textbox',
                                                        id: `component-${componentCount}`,
                                                        properties: getDefaultProperties("textbox")
                                                    })
                                            }
                                        >
                                            <Tooltip
                                                // title={`${(reportId === null &&
                                                // !isNewReportActive &&
                                                //     selectedTemplate === null) ? "You can't drag the Text; no datasource selected." : ""}`}
                                                title={
                                                    (reportId === null && !isNewReportActive && selectedTemplate === null)
                                                        ? "You can't drag the Text; no datasource selected."
                                                        : "You can drag or click the Textbox to select it."
                                                }
                                            >
                                                <TextFieldsIcon sx={{ fontSize: 25, color: '#fff' }} />
                                            </Tooltip>
                                            <span className="text-xs font-semibold text-white">
                                                Text
                                            </span>
                                        </div>
                                    </DraggableComponent>
                                    <DraggableComponent
                                        disabled={
                                            selectedTemplate !== null ||
                                            (reportId === null &&
                                                !isNewReportActive &&
                                                selectedTemplate === null)
                                        }
                                        type="image"
                                        className="d-flex justify-center w-50 grow"
                                        id={`component-${componentCount}`}
                                        properties={getDefaultProperties("image")}
                                    >
                                        <div className="d-flex flex-col cursor-grab items-center"
                                            onClick={
                                                selectedTemplate !== null ||
                                                    (reportId === null &&
                                                        !isNewReportActive &&
                                                        selectedTemplate === null)
                                                    ? null // Disable onClick if the component is disabled
                                                    : () => {
                                                        handleComponentDrop({
                                                            type: "image",
                                                            id: `component-${componentCount}`,
                                                            properties: getDefaultProperties("image")
                                                        });
                                                    }
                                            }
                                        >
                                            <Tooltip
                                                // title={`${(reportId === null &&
                                                // !isNewReportActive &&
                                                //     selectedTemplate === null) ? "You can't drag the Image; no datasource selected." : ""}`}
                                                title={
                                                    selectedTemplate !== null ||
                                                        (reportId === null &&
                                                            !isNewReportActive &&
                                                            selectedTemplate === null)
                                                        ? "You can't drag the Image; no datasource selected."
                                                        : "You can drag or click the image to select it."
                                                }
                                            >
                                                <ImageIcon sx={{ fontSize: 25, color: '#fff' }} />
                                            </Tooltip>
                                            <span className="text-xs font-semibold text-white">
                                                Image
                                            </span>
                                        </div>
                                    </DraggableComponent>
                                </div>
                            </SidebarSection>
                        </div>
                        <div className="main-content grow w-4/5 mx-2 overflow-auto">
                            {selectedTemplate === null &&
                                (isNewReportActive || reportId !== null) && (
                                    <div className="d-flex gap-1 mb-1 text-body-secondary ">
                                        {/* <div className='hover:text-primaryblue cursor-pointer'
                                      onClick={toggleMenu}
                                        >
                                        <MenuOpenIcon/>
                                    </div> */}
                                        <span
                                            className="report-builder-nav-icon"
                                            onClick={() => {
                                                // if (reportId === null) {
                                                //     setIsnewReport(true)
                                                // } else {
                                                //     setIsSaveReportClicked(true);

                                                //     handleSaveReport();
                                                // }
                                                setIsnewReport(true);
                                                if (reportId === null) {
                                                    setIsSaveAsReport(true);
                                                }
                                            }}
                                        >
                                            <Tooltip title={"Save"}  >
                                                <SaveOutlinedIcon />
                                            </Tooltip>
                                        </span>
                                        <span
                                            className="report-builder-nav-icon"
                                            onClick={() => {
                                                setIsnewReport(true);
                                                setIsSaveAsReport(true);
                                            }}
                                        >
                                            <Tooltip title={"Save As"}  >
                                                <SaveAsOutlinedIcon />
                                            </Tooltip>
                                        </span>
                                        <span
                                            className="report-builder-nav-icon"
                                            onClick={async () => {
                                                // if (!isTableView) {
                                                //     const result = components?.filter((item) => {
                                                //         return item.type === 'table'
                                                //     })
                                                //     if (result?.length > 0) {
                                                //         await setSelectedComponent(null);
                                                //         setIsTableView(true)
                                                //         setIsChartView(false)
                                                //     } else {
                                                //         showAlert("There is no table available.", 'info');
                                                //     }
                                                // } else {
                                                setIsTableView(!isTableView);
                                                setSelectedComponent(null);
                                                // }
                                            }}
                                        >
                                            {isTableView ?
                                                <Tooltip title={"Table View"}  >
                                                    <TableChartOutlinedIcon />
                                                </Tooltip>
                                                : <NotTable height='20px' width='20px' fill='#0a3a67' />
                                            }
                                        </span>
                                        <span
                                            className="report-builder-nav-icon"
                                            onClick={async () => {
                                                // if (!isChartView) {
                                                //     const result = components?.filter((item) => {
                                                //         return item.type === 'chart'
                                                //     })
                                                //     if (result?.length > 0) {
                                                //         await setSelectedComponent(null);
                                                //         setIsTableView(false)
                                                //         setIsChartView(true)
                                                //     } else {
                                                //         showAlert("There is no chart available.", 'info');
                                                //     }
                                                //} else {
                                                setIsChartView(!isChartView);
                                                setSelectedComponent(null);
                                                //  }
                                            }}
                                        >
                                            {isChartView ?
                                                <Tooltip title={"Chart View"}  >
                                                    <BarChartIcon />
                                                </Tooltip>
                                                : <NotChart height='20px' width='20px' fill='#0a3a67' />
                                            }
                                        </span>
                                        <span
                                            className="report-builder-nav-icon"
                                            onClick={() => {
                                                setIsnewReport(true);
                                                setIsCloseReport(true);
                                            }}
                                        >
                                            <Tooltip title={"Close"} >
                                                <CloseIcon />
                                            </Tooltip>
                                        </span>
                                        {/* <div><ZoomInIcon /></div>
                                <div><ZoomOutIcon /></div> */}
                                    </div>
                                )}
                            <div
                                className="custom-scrollbar"
                                style={{ height: "84vh", overflow: "auto", outline: "none" }}
                            >
                                <ReportBuildingArea
                                    onDrop={handleComponentDrop}
                                    onDelete={handleDeleteComponent}
                                    components={components}
                                    setComponents={setComponents}
                                    selectedComponent={selectedComponent !== null ? selectedComponent : null}
                                    setSelectedComponent={setSelectedComponent}
                                    onPropertyChange={handlePropertyChange}
                                    disableRndDragging={disableRndDragging}
                                    setFlag={setFlag}
                                    dropRef={dropRef}
                                    setIsSavedClicked={setIsSavedClicked}
                                    selectedTemplate={selectedTemplate}
                                    setIsCreateNewTemplate={setIsCreateNewTemplate}
                                    setIsSaveAsClicked={setIsSaveAsClicked}
                                    isSelectReport={isSelectReport}
                                    setIsSelectReport={setIsSelectReport}
                                    reportId={reportId}
                                    isNewReportActive={isNewReportActive}
                                    isLoadingReport={isLoadingReport}
                                    isLoadingTemplate={isLoadingTemplate}
                                    isTableView={isTableView}
                                    isChartView={isChartView}
                                    setIsAnimated={setIsAnimated}
                                >
                                    {components?.map((component, index) => {
                                        return (
                                            <div
                                                key={component.id}
                                                className={`report-component ${index === selectedComponent ? "selected" : ""
                                                    } no-scrollbar`}
                                                onClick={() => handleComponentSelect(component)}
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    overflow:
                                                        component?.type === "textbox" ? "unset" : "auto",
                                                    display:
                                                        (isTableView && !isChartView && component?.type == 'chart') || (isChartView && !isTableView && component?.type == 'table') || (!isTableView && component?.type == 'table') || (!isChartView && component?.type == 'chart') ? 'none' : ''
                                                }}
                                            >
                                                {component.type === "table" && (
                                                    <>
                                                        <TableComponent
                                                            type={component.properties.type}
                                                            columns={component.properties.columns}
                                                            data={component.properties.data}
                                                            //data={currentReportData.data}
                                                            key={component.id}
                                                            properties={component.properties}
                                                            id={component.id}
                                                            stopTableDrag={() => {
                                                                setDisableRndDragging(true);
                                                            }}
                                                            onPropertyChange={handlePropertyChange}
                                                            setSelectedComponent={setSelectedComponent}
                                                            component={
                                                                selectedComponent !== null
                                                                    ? selectedComponent
                                                                    : null
                                                            }
                                                            showAlert={showAlert}
                                                            setIsSavedClicked={setIsSavedClicked}
                                                            setIsSaveAsClicked={setIsSaveAsClicked}
                                                            setIsCreateNewTemplate={setIsCreateNewTemplate}
                                                            selectedTemplate={selectedTemplate}
                                                            isChartView={isChartView}
                                                            isTableView={isTableView}

                                                        />
                                                    </>
                                                )}
                                                {component.type === "chart" && (
                                                    <ChartComponent
                                                        type={component?.properties?.type}
                                                        option={component?.properties?.option}
                                                        key={index}
                                                        properties={component?.properties}
                                                        echartsRef={echartsRef}
                                                        showAlert={showAlert}
                                                        isChartView={isChartView}
                                                        isTableView={isTableView}
                                                        isAnimated={isAnimated}
                                                        setIsAnimated={setIsAnimated}
                                                    />
                                                )}
                                                {component.type === "textbox" && (
                                                    <TextboxComponent
                                                        type={component.properties.type}
                                                        text={component.properties.text}
                                                        key={component.id}
                                                        properties={component.properties}
                                                        id={component.id}
                                                        onPropertyChange={handlePropertyChange}
                                                        setSelectedComponent={setSelectedComponent}

                                                    />
                                                )}
                                                {component.type === "image" && (
                                                    <ImageComponent
                                                        type={component.properties.type}
                                                        src={component.properties.src}
                                                        key={component.id}
                                                        properties={component.properties}
                                                        onPropertyChange={handlePropertyChange}
                                                        setSelectedComponent={setSelectedComponent}
                                                    />
                                                )}
                                                {component.type === "shape" && (
                                                    <ShapeComponent
                                                        type={component.properties.type}
                                                        key={component.id}
                                                    />
                                                )}
                                            </div>)
                                    })}
                                </ReportBuildingArea>
                            </div>
                            <React.Fragment key="right">
                                <Button
                                    className="position-fixed -end-1 top-50  min-w-12 disabled:opacity-60"
                                    onClick={toggleDrawer(true)}
                                    sx={{ minWidth: "50px", backgroundColor: '#0a3a67 !important' }}
                                    disabled={components.length === 0 || selectedComponent === null}
                                >
                                    <SettingsIcon
                                        sx={{
                                            color: "#fff",
                                            animation:
                                                components.length > 0 && selectedComponent !== null
                                                    ? "rotate 3s linear infinite"
                                                    : "none",
                                            "@keyframes rotate": {
                                                "0%": {
                                                    transform: "rotate(0deg)",
                                                },
                                                "100%": {
                                                    transform: "rotate(360deg)",
                                                },
                                            },
                                        }}
                                    ></SettingsIcon>
                                </Button>
                                {/* <Button
                                className='position-fixed -end-1 min-w-12 disabled:opacity-60'
                                // onClick={toggleDrawer(true)}
                                sx={{ minWidth: '50px', top: '21rem', right: '-10px' }}
                                disabled={components.length === 0}
                                onClick={handleSaveReport}
                            >
                                <SaveOutlinedIconsx={{
                                    color: '#0d6efd',
                                    fontSize: 40
                                }}></SaveOutlinedIconsx=>
                            </Button> */}
                                <SwipeableDrawer
                                    anchor="right"
                                    open={drawer}
                                    onClose={toggleDrawer(false)}
                                    onOpen={toggleDrawer(true)}
                                    sx={{
                                        "& .MuiDrawer-paper": {
                                            height: "100%",
                                            backgroundColor: "rgb(236, 244, 247)",
                                            width: {
                                                sm: "40%",
                                                md: "40%",
                                                lg: "30%"
                                            },
                                        }
                                    }}
                                >
                                    <PropertiesPanel
                                        component={
                                            selectedComponent !== null ? selectedComponent : null
                                        }
                                        setSelectedComponent={setSelectedComponent}
                                        onPropertyChange={handlePropertyChange}
                                        showAlert={showAlert}
                                        flag={flag}
                                        setFlag={setFlag}
                                        onCloseDrawer={toggleDrawer(false)}
                                        reportAreaRef={dropRef}
                                        setIsSavedClicked={setIsSavedClicked}
                                        isSaveClicked={isSaveClicked}
                                        selectedTemplate={selectedTemplate}
                                        setIsCreateNewTemplate={setIsCreateNewTemplate}
                                        isNewReportActive={isNewReportActive}
                                        reportId={reportId}
                                        generateChartData={generateChartData}
                                        generatePieChartData={generatePieChartData}
                                        generateDoubleChartData={generateDoubleChartData}
                                        echartsRef={echartsRef}
                                        setCurrentReportData={setCurrentReportData}
                                        getUserTemplates={getUserTemplates}
                                        isAnimated={isAnimated}
                                        setIsAnimated={setIsAnimated}
                                        currentReportData={currentReportData}
                                    />
                                </SwipeableDrawer>
                            </React.Fragment>
                        </div>
                        {/* <div className="properties-panel w-4/12 mx-1" style={{ border: '1px solid gray', borderRadius: '5px', overflow: 'auto' }}> */}
                        {/* </div> */}
                    </div>
                </div>
            </DndProvider >
        </>
    );
};
export default ReportBuilder;
const SidebarSection = ({ title, isOpen, onClick, children, isMenuExpand, icon }) => {
    const menuBgColor = 'rgb(10 58 103)'
    return (
        <div
            className="sidebar-section"
        // style={{
        //     borderTop: "0.5px solid #d2d9e3",
        //     borderBottom: "0.5px solid #d2d9e3",
        // }}
        >
            <div
                className="sidebar-section-header cursor-pointer px-2 py-2"
                style={{ backgroundColor: `${menuBgColor}` }}
                onClick={onClick}
            >
                <div className={`d-flex ${isMenuExpand === false ? 'justify-content-center' : 'justify-between'}`}>
                    {isMenuExpand ? <div className="d-flex w-full justify-between items-center">
                        <span className="font-semibold d-flex items-center gap-2 text-sm text-white">{icon}{title}</span>
                        <button className="toggle-button">
                            {isOpen ? (
                                <RemoveIcon className="text-white" />
                            ) : (
                                <AddIcon className="text-white" />
                            )}
                        </button>
                    </div> : <span className="font-semibold text-sm text-white">{icon}</span>}

                </div>
            </div>
            {isOpen && (
                <div className="sidebar-section-content">{children}</div>
            )}
        </div>
    );
};
