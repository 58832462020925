import * as React from "react";

import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

const ExcelIcon = (props) => {
  const disabledColors = [
    "#57585D",
    "#6F7075",
    "#5A5A5A",
    "#6A6A6A",
    "#565656",
    "#545454",
    "#818181",
  ];

  const colors = [
    "#185C37",
    "#21A366",
    "#107C41",
    "#18884f",
    "#117e43",
    "#0b6631",
    "#33C481",
  ];
  const fill = props.disabled ? disabledColors : colors;
  return (
    <Tooltip title={props.tooltipText}>
      <svg
        width={props.width} // Ensure props are used correctly
        height={props.height} // Ensure props are used correctly
        //  viewBox="0 0 1024 1024"
        viewBox="-343.4625 -532.5 2976.675 3195"
        xmlns="http://www.w3.org/2000/svg"
        //  className="icon" // Corrected from class to className
      >
        {/* <path
                className="st0"
                d="M321.49,244.09l-202.42-35.72v263.94c0,12.05,9.77,21.83,21.83,21.83h349.28c12.05,0,21.83-9.77,21.83-21.83v-97.24L321.49,244.09z"
                fill="#185C37" // Added fill inline for demonstration, can be replaced with dynamic props
            />
            <path
                className="st1"
                d="M321.49,17.86H140.9c-12.05,0-21.83,9.77-21.83,21.83v97.24L321.49,256l107.16,35.72L512,256V136.93L321.49,17.86z"
                fill="#21A366"
            />
            <path
                className="st2"
                d="M119.07,136.93h202.42V256H119.07V136.93z"
                fill="#107C41"
            />
            <path
                className="st3"
                d="M263.94,113.12H119.07v297.67h144.87c12.04-0.04,21.79-9.79,21.83-21.83V134.94C285.73,122.9,275.98,113.16,263.94,113.12z"
                fill="#33C481"
            />
            <path
                className="st4"
                d="M252.04,125.02H119.07V422.7h132.97c12.04-0.04,21.79-9.79,21.83-21.83V146.85C273.82,134.81,264.07,125.06,252.04,125.02z"
                fill="#0B6631"
            />
            <path
                className="st4"
                d="M252.04,125.02H119.07v273.86h132.97c12.04-0.04,21.79-9.79,21.83-21.83V146.85C273.82,134.81,264.07,125.06,252.04,125.02z"
                fill="#0B6631"
            />
            <path
                className="st4"
                d="M240.13,125.02H119.07v273.86h121.06c12.04-0.04,21.79-9.79,21.83-21.83V146.85C261.91,134.81,252.17,125.06,240.13,125.02z"
                fill="#0B6631"
            />
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="45.5065" y1="-1464.0308" x2="216.4467" y2="-1167.9695" gradientTransform="matrix(1 0 0 1 0 1572)">
                <stop offset="0" style={{ stopColor: '#18884F' }} />
                <stop offset="0.5" style={{ stopColor: '#117E43' }} />
                <stop offset="1" style={{ stopColor: '#0B6631' }} />
            </linearGradient>
            <path
                className="st5"
                d="M21.83,125.02h218.3c12.05,0,21.83,9.77,21.83,21.83v218.3c0,12.05-9.77,21.83-21.83,21.83H21.83C9.77,386.98,0,377.21,0,365.15v-218.3C0,134.79,9.77,125.02,21.83,125.02z"
                fill="url(#SVGID_1_)"
            />
            <path
                className="st6"
                d="M67.6,326.94l45.91-71.14l-42.07-70.75h33.84l22.96,45.25c2.12,4.3,3.57,7.49,4.36,9.6h0.3c1.51-3.43,3.1-6.76,4.76-9.99l24.54-44.83h31.07l-43.14,70.33l44.23,71.54H161.3l-26.52-49.66c-1.25-2.11-2.31-4.33-3.17-6.63h-0.39c-0.78,2.25-1.81,4.41-3.07,6.43l-27.3,49.87L67.6,326.94L67.6,326.94z"
                fill="#FFFFFF"
            />
            <path
                className="st7"
                d="M490.17,17.86H321.49v119.07H512V39.69C512,27.63,502.23,17.86,490.17,17.86L490.17,17.86z"
                fill="#33C481"
            />
            <path
                className="st2"
                d="M321.49,256H512v119.07H321.49V256z"
                fill="#107C41"
            /> */}
        <path
          d="M1437.75 1011.75L532.5 852v1180.393c0 53.907 43.7 97.607 97.607 97.607h1562.036c53.907 0 97.607-43.7 97.607-97.607V1597.5z"
          fill={fill[0]}
        />
        <path
          d="M1437.75 0H630.107C576.2 0 532.5 43.7 532.5 97.607V532.5l905.25 532.5L1917 1224.75 2289.75 1065V532.5z"
          fill={fill[1]}
        />
        <path d="M532.5 532.5h905.25V1065H532.5z" fill={fill[2]} />
        <path
          d="M1180.393 426H532.5v1331.25h647.893c53.834-.175 97.432-43.773 97.607-97.607V523.607c-.175-53.834-43.773-97.432-97.607-97.607z"
          opacity=".1"
        />
        <path
          d="M1127.143 479.25H532.5V1810.5h594.643c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
          opacity=".2"
        />
        <path
          d="M1127.143 479.25H532.5V1704h594.643c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
          opacity=".2"
        />
        <path
          d="M1073.893 479.25H532.5V1704h541.393c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
          opacity=".2"
        />
        <linearGradient
          gradientTransform="matrix(1 0 0 -1 0 2132)"
          y2="404.982"
          x2="967.987"
          y1="1729.018"
          x1="203.513"
          gradientUnits="userSpaceOnUse"
          id="a"
        >
          <stop offset="0" stopColor={fill[3]} />
          <stop offset=".5" stopColor={fill[4]} />
          <stop offset="1" stopColor={fill[5]} />
        </linearGradient>
        <path
          d="M97.607 479.25h976.285c53.907 0 97.607 43.7 97.607 97.607v976.285c0 53.907-43.7 97.607-97.607 97.607H97.607C43.7 1650.75 0 1607.05 0 1553.143V576.857c0-53.907 43.7-97.607 97.607-97.607z"
          fill="url(#a)"
        />
        <path
          d="M302.3 1382.264l205.332-318.169L319.5 747.683h151.336l102.666 202.35c9.479 19.223 15.975 33.494 19.49 42.919h1.331a798.667 798.667 0 0121.3-44.677L725.371 747.79H864.3l-192.925 314.548L869.2 1382.263H721.378L602.79 1160.158a186.298 186.298 0 01-14.164-29.66h-1.757a140.458 140.458 0 01-13.739 28.755l-122.102 223.011z"
          fill="#FFF"
        />
        <path
          d="M2192.143 0H1437.75v532.5h852V97.607C2289.75 43.7 2246.05 0 2192.143 0z"
          fill={fill[6]}
        />
        <path d="M1437.75 1065h852v532.5h-852z" fill={fill[2]} />
      </svg>
    </Tooltip>
  );
};

ExcelIcon.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  // tooltipText: PropTypes.string.isRequired,
};

export default ExcelIcon;
