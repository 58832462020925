import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { CircularProgress, Stack } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import CloseIcon from "@mui/icons-material/Close";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { Default_Table } from '../../../../assets/images';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DraggableComponent from './DraggableComponent';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import ReportListViewComponent from './ReportListViewComponent';
import { Rnd } from 'react-rnd';
import StraightIcon from '@mui/icons-material/Straight';
import TableComponent from '../../DataManagement/components/TableComponent';
import { Tooltip } from "@mui/material";
import { Translate } from '@mui/icons-material';
import dayjs from "dayjs";
import { useDrop } from 'react-dnd';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const ReportBuildingArea = ({
    onDrop,
    showAlert,
    children,
    onDelete,
    components,
    setComponents,
    selectedComponent,
    setSelectedComponent,
    onPropertyChange,
    disableRndDragging,
    setFlag,
    dropRef,
    setIsSavedClicked,
    selectedTemplate,
    setIsCreateNewTemplate,
    setIsSaveAsClicked,
    isSelectReport,
    setIsSelectReport,
    reportId,
    isNewReportActive,
    isLoadingReport,
    isLoadingTemplate,
    setIsAnimated,
    handleAddMeasure,
    isAddingMeasure,
    reportProperty,
    multiSelectedComponents,
    setMultiSelectedComponents,
    customRef,
    setIsListViewScreen,
    islistViewScreen,
    handleListViewFilter,
    listData,
    setListData,
    handleViewFilteredReport,
    handleClearFilter,
    dateOptions,
    inputValue,
    setInputValue,
    dateRange,
    setDateRange,
    dateR,
    setDateR,
    filteredReport,
    setFilteredReport,
    myReports,
    getReportById,
    tables,
    setSelectedColumnsFilters,
    selectedColumnsFilters,
    dateColumn,
    setDateColumn,
    handleClearMenu,
    isListLoading,
    listViewData,
    setListViewData,
    isTableDataLoading,
    handleSetAgain,
    isBorder,
    handleSelectColumn,
    setIsTableLoading,
    selectedColumn,
    handleAddColumnValues,
    dateformat,
    isFilterLoading,
    setIsFilterLoading,
    handleComponentSelect
}) => {
    const [componentPositions, setComponentPositions] = useState({});
    // const [guidelines, setGuidelines] = useState({ vertical: [], horizontal: [] });
    // const [dragging, setDragging] = useState(false);
    const [delta, setDelta] = useState({ width: '100%', height: '98%' });
    const [isApplyed, setIsApplied] = useState(false);
    const reportAreaRef = useRef(null);
    const componentRefs = useRef({});

    const [minSize, setMinSize] = useState({ minWidth: 100, minHeight: 100 });
    const [reportPages, setReportPages] = useState([{ id: 1, components: [] }]);

    const [isDragging, setIsDragging] = useState(false);
    const startPos = useRef({ x: 0, y: 0 });
    const DropDownRef = React.useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [sortConfig, setSortConfig] = React.useState({ key: null, direction: "none" });
    const [layouts, setLayouts] = useState({ lg: [] });

    // const updateGuidelines = (id, x, y, width, height) => {
    //     const otherComponents = Object.keys(componentPositions)?.filter(compId => compId !== id);

    //     const verticalGuides = otherComponents?.flatMap(compId => {
    //         const comp = components?.find(item => item.id === compId);
    //         return [comp?.properties?.left, comp?.properties?.left + comp?.properties?.width];
    //     });

    //     const horizontalGuides = otherComponents?.flatMap(compId => {
    //         const comp = components?.find(item => item.id === compId);
    //         return [comp?.properties?.top, comp?.properties?.top + comp?.properties?.height];
    //     });

    //     const matchedVerticals = verticalGuides.filter(v => Math.abs(v - x) <= 5 || Math.abs(v - (x + width)) <= 5);
    //     const matchedHorizontals = horizontalGuides.filter(h => Math.abs(h - y) <= 5 || Math.abs(h - (y + height)) <= 5);

    //     setGuidelines({
    //         vertical: matchedVerticals,
    //         horizontal: matchedHorizontals,
    //     });
    // };

    const handleSort = (column) => {
        setSortConfig((prev) => {
            if (prev.key === column) {
                // Cycle through: none -> asc -> desc -> none
                const newDirection = prev.direction === "none" ? "asc" :
                    prev.direction === "asc" ? "desc" :
                        "none";
                return { key: newDirection === "none" ? null : column, direction: newDirection };
            } else {
                return { key: column, direction: "asc" };
            }
        });
    };

    // Sorting logic
    const sortedData = [...listData].sort((a, b) => {
        if (!sortConfig.key || sortConfig.direction === "none") return 0;
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
    });
    const usedDataSources = reportId !== null
        ? components
            ?.filter(component => component?.type === "table" || component?.type === "chart")
            ?.map(component => component?.properties?.tableId)
        : [];
    const filteredTables = reportId !== null
        ? tables?.filter(table => usedDataSources?.includes(table.tableMetaData.table_id))
        : [];
    const handleMouseDown = (e) => {
        if (multiSelectedComponents.length > 0) {
            setIsDragging(true);
            startPos.current = { x: e.clientX, y: e.clientY };
        }
    };

    const today = dayjs();
    const handleDateOptionChange = (event) => {
        const selectedValue = event.target.value;
        let startDate = today;

        switch (selectedValue) {
            case "WTD":
                startDate = today.startOf("week");
                break;
            case "MTD":
                startDate = today.startOf("month");
                break;
            case "YTD":
                startDate = today.startOf("year");
                break;
            default:
                startDate = today;
        }
        setDateR(dateOptions?.find(item => item?.value === selectedValue))
        setDateRange([startDate, today]);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };


    // const handleMouseMove = (e) => {
    //     if (!isDragging) return;
    //     const dx = e.clientX - startPos.current.x;
    //     const dy = e.clientY - startPos.current.y;
    //     setComponentPositions((prevPositions) => {
    //         const newPositions = { ...prevPositions };
    //         multiSelectedComponents.forEach((compId) => {
    //             const comp = components.find(item => item.id === compId);
    //             const reportAreaWidth = reportAreaRef?.current?.offsetWidth;
    //             const newX = Math.max(0, Math.min(dx, reportAreaWidth - comp?.properties?.width));
    //             const newY = Math.max(0, dy);

    //             if (comp) {
    //                 newPositions[compId] = {
    //                     x: comp.properties.left + newX,
    //                     y: comp.properties.top + newY
    //                 };
    //             }
    //         });
    //         return newPositions;
    //     });
    //     setComponents((prev) =>
    //         prev.map((comp) => {
    //             const reportAreaWidth = reportAreaRef?.current?.offsetWidth;
    //             const newX = Math.max(0, Math.min(dx, reportAreaWidth - comp?.properties?.width));
    //             const newY = Math.max(0, dy);

    //             console.log(newX, ">>> NeWEEEEE", newY, ">>> GYGYGY gyyy");

    //             return multiSelectedComponents.includes(comp.id)
    //                 ? {
    //                     ...comp,
    //                     properties: {
    //                         ...comp.properties,
    //                         left: comp.properties.left + newX,
    //                         top: comp.properties.top + newY,
    //                     },
    //                 }
    //                 : comp
    //         })
    //     );
    //     startPos.current = { x: e.clientX, y: e.clientY }; // Update reference for next move
    // };
    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const dx = e.clientX - startPos.current.x;
        const dy = e.clientY - startPos.current.y;

        setComponentPositions((prevPositions) => {
            const newPositions = { ...prevPositions };
            const reportAreaWidth = dropRef?.current?.offsetWidth;
            const reportAreaHeight = dropRef?.current?.offsetHeight;

            multiSelectedComponents.forEach((compId) => {
                const comp = components.find(item => item.id === compId);
                if (comp) {
                    const newX = comp.properties.left + dx;
                    const newY = comp.properties.top + dy;

                    // Ensure the component does not overflow the container
                    const constrainedX = Math.max(0, Math.min(newX, reportAreaWidth - comp.properties.width + comp.properties.left));
                    const constrainedY = Math.max(0, Math.min(newY, reportAreaHeight - comp.properties.height + comp.properties.top));

                    newPositions[compId] = {
                        x: constrainedX,
                        y: constrainedY
                    };
                }
            });
            return newPositions;
        });

        setComponents((prev) =>
            prev.map((comp) => {
                if (multiSelectedComponents.includes(comp.id)) {
                    const newX = comp.properties.left + dx;
                    const newY = comp.properties.top + dy;

                    const reportAreaWidth = dropRef?.current?.offsetWidth;
                    const reportAreaHeight = dropRef?.current?.offsetHeight;

                    // Ensure the component does not overflow the container
                    const constrainedX = Math.max(0, Math.min(newX, reportAreaWidth - comp.properties.width));
                    const constrainedY = Math.max(0, Math.min(newY, reportAreaHeight - comp.properties.height));

                    return {
                        ...comp,
                        properties: {
                            ...comp.properties,
                            left: constrainedX,
                            top: constrainedY,
                        },
                    };
                }
                return comp;
            })
        );

        startPos.current = { x: e.clientX, y: e.clientY }; // Update reference for next move
        // setDragging(true)
    };
    // const handleMouseMove = (e) => {
    //     if (!isDragging) return;

    //     const dx = e.clientX - startPos.current.x;
    //     const dy = e.clientY - startPos.current.y;

    //     setComponentPositions((prevPositions) => {
    //         const newPositions = { ...prevPositions };
    //         const reportAreaWidth = dropRef?.current?.offsetWidth;
    //         const reportAreaHeight = dropRef?.current?.offsetHeight;

    //         // Calculate the minimum allowable movement for all components
    //         let minDx = dx;
    //         let minDy = dy;

    //         multiSelectedComponents.forEach((compId) => {
    //             const comp = components.find(item => item.id === compId);
    //             if (comp) {
    //                 // Calculate how much this component can move before hitting the boundary
    //                 const maxDx = reportAreaWidth - comp.properties.width - comp.properties.left;
    //                 const maxDy = reportAreaHeight - comp.properties.height - comp.properties.top;

    //                 minDx = Math.min(minDx, maxDx); // Smallest allowable dx
    //                 minDy = Math.min(minDy, maxDy); // Smallest allowable dy
    //             }
    //         });

    //         // Constrain dx and dy to the smallest allowable movement
    //         const constrainedDx = Math.max(-startPos.current.x, Math.min(minDx, dx));
    //         const constrainedDy = Math.max(-startPos.current.y, Math.min(minDy, dy));

    //         // Update positions for all selected components using the constrained values
    //         multiSelectedComponents.forEach((compId) => {
    //             const comp = components.find(item => item.id === compId);
    //             if (comp) {
    //                 newPositions[compId] = {
    //                     x: comp.properties.left + constrainedDx,
    //                     y: comp.properties.top + constrainedDy,
    //                 };
    //             }
    //         });

    //         return newPositions;
    //     });

    //     setComponents((prev) =>
    //         prev.map((comp) => {
    //             if (multiSelectedComponents.includes(comp.id)) {
    //                 const reportAreaWidth = dropRef?.current?.offsetWidth;
    //                 const reportAreaHeight = dropRef?.current?.offsetHeight;

    //                 // Calculate the minimum allowable movement for all components
    //                 let minDx = dx;
    //                 let minDy = dy;

    //                 multiSelectedComponents.forEach((compId) => {
    //                     const selectedComp = components.find(item => item.id === compId);
    //                     if (selectedComp) {
    //                         // Calculate how much this component can move before hitting the boundary
    //                         const maxDx = reportAreaWidth - selectedComp.properties.width - selectedComp.properties.left;
    //                         const maxDy = reportAreaHeight - selectedComp.properties.height - selectedComp.properties.top;

    //                         minDx = Math.min(minDx, maxDx); // Smallest allowable dx
    //                         minDy = Math.min(minDy, maxDy); // Smallest allowable dy
    //                     }
    //                 });

    //                 // Constrain dx and dy to the smallest allowable movement
    //                 const constrainedDx = Math.max(-startPos.current.x, Math.min(minDx, dx));
    //                 const constrainedDy = Math.max(-startPos.current.y, Math.min(minDy, dy));

    //                 return {
    //                     ...comp,
    //                     properties: {
    //                         ...comp.properties,
    //                         left: comp.properties.left + constrainedDx,
    //                         top: comp.properties.top + constrainedDy,
    //                     },
    //                 };
    //             }
    //             return comp;
    //         })
    //     );

    //     startPos.current = { x: e.clientX, y: e.clientY }; // Update reference for next move
    // };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // const handleDrop = async (item, dropPosition, size) => {
    //     if (dropRef.current) {
    //         const dropAreaRect = dropRef.current.getBoundingClientRect();
    //         const newPosition = {
    //             x: dropPosition.x - dropAreaRect.left,
    //             y: dropPosition.y - dropAreaRect.top,
    //         };
    //         // Ensure the component stays within the report building area
    //         newPosition.x = Math.min(Math.max(newPosition.x, 0), dropAreaRect.width);
    //         newPosition.y = Math.min(Math.max(newPosition.y, 0), dropAreaRect.height);
    //         const newComponentPositions = {
    //             ...componentPositions,
    //             [item.id]: newPosition,
    //         };
    //         setComponentPositions(newComponentPositions);
    //         item.properties.left = newPosition?.x
    //         item.properties.top = newPosition?.y
    //         onDrop(item, size);
    //     }
    // };



    const handleDrop = async (item, dropPosition) => {
        if (dropRef.current) {
            const dropAreaRect = dropRef.current.getBoundingClientRect();
            const newPosition = {
                x: dropPosition.x - dropAreaRect.left,
                y: dropPosition.y - dropAreaRect.top,
            };

            // Ensure the component stays within the drop area
            newPosition.x = Math.min(Math.max(newPosition.x, 0), dropAreaRect.width);
            newPosition.y = Math.min(Math.max(newPosition.y, 0), dropAreaRect.height);

            const marginOffset = 15;
            let isCompletelyOverlapping = false;

            // Check if the new position fully overlaps any existing component
            components.forEach(existingItem => {
                if (existingItem.id !== item.id) {
                    const existingPos = existingItem.properties;

                    const isXOverlapping = (
                        newPosition.x < existingPos.left + existingPos.width &&
                        newPosition.x + item.properties.width > existingPos.left
                    );
                    const isYOverlapping = (
                        newPosition.y < existingPos.top + existingPos.height &&
                        newPosition.y + item.properties.height > existingPos.top
                    );

                    // Only block if it completely overlaps
                    if (isXOverlapping && isYOverlapping) {
                        const isFullyInsideX = newPosition.x >= existingPos.left && (newPosition.x + item.properties.width) <= (existingPos.left + existingPos.width);
                        const isFullyInsideY = newPosition.y >= existingPos.top && (newPosition.y + item.properties.height) <= (existingPos.top + existingPos.height);

                        if (isFullyInsideX && isFullyInsideY) {
                            isCompletelyOverlapping = true;
                        }
                    }
                }
            });


            if (isCompletelyOverlapping) {
                return; // Block the drop
            }

            // 🔹 Find available space dynamically
            let availableHeight = dropAreaRect.height - newPosition.y - marginOffset;
            let availableWidth = dropAreaRect.width - newPosition.x - marginOffset;

            components.forEach(existingItem => {
                if (existingItem.id !== item.id) {
                    const existingPos = existingItem.properties;

                    if (existingPos.top > newPosition.y) {
                        availableHeight = Math.min(availableHeight, existingPos.top - newPosition.y - marginOffset);
                    }
                    if (existingPos.left > newPosition.x) {
                        availableWidth = Math.min(availableWidth, existingPos.left - newPosition.x - marginOffset);
                    }
                }
            });
            // Resize if needed
            if (item.properties.height > availableHeight) {
                item.properties.height = availableHeight;
            }
            if (item.properties.width > availableWidth) {
                item.properties.width = availableWidth;
            }
            // Assign new size
            item.properties.width = availableWidth;
            item.properties.height = availableHeight;

            // Update component position
            setComponentPositions(prevPositions => ({
                ...prevPositions,
                [item.id]: newPosition
            }));

            // Assign position and size to item 
            item.properties.left = newPosition.x;
            item.properties.top = newPosition.y;
            onDrop(item, { height: availableHeight, width: availableWidth });
        }
    };
    const [, drop] = useDrop({
        accept: 'reportComponent',
        drop: (item, monitor) => {
            const dropPosition = monitor.getClientOffset();
            handleDrop(item, dropPosition);
            setTimeout(() => {
                const dropElement = document.getElementById(item.id);
                if (dropElement) {
                    const initialWidth = dropElement.offsetWidth;
                    const initialHeight = dropElement.offsetHeight;
                    handleDrop(item, dropPosition, { height: initialHeight, width: initialWidth });
                }
            }, 0);
        },
    });
    // const handleResizeStop = (e, direction, ref, delta, position) => {
    //     setDelta({
    //         width: ref.style.width,
    //         height: ref.style.height
    //     });
    // };
    const handleDelete = (id) => {
        const newComponentPositions = { ...componentPositions };
        delete newComponentPositions[id];
        setComponentPositions(newComponentPositions);
        onDelete(id);
    };
    const handleResizeStop = (e, direction, ref, delta, position) => {
        setDelta({
            width: Number(ref.style?.width.split('%')[0]) < 99 ? '100%' : ref.style?.width,
            height: Number(ref.style.height.split('%')[0]) < 98 ? '98%' : ref.style.height
        });
        // Reposition components if necessary
        const newComponentPositions = { ...componentPositions };
        Object.keys(newComponentPositions).forEach(id => {
            const componentPosition = newComponentPositions[id];
            const componentElement = document.getElementById(id);

            if (componentElement) {
                const componentWidth = componentElement.offsetWidth;
                const componentHeight = componentElement.offsetHeight;

                // Adjust position if the component is outside the resized area
                if (componentPosition.x + componentWidth > ref.offsetWidth) {
                    componentPosition.x = ref.offsetWidth - componentWidth;
                }
                if (componentPosition.y + componentHeight > ref.offsetHeight) {
                    componentPosition.y = ref.offsetHeight - componentHeight;
                }
            }
        });
        setComponentPositions(newComponentPositions);
        // setDragging(false)
    };
    // React.useEffect(() => {
    //     setComponentPositions(
    //         Object.fromEntries(components.map(comp => [comp.id, comp.properties]))
    //     );
    // }, [components]);

    
    React.useEffect(() => {
        const calculateMinSize = () => {
            let minWidth = 100;
            let minHeight = 100;
    
            components.forEach((component) => {
                const childElement = document.getElementById(component.id);
                if (childElement) {
                    const rect = childElement.getBoundingClientRect(); 
                    const parentRect = document.getElementById("rnd-container-custom")?.getBoundingClientRect(); 
                    
                    const childTop = parentRect ? rect.top - parentRect.top : rect.top;
                    const childRight = component.properties.left + childElement.offsetWidth + 10;
                    const childBottom = childTop + childElement.offsetHeight + 30;
    
                    minWidth = Math.max(minWidth, childRight);
                    minHeight = Math.max(minHeight, childBottom) + (reportProperty?.margin?.top || 0) * 2;
                }
            });
    
            setMinSize({ minWidth, minHeight });
        };
    
        calculateMinSize();
    }, [components, componentPositions, isAddingMeasure, showAlert]);
    
    
    
    // React.useEffect(() => {
    //     const calculateMinSize = () => {
    //         let minWidth = 100;
    //         let minHeight = 100;

    //         components.forEach(component => {
    //             const childElement = document.getElementById(component.id);
    //             if (childElement) {
    //                 const childRight = component.properties.left + childElement.offsetWidth + 10;
    //                 const childBottom = component.properties.top + childElement.offsetHeight + 30;
    //                 minWidth = Math.max(minWidth, childRight);
    //                 minHeight = Math.max(minHeight, childBottom) + reportProperty?.margin?.top * 2;
    //             }
    //         });

    //         setMinSize({ minWidth, minHeight });
    //     };
    //     calculateMinSize();
    // }, [components, componentPositions, isAddingMeasure, showAlert]);


    React.useEffect(() => {
        const handleClickOutside = (event) => {
            const ele = document?.getElementById("rnd-container-custom");

            // Traverse up the DOM tree to check if any parent has a class matching "gridcomponent-"
            let currentElement = event.target;
            while (currentElement) {
                if (currentElement.classList && [...currentElement.classList].some(className => className.startsWith("gridcomponent-"))) {
                    return; // Clicked inside a grid component, so do nothing
                }
                currentElement = currentElement.parentElement;
            }
            if (ele && ele?.contains(event.target) || (dropRef?.current && dropRef?.current?.contains(event.target))) {
                let clickedOutside = true;
                Object.keys(componentRefs.current).forEach(key => {

                    if (componentRefs.current[key]?.contains(event.target)) {
                        clickedOutside = false;
                    }
                });
                if (clickedOutside) {
                    setSelectedComponent(null);
                    setMultiSelectedComponents([])
                }
            }
            // If clicked outside, reset selection
            if (
                (ele && ele.contains(event.target)) ||
                (dropRef?.current && dropRef?.current.contains(event.target))
            ) {
                setSelectedComponent(null);
                setMultiSelectedComponents([]);
            }

            // Check dropdown click logic
            if (DropDownRef?.current && !DropDownRef?.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setSelectedComponent]);

    React.useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        } else {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging]);

    React.useEffect(() => {
        if (isSelectReport === true) {
            setDelta({ width: '100%', height: '98%' });
            setIsSelectReport(false);
        }
    }, [isSelectReport])

    // React.useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         if (event.key === 'Enter' && selectedComponent) {
    //             const shiftAmount = 20;

    //             setComponents((prevComponents) => {
    //                 const updatedComponents = prevComponents.map(comp => {
    //                     if (comp.id === selectedComponent.id || comp.properties.top > selectedComponent.properties.top) {
    //                         return {
    //                             ...comp,
    //                             properties: {
    //                                 ...comp.properties,
    //                                 top: comp.properties.top + shiftAmount,
    //                             },
    //                         };
    //                     }
    //                     return comp;
    //                 });

    //                 const updatedSelectedComponent = updatedComponents.find(comp => comp.id === selectedComponent.id);
    //                 if (updatedSelectedComponent) {
    //                     setSelectedComponent(updatedSelectedComponent);
    //                 }

    //                 return updatedComponents;
    //             });
    //         }
    //     };

    //     window.addEventListener('keydown', handleKeyDown);
    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, [selectedComponent, components]);



    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                if (event.target.closest(".ck-editor__editable")) {
                    return;
                }
                const shiftAmount = 20;

                setComponents((prevComponents) => {
                    if (multiSelectedComponents.length > 1) {
                        // Case: Multiple components selected
                        const lowestTop = Math.min(...multiSelectedComponents.map(id => {
                            const comp = prevComponents.find(c => c.id === id);
                            return comp ? comp.properties.top : Infinity;
                        }));

                        return prevComponents.map((comp) => {
                            if (multiSelectedComponents.includes(comp.id)) {
                                // Move selected components down
                                return {
                                    ...comp,
                                    properties: {
                                        ...comp.properties,
                                        top: comp.properties.top + shiftAmount,
                                    },
                                };
                            } else if (comp.properties.top > lowestTop) {
                                // Move components below the selected ones further down
                                return {
                                    ...comp,
                                    properties: {
                                        ...comp.properties,
                                        top: comp.properties.top + shiftAmount,
                                    },
                                };
                            }
                            return comp;
                        });
                    } else if (selectedComponent) {
                        // Case: Single component selected 
                        const selectedComp = prevComponents.find(comp => comp.id === selectedComponent.id);
                        if (selectedComp) {
                            return prevComponents.map((comp) => {
                                if (comp.id !== selectedComp.id && comp.properties.top > selectedComp.properties.top) {
                                    return {
                                        ...comp,
                                        properties: {
                                            ...comp.properties,
                                            top: comp.properties.top + shiftAmount,
                                        },
                                    };
                                }
                                return comp;
                            });
                        }
                    }
                    return prevComponents;
                });
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedComponent, multiSelectedComponents, components]);

    // const handleAddNewPage = () => {
    //     setReportPages((prevPages) => {
    //         const newPage = { id: prevPages.length + 1, components: [] };

    //         const updatedPages = [...prevPages];
    //         const lastPage = updatedPages[updatedPages.length - 1];

    //         lastPage.components = lastPage.components.filter((comp) => {

    //             return comp?.propeties?.top + comp?.properties?.height <= reportProperty.size.height;
    //         });

    //         newPage.components = components.filter((comp) => !lastPage.components.includes(comp));

    //         console.log(...updatedPages, newPage, ">>> Pagessss gggggg");
    //         return [...updatedPages, newPage];
    //     });

    //     notifyUser();
    // };

    // const notifyUser = () => {
    //     showAlert("New page added automatically!");
    // };
    React.useEffect(() => {
        console.log(">> Cal api")
        if (islistViewScreen && listViewData?.columns?.length > 0) {
            console.log(">> Cal api")
        }
    }, [])

    const handleLayoutChange = (newLayout) => {
        setLayouts({ lg: newLayout });
        console.log(newLayout, "newlayoutssss")
        setComponents(components?.map((item) => {
            console.log(newLayout?.find((l) => l?.i === item?.id), "condiiiii")
            return {
                ...item,
                properties: {
                    ...item?.properties,
                    layouts: newLayout?.find((l) => l?.i === item?.id)
                }
            }
        }))
    };
  
    return (
        <div
            className={`custom-scrollbar ${islistViewScreen && filteredReport?.status === false ? "p-0" : "p-[10px] overflow-auto"}`}
            style={{
                backgroundColor: '#cbcdcf',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            {(islistViewScreen && filteredReport?.status === false) ?
                (
                    <div
                        style={{
                            backgroundColor: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            flexDirection: 'column'
                        }}>
                        <ReportListViewComponent
                            component={listViewData}
                            setSelectedComponent={setListViewData}
                            data={listViewData?.data}
                            columns={listViewData?.columns?.map((item) => item?.columnName)}
                            setIsListViewScreen={setIsListViewScreen}
                            getReportById={getReportById}
                            reportId={reportId}
                            handleViewFilteredReport={handleViewFilteredReport}
                            isTableDataLoading={isTableDataLoading}
                            setIsTableLoading={setIsTableLoading}
                            selectedColumn={selectedColumn}
                            handleAddColumnValues={handleAddColumnValues}
                            filters={listViewData?.filters}
                            handleSelectColumn={handleSelectColumn}
                            handleListViewFilter={handleListViewFilter}
                            dateformat={dateformat}
                            setIsFilterLoading={setIsFilterLoading}
                            isFilterLoading={isFilterLoading}
                        />
                    </div>

                ) :
                <Rnd
                    id='rnd-container-custom'
                    className='rnd-container'
                    default={{ x: 0, y: 0 }}
                    size={{
                        width: reportProperty?.size?.width,
                        height: delta?.height
                    }}
                    enableResizing={{
                        top: false,
                        right: false,
                        bottom: true,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                    onResizeStop={handleResizeStop}
                    maxWidth={reportProperty?.size?.width}
                    minHeight={minSize.minHeight}
                    disableDragging={true}
                    style={{
                        top: '0px',
                        // minHeight: '98%',
                        minWidth: '98%',
                        backgroundColor: "#fff",
                        position: 'relative',
                        transform: 'none',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                        paddingTop: `${reportProperty?.margin?.top}px`,
                        paddingBottom: `${reportProperty?.margin?.bottom}px`,
                        paddingLeft: `${reportProperty?.margin?.left}px`,
                        paddingRight: `${reportProperty?.margin?.right}px`
                    }}
                    ref={customRef}
                >
                    {filteredReport?.status &&
                        <span style={{
                            position: 'absolute',
                            top: '5px',
                            right: '10px',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                setFilteredReport({ status: false, filters: {} });
                                setIsListViewScreen(true)
                                // handleClearMenu()
                                handleSetAgain();
                            }}
                        >
                            <CloseIcon />
                        </span>}
                    {isLoadingReport ? <Stack sx={{ color: 'grey.500', height: '100%', alignItems: 'center', justifyContent: 'center' }} spacing={2} direction="row">
                        <CircularProgress color="inherit" disableShrink />
                    </Stack> :
                        <div
                            ref={(node) => {
                                drop(node);
                                dropRef.current = node;
                            }}
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                cursor: isDragging ? 'move' : 'auto',
                                border: isBorder ? "1px dotted #979393" : "none"
                            }}
                            onMouseDown={handleMouseDown}

                        >
                            {/* {dragging ? guidelines.vertical.map((x, index) => (
                                <span
                                    key={`v-${index}`}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: x,
                                        width: "1px",
                                        height: "100%",
                                        backgroundColor: "white",
                                        border: "0.5px dashed #3989b4",
                                        zIndex: 10,
                                    }}
                                />
                            )) : null}
                            {dragging ? guidelines.horizontal.map((y, index) => (
                                <span
                                    key={`h-${index}`}
                                    style={{
                                        position: "absolute",
                                        left: 0,
                                        top: y,
                                        height: "1px",
                                        width: "100%",
                                        backgroundColor: "white",
                                        border: "0.5px dashed #3989b4",
                                        zIndex: 10,
                                    }}
                                />
                            )) : null} */}
                            <ResponsiveReactGridLayout
                                className="layout"
                                layouts={{ lg: components?.map((item) => item.properties?.layouts || {}) }}

                                // layouts={layouts}
                                // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                measureBeforeMount={false}
                                useCSSTransforms={false}
                                compactType="vertical"
                                preventCollision={false}
                                rowHeight={40}
                                // cols={{ lg: 12, md: 10, sm: 8, xs: 4, xxs: 2 }}
                                cols={{ lg: 4 }}
                                breakpoints={{ lg: 1200 }}
                                width={1200}
                                isResizable={true}
                                isDraggable={true}
                                draggableHandle=".drag-handle"
                                autoSize={true}
                                // onLayoutChange={(currentLayout) => {
                                //     console.log(currentLayout, "layoutssssssssss")
                                //     handleLayoutChange(currentLayout);
                                // }}
                                onDragStop={(currentLayout) => {
                                    handleLayoutChange(currentLayout);
                                }}
                                onResizeStop={(currentLayout) => {
                                    handleLayoutChange(currentLayout);
                                }}
                            >
                                {children.map((child, index) => {
                                    const id = child?.key
                                    const position = componentPositions[id] || { x: 0, y: 0 }; // Initialize position properly 
                                    const component = components.find((item) => item.id === id);                                  
                                    return (
                                        <div                                        
                                            // style={{
                                            //     width: components?.find((item) => item.id == id)?.properties?.width,
                                            //     height: components?.find((item) => item.id == id)?.properties?.height,
                                            // }}                                         
                                            key={child?.key}
                                            className={`gridcomponent-${id}`}
                                            ref={(el) => componentRefs.current[id] = el}
                                            style={{ zIndex: 20 }}
                                            data-grid={{
                                                ...component?.properties?.layouts,
                                                isResizable: selectedComponent?.id === id,
                                            }}
                                        >
                                            <DraggableComponent
                                                setFlag={setFlag}
                                                key={child?.key}
                                                id={child?.key}
                                                type={child?.props?.type}
                                                position={position}
                                                properties={components?.find((item) => item?.id === id)?.properties}
                                                setPosition={(newPosition) => {
                                                    setComponentPositions({
                                                        ...componentPositions,
                                                        [id]: newPosition,
                                                    });
                                                }}
                                                onDelete={handleDelete}
                                                isInReportArea={true}
                                                components={components}
                                                setComponents={setComponents}
                                                selectedComponent={selectedComponent !== null ? selectedComponent : null}
                                                setSelectedComponent={setSelectedComponent}
                                                onPropertyChange={onPropertyChange}
                                                reportAreaRef={dropRef}
                                                disableRndDragging={disableRndDragging}
                                                setIsSavedClicked={setIsSavedClicked}
                                                setIsCreateNewTemplate={setIsCreateNewTemplate}
                                                setIsSaveAsClicked={setIsSaveAsClicked}
                                                setIsAnimated={setIsAnimated}
                                                handleAddMeasure={handleAddMeasure}
                                                isAddingMeasure={isAddingMeasure}
                                                multiSelectedComponents={multiSelectedComponents}
                                                setComponentPositions={setComponentPositions}
                                                componentPositions={componentPositions}
                                                isDraggingMul={isDragging}
                                                reportProperty={reportProperty}
                                                handleComponentSelect={handleComponentSelect}
                                            // updateGuidelines={updateGuidelines}
                                            // setDragging={setDragging}

                                            >
                                                {child}
                                            </DraggableComponent>
                                        </div>

                                    );
                                })}
                            </ResponsiveReactGridLayout>

                        </div>
                    }
                </Rnd>
            }

        </div >
    );
};

export default ReportBuildingArea;
