import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const CommaIcon = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.height}
            height={props.width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-6.5 0 32 32"
            fill={props.fill}
        >
            <path d="M9.375 21.969l2.188 1.031-2.938 6.125-1.875-0.906z"></path>
        </svg>

    </Tooltip>
);

export default CommaIcon;

