import { DeleteOutline } from "@mui/icons-material";
import {
  useCreateUserMutation,
  useUpdateOrganisationUserMutation,
  useGetAllUserMutation,
} from "../../features/user/userApiSlice";

import UserModal from "./components/UserModal";
import FilterSelect from "../../components/Filter/FilterSelect";
import Loading from "../../components/Loading";
import React from "react";
import Search from "../../components/Search/SearchNew";
import { Avatar } from "@mui/material";
import classNames from "classnames";
import { useGetAllRolesMutation } from "../../features/role/roleApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { usePermissionsStore } from "../../context/permissions.store";
const Users = () => {
  const user = useSelector(selectUser);
  const { permissions } = usePermissionsStore();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [userModalOpen, setUserModalOpen] = React.useState(false);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [statuses, setStatuses] = React.useState([]);

  const [users, setUsers] = React.useState([]);
  const [roles, setRoles] = React.useState([]);

  const [getUsers, { isLoading }] = useGetAllUserMutation();
  const [getRoles, { isLoading: rolesLoading }] = useGetAllRolesMutation();

  const [edit, { isLoading: editLoading }] =
    useUpdateOrganisationUserMutation();

  const [create, { isLoading: createLoading }] = useCreateUserMutation();

  const loading = isLoading || editLoading || createLoading || rolesLoading;

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await getUsers().unwrap();

      setUsers(data?.filter((u) => u.id !== user?.id) ?? []);

      const statuses = Array.from(new Set(data.map((User) => User.status)));

      setStatuses(statuses);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const fetchRoles = async () => {
    try {
      const { data } = await getRoles().unwrap();

      setRoles(data ?? []);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    const statuses = Array.from(new Set(users.map((user) => user.status)));

    setStatuses(statuses);
  }, [users]);

  React.useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <Loading loading={loading} />
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Users{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {
                users
                  ?.filter((user) =>
                    user?.firstname
                      ?.toLowerCase()
                      .includes(searchFilter?.toLowerCase())
                  )
                  .filter(
                    (user) =>
                      statusFilter.length === 0 ||
                      statusFilter.indexOf(user.status) !== -1
                  )?.length
              }
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
            <FilterSelect
              value={statusFilter}
              handleChange={handleStatusFilter}
              data={statuses}
              placeholder="Filter by Status ..."
            />
          </div>
        </div>
        {permissions?.permissions?.includes("create_user") && (
          <button
            onClick={() => {
              setUserModalOpen(true);
            }}
            className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
          >
            Create User
          </button>
        )}
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">Name</th>
                <th className="px-4 py-3">Email</th>
                <th className="px-4 py-3">Phone</th>
                <th className="px-4 py-3">Role</th>
                <th className="px-4 py-3">Status</th>
                <th className="px-4 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {users
                .filter((u) =>
                  `${u.firstname || ""} ${u.lastname || ""}`
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .map((user, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== user.length - 1 ? "border-b" : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <button className="flex flex-row items-center gap-4">
                        <Avatar name={user.firstname} image={null} />
                        <p className="m-0 font-semibold underline">
                          {`${user.firstname || ""} ${user.lastname || ""}`}
                        </p>
                      </button>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {user.email}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {user.phone ?? "NA"}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {user.type === "2" ? "Admin" : "User"}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <div
                        className={classNames(
                          user.status.toUpperCase() === "ACTIVE"
                            ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                            : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                          "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                        )}
                      >
                        <div
                          className={classNames(
                            "w-2 h-2 rounded-full",
                            user.status.toUpperCase() === "ACTIVE"
                              ? "bg-primarygreen"
                              : "bg-primarytextgrey"
                          )}
                        />
                        {user.status}
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {permissions?.permissions?.includes("delete_user") && (
                        <button
                          // onClick={() => removeGroupUser(user.userGroupId)}
                          className="flex items-center justify-center w-8 h-8 rounded-md aspect-square bg-primarylightgrey"
                        >
                          <DeleteOutline
                            style={{
                              fontSize: "20px",
                              color: "#EB5757",
                            }}
                          />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {userModalOpen && (
        <UserModal
          type={selectedUser ? "update" : "create"}
          open={userModalOpen}
          setOpen={setUserModalOpen}
          data={selectedUser}
          setData={setSelectedUser}
          edit={edit}
          create={create}
          roles={roles}
          fetchUsers={fetchUsers}
        />
      )}
    </div>
  );
};

export default Users;
