import React, { useEffect, useRef, useState } from "react";
import { useSchedulerStore } from "../../../context/scheduler.store";
import SchedulingSettingsFooter from "../components/SchedulingSettingsFooter";
import SchedulingSettingsHeader from "../components/SchedulingSettingsHeader";
import RunScheduler from "../RunScheduler";
import { useGetProvidersMutation } from "../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import GenerateSchedules from "../GenerateSchedules";
import moment from "moment";

const RunSchedulerMain = () => {
  const [opaque, setOpaque] = useState(false);
  const [option, setOption] = useState(0);
  const [createdCount, setCreatedCount] = useState(0);
  const user = useSelector(selectUser);
  const providers = useSchedulerStore((state) => state.providers);
  const setProviders = useSchedulerStore((state) => state.setProviders);

  const handleOptionChange = (option) => {
    setOption(option);
  };

  const [getProviders] = useGetProvidersMutation();

  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();

      const updatedProviders = data.map((provider) => {
        return {
          ...provider,
          isSelected: false,
          startDate: provider.startDate || moment().format("YYYY-MM-DD"),
          endDate:
            provider.endDate || moment().add(4, "weeks").format("YYYY-MM-DD"),
        };
      });

      setProviders(updatedProviders);
    } catch (error) {
      console.error(error?.data?.message);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  return (
    <div className="relative flex gap-0 flex-col w-full h-[calc(100vh-110px)]">
      {option !== 0 && (
        <SchedulingSettingsHeader
          setOpaque={setOpaque}
          title={
            option === 0
              ? "Run Scheduler"
              : `Created ${createdCount || 0} of ${
                  providers.filter((provider) => provider.isSelected).length
                } schedules`
          }
        />
      )}
      {option === 0 ? (
        <RunScheduler
          setOpaque={setOpaque}
          providers={providers}
          setProviders={setProviders}
        />
      ) : (
        <GenerateSchedules
          providers={providers}
          setCreatedCount={setCreatedCount}
        />
      )}
      {option < 1 && (
        <SchedulingSettingsFooter
          providers={providers.filter((provider) => provider.isSelected)}
          title={"GENERATE SCHEDULES"}
          handleClick={handleOptionChange}
          option={option}
          hidePrevious
        />
      )}
    </div>
  );
};

export default RunSchedulerMain;
