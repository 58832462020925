import React from "react";
import ReportHeader from "../reportHeader";
import ApptCancleReportFilter from "./components/apptCancleReportFilter";
import ApptCancleReportTable from "./components/apptCancleReportTable";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useGetAppointmentCancellationReportMutation } from "../../../features/report/reportApiSlice";

function index() {
  const today = dayjs();
  const [providers, setProviders] = React.useState([]);
  const [speciality, setSpeciality] = React.useState([]);
  const [visitType, setVisitType] = React.useState([]);
  const [selectedVisitType, setSelecetdVisitType] = React.useState([]);

  const [cancellationReports, setCancellationReports] = React.useState([]);

  const [filteredPv, setFilteredPv] = React.useState([]);
  const [filteredSpe, setFilteredSpe] = React.useState([]);

  const [selectedProviders, setSelectedProviders] = React.useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = React.useState([]);

  const [dateRange, setDateRange] = React.useState([
    today.startOf("month"),
    today,
  ]);
  const [dateRangeValue, setDateRangeValue] = React.useState("THIS_MONTH");
  const [isSelecetdValue, setIsSelectedValue] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("Something went wrong");
  const [clear, setClear] = React.useState(true);

  const [isSelectAllProviders, setIsSelectAllProviders] = React.useState(true);
  const [isSelectAllSpe, setIsSelectAllSpe] = React.useState(true);
  const [isSelectAllVisitType, setIsSelectAllVisitType] = React.useState(true);
  const [totals, setTotals] = React.useState([
    { name: "Total Cancellation", value: 0 },
    { name: "Total Billing Loss", value: "$0" },
    { name: "Cancellation Rate", value: "0%" },
  ]);
  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const [getAppointmentCancellationReport] =
    useGetAppointmentCancellationReportMutation();

  const getCancellationReport = async (filters) => {
    try {
      const from_date = filters.dateRange
        ? dayjs(filters.dateRange[0]).isValid()
          ? dayjs(filters.dateRange[0])?.format("DD-MM-YYYY")
          : ""
        : "";
      const to_date = filters.dateRange
        ? dayjs(filters.dateRange[1]).isValid()
          ? dayjs(filters.dateRange[1])?.format("DD-MM-YYYY")
          : ""
        : "";
      const appointmentCancelReport = await getAppointmentCancellationReport({
        date_range: filters.dateRangeValue
          ? filters.dateRangeValue
          : dateRangeValue,
        from_date: dateRangeValue ? "" : from_date,
        to_date: dateRangeValue ? "" : to_date,
        visit_type: filters.setHeaders ? "" : selectedVisitType,
        provider_id: filters.setHeaders ? "" : selectedProviders,
        provider_type: filters.setHeaders ? "" : selectedSpeciality,
      }).unwrap();
      setCancellationReports(
        [...appointmentCancelReport.data.report_data].sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )
      );
      setTotals([
        {
          name: "Total Cancellation",
          value: appointmentCancelReport.data.total_cancellations,
        },
        {
          name: "Total Billing Loss",
          value: `$${appointmentCancelReport.data.total_billing}`,
        },
        {
          name: "Cancellation Rate",
          value: `${appointmentCancelReport.data.cancellation_rate_perc}%`,
        },
      ]);
      if (filters.setHeaders == true) {
        const uniqueProviders = appointmentCancelReport.data.report_data.reduce(
          (acc, item) => {
            const existingProvider = acc.find(
              (provider) => provider.id === item.provider_id
            );
            if (!existingProvider) {
              acc.push({ name: item.provider_name, id: item.provider_id });
            }
            return acc;
          },
          []
        );
        setProviders(uniqueProviders);
        setFilteredPv(uniqueProviders);
        const uniqueVisitT = appointmentCancelReport.data.report_data.reduce(
          (acc, item) => {
            const existingItem = acc.find((v) => v.id === item.visit_type_id);
            if (!existingItem) {
              acc.push({ name: item.visit_type, id: item.visit_type_id });
            }
            return acc;
          },
          []
        );
        setVisitType(uniqueVisitT);
        const spe = appointmentCancelReport.data.report_data.map(
          (item) => item.provider_type
        );
        setSpeciality([...new Set(spe)]);
        setFilteredSpe([...new Set(spe)]);
        setSelectedProviders(uniqueProviders.map(i => i.id));
        setSelectedSpeciality([...new Set(spe)]);
        setSelecetdVisitType(uniqueVisitT.map(i => i.id));
      }
    } catch (err) {
      setOpen(true);
      setMessage(err?.response?.data?.message);
    }
  };
  const handleProviderFilter = (provider, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedProviders(providers.map(i => i.id))
      } else {
        setSelectedProviders([])
      }
    } else {
      if (selectedProviders.includes(provider)) {
        setSelectedProviders(
          selectedProviders.filter((filter) => filter !== provider)
        );
      } else {
        setSelectedProviders([...selectedProviders, provider]);
      }
    }
  };
  const handleSpecialityFilter = (spe, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelectedSpeciality(speciality)
      } else {
        setSelectedSpeciality([])
      }
    } else {
      if (selectedSpeciality.includes(spe)) {
        setSelectedSpeciality(
          selectedSpeciality.filter((filter) => filter !== spe)
        );
      } else {
        setSelectedSpeciality([...selectedSpeciality, spe]);
      }
    }
  };
  const handleVisitTypeFilter = (type, obj) => {
    setClear(false);
    if (obj.selectAll === true) {
      if (obj.isSelectAll) {
        setSelecetdVisitType(visitType.map(i => i.id))
      } else {
        setSelecetdVisitType([])
      }
    } else {
      if (selectedVisitType.includes(type)) {
        setSelecetdVisitType(
          selectedVisitType.filter((filter) => filter !== type)
        );
      } else {
        setSelecetdVisitType([...selectedVisitType, type]);
      }
    }
  };
  const exportPDF = () => {
    if (cancellationReports?.length > 0) {
      const doc = new jsPDF();
      doc.text("Appointment Cancellation Report", 20, 10);
      doc.autoTable({
        styles: { halign: "center" },
        headStyles: { fillColor: [245, 247, 249] },
        html: "#cancellationReport",
        theme: "plain",
        didParseCell: function (data) {
          if (data.row.index === 0 || data.row.index % 2 === 0) {
            data.cell.styles.fillColor = [255, 255, 255]; // White color for even rows
          } else {
            data.cell.styles.fillColor = [245, 247, 249]; // Light blue color for odd rows
          }
        },
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(13);
      doc.setTextColor(0, 0, 0);

      doc.text(
        `Total Cancellation: ${totals[0].value}\nTotal Billing Loss: ${totals[1].value}\nCancellation Rate: ${totals[2].value}`,
        15,
        doc.lastAutoTable.finalY + 10
      );
      doc.save("revenue-report.pdf");
      doc.save("appointment-cancellation-report.pdf");
    } else {
      alert("No Records Found");
    }
  };
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
        ? value.map((day) => DAY_MAP[day]).join(", ")
        : type === "date"
          ? moment(value).format("DD/MM/YYYY")
          : value;
  };

  const exportCSV = () => {
    if (cancellationReports?.length > 0) {
      let csvContent =
        "Appt_Date,Provider_Name,Speciality,Patient_id,Patient_Name,Visit_Type,Reason_for_Cancellation,Expected_Billing\r\n";

      cancellationReports.forEach((cancellation) => {
        const row = [
          parseNull(cancellation.visit_date, "NA", "date"),
          parseNull(cancellation.provider_name),
          parseNull(cancellation.provider_type),
          parseNull(cancellation.provider_id),
          parseNull(cancellation.patient_name),
          parseNull(cancellation.visit_type),
          parseNull(cancellation.cancel_reason),
          parseNull(cancellation.rate),
        ];
        csvContent += row.join(",") + "\r\n";
      });

      csvContent += `\r\nTotal_Cancellation: ${totals[0].value}`;
      csvContent += `\r\nTotal_Billing_Loss: ${totals[1].value}`;
      csvContent += `\r\nCancellation_Rate: ${totals[2].value}`;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "cancellation-report.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      alert("No Records Found");
    }
  };

  const handleClear = () => {
    setSelectedSpeciality([]);
    setSelectedProviders([]);
    setSelecetdVisitType([]);
    // setDateRange([today.startOf("month"), today]);
    setDateRange([null, null]);
    setDateRangeValue("");
    setProviders([]);
    setFilteredPv([]);
    setSpeciality([]);
    setFilteredSpe([]);
    setVisitType([]);
    // getCancellationReport({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
    setClear(true);
    setCancellationReports([])
  };
  React.useEffect(() => {
    // getProviders()
    // getSpeciality()
    //getVisitTypes()
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (!clear) {
      getCancellationReport({ dateRange: dateRange });
    }
    if (
      selectedProviders?.length > 0 ||
      selectedSpeciality?.length > 0 ||
      selectedVisitType?.length > 0
    ) {
      setIsSelectedValue(true);
    } else {
      setIsSelectedValue(false);
    }
  }, [selectedProviders, selectedSpeciality, selectedVisitType]);
  React.useEffect(() => {
    getCancellationReport({ setHeaders: true, dateRangeValue: "THIS_MONTH" });
    if (
      selectedProviders?.length > 0 ||
      selectedSpeciality?.length > 0 ||
      selectedVisitType?.length > 0
    ) {
      setIsSelectedValue(true);
    } else {
      setIsSelectedValue(false);
    }
  }, []);
  return (
    <>
      {/* <div className='d-flex w-full justify-center align-items-center absolute top-10 z-10'>
                <Alert severity="info" className='mx-3 mt-3 absolute w-96' onClose={() => { }}>
                    No Records Found
                </Alert>
            </div> */}
      <div className="relative flex flex-col w-full overflow-auto no-scrollbar standard-reports" style={{ height: "89vh" }}>
        <Snackbar
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={6000}
          message={message ? message : "Something went wrong"}
          severity="error"
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message ? message : "Something went wrong"}
          </Alert>
        </Snackbar>
        <ReportHeader
          title="Appointment Cancellation Report"
          exportCSV={exportCSV}
          exportPDF={exportPDF}
          totals={totals}
          total={cancellationReports.length}
        />
        <div
          className="p-2 d-flex flex-column overflow-auto align-items-center"
          style={{ height: "77vh" }}
        >
          <ApptCancleReportFilter
            getCancellationReport={getCancellationReport}
            dateRange={dateRange}
            setDateRange={setDateRange}
            dateRangeValue={dateRangeValue}
            setDateRangeValue={setDateRangeValue}
            handleClear={handleClear}
            setClear={setClear}
            isSelecetdValue={isSelecetdValue}
          />
          <ApptCancleReportTable
            cancellationReports={cancellationReports}
            setCancellationReports={setCancellationReports}
            filteredPv={filteredPv}
            providers={providers}
            setFilteredPv={setFilteredPv}
            selectedProviders={selectedProviders}
            handleProviderFilter={handleProviderFilter}
            speciality={speciality}
            selectedSpeciality={selectedSpeciality}
            handleSpecialityFilter={handleSpecialityFilter}
            filteredSpe={filteredSpe}
            setFilteredSpe={setFilteredSpe}
            isSelecetdValue={isSelecetdValue}
            visitType={visitType}
            selectedVisitType={selectedVisitType}
            handleVisitTypeFilter={handleVisitTypeFilter}
            isSelectAllVisitType={isSelectAllVisitType}
            setIsSelectAllVisitType={setIsSelectAllVisitType}
            isSelectAllProviders={isSelectAllProviders}
            setIsSelectAllProviders={setIsSelectAllProviders}
            isSelectAllSpe={isSelectAllSpe}
            setIsSelectAllSpe={setIsSelectAllSpe}
          />
        </div>
      </div>
    </>
  );
}

export default index;
