// import { TablePagination } from "@mui/material";
// import React, { useState } from "react";
// import "./Pagination.css";

// function Pagination({ count, api }) {
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(20);

//   const onChangePage = (data, limit) => {
//     setPage(data);
//     api({
//       limit: limit
//         ? limit === "View All"
//           ? count
//           : limit
//         : rowsPerPage === "View All"
//           ? count
//           : rowsPerPage,
//       skip: rowsPerPage * data,
//     });
//   };

//   const handleChangeRowsPerPage = (event) => {
//     const rows = event.target.value;
//     setRowsPerPage(rows);
//     onChangePage(0, rows);
//   };

//   const handleChangePage = (event, newPage) => {
//     onChangePage(newPage, rowsPerPage);
//   };

//   return (
//     <div>
//       {count > 20 && (
//         <div className="flex flex-row-reverse items-center custom-pagination">
//           <TablePagination
//             component="div"
//             count={count}
//             rowsPerPageOptions={[
//               10,
//               20,
//               50,
//               100,
//               // rowsPerPage === "View All" && count,
//               // "View All",
//               { label: 'All', value: count }
//             ]}
//             // rowsPerPageOptions={rowsPerPageOptions.map(option => option.label)}
//             page={page}
//             onPageChange={handleChangePage}
//             rowsPerPage={rowsPerPage === "View All" ? count : rowsPerPage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </div>
//       )}
//     </div>
//   );
// }

// export default Pagination;

import "./Pagination.css";

import React, { useState } from "react";

import { TablePagination } from "@mui/material";

function Pagination({ count, api, defaultRowsPerPage = 20, disableRowsPerPageSelection = false, tableKey }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  React.useEffect(() => {
    setPage(0);
    setRowsPerPage(defaultRowsPerPage);
  }, [tableKey, count, defaultRowsPerPage]);

  const onChangePage = (data, limit) => {
    setPage(data);
    api({
      limit: limit || rowsPerPage,
      skip: (limit || rowsPerPage) * data,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    onChangePage(0, rows);
  };

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage, rowsPerPage);
  };

  return (
    <div>
      {count > defaultRowsPerPage && (
        <div className="flex flex-row-reverse items-center custom-pagination">
          <TablePagination
            component="div"
            count={count}
            rowsPerPageOptions={
              disableRowsPerPageSelection ? [] : [10, 20, 50, 100, { label: "All", value: count }]
            }
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={disableRowsPerPageSelection ? null : handleChangeRowsPerPage}
          />
        </div>
      )}
    </div>
  );
}

export default Pagination;
