
import React, { useState } from 'react';

import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { Rnd } from 'react-rnd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SaveIcon from '@mui/icons-material/Save';
import { Tooltip } from '@mui/material';
import { useDrag } from 'react-dnd';
import CancelIcon from '@mui/icons-material/Cancel';

function DraggableComponent({
    type,
    id,
    position = { x: 0, y: 0 },
    setPosition,
    onDelete,
    children,
    isInReportArea,
    components,
    setComponents,
    properties,
    selectedComponent,
    setSelectedComponent,
    onPropertyChange,
    reportAreaRef,
    disableRndDragging,
    setFlag,
    disabled,
    setIsSavedClicked,
    setIsCreateNewTemplate,
    setIsSaveAsClicked,
    isTableView,
    isChartView,
    setIsAnimated,
    handleAddMeasure,
    isAddingMeasure,
    multiSelectedComponents,
    componentPositions,
    setComponentPositions,
    isDraggingMul,
    reportProperty,
    handleDrop,
    // updateGuidelines,
    // setDragging,
    setLayouts,
    handleComponentSelect
}) {
    const dragRef = React.useRef(null);
    const drgRefDisabled = React.useRef(null);
    const rndRef = React.useRef();
    const childRef = React.useRef();
    const [delta, setDelta] = React.useState({ width: 'auto', height: 'auto' });
    const [isDraggingWithButton, setIsDraggingWithButton] = React.useState(false);
    const prevSizeRef = React.useRef();
    const prevMarginRef = React.useRef();
    const [reportProp, setReportProp] = useState(reportProperty)

    const [{ isDragging }, drag] = useDrag({
        type: 'reportComponent',
        item: { type, id: id ? id : "", properties },
        size: () => {
            const initialWidth = dragRef.current.offsetWidth;
            const initialHeight = dragRef.current.offsetHeight;
            return { height: initialHeight, width: initialWidth }
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (isInReportArea) {
                const dropResult = monitor.getClientOffset();
                const containerOffset = dragRef.current.getBoundingClientRect();
                if (dropResult && containerOffset) {
                    const relativePosition = {
                        x: dropResult.x - containerOffset.left,
                        y: dropResult.y - containerOffset.top,
                    };
                    setPosition(relativePosition);
                    // updateGuidelines(id, relativePosition.x, relativePosition.y, properties.width, properties.height);
                }
            }
        },
    });
    const handleResizeStop = (e, direction, ref, delta, position) => {
        const reportAreaWidth = reportAreaRef?.current?.offsetWidth;
        const reportAreaHeight = reportAreaRef?.current?.offsetHeight;
        const reportAreaPaddingLeft = parseInt(window.getComputedStyle(reportAreaRef.current).paddingLeft, 10);
        const reportAreaPaddingRight = parseInt(window.getComputedStyle(reportAreaRef.current).paddingRight, 10);
        const maxWidth = reportAreaWidth - reportAreaPaddingLeft - reportAreaPaddingRight;

        let newWidth = parseInt(ref.style.width, 10);
        let newHeight = parseInt(ref.style.height, 10);

        // Get the component's left position
        const leftPosition = selectedComponent?.properties?.left || 0;
        // Ensure the width does not exceed the available space from its left position
        if (newWidth + leftPosition > maxWidth) {
            newWidth = maxWidth - leftPosition;
        }
        setDelta({
            width: newWidth,
            height: newHeight
        });
        setPosition(position);
        // updateGuidelines(id, position.x, position.y, newWidth, newHeight);
        const right = reportAreaWidth - (leftPosition + newWidth);
        const bottom = reportAreaHeight - (selectedComponent?.properties?.top + newHeight);
        setSelectedComponent((prev) => ({
            ...prev,
            properties: {
                ...prev?.properties,
                width: newWidth,
                height: newHeight,
                right,
                bottom
            }
        }));
        onPropertyChange({ width: newWidth, height: newHeight, right, bottom });
        // setDragging(false)
        setFlag(false);
    };
    React.useEffect(() => {
        drag(dragRef.current);
    }, [drag]);

    const calculateAvailableDimensions = (size, margin) => {
        const availableWidth = size?.width - (parseFloat(margin?.left) + parseFloat(margin?.right));
        return { availableWidth };
    };

    const adjustComponentSize = (component, availableWidth) => {
        if (!component) return null;

        const currentWidth = component?.properties?.width || 100;
        let newWidth = Math.min(currentWidth, availableWidth);

        if (currentWidth < availableWidth) {
            newWidth = availableWidth;
        }

        const adjustedLeft = Math.min(component?.properties?.left || 0, availableWidth - newWidth);

        return {
            ...component,
            properties: {
                ...component.properties,
                width: newWidth,
                left: adjustedLeft,
            },
        };
    };
    React.useEffect(() => {
        if (reportProperty?.size?.name !== reportProp?.size?.name || reportProperty?.margin?.name !== reportProp?.margin?.name && isInReportArea) {
            const { availableWidth } = calculateAvailableDimensions(reportProperty.size, reportProperty.margin);
            setComponents((prevComponents) => {
                const updatedComponents = prevComponents.map((component) =>
                    adjustComponentSize(component, availableWidth)
                );

                return updatedComponents;
            });

            if (selectedComponent) {
                const updatedSelectedComponent = adjustComponentSize(selectedComponent, availableWidth);
                setSelectedComponent(updatedSelectedComponent);

            }
            setReportProp(reportProperty)
        }
    }, [reportProperty]);
    return (
        <>
            {isInReportArea && (selectedComponent?.id === id) ?
                <div
                    id={id}
                    // size={{
                    //     width: multiSelectedComponents.includes(id) ? components?.find((item) => item.id == id)?.properties?.width : selectedComponent?.properties?.width || 100,
                    //     height: multiSelectedComponents.includes(id) ? components?.find((item) => item.id == id)?.properties?.height : selectedComponent?.properties?.height || 100
                    // }}
                    size={{ height: '100%', width: '100%' }}
                    // position={{
                    //     x: multiSelectedComponents.includes(id) ? components?.find((item) => item.id == id)?.properties?.left : selectedComponent?.properties?.left || 0,
                    //     y: multiSelectedComponents.includes(id) ? components?.find((item) => item.id == id)?.properties?.top : selectedComponent?.properties?.top || 0
                    // }}
                    bounds={false}
                    // onDragStop={(e, d) => {
                    //     const x = Math.round(d.x);
                    //     const y = Math.round(d.y);
                    //     setIsDraggingWithButton(false);
                    //     setPosition({ x: x > 0 ? x : 0, y: y > 0 ? y : 0 });
                    //     setSelectedComponent((prev) => ({
                    //         ...prev,
                    //         properties: {
                    //             ...prev?.properties,
                    //             top: y > 0 ? y : 0,
                    //             left: x > 0 ? x : 0,
                    //         }
                    //     }));
                    //     onPropertyChange({ left: x > 0 ? x : 0, top: y > 0 ? y : 0 });
                    //     setFlag(false);
                    // }}
                    // onDrag={(e, d) => {
                    //     const x = Math.round(d.x);
                    //     const y = Math.round(d.y);
                    //     // setComponents(prev => prev.map(comp =>
                    //     //     comp.id === id ? { ...comp, properties: { ...comp.properties, left: x, top: y } } : comp
                    //     // ));
                    //     updateGuidelines(id, x, y, selectedComponent?.properties?.width, selectedComponent?.properties?.height);
                    //     // setPosition({ x, y });
                    // }}
                    onDragStop={(e, d) => {
                        const x = Math.round(d.x);
                        const y = Math.round(d.y);

                        // Get the width of the component
                        const componentWidth = multiSelectedComponents.includes(id)
                            ? components?.find((item) => item.id == id)?.properties?.width
                            : selectedComponent?.properties?.width || 100;

                        // Get the width of the report area
                        const reportAreaWidth = reportAreaRef?.current?.offsetWidth;

                        // Calculate the new position
                        const newX = Math.max(0, Math.min(x, reportAreaWidth - componentWidth));
                        const newY = Math.max(0, y); // Assuming you want to allow dragging downwards
                        setIsDraggingWithButton(false);
                        // updateGuidelines(id, newX, newY, componentWidth, selectedComponent?.properties?.height);
                        // const newPosition = { x: newX, y: newY };
                        // updateGuidelines(id, newPosition.x, newPosition.y, selectedComponent?.properties?.width, selectedComponent?.properties?.height);
                        setPosition({ x: newX, y: newY });
                        setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                                ...prev?.properties,
                                top: newY,
                                left: newX,
                            }
                        }));
                        onPropertyChange({ left: newX, top: newY });
                        // setDragging(false)
                        setFlag(false);
                    }}
                    onResize={(e, direction, ref, delta, position) => {
                        // setSelectedComponent((prev) => ({
                        //     ...prev,
                        //     properties: {
                        //         ...prev.properties,
                        //         width: parseInt(ref.style.width, 10),
                        //         height: parseInt(ref.style.height, 10),
                        //         left: position.x,
                        //         top: position.y,
                        //     }
                        // }));
                        // updateGuidelines(id, position.x, position.y, parseInt(ref.style.width, 10), parseInt(ref.style.height, 10));
                    }}
                    onResizeStop={handleResizeStop}
                    // onResizeStart={() => setDragging(true)}
                    enableResizing={{
                        top: false,
                        right: false,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                    style={{
                        position: 'relative',
                        // border: '1px solid gray',
                        // zIndex: parseInt(id.split("-")[1], 10),
                        width: '100%',
                        height: '100%',
                        boxSizing: 'content-box',
                        boxShadow: 'rgba(9 30 66 / 20%) 0px 4px 8px -2px, rgba(9 30 66 / 20%) 0px 0px 0px 1px',
                        borderRadius:'3px'
                        // boxShadow: 'rgb(14 14 14 / 50%) 0px 0px 0.25em, rgb(13 14 14 / 20%) 0px 0.25em 1em'
                    }}
                    disableDragging={!isDraggingWithButton && disableRndDragging}
                >
                    <div
                        ref={childRef}
                        style={{
                            left: isInReportArea ? components?.find((item) => item.id == id)?.properties?.left : 'auto',
                            top: isInReportArea ? components?.find((item) => item.id == id)?.properties?.top : 'auto',
                            opacity: isDragging ? 0.5 : 1,
                            //   padding: '8px',
                            height: '100%',
                            width: '100%',
                            boxSizing: 'content-box',
                            overflow: selectedComponent?.type === 'textbox' || selectedComponent?.type === 'summery' ? 'unset' : 'auto'
                        }}>
                        {children}
                        {isInReportArea && (
                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                {!isAddingMeasure && selectedComponent?.id == id && selectedComponent?.type === 'summery' && (
                                    <button
                                        onClick={handleAddMeasure}
                                        style={{
                                            marginTop: "1px",
                                            alignSelf: "flex-end",
                                            color: "#075985",
                                            cursor: "pointer",
                                            fontSize: "10px",
                                            position: 'absolute',
                                            top: '-16px',
                                            // left: '-61px'
                                            left: '-46px'

                                        }}
                                    >
                                        <Tooltip title="Add Measure">
                                            <AddCardOutlinedIcon
                                                sx={{
                                                    height: '26px !important',
                                                    width: '26px !important'
                                                }}
                                            />
                                        </Tooltip>
                                    </button>
                                )}
                                <button
                                    ref={rndRef}
                                    className="drag-handle"
                                    style={{
                                        position: 'absolute',
                                        top: '-20px',
                                        right: '13px',
                                        backgroundColor: 'transparent',
                                        color: '#075985',
                                        border: 'none',
                                        cursor: 'move',
                                        paddingLeft: '2px',
                                        paddingRight: '6px'
                                    }}
                                // onMouseDown={() => {
                                //     setIsDraggingWithButton(true);
                                //     // setDragging(true)
                                // }}
                                // onMouseUp={() => {
                                //     setIsDraggingWithButton(false);
                                //     // setDragging(false)
                                // }}
                                // draggable='true'
                                // onDrag={() => {
                                //     setIsDraggingWithButton(true);
                                //     // setDragging(true)
                                // }}
                                // onMouseDownCapture={() => {
                                //     setIsDraggingWithButton(true);
                                //     // setDragging(true)
                                // }}

                                >
                                    <OpenWithIcon />
                                </button>
                                {/* <button
                                    style={{
                                        position: 'absolute',
                                        top: '-17px',
                                        right: '-6px',
                                        backgroundColor: '#075985',
                                        color: 'white',
                                        border: 'none',
                                        cursor: 'pointer',
                                        borderRadius: '50%',
                                        padding: '1px 6px',
                                        fontSize:'13px'

                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete(id);
                                    }}
                                >
                                    X
                                    
                                </button> */}
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '-20px',
                                        right: '-6px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete(id);
                                    }}
                                >
                                    <CancelIcon className='dashboard-close-icon'/>
                                </span>
                            </div>
                        )}
                    </div>
                </div> :
                <div
                    id={id}
                    ref={isInReportArea ? drgRefDisabled : dragRef}
                    // ref={dragRef}
                    className={!isInReportArea ? `  hover:bg-[#d0e2f0] px-0.5 py-0.5 rounded ` : ""}
                    style={{
                        position: isInReportArea ? 'relative' : 'static',
                        // left: isInReportArea ? components?.find((item) => item?.id == id)?.properties?.left : 'auto',
                        // top: isInReportArea ? components?.find((item) => item?.id == id)?.properties?.top : 'auto',
                        // width: isInReportArea ? components?.find((item) => item.id == id)?.properties?.width : delta?.width,
                        // height: isInReportArea ? components?.find((item) => item.id == id)?.properties?.height : delta?.height,
                        width: isInReportArea ? '100%' : delta?.width,
                        height: isInReportArea ? '100%' : delta?.height,
                        opacity: disabled || isDragging ? 0.6 : 1,
                        //border: isInReportArea ? '1px solid black' : "",
                        cursor: isInReportArea ? 'auto' : disabled ? 'not-allowed' : 'grab',
                        //display: isDragging ? 'none' : 'block', // Hide the original while dragging
                        // padding: '3px',
                        zIndex: parseInt(id.split("-")[1], 10),
                        border: isInReportArea && multiSelectedComponents?.includes(id) ? '1px solid gray' : 'none',
                        boxShadow: isInReportArea && multiSelectedComponents?.includes(id) ? 'rgb(14 14 14 / 50%) 0px 0px 0.25em, rgb(13 14 14 / 20%) 0px 0.25em 1em' : 'none',
                        transition: isInReportArea && isDraggingMul ? "none" : "transform 0.1s ease-out",
                    }}>
                    {children}
                    {isInReportArea && (
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        </div>
                    )}
                </div >
                // </Rnd >
            }
        </>
    )
}
export default DraggableComponent;