import * as React from "react";

import Tooltip from '@mui/material/Tooltip';

const DataviewIcon = (props) => (
    <Tooltip title={props.tooltipText}>
        <svg
            width={props.height}
            height={props.width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={props.fill}
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M15 21H9V10h6v11zm2 0V10h5v10a1 1 0 0 1-1 1h-4zM7 21H3a1 1 0 0 1-1-1V10h5v11zM22 8H2V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4z" />
        </svg>

    </Tooltip>
);

export default DataviewIcon;

