import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import React from 'react'
import dayjs from "dayjs";

function FilterPanel({
    handleApplyFilter,
    reportProperty,
    setReportProperty,
    locations,
    isEmplty
}) {
    const [isExpandPropertyPanel, setIsExpandPropertyPanel] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [toggleValue, setToggleValue] = React.useState('AND');
    const [addedSelections, setAddedSelections] = React.useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [selectedLocations, setSelectedLocations] = React.useState([]);
    const dropdownRef = React.useRef(null);
    const today = dayjs();
    // const locations = [
    //     { id: 1, name: "Rajkot" },
    //     { id: 2, name: "Amreli" },
    //     { id: 3, name: "Surat" },
    //     { id: 4, name: "Ahmedabad" },
    // ];
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleToggleChange = () => {
        if (!inputValue) return;
        setToggleValue(toggleValue === 'AND' ? 'OR' : 'AND');
    };
    const handleAddSelection = () => {
        if (inputValue) {
            setReportProperty((prev) => ({
                ...prev,
                filters: {
                    ...prev.filters,
                    searchQuery: [...(prev?.filters?.searchQuery || []), { input: inputValue, toggle: toggleValue }]
                }
            }))
            // setAddedSelections([...addedSelections, { input: inputValue, toggle: toggleValue }]);
            setInputValue(''); // Clear the input
            setToggleValue('AND'); // Reset toggle to 'AND'
        }
    };
    const handleRemoveSelection = (index) => {
        setReportProperty((prev) => ({
            ...prev,
            filters: {
                ...prev.filters,
                searchQuery: prev?.filters?.searchQuery.filter((_, i) => i !== index)
            }
        }))
        // setAddedSelections(addedSelections.filter((_, i) => i !== index));
    };
    const handleLocationSelect = (location) => {
        // setSelectedLocations((prevSelected) =>
        //     prevSelected.some((item) => item.id === location.id)
        //         ? prevSelected.filter((item) => item.id !== location.id)
        //         : [...prevSelected, location]
        // );
        setReportProperty((prev) => {
            return {
                ...prev,
                filters: {
                    ...prev?.filters,
                    locationFilter: prev?.filters?.locationFilter.some((item) => item === location)
                        ? prev?.filters?.locationFilter.filter((item) => item !== location)
                        : [...prev.filters.locationFilter, location]
                }
            }
        })
    };
    // Close dropdown when clicking outside
    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    React.useEffect(() => {
        if (isEmplty && isExpandPropertyPanel) {
            setIsExpandPropertyPanel(false);
        }
    }, [isEmplty, isExpandPropertyPanel]);
    return (
        <div
            className={`sidebar menu-main   ${true ? "menu-show" : "menu-hide"}   d-flex flex-col ${isExpandPropertyPanel ? 'w-56 min-w-56 ' : 'min-w-5 w-10'} no-scrollbar bg-[#edf0f5] border-l border-slate-300 custom-scrollbar`}
            style={{ overflow: 'auto', height: '100%' }}
        >
            <div style={{
                top: 0,
                position: 'sticky',
                backgroundColor: '#edf0f5',
                zIndex: 11,
            }}>
                <span className={`d-flex  justify-center ${isEmplty ? 'opacity-50' : 'opacity-100 '}  ${isExpandPropertyPanel ? 'flex-row' : 'flex-col-reverse'} justify-between px-2 py-1 sticky top-0 bg-[#edf0f5] transition-all duration-300 ease-in-out`}>
                    <p className={`m-0 text-sm font-bold text-gray-600 whitespace-nowrap ${!isExpandPropertyPanel ? 'rotate-90 translate-y-5' : "rotate-0 translate-y-0 "}`}>Filters</p>
                    <KeyboardDoubleArrowRightOutlinedIcon
                        className={`font-bold text-[#075985] ${isEmplty ? 'cursor-not-allowed' : 'cursor-pointer'} ${isExpandPropertyPanel ? 'rotate-180' : 'rotate-0'}`}
                        onClick={() => {
                            !isEmplty && setIsExpandPropertyPanel(!isExpandPropertyPanel);
                        }}
                        sx={{
                            width: '30px !important'
                        }} />
                </span>
                {isExpandPropertyPanel &&
                    <>
                        <span className="flex flex-col py-1 px-1">
                            <p className="m-0 sidebar-section-label whitespace-nowrap pl-1">
                                Select Date:
                            </p>
                            <div className='d-flex flex-row align-items-center mx-1 filter-panel-date'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateRangePicker']}>
                                        <DateRangePicker
                                            localeText={{ start: 'From', end: 'To' }}
                                            calendars={2}
                                            value={reportProperty?.filters?.dateFilter}
                                            onChange={(newValue) => {
                                                // setDateRange(newValue);
                                                // setDateRangeValue('')
                                                setReportProperty((prev) => ({
                                                    ...prev,
                                                    filters: {
                                                        ...prev?.filters,
                                                        dateFilter: newValue
                                                    }
                                                }))
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </span>
                        <span className="flex flex-col py-2 px-2">
                            <p className="m-0 sidebar-section-label whitespace-nowrap self-start">
                                Select Location:
                            </p>
                            <div
                                className="relative flex justify-content-between bg-white input-border-custome pl-1 items-center  rounded-[5px] w-[180px] h-[25px] cursor-pointer"
                                ref={dropdownRef}
                            >
                                <span className="block border-0 items-center text-xs text-[#5f6673] w-[150px] truncate ">
                                    {reportProperty?.filters?.locationFilter.length > 0
                                        ? locations?.filter((item) => reportProperty?.filters?.locationFilter?.includes(item?.id))?.map(item => item?.name).join(", ")
                                        : "Select Location"}
                                </span>
                                <button
                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                >
                                    <KeyboardArrowDownIcon className="text-gray-800" />
                                </button>
                                {isDropdownOpen && (
                                    <div
                                        className="absolute flex flex-col overflow-auto bg-white border rounded-md max-h-52 top-7 z-10 left-0 w-full"
                                        style={{
                                            boxShadow:
                                                "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                                        }}
                                    >
                                        {console.log(selectedLocations, ">>>> Selected locationdd")}
                                        {locations.length === 0 ? (
                                            <p className="text-gray-500 text-xs p-2 text-center">No locations available</p>
                                        ) : (
                                            locations.map((location) => (
                                                <label
                                                    key={location.id}
                                                    className="hover:bg-gray-100 border-b flex px-2 py-2 items-center gap-2"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="w-4 h-4 cursor-pointer"
                                                        checked={reportProperty?.filters?.locationFilter.some((loc) => loc === location.id)}
                                                        onChange={() => handleLocationSelect(location?.id)}
                                                    />
                                                    <p className="m-0 text-xs cursor-pointer">{location.name}</p>
                                                </label>
                                            ))
                                        )}
                                        {/* {locations.map((location) => (
                                            <label
                                                key={location.id}
                                                className="hover:bg-gray-100 border-b flex px-2 py-2 items-center gap-2"
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 cursor-pointer"
                                                    checked={reportProperty?.filters?.locationFilter.some((loc) => loc === location.id)}
                                                    onChange={() => handleLocationSelect(location?.id)}
                                                />
                                                <p className="m-0 text-xs cursor-pointer">{location.name}</p>
                                            </label>
                                        ))} */}
                                    </div>
                                )}
                            </div>
                        </span>
                        <span className="flex px-2 py-2 gap-1">
                            <input
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                className="block px-1 py-0.5 sidebar-section-text bg-white input-border-custome rounded-[5px] w-[110px]"
                                placeholder="Search..."
                            />
                            <span className="flex input-border-custome rounded-[5px] ">
                                <span
                                    // className={`cursor-pointer  rounded-l-[4px] px-1 py-1 text-xs text-center  ${toggleValue === 'AND' ? 'bg-[#6b7280] text-white' : 'bg-white text-[#4b5563]'
                                    //     }`}
                                    className={`rounded-l-[4px] px-1 py-1 text-xs text-center transition
                                    ${!inputValue ? 'cursor-not-allowed opacity-50 bg-gray-300 text-gray-500'
                                            : toggleValue === 'AND' ? 'bg-[#6b7280] text-white cursor-pointer' : 'bg-white text-[#4b5563] cursor-pointer'}
                                `}
                                    onClick={() => handleToggleChange('AND')}
                                >
                                    AND
                                </span>
                                <span
                                    // className={`cursor-pointer  rounded-r-[4px] px-1 py-1  text-xs text-center  ${toggleValue === 'OR' ? 'bg-[#6b7280] text-white' : 'bg-white text-[#4b5563]'
                                    //     }`}
                                    className={`rounded-r-[4px] px-1 py-1 text-xs text-center transition
                                    ${!inputValue ? 'cursor-not-allowed opacity-50  text-gray-500'
                                            : toggleValue === 'OR' ? 'bg-[#6b7280] text-white cursor-pointer' : 'bg-white text-[#4b5563] cursor-pointer'}
                                `}
                                    onClick={() => handleToggleChange('OR')}
                                >
                                    OR
                                </span>
                            </span>
                            <button
                                onClick={handleAddSelection}
                                disabled={!inputValue}
                                className={`rounded-[5px] text-white  bg-[#6b7280] flex items-center px-0.5 ${!inputValue ? "cursor-not-allowed opacity-[0.5]" : "cursor-pointer opacity-[1]"}`}
                            >
                                <CheckIcon className='filter-righticon' />
                            </button>
                        </span>
                        {reportProperty?.filters?.searchQuery?.length > 0 &&
                            <span className='d-flex border border-gray-200 bg-white rounded px-1 py-1 mx-1 mb-1 max-h-[150px] overflow-auto custom-scrollbar'>
                                <ul className="d-flex items-center flex-wrap space-y-1 px-1 w-full">
                                    {reportProperty?.filters?.searchQuery?.map((selection, index) => (
                                        <span className='d-flex items-center justify-cenetr'>
                                            <li key={index} className="flex items-center text-gray-600 font-semibold justify-between text-xs input-border-custome rounded-[5px] bg-white pl-1">
                                                <span>{selection?.input}</span>
                                                <button
                                                    onClick={() => handleRemoveSelection(index)}
                                                    className=" text-gray-700 text-xs cursor-pointer"
                                                >
                                                    <CloseIcon sx={{ height: "15px" }} />
                                                </button>
                                            </li>
                                            {index !== reportProperty?.filters?.searchQuery?.length - 1 && <span className='text-xs font-semibold mx-1'>{selection?.toggle}</span>}
                                        </span>
                                    ))}
                                </ul>
                            </span>}
                        <span>

                            <button
                                onClick={() => {
                                    setInputValue(''); // Clear the input
                                    setToggleValue('AND'); // Reset toggle to 'AND'
                                    handleApplyFilter(true);

                                }}
                                // disabled={reportProperty?.filters?.searchQuery?.length <= 0 && reportProperty?.filters?.locationFilter?.length <= 0}
                                className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2">
                                Clear
                            </button>
                            <button
                                onClick={() => handleApplyFilter(false)}
                                disabled={reportProperty?.filters?.searchQuery?.length <= 0 && reportProperty?.filters?.locationFilter?.length <= 0 && reportProperty?.filters?.dateFilter?.length <= 0}
                                className="px-4 py-1 text-sm btn-bgcolor font-bold text-white rounded-md  disabled:opacity-40 disabled:cursor-not-allowed m-2">
                                Apply
                            </button>
                        </span>
                    </>
                }
            </div>
        </div >
    )
}

export default FilterPanel