import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (credentials) => ({
        url: "/auth/sign-in",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    signUp: builder.mutation({
      query: (credentials) => ({
        url: "/auth/sign-up",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    verifyEmail: builder.mutation({
      query: (credentials) => ({
        url: "/auth/verify-email",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    verifyOtp: builder.mutation({
      query: (credentials) => ({
        url: "/auth/verify-otp",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "GET",
      }),
    }),
    googleAuth: builder.mutation({
      query: () => ({
        url: "/auth/google",
        method: "GET",
      }),
    }),
    googleUser: builder.mutation({
      query: () => ({
        url: "/auth/google/user",
        method: "GET",
      }),
    }),
    forgot: builder.mutation({
      query: (credentials) => ({
        url: "/auth/forgot",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useVerifyEmailMutation,
  useVerifyOtpMutation,
  useLogoutMutation,
  useGoogleAuthMutation,
  useGoogleUserMutation,
  useForgotMutation,
} = authApiSlice;
