import React from "react";
import PivotFilter from "./components/PivotFilter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import dayjs from "dayjs";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useGetPatientReportMutation } from "../../../features/report/reportApiSlice";
import { useGetProviderActivityReportMutation } from "../../../features/report/reportApiSlice";
import { useGetRevenueReportMutation } from "../../../features/report/reportApiSlice";
import { useGetAppointmentCancellationReportMutation } from "../../../features/report/reportApiSlice";
import {
  useGetTemplatesMutation,
  useCreateTemplatesMutation,
  useUpdateTemplatesMutation,
} from "../../../features/report/reportApiSlice";
import html2canvas from "html2canvas";
import ReportHeader from "../../Reports/reportHeader";
import UploadCSVModal from "./components/uploadCSVModal";
import PivotTable from "./components/PivotTable";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import classNames from "classnames";
import { Icons } from "../../../assets";
import { Tooltip } from "@mui/material";

function index() {
  const [totals, setTotals] = React.useState([
    { name: "Total Records Found", value: 0 },
  ]);
  const [reports, setReports] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("Something went wrong");
  const today = dayjs();
  const [clear, setClear] = React.useState(false);

  //Header
  const [isUploadCsv, setIsUploadCsv] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [selectedReport, setSelectedReport] = React.useState(null);

  const [optionListOpen, setOptionsListOpen] = React.useState(false);
  const [reportListOpen, setReportListOpen] = React.useState(false);
  const optionRef = React.useRef(null);
  const reportRef = React.useRef(null);
  //upload csv
  const [templates, setTemplates] = React.useState([]);
  const [tmpName, setTmpName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [selectedTmp, setselectedTmp] = React.useState(null);
  const [severity, setSeverity] = React.useState("error");
  const [loading, setLoading] = React.useState(false);
  const DAY_MAP = {
    MONDAY: "M",
    TUESDAY: "T",
    WEDNESDAY: "W",
    THURSDAY: "Th",
    FRIDAY: "F",
    SATURDAY: "Sa",
    SUNDAY: "Su",
  };
  const [getPatientReport] = useGetPatientReportMutation();
  const [getProviderActivityReport] = useGetProviderActivityReportMutation();
  const [getRevenueReport] = useGetRevenueReportMutation();
  const [getAppointmentCancellationReport] =
    useGetAppointmentCancellationReportMutation();
  const [getTemplates] = useGetTemplatesMutation();
  const [creteTemplates] = useCreateTemplatesMutation();
  const [updateTemplates] = useUpdateTemplatesMutation();

  const getPatientReports = async (filters) => {
    setLoading(true);
    try {
      const patientReports = await getPatientReport({
        dateRangeValue: "",
        from_date: "",
        to_date: "",
        patient_id: "",
        city: "",
        state: "",
        visit_type: "",
        provider_id: "",
        location: "",
      }).unwrap();
      setReports(
        [...patientReports.data]?.sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )
      );
      setTotals([
        { name: "Total Records Found", value: patientReports.data.length },
      ]);
    } catch (err) {
      console.log(err, "errorbchs");
      setSeverity("error");
      setLoading(false);
      setOpen(true);
      setMessage(err?.response?.data?.message);
    }
  };
  const getProviderReports = async (filters) => {
    setLoading(true);
    try {
      const providerActivityReport = await getProviderActivityReport({
        date_range: "",
        from_date: "",
        to_date: "",
        provider_type: "",
        state: "",
        provider_id: "",
      }).unwrap();
      setReports(
        [...providerActivityReport.data.report_data].sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )
      );
      setTotals([
        {
          name: "Total Records Found",
          value: providerActivityReport?.data?.report_data.length,
        },
        {
          name: "Total Distance Travelled",
          value: `${providerActivityReport.data.total_distance_travelled} miles`,
        },
      ]);
    } catch (err) {
      setLoading(false);
      setSeverity("error");
      setOpen(true);
      setMessage(err?.response?.data?.message);
    }
  };
  const getRevenueReports = async (filters) => {
    setLoading(true);
    try {
      const revenueReport = await getRevenueReport({
        date_range: "",
        provider_type: "",
        from_date: "",
        to_date: "",
        visit_type: "",
        provider_id: "",
        patient_id: "",
      }).unwrap();
      setReports(
        [...revenueReport.data.report_data].sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )
      );
      setTotals([
        {
          name: "Total Records Found",
          value: revenueReport.data.report_data.length,
        },
        {
          name: "Total Revenue",
          value: `$${revenueReport.data.total_revenue?.toLocaleString(
            "en-US"
          )}`,
        },
      ]);
    } catch (err) {
      setLoading(false);
      setSeverity("error");
      setOpen(true);
      setMessage(err.response?.data?.message);
    }
  };
  const getCancellationReport = async (filters) => {
    setLoading(true);
    try {
      const appointmentCancelReport = await getAppointmentCancellationReport({
        date_range: "",
        from_date: "",
        to_date: "",
        visit_type: "",
        provider_id: "",
        provider_type: "",
      }).unwrap();
      setReports(
        [...appointmentCancelReport.data.report_data].sort(
          (a, b) => new Date(b.visit_date) - new Date(a.visit_date)
        )
      );
      setTotals([
        {
          name: "Total Cancellation",
          value: appointmentCancelReport.data.total_cancellations,
        },
        {
          name: "Total Billing Loss",
          value: `$${appointmentCancelReport.data.total_billing}`,
        },
        {
          name: "Cancellation Rate",
          value: `${appointmentCancelReport.data.cancellation_rate_perc}%`,
        },
      ]);
    } catch (err) {
      setLoading(false);
      setSeverity("error");
      setOpen(true);
      setMessage(err?.response?.data?.message);
    }
  };
  const getUserTemplates = async () => {
    try {
      const result = await getTemplates().unwrap();
      setTemplates(result?.data);
    } catch (error) {
      console.log(error, "Error");
    }
  };
  const exportPDF = () => {
    const doc = new jsPDF("landscape");
    doc.text("Patient Visit Report", 20, 10);
    const table = document.getElementById("patientVisitReport");
    html2canvas(table).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imageData, "PNG", 2, 40, imgWidth, imgHeight);
      doc.save("table-data.pdf");
    });
  };
  const parseNull = (value, replacement = "NA", type = "string") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : Array.isArray(value)
      ? value.map((day) => DAY_MAP[day]).join(", ")
      : type === "date"
      ? moment(value).format("DD/MM/YYYY")
      : value;
  };
  const exportCSV = () => {
    const result = processApiData(reports, { isCsv: true });
    const result2 = csvData.map((item, index) => {
      return index === 0 ? item : `\n${item}`;
    });
    const blob = new Blob([result && reports.length > 0 ? result : result2], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "table-data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const transformToJSON = (data) => {
    const headers = data[0];
    const rows = data.slice(1);

    // Step 1: Determine all possible keys
    const allKeys = new Set(headers);

    // Step 2: Normalize each object
    return rows.map((row) => {
      const obj = {};
      row.forEach((cell, i) => {
        obj[headers[i]] = cell;
      });

      // Add missing keys with null value
      allKeys.forEach((key) => {
        if (!(key in obj)) {
          obj[key] = null;
        }
      });

      return obj;
    });
  };
  const handleChange = async (file) => {
    setIsUploadCsv(false);
    setLoading(true);
    try {
      const fileSizeInKB = file.size / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
      console.log(`File size: ${fileSizeInKB.toFixed(2)} KB`);
      console.log(`File size: ${fileSizeInMB.toFixed(2)} MB`);
      if (fileSizeInMB > 3) {
        setOpen(true);
        setMessage("File size is more than 3 MB.");
        setLoading(false);
        return new Error("File size is more than 3 MB.");
      } else {
        if (file) {
          const fileExtension = file.name.split(".").pop().toLowerCase();
          const reader = new FileReader();
          reader.onload = async (e) => {
            let jsonData;
            if (fileExtension === "csv") {
              const data = e.target.result;
              const parsedData = Papa.parse(data, { header: true });
              const headers = Object.keys(parsedData.data[0]); // Assuming first row is headers
              const values = parsedData.data.map((row) => Object.values(row));
              jsonData = [headers, ...values];
              const headers2 = jsonData[0].filter(
                (header) =>
                  header !== "" && header !== undefined && header !== null
              );
              const adjustedData = jsonData.map((row, rowIndex) => {
                if (rowIndex === 0) return headers2;
                return row.filter(
                  (_, colIndex) =>
                    jsonData[0][colIndex] !== "" &&
                    jsonData[0][colIndex] !== undefined &&
                    jsonData[0][colIndex] !== null
                );
              });
              jsonData = adjustedData;
            } else {
              const data = new Uint8Array(e.target.result);
              const workbook = XLSX.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
              const headers2 = jsonData[0].filter(
                (header) =>
                  header !== "" && header !== undefined && header !== null
              );
              const adjustedData = jsonData.map((row, rowIndex) => {
                if (rowIndex === 0) return headers2;
                return row.filter(
                  (_, colIndex) =>
                    jsonData[0][colIndex] !== "" &&
                    jsonData[0][colIndex] !== undefined &&
                    jsonData[0][colIndex] !== null
                );
              });
              jsonData = adjustedData;
            }
            jsonData = jsonData.filter((row) =>
              row.some(
                (cell) => cell !== "" && cell !== undefined && cell !== null
              )
            );
            const jsonLikeData = await transformToJSON(jsonData);
            if (!selectedTmp) {
              try {
                await creteTemplates({
                  name: tmpName,
                  description: description,
                  tableData: jsonLikeData,
                }).unwrap();
                setOpen(true);
                setSeverity("success");
                setMessage("Template created successfully");
              } catch (err) {
                if (
                  err?.data?.message &&
                  err?.data?.message?.includes("already exists")
                ) {
                  setMessage(err?.data?.message);
                } else if (err.message?.includes("syntax error")) {
                  setMessage(
                    "The uploaded file contains invalid data format or mismatched data types. Please check your file and try again."
                  );
                } else if (err.message?.includes("INSERT")) {
                  setMessage("The uploaded file has missing or extra columns.");
                } else {
                  setMessage("An unexpected error occurred. Please try again.");
                }
                setselectedTmp(null);
                setTmpName("");
                setDescription("");
                setSeverity("error");
                setOpen(true);
                setLoading(false);
                return;
              }
            } else {
              try {
                const response = await updateTemplates({
                  templateId: selectedTmp?.id,
                  tableData: jsonLikeData,
                }).unwrap();
                setOpen(true);
                setSeverity("success");
                setMessage("Template updated successfully");
              } catch (err) {
                if (err?.data?.message?.includes("already exists")) {
                  setMessage(
                    "The template name already exists. Please choose a different name."
                  );
                } else if (err?.data?.message?.includes("syntax error")) {
                  setMessage(
                    "The uploaded file contains invalid data format or mismatched data types. Please check your file and try again."
                  );
                } else if (err?.data?.message) {
                  setMessage(err?.data?.message);
                } else {
                  setMessage("The uploaded file has missing or extra columns.");
                }
                setselectedTmp(null);
                setTmpName("");
                setDescription("");
                setSeverity("error");
                setOpen(true);
                setLoading(false);
                return;
              }
            }
            await getUserTemplates();
            setCsvData(jsonData);
            setTotals([
              { name: "Total Records Found", value: jsonLikeData?.length },
            ]);
            setTmpName("");
            setselectedTmp(null);
            setDescription("");
          };
          reader.onerror = (error) => {
            console.error("Error reading file:", error);
          };
          if (fileExtension === "csv") {
            reader.readAsText(file);
          } else {
            reader.readAsArrayBuffer(file);
          }
          //reader.readAsArrayBuffer(file);
        }
      }
    } catch (err) {
      setOpen(true);
      setSeverity("error");
      setMessage("Something went wrong");
    }
  };
  const processApiData = (data, obj) => {
    if (selectedReport?.value == 1) {
      const aggregatedData = {};
      data.forEach((record) => {
        const date = new Date(record.visit_date).toLocaleDateString("en-US");
        const provider = record.provider_name;
        const visitType = record.visit_type;

        const key = `${date}|${provider}|${visitType}`;
        if (!aggregatedData[key]) {
          aggregatedData[key] = 0;
        }
        aggregatedData[key] += 1;
      });
      const result = [["Date", "Provider", "Visit Type", "Patient Count"]];
      Object.keys(aggregatedData).forEach((key) => {
        const [date, provider, visitType] = key.split("|");
        obj?.isCsv && obj?.isCsv == true
          ? result.push(`\n${[date, provider, visitType, aggregatedData[key]]}`)
          : result.push([date, provider, visitType, aggregatedData[key]]);
      });
      return result;
    } else if (selectedReport?.value === 2) {
      const aggregatedData = {};
      data.forEach((record) => {
        const date = new Date(record.visit_date).toLocaleDateString("en-US");
        const provider = record.provider_name;
        const speciality = record.provider_type;

        const key = `${date}|${provider}|${speciality}`;
        if (!aggregatedData[key]) {
          aggregatedData[key] = {
            count: 0,
            visits_completed: 0,
            dist_travelled: 0,
          };
        }
        aggregatedData[key].count += 1;
        aggregatedData[key].visits_completed += record.appointment_completed;
        aggregatedData[key].dist_travelled += record.distance_from_provider;
      });
      const result = [
        [
          "Date",
          "Provider",
          "speciality",
          "Visits Completed",
          "Distance Travelled",
          "Patient Count",
        ],
      ];
      Object.keys(aggregatedData).forEach((key) => {
        const [date, provider, speciality] = key.split("|");
        const { count, visits_completed, dist_travelled } = aggregatedData[key];
        obj?.isCsv && obj?.isCsv == true
          ? result.push(
              `\n${[
                date,
                provider,
                speciality,
                visits_completed,
                dist_travelled,
                count,
              ]}`
            )
          : result.push([
              date,
              provider,
              speciality,
              visits_completed,
              dist_travelled,
              count,
            ]);
      });
      return result;
    } else if (selectedReport?.value === 3) {
      const aggregatedData = {};
      data.forEach((record) => {
        const date = new Date(record.visit_date).toLocaleDateString("en-US");
        const provider = record?.provider_name;
        const visitType = record?.visit_type;
        const speciality = record?.provider_type;

        const key = `${date}|${provider}|${visitType}|${speciality}`;
        if (!aggregatedData[key]) {
          aggregatedData[key] = { count: 0, totalRevenue: 0 };
        }
        aggregatedData[key].count += 1;
        aggregatedData[key].totalRevenue += record?.rate;
      });
      const result = [
        [
          "Date",
          "Provider",
          "Visit Type",
          "Speciality",
          "Expected Revenue",
          "Patient Count",
        ],
      ];
      Object.keys(aggregatedData).forEach((key) => {
        const [date, provider, visitType, speciality] = key.split("|");
        const { count, totalRevenue } = aggregatedData[key];
        result.push([
          date,
          provider,
          visitType,
          speciality,
          totalRevenue,
          count,
        ]);
      });
      return result;
    } else if (selectedReport?.value === 4) {
      const aggregatedData = {};
      data.forEach((record) => {
        const date = new Date(record.visit_date).toLocaleDateString("en-US");
        const provider = record?.provider_name;
        const visitType = record?.visit_type;
        const speciality = record?.provider_type;
        const key = `${date}|${provider}|${visitType}|${speciality}`;
        if (!aggregatedData[key]) {
          aggregatedData[key] = { count: 0, totalBilling: 0 };
        }
        aggregatedData[key].count += 1;
        aggregatedData[key].totalBilling += record?.rate;
      });
      const result = [
        [
          "Date",
          "Provider",
          "Visit Type",
          "Speciality",
          "Expected Billing",
          "Patient Count",
        ],
      ];
      Object.keys(aggregatedData).forEach((key) => {
        const [date, provider, visitType, speciality] = key.split("|");
        const { count, totalBilling } = aggregatedData[key];
        result.push([
          date,
          provider,
          visitType,
          speciality,
          totalBilling,
          count,
        ]);
      });
      return result;
    }
  };
  React.useEffect(() => {
    setTotals([{ name: "Total Records Found", value: 0 }]);
    if (!clear && selectedOption?.value == 3) {
      setSelectedReport(null);
      setCsvData([]);
      setIsUploadCsv(false);
    } else if (!clear && selectedOption?.value === 1) {
      setCsvData([]);
      setIsUploadCsv(true);
      setSelectedReport(null);
    } else {
      setCsvData([]);
      setIsUploadCsv(false);
      setSelectedReport(null);
    }
  }, [selectedOption]);
  React.useEffect(() => {
    setTotals([{ name: "Total Records Found", value: 0 }]);
    if (selectedOption?.value === 3) {
      setCsvData([]);
      setIsUploadCsv(false);
      setSelectedReport(null);
    } else if (selectedOption?.value == 1) {
      setCsvData([]);
      setIsUploadCsv(true);
      setSelectedReport(null);
    } else {
      setCsvData([]);
      setIsUploadCsv(false);
      setSelectedReport(null);
    }
  }, [selectedOption]);
  React.useEffect(() => {
    setLoading(true);
    setReports([]);
    if (selectedReport) {
      setTotals([{ name: "Total Records Found", value: 0 }]);
      if (selectedReport?.value === 1) {
        getPatientReports();
      } else if (selectedReport?.value === 2) {
        getProviderReports();
      } else if (selectedReport?.value === 3) {
        getRevenueReports();
      } else if (selectedReport?.value === 4) {
        getCancellationReport();
      }
    }
  }, [selectedReport]);

  React.useEffect(() => {
    getUserTemplates();
  }, []);
  const handleClickOutside = (event) => {
    if (optionRef.current && !optionRef.current.contains(event.target)) {
      setOptionsListOpen(false);
    }
    if (reportRef.current && !reportRef.current.contains(event.target)) {
      setReportListOpen(false);
    }
  };
  React.useEffect(() => {
    if (optionListOpen || reportListOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionListOpen, reportListOpen]);
  return (
    <div
      className="relative flex flex-col w-full overflow-auto no-scrollbar"
      style={{ height: "83vh" }}
    >
      <Snackbar
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        message={message ? message : "Something went wrong"}
        severity="error"
      >
        <Alert
          onClose={handleClose}
          severity={severity ? severity : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message ? message : "Something went wrong"}
        </Alert>
      </Snackbar>
      <ReportHeader
        title="Analytics Report"
        exportCSV={exportCSV}
        exportPDF={exportPDF}
        totals={totals}
        total={reports.length || csvData?.length}
        isPivot={true}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        //setDisabled={setDisabled}
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
      />
      <div
        className="p-1 d-flex flex-column align-items-center"
        style={{ height: "70vh" }}
      >
        <div className="d-flex gap-3  self-start px-3 pb-1 pt-0">
          <div className="d-flex flex-col">
            <span className="font-semibold text-sm text-gray-700">
              Data source
            </span>
            <div className="d-flex sm:flex-row flex-col h-fit">
              <div
                className="relative flex  mt-1 flex-row items-center gap-2 divide-x-2 rounded-md cursor-grab outline outline-gray-400 sm:w-72 w-48 "
                ref={optionRef}
              >
                <Tooltip title="Select Data Source">
                  <button className="pl-2">
                    <img
                      src={Icons.Sort_Icon}
                      alt="search"
                      className="w-5 h-5 cursor-pointer search-icon"
                      onClick={() => {
                        setOptionsListOpen(!optionListOpen);
                      }}
                    />
                  </button>
                </Tooltip>
                <div className="flex border-gray-400 flex-row items-center w-full h-full py-1 gap-2 px-2 overflow-scroll flex-nowrap no-scrollbar">
                  {selectedOption === null ? (
                    <p className="m-0 text-base text-gray-400 whitespace-nowrap">
                      Select Data Source
                    </p>
                  ) : (
                    <p className="m-0 text-base  whitespace-nowrap">
                      {selectedOption?.name}
                    </p>
                  )}
                </div>
                {optionListOpen && (
                  <div
                    className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-9"
                    style={{
                      boxShadow:
                        "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {[
                      { value: 1, name: "Upload File" },
                      { value: 2, name: "Datasource" },
                      { value: 3, name: "Available Data" },
                    ].map((item, index) => (
                      <div
                        className={classNames(
                          "hover:bg-primarylightgrey z-10 bg-white",
                          index !== 7 && "border-b"
                        )}
                      >
                        <div
                          className="px-2 py-2"
                          onClick={() => {
                            setSelectedOption(item);
                          }}
                        >
                          {item?.name}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`d-flex flex-col ${
              selectedOption && selectedOption.value === 3
                ? ""
                : "disabled-dropdown"
            }`}
          >
            <span className="font-semibold text-sm text-gray-700">
              Data Elements
            </span>
            <div className={`d-flex sm:flex-row flex-col h-fit `}>
              <div
                className="relative flex  mt-1 flex-row items-center gap-2 divide-x-2 rounded-md cursor-grab outline outline-gray-400 sm:w-72 w-48"
                ref={reportRef}
              >
                <Tooltip title="Select Types of Data">
                  <button className="pl-2">
                    <img
                      src={Icons.Sort_Icon}
                      alt="search"
                      className="w-5 h-5 cursor-pointer search-icon"
                      onClick={() => {
                        setReportListOpen(!reportListOpen);
                      }}
                    />
                  </button>
                </Tooltip>
                <div className="flex flex-row items-center w-full h-full py-1 gap-2 px-2 overflow-scroll flex-nowrap no-scrollbar border-gray-400 ">
                  {selectedReport === null ? (
                    <p className="m-0 text-base text-gray-400 whitespace-nowrap">
                      Select Types of Data
                    </p>
                  ) : (
                    <p className="m-0 text-base  whitespace-nowrap">
                      {selectedReport?.name}
                    </p>
                  )}
                </div>
                {reportListOpen && (
                  <div
                    className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-9"
                    style={{
                      boxShadow:
                        "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    {[
                      { value: 1, name: "Patient Visit Data" },
                      { value: 2, name: "Provider Activity Data" },
                      { value: 3, name: "Revenue Data" },
                      { value: 4, name: "Appointment Cancellation Data" },
                    ].map((item, index) => (
                      <div
                        className={classNames(
                          "hover:bg-primarylightgrey z-10 bg-white",
                          index !== 7 && "border-b"
                        )}
                      >
                        <div
                          className="px-2 py-2"
                          onClick={() => {
                            setSelectedReport(item);
                          }}
                        >
                          {item?.name}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="h-screen overflow-auto mt-2 w-full"
          style={{ outline: "none" }}
        >
          <PivotTable
            incomingData={reports}
            processApiData={processApiData}
            isPatientReport={true}
            id="patientVisitReport"
            csvData={csvData}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        {isUploadCsv && (
          <UploadCSVModal
            setIsUploadCsv={setIsUploadCsv}
            isUploadCsv={isUploadCsv}
            handleFileChange={handleChange}
            tmpName={tmpName}
            setTmpName={setTmpName}
            description={description}
            setDescription={setDescription}
            selectedTmp={selectedTmp}
            setselectedTmp={setselectedTmp}
            templates={templates}
          />
        )}
      </div>
    </div>
  );
}

export default index;
