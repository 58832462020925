import { FileUploader } from 'react-drag-drop-files'
import { Icons } from '../../../../assets';
import React from 'react'
import ReusableModal from '../../../../components/Modals/ReusableModal'
import ReusableReportModal from '../../ReportBuilder/components/ReusableReportModal';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import classNames from 'classnames';

function UploadCSVModal({
    setIsUploadCsv,
    isUploadCsv,
    handleFileChange,
    tmpName,
    setTmpName,
    description,
    setDescription,
    selectedTmp,
    setselectedTmp,
    templates,
    isReportBuilder,
    availableTemplates,
    handleUpsertTemplate,
    setAvailableTemplates,
    isCreateNewTemplate,
    setIsCreateNewTemplate
}) {
    const fileTypes = ["csv", "xlsx", "xls"];
    const [name, setName] = React.useState("")
    // const [description, setDescription] = React.useState("")
    // const templates = ['abc', 'mno', 'pqr', 'xyz'];
    //  const templates = [];
    // const [selectedTmp, setselectedTmp] = React.useState("")

    const [file, setFile] = React.useState("");
    const [isCreateNew, setIsCreateNew] = React.useState(false);
    const [isView, setIsview] = React.useState(false)
    const [viewableTemplate, setViewableTemplate] = React.useState(null);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState('');


    const handleSave = () => {
        handleFileChange(file)
        setIsUploadCsv(!isUploadCsv)
    }
    const isButtonDisabled = () => {
        if (isReportBuilder) {
            return !file
        } else {
            if (templates.length > 0 && !isCreateNew) {
                return !file || !selectedTmp || selectedTmp == -1
            } else {
                return !tmpName || !file || !description || errorMessage
            }
        }
    }
    const handleNameChange = (e) => {
        const name = e.target.value;
        if (/\s/.test(name)) {
            setErrorMessage('Template name cannot contain spaces.');
        } else {
            setErrorMessage('');
        }
        setTmpName(name);
    };

    const handleCheckTemplates = async () => {
        handleFileChange(file)
    }
    return (
        <>
            {
                isView && <ReusableReportModal
                    open
                    onClose={() => setIsview(!isView)}
                    title={"Template Data"}>
                    <div className='max-w-4xl' style={{ minWidth: '45rem' }}>
                        <p className="m-0 mt-2 mb-2 font-bold whitespace-nowrap px-2 text-[15px] text-gray-500 text-center">{viewableTemplate?.name}</p>
                        <div className='max-h-96 overflow-y-auto mt-1'>
                            <table className='w-full'>
                                {viewableTemplate?.data.length > 0 ?
                                    <>
                                        <thead className='bg-slate-200'>
                                            <tr className='text-center text-sm text-[#0a3a67]'>
                                                {
                                                    Object.keys(viewableTemplate?.data[0])?.map((item) => (
                                                        <th className='px-3 py-1'>{item}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {viewableTemplate?.data?.length > 0 &&
                                                viewableTemplate.data.map((item, index) => (
                                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-slate-100' : 'bg-slate-200'}`}>
                                                        {Object.keys(item).map((key, subIndex) => (
                                                            <td key={subIndex} className='px-3 py-1 text-sm'>{item[key]}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                        </tbody></> :
                                    <tbody className='w-full  text-gray-400 font-bold text-lg'>
                                        <div className='px-4 py-4'>No Data Available</div>
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </ReusableReportModal>
            }
            {isReportBuilder ?
                (<ReusableReportModal
                    open
                    onClose={() => {
                        setAvailableTemplates([]);
                        setIsUploadCsv(!isUploadCsv);
                    }}
                    title={"Upload Data"}
                >
                    <div>
                        {availableTemplates?.length <= 0 &&
                            <>
                                {/* Render Report Builder-specific content */}
                                <div className="mx-4 my-3 flex justify-center items-center grow border-2 border-dashed border-gray-500 rounded" style={{
                                    minHeight: '12rem',
                                    minWidth: '26rem'
                                }}>
                                    <div className='flex flex-col'>
                                        <div className='flex justify-center'>
                                            <label className="inline-flex text-sm items-center space-x-2 px-3 py-2 font-bold text-white bg-[#105597] rounded-md cursor-pointer">
                                                <UploadFileIcon />
                                                <span>Select File</span>
                                                <input type="file" accept=".csv, .xls, .xlsx" className="hidden" onChange={(event) => setFile(event?.target?.files[0])} />
                                            </label>
                                        </div>
                                        <span className='mt-2 min-h-5 text-sm font-semibold self-center'>{file?.name}</span>
                                        <p className="m-0 text-xs pt-2 text-primarytextgrey">
                                            CSV, XLS or XLSX Max size of 3MB
                                        </p>
                                    </div>
                                </div>
                                {/* Additional Report Builder-specific UI */}
                                <div className="flex flex-col items-start justify-between gap-2 p-3">
                                    <div className="flex flex-row items-center justify-end w-full gap-4">
                                        <button
                                            disabled={isButtonDisabled()}
                                            onClick={handleCheckTemplates}
                                            className="px-3 py-2 font-bold text-sm text-white rounded-md btn-bgcolor disabled:opacity-40 disabled:cursor-not-allowed"
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                        {availableTemplates?.length > 0 &&
                            <>
                                <p className="m-0 mt-2 font-bold whitespace-nowrap px-3 text-[15px]">Available Templates:</p>
                                <div className='max-h-72  overflow-x-auto overflow-y-auto pt-2 px-2' style={{ width: '40rem ' }}>
                                    {availableTemplates?.map((item) => {
                                        return (
                                            <>
                                                <div className={`d-flex justify-between  ${selectedTemplate?.id === item.id ? 'bg-blue-200' : 'bg-gray-200 '} font-medium text-sm mt-1 px-2 py-2 cursor-pointer text-gray-700`} onClick={() => setSelectedTemplate(item)} >
                                                    <div>
                                                        {item.name}
                                                    </div>
                                                    <button className="px-2 py-1 font-bold text-sm text-white bg-[#105597] rounded-md" onClick={() => {
                                                        setViewableTemplate(item);
                                                        setIsview(true)
                                                    }}>view</button>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                                <div className='px-2 py-4 d-flex '>
                                    <button
                                        onClick={() => {
                                            setIsCreateNewTemplate(true)
                                        }}
                                        className={`px-3 py-2 font-bold text-white text-sm rounded-md ${!isCreateNew ? 'btn-bgcolor' : 'bg-gray-600'} disabled:opacity-40 disabled:cursor-not-allowed`}
                                    >
                                        Create New
                                    </button>
                                    <button
                                        onClick={() => {
                                            handleUpsertTemplate({ template: selectedTemplate, isCreate: false, isChild: false })
                                        }}
                                        className={`px-3 ml-2 py-2 font-bold text-white rounded-md  btn-bgcolor text-sm disabled:opacity-40 disabled:cursor-not-allowed 
                                                    ${selectedTemplate ? 'opacity-100' : 'opacity-60'}`}
                                    >
                                        Select
                                    </button>
                                </div>
                            </>}
                    </div>

                </ReusableReportModal>)
                :
                (<ReusableModal
                    open
                    onClose={() => {
                        isReportBuilder === true ? setAvailableTemplates([]) : '';
                        setIsUploadCsv(!isUploadCsv)
                    }}
                    title={"Upload Data"}
                >
                    {isReportBuilder === true ?
                        <div>
                            {availableTemplates?.length <= 0 &&
                                <>
                                    <div className="mx-4 my-3  flex justify-center items-center grow border-2 border-dashed border-gray-500 rounded" style={{
                                        minHeight: '12rem',
                                        minWidth: '26rem'
                                    }}>
                                        <div className='flex flex-col'>
                                            <div className='flex justify-center'>
                                                <label className="inline-flex items-center space-x-2 px-4 py-2 font-bold text-white bg-primaryblue rounded-md cursor-pointer">
                                                    <UploadFileIcon />
                                                    <span>Select File</span>
                                                    <input type="file" accept=".csv, .xls, .xlsx" className="hidden" onChange={(event) => { console.log(event?.target?.files[0]); setFile(event?.target?.files[0]) }} />
                                                </label>
                                            </div>
                                            <span className='mt-2 min-h-5 text-sm'>{file?.name}</span>
                                            <p className="m-0 text-xs text-primarytextgrey">
                                                {" "}
                                                CSV, XLS or XLSX Max size of 3MB
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-start justify-between gap-2 p-3">
                                        <div className="flex flex-row items-center justify-end w-full gap-4">
                                            <button
                                                disabled={isButtonDisabled()}
                                                onClick={handleCheckTemplates}
                                                className="px-4 py-2 font-bold text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
                                            >
                                                Upload
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                            {availableTemplates?.length > 0 &&
                                <>
                                    <p className="m-0 mt-1 font-bold whitespace-nowrap px-2">Available Templates:</p>
                                    <div className='max-h-72  overflow-x-auto overflow-y-auto pt-2 px-2' style={{ width: '40rem ' }}>
                                        {availableTemplates?.map((item) => {
                                            return (
                                                <>
                                                    <div className={`d-flex justify-between  ${selectedTemplate?.id === item.id ? 'bg-blue-200' : 'bg-gray-200 '} font-medium  mt-1 px-2 py-2 cursor-pointer text-gray-700`} onClick={() => setSelectedTemplate(item)} >
                                                        <div>
                                                            {item.name}
                                                        </div>
                                                        <button className="px-2 py-1 font-bold text-white bg-primaryblue rounded-md" onClick={() => {
                                                            setViewableTemplate(item);
                                                            setIsview(true)
                                                        }}>view</button>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                    <div className='px-2 py-4 d-flex '>
                                        <button
                                            onClick={() => {
                                                setIsCreateNewTemplate(true)
                                            }}
                                            className={`px-4 py-2 font-bold text-white rounded-md ${!isCreateNew ? 'bg-primaryblue' : 'bg-gray-600'} disabled:opacity-40 disabled:cursor-not-allowed`}
                                        >
                                            Create New
                                        </button>
                                        <button
                                            onClick={() => {
                                                handleUpsertTemplate({ template: selectedTemplate, isCreate: false, isChild: false })
                                            }}
                                            className={`px-4 ml-2 py-2 font-bold text-white rounded-md  bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed 
                                                    ${selectedTemplate ? 'opacity-100' : 'opacity-60'}`}
                                        >
                                            Select
                                        </button>
                                    </div>
                                </>}
                        </div>
                        :
                        <>
                            {templates.length > 0 ? <div className="flex flex-col items-start justify-between gap-2 px-3 py-2 pt-3">
                                <div className="flex flex-row items-center justify-center w-full gap-4">
                                    <button
                                        onClick={() => setIsCreateNew(false)}
                                        className={`px-4 py-2 font-bold text-white ${isCreateNew ? 'bg-primaryblue' : 'bg-gray-600'} rounded-md`}
                                    >
                                        Use Existing
                                    </button>
                                    <button
                                        // disabled={isButtonDisabled()}
                                        onClick={() => {
                                            setIsCreateNew(true)
                                            setselectedTmp(null)
                                        }}
                                        className={`px-4 py-2 font-bold text-white rounded-md ${!isCreateNew ? 'bg-primaryblue' : 'bg-gray-600'} disabled:opacity-40 disabled:cursor-not-allowed`}
                                    >
                                        Create New
                                    </button>
                                </div>
                            </div> : ""}
                            {isCreateNew || templates.length <= 0 ?
                                <>
                                    <div className="flex flex-row items-center mt-1">
                                        <div className="flex flex-col items-start justify-start w-full gap-1 py-1 px-4">
                                            <p className="m-0 font-bold whitespace-nowrap">Name</p>
                                            <input
                                                type="text"
                                                className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                                value={tmpName}
                                                onChange={handleNameChange}
                                            />
                                            <p className={`text-red-500 text-sm m-0 ${errorMessage ? 'opacity-100' : 'opacity-0'}`}>{errorMessage}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-col items-start justify-start w-full gap-1 py-1 px-4">
                                            <p className="m-0 font-bold whitespace-nowrap">Description</p>
                                            <input
                                                type="text"
                                                className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full  focus:ring-blue-500 focus:border-blue-500"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                        </div>
                                    </div> </> : ""}
                            {!isCreateNew && templates.length > 0 ?
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-col items-start justify-start w-full gap-2 p-1 px-4 mt-1">
                                        <p className="m-0 font-bold">Select Uploads</p>
                                        <select
                                            value={JSON.stringify(selectedTmp)}
                                            onChange={(e) => {
                                                console.log(e.target.value)
                                                setselectedTmp(JSON.parse(e.target.value));
                                            }}
                                            className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full focus:ring-blue-500 focus:border-blue-500"
                                        >
                                            <option value={-1}>Select Upload</option>
                                            {templates &&
                                                templates.map((item, index) => (
                                                    <option key={index} value={JSON.stringify({ id: item.id, name: item.name })}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </select>

                                    </div>
                                </div> : ""}
                            <div className="mx-4 my-3  flex justify-center items-center grow min-h-40 min-w-80 border-2 border-dashed border-gray-500 rounded">
                                {/* <FileUploader
                            handleChange={(file) => setFile(file)}
                            name="file"
                            types={fileTypes}
                            classes="w-100"
                        //disabled={!canUpload || user?.showTestData}
                        >
                            <div className="flex flex-col h-full w-full justify-center items-center gap-2 border-[1px] border-dashed border-secondarygrayborder rounded-lg p-4">
                                <img
                                    src={Icons.Upload_Icon}
                                    alt="upload icon"
                                    className="mx-2 icon"
                                />
                                <p className="m-0 mt-2 text-sm">
                                    Drag & Drop or{" "}
                                    <span
                                        className={classNames(
                                            "text-primaryblue"
                                        )}
                                    >
                                        {file ? file?.name : "Choose file"}
                                    </span>{" "}
                                    to upload
                                </p>
                                <p className="m-0 text-xs text-primarytextgrey">
                                    {" "}
                                    CSV, XLS or XLSX Max size of 3MB
                                </p>
                            </div>
                        </FileUploader> */}
                                <div className='flex flex-col'>
                                    <div className='flex justify-center'>
                                        <label className="inline-flex items-center space-x-2 px-4 py-2 font-bold text-white bg-primaryblue rounded-md cursor-pointer">
                                            <UploadFileIcon />
                                            <span>Select File</span>
                                            <input type="file" accept=".csv, .xls, .xlsx" className="hidden" onChange={(event) => { console.log(event?.target?.files[0]); setFile(event?.target?.files[0]) }} />
                                        </label>
                                    </div>
                                    <span className='mt-2 min-h-5 text-sm'>{file?.name}</span>
                                    <p className="m-0 text-xs text-primarytextgrey">
                                        {" "}
                                        CSV, XLS or XLSX Max size of 3MB
                                    </p>
                                </div>

                            </div>
                            <div className="flex flex-col items-start justify-between gap-2 p-3">
                                <div className="flex flex-row items-center justify-end w-full gap-4">
                                    <button
                                        onClick={() => setIsUploadCsv(!isUploadCsv)}
                                        className="px-4 py-2 font-bold text-white bg-gray-600 rounded-md"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={isButtonDisabled()}
                                        onClick={handleSave}
                                        className="px-4 py-2 font-bold text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                </ReusableModal >)}

        </>
    )
}
export default UploadCSVModal
