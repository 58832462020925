import * as React from "react";

import PropTypes from "prop-types";
import Tooltip from '@mui/material/Tooltip';

const AddColumnIcon = (props) => {
    const { width, height, tooltipText, fillColors } = props;
    return (

        <Tooltip title={tooltipText}>
            <svg
                width={width}
                height={height}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"

            >
                <path fill={fillColors[0] || "#c5c8cb"} d="M30,5H18c-2.2,0-4,1.8-4,4v30c0,2.2,1.8,4,4,4h12c2.2,0,4-1.8,4-4V9C34,6.8,32.2,5,30,5z M18,39V9h12l0,30 H18z" />
                <circle fill={fillColors[1] || "#c5c8cb"} cx="38" cy="38" r="10" />
                <g fill={fillColors[2] || "#ffffff"}>
                    <rect x="36" y="32" width="4" height="12" />
                    <rect x="32" y="36" width="12" height="4" />
                </g>
            </svg>

        </Tooltip>
    );
}
AddColumnIcon.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    tooltipText: PropTypes.string.isRequired,
    fillColors: PropTypes.arrayOf(PropTypes.string), // Prop for colors
};

AddColumnIcon.defaultProps = {
    fillColors: ["#c5c8cb", "#c5c8cb", "#ffffff"], // Default colors
};
export default AddColumnIcon;

