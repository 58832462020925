import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

import ProvidersScreen from "./ProvidersScreen";
import SchedulingSettingsHeader from "../../components/SchedulingSettingsHeader";
import { selectUser } from "../../../../features/auth/authSlice";
import { useGetProvidersMutation } from "../../../../features/organisation/organisationApiSlice";
import { useSchedulerStore } from "../../../../context/scheduler.store";
import { useSelector } from "react-redux";

const ProvidersConfig = () => {
  const [opaque, setOpaque] = useState(false);
  const user = useSelector(selectUser);
  const providers = useSchedulerStore((state) => state.providers);
  const setProviders = useSchedulerStore((state) => state.setProviders);

  const [getProviders] = useGetProvidersMutation();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("Something went wrong");
  const [severity, setSeverity] = React.useState("error");

  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(user.organisation.id).unwrap();

      setProviders(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };
  const showAlert = (msg, severity) => {
    setOpen(true);
    setMessage(msg);
    setSeverity(severity);
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  return (
    <div
      // className="relative flex flex-col w-full h-screen"
      className="relative flex flex-col w-full overflow-hidden"
    >

      <SchedulingSettingsHeader title={"Providers"} />
      <ProvidersScreen
        setOpaque={setOpaque}
        providers={providers}
        setProviders={setProviders}
        showAlert={showAlert}
      />
      <Snackbar
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={5000}
        message={message ? message : "Something went wrong"}
        severity="error"
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity ? severity : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message ? message : "Something went wrong"}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ProvidersConfig;
