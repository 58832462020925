import { FilterList } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import React from "react";
import CheckboxSelector from "../../screens/Schedules/components/CheckboxSelector";
import CloseSvg from "../../assets/svgs/CloseSvg";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const FilterSelect = ({ value, handleChange, data, placeholder, isFrequency }) => {
  const [specialityDropdown, setSpecialityDropdown] = React.useState(false);

  return (
    <div className="w-1/3 relative flex flex-row divide-x-[2px] divide-primarygrayborder items-center gap-2 rounded-md border-[2px] border-primarygrayborder h-10">
      <div
        className="flex items-center gap-2 overflow-x-scroll no-scrollbar"
        onClick={() => setSpecialityDropdown(!specialityDropdown)}
      >
        <div className="ml-2 min-w-fit">
          <Tooltip title="Filter">
            <button
              className={classNames(
                "rounded-full w-7 h-7",
                value.length > 0 ? "bg-primaryblue" : "bg-primarygrayborder"
              )}
            >
              <FilterList
                style={{
                  color: "#FFF",
                  fontSize: "20px",
                }}
              />
            </button>
          </Tooltip>
        </div>
        <div className="flex flex-row items-center h-full gap-2 px-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
          {value.length === 0 && (
            <p className="m-0 text-base text-primarygrayborder whitespace-nowrap px-2 py-[2px]">
              {placeholder}
            </p>
          )}
          {value.map((speciality, index) => (
            <div
              key={index}
              className="flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primaryblue"
            >
              <p aria-multiline={false} className="m-0 whitespace-nowrap">
                {isFrequency ? data?.find(item => item?.value == speciality)?.name : speciality}
              </p>
              <Tooltip title="Remove">
                <button
                  onClick={() => handleChange(speciality)}
                  className="flex items-center justify-center scale-90 border border-white rounded-full aspect-square"
                >
                  <CloseSvg color={"#FFF"} />
                </button>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
      {specialityDropdown && (
        <ClickAwayListener onClickAway={() => setSpecialityDropdown(false)}>
          <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
            {data.map((speciality, index) => (
              <div
                className={classNames(
                  "hover:bg-primarylightgrey",
                  index !== data.length - 1 && "border-b"
                )}
              >
                <CheckboxSelector
                  key={index}
                  header={isFrequency ? speciality?.name : speciality}
                  isChecked={value.indexOf(isFrequency ? speciality?.value : speciality) !== -1}
                  handleChange={(_e) => handleChange(isFrequency ? speciality?.value : speciality)}
                  isBold={false}
                />
              </div>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default FilterSelect;
