import { ArrowDownwardTwoTone, ArrowUpwardTwoTone } from "@mui/icons-material";
import {
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetAllRolesMutation,
} from "../../features/role/roleApiSlice";

import RoleModal from "./components/RoleModal";
import FilterSelect from "../../components/Filter/FilterSelect";
import IconComponent from "../../components/DataTable/Icon";
import Loading from "../../components/Loading";
import React from "react";
import Search from "../../components/Search/SearchNew";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useGetAllPermissionsMutation } from "../../features/permission/permissionApiSlice";
import { usePermissionsStore } from "../../context/permissions.store";
const Roles = () => {
  const { permissions: userPermissions } = usePermissionsStore();
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = React.useState(null);
  const [roleModalOpen, setRoleModalOpen] = React.useState(false);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [statuses, setStatuses] = React.useState([]);

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const [getRoles, { isLoading }] = useGetAllRolesMutation();
  const [getPermissions, { isLoading: permissionsLoading }] =
    useGetAllPermissionsMutation();

  const [edit, { isLoading: editLoading }] = useEditRoleMutation();

  const [create, { isLoading: createLoading }] = useCreateRoleMutation();

  const loading = editLoading || createLoading || isLoading;

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const [roles, setRoles] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);

  const handleRoleClick = (Role) => {
    // navigate(`/Roles/${Role.id}`);
  };

  const fetchRoles = async () => {
    try {
      const { data } = await getRoles().unwrap();

      setRoles(data);

      const statuses = Array.from(new Set(data.map((Role) => Role.status)));

      setStatuses(statuses);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const fetchPermissions = async () => {
    try {
      const { data } = await getPermissions().unwrap();

      setPermissions(
        data?.map((permission) => ({
          value: permission.id,
          label: permission.action,
        })) ?? []
      );
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    const statuses = Array.from(new Set(roles.map((role) => role.status)));

    setStatuses(statuses);
  }, [roles]);

  React.useEffect(() => {
    fetchRoles();
    fetchPermissions();
  }, []);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <Loading loading={loading} />
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Roles{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {
                roles
                  .filter((role) =>
                    role.name.toLowerCase().includes(searchFilter.toLowerCase())
                  )
                  .filter(
                    (role) =>
                      statusFilter.length === 0 ||
                      statusFilter.indexOf(role.status) !== -1
                  )?.length
              }
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
            <FilterSelect
              value={statusFilter}
              handleChange={handleStatusFilter}
              data={statuses}
              placeholder="Filter by Status ..."
            />
          </div>
        </div>
        {userPermissions?.permissions?.includes("create_role") && (
          <button
            onClick={() => {
              setRoleModalOpen(true);
            }}
            className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
          >
            Create Role
          </button>
        )}
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortNameOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Permissions</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortStatusOrder === "none") {
                          setSortStatusOrder("asc");
                        }
                        if (sortStatusOrder === "asc") {
                          setSortStatusOrder("desc");
                        }
                        if (sortStatusOrder === "desc") {
                          setSortStatusOrder("none");
                        }
                      }}
                    >
                      {sortStatusOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortStatusOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                {userPermissions?.permissions?.includes("edit_role") && (
                  <th className="px-4 py-3">Edit</th>
                )}
              </tr>
            </thead>
            <tbody>
              {roles
                .filter((role) =>
                  role.name.toLowerCase().includes(searchFilter.toLowerCase())
                )
                .filter(
                  (role) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(role.status) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((role, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== roles.length - 1 ? "border-b" : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <div className="flex flex-row items-center gap-4">
                        <button onClick={() => handleRoleClick(role)}>
                          <p className="m-0 font-semibold underline">
                            {role.name}
                          </p>
                        </button>
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <div
                        className={classNames(
                          "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey text-wrap",
                          "rounded-md text-left flex flex-row gap-1 items-center px-2 w-fit line-clamp-2"
                        )}
                      >
                        {/* <div
                          className={classNames(
                            "w-2 h-2 rounded-full",
                            "bg-primarytextgrey"
                          )}
                        /> */}
                        {role?.permissions?.join(", ") ?? "NA"}
                      </div>
                    </td>
                    {userPermissions?.permissions?.includes("edit_role") && (
                      <td className="px-4 py-3 text-left">
                        <button
                          onClick={() => {
                            setSelectedRole(role);
                            setRoleModalOpen(true);
                          }}
                          className="text-white rounded-md"
                        >
                          <IconComponent
                            tooltip="Edit Role"
                            icon="Edit_Icon"
                            i={role.id}
                            key={role.id}
                          />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {roleModalOpen && (
        <RoleModal
          type={selectedRole ? "update" : "create"}
          open={roleModalOpen}
          setOpen={setRoleModalOpen}
          data={selectedRole}
          setData={setSelectedRole}
          edit={edit}
          create={create}
          permissions={permissions}
          fetchRoles={fetchRoles}
        />
      )}
    </div>
  );
};

export default Roles;
