import 'ckeditor5/ckeditor5.css';

import { Alignment, Bold, ClassicEditor, Essentials, Font, Heading, ImageInsert, Indent, Italic, List, Paragraph, TodoList, Underline } from 'ckeditor5';
import React, { useEffect, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";

const TextboxComponent = ({
    stopTableDrag,
    properties,
    onPropertyChange,
    setSelectedComponent,
    selectedComId,
    component,
    id
}) => {
    const [editorData, setEditorData] = useState("");
    const [editorInstance, setEditorInstance] = useState(null);
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        // setEditorData(data);
        setSelectedComponent({
            ...component,
            properties: {
                ...component.properties,
                editorData: data
            }
        });
        onPropertyChange({ editorData: data })
    };

    // const adjustToolbarPosition = (editor) => {
    //     const toolbarElement = editor.ui.view.toolbar.element;
    // const topPosition = component?.properties?.top; // Get the top position of the textbox
    // const textboxHeight = component?.properties?.height || 0; // Get the height of the textbox

    // if (topPosition <= 50) {
    //     toolbarElement.style.position = 'absolute';
    //     toolbarElement.style.top = `${topPosition + textboxHeight + 10}px`; 
    // } else {
    //     toolbarElement.style.position = 'absolute';
    //     toolbarElement.style.top = '-100px';
    // }
    // };


    const GRID_CELL_HEIGHT = 50;

    const adjustToolbarPosition = (editor) => {
        if (!editor) return;

        const toolbarElement = editor.ui.view.toolbar.element;
        const topPosition = (component?.properties?.layouts?.y || 0) * GRID_CELL_HEIGHT;
        const textboxHeight = (component?.properties?.layouts?.h || 0) * GRID_CELL_HEIGHT;

        toolbarElement.style.position = 'absolute';
        toolbarElement.style.top = topPosition <= 50
            ? `${topPosition + textboxHeight + 10}px`
            : '-100px';
    };

    useEffect(() => {
        if (editorInstance && component) {
            adjustToolbarPosition(editorInstance);
        }
    }, [component, editorInstance]);


    // const adjustToolbarPosition = (editor) => {
    //     if (!editor) return;

    //     const toolbarElement = editor.ui.view.toolbar.element;
    //     const topPosition = component?.properties?.top || 0; 
    //     const textboxHeight = component?.properties?.height || 0;

    //     toolbarElement.style.position = 'absolute';
    //     toolbarElement.style.top = topPosition <= 50 
    //         ? `${topPosition + textboxHeight + 10}px` 
    //         : '-100px';
    // };

    // // Update toolbar position dynamically when top/height changes
    // useEffect(() => {
    //     if (editorInstance) {
    //         adjustToolbarPosition(editorInstance);
    //     }
    // }, [component?.properties?.top, component?.properties?.height, editorInstance]);
    return (
        <div
            // className="textbox-component"
            style={{
                height: "100%",
                width: "100%",
                position: 'relative',
            }}
            onMouseMove={() => stopTableDrag()}
            className={`textbox-component ${selectedComId == id ? "cursor-text" : "cursor-pointer"}`}
        >
            <div
                className={`report-builder-textBox ${selectedComId == id ? "" : "hidden-toolbar"} `}
                style={{

                    height: "100%",
                    width: "100%",
                    resize: "none",
                    boxSizing: "border-box",
                    border: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                    backgroundColor: properties?.backgroundColor || "#ffffff",
                    borderRadius:'3px'
                    // overflow: 'auto'
                }}
            >
                <CKEditor
                    editor={ClassicEditor}
                    data={properties?.editorData}
                    config={{
                        licenseKey: 'GPL',
                        plugins: [Essentials, Paragraph, Bold, Italic, Font, List, TodoList, Indent, Alignment, Heading, Underline],
                        // toolbar: ['undo', 'redo', '|', 'bold', 'italic', '|', 'fontSize', 'fontFamily', '|', 'fontColor', 'fontBackgroundColor', '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent', '|', 'alignment', '|', 'heading', '|', 'insertImage'],
                        toolbar: {
                            items: [
                                'undo', 'redo',
                                '|', 'bold', 'italic', 'underline',
                                '|', 'fontSize', 'fontFamily',
                                '|', 'fontColor', 'fontBackgroundColor',
                                '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                                'alignment',
                                '|', 'heading',
                                // '|', 'insertImage'
                            ],
                            shouldNotGroupWhenFull: true
                        },
                        fontFamily: {
                            options: [
                                "Inter",
                                'Arial, sans-serif',
                                "Times New Roman",
                                "Times",
                                'Tahoma, sans-serif',
                                "Courier New",
                                "Courier",
                                "Verdana",
                                "Georgia",
                                "Palatino",
                                "Garamond",
                                "Bookman",
                                "Trebuchet MS",
                                "Comic Sans MS",
                                "Impac",
                            ],
                            supportAllValues: true
                        },
                        placeholder: "Type here ..."
                    }}

                    onChange={handleEditorChange}
                    // onReady={editor => {
                    //     // Adjust the toolbar position when the editor is ready
                    //     adjustToolbarPosition(editor);
                    // }}
                    onReady={(editor) => {
                        setEditorInstance(editor); // Store editor instance
                        adjustToolbarPosition(editor); // Adjust toolbar initially
                    }}
                />
            </div>


        </div>

    );
};

export default TextboxComponent;


