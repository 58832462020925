import React from "react";

const Content = ({ header, content, note, onLinkClick, link }) => {
  return (
    <div className="mx-4">
      <h2 className="font-bold sm:text-2xl text-xl items-center">
        {header}
        {link && (
          <span
            role="button"
            className="text-primaryblue text-decoration-underline text-sm ml-3"
            onClick={onLinkClick}
          >
            {link}
          </span>
        )}
      </h2>
      <p className="my-2 text-md">{content}</p>
      {note && (
        <p className="my-3 text-md">
          <span className="font-semibold">Note:</span> {note}
        </p>
      )}
    </div>
  );
};

export default Content;
