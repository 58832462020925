import React, { useEffect } from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";
import CloseSvg from "../../../../assets/svgs/CloseSvg";
import { useLSStore } from "../../../../context/ls-store";
import Papa from "papaparse"; // For parsing CSV
import * as XLSX from "xlsx"; // For parsing XLS/XLSX
import axios from "axios";
import { useSelector } from "react-redux";
import { selectToken } from "../../../../features/auth/authSlice";

const ZoneModal = ({
  type = "update",
  open,
  setOpen,
  data = null,
  setData,
  zones,
  setZones,
  create,
  edit,
}) => {
  const handleClose = () => {
    setName("");
    setZipCode("");
    setOpen(false);
    setData(null);
  };

  const [name, setName] = React.useState(data !== null ? data.name : "");
  const [zipCode, setZipCode] = React.useState(
    data !== null ? data.zipCodes : ""
  );
  const [zipCodes, setZipCodes] = React.useState([]);

  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    if (open && data !== null) {
      setName(data.name);
      setZipCode(data.zipCodes);
    }
  }, [open, data]);

  // Validate zip code (assuming 5-digit US ZIP codes)
  const validateZipCode = (zip) => {
    const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/; // Adjust if needed (e.g., for ZIP+4)
    return zipCodeRegex.test(zip);
  };

  // Handle file upload for both CSV and Excel formats
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase(); // Get file extension

    if (fileExtension === "csv") {
      // Parse CSV file using PapaParse
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          processZipCodes(result.data);
        },
      });
    } else if (fileExtension === "xlsx" || fileExtension === "xls") {
      // Parse Excel file using XLSX
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        processZipCodes(worksheet);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Please upload a valid CSV or Excel file.");
    }
  };

  // Process and validate zip codes
  const processZipCodes = (rows) => {
    const validZips = [];
    const invalidZips = [];
    rows.forEach((row) => {
      const zip = row.zip_code ? row.zip_code.trim() : ""; // Trim spaces and handle missing data

      if (validateZipCode(zip)) {
        validZips.push(zip);
      } else {
        invalidZips.push(zip || "(missing)"); // If row is empty, handle gracefully
      }
    });

    setZipCodes(validZips);
  };

  const updateZone = async () => {
    try {
      const zipParse =
        typeof zipCode === "string"
          ? zipCode.split(",").map((zip) => zip.trim())
          : zipCode;
      const zip = [...new Set([...zipParse, ...zipCodes])];
      await edit({
        id: data.id,
        name,
        zipCodes: zip,
      }).unwrap();

      const updatedZones = zones.map((zone) => {
        if (zone.id === data.id) {
          return {
            ...zone,
            name,
            zipCodes: zip,
          };
        }
        return zone;
      });

      setZones(updatedZones);
      setZipCodes([]);

      setAlert({
        message: "Updated Zone",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to update Zone",
        severity: "error",
      });
    }
  };

  const createZone = async () => {
    try {
      const zipParse =
        typeof zipCode === "string"
          ? zipCode.split(",").map((zip) => zip.trim())
          : zipCode;
      const zip = [...new Set([...zipParse, ...zipCodes])].filter(
        (x) => x !== ""
      );
      const { data } = await create({
        name,
        zipCodes: zip,
      }).unwrap();

      const updatedZones = [
        ...zones,
        {
          id: data.id,
          name,
          zipCodes: data.zip_codes,
        },
      ];

      setZones(updatedZones);
      setZipCodes([]);

      setAlert({
        message: "Created Zone",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to create Zone",
        severity: "error",
      });
    }
  };

  const token = useSelector(selectToken);

  const downloadCSV = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/zone/get/template`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "zipcodes-template.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <ReusableModal
      open={open}
      title={`${type === "update" ? "Update" : "Create"} Zone`}
      onClose={handleClose}
    >
      <div className="flex flex-col w-full gap-4 p-6">
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="Name" className="m-0 text-base font-semibold">
            Name
          </label>
          <input
            type="text"
            id="Name"
            name="Name"
            placeholder="Enter Name"
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-row items-center justify-between w-full mb-2 md:mb-0">
            <label htmlFor="zipCodes" className="m-0 text-base font-semibold">
              Zip codes
            </label>
            <p
              className="m-0 text-sm underline text-primaryblue"
              onClick={downloadCSV}
              role="button"
            >
              Zip codes Data Template
            </p>
          </div>
          <div>
            <input
              type="file"
              accept=".csv, .xlsx, .xls"
              onChange={handleFileUpload}
              className="ml-24"
            />
          </div>
          <span className="flex self-center">OR</span>
          <textarea
            id="zipCodes"
            name="zipCodes"
            placeholder="Enter zip codes separated by comma"
            rows={5}
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none text-center"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </div>
        <div className="flex flex-row items-center justify-between w-full gap-6">
          <button
            onClick={handleClose}
            className="w-1/2 p-2 font-semibold rounded-md  border-[2px] text-primaryblue border-primaryblue"
          >
            Cancel
          </button>
          <button
            disabled={name === "" || (zipCode === "" && zipCodes.length === 0)}
            onClick={async () => {
              handleClose();
              if (type === "update") {
                await updateZone();
              } else {
                await createZone();
              }
            }}
            className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-primaryblue border-primaryblue text-white whitespace-nowrap"
          >
            {type === "update" ? "Yes, Update" : "Create"}
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default ZoneModal;
