import classNames from "classnames";
import React from "react";

const CheckboxSelector = ({
  isChecked,
  handleChange,
  key,
  header,
  textSize = "text-base",
  isBold = true,
}) => {
  return (
    <th
      key={key}
      className={classNames("px-4 py-3 cursor-pointer " + textSize)}
      onChange={(e) => {
        handleChange(e);
      }}
    >
      <label className="flex flex-row items-center gap-3 cursor-pointer">
        <input
          type="checkbox"
          className="cursor-pointer form-checkbox"
          checked={isChecked}
        />
        <p
          className={classNames(
            "m-0 cursor-pointer text-base " + textSize,
            isBold ? "font-bold" : "font-normal"
          )}
        >
          {header}
        </p>
      </label>
    </th>
  );
};

export default CheckboxSelector;
