import { apiSlice } from "../../app/api/apiSlice";

export const notificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    markSeen: builder.mutation({
      query: (credentials) => ({
        url: `/notification/mark-seen/${credentials.id}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    getNotifications: builder.mutation({
      query: () => ({
        url: `/notification/`,
        method: "GET",
      }),
    }),
  }),
});
export const { useMarkSeenMutation, useGetNotificationsMutation } =
  notificationApiSlice;
