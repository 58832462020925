import React from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { avatarName } from "../../utilities/resuseFunctions";

const MemberSelectedCard = ({ profile, name, style, onRemove = () => {} }) => {
  return (
    <Grid className="align-items-center" style={style}>
      <Grid className="d-flex justify-content-center position-relative ">
        <Avatar size={"md"} src={profile}>
          {avatarName(name || "")}
        </Avatar>
        <button
          className="btn border-0"
          onClick={onRemove}
          style={{ position: "absolute", top: -10, right: 3 }}
        >
          <HighlightOffOutlinedIcon
            style={{
              fontSize: 13,
              color: "#FE0606",
            }}
          />
        </button>
      </Grid>
      <Typography
        style={{
          fontSize: 10,
          color: "rgba(0, 0, 0, 0.7)",
        }}
      >
        {name}
      </Typography>
    </Grid>
  );
};

export default MemberSelectedCard;
