import * as React from "react";
const Lock = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6002 2.99961C2.6002 1.67413 3.67471 0.599609 5.0002 0.599609C6.32568 0.599609 7.4002 1.67413 7.4002 2.99961V3.14961C7.4002 3.39814 7.19872 3.59961 6.9502 3.59961C6.70167 3.59961 6.5002 3.39814 6.5002 3.14961V2.99961C6.5002 2.17118 5.82862 1.49961 5.0002 1.49961C4.17177 1.49961 3.5002 2.17118 3.5002 2.99961V4.79961H8.1502C9.06147 4.79961 9.8002 5.53834 9.8002 6.44961V9.74961C9.8002 10.6609 9.06147 11.3996 8.1502 11.3996H1.8502C0.938926 11.3996 0.200195 10.6609 0.200195 9.74961V6.44961C0.200195 5.53834 0.938926 4.79961 1.8502 4.79961H2.6002V2.99961ZM1.8502 5.69961C1.43598 5.69961 1.1002 6.0354 1.1002 6.44961V9.74961C1.1002 10.1638 1.43598 10.4996 1.8502 10.4996H8.1502C8.56441 10.4996 8.9002 10.1638 8.9002 9.74961V6.44961C8.9002 6.0354 8.56441 5.69961 8.1502 5.69961H1.8502ZM5.0002 7.19961C5.24872 7.19961 5.4502 7.40108 5.4502 7.64961V8.54961C5.4502 8.79814 5.24872 8.99961 5.0002 8.99961C4.75167 8.99961 4.5502 8.79814 4.5502 8.54961V7.64961C4.5502 7.40108 4.75167 7.19961 5.0002 7.19961Z"
      fill={color || "#000"}
    />
  </svg>
);
export default Lock;
