import React, { useEffect } from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { useLSStore } from "../../../context/ls-store";
import { Country, State } from "country-state-city";
import Select from "react-select";

const LocationModal = ({
  type = "update",
  open,
  setOpen,
  data = null,
  setData,
  edit,
  create,
  fetchLocations,
}) => {
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [country, setCountry] = React.useState("");
  const handleClose = () => {
    setName("");
    setAddress("");
    setCity("");
    setState("");
    setZip("");
    setCountry("");
    setOpen(false);
    setData(null);
  };

  const countries = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));

  const states = State.getStatesOfCountry(country?.value ?? "").map(
    (state) => ({
      label: state.name,
      value: state.isoCode,
    })
  );

  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    if (open && data !== null) {
      setName(data.name);
      setAddress(data.address);
      setCity(data.city);
      setState(states.find((c) => c.label === data.state));
      setZip(data.zip);
      setCountry(countries.find((c) => c.label === data.country));
    }
  }, [open, data]);

  const updateLocation = async () => {
    try {
      await edit({
        locationId: data.id,
        name,
        address,
        city,
        state: state?.value,
        zip,
        country: country?.value,
      }).unwrap();

      fetchLocations();

      setAlert({
        message: "Updated Location",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to update Location",
        severity: "error",
      });
    }
  };

  const createLocation = async () => {
    try {
      const { data } = await create({
        name,
        address,
        city,
        state: state?.value,
        zip,
        country: country?.value,
      }).unwrap();

      fetchLocations();

      setAlert({
        message: "Created Location",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to create Location",
        severity: "error",
      });
    }
  };

  return (
    <ReusableModal
      open={open}
      title={`${type === "update" ? "Update" : "Create"} Location`}
      onClose={handleClose}
    >
      <div className="flex flex-col w-full gap-4 p-6 max-w-[650px] min-w-[500px] overflow-y-auto">
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="Name" className="m-0 text-base font-semibold">
            Name
          </label>
          <input
            type="text"
            id="Name"
            name="Name"
            placeholder="Enter Name"
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="Address" className="m-0 text-base font-semibold">
            Address
          </label>
          <input
            type="text"
            id="Address"
            name="Address"
            placeholder="Enter Address"
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="City" className="m-0 text-base font-semibold">
            City
          </label>
          <input
            type="text"
            id="City"
            name="City"
            placeholder="Enter City"
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="Status" className="m-0 text-base font-semibold">
            State
          </label>
          <Select
            options={states}
            value={state}
            onChange={(selected) => setState(selected)}
            className="w-full"
          />
          {/* <select value={state} onChange={(e) => setState(e.target.value)}>
            <option value="">Select State</option>
            {State.getStatesOfCountry(country ?? "").map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </select> */}
        </div>
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="Zip" className="m-0 text-base font-semibold">
            Zip
          </label>
          <input
            type="text"
            id="Zip"
            name="Zip"
            placeholder="Enter Zip"
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="Status" className="m-0 text-base font-semibold">
            Country
          </label>
          <Select
            options={countries}
            value={country}
            onChange={(selected) => setCountry(selected)}
            className="w-full"
          />
          {/* <select value={country} onChange={(e) => setCountry(e.target.value)}>
            <option value="">Select Country</option>
            {Country.getAllCountries().map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select> */}
        </div>
        <div className="flex flex-row items-center justify-between w-full gap-6">
          <button
            onClick={handleClose}
            className="w-1/2 p-2 font-semibold rounded-md  border-[2px] text-primaryblue border-primaryblue"
          >
            Cancel
          </button>
          <button
            disabled={name === ""}
            onClick={async () => {
              handleClose();
              if (type === "update") {
                await updateLocation();
              } else {
                await createLocation();
              }
            }}
            className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-primaryblue border-primaryblue text-white whitespace-nowrap"
          >
            {type === "update" ? "Yes, Update" : "Create"}
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default LocationModal;
