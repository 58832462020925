import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ReactDOM from "react-dom";
import { Tooltip } from "@mui/material";
import classNames from "classnames";

const modalRootEl = document.getElementById("modal-root");

const Header = ({ title, onClose }) => {
  return (
    <div className="px-3 py-2 rounded-t d-flex justify-content-between align-items-center bg-primaryblue">
      <span className="font-bold text-white text-md">{title}</span>
      <Tooltip title="Close">
        <button onClick={onClose}>
          <CloseIcon className="close-icon" />
        </button>
      </Tooltip>
    </div>
  );
};

const ReusableModal = ({
  children,
  open = false,
  title,
  onClose,
  containerStyle,
  containerClasses,
  boxClasses,
  hideHeader = false,
}) => {
  // const [opaque] = useState(open);
  return (
    <Modal open={open}>
      <div
        style={{ ...containerStyle, zIndex: 1201 }}
        className={classNames(
          "absolute inset-0 flex justify-center items-center h-full p-3",
          containerClasses
        )}
      >
        {/* {opaque && ( */}
        <div className="absolute inset-0 z-[998] h-svh bg-black bg-opacity-50" />
        {/* )} */}
        <div className="inset-0 flex justify-center h-full mt-2  z-[999] md:h-fit">
          <div
            className={classNames(
              "bg-white shadow-xl rounded max-h-[calc(100vh-100px)]",
              boxClasses
            )}
          >
            {!hideHeader && <Header title={title} onClose={onClose} />}
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReusableModal;

const Modal = ({ children, open = false }) => {
  if (!open) return null;

  return ReactDOM.createPortal(children, modalRootEl);
};
