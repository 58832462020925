import React from "react";
import Profile from "./Profile";
import { SUBSCRIPTION_TYPE } from "../../../constants/config.constants";

const User = ({ user, isEdit, userData, setUserData, errMsg }) => {
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-12">
      <Profile />
      <div className="flex flex-col gap-8">
        <div className="flex flex-row gap-6 items-center">
          <p className="m-0 font-bold w-16">Name</p>
          {isEdit ? (
            <input
              type="text"
              id="name"
              name="name"
              className="p-2 border-[1px] border-primarygray rounded-md outline-none"
              placeholder="Enter Name"
              value={userData.name}
              onChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
          ) : (
            <p className="m-0 text-primarytextgrey">{user.name}</p>
          )}
        </div>
        <div className="flex flex-row gap-6 items-center">
          <p className="m-0 font-bold w-16">Email</p>
          <p className="m-0 text-primarytextgrey">{user.email}</p>
        </div>
        <div className="flex flex-row gap-6 items-center">
          <p className="m-0 font-bold w-16">Phone</p>
          {isEdit ? (
            <input
              type="tel"
              id="tel"
              name="tel"
              className="p-2 border-[1px] border-primarygray rounded-md outline-none"
              placeholder="xxx-xxx-xxxx"
              value={userData.phone}
              onChange={(e) => {
                if (e.target.value.length >= 13) {
                  return;
                }
                setUserData((prev) => ({
                  ...prev,
                  phone: formatPhoneNumber(e.target.value),
                }));
              }}
            />
          ) : (
            <p className="m-0 text-primarytextgrey">
              {formatPhoneNumber(user.phone)}
            </p>
          )}
        </div>
        {errMsg && <p className="m-0 text-xs text-danger">{errMsg}</p>}
      </div>
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <div className="flex flex-row gap-6">
        <div className="flex flex-col gap-8 font-bold">
          <p className="m-0">Organization:</p>
        </div>
        <div className="flex flex-col gap-8 text-primarytextgrey">
          <p className="m-0">{user.organisation.name}</p>
        </div>
      </div>
      <div className="flex flex-row gap-6">
        <div className="flex flex-col gap-8 font-bold">
          <p className="m-0">Subscription:</p>
        </div>
        <div className="flex flex-col gap-8 text-primarytextgrey">
          <p className="m-0">
            {SUBSCRIPTION_TYPE?.[user.subscription.lookupKey]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default User;
