import { DndProvider, DragPreviewImage, useDrag, useDrop } from "react-dnd";
import React, { useCallback, useState } from "react";

import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import { Default_Table } from "../../../../../assets/images";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { HTML5Backend } from "react-dnd-html5-backend";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Search_Icon } from "../../../../../assets/images";
import StraightIcon from "@mui/icons-material/Straight";
import { Tooltip } from "@mui/material";
import moment from "moment";

const ItemType = {
  ROW: "row",
  COLUMN: "column",
};

const TableComponent = ({
  component,
  columns,
  data,
  id,
  properties,
  stopTableDrag,
  onPropertyChange,
  setSelectedComponent,
  searchQuery,
  showAlert,
  setIsSavedClicked,
  setIsCreateNewTemplate,
  setIsSaveAsClicked,
  isChartView,
  isTableView,
  selectedComId,
  isListViewReport
}) => {
  const dateformat = [
    { format: "YYYY-MM-DDTHH:mm:ss.SSSZ" },
    { format: "YYYY-MM-DDTHH:mm:ssZ" },
    { format: "D/M/YY" },
    { format: "D/M/YYYY" },
    { format: "DD-MM-YYYY" },
    { format: "MM-DD-YYYY" },
    { format: "YYYY-MM-DD" },
    { format: "DD/MM/YYYY" },
    { format: "MM/DD/YYYY" },
    { format: "YYYY/MM/DD" },
    { format: "D/M/YY HH:mm" },
    { format: "D/M/YYYY HH:mm" },
    { format: "DD-MM-YYYY HH:mm" },
    { format: "MM-DD-YYYY HH:mm" },
    { format: "YYYY-MM-DD HH:mm" },
    { format: "DD/MM/YYYY HH:mm" },
    { format: "MM/DD/YYYY HH:mm" },
    { format: "YYYY/MM/DD HH:mm" },
    { format: "D/M/YY HH:mm:ss" },
    { format: "D/M/YYYY HH:mm:ss" },
    { format: "DD-MM-YYYY HH:mm:ss" },
    { format: "YYYY-MM-DD HH:mm:ss" },
    { format: "YYYY-MM-DDTHH:mm:ssZ" },
    { format: "YYYY-MM-DDTHH:mm:ss.SSSZ" },
    { format: "YYYY-MM-DDTHH:mm:ssZ" },
    { format: "DD-MM-YY" },
    { format: "MM-DD-YY" },
    { format: "YY-MM-DD" },
    { format: "DD/MM/YY" },
    { format: "MM/DD/YY" },
    { format: "YY/MM/DD" },
    { format: "DD-MM-YY HH:mm" },
    { format: "MM-DD-YY HH:mm" },
    { format: "YY-MM-DD HH:mm" },
    { format: "DD/MM/YY HH:mm" },
    { format: "MM/DD/YY HH:mm" },
    { format: "YY/MM/DD HH:mm" },
    { format: "DD-MM-YY HH:mm:ss" },
    { format: "YY-MM-DD HH:mm:ss" },
    { format: "dddd, MMMM Do YYYY" },
    { format: "ddd, MMM D, YYYY" },
    { format: "MMMM D, YYYY" },
    { format: "Do MMMM YYYY" }
  ];
  const [currentColumns, setCurrentColumns] = useState(columns?.map((item) => item?.columnName));
  const [currentData, setCurrentData] = useState(data);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [filteredData, setFilteredData] = useState(data);
  const [search, setSearch] = useState("");
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const tableRef = React.useRef(null);
  const [isdeleteRowOpen, setIsdeleteRowOpen] = React.useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = React.useState(null);
  const tablescrollRef = React.useRef(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  const moveRow = useCallback(
    (fromIndex, toIndex) => {
      const updatedData = [...currentData];
      const [movedRow] = updatedData.splice(fromIndex, 1);
      updatedData.splice(toIndex, 0, movedRow);
      setCurrentData(updatedData);
    },
    [currentData]
  );


  const DragHandle = ({ index, moveItem, type, children, id, selectedComId }) => {
    const [{ isDragging }, ref] = useDrag({
      type,
      item: { index },
      canDrag: id === selectedComId,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: type,
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveItem(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });
    return (
      <div
        ref={(node) => ref(drop(node))}
        style={{
          // cursor: 'grab',
          cursor: id === selectedComId ? "grab" : "pointer",
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        {children}
      </div>
    );
  }
  const moveColumn = (draggedIndex, targetIndex) => {
    const updatedColumns = [...currentColumns];
    const [movedColumn] = updatedColumns.splice(draggedIndex, 1);
    updatedColumns.splice(targetIndex, 0, movedColumn);
    // Update the component.columnProperties with the new order
    const updatedColumnProperties = [...component.properties.columns];
    const [movedProperty] = updatedColumnProperties.splice(draggedIndex, 1);
    updatedColumnProperties.splice(targetIndex, 0, movedProperty);

    // Update the count property to reflect the new order
    const updateproperties = updatedColumnProperties.map((prop, idx) => ({
      ...prop,
      count: idx
    }));

    // Update the state and component properties
    setCurrentColumns(updatedColumns);
    setSelectedComponent({
      ...component,
      properties: {
        ...component.properties,
        columns: updateproperties
      }
    });
    onPropertyChange({ columns: updateproperties }, null);

    // Reorder data based on the new column order
    const updatedData = currentData.map(row => {
      const newRow = {};
      updatedColumns.forEach((col) => {
        newRow[col] = row[col];
      });
      return newRow;
    });

    setCurrentData(updatedData);
  };

  const handleHeaderClick = (index) => {
    setSelectedColumnIndex(index);
  };


  const handleClickOutside = (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target)) {
      setActiveRowIndex(null);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // React.useEffect(() => {
  //   setCurrentColumns(columns?.map(i => i?.columnName));
  //   setCurrentData(data);
  // }, [columns, data]);

  React.useEffect(() => {

    const filteredColumns = columns?.filter((item) => item !== `${String(component?.table_name?.replace(/[- ]/g, "_"))?.toLowerCase()}_id`).map((item) => item.columnName);
    const orderedColumns = filteredColumns?.sort((a, b) => {
      const aIndex = component?.properties?.columns?.find(prop => prop.columnName === a)?.count;
      const bIndex = component?.properties?.columns?.find(prop => prop.columnName === b)?.count;
      return aIndex - bIndex;
    });
    setCurrentColumns(orderedColumns);
    setCurrentData(data);

  }, [columns, data]);


  // const borderColorWithOpacity = hexToRgba(properties?.borderColor, 0.6);
  const rgbaToRgb = (rgba) => {
    const match = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*\d*\.\d+\)$/);
    if (match) {
      return `rgb(${match[1]}, ${match[2]}, ${match[3]})`;
    }
    return rgba; // Return the original value if it doesn't match the expected format
  };

  React.useEffect(() => {
    const checkScrollbar = () => {
      if (tablescrollRef.current) {
        setHasScrollbar(tablescrollRef.current.scrollHeight > tablescrollRef.current.clientHeight);
      }
    };

    // Check for scrollbar on mount and whenever data changes
    checkScrollbar();
    window.addEventListener("resize", checkScrollbar);

    return () => {
      window.removeEventListener("resize", checkScrollbar);
    };
  }, [currentData, columns]);

  const [currentPage, setCurrentPage] = React.useState(0);
  const rowsPerPage = 20;

  React.useEffect(() => {
    const initialData = data.slice(0, rowsPerPage);
    setCurrentData(initialData);
  }, [data]);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      loadMoreData();
    }
  };

  const loadMoreData = () => {
    if (currentPage * rowsPerPage < data.length) {
      const nextPage = currentPage + 1;
      const nextData = data.slice(0, nextPage * rowsPerPage);
      setCurrentData(nextData);
      setCurrentPage(nextPage);
    }
  };


  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div
          // className="d-flex flex-col "
          className={`d-flex flex-col  ${selectedComId == id ? "" : "cursor-pointer"}`}
          style={{ height: "100%", width: "100%" }}
        >
          {columns?.length > 0 &&
            <div
              // className={`w-full`}  //md:w-[calc(100%-3px)]
              colSpan={currentColumns.length + 1}
              style={{
                whiteSpace: "nowrap",
                border: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                color: `${properties?.textColor}`,
                backgroundColor: `${rgbaToRgb(
                  properties?.backgroundColor
                )}`,
                textAlign: `${properties?.align} `,
                fontSize: `${Number(properties?.fontSize) + 2 + "px"}`,
                fontWeight: `${properties?.fontWeight} `,
                paddingLeft: `${properties.paddingLeft + "px"}`,
                paddingRight: `${properties.paddingRight + "px"}`,
                paddingTop: `${properties.paddingTop + "px"}`,
                paddingBottom: `${properties.paddingBottom + "px"}`,
                width: hasScrollbar ? "calc(100% - 3px)" : "100%",
              }}
            >
              <div className="d-flex text-sm justify-between items-center table-total">
                <div
                  // className="px-3 text-left capitalize"
                  style={{
                    fontFamily: `${properties?.fontFamily}`,
                    fontWeight: "bold",
                  }}
                  className={`padding-bottom-cu px-3 text-left capitalize ${selectedComId == id ? "cursor-default" : "cursor-pointer"}`}
                >
                  Name: {properties?.name}
                </div>
                <div
                  // className="px-4 text-right capitalize fw-bold"
                  style={{
                    fontFamily: `${properties?.fontFamily}`,
                    fontWeight: "bold",
                  }}
                  className={`padding-bottom-cu px-4 text-right capitalize  ${selectedComId == id ? "cursor-default" : "cursor-pointer"}`}
                >
                  Total Rows: {properties?.data?.length}
                </div>
              </div>
            </div>}

          <div
            style={{ width: "100%", overflow: "auto" }}
            // className={`custom-scrollbar ${search && filteredData?.length <= 0 ? "no-scrollbar " : ""
            //   }  `}
            className={`custom-scrollbar`}
            id={id}
            onMouseMove={() => stopTableDrag()}
            ref={tablescrollRef}
            onScroll={handleScroll}
          >
            {data?.length > 0 && columns?.length > 0 ?
              <table
                id={`element-${id}`}
                style={{
                  borderLeft: `${properties.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                  height: "100%",
                  width: "100%",
                  backgroundColor: `${properties.backgroundColor}`,
                  color: `${properties.textColor}`,
                  borderCollapse: "separate",
                  borderSpacing: "0",
                }}
                className="scroll"
              >
                <thead
                  style={{
                    top: "0px",
                  }}
                >
                  <tr>
                    {currentColumns?.map((col, index) => (
                      <th
                        onClick={() => handleHeaderClick(index)}
                        key={index}
                        id={`header-${index}`}
                        className={`p-[2px] ${selectedComId == id ? "cursor-default" : "cursor-pointer"}`}
                        style={{
                          // textAlign: 'center',
                          whiteSpace: "nowrap",
                          borderBottom: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                          borderTop: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                          borderRight: `${properties?.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                          // padding: "3px 3px",
                          color: `${properties?.columnProperties?.find(
                            (item) => item.name === col
                          )?.textColor
                            ? properties.columnProperties?.find(
                              (item) => item.name === col
                            )?.textColor
                            : properties?.textColor
                            }`,
                          backgroundColor: rgbaToRgb(
                            `${properties?.columnProperties?.find(
                              (item) => item.name === col
                            )?.backgroundColor
                              ? properties?.columnProperties?.find(
                                (item) => item.name === col
                              )?.backgroundColor
                              : properties?.backgroundColor
                            }`
                          ),
                          textAlign: `${properties?.columnProperties?.find(
                            (item) => item.name === col
                          )?.align
                            ? properties.columnProperties?.find(
                              (item) => item.name === col
                            )?.align
                            : properties?.align
                            } `,
                          fontSize: `${properties?.columnProperties?.find(
                            (item) => item.name === col
                          )?.fontSize
                            ? properties.columnProperties?.find(
                              (item) => item.name === col
                            )?.fontSize + "px"
                            : properties?.fontSize + "px"
                            }`,
                          paddingLeft: ` ${properties.paddingLeft + "pt"}`,
                          paddingRight: `${properties.paddingRight + "pt"}`,
                          paddingTop: ` ${properties.paddingTop + "pt"}`,
                          paddingBottom: `${properties.paddingBottom + "pt"}`,
                        }}
                      >
                        {/* <div
                          style={{ fontFamily: `${properties?.fontFamily}` }}
                        >
                          {col}
                        </div> */}
                        <DragHandle
                          index={index}
                          moveItem={(draggedIndex, targetIndex) => {
                            moveColumn(draggedIndex, targetIndex);
                            component.properties.columns = component?.properties?.columns?.map((prop, idx) => ({
                              ...prop,
                              count: idx
                            }));
                          }}
                          type="COLUMN"
                          column={col}
                          properties={properties}
                          id={id}
                          selectedComId={selectedComId}
                        >
                          {/* <DragIndicatorIcon /> */}
                          <div
                            style={{ fontFamily: `${properties?.fontFamily}` }}
                          >
                            {col}
                          </div>
                        </DragHandle>
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* {search && filteredData.length <= 0 ? (
                  <tbody>
                    <div
                      className="d-flex align-Items-center justify-content-center absolute w-full py-4"
                      style={{ zIndex: 0 }}
                    >
                      <p className="text-base font-bold text-primarygrayborder">
                        {" "}
                        No Records Found
                      </p>
                    </div>
                  </tbody>
                ) : ( */}
                <tbody ref={tableRef}>
                  {(currentData).map(
                    (row, rowIndex) => (
                      <tr key={rowIndex}>
                        {currentColumns?.map((col, colIndex) => {
                          const columnProperty = properties?.columnProperties?.find(item => item.name === col);
                          let formattedValue = row[col];
                          if (formattedValue === null && !columnProperty?.isDataColumn && !columnProperty?.isComputed) {
                            if (columnProperty?.type === 'int') {
                              formattedValue = "NA";
                            } else if (columnProperty?.type === 'date') {
                              formattedValue = "NA";
                            } else if (columnProperty?.type === 'varchar') {
                              formattedValue = "NA";
                            }
                          } else if (formattedValue === null && columnProperty?.isDataColumn) {
                            formattedValue = "";
                          } else if (formattedValue === null && columnProperty?.isComputed) {
                            formattedValue = 0;
                          }
                          if (columnProperty) {
                            const { format, currency, isPercentage, decimal, isComma } = columnProperty;

                            if (format === "Percentage" && isPercentage) {
                              // formattedValue = (row[col] * 100)?.toFixed(0);
                              formattedValue = (row[col]);
                              if (isComma) {
                                formattedValue = parseFloat(formattedValue)?.toLocaleString();
                              }
                              formattedValue = `${formattedValue}%`;
                            }
                            const dateFormats = dateformat.map(item => item.format);

                            if (dateFormats.includes(format)) {
                              formattedValue = moment(row[col]).isValid() ? moment(row[col])?.utc(false).format(format) : "Invalid Date";
                            }

                            else if (format === "Whole Number") {
                              formattedValue = Math?.round(row[col]);
                              if (isComma) {
                                formattedValue = formattedValue?.toLocaleString();
                              }
                            } else if (format === "General") {
                              formattedValue;
                            }
                            if (currency) {
                              formattedValue = `${currency}${parseFloat(formattedValue)?.toFixed(2)}`;
                              if (isComma) {
                                const [integerPart, decimalPart] = formattedValue.split(".");
                                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                formattedValue = `${formattedInteger}${decimalPart ? "." + decimalPart : ""}`;
                              }
                            }
                            if (format === "Decimal" && typeof decimal === "number") {
                              formattedValue = parseFloat(row[col])?.toFixed(decimal);
                              if (isComma) {
                                formattedValue = formattedValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              }
                            }
                          }
                          return (
                            <td
                              id={`cell-${rowIndex}-${col}`}
                              key={colIndex}
                              className="p-[2px]"
                              style={{
                                borderBottom: `${properties.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                                // padding: "3px 3px",
                                borderRight: `${properties.borderWidth}px ${properties?.borderType} ${properties?.borderColor}`,
                                color: `${properties.columnProperties?.find(
                                  (item) => item.name === col
                                )?.textColor
                                  ? properties.columnProperties?.find(
                                    (item) => item.name === col
                                  )?.textColor
                                  : properties?.textColor
                                  }`,
                                backgroundColor: `${properties?.columnProperties?.find(
                                  (item) => item.name === col
                                )?.backgroundColor
                                  ? properties?.columnProperties?.find(
                                    (item) => item.name === col
                                  )?.backgroundColor
                                  : properties?.backgroundColor
                                  }`,
                                textAlign: `${properties?.columnProperties?.find(
                                  (item) => item.name === col
                                )?.align
                                  ? properties?.columnProperties?.find(
                                    (item) => item.name === col
                                  )?.align
                                  : properties?.align
                                  }`,
                                fontSize: `${properties.columnProperties?.find(
                                  (item) => item.name === col
                                )?.fontSize
                                  ? properties.columnProperties?.find(
                                    (item) => item.name === col
                                  )?.fontSize + "px"
                                  : properties?.fontSize + "px"
                                  }`,
                                fontWeight: `${properties.columnProperties?.find(
                                  (item) => item.name === col
                                )?.fontWeight
                                  ? properties.columnProperties?.find(
                                    (item) => item.name === col
                                  )?.fontWeight
                                  : properties?.fontWeight
                                  } `,
                                // cursor: "text",
                                paddingLeft: ` ${properties.paddingLeft + "pt"}`,
                                paddingRight: `${properties.paddingRight + "pt"}`,
                                paddingTop: ` ${properties.paddingTop + "pt"}`,
                                paddingBottom: `${properties.paddingBottom + "pt"
                                  }`,
                                verticalAlign: `${properties?.verticalAlign}`,
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  fontFamily: `${properties?.columnProperties?.find((item) => item.name === col)
                                    ?.fontFamily
                                    ? properties?.columnProperties?.find((item) => item.name === col)
                                      ?.fontFamily
                                    : properties?.fontFamily
                                    }`,
                                }}
                                className={` ${selectedComId == id ? "cursor-default" : "cursor-pointer"}`}
                              >
                                {formattedValue}
                              </div>
                            </td>
                          )
                        }
                        )}
                      </tr>
                    )
                  )}
                </tbody>
                {/* )} */}
              </table>
              : <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <img
                  //src={src ? `data:image/jpeg;base64,${src}` : placeholderImage}
                  src={Default_Table}
                  alt="Selected"
                  // style={{
                  //     ...imageSizingStyle(),
                  //     ...imageRotationStyle(),
                  //     boxSizing: 'border-box',
                  //     borderRadius: `${properties?.borderRadius}%`,
                  // }}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
                {properties?.data?.length === 0 && (
                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#919090',
                    pointerEvents: 'none',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontFamily: "Inter",
                    zIndex: 1,
                    backgroundColor: '#ffffff',
                    opacity: 1,
                    padding: '10px',
                    borderRadius: '5px'
                  }}>
                    'Please select columns from the Reports Columns of Data Source Panel to create table.'
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default TableComponent;
