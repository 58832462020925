import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import LeftArrowSvg from "../../../../assets/svgs/LeftArrowSvg";
import { Icons } from "../../../../assets";
import { useSchedulerStore } from "../../../../context/scheduler.store";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectToken } from "../../../../features/auth/authSlice";
import { FileUploader } from "react-drag-drop-files";
import { useCheckEHRUploadStatusMutation } from "../../../../features/organisation/organisationApiSlice";
import { extractUniqueObjects } from "../../../../utilities/resuseFunctions";

const fileTypes = ["csv", "xls", "xlsx"];

const status = {
  steps: [
    {
      error: false,
      discription: "Checking for incorrect formatting",
    },
    {
      error: false,
      discription: "Updating old providers and creating new providers",
    },
    {
      error: false,
      discription: "Updating old patients and creating new patients",
    },
    {
      error: false,
      discription:
        "Updating old appointment types and creating new appointment types",
    },
    {
      error: false,
      discription: "Updating old careplans and creating new careplans",
    },
    {
      error: false,
      discription: "Updating provider matrix",
    },
    {
      error: true,
      discription: "Error in updating provider matrix",
    },
  ],
  canUploadAgain: true,
};

const UploadCsv = ({
  onBackCLick,
  isWelcome,
  initUpload,
  onSuccess,
  onError,
  setUploadSteps,
  setRefresh,
}) => {
  const [errorTitle, setErrorTitle] = useState(null);
  const [errors, setErrors] = useState([]);
  const token = useSelector(selectToken);

  const fileName = useSchedulerStore((state) => state.fileName);
  const setFileName = useSchedulerStore((state) => state.setFileName);

  const [fileSize, setFileSize] = useState(null);

  const [canUpload, setCanUpload] = useState(false);

  const [steps, setSteps] = useState([]);

  const [checkStatus, setCheckStatus] = useState(true);

  function storeSizeInMBorKB(size) {
    if (size > 800 * 1000) {
      setErrorTitle("File Size should be less than 800KB");
      return;
    }
    if (size < 1000) {
      setFileSize(`${size}B`);
    } else if (size < 1000 * 1000) {
      setFileSize(`${(size / 1000).toFixed(2)}KB`);
    }
  }

  const [checkEHRStatus] = useCheckEHRUploadStatusMutation();

  async function uploadFile(file) {
    if (isWelcome) {
      initUpload();
      setUploadSteps([]);
    }
    setErrorTitle(null);
    setErrors([]);
    setFileSize(null);
    setFileName(null);
    setSteps([]);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/organisation/verify-ehr-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": process.env.REACT_APP_SERVER_URL,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setFileName(file.name);
        setCheckStatus(true);
        // storeSizeInMBorKB(file.size);
        if (isWelcome) {
          onSuccess();
        }
      }
    } catch (error) {
      const { message, detail } = error.response.data;
      if (isWelcome) {
        onError();
      }

      setErrorTitle(message);

      if (detail) {
        const errorDetails = extractUniqueObjects(detail);
        setErrors(errorDetails);
      }
    }
  }

  const downloadCSV = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/appointment/careplan-template`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "careplan-template.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleChange = (file) => {
    try {
      uploadFile(file);
    } catch (error) {
      console.log("err");
    }
  };

  useEffect(() => {
    if (checkStatus) {
      const interval = setInterval(async () => {
        try {
          const response = await checkEHRStatus().unwrap();

          if (response.data === null || response.data.canUploadAgain) {
            setCanUpload(true);
            clearInterval(interval);
            setCheckStatus(false);
          }

          if (response.data !== null) {
            setSteps(response.data.status.steps);
            if (isWelcome) {
              setUploadSteps(response.data.status.steps);
            }

            if (response.data.status.canUploadAgain) {
              setCanUpload(true);
              clearInterval(interval);
              setCheckStatus(false);
              if (setRefresh) {
                setRefresh(true);
              }
            }
          }
        } catch (error) {
          console.log(error);
          clearInterval(interval);
          setCheckStatus(false);
        }
      }, 4000);

      return () => clearInterval(interval); // Clean up the interval on component unmount
    }
  }, [checkStatus]);

  return (
    <div className="flex flex-col gap-20 mb-8">
      <div className="flex flex-col w-full gap-1">
        <div className="flex flex-row items-end justify-end w-full mb-2 md:mb-0">
          <p
            className="m-0 text-sm underline text-primaryblue"
            onClick={downloadCSV}
            role="button"
          >
            CareSMS Scheduling Data Template
          </p>
        </div>
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          classes="w-100"
          disabled={!canUpload}
        >
          <div className="flex hover:bg-subtleblue transition-all cursor-pointer flex-col w-full justify-center items-center gap-2 border-[1px] border-dashed border-secondarygrayborder rounded-lg p-4">
            <img
              src={Icons.Upload_Icon}
              alt="upload icon"
              className="mx-2 icon"
            />
            <p className="m-0 mt-2 text-sm">
              Drag & Drop or{" "}
              <span
                className={classNames(
                  canUpload ? "text-primaryblue" : "text-primarydarkgrey"
                )}
              >
                Choose file
              </span>{" "}
              to upload
            </p>
            {/* <p className="m-0 text-xs text-primarytextgrey">Max size of 800K</p> */}
          </div>
        </FileUploader>
        {
          <div className="flex flex-col items-start gap-4 py-4">
            {steps.map((step, indx) => (
              <div key={indx} className="flex flex-row items-center gap-2">
                <div
                  className={classNames(
                    "text-sm m-0 w-5 h-5 rounded-full flex items-center justify-center text-white",
                    step.error ? "bg-primaryred" : "bg-primaryblue"
                  )}
                >
                  {indx + 1}
                </div>
                <p
                  className={classNames(
                    "m-0 text-sm",
                    step.error ? "text-primaryred" : "text-primarytextgrey"
                  )}
                >
                  {step.discription}
                </p>
              </div>
            ))}
          </div>
        }
        {errorTitle ? (
          <>
            <p className="m-0 mt-1 text-sm text-primaryred">{errorTitle}</p>
            {errors?.map((detail, indx) => (
              <div key={indx} className="flex flex-row items-center gap-2">
                <div
                  className={classNames(
                    "text-sm m-0 w-8 h-8 rounded-full flex items-center justify-center text-white",
                    "bg-primaryred"
                  )}
                >
                  {indx + 1}
                </div>
                <p className={classNames("m-0 text-sm", "text-primaryred")}>
                  {Object.keys(detail)
                    .map((d) => `${d}: ${detail[d]}`)
                    .join(", ")}
                </p>
              </div>
            ))}
            {/* <div className="flex flex-col items-start gap-4 py-4">
              {Array.isArray(errors) &&
                errors.steps.map((error, indx) => (
                  <div key={indx} className="flex flex-row items-center gap-2">
                    <div className="flex items-center justify-center w-5 h-5 m-0 text-sm text-white rounded-full bg-primaryred">
                      {indx + 1}
                    </div>
                    <p className="m-0 text-sm text-primaryred">
                      {error.missing_column !== undefined
                        ? `Missing column "${error.missing_column}" at column index ${error.missing_column_position}.`
                        : error.column_name !== undefined &&
                          error.row_index !== undefined
                        ? ""
                        : ""}
                    </p>
                  </div>
                ))}
            </div>
            <div className="flex flex-col w-fit">
              {Array.isArray(errors) &&
                errors.map((error, indx) => (
                  <p key={indx} className="m-0 mt-1 text-sm text-primaryred">
                    {indx + 1}. <span className="font-bold">{error.error}</span>
                    {error.missing_column !== undefined && (
                      <>
                        {" "}
                        Missing column{" "}
                        <span className="font-bold">
                          {error.missing_column}
                        </span>
                        .
                      </>
                    )}
                    {error.column_name !== undefined ||
                      (error.row_index !== undefined && (
                        <>
                          {" "}
                          Located in column{" "}
                          <span className="font-bold">{error.column_name}</span>
                          , row index{" "}
                          <span className="font-bold">{error.row_index}</span>.
                        </>
                      ))}
                  </p>
                ))}
            </div> */}
          </>
        ) : (
          fileName && (
            <p className={classNames("m-0 mt-1 text-sm text-primarytextgrey")}>
              <span>
                File: <span className="text-primaryblue">{fileName}</span>{" "}
                uploaded successfully
              </span>
            </p>
          )
        )}
      </div>
      {/* <div className="flex flex-row items-center justify-center w-full gap-2 p-4 border-2 rounded-lg cursor-pointer bg-blue-50 border-primaryblue">
        <img src={Icons.Radio_Icon} alt="upload icon" className="icon" />
        <p className="m-0 text-base">
          <button>
            <span className="text-primaryblue">Sync</span>
          </button>{" "}
          with EHR
        </p>
      </div> */}
      {fileName && (
        <div className="flex flex-col w-full">
          <div className="flex flex-row items-start justify-between w-full p-4 rounded-lg bg-subtleblue">
            <div className="flex flex-col w-full gap-2">
              <p className="m-0 text-lg font-bold text-black">{fileName}</p>
              {fileSize && (
                <p className="m-0 text-sm text-primarytextgrey">{fileSize}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadCsv;
