import React from "react";
import ReusableModal from "../../../components/Modals/ReusableModal";
import Lock from "../../../assets/svgs/Lock";
import Unlock from "../../../assets/svgs/Unlock";
import classNames from "classnames";

const AppointmentLockModal = ({
  open,
  handleLockModal,
  handleLock,
  status,
}) => {
  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col gap-3 p-3">
        <div className="flex flex-row gap-2">
          <div
            className={classNames(
              "flex items-center justify-center w-10 h-10 rounded-full",
              status === "UNLOCKED" ? "bg-primaryblue" : "bg-primaryyellow"
            )}
          >
            {status === "UNLOCKED" ? (
              <Lock color={"#FFF"} />
            ) : (
              <Unlock color={"#FFF"} />
            )}
          </div>
          <div className="flex flex-col">
            <p className="m-0 font-semibold">
              {status === "LOCKED" ? "Unlock" : "Lock"} Appointment
            </p>
            <p className="m-0 font-light">
              {status === "UNLOCKED" ? (
                <>
                  Are you sure you want to lock this appointment? This
                  appointment <br /> won't be overriden by any other appointment
                  until it is unlocked.
                </>
              ) : (
                <>
                  Are you sure you want to unlock this appointment? This
                  appointment <br /> will be overriden by any other appointment
                  until it is locked.
                </>
              )}
            </p>
          </div>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-row items-center justify-end gap-3">
          <button
            onClick={handleLockModal}
            className="px-4 py-2 font-medium border rounded-md bg-primarylightgrey border-primarygray"
          >
            Cancel
          </button>
          <button
            className={classNames(
              "px-4 py-2 font-medium text-white rounded-md",
              status === "LOCKED" ? "bg-primaryyellow border-[1px] border-primaryyellow" : "bg-primaryblue border-[1px] border-primaryblue"
            )}
            onClick={async () => {
              await handleLock();
              handleLockModal();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default AppointmentLockModal;
