import * as React from "react";
const LogoutSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M17.801 10.509a.75.75 0 0 0 0-1.018l-3-3.25A.75.75 0 1 0 13.7 7.26l1.838 1.991H8.75a.75.75 0 0 0 0 1.5h6.787l-1.838 1.991A.75.75 0 1 0 14.8 13.76l3-3.25ZM10 18.25a.75.75 0 0 0-.75-.75h-4.5c-.69 0-1.25-.56-1.25-1.25V3.75c0-.69.56-1.25 1.25-1.25h4.5a.75.75 0 0 0 0-1.5h-4.5A2.75 2.75 0 0 0 2 3.75v12.5A2.75 2.75 0 0 0 4.75 19h4.5a.75.75 0 0 0 .75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default LogoutSvg;
