import * as React from "react";
const UserSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M10 2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM6 5a4 4 0 1 1 8 0 4 4 0 0 1-8 0ZM3 16.625A5.625 5.625 0 0 1 8.625 11h2.75A5.625 5.625 0 0 1 17 16.625 2.375 2.375 0 0 1 14.625 19h-9.25A2.375 2.375 0 0 1 3 16.625ZM8.625 12.5A4.125 4.125 0 0 0 4.5 16.625c0 .483.392.875.875.875h9.25a.875.875 0 0 0 .875-.875 4.125 4.125 0 0 0-4.125-4.125h-2.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default UserSvg;
