import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from 'react'
import RemoveIcon from '@mui/icons-material/Remove';
import { SketchPicker } from 'react-color';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useUpdateTemplatesMutation } from '../../../../../features/report/reportApiSlice';
import { Tooltip } from '@mui/material';

const SidebarSection = ({ title, children, subSection, isOpen, onToggle }) => {
    // const [isOpen, setIsOpen] = React.useState(false);
    // const toggleOpen = () => {
    //     setIsOpen(!isOpen);
    // };
    return (
        <div className="sidebar-section"
        >
            {/* <div className={`sidebar-section-header hover:bg-slate-300 ${subSection ? ' bg-slate-100' : 'bg-slate-200 py-1'} px-2 `}
                onClick={onToggle}
            > */}
            <div
                className={`sidebar-section-header px-2 cursor-pointer  ${subSection ? "" : "py-1"}`}
                onClick={onToggle}
                style={{
                    backgroundColor: !subSection ? "rgb(16 85 151)" : "#534a4a40",
                }}
            >
                <div className='d-flex justify-between items-center'>
                    {/* <span className={`font-semibold ${subSection ? 'text-sm text-gray-600' : 'text-base text-gray-500'} `}>
                        {title}
                    </span> */}
                    <span
                        className={`font-semibold text-sm ${subSection ? "text-sm text-gray-600" : "text-base text-gray-500"
                            } `}
                        style={{ color: subSection ? "text-sm text-gray-600" : "white", paddingLeft: "8px" }}
                    >
                        {title}
                    </span>
                    <button className="toggle-button">
                        {isOpen ? (
                            <Tooltip title="Remove">

                                <RemoveIcon className={`${subSection ? "text-sm text-gray-600" : "text-white"}`} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Add">

                                <AddIcon className={`${subSection ? "text-sm text-gray-600" : "text-white"}`} />
                            </Tooltip>
                        )}
                    </button>
                </div>
            </div>
            {isOpen && (
                <div className="sidebar-section-content">
                    {children}
                </div>
            )}
        </div>
    );
};

function ImageProperties({ component, onPropertyChange, setSelectedComponent, showAlert, flag, setFlag, reportAreaRef, onImageSelect, openSection, setOpenSection }) {
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const [colorPickerBg, setColorPickerBg] = React.useState(false);
    const [colorPickerText, setColorPickerText] = React.useState(false)
    const [updateTemplates] = useUpdateTemplatesMutation();
    const borderColorRef = React.useRef(null);
    const colorPickerRefs = React.useRef({});
    const backgroundColorRef = React.useRef(null);
    const textColorRef = React.useRef(null);
    const columnDropdownRef = React.useRef(null);
    const [columnListOpen, setColumnListOpen] = React.useState(false);
    const [colorPickerState, setColorPickerState] = React.useState({});
    const [fileName, setFileName] = React.useState('');

    const fontWeights = [
        { name: 'Thin', value: 100 },
        { name: 'Extra Light', value: 200 },
        { name: 'Light', value: 300 },
        { name: 'Normal', value: 400 },
        { name: 'Medium', value: 500 },
        { name: 'Semi Bold', value: 600 },
        { name: 'Bold', value: 700 },
        { name: 'Extra Bold', value: 800 },
    ];
    const Fonts = [
        'Arial',
        'Helvetica',
        'Times New Roman',
        'Times',
        'Courier New',
        'Courier',
        'Verdana',
        'Georgia',
        'Palatino',
        'Garamond',
        'Bookman',
        'Trebuchet MS',
        'Comic Sans MS',
        'Impac'
    ]

    const handlePositionChange = (e, property) => {
        const newValue = parseInt(e.target.value, 10);
        let updatedProperties = { [property]: newValue };
        if (newValue < 0) {
            updatedProperties[property] = 0;
        }
        if (property === 'top') {
            updatedProperties.bottom = reportAreaRef.current.clientHeight - newValue - component.properties.height;
            // Ensure bottom doesn't go below 0
            if (updatedProperties.bottom < 0) {
                updatedProperties.bottom = 0;
                updatedProperties.top = reportAreaRef.current.clientHeight - component.properties.height;
            }
        } else if (property === 'bottom') {
            updatedProperties.top = reportAreaRef.current.clientHeight - newValue - component.properties.height;
            // Ensure top doesn't go below 0
            if (updatedProperties.top < 0) {
                updatedProperties.top = 0;
                updatedProperties.bottom = reportAreaRef.current.clientHeight - component.properties.height;
            }
        } else if (property === 'left') {
            updatedProperties.right = reportAreaRef.current.clientWidth - newValue - component.properties.width;
            if (updatedProperties.right < 0) {
                updatedProperties.right = 0;
                updatedProperties.left = reportAreaRef.current.clientWidth - component.properties.width;
            }
        } else if (property === 'right') {
            updatedProperties.left = reportAreaRef.current.clientWidth - newValue - component.properties.width;
            if (updatedProperties.left < 0) {
                updatedProperties.left = 0;
                updatedProperties.right = reportAreaRef.current.clientWidth - component.properties.width;
            }
        }
        onPropertyChange(updatedProperties);
        setSelectedComponent((prev) => ({
            ...prev,
            properties: {
                ...prev.properties,
                ...updatedProperties
            }
        }));
        setFlag(false);
    };
    const handleClickOutside = (event) => {
        if (
            borderColorRef.current &&
            !borderColorRef.current.contains(event.target)) {
            setColorPickerOpen(false);
        }
        if (
            textColorRef.current &&
            !textColorRef.current.contains(event.target)
        ) {
            setColorPickerText(false);
        }
        if (backgroundColorRef?.current && !backgroundColorRef?.current?.contains(event.target)) {
            setColorPickerBg(false);
        }
        if (columnDropdownRef.current && !columnDropdownRef.current.contains(event.target)) {
            setColumnListOpen(false);
        }
        const isOutsideColorPickers = Object.keys(colorPickerRefs.current).every(columnName => {
            const refs = colorPickerRefs.current[columnName];
            return !refs || Object.keys(refs).every(pickerType => {
                const ref = refs[pickerType];
                return ref && !ref.contains(event.target);
            });
        });
        if (isOutsideColorPickers) {
            setColorPickerState({});
        } else {
            setColorPickerState((prevState) => {
                const newState = { ...prevState };
                Object.keys(colorPickerRefs.current).forEach(columnName => {
                    const refs = colorPickerRefs.current[columnName];
                    Object.keys(refs).forEach(pickerType => {
                        const ref = refs[pickerType];
                        if (ref && !ref.contains(event.target)) {
                            if (newState[columnName]) {
                                newState[columnName][pickerType] = false;
                            }
                        }
                    });
                });
                return newState;
            });
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                console.log(base64String, 'Base64 String');
                onPropertyChange({ src: base64String });
                setSelectedComponent((prev) => ({
                    ...prev,
                    properties: {
                        ...prev.properties,
                        src: base64String
                    }
                }));
                setFileName(file.name);
            };
            reader.readAsDataURL(file);
        }
    };
    // const [openSection, setOpenSection] = React.useState(null);

    const handleToggle = (section) => {
        setOpenSection(openSection === section ? null : section);
    };
    return (
        <>
            <div>
                <span className="flex flex-row items-start justify-start w-full gap-1 py-3 px-3 items-center">
                    <p className='font-semibold text-sm text-gray-500 m-0'>Name:</p>
                    <input
                        type="text"
                        className="block p-1 text-sm text-gray-900 bg-white border-custome  rounded w-full  focus:ring-blue-500 focus:border-blue-500"
                        value={component?.properties?.name || ''}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            onPropertyChange({ name: newValue });
                            setSelectedComponent((prev) => ({
                                ...prev,
                                properties: {
                                    ...prev.properties,
                                    name: newValue
                                }
                            }));
                            setFlag(false)
                        }}
                    />
                </span>
                <span className="flex py-1 mx-3 px-1 my-2 mb-3 mt-1 items-center text-sm text-gray-900 bg-white border border-gray-300 rounded-lg" >
                    <div className="flex flex-grow justify-start items-center">
                        <span className="text-gray-700 pl-1">{fileName || 'No file selected'}</span>
                    </div>
                    <div className="flex justify-end">
                        <label className="inline-flex items-center space-x-1 px-2 py-1 font-bold text-white bg-[#0a3a67] rounded-md cursor-pointer properties-button">
                            <UploadFileIcon style={{ fontSize: "20px" }} />
                            <span className='text-xs'>Select File</span>
                            <input
                                type="file"
                                accept="image/png, image/jpeg"
                                className="hidden"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>
                </span>
                <SidebarSection title="Position" subSection={false} isOpen={openSection === 'position'} onToggle={() => handleToggle('position')}>
                    <span className="d-flex px-2 py-2 justify-center">
                        <span className="d-flex flex-col">
                            <span className='d-flex  py-2'>
                                <p className="prop-label mb-1 self-end">Position (px):</p>
                                <div className='mx-2'>
                                    <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">Left</p>
                                    <input
                                        type="number"
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                        value={Math?.round(component?.properties?.left)}
                                        onChange={(e) => handlePositionChange(e, 'left')}
                                    />
                                </div>
                                <div className='mx-2'>
                                    <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">Top</p>
                                    <input
                                        type="number"
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                        value={Math?.round(component?.properties?.top)}
                                        onChange={(e) => handlePositionChange(e, 'top')}
                                    />
                                </div>
                            </span>
                            <span className="d-flex py-2 self-end">
                                <div className='mx-2'>
                                    <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600">Right</p>
                                    <input
                                        type="number"
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                        value={Math?.round(component?.properties?.right)}
                                        onChange={(e) => handlePositionChange(e, 'right')}
                                    />
                                </div>
                                <div className='mx-2'>
                                    <p className="m-0 font-medium text-tiny text-gray-600 whitespace-nowrap">Bottom</p>
                                    <input
                                        type="number"
                                        className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                        value={Math?.round(component?.properties?.bottom)}
                                        onChange={(e) => handlePositionChange(e, 'bottom')}
                                    />
                                </div>
                            </span>
                        </span>
                    </span>
                </SidebarSection>
                <SidebarSection title="Appearance" subSection={false} isOpen={openSection === 'appearance'} onToggle={() => handleToggle('appearance')}>
                    <span className=" justify-center px-2 py-2 pt-3 space-x-2 flex sm:flex-row lg:flex-row ">
                        <span className='m-0 lg:self-end lg:pb-0 mb-1 sm:self-center sm:pb-7'>
                            <p className="m-0 prop-label lg:self-end mb-0">Size (px):</p>
                        </span>
                        <span className='flex lg:flex-row sm:flex-col lg:space-x-2 sm:space-y-1 lg:space-y-0'>
                            <div className="flex flex-col space-x-2">
                                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600 pl-2">Height</p>
                                <input
                                    type="number"
                                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                    value={component?.properties?.height || 0}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        onPropertyChange({ height: Number(newValue) });
                                        setSelectedComponent((prev) => ({
                                            ...prev,
                                            properties: {
                                                ...prev.properties,
                                                height: Number(newValue)
                                            }
                                        }));
                                        setFlag(false)
                                    }}
                                />
                            </div>
                            <div className="flex flex-col space-x-2">
                                <p className="m-0 font-medium text-tiny whitespace-nowrap text-gray-600 pl-2">Width</p>
                                <input
                                    type="number"
                                    className="block p-1 text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                                    value={component?.properties?.width || 0}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        onPropertyChange({ width: Number(newValue) });
                                        setSelectedComponent((prev) => ({
                                            ...prev,
                                            properties: {
                                                ...prev.properties,
                                                width: Number(newValue)
                                            }
                                        }));
                                        setFlag(false)
                                    }}
                                />
                            </div>
                        </span>
                    </span>

                    <div className="flex lg:flex-row sm:flex-col justify-center items-center space-x-4">
                        <span className="flex  py-2 space-x-4 lg:ml-5 sm:ml-16">
                            <p className="m-0  whitespace-nowrap prop-label self-center">Border:</p>
                            <select
                                value={component?.properties?.borderType || 'solid'}
                                onChange={(e) => {
                                    onPropertyChange({ borderType: e.target.value });
                                    setSelectedComponent((prev) => ({
                                        ...prev,
                                        properties: {
                                            ...prev.properties,
                                            borderType: e.target.value
                                        }
                                    }));
                                    setFlag(false)
                                }}
                                className="block p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value={-1}>Select Border Type</option>
                                {
                                    ['Solid', 'Dotted', 'Dashed', 'Double', 'None'].map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                            </select>

                        </span>
                        <span className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-20 lg:pr-0" ref={borderColorRef}>
                            <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">Border Color:</p>
                            <span className="flex items-center border-custome" >
                                <span
                                    className="w-6 h-6 sketch-border"
                                    style={{
                                        backgroundColor: component?.properties?.borderColor || "#000000",
                                    }}
                                />

                                <span
                                    className="w-5 h-6 text-center cursor-pointer"
                                    onClick={() => {
                                        setColorPickerOpen(!colorPickerOpen);
                                        setColorPickerBg(false);
                                        setColorPickerText(false);
                                    }}
                                >
                                    <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                                </span>
                            </span>
                            {colorPickerOpen && (
                                <span className="absolute z-10 right-0 bottom-0 mb-[36px] cursor-pointer" >
                                    <SketchPicker
                                        color={component?.properties?.borderColor || 'rgba(0,0,0,1)'}
                                        onChange={(color) => {
                                            const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                            onPropertyChange({ borderColor: rgbaColor });
                                            setSelectedComponent((prev) => ({
                                                ...prev,
                                                properties: {
                                                    ...prev.properties,
                                                    borderColor: rgbaColor
                                                }
                                            }));
                                            setFlag(false)
                                        }}
                                    />
                                </span>
                            )}
                        </span>
                    </div>

                    <div className="d-flex justify-center py-2 space-x-4 lg:mr-[126px] sm:mr-6">
                        <p className="m-0  whitespace-nowrap prop-label self-center">Border Width:</p>
                        <input
                            type="number"
                            className="block p-1  text-xs text-gray-900 bg-white border-custome  rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500"
                            value={component?.properties?.borderWidth || 0}
                            onChange={(e) => {
                                let newValue = e.target.value;
                                if (newValue !== '') {
                                    newValue = newValue.replace(/^0+/, '') || '0';
                                }
                                if (parseInt(newValue) < 0) {
                                    newValue = '0';
                                }
                                onPropertyChange({ borderWidth: newValue });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        borderWidth: newValue
                                    }
                                }));
                                setFlag(false)
                            }}
                        />
                    </div>
                    <div className="d-flex justify-center py-2 lg:mr-[70px] sm:ml-8 lg:ml-0 space-x-4">
                        <p className="m-0  whitespace-nowrap prop-label self-center">Align:</p>
                        <select
                            value={component?.properties?.align || "center"}
                            onChange={(e) => {
                                onPropertyChange({ align: e.target.value });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        align: e.target.value
                                    }
                                }));
                                setFlag(false)
                                //handleColumnPropertyChange(item.name, { align: e.target.value })
                            }}
                            className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded  focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value={-1}>Select Align</option>
                            {
                                ['Center', 'Left', 'Right'].map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="d-flex justify-center py-2 space-x-4 lg:mr-[196px] sm:mr-24" >
                        <p className="m-0  whitespace-nowrap prop-label self-center">Background Color:</p>
                        <span className="relative" ref={backgroundColorRef}>
                            <span className="flex items-center border-custome">
                                <span
                                    className="w-6 h-6 sketch-border"
                                    style={{
                                        backgroundColor: component?.properties?.backgroundColor || "#fff",
                                    }}
                                />
                                <span
                                    className="w-5 h-6 text-center cursor-pointer"
                                    onClick={() => {
                                        setColorPickerBg(!colorPickerBg);
                                        setColorPickerOpen(false);
                                        setColorPickerText(false);
                                    }}
                                >
                                    <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                                </span>
                            </span>
                            {colorPickerBg && (
                                <span className="absolute z-10 bottom-0 mb-[32px] cursor-pointer" >
                                    <SketchPicker
                                        color={component?.properties?.backgroundColor || 'rgba(255,255,255,1)'}
                                        onChange={(color) => {
                                            const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                            onPropertyChange({ backgroundColor: rgbaColor });
                                            setSelectedComponent((prev) => ({
                                                ...prev,
                                                properties: {
                                                    ...prev.properties,
                                                    backgroundColor: rgbaColor
                                                }
                                            }));
                                            setFlag(false)
                                        }}
                                    />
                                </span>
                            )}
                        </span>
                    </div>
                    <span className="flex lg:flex-row sm:flex-col justify-center items-center space-x-3">
                        <span className='flex  py-2 space-x-4 lg:ml-[30px] sm:ml-[72px]'>
                            <p className="m-0 whitespace-nowrap prop-label self-center">Font:</p>
                            <select
                                value={component?.properties?.fontFamily}
                                onChange={(e) => {
                                    onPropertyChange({ fontFamily: e.target.value });
                                    setSelectedComponent((prev) => ({
                                        ...prev,
                                        properties: {
                                            ...prev.properties,
                                            fontFamily: e.target.value
                                        }
                                    }));
                                    setFlag(false)
                                    //handleColumnPropertyChange(item.name, { align: e.target.value })
                                }}
                                className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer rounded  focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value={'Inter'}>Select Font Family</option>
                                {
                                    Fonts.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                            </select>
                        </span>
                        <span className="relative flex sm:flex-row sm:space-x-4 lg:space-x-0 sm:py-2 sm:pr-16 lg:pr-0" ref={textColorRef}>
                            <p className="m-0  whitespace-nowrap prop-label self-center sm:block lg:hidden">Font Color:</p>
                            <span className="flex items-center border-custome ">
                                <span
                                    className="w-6 h-6 sketch-border"
                                    style={{
                                        backgroundColor: component?.properties?.textColor || '#fff',
                                    }}
                                />
                                <span
                                    className="w-5 h-6 text-center cursor-pointer"
                                    onClick={() => {
                                        setColorPickerText(!colorPickerText);
                                        setColorPickerBg(false);
                                        setColorPickerOpen(false);
                                    }}
                                >
                                    <KeyboardArrowDownIcon style={{ fontSize: "20px" }} />
                                </span>
                            </span>
                            {colorPickerText && (
                                <span className="absolute z-10 right-0 bottom-0 mb-[38px] cursor-pointer" >
                                    <SketchPicker
                                        color={component?.properties?.textColor || 'rgba(0, 0, 0, 1)'}
                                        onChange={(color) => {
                                            const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                            onPropertyChange({ textColor: rgbaColor });
                                            setSelectedComponent((prev) => ({
                                                ...prev,
                                                properties: {
                                                    ...prev.properties,
                                                    textColor: rgbaColor
                                                }
                                            }));
                                            setFlag(false)
                                        }}
                                    />
                                </span>
                            )}
                        </span>
                    </span>
                    <div className="d-flex justify-center py-2 space-x-4 lg:mr-[102px]">
                        <p className="block m-0 prop-label whitespace-nowrap  self-center">
                            Font Size:
                        </p>
                        <input
                            type="number"
                            className="block p-1  text-xs text-gray-900 bg-white border-custome rounded w-[90px]  focus:ring-blue-500 focus:border-blue-500 "
                            value={component?.properties?.fontSize || 16}
                            onChange={(e) => {
                                let newValue = e.target.value;
                                if (newValue !== '') {
                                    newValue = newValue.replace(/^0+/, '') || '0';
                                }
                                if (parseInt(newValue) < 0) {
                                    newValue = '0';
                                }
                                onPropertyChange({ fontSize: newValue });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        fontSize: newValue
                                    }
                                }));
                                setFlag(false)
                            }}
                        />
                    </div>
                    <div className="d-flex justify-center py-2 pb-3 space-x-4 lg:mr-[68px] sm:ml-8 lg:ml-0">
                        <p className="m-0  whitespace-nowrap prop-label self-center">Font Weight:</p>
                        <select
                            value={component?.properties?.fontWeight}
                            onChange={(e) => {
                                onPropertyChange({ fontWeight: e.target.value });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        fontWeight: e.target.value
                                    }
                                }));
                                setFlag(false)
                                //handleColumnPropertyChange(item.name, { align: e.target.value })
                            }}
                            className="block p-1 text-xs text-gray-900 bg-white border-custome rounded cursor-pointer focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value={'Inter'}>Select Font Weight</option>
                            {
                                fontWeights.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                </SidebarSection>
                <SidebarSection title="Advance" subSection={false} isOpen={openSection === 'advance'} onToggle={() => handleToggle('advance')} >
                    <span className="d-flex justify-center py-2 space-x-4  mt-3 lg:ml-0 sm:ml-[48px] ">
                        <p className="m-0  whitespace-nowrap prop-label self-center">Image Sizing:</p>
                        <select
                            value={component?.properties?.imageSizing || 'autosize'}
                            onChange={(e) => {
                                onPropertyChange({ imageSizing: e.target.value });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        imageSizing: e.target.value
                                    }
                                }));
                                setFlag(false);
                            }}
                            className="block  p-1 text-xs text-gray-900 bg-white border-custome cursor-pointer lg:w-fit  rounded focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value={-1}>Select Image Sizing</option>
                            {
                                ['Auto Size', 'Fit', 'Fit Proportional', 'Clip'].map((item, index) => (
                                    <option key={index} value={item.toLowerCase().replace(' ', '-')}>
                                        {item}
                                    </option>
                                ))}
                        </select>
                    </span>
                    <span className="d-flex justify-center py-2 space-x-4 lg:mr-[96px] sm:mr-[48px]">
                        <p className="m-0  whitespace-nowrap prop-label self-center">Rotation Angle (deg):</p>
                        <input
                            type="number"
                            value={component?.properties?.rotation || 0}
                            onChange={(e) => {
                                let newValue = e.target.value;
                                if (newValue !== '') {
                                    newValue = newValue.replace(/^0+/, '') || '0';
                                }
                                onPropertyChange({ rotation: newValue });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        rotation: newValue
                                    }
                                }));
                                setFlag(false);
                            }}
                            className="block p-1 text-xs text-gray-900 bg-white border-custome w-[90px]  rounded focus:ring-blue-500 focus:border-blue-500"
                            min="0"
                            max="360"
                        />
                    </span>
                    <span className="d-flex justify-center py-2 space-x-4 lg:mr-[54px] sm:mr-1 mb-3">
                        <p className="m-0  whitespace-nowrap prop-label self-center">Image Radius:</p>
                        <input
                            type="number"
                            value={component?.properties?.borderRadius || "0"}
                            onChange={(e) => {
                                let newValue = e.target.value;
                                if (newValue !== '') {
                                    newValue = newValue.replace(/^0+/, '') || '0';
                                }
                                onPropertyChange({ borderRadius: newValue });
                                setSelectedComponent((prev) => ({
                                    ...prev,
                                    properties: {
                                        ...prev.properties,
                                        borderRadius: newValue
                                    }
                                }));
                                setFlag(false);
                            }}
                            className="block p-1 text-xs text-gray-900 bg-white border-custome w-[90px] rounded focus:ring-blue-500 focus:border-blue-500"
                            min="0"
                            max="50"
                        />
                    </span>
                </SidebarSection>

            </div >
        </>
    )
}
export default ImageProperties;