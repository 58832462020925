import * as React from "react";
const CalendarSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M6.75 1a.75.75 0 0 1 .75.75V3h5V1.75a.75.75 0 0 1 1.5 0V3h2.25A2.75 2.75 0 0 1 19 5.75v10.5A2.75 2.75 0 0 1 16.25 19H3.75A2.75 2.75 0 0 1 1 16.25V5.75A2.75 2.75 0 0 1 3.75 3H6V1.75A.75.75 0 0 1 6.75 1ZM6 4.5H3.75c-.69 0-1.25.56-1.25 1.25V8h15V5.75c0-.69-.56-1.25-1.25-1.25H14v.75a.75.75 0 0 1-1.5 0V4.5h-5v.75a.75.75 0 0 1-1.5 0V4.5Zm11.5 5h-15v6.75c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25V9.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CalendarSvg;
