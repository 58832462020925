import { apiSlice } from "../../app/api/apiSlice";

export const groupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createGroup: builder.mutation({
      query: (credentials) => ({
        url: "/group",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    editGroup: builder.mutation({
      query: ({ groupId, ...credentials }) => ({
        url: `/group/${groupId}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    getAllGroups: builder.mutation({
      query: () => ({
        url: `/group`,
        method: "GET",
      }),
    }),
    getGroupById: builder.mutation({
      query: ({ groupId }) => ({
        url: `/group/${groupId}`,
        method: "GET",
      }),
    }),
    addUserToGroup: builder.mutation({
      query: ({ groupId, ...data }) => ({
        url: `/group/user/${groupId}`,
        method: "POST",
        body: data,
      }),
    }),
    removeUserFromGroup: builder.mutation({
      query: ({ userGroupId }) => ({
        url: `/group/user/${userGroupId}`,
        method: "DELETE",
      }),
    }),
    getAllGroupUsers: builder.mutation({
      query: ({ groupId }) => ({
        url: `/group/user/${groupId}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useCreateGroupMutation,
  useEditGroupMutation,
  useGetAllGroupsMutation,
  useGetGroupByIdMutation,
  useAddUserToGroupMutation,
  useRemoveUserFromGroupMutation,
  useGetAllGroupUsersMutation,
} = groupApiSlice;
