import { apiSlice } from "../../app/api/apiSlice";

export const permissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPermission: builder.mutation({
      query: (credentials) => ({
        url: "/permission",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    editPermission: builder.mutation({
      query: ({ permissionId, ...credentials }) => ({
        url: `/permission/${permissionId}`,
        method: "PATCH",
        body: { ...credentials },
      }),
    }),
    getAllPermissions: builder.mutation({
      query: () => ({
        url: `/permission`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useCreatePermissionMutation,
  useEditPermissionMutation,
  useGetAllPermissionsMutation,
} = permissionApiSlice;
