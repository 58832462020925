import React, { useEffect, useState, createContext, useCallback } from "react";
import useSocketService from "../services/socket";
import { useSelector } from "react-redux";
import { selectUser } from "../features/auth/authSlice";
import {
  useCreateConversationMutation,
  useFetchAthenaMessagesMutation,
  useFetchChatsMutation,
  useFetchConversationAttachmentsMutation,
  useFetchConversationByIdMutation,
  useFetchConversationMessagesMutation,
  useFetchLatestMessagesMutation,
  useFetchStarredMessagesMutation,
  useFetchTaggedCaretagsMutation,
  useFetchTaggedPatientsMutation,
  useStarMessageMutation,
  useUnstarMessageMutation,
  useUploadEHRMutation,
} from "../features/chat/chatApiSlice";
import moment from "moment";
import { useGetNotificationsMutation } from "../features/notification/notificationApiSlice";
import { useMapStore } from "./map.store";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const user = useSelector(selectUser);
  const [messages, setMessages] = useState([]);
  const [latestMessages, setLatestMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [careTags, setCareTags] = useState([]);
  const [selectedProviderLocation, setSelectedProviderLocation] = useState([]);

  const socket = useSocketService();

  const [fetchChats] = useFetchChatsMutation();
  const [createConversationApi] = useCreateConversationMutation();
  const [fetchConversationById] = useFetchConversationByIdMutation();
  const [fetchConversationMessages] = useFetchConversationMessagesMutation();
  const [starMessage] = useStarMessageMutation();
  const [unstarMessage] = useUnstarMessageMutation();
  const [fetchStarredMessages] = useFetchStarredMessagesMutation();
  const [fetchTaggedPatients] = useFetchTaggedPatientsMutation();
  const [fetchTaggedCaretags] = useFetchTaggedCaretagsMutation();
  const [fetchLatestMessages] = useFetchLatestMessagesMutation();
  const [getNotifications] = useGetNotificationsMutation();
  const [fetchMediaFiles] = useFetchConversationAttachmentsMutation();
  const [fetchAthenaMessages] = useFetchAthenaMessagesMutation();
  const [uploadEHRApi] = useUploadEHRMutation();

  const { providers } = useMapStore((state) => state);

  // useEffect(() => {
  //   if (user != null) {
  //     getNotifications().then((res) => {
  //       setNotifications(Array.isArray(res?.data?.data) ? res?.data?.data : []);
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected with token");
    });

    socket.on("connect_error", (err) => {
      console.log("Connection error: ", err.message);
    });

    socket.on("echo", (message) => {
      console.log(message, "echo");
    });

    const conversationCreated = (group) => {
      console.log("conversation created");
      // setChats((prevChats) => [...prevChats, chatConvert(group || {})]);
      setChats((prevChats) => (group ? [...prevChats, group] : prevChats));
    };

    socket.on("conversation created", conversationCreated);

    const conversationUpdated = (group) => {
      console.log("conversation updated");
      setChats((prevChats) =>
        prevChats.map((chat) => {
          if (chat.id === group.id) {
            // return chatConvert(group);
            return group;
          }
          return chat;
        })
      );
    };

    socket.on("conversation updated", conversationUpdated);

    const conversationDeleted = (group) => {
      console.log("conversation deleted");
      setChats((prevChats) => prevChats.filter((chat) => chat.id !== group));
    };

    socket.on("conversation deleted", conversationDeleted);

    const messageCreationAcknowledged = ({ databaseId, tempId }) => {
      console.log(databaseId, tempId, "message creation acknowledged");
      setMessages((prevMessages) => {
        const index = prevMessages.findIndex((msg) => msg.tempId === tempId);
        if (index !== -1) {
          prevMessages[index].id = databaseId;
        }
        return [...prevMessages];
      });
    };

    socket.on("message creation acknowledged", messageCreationAcknowledged);

    const messageUpdateAcknowledged = (updatedMessage) => {
      console.log("message update acknowledged");
      setMessages((prevMessages) =>
        prevMessages.map((message) => {
          if (message.id === updatedMessage.id) {
            return { ...message, ...updatedMessage };
          }
          return message;
        })
      );
    };

    socket.on("message update acknowledged", messageUpdateAcknowledged);

    const messageUpdated = (message) => {
      console.log("message updated");
      setMessages((prevMessages) => {
        return prevMessages.map((msg) => {
          if (msg.id === message.id) {
            return { ...msg, ...message };
          }
          return msg;
        });
      });
    };

    socket.on("message updated", messageUpdated);

    const messageDeletionAcknowledged = ({ databaseId }) => {
      console.log("message deletion acknowledged");
      setMessages((prevMessages) => {
        return prevMessages?.filter((msg) => msg.id !== databaseId);
      });
    };

    socket.on("message deletion acknowledged", messageDeletionAcknowledged);

    const messageDeleted = (messageId) => {
      console.log("message deleted");
      setMessages((prevMessages) => {
        return prevMessages?.filter((msg) => msg.id !== messageId);
      });
    };

    socket.on("message deleted", messageDeleted);

    const notificationReceive = (notification) => {
      console.log("received notification");
      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);
    };

    socket.on("notification", notificationReceive);

    return () => {
      socket.off("connect");
      socket.off("connect_error");
      socket.off("conversation created");
      socket.off("conversation updated");
      socket.off("conversation deleted");
      socket.off("message creation acknowledged");
      socket.off("message update acknowledged");
      socket.off("message updated");
      socket.off("message deletion acknowledged");
      socket.off("message deleted");
      socket.off("notification", notificationReceive);
    };
  }, []);

  useEffect(() => {
    const messageSent = (message) => {
      console.log(message, "message sent");
      setMessages((prevMessages) => {
        if (currentChat?.id === message.conversationId) {
          return [message, ...prevMessages];
        }
        return prevMessages;
      });
      setChats((prevChats) =>
        prevChats?.map((data_G) => {
          if (data_G.id === message.conversationId) {
            return {
              ...data_G,
              lastMessage: message,
            };
          }
          return {
            ...data_G,
          };
        })
      );
    };

    socket.on("message sent", messageSent);

    return () => {
      socket.off("message sent", messageSent);
    };
  }, [currentChat]);

  useEffect(() => {
    const providerCurrentlyAt = ({ provider, location }) => {
      if (providers?.length > 0) {
        const providerData = providers.find(
          (data) => !!data?.providers.find((p) => p.id == provider.id)
        );
        const isExist = selectedProviderLocation.find(
          (pro) => pro?.provider?.id == provider.id
        );
        setSelectedProviderLocation((prev) =>
          isExist
            ? prev.map((pro) => {
                return {
                  provider: providerData,
                  providers: providerData.providers,
                  coords: provider.coordinates,
                };
              })
            : [
                ...prev,
                {
                  provider: providerData,
                  providers: providerData.providers,
                  coords: provider.coordinates,
                },
              ]
        );
      }
    };

    socket.on("provider currently at", providerCurrentlyAt);
    return () => {
      socket.off("provider currently at");
    };
  }, [providers]);

  const chatConvert = (data_res) => {
    const contact = data_res?.participants.length
      ? data_res?.participants.find((data) =>
          user !== undefined ? data.user_id !== user?.id : false
        )
      : undefined;
    return {
      conversationId: data_res?.id,
      isCreator: data_res?.creator_id === user?.id,
      isAdmin: data_res?.creator_id === user?.id,
      conversationName: data_res?.name,
      conversationImg: data_res?.image,
      conversationType: data_res?.type,
      communityId: data_res?.community_id,
      lastSeen: data_res?.last_seen,
      lastMessage: data_res?.lastMessage,
      selected: false,
      contactId: contact?.user_id,
      contactImg: contact?.image,
    };
  };

  const getChats = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchChats();
      let filteredData = data?.data ?? [];
      setChats(filteredData);
      setLoading(false);
      return filteredData;
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
      return [];
    }
  }, [user]);

  const getConversationDetails = useCallback(
    async ({ conversationId }) => {
      try {
        setLoading(true);
        const { data } = await fetchConversationById({
          id: conversationId,
        });
        let filteredData = {};
        if (data?.data) {
          // filteredData = chatConvert(data.data);
          filteredData = data.data;
        }
        setLoading(false);
        return filteredData;
      } catch (error) {
        setLoading(false);
        return {};
      }
    },
    [user]
  );

  const getMessages = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchConversationMessages({
        id: conversationId,
      });
      const filteredData = data?.data;
      setMessages(filteredData ?? []);
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getLatestMessages = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchLatestMessages();
      const filteredData = data.data;
      setLatestMessages(filteredData);
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getStarredMessages = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchStarredMessages({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getReferencedPatients = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchTaggedPatients({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getTaggedCaretags = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchTaggedCaretags({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getMediaFiles = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchMediaFiles({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const getAthenaMessages = useCallback(async ({ conversationId }) => {
    try {
      setLoading(true);
      const { data } = await fetchAthenaMessages({
        id: conversationId,
      });
      const filteredData = data.data;
      setLoading(false);
      return filteredData;
    } catch (error) {
      setLoading(false);
      return {};
    }
  }, []);

  const emitEcho = () => {
    socket.emit("echo");
  };

  const createConversation = async ({
    userIds,
    community_id,
    name,
    description,
    image,
    type,
  }) => {
    try {
      const { data } = await createConversationApi({
        participants: userIds,
        community_id,
        name,
        description,
        image,
        type,
      });
      setChats((prevChats) => [...prevChats, data?.data || {}]);
      socket.emit("create conversation", {
        group: {
          ...data?.data,
          name: type === "GROUP" ? name : user?.details?.name,
        },
        userIds: userIds?.map((id) => `USER:${id}`),
        meta: {
          creatorName: user?.details?.name,
          groupName: name,
        },
      });
      return data.data;
    } catch (error) {
      return {};
    }
  };

  const onStarMessage = async (message) => {
    try {
      await starMessage({
        id: message.id,
      });
      setMessages((prevMsgs) =>
        prevMsgs?.map((data) => {
          if (data.id === message.id) {
            return {
              ...data,
              is_starred: true,
            };
          }
          return {
            ...data,
          };
        })
      );
    } catch (error) {
      console.log(error, "err-star-message");
    }
  };

  const onUnstarMessage = async (message) => {
    try {
      await unstarMessage({
        id: message.id,
      });
      setMessages((prevMsgs) =>
        prevMsgs?.map((data) => {
          if (data.id === message.id) {
            return {
              ...data,
              is_starred: false,
            };
          }
          return {
            ...data,
          };
        })
      );
    } catch (error) {
      console.log(error, "err-star-message");
    }
  };

  const markUrgent = async ({ message, userIds, updateMsg, meta }) => {
    try {
      updateMessage({ message, userIds, updateMsg, meta });
    } catch (error) {
      console.log(error, "mark-message");
    }
  };

  const uploadEHR = async ({ message }) => {
    try {
      await uploadEHRApi({
        messageId: message.id,
        message,
        practiceId: user?.organisation?.practiceId,
      });
      setMessages((prevMessages) => {
        return prevMessages?.map((msg) => {
          if (msg.id === message.id) {
            return { ...message, isSentToEhr: true };
          }
          return msg;
        });
      });
    } catch (error) {
      console.log(error, "mark-message");
    }
  };

  const updateConversation = (group, userIds) => {
    socket.emit("update conversation", { group, userIds });
  };

  const deleteConversation = (group, userIds) => {
    socket.emit("delete conversation", { group, userIds });
  };

  const addConversationUser = (group, userIds, meta) => {
    socket.emit("add conversation user", { group, userIds, meta });
  };

  const removeConversationUser = (group, userIds) => {
    socket.emit("remove conversation user", { group, userIds });
  };

  const sendMessage = ({ message, userIds, meta }) => {
    setMessages((prevMessages) => [
      {
        ...message,
        sentAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        sender: {
          id: user.id,
        },
      },
      ...prevMessages,
    ]);
    // event -> 'CREATE' | 'UPDATE' | 'DELETE'
    // userIds -> participants
    const senderId = user.id;
    const event = "CREATE";
    socket.emit("send message", { senderId, event, message, userIds, meta });
    setChats((prevChats) =>
      prevChats?.map((data_G) => {
        if (data_G.id === message.conversationId) {
          return {
            ...data_G,
            lastMessage: {
              ...message,
              sentAt: moment().format("YYYY-MM-DD HH:mm:ss"),
              sender: {
                id: user.id,
              },
            },
          };
        }
        return {
          ...data_G,
        };
      })
    );
  };

  const updateMessage = ({ message, userIds, meta, updateMsg }) => {
    setMessages((prevMessages) => {
      const result = prevMessages?.map((msg) => {
        if (msg.id === message.id) {
          return updateMsg;
        }
        return msg;
      });
      return result;
    });
    const senderId = user.id;
    const event = "UPDATE";
    socket.emit("update message", { senderId, event, message, userIds, meta });
  };

  const deleteMessage = ({ message, userIds, meta }) => {
    const filteredMessages = messages?.filter((msg) => msg.id !== message.id);
    setMessages(filteredMessages);
    setChats((prevChats) =>
      prevChats?.map((data_G) => {
        if (data_G.id === message.conversationId) {
          return {
            ...data_G,
            lastMessage: filteredMessages?.[filteredMessages - 1],
          };
        }
        return {
          ...data_G,
        };
      })
    );
    const senderId = user.id;
    const event = "DELETE";
    socket.emit("delete message", { senderId, event, message, userIds, meta });
  };

  function updateLastSeen(userId, conversationId) {
    socket.emit("last seen", {
      userId,
      conversationId,
    });
    setChats((chats) => {
      return chats.map((chat) => {
        if (chat.id === conversationId) {
          return {
            ...chat,
            lastSeen: moment().format("YYYY-MM-DD HH:mm:ss"),
          };
        }
        return chat;
      });
    });
  }

  return (
    <SocketContext.Provider
      value={{
        socket,
        emitEcho,
        createConversation,
        updateConversation,
        deleteConversation,
        addConversationUser,
        removeConversationUser,
        sendMessage,
        updateMessage,
        deleteMessage,
        messages,
        setMessages,
        chats,
        setChats,
        currentChat,
        setCurrentChat,
        editMessage,
        setEditMessage,
        getChats,
        getConversationDetails,
        getMessages,
        onStarMessage,
        onUnstarMessage,
        markUrgent,
        getStarredMessages,
        getReferencedPatients,
        getTaggedCaretags,
        isLoading,
        getLatestMessages,
        latestMessages,
        setLatestMessages,
        notifications,
        setNotifications,
        getNotifications,
        getMediaFiles,
        uploadEHR,
        chatConvert,
        selectedProviderLocation,
        setSelectedProviderLocation,
        careTags,
        setCareTags,
        updateLastSeen,
        getAthenaMessages,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
