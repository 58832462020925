import OpenWithIcon from '@mui/icons-material/OpenWith';
import React from 'react';
import { Rnd } from 'react-rnd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SaveIcon from '@mui/icons-material/Save';
import { useDrag } from 'react-dnd';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import { Tooltip } from "@mui/material";

const DraggableComponent = ({
    type,
    id,
    position = { x: 0, y: 0 },
    setPosition,
    onDelete,
    children,
    isInReportArea,
    components,
    setComponents,
    properties,
    selectedComponent,
    setSelectedComponent,
    onPropertyChange,
    reportAreaRef,
    disableRndDragging,
    setFlag,
    disabled,
    setIsSavedClicked,
    setIsCreateNewTemplate,
    setIsSaveAsClicked,
    isTableView,
    isChartView,
    setIsAnimated,
    handleDesignClick,
    handleDataClick
}) => {
    const dragRef = React.useRef(null);
    const drgRefDisabled = React.useRef(null);
    const rndRef = React.useRef();
    const childRef = React.useRef();
    const [delta, setDelta] = React.useState({ width: 'auto', height: 'auto' });
    const [isDraggingWithButton, setIsDraggingWithButton] = React.useState(false);

    const [{ isDragging }, drag] = useDrag({
        type: 'reportComponent',
        item: { type, id: id ? id : "", properties },
        size: () => {
            const initialWidth = dragRef.current.offsetWidth;
            const initialHeight = dragRef.current.offsetHeight;
            return { height: initialHeight, width: initialWidth }
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (isInReportArea) {
                const dropResult = monitor.getClientOffset();
                const containerOffset = dragRef.current.getBoundingClientRect();
                if (dropResult && containerOffset) {
                    const relativePosition = {
                        x: dropResult.x - containerOffset.left,
                        y: dropResult.y - containerOffset.top,
                    };
                    setPosition(relativePosition);

                }
            }
        },
    });
    const handleResizeStop = (e, direction, ref, delta, position) => {
        setDelta({
            width: ref.style.width,
            height: ref.style.height
        });
        setPosition(position);
        const newWidth = parseInt(ref.style.width, 10);
        const newHeight = parseInt(ref.style.height, 10);
        const reportAreaWidth = reportAreaRef?.current?.offsetWidth;
        const reportAreaHeight = reportAreaRef?.current?.offsetHeight;
        const right = reportAreaWidth - (selectedComponent?.properties?.left + newWidth);
        const bottom = reportAreaHeight - (selectedComponent?.properties?.top + newHeight);
        setSelectedComponent((prev) => ({
            ...prev,
            properties: {
                ...prev?.properties,
                width: newWidth,
                height: newHeight,
                right,
                bottom
            }
        }));
        onPropertyChange({ width: newWidth, height: newHeight, right, bottom });
        setFlag(false)
        setIsAnimated(false);
    };
    React.useEffect(() => {
        drag(dragRef.current);
    }, [drag]);

    return (
        <>
            {isInReportArea && selectedComponent?.id === id ?
                <Rnd
                    id={id}
                    size={{
                        width: (isTableView && selectedComponent?.type === 'chart' && !isChartView) || (isChartView && selectedComponent?.type === 'table' && !isTableView)
                            || (!isTableView && selectedComponent?.type === 'table')
                            || (!isChartView && selectedComponent?.type === 'chart')
                            ? 0 : selectedComponent?.properties?.width,
                        height: (isTableView && selectedComponent?.type == 'chart' && !isChartView) || (isChartView && selectedComponent?.type == 'table' && !isTableView)
                            || (!isTableView && selectedComponent?.type === 'table')
                            || (!isChartView && selectedComponent?.type === 'chart')
                            ? 0 : selectedComponent?.properties?.height
                    }}
                    position={{
                        x: (isTableView && selectedComponent?.type == 'chart' && !isChartView)
                            || (isChartView && selectedComponent?.type == 'table' && !isTableView)
                            || (!isTableView && selectedComponent?.type === 'table')
                            || (!isChartView && selectedComponent?.type === 'chart')
                            ? 0 : selectedComponent?.properties?.left,
                        y: (isTableView && selectedComponent?.type == 'chart' && !isChartView)
                            || (isChartView && selectedComponent?.type == 'table' && !isTableView)
                            || (!isTableView && selectedComponent?.type === 'table')
                            || (!isChartView && selectedComponent?.type === 'chart')
                            ? 0 : selectedComponent?.properties?.top
                    }}
                    // bounds={reportAreaRef.current}
                    bounds={false}
                    onDragStop={(e, d) => {
                        const x = Math.round(d.x);
                        const y = Math.round(d.y);
                        setIsDraggingWithButton(false);
                        setPosition({ x: x > 0 ? x : 0, y: y > 0 ? y : 0 });
                        setSelectedComponent((prev) => ({
                            ...prev,
                            properties: {
                                ...prev?.properties,
                                top: y > 0 ? y : 0,
                                left: x > 0 ? x : 0,
                            }
                        }));
                        onPropertyChange({ left: x > 0 ? x : 0, top: y > 0 ? y : 0 });
                        setFlag(false);
                        setIsAnimated(false);
                    }}
                    onResizeStop={handleResizeStop}
                    enableResizing={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: true,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                    style={{
                        position: 'relative',
                        border: '1px dotted #247dda',
                        zIndex: parseInt(id.split("-")[1], 10),
                        boxSizing: 'content-box'
                    }}
                    disableDragging={!isDraggingWithButton && disableRndDragging}
                >
                    <div
                        ref={childRef}
                        style={{
                            left: isInReportArea ? position.x : 'auto',
                            top: isInReportArea ? position.y : 'auto',
                            opacity: isDragging ? 0.5 : 1,
                            padding: '8px',
                            height: '100%',
                            width: '100%',
                            overflow: selectedComponent?.type === 'textbox' ? 'unset' : 'auto'
                        }}>
                        {children}
                        {isInReportArea && (
                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                {selectedComponent?.type !== 'textbox' && selectedComponent?.type !== 'image' && (
                                    <>
                                        <Tooltip title="Design">
                                            <button
                                                style={{
                                                    position: 'absolute',
                                                    top: '-22px',
                                                    right: '60px',
                                                    backgroundColor: '#fff',
                                                    color: '#247dda',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    borderRadius: '3px',
                                                    padding: '2px 4px',
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDesignClick();
                                                }}
                                            >
                                                <ViewQuiltIcon />
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Data">
                                            <button
                                                style={{
                                                    position: 'absolute',
                                                    top: '-22px',
                                                    right: '35px',
                                                    backgroundColor: '#fff',
                                                    color: '#247dda',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    borderRadius: '3px',
                                                    padding: '2px 4px',
                                                    opacity: selectedComponent?.properties?.data?.length <= 0 ? 0.7 : 1,
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDataClick();
                                                }}
                                                disabled={selectedComponent?.properties?.data?.length <= 0}
                                            >
                                                <VerticalSplitIcon />
                                            </button>
                                        </Tooltip>
                                    </>
                                )}
                                <button
                                    ref={rndRef}
                                    style={{
                                        position: 'absolute',
                                        top: '-20px',
                                        right: '10px',
                                        backgroundColor: 'transparent',
                                        color: '#247dda',
                                        border: 'none',
                                        cursor: 'move',
                                        paddingLeft: '2px',
                                        paddingRight: '6px'
                                    }}
                                    onMouseDown={(e) => {
                                        setIsDraggingWithButton(true);
                                    }}
                                    onMouseUp={(e) => {
                                        setIsDraggingWithButton(false);
                                    }}
                                    draggable='true'
                                    onDrag={(e) => {
                                        setIsDraggingWithButton(true);
                                    }}
                                    onMouseDownCapture={() => {
                                        setIsDraggingWithButton(true);
                                    }}
                                // onMouseMove={(e) => {
                                //     setIsDraggingWithButton(true);
                                // }}
                                >
                                    <OpenWithIcon />
                                </button>
                                <button
                                    style={{
                                        position: 'absolute',
                                        top: '-19px',
                                        right: '-9px',
                                        backgroundColor: '#247dda',
                                        color: 'white',
                                        border: 'none',
                                        cursor: 'pointer',
                                        borderRadius: '50%',
                                        padding: '0px 7px'

                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete(id);
                                    }}
                                >
                                    X
                                </button>
                            </div>
                        )}
                    </div>
                </Rnd > :
                <div
                    id={id}
                    ref={isInReportArea ? drgRefDisabled : dragRef}
                    // ref={dragRef}
                    className={!isInReportArea ? `d-flex grow w-2/4 justify-center hover:bg-[#1c65ad]` : ""}
                    style={{
                        position: isInReportArea ? 'absolute' : 'static',
                        left: isInReportArea ?
                            (isTableView && components?.find((item) => item.id == id)?.type == 'chart' && !isChartView)
                                || (isChartView && components?.find((item) => item.id == id)?.type == 'table' && !isTableView)
                                || (!isTableView && components?.find((item) => item.id == id)?.type == 'table')
                                || (!isChartView && components?.find((item) => item.id == id)?.type == 'chart')
                                ? 0 :
                                components?.find((item) => item?.id == id)?.properties?.left : 'auto',
                        top: isInReportArea ?
                            (isTableView && components?.find((item) => item.id == id)?.type == 'chart' && !isChartView)
                                || (isChartView && components?.find((item) => item.id == id)?.type == 'table' && !isTableView)
                                || (!isTableView && components?.find((item) => item.id == id)?.type == 'table')
                                || (!isChartView && components?.find((item) => item.id == id)?.type == 'chart')
                                ? 0 :
                                components?.find((item) => item?.id == id)?.properties?.top : 'auto',
                        width: isInReportArea ?
                            (isTableView && components?.find((item) => item.id == id)?.type == 'chart' && !isChartView)
                                || (isChartView && components?.find((item) => item.id == id)?.type == 'table' && !isTableView)
                                || (!isTableView && components?.find((item) => item.id == id)?.type == 'table')
                                || (!isChartView && components?.find((item) => item.id == id)?.type == 'chart')
                                ? 0 :
                                components?.find((item) => item.id == id)?.properties?.width : delta?.width,
                        height: isInReportArea ?
                            (isTableView && components?.find((item) => item.id == id)?.type == 'chart' && !isChartView)
                                || (isChartView && components?.find((item) => item.id == id)?.type == 'table' && !isTableView)
                                || (!isTableView && components?.find((item) => item.id == id)?.type == 'table')
                                || (!isChartView && components?.find((item) => item.id == id)?.type == 'chart')
                                ? 0 :
                                components?.find((item) => item.id == id)?.properties?.height : delta?.height,
                        opacity: disabled || isDragging ? 0.6 : 1,
                        //border: isInReportArea ? '1px solid black' : "",
                        padding: '8px',
                        cursor: isInReportArea ? 'auto' : disabled ? 'not-allowed' : 'grab',
                        //display: isDragging ? 'none' : 'block', // Hide the original while dragging
                    }}>
                    {children}
                    {isInReportArea && (
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        </div>
                    )}
                </div >
                // </Rnd >
            }
        </>
    );
};

export default DraggableComponent;
